import { useQuery } from 'react-query'
import { blogUseCase } from '../../core/factories'
import { useContentful } from 'contexts/ContentfulContext'

const useListBlog = () => {
  const { client } = useContentful()

  const query = useQuery(['list_blog'], () =>
    blogUseCase({ client }).listBlog()
  )

  return query
}

export default useListBlog
