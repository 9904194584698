import React from 'react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import PaymentForm from './PaymentForm'

const Payment = () => {
  const { t } = useTranslation()

  return (
    <Tabs>
      <TabList width="100%">
        <Tab
          borderColor="border.100"
          _focus={{
            outline: 'none',
          }}
          _selected={{
            color: 'blue.400',
            fontWeight: 'bold',
            borderColor: 'orange.400',
          }}
          flex="1"
        >
          {t('payment@monthly')}
        </Tab>
        <Tab
          borderColor="border.100"
          _focus={{
            outline: 'none',
          }}
          _selected={{
            color: 'blue.400',
            fontWeight: 'bold',
            borderColor: 'orange.400',
          }}
          flex="1"
        >
          {t('payment@oneTime')}
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <PaymentForm />
        </TabPanel>
        <TabPanel>
          <PaymentForm />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default Payment
