import React from 'react'
import { Box, Menu, MenuList, MenuButton, MenuItem } from '@chakra-ui/react'
import { Link } from 'pages'
import { useSignOut, useUser } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as AngleDown } from 'assets/icons/angleDown.svg'
import { ReactComponent as Avatar } from 'assets/icons/avatar.svg'

const ProfileDropdown = ({ black = false, disableLinks, onClick }) => {
  const signOut = useSignOut()
  const { user } = useUser()
  const { t } = useTranslation()
  return (
    <Menu>
      <MenuButton
        sx={{
          margin: '0 !important',
          marginLeft: '1.5rem !important',
        }}
        padding="0"
        color={black ? 'gray.800' : 'white'}
        fill={black ? 'gray.800' : 'white'}
        transition="all 0.2s"
      >
        <Box
          fontWeight="bold"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderColor="transparent"
          color="inherit"
        >
          <Avatar height="44px" width="44px" />
          <AngleDown
            fill={black ? 'gray.800' : 'white'}
            style={{ marginLeft: 10 }}
            width={10}
            height={10}
          />
        </Box>
      </MenuButton>
      <MenuList boxShadow="0 12px 16px 0 rgba(0,0,0,0.15)" border="none">
        <Link to="/profile/dashboard" onClick={onClick}>
          <MenuItem>{t('nav@profile')}</MenuItem>
        </Link>
        {user.isAdmin && (
          <>
            <Link to="/collaborations-dashboard" onClick={onClick}>
              <MenuItem>{t('dashboard@nav@collaborations')}</MenuItem>
            </Link>
            <Link to="/fundraisers-dashboard" onClick={onClick}>
              <MenuItem>{t('dashboard@nav@fundraisers')}</MenuItem>
            </Link>
            <Link to="/applications-dashboard" onClick={onClick}>
              <MenuItem>{t('dashboard@nav@applications')}</MenuItem>
            </Link>
            <Link to="/expertise-dashboard" onClick={onClick}>
              <MenuItem>{t('dashboard@nav@expertise')}</MenuItem>
            </Link>
            <Link to="/experts-dashboard" onClick={onClick}>
              <MenuItem>{t('Experts dashboard')}</MenuItem>
            </Link>
            <Link to="/cosmo-admin" onClick={onClick}>
              <MenuItem>{t('Cosmo Admin')}</MenuItem>
            </Link>
            <Link to="/analytics" onClick={onClick}>
              <MenuItem>{t('analytics')}</MenuItem>
            </Link>
          </>
        )}
        <MenuItem
          onClick={() => (disableLinks ? onClick('signOut') : signOut())}
        >
          {t('nav@logout')}
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export default ProfileDropdown
