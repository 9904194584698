const feedRepo = (feedService) => {
  const listFeedByUserId = async (input) =>
    await feedService.listFeedByUserId(input)

  return {
    listFeedByUserId,
  }
}

export default feedRepo
