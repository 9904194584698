const LineClamp = ({ children, Component, lineClamp = 1, ...props }) => {
  return (
    <Component
      overflow="hidden"
      textOverflow="ellipsis"
      sx={{
        WebkitLineClamp: lineClamp,
        lineClamp: lineClamp,
        WebkitBoxOrient: 'vertical',
      }}
      {...props}
    >
      {children}
    </Component>
  )
}

export default LineClamp
