/* eslint-disable */
import React from 'react'
import { Box } from '@chakra-ui/react'
import Content from 'components/Content'
import { useTranslation } from 'contexts/TranslationContext'
import { useScreenSize } from 'contexts'
import { useNavigation } from 'pages'
import NeedCard from './NeedCard'
import PeerToPeerCard from './PeerToPeerCard'

const Story = ({ project, peerProject }) => {
  const { language, t } = useTranslation()
  const { navigationPush } = useNavigation()
  const { isMobile } = useScreenSize()

  return (
    <Box>
      {isMobile &&
        project.collaborations &&
        project.collaborations.length > 0 &&
        !project.noCTA && (
          <Box mb={8}>
            {project.collaborations.map((collaboration, index) => (
              <NeedCard
                key={`collaboration_${index}`}
                collaboration={collaboration}
                t={t}
                lang={language}
                selectAction={() => {
                  navigationPush(
                    `/act-now?InitiativeId=${project?.id}&need=${collaboration.needs_en}`
                  )
                }}
                ended={project.status !== 'live'}
              />
            ))}
          </Box>
        )}

      {peerProject?.id && (
        <PeerToPeerCard project={project} peerProject={peerProject} />
      )}
      {project && project[`content_${language}`] && (
        <Content
          px={{ base: 5, md: 0 }}
          html={project[`content_${language}`]}
        />
      )}
    </Box>
  )
}

export default Story
