export default [
  {
    href: '/pre-create-project',
    label: 'footer@createProject',
  },
  {
    href: '/about-us',
    label: 'footer@about',
  },
  {
    href: '/fundraisers',
    label: 'Fundraisers',
  },
  {
    href: '/our-team',
    label: 'footer@ourTeam',
  },

  {
    href: '/collaborations',
    label: 'footer@initiatives',
  },

  {
    href: '/our-impact',
    label: 'footer@ourImpact',
  },
  {
    href: '/experts',
    label: 'experts',
  },
  {
    href: '/select-gift-card',
    label: 'Gift card',
  },
  {
    href: '/contact-us',
    label: 'footer@contactUs',
  },
  {
    href: '/paas',
    label: 'footer@corporate',
  },
  // {
  //   href: '/blog',
  //   label: 'footer@blog',
  // },
]
