import { useMutation } from 'react-query'
import { initiativeUseCase } from '../../core/factories'

const useCollabRequest = () => {
  const {
    mutate: collaboratorRequest,
    isLoading,
    error,
    data,
  } = useMutation(initiativeUseCase.collaboratorRequest)

  return {
    collaboratorRequest,
    isLoading,
    error,
    data,
  }
}

export default useCollabRequest
