import React from 'react'
import { v4 as uuid } from 'uuid'
import { Stack, Box, Heading, Button } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { InputField } from 'components/InputField'
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg'

const Collaboration = ({ register, collaborations, errors, append }) => {
  const { t } = useTranslation()

  return (
    <Box width="100%">
      <Heading mb="3" fontSize="1.625rem" as="h2">
        {t('Collaboration')}
      </Heading>
      <Stack w="full" mb="5.5rem" alignItems="flex-start" spacing="6">
        {collaborations.map((item, index) => (
          <Stack w="full" spacing="6" key={item.id}>
            <InputField
              isInvalid={
                !!errors?.collaborations && !!errors?.collaborations[index]
              }
              label={t('describe the needs')}
              errorMessage={t('field is required')}
              {...register(`collaborations.${index}.needs`, {
                required: true,
              })}
            />
            <Box borderBottom="1px solid" borderColor="border.100" w="full" />
          </Stack>
        ))}
        <Button
          onClick={() => append({ id: uuid(), needs: '' })}
          color="gray.800"
        >
          <Box mr="4">
            <Plus />
          </Box>
          {t('Add more options')}
        </Button>
      </Stack>
    </Box>
  )
}

export default Collaboration
