import React, { useState } from 'react'
import {
  Button,
  Stack,
  Text,
  IconButton,
  Flex,
  Box,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Heading,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { authUseCase } from '../core/factories'
import { useNavigation } from 'pages'
import { PasswordField } from 'components/PasswordField'
import { InputField } from 'components/InputField'
import { useTranslation } from 'contexts/TranslationContext'
import { useQueryParams, useSignIn, useUser } from 'hooks'
import { ReactComponent as Facebook } from 'assets/icons/authFacebook.svg'
import { ReactComponent as Google } from 'assets/icons/authGoogle.svg'
import useCustomToast from 'hooks/useCustomToast'
import amplitude from 'amplitude-js'

const SignInModal = ({ setModal, redirectTo, closeModal }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })
  const queryParams = useQueryParams()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const { signIn } = useSignIn()
  const { getUser } = useUser()
  const { navigationPush } = useNavigation()
  const toast = useCustomToast()

  const submit = (data) => {
    setLoading(true)
    signIn(
      {
        ...data,
        email: data.email.toLowerCase(),
      },
      {
        onSuccess: async ({ isSignedIn, nextStep }) => {
          await getUser()
          closeModal()
          if (!isSignedIn && nextStep?.signInStep === 'CONFIRM_SIGN_UP') {
            setModal({
              name: 'verify',
              modalProps: {
                email: data.email.toLowerCase(),
              },
            })
          } else {
            if (queryParams.get('token')) {
              return navigationPush(
                `/collaboration-request?token=${queryParams.get(
                  'token'
                )}&initiativeId=${queryParams.get(
                  'initiativeId'
                )}&email=${queryParams.get('email')}&response=${queryParams.get(
                  'response'
                )}`
              )
            }
            if (redirectTo) navigationPush(redirectTo)
            amplitude.getInstance().logEvent('signin-desktop')
            console.log('sent event to amplitude (signin-desktop)')
          }
        },
        onError: (err) => {
          setLoading(false)
          if (err.__type === 'UserNotConfirmedException') {
            setModal({
              name: 'verify',
              modalProps: {
                email: data.email.toLowerCase(),
              },
            })
          }
          toast({
            title: t(err.message),
            status: 'error',
            duration: 5000,
            position: 'top',
          })
        },
      }
    )
  }
  const onError = (data) => {
    console.log(data)
    console.log(getValues('password'))
  }
  const onGoogleSignIn = () => {
    amplitude.getInstance().logEvent('signingmail_desktop')
    console.log('sent event to amplitude (signingmail_desktop)')

    authUseCase.googleSignIn()
  }
  const onFacebookSignIn = () => {
    amplitude.getInstance().logEvent('signinfb_desktop')
    console.log('sent event to amplitude (signinfb_desktop)')

    authUseCase.fbSignIn()
  }
  return (
    <>
      <ModalHeader marginTop="15px">
        <Heading fontSize="3xl" as="h2" fontWeight="extrabold">
          {t('login@heading')}
        </Heading>
      </ModalHeader>
      <ModalCloseButton
        color="#DDD"
        _active={{ background: 'transparent' }}
        _hover={{ background: 'transparent' }}
        id="signin_modal_close"
      />
      <ModalBody>
        <form onSubmit={handleSubmit(submit, onError)}>
          <Flex mb={4}>
            <IconButton
              onClick={onFacebookSignIn}
              p="0"
              w="60px"
              h="60px"
              mr="16px"
              icon={<Facebook />}
              id="signin_button_facebook"
            />
            <IconButton
              onClick={onGoogleSignIn}
              p="0"
              w="60px"
              h="60px"
              icon={<Google />}
              id="signin_button_google"
            />
          </Flex>
          <Box
            as="span"
            display="block"
            fontSize="md"
            color="gray.700"
            letterSpacing="0.05px"
            fontWeight="500"
            mb={4}
          >
            {t('login@orLogin')}
          </Box>
          {(!!errors.password || !!errors.email) && (
            <Text mb="4" ml="2" color="red.400">
              {t('Credentials are required')}
            </Text>
          )}
          <Stack spacing="2">
            <InputField
              id="signin_input_email"
              size="md"
              label={t('login@email')}
              {...register('email', {
                required: true,
                pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
              })}
            />

            <PasswordField
              id="signin_input_password"
              size="lg"
              label={t('login@password')}
              onUpdate={(e) => {
                setValue('password', e.target.value)
              }}
              {...register('password', { required: true })}
              notValidate
            />
            <Box
              display="inline-block"
              color="gray.700"
              textAlign="left"
              fontWeight="500"
              fontSize="sm"
              textTransform="initial"
            >
              <Button
                textTransform="initial"
                color="gray.800"
                fontSize="sm"
                fontWeight="semibold"
                onClick={() => {
                  setModal({ name: 'forgotPassword' })
                }}
                id="signin_modal_forgot_password"
              >
                {t('login@forgotPassword')}
              </Button>
            </Box>
          </Stack>
          <Button
            isLoading={loading}
            w="full"
            type="submit"
            size="lg"
            variant="blue"
            colorScheme="blue"
            mt="30px"
            id="signin_modal_submit"
          >
            {t('login@submit')}
          </Button>
        </form>
        <ModalFooter justifyContent="center" mt="8">
          <Text
            textAlign="center"
            color="gray.800"
            fontSize="sm"
            fontWeight="semibold"
          >
            {t('login@dontHaveAccount')}{' '}
            <Button
              onClick={() => {
                setModal({ name: 'signUp' })
              }}
              color="orange.400"
              textTransform="initial"
              id="signin_modal_dontHaveAccount"
            >
              {t('login@signUp')}
            </Button>
          </Text>
        </ModalFooter>
      </ModalBody>
    </>
  )
}

export default SignInModal
