import { Flex, Popover, Text } from '@chakra-ui/react'
import { PopoverWrapper } from './PopoverWrapper'

//** Icons
import { ReactComponent as ArrowDown } from 'assets/icons/small_chevron_down.svg'
import { ReactComponent as Clock } from 'assets/icons/clock.svg'
import { RadioCheckGroup } from './ExpertProfileForm'

const Header = ({ tt, watch, language }) => (
  <>
    <Flex>
      <Clock />
      <Text ml={3} fontSize="md" color="gray.800">
        {watch('hrsPerWeek')
          ? tt(`expert@form@${watch('hrsPerWeek')}`, language)
          : tt('Available hours per week', language)}
      </Text>
    </Flex>
    <ArrowDown />
  </>
)

export const HoursPopover = ({
  tt,
  control,
  watch,
  isInvalid,
  isDisabled,
  language,
}) => (
  <Popover placement="bottom-start">
    {({ isOpen }) => (
      <PopoverWrapper
        isInvalid={isInvalid}
        isOpen={isOpen}
        isDisabled={isDisabled}
        Header={() => <Header tt={tt} watch={watch} language={language} />}
      >
        <RadioCheckGroup
          options={[
            { label: tt('expert@form@1h', language), value: '1h' },
            { label: tt('expert@form@2h', language), value: '2h' },
            { label: tt('expert@form@3-5h', language), value: '3-5h' },
            { label: tt('expert@form@6-8h', language), value: '6-8h' },
            { label: tt('expert@form@9+h', language), value: '9+h' },
          ]}
          name="hrsPerWeek"
          watch={watch}
          errorMessage={tt('createEditProject@thisFieldIsRequired', language)}
          control={control}
        />
      </PopoverWrapper>
    )}
  </Popover>
)
