import { Tab as ChakraTab } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'

export default function Tab({ title, onClick }) {
  const { t } = useTranslation()
  return (
    <ChakraTab
      _active={{ background: 'initial' }}
      _selected={{
        fontWeight: 'extrabold',
        color: 'blue.300',
        borderColor: 'blue.300',
      }}
      onClick={() => onClick(title)}
      fontWeight="semibold"
      color="gray.700"
      minW={{ base: '50%', md: '160px' }}
      fontSize="lg"
      py="22px"
    >
      {t(title)}
    </ChakraTab>
  )
}
