import React from 'react'
import { useTranslation } from 'contexts/TranslationContext'
import {
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Heading,
  Modal,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { useDeleteReport, useCustomToast } from 'hooks'

const DeleteReportModal = ({ onClose, isOpen, report, refetchReports }) => {
  const { t } = useTranslation()
  const { mutate: deleteReport, isLoading } = useDeleteReport()
  const toast = useCustomToast()
  const onRemove = () => {
    deleteReport(
      { id: report.id, createdAt: report.createdAt },
      {
        onSuccess: (data) => {
          console.log(data)
          refetchReports()
          toast({ title: t('report@deleteSuccessMessage'), status: 'success' })
          onClose()
        },
        onError: (err) => {
          console.log(err)
          toast({ title: 'Something went wrong', status: 'error' })
        },
      }
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      isCentered={window.innerHeight > 730}
    >
      <ModalOverlay />
      <ModalContent borderRadius="16px">
        <ModalHeader>
          <Heading fontWeight="bold" fontSize="lg">
            {t('report@deletingTitle')}
          </Heading>
        </ModalHeader>
        <ModalCloseButton onClick={onClose} id="delete_report_modal_close" />
        <ModalBody pb={4}>
          <Text fontSize="lg">{t('report@subtitle')}</Text>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} id="delete_report_modal_discard">
            {t('report@discard')}
          </Button>
          <Button
            variant="transparent"
            ml={4}
            color="red.400"
            isLoading={isLoading}
            onClick={onRemove}
            id="delete_report_modal_remove"
          >
            {t('report@remove')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DeleteReportModal
