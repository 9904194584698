import { Box, Flex, Heading, Image, Progress, Text } from '@chakra-ui/react'
import { useGetProject } from 'hooks'
import React from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { thousandSeparator } from 'contexts/CurrencySettingsContext'
import { useTranslation } from 'contexts/TranslationContext'
export default function OgImage() {
  const date = moment(new Date()).format('DD.MM.YYYY')
  const { t } = useTranslation()
  const { projectId } = useParams()
  const { data: project, isLoading } = useGetProject({ projectId })
  if (isLoading) {
    return <>Loading...</>
  }
  if (!isLoading && !project) {
    return <>project with id: {projectId} not found</>
  }
  return (
    <Box w="1200px" h="630px" overflow="hidden" id="mainImage" m="0">
      <Image
        w="1200px"
        h="400px"
        objectFit="cover"
        fallbackSrc="/rearmenia.jpg"
        src={`${process.env.REACT_APP_S3_BUCKET_URL}/public/thumbnail/medium/${project.cover}`}
      />
      <Box w="1200px" h="230px" background="white" boxSizing="border-box" p={8}>
        <Heading fontSize="40px">{t('supportWithDonation')}</Heading>
        <Progress
          mt={4}
          value={project?.total?.goalMetPercentage}
          borderRadius="100px"
          colorScheme="green"
          h="24px"
          backgroundColor="gray.500"
        />
        <Flex
          mt={6}
          w="full"
          justifyContent="space-between"
          fontSize="32px"
          fontWeight="400"
        >
          <Text>
            <Text as="span" fontWeight="bold">
              {thousandSeparator(project?.amount, 'AMD')} AMD{' '}
            </Text>{' '}
            <Text as="span" color="gray.700">
              {t('raisedBy')}
            </Text>{' '}
            {date}{' '}
          </Text>
          <Text>
            <Text as="span" color="gray.700">
              {t('goal')}
            </Text>{' '}
            {thousandSeparator(
              project.activeGoal?.amount || project.goal,
              'AMD'
            )}{' '}
            AMD
          </Text>
        </Flex>
      </Box>
    </Box>
  )
}
