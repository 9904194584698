const giftCardRepo = (giftCardService) => {
  const getGiftCard = async (id) => giftCardService.getGiftCard(id)

  const listGiftCards = async (variables) =>
    giftCardService.listGiftCards(variables)

  const listGiftCardsByCode = async (variables) =>
    giftCardService.listGiftCardsByCode(variables)

  const createGiftCard = async (input) => giftCardService.createGiftCard(input)
  const updateGiftCard = async (input) => giftCardService.updateGiftCard(input)

  return {
    createGiftCard,
    getGiftCard,
    listGiftCards,
    listGiftCardsByCode,
    updateGiftCard,
  }
}

export default giftCardRepo
