import React from 'react'
import { Stack, Box, Heading, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { InputField } from 'components/InputField'
import { TextareaField } from 'components/TextareaFIeld'
import { Select } from 'components/Select'
import { CATEGORY, REGIONS } from '../../../_constants'

const Basic = ({ register, errors, oldId }) => {
  const { t, language } = useTranslation()
  return (
    <Box width="100%">
      <Heading mb="3" fontSize="1.625rem" as="h2">
        {t('Basic')}
      </Heading>
      {!!oldId && (
        <Text mb="8" color="gray.700" fontSize="md">
          {t('Collaboration ID: ')} {oldId}
        </Text>
      )}
      <Stack mb="5.5rem" spacing="6">
        <Stack direction={{ base: 'column', sm: 'row' }} spacing="6">
          <Select
            isDisabled={!!oldId}
            type="category"
            {...register('category', { required: true })}
            isInvalid={!!errors.category}
            options={CATEGORY[language]}
            errorMessage={t('createEditProject@categoryErrorMessage')}
            placeholder={t('createEditProject@category')}
            label={t('createEditProject@projectCategory')}
          />
          <Select
            isDisabled={!!oldId}
            type="region"
            {...register('region', { required: true })}
            isInvalid={!!errors.region}
            errorMessage={t('createEditProject@regionErrorMessage')}
            placeholder={t('createEditProject@region')}
            label={t('createEditProject@projectRegion')}
            options={REGIONS}
          />
          <InputField
            isDisabled={!!oldId}
            isInvalid={!!errors.applicationTitle}
            label={t('createEditProject@projectTitle')}
            errorMessage={t('createEditProject@titleErrorMessage')}
            {...register('applicationTitle', { required: true })}
          />
          <TextareaField
            isInvalid={!!errors.summary}
            label={t('createEditProject@projectSummary')}
            errorMessage={t('createEditProject@projectSummaryRequired')}
            {...register('summary', { required: true })}
          />
          <TextareaField
            isInvalid={!!errors.problem}
            {...register('problem', { required: true })}
            label={t('createEditProject@projectProblem')}
            errorMessage={t('createEditProject@projectProblemRequired')}
          />
          <TextareaField
            isInvalid={!!errors.solution}
            label={t('createEditProject@projectSolution')}
            errorMessage={t('createEditProject@solutionErrorMessage')}
            {...register('solution', { required: true })}
          />
          <TextareaField
            isInvalid={!!errors.beneficiaries}
            label={t('createEditProject@beneficiaries')}
            errorMessage={t('createEditProject@beneficiariesErrorMessage')}
            {...register('beneficiaries', { required: true })}
          />
          <TextareaField
            isInvalid={!!errors.risks}
            label={t('createEditProject@risks')}
            errorMessage={t('createEditProject@risksErrorMessage')}
            {...register('risks', { required: true })}
          />
          <TextareaField
            isInvalid={!!errors.phases}
            label={t('createEditProject@phases')}
            errorMessage={t('createEditProject@phasesErrorMessage')}
            {...register('phases', { required: true })}
          />
          <TextareaField
            isInvalid={!!errors.objective}
            label={t('createEditProject@objective')}
            errorMessage={t('createEditProject@objectiveErrorMessage')}
            {...register('objective', { required: true })}
          />
          <TextareaField
            isInvalid={!!errors.keyResults}
            label={t('createEditProject@keyResults')}
            errorMessage={t('createEditProject@keyResultErrorMessage')}
            {...register('keyResults', { required: true })}
          />
          <TextareaField
            isInvalid={!!errors.otherPlayers}
            label={t('createEditProject@otherPlayers')}
            errorMessage={t('createEditProject@thisFieldIsRequired')}
            {...register('otherPlayers', { required: true })}
          />
        </Stack>
      </Stack>
    </Box>
  )
}

export default Basic
