import React from 'react'
import { InputGroup, Input, InputLeftElement } from '@chakra-ui/react'
import { ReactComponent as BlackSearch } from 'assets/icons/blackSearch.svg'

export default function SearchInput({
  onSearch,
  placeholder,
  isDisabled,
  searchRef,
}) {
  return (
    <InputGroup background="#F2F2F2" borderRadius="8px" px={8}>
      <InputLeftElement
        pointerEvents="none"
        children={<BlackSearch />}
        ml={6}
      />
      <Input
        isDisabled={isDisabled}
        background="transparent"
        color={'black'}
        ref={searchRef}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onSearch(e.target.value)
        }}
        placeholder={placeholder}
        _focus={{
          outline: 'none',
        }}
        _placeholder={{
          fontWeight: '400',
        }}
        border="none"
      />
    </InputGroup>
  )
}
