import { HStack, Img, Stack, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'

function Info({ logo, heading, description }) {
  const { t } = useTranslation()

  return (
    <HStack spacing={4}>
      <Img
        src={`/assets/images/${logo}`}
        alt="Logo"
        w="44px"
        objectFit="contain"
      />
      <Stack spacing={1}>
        <Text as="span" fontWeight="500">
          {t(heading)}
        </Text>
        <Text fontSize="md" color="gray.700">
          {t(description)}
        </Text>
      </Stack>
    </HStack>
  )
}

export default Info
