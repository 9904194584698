import React, { useState, createContext } from 'react'
import { Box } from '@chakra-ui/react'
import { useUpdateUser, useUser } from 'hooks'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import IntroducingExpertsPopup from 'components/IntroducingExpertsPopup'
import { useLocalStorage } from 'hooks'

const EXPERT_POPUP_STATE_KEY = 'isSeenExpertPopup'

const PopupContext = createContext()

const PopUpProvider = ({ children }) => {
  const { put, get, remove } = useLocalStorage()
  const { user } = useUser()
  const { updateUser } = useUpdateUser()
  const location = useLocation()

  const [showPopup, setShowPopup] = useState(false)
  const [popupQueue, setPopupQueue] = useState(false)
  const [dontShowPopup, setDontShowPopup] = useState(false)

  const updatePopupStatus = () => {
    setShowPopup(false)
    if (user.id !== 'guest') {
      updateUser({
        id: user.id,
        isSeenExpertPopup: true,
      })
    }
    put(EXPERT_POPUP_STATE_KEY, true)
  }

  const disabledPages = ['experts', 'donation']
  const isDisabledPage = disabledPages.some((path) =>
    location.pathname.includes(path)
  )
  useEffect(() => {
    if (isDisabledPage) {
      setShowPopup(false)
      return
    }

    if (user.id !== 'guest' && !user.isSeenExpertPopup) {
      remove(EXPERT_POPUP_STATE_KEY)
      setDontShowPopup(() => false)
      setShowPopup(true)
      setTimeout(() => {
        if (!popupQueue) {
          setShowPopup(true)
        }
        setPopupQueue(true)
      }, 30_000)
      return
    }
    const localIsSeenExpertPopup = Boolean(get(EXPERT_POPUP_STATE_KEY))
    if (!localIsSeenExpertPopup) {
      if (user.id === 'guest') {
        setTimeout(() => {
          if (!popupQueue) {
            setShowPopup(true)
          }
        }, 30_000)
        setPopupQueue(true)
      } else if (!user.isSeenExpertPopup) {
        setTimeout(() => {
          if (!popupQueue) {
            setShowPopup(true)
          }
          setPopupQueue(true)
        }, 30_000)
      }
    }
    if (localIsSeenExpertPopup || user.isSeenExpertPopup) {
      setDontShowPopup(() => true)
    }
  }, [user, location.pathname])

  return (
    <PopupContext.Provider value={{}}>
      {showPopup && !dontShowPopup && !isDisabledPage && (
        <Box
          position="fixed"
          right={{ md: '24px', base: 0 }}
          bottom={{ md: '24px', base: 0 }}
          zIndex="2000"
        >
          <IntroducingExpertsPopup updatePopupStatus={updatePopupStatus} />
        </Box>
      )}
      {children}
    </PopupContext.Provider>
  )
}

export default PopUpProvider
