import { notificationsByUserId } from 'graphql/queries'
import { updateNotification as update } from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api'
const client = generateClient()

const notificationService = () => {
  const listNotificationsByUserId = (variables) =>
    client.graphql({ query: notificationsByUserId, variables })

  const updateNotification = async (notification) =>
    await client.graphql({ query: update, variables: { input: notification } })

  return {
    listNotificationsByUserId,
    updateNotification,
  }
}

export default notificationService
