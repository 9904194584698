/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cloud_logic_custom: [
    {
      name: 'elastic',
      endpoint:
        'https://yt479ry424.execute-api.us-east-1.amazonaws.com/production',
      region: 'us-east-1',
    },
    {
      name: 'email',
      endpoint:
        'https://zg3c4idvf8.execute-api.us-east-1.amazonaws.com/production',
      region: 'us-east-1',
    },
    {
      name: 'export',
      endpoint:
        'https://yu6rxyue0d.execute-api.us-east-1.amazonaws.com/production',
      region: 'us-east-1',
    },
    {
      name: 'mailerLiteAPI',
      endpoint:
        'https://oerhip3f3g.execute-api.us-east-1.amazonaws.com/production',
      region: 'us-east-1',
    },
    {
      name: 'paymentAPI',
      endpoint:
        'https://xyhu1lz4q4.execute-api.us-east-1.amazonaws.com/production',
      region: 'us-east-1',
    },
    {
      name: 'sharingAPI',
      endpoint:
        'https://gjpxo8rwg2.execute-api.us-east-1.amazonaws.com/production',
      region: 'us-east-1',
    },
    {
      name: 'stripePaymentAPI',
      endpoint:
        'https://6s9syz03dj.execute-api.us-east-1.amazonaws.com/production',
      region: 'us-east-1',
    },
    {
      name: 'syncPrismicWithDDB',
      endpoint:
        'https://p875lejjhg.execute-api.us-east-1.amazonaws.com/production',
      region: 'us-east-1',
    },
    {
      name: 'user',
      endpoint:
        'https://5yif99yhee.execute-api.us-east-1.amazonaws.com/production',
      region: 'us-east-1',
    },
  ],
  aws_appsync_graphqlEndpoint:
    'https://rfwntto4hnfhtj2dhsot3ww3om.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-54wguonvl5gndiw7x3fruolrpy',
  aws_cognito_identity_pool_id:
    'us-east-1:3da5a487-97cb-438e-9bd0-f6023b02413e',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_zN2TEC0qr',
  aws_user_pools_web_client_id: '71c1ad35eoakfa14ac17400qg1',
  oauth: {
    domain: 'auth.rearmenia.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'https://rearmenia.com/',
    redirectSignOut: 'https://rearmenia.com/',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: ['FACEBOOK', 'GOOGLE'],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_user_files_s3_bucket:
    'rearmeniae4cf535a688d4c98a974b86967d6a64f162126-production',
  aws_user_files_s3_bucket_region: 'us-east-1',
}

export default awsmobile
