import { Text } from '@chakra-ui/react'
import { convert, thousandSeparator } from 'contexts/CurrencySettingsContext'

const AmountWithCurrency = ({
  currencyRate = 1,
  amount,
  currency = 'AMD',
  ...props
}) => (
  <Text minWidth="max-content" as="span" {...props}>
    {thousandSeparator(convert({ amount, currencyRate }), currency)}&nbsp;
    {currency}
  </Text>
)
export default AmountWithCurrency
