import {
  Button,
  Container,
  Flex,
  Heading,
  Select,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { MainLayout } from 'components/Layouts'
import LineClamp from 'components/LineClamp'
import ListView from 'components/ListView'
import Pagination from 'components/Pagination/Index'
import { useTranslation } from 'contexts/TranslationContext'
import { useContentFromElastic, useUpdateContentItem } from 'core/Content/hooks'
import { expertsDashboardQuery } from 'elastic/queries'
import { useQueryParams, useUpdateUser, useUser } from 'hooks'
import { Link, useNavigation } from 'pages'
import React, { useEffect, useState } from 'react'
import { FaEye } from 'react-icons/fa'
import { useModal } from 'providers/ModalProvider'
import Switch from 'components/Switch'
import amplitude from 'amplitude-js'

const PAGE_SIZE = 20

export default function ExpertsDashboard() {
  const { user } = useUser()
  const { navigationPush } = useNavigation()
  const params = useQueryParams()
  const [page, setPage] = useState(Number(params.get('page')) || 1)
  const [isChecked, setChecked] = useState(true)
  const { t, language } = useTranslation()
  const [filterStatus] = useState('pending')
  const { openModal } = useModal()
  const { mutate: updateExpert } = useUpdateContentItem()
  const { updateUser } = useUpdateUser()
  const {
    data: experts,
    isLoading,
    setFilters,
    filters,
  } = useContentFromElastic(
    expertsDashboardQuery({
      from: 0,
      size: PAGE_SIZE,
      isAdmin: user.isAdmin,
      status: filterStatus,
    }),
    filterStatus,
    page,
    params.has('search')
  )
  const onStatusChange = ({ id, userId, updatedStatus }) => {
    updateExpert(
      { id, expert_status: updatedStatus },
      {
        onSuccess() {
          if (userId) {
            updateUser(
              {
                cognitoId: user.cognitoId,
                id: user.id,
                isSeenExpertProfileNotification: false,
                isSeenExpertProfileModal: false,
              },
              {
                onSettled() {
                  window.location.reload()
                },
              }
            )
          }
        },
      }
    )
  }
  useEffect(() => {
    if (!user.isAdmin) {
      navigationPush('/')
    }
    if (!user.isAdmin) {
      return
    }
    const basePath = `/experts-dashboard`
    navigationPush(basePath + `?page=${page}`)
    setFilters({ ...filters, from: (page - 1) * PAGE_SIZE })
  }, [user, page])
  return (
    <MainLayout>
      <Container w="full" maxW="90%" mt={8} minH="calc(100vh - 112px)">
        <Flex mb={4}>
          <Switch
            label={'Show only pending experts'}
            onChange={(checked) => {
              setFilters(
                expertsDashboardQuery({
                  from: 0,
                  size: PAGE_SIZE,
                  isAdmin: user.isAdmin,
                  status: checked ? 'pending' : 'all',
                })
              )
            }}
            isChecked={isChecked}
            setChecked={setChecked}
            containerProps={{
              mr: 3,
            }}
          />
        </Flex>
        {isLoading ? (
          <Flex minH="520px" w="full" align="center" justifyContent="center">
            <Spinner color="blue.300" />
          </Flex>
        ) : (
          <>
            {experts?.total === 0 || !experts?.items.length ? (
              <Flex alignItems="center" justifyContent="center" w="full">
                <Heading as="h2" fontSize="4xl" color="blue.300">
                  {t('noResults')}
                </Heading>
              </Flex>
            ) : (
              <>
                {/* eslint-disable */}
                <ListView
                  head={[
                    t('dashboard@name'),
                    t('dashboard@phoneNumber'),
                    t('dashboard@email'),
                    t('Occupation'),
                    t('Date'),
                    t('dashboard@status'),
                    t('Edit'),
                    '',
                  ]}
                  height={experts?.items.length}
                  projects={experts?.items}
                  body={experts?.items.map((expert, index) => [
                    <Button
                      key={`${expert.firstName_en}_${index}`}
                      textTransform="unset"
                      onClick={() => {
                        openModal('expertCard', null, false, null, {
                          expert,
                          onStatusChange,
                        })
                      }}
                    >
                      <FaEye />

                      <LineClamp ml={2} Component={Text}>
                        {expert[`firstName_${language}`] ||
                          expert.firstName_en ||
                          expert.firstName_ru ||
                          expert.firstName_hy}{' '}
                        {expert[`lastName_${language}`] ||
                          expert.lastName_en ||
                          expert.lastName_ru ||
                          expert.lastName_hy}
                      </LineClamp>
                    </Button>,
                    <LineClamp Component={Text}>{expert.phone}</LineClamp>,
                    <LineClamp Component={Text}>{expert.email}</LineClamp>,
                    <LineClamp Component={Text}>
                      {expert[`title_${language}`] ||
                        expert.title_en ||
                        expert.title_ru ||
                        expert.title_hy}{' '}
                    </LineClamp>,
                    <LineClamp Component={Text}>
                      {expert.createdAt.split('T')[0]}
                    </LineClamp>,
                    <Select
                      value={expert.expert_status}
                      onChange={(e) => {
                        console.log(
                          e.target.value,
                          e.target.value === 'approved'
                        )
                        if (e.target.value === 'approved') {
                          amplitude.getInstance().logEvent('approved_expert')
                          console.log(
                            'sent event to amplitude(approved_expert)'
                          )
                        }
                        onStatusChange({
                          id: expert.id,
                          updatedStatus: e.target.value,
                          userId: expert.userId,
                        })
                      }}
                    >
                      <option value="pending">Pending</option>
                      <option value="approved">Approved</option>
                      <option value="rejected">Rejected</option>
                    </Select>,
                    <Link to={`/edit-expert/${expert.id}`}>Edit</Link>,
                  ])}
                />
                {/* eslint-disable */}
                <Pagination
                  total={experts?.total}
                  currentPage={page}
                  setCurrentPage={setPage}
                  size={PAGE_SIZE}
                />
              </>
            )}
          </>
        )}
      </Container>
    </MainLayout>
  )
}
