import { Button, Stack, Input, Flex } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'contexts/TranslationContext'

function Form({ isSecret, inputs, isLoading, onSubmit }) {
  const { t } = useTranslation()

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: inputs.reduce(
      (acc, input) => ({ ...acc, [input.fieldKey]: input.defaultValue }),
      {}
    ),
  })

  return (
    <Stack
      direction={inputs.length > 1 ? 'column' : 'row'}
      spacing={4}
      as="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      {inputs.map((input) => (
        <Input
          key={input.fieldKey}
          borderWidth={1}
          borderColor="gray.600"
          borderRadius="8px"
          isInvalid={!!errors.key}
          type={isSecret ? 'password' : 'text'}
          placeholder={t(input.placeholder)}
          {...register(input.fieldKey, { required: true })}
        />
      ))}

      <Flex justifyContent="flex-end">
        <Button
          type="submit"
          variant="green"
          minW="150px"
          minH="43px"
          px={1}
          isDisabled={isLoading}
          isLoading={isLoading}
        >
          {t('Save')}
        </Button>
      </Flex>
    </Stack>
  )
}

export default Form
