import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as CloseIcon } from 'assets/icons/closeX.svg'
import { ReactComponent as SearchIcon } from 'assets/icons/searchSmall.svg'

export default function SearchBar({
  onSearch,
  searchBarRef,
  defaultValue = '',
  someFiltersSelected,
  setLocalFilters,
  ...rest
}) {
  const { t } = useTranslation()
  return (
    <InputGroup {...rest}>
      <InputLeftElement children={<SearchIcon />} />
      {searchBarRef?.current?.value?.length && (
        <InputRightElement
          children={
            <IconButton
              onClick={() => {
                if (someFiltersSelected) {
                  onSearch({ target: { value: '' } })
                } else {
                  setLocalFilters({
                    isVolunteer: false,
                    categories: [],
                    directions: [],
                    countryCode: '',
                    languages: [],
                    hrsPerWeek: '',
                    search: null,
                    appliedFilters: 0,
                  })
                }
                searchBarRef.current.value = ''
              }}
            >
              <CloseIcon />
            </IconButton>
          }
        />
      )}
      <Input
        placeholder={t('experts@placeholder')}
        background="gray.300"
        _focus={{ borderColor: 'blue.200' }}
        onChange={onSearch}
        ref={searchBarRef}
        defaultValue={defaultValue}
      />
    </InputGroup>
  )
}
