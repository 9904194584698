import React, { useEffect } from 'react'
import { useTranslation } from 'contexts/TranslationContext'
import { useQueryParams, useUser } from 'hooks'
import { Stack, Heading, Text, Button, Flex } from '@chakra-ui/react'
import { Link, useNavigation } from 'pages'
import { useModal } from 'providers/ModalProvider'
import { CHOOSE_PEER_TO_PEER_FUNDRAISER_MODAL } from 'constants/modals'

const Join = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { openModal } = useModal()
  const { navigationPush, navigationReplace } = useNavigation()
  const queryParams = useQueryParams()

  const onChoosePeerToPeerFundraiser = (project) => {
    navigationPush(`/create-peer-to-peer-fundraiser/${project.id}/content`)
  }
  const openBirthdayFundraiserModal = () =>
    openModal(
      CHOOSE_PEER_TO_PEER_FUNDRAISER_MODAL,
      null,
      false,
      () => {},
      {
        onChoose: onChoosePeerToPeerFundraiser,
      },
      { maxWidth: '1096px', minH: '50vh' },
      false
    )

  useEffect(() => {
    if (queryParams.get('openModal') === CHOOSE_PEER_TO_PEER_FUNDRAISER_MODAL) {
      if (user?.id !== 'guest') {
        openBirthdayFundraiserModal()
      }
      navigationReplace('/')
    }
  }, [queryParams.get('openModal'), user])

  return (
    <Flex
      py="3rem"
      px="4"
      bg="#F5F7FB"
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Heading
        as="h1"
        mb="3"
        fontSize="4xl"
        fontWeight="600"
        textAlign="center"
      >
        {t('reArmenia is a collaboration platform')}
      </Heading>
      <Text
        maxW="900px"
        mb="5"
        textAlign="center"
        color="gray.700"
        fontSize="lg"
      >
        {t(
          'that gives an opportunity to Armenians all over the world to unite, put together their resources and make effective changes in Armenia by raising issues related to different spheres or coming up with proper solutions.'
        )}
      </Text>
      <Stack direction={{ base: 'column', md: 'row' }} spacing="3">
        {user.id !== 'guest' ? (
          <>
            <Button
              variant="blue"
              w="auto"
              onClick={() => openBirthdayFundraiserModal()}
            >
              {t('Start a Birthday Fundraiser')}
            </Button>
            <Link to="/pre-create-project">
              <Button
                variant="border"
                w="250px"
                id="homepage_create_button_guest"
              >
                {t('Create Project')}
              </Button>
            </Link>
          </>
        ) : (
          <>
            <Button
              onClick={() => {
                openModal(
                  'signUp',
                  `?openModal=${CHOOSE_PEER_TO_PEER_FUNDRAISER_MODAL}`
                )
              }}
              variant="blue"
              id="homepage_join_button"
            >
              {t('Start a Birthday Fundraiser')}
            </Button>
            <Button
              onClick={() => {
                openModal('signIn', '/pre-create-project')
              }}
              variant="border"
              id="homepage_create_button"
            >
              {t('Create Project')}
            </Button>
          </>
        )}
      </Stack>
    </Flex>
  )
}

export default Join
