import React from 'react'
import { Box } from '@chakra-ui/react'
import Navbar from 'components/Navbar'
import { useScreenSize } from 'contexts'

const MainLayout = ({
  children,
  overflow = 'hidden',
  disableLinks = false,
  onClick,
  ...rest
}) => {
  const { isMobile } = useScreenSize()
  return (
    <Box w="full" overflow={{ lg: overflow, base: 'hidden' }} {...rest}>
      {!isMobile && <Navbar disableLinks={disableLinks} onClick={onClick} />}
      {children}
    </Box>
  )
}

export default MainLayout
