const hashRepo = (hashService) => {
  const hash = (params) => hashService.hash(params)

  const verify = (params) => hashService.verify(params)

  return {
    hash,
    verify,
  }
}

export default hashRepo
