import React from 'react'
import { Box, Container, Text, Img, Flex, Button } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useQueryParams } from 'hooks'
import { Link } from 'pages'
import { SocialMediaLinks } from './SocialMediaLinks'
import { MainLayout } from 'components/Layouts'

const ThankYouSignUp = () => {
  const { t } = useTranslation()
  const params = useQueryParams()
  return (
    <MainLayout>
      <Container pt="10" maxW="6xl">
        <Img src="/assets/images/thankYou.jpg" width="300px" minH="218px" />
        {!!params.get('firstName') && (
          <Box mb="2rem" fontSize={{ base: '1.5rem', sm: '2rem' }}>
            {t('Dear')} {params.get('firstName')}
          </Box>
        )}

        <Text fontSize="lg" mb="5">
          {t('thankyou@signUp')}
        </Text>

        <Flex direction="column" mb="5">
          <Text fontSize="lg" pb="5">
            {t('thankyou@check')}
          </Text>
          <Link to="/fundraisers?type=oneTime">
            <Button
              borderRadius="8px"
              boxShadow="none"
              minHeight="40px"
              variant="blue"
            >
              {t('thankyou@buttonProjects')}
            </Button>
          </Link>
        </Flex>
        <Text fontSize="lg" mb="5">
          {t('thankyou@follow')}
        </Text>
        <SocialMediaLinks />
      </Container>
    </MainLayout>
  )
}

export default ThankYouSignUp
