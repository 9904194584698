const initiativeRepo = (initiativeService) => {
  const deleteInitiative = async (initiative) =>
    initiativeService.deleteInitiative(initiative)

  const createInitiative = async (project) =>
    initiativeService.createInitiative(project)

  const addCollaborator = (params) => initiativeService.addCollaborator(params)

  const setImplementor = (params) => initiativeService.addCollaborator(params)

  const updateStatus = (params) => initiativeService.updateStatus(params)

  const updateInitiative = async (initiative) =>
    initiativeService.updateInitiative(initiative)

  const retrieveInitiative = (id) => initiativeService.retrieveInitiative(id)

  const getInitiatives = async (filter) =>
    initiativeService.getInitiatives(filter)

  const searchInitiatives = async (condition) =>
    initiativeService.searchInitiatives(condition)

  const collaboratorRequest = (params) =>
    initiativeService.collaboratorRequest(params)

  return {
    createInitiative,
    addCollaborator,
    updateStatus,
    updateInitiative,
    retrieveInitiative,
    getInitiatives,
    collaboratorRequest,
    setImplementor,
    deleteInitiative,
    searchInitiatives,
  }
}

export default initiativeRepo
