import {
  createTransaction as createTransactionMutation,
  updateTransaction as updateTransactionMutation,
} from 'graphql/mutations'
import {
  getTransaction as getTransactionQuery,
  listTransactions as listTransactionsQuery,
  transactionsByDate,
  transactionsByGiftCardCode as transactionsByGiftCardCodeQuery,
  transactionsByTopProjectDonation,
  transactionsByEmailDate as transactionsByEmailDateQuery,
  transactionsByProjectIdEmail,
  transactionsByPeerProjectId,
} from 'graphql/queries'
import { generateClient } from 'aws-amplify/api'
const client = generateClient()

const transactionService = () => {
  const createTransaction = (input) =>
    client.graphql({ query: createTransactionMutation, variables: { input } })
  const updateTransaction = (input) =>
    client.graphql({ query: updateTransactionMutation, variables: { input } })

  const getTransaction = (id) =>
    client.graphql({ query: getTransactionQuery, variables: { id } })

  const listTransactions = (variables) =>
    client.graphql({ query: listTransactionsQuery, variables })

  const listTransactionsByGiftCardCode = (variables) =>
    client.graphql({ query: transactionsByGiftCardCodeQuery, variables })

  const listTransactionsByDate = (variables) =>
    client.graphql({ query: transactionsByDate, variables })

  const listTransactionsByPeerProjectId = (variables) =>
    client.graphql({ query: transactionsByPeerProjectId, variables })

  const listTransactionsByTopDonation = (variables) =>
    client.graphql({ query: transactionsByTopProjectDonation, variables })

  const getTransactionsByEmailDate = (variables) =>
    client.graphql({ query: transactionsByEmailDateQuery, variables })

  const getUserTransactionsByProjectIdEmail = async (variables) => {
    const response = await client.graphql({
      query: transactionsByProjectIdEmail,
      variables,
    })

    return response.data.transactionsByProjectIdEmail
  }

  return {
    createTransaction,
    getTransaction,
    listTransactions,
    listTransactionsByGiftCardCode,
    listTransactionsByDate,
    listTransactionsByTopDonation,
    getTransactionsByEmailDate,
    updateTransaction,
    getUserTransactionsByProjectIdEmail,
    listTransactionsByPeerProjectId,
  }
}

export default transactionService
