import { useMutation } from 'react-query'
import { authUseCase } from '../../core/factories'

const useSignUp = () => {
  const {
    mutate: signUp,
    isLoading,
    data,
    error,
  } = useMutation(authUseCase.signUp)

  return {
    signUp,
    isLoading,
    data,
    error,
  }
}

export default useSignUp
