import { Heading, Stack } from '@chakra-ui/react'
import { CATEGORY } from '_constants'
import { InputField } from 'components/InputField'
import { Select } from 'components/Select'
import { ReactComponent as ChainIcon } from 'assets/icons/chain.svg'
import { useTranslation } from 'contexts/TranslationContext'
import Files from './Files'
import BottomBar from '../BottomBar'

export default function NonProfitApplicationForm({
  onSubmit,
  register,
  errors,
}) {
  const { t, language } = useTranslation()

  return (
    <form onSubmit={onSubmit}>
      <Heading fontSize="5xl" as="h1">
        {t('createEditProject@projectApplication')}
      </Heading>
      <Stack my="6" spacing="6">
        <Heading as="h2" fontSize="xl">
          {t('Basic')}
        </Heading>
        <InputField
          isInvalid={!!errors.organizationName}
          label={t('Company name and legal form')}
          errorMessage={t('createEditProject@thisFieldIsRequired')}
          {...register('organizationName', { required: true })}
        />
        <InputField
          isInvalid={!!errors.TIN}
          label={t('TIN (tax identification number)')}
          errorMessage={t('createEditProject@thisFieldIsRequired')}
          maxLength={80}
          type="number"
          pattern="[0-9+]"
          {...register('TIN', {
            required: true,
            maxLength: 80,
          })}
        />
        <Select
          type="category"
          {...register('category', { required: true })}
          isInvalid={!!errors.category}
          options={CATEGORY[language]}
          errorMessage={t('createEditProject@categoryErrorMessage')}
          placeholder={t('createEditProject@category')}
          label={t('createEditProject@projectCategory')}
        />
        <InputField
          isInvalid={!!errors.summary}
          label={t("Description of the organization's activities")}
          errorMessage={t('createEditProject@thisFieldIsRequired')}
          maxLength={80}
          {...register('summary', {
            required: true,
            maxLength: 80,
          })}
        />
        <Heading mt={3} as="h2" fontSize="xl">
          {t('details')}
        </Heading>
        <InputField
          isInvalid={!!errors.contactFullName}
          label={t('Contact person (name, surname)')}
          errorMessage={t('createEditProject@thisFieldIsRequired')}
          maxLength={80}
          {...register('contactFullName', { required: true })}
        />
        <InputField
          isInvalid={!!errors.phone}
          label={t('Phone number')}
          errorMessage={t('createEditProject@thisFieldIsRequired')}
          type="number"
          pattern="[0-9+]"
          {...register('phone', { required: true })}
        />
        <InputField
          isInvalid={!!errors.email}
          label={t('Email')}
          errorMessage={t(errors?.email?.message)}
          maxLength={80}
          {...register('email', {
            required: {
              message: 'createEditProject@thisFieldIsRequired',
              value: true,
            },
            pattern: {
              message: 'createEditInitiative@emailError',
              value: /\S+@\S+\.\S+/,
            },
          })}
        />
        <InputField
          label={t(
            'If you have any media coverage about the organization, please attach them here.'
          )}
          Icon={ChainIcon}
          maxLength={80}
          {...register('mediaLink')}
        />
        <Files />
        <InputField
          label={t('Helpful Links (Website, Social Media Pages, etc.)')}
          Icon={ChainIcon}
          maxLength={80}
          {...register('links')}
        />
      </Stack>
      <BottomBar />
    </form>
  )
}
