import { useState, useMemo } from 'react'
import { Flex, Text, Box, Checkbox, Button, Stack } from '@chakra-ui/react'
import { InputField } from 'components/InputField'
import { useCollabRequest, useInitiative, useUser } from 'hooks'
import { useHistory } from 'react-router-dom'
import { Select } from 'components/Select'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as Back } from '../../assets/icons/closeBlack.svg'
import useCustomToast from 'hooks/useCustomToast'

const MobileInitiative = () => {
  const toast = useCustomToast()
  const { t } = useTranslation()
  const { user } = useUser()
  const { collaboratorRequest, isLoading } = useCollabRequest()
  const { initiative } = useInitiative()

  const [changeEmail, setChangeEmail] = useState(user.id === 'guest')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState('')
  const [description, setDescription] = useState('')

  const history = useHistory()

  const options = useMemo(() => {
    if (!initiative) return []
    if (initiative.implementor?.id) {
      setRole('collaborator')
      return ['collaborator']
    }
    return ['collaborator', 'implementor']
  }, [initiative])

  const onSend = () => {
    const fullname = `${user.firstName} ${user.lastName}`
    const addresses = [initiative?.contactEmail]
    if (initiative?.implementor?.email)
      addresses.push(initiative.implementor.email)

    collaboratorRequest(
      {
        userId: user.id,
        initiativeId: initiative.id ?? '',
        addresses,
        contactEmail: changeEmail && email ? email : user.email ?? '',
        fullname,
        isCollab: role === 'collaborator',
        description: description,
      },
      {
        onSuccess: () => {
          toast({
            title: 'Requested successfully',
            status: 'success',
            isClosable: true,
            duration: 5000,
          })
          history.goBack()
        },
      }
    )
  }

  if (!initiative) return <></>

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      py="8"
      position="relative"
      px="4"
    >
      <Box
        onClick={() => history.goBack()}
        top="40px"
        right="25px"
        position="absolute"
      >
        <Back height="20px" width="20px" />
      </Box>
      <Text mb="4" textAlign="center" color="gray.700" fontSize="sm">
        {t('initiative@receiveResponseDetails')}
        <Box color="gray.800" fontWeight="semibold" as="span">
          {' '}
          {user.email}
        </Box>
      </Text>
      <Checkbox
        isDisabled={user.id === 'guest'}
        isChecked={changeEmail}
        onChange={() => setChangeEmail(!changeEmail)}
        mb="6"
      >
        <Box fontSize="sm">{t('initiative@changeYourEmail')}</Box>
      </Checkbox>
      <Stack spacing="4">
        {changeEmail && (
          <InputField onChange={(e) => setEmail(e.target.value)} />
        )}
        <Select
          placeholder="Select option"
          display={{ base: 'block', sm: 'none' }}
          value={role}
          onChange={(e) => setRole(e.target.value.toLowerCase())}
          options={options}
          label={t('initiative@chooseYourRole')}
        />
        {role && (
          <InputField
            textarea
            resize="none"
            rows={8}
            maxRows={9}
            onChange={(e) => setDescription(e.target.value)}
            label={`${t('initiative@howDoYouWantToBeInvolved')} ${
              role === 'collaborator' ? 'collaborate' : 'implement'
            }`}
          />
        )}
        <Button
          isDisabled={(user.id === 'guest' && !email) || !role}
          isLoading={isLoading}
          w="full"
          variant="blue"
          onClick={() => onSend()}
        >
          Send
        </Button>
      </Stack>
      {/* {changeEmail && (
                <InputField
                    onChange={(e) => setEmail(e.target.value)}
                    mb="4"
                    label="New email" />
            )}
            <SelectField
                type="role"
                display={{base: 'none', sm: 'block'}}
                onChange={(e) => setRole(e)}
                options={options}
                placeholder="Select option"
                label={t('initiative@chooseYourRole')} />
            <Select
                type="role"
                display={{base: 'block', sm: 'none'}}
                onChange={(e) => setRole(e)}
                options={options}
                placeholder={t('initiative@chooseYourRole')}
                label={t('initiative@chooseYourRole')} /> */}
    </Flex>
  )
}

export default MobileInitiative
