import React, { useEffect, useState } from 'react'
import {
  Tabs as ChakraTabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
} from '@chakra-ui/react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'contexts/TranslationContext'
import { useQueryParams } from 'hooks'
import Story from '../Story'
import Update from '../Update'
import Donations from './Donations'
import { thousandSeparator } from 'contexts/CurrencySettingsContext'
import Report from '../Report'
import NoScrollbar from 'components/NoScrollbar'

const Tabs = ({
  project,
  updates,
  isUpdatesLoading,
  tabsSectionRef,
  reports,
  isReportsLoading,
  peerProject,
}) => {
  const { t } = useTranslation()
  const [tabIndex, setTabIndex] = useState(0)
  const tabs = ['story', 'updates', 'donors', 'reports']
  const queryParams = useQueryParams()
  const history = useHistory()
  const { pathname } = useLocation()

  const reportsUpdatesCondition = [
    'live',
    'launching_soon',
    'closed',
    'terminated',
    'ended',
    'implemented',
    'waiting_for_transfer_confirmation',
  ].includes(project.status)

  const handleTabsChange = (index) => {
    console.log(tabs[index])
    if (queryParams.get('tab') !== tabs[index]) {
      history.replace(`${pathname}?tab=${tabs[index]}`)
    }
    setTabIndex(index)
  }
  useEffect(() => {
    const tab = queryParams.get('tab')
    if (tab) {
      setTabIndex(tabs.indexOf(tab))
      return
    }
    if (
      project?.status === 'ended' ||
      project?.status === 'closed' ||
      project?.status === 'terminated'
    ) {
      setTabIndex(tabs.indexOf('updates'))
      history.replace(`${pathname}?tab=updates`)
      return
    }
    if (project?.status === 'implemented') {
      setTabIndex(tabs.indexOf('reports'))
      history.replace(`${pathname}?tab=reports`)
      return
    }
    history.replace(`${pathname}?tab=story`)
  }, [project])

  return (
    <ChakraTabs
      mt="10"
      maxW="735px"
      overflowX="hidden"
      index={tabIndex}
      onChange={handleTabsChange}
      ref={tabsSectionRef}
    >
      <Box>
        <NoScrollbar
          borderColor="border.100"
          borderBottom="1px"
          pb="2px"
          overflow="scroll"
          Component={TabList}
        >
          <Tab
            position="relative"
            _active={{ backgroundColor: 'transparent' }}
            _focus={{ outline: 'none' }}
            _selected={{
              borderBottom: '2px',
              color: 'blue.400',
              borderColor: 'blue.400',
              fontWeight: 'bold',
            }}
            py="4"
            flex="1"
          >
            {t('story')}
          </Tab>
          {reportsUpdatesCondition && (
            <Tab
              _active={{ backgroundColor: 'transparent' }}
              _focus={{ outline: 'none' }}
              _selected={{
                borderBottom: '2px',
                color: 'blue.400',
                borderColor: 'blue.400',
                fontWeight: 'bold',
              }}
              flex="1"
            >
              {t('updates')}
            </Tab>
          )}
          {!!project.isProject && (
            <Tab
              _active={{ backgroundColor: 'transparent' }}
              _focus={{ outline: 'none' }}
              _selected={{
                borderBottom: '2px',
                color: 'blue.400',
                borderColor: 'blue.400',
                fontWeight: 'bold',
              }}
              py="4"
              flex="1"
            >
              {t('Donors')}
            </Tab>
          )}
          {reportsUpdatesCondition && (
            <Tab
              _active={{ backgroundColor: 'transparent' }}
              _focus={{ outline: 'none' }}
              _selected={{
                borderBottom: '2px',
                color: 'blue.400',
                borderColor: 'blue.400',
                fontWeight: 'bold',
              }}
              flex="1"
            >
              {t('reports')}
            </Tab>
          )}
        </NoScrollbar>
      </Box>
      <TabPanels pt={tabIndex !== 0 ? '13' : 0}>
        <TabPanel px={0} pt={0}>
          <Story project={project} peerProject={peerProject} />
        </TabPanel>
        {reportsUpdatesCondition && (
          <TabPanel px="5">
            <Update
              project={project}
              updates={updates}
              isUpdatesLoading={isUpdatesLoading}
            />
          </TabPanel>
        )}
        {project.isProject && (
          <TabPanel px="5">
            <Donations
              project={project}
              thousandSeparator={thousandSeparator}
            />
          </TabPanel>
        )}
        {reportsUpdatesCondition && (
          <TabPanel px="5">
            <Report
              project={project}
              reports={reports}
              isReportsLoading={isReportsLoading}
            />
          </TabPanel>
        )}
      </TabPanels>
    </ChakraTabs>
  )
}

export default Tabs
