import './styles.scss'
import React, { useEffect } from 'react'
import {
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
} from '@stripe/react-stripe-js'
import { Checkbox, Flex, Radio, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as CreditCard } from 'assets/icons/credit-card.svg'
import PaymentGuestUserFormDonationPage from 'components/PaymentGuestUserFormDonationPage'

const CheckoutForm = ({
  setPaymentMethod,
  getValues,
  setValue,
  clearErrors,
  isRecurring,
  user,
  paymentMethod,
  setTokenId,
  setNewStripeCard,
  register,
  errors,
  isInvalid,
}) => {
  const elements = useElements()
  const isSelected = paymentMethod === 'stripe'
  const { t } = useTranslation()
  const stripe = useStripe()

  const paymentElement = elements?.getElement('payment')

  useEffect(() => {
    if (!paymentElement) {
      return
    }
    if (paymentMethod !== 'stripe') {
      paymentElement.collapse()
    }
  }, [paymentMethod, paymentElement])

  const handleCardElementChange = async (event) => {
    if (event.complete) {
      const cardElement = elements.getElement(CardNumberElement)
      const { token } = await stripe.createToken(cardElement)
      setTokenId(token?.id)
      if (token?.id) {
        clearErrors('stripe')
      }
      setNewStripeCard(token?.card)
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          email: getValues('email'),
          name: `${getValues('firstName')} ${getValues('lastName')}`,
        },
      })
      setPaymentMethod(paymentMethod)
    }
  }

  return (
    <Flex
      w="full"
      p="22px"
      borderRadius={8}
      borderWidth="1px"
      borderColor={
        isInvalid && isSelected
          ? 'red.400'
          : isSelected
          ? 'blue.400'
          : 'border.500'
      }
      position="relative"
      cursor={isSelected ? 'auto' : 'pointer'}
      direction="column"
      onClick={() => {
        setValue('paymentMethod', 'stripe')
      }}
      h={isSelected ? 'auto' : '64px'}
      justify="center"
    >
      <Flex w="full" alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <CreditCard style={{ height: '28px' }} />
          <Text ml={4} color="black.100" fontSize="md">
            {t('donation@CreditOrDebitCard')}
          </Text>
        </Flex>
        <Flex alignItems="center">
          <Radio value="1" isChecked={isSelected}></Radio>
        </Flex>
      </Flex>

      {isSelected && (
        <Flex
          direction="column"
          sx={{
            '& .StripeElement': {
              width: 'full',
            },
          }}
          gap={3}
        >
          {user.id === 'guest' && (
            <PaymentGuestUserFormDonationPage
              register={register}
              errors={errors}
              isRecurring={isRecurring}
              isStripe
              emailErrorMessage={t(errors?.email?.message)}
            />
          )}
          <SingleInputWithLabel label={t('donation@cardNumber')}>
            <CardNumberElement
              onChange={handleCardElementChange}
              id="card-number-element"
              options={cardElementOptions}
            />
          </SingleInputWithLabel>
          <Flex gap={3}>
            <SingleInputWithLabel flex={1} label="MM/YY">
              <CardExpiryElement
                onChange={handleCardElementChange}
                id="card-element"
                options={cardElementOptions}
              />
            </SingleInputWithLabel>
            <SingleInputWithLabel flex={1} label="CVV">
              <CardCvcElement
                id="card-element"
                onChange={handleCardElementChange}
                options={cardElementOptions}
              />
            </SingleInputWithLabel>
          </Flex>
          {isRecurring ? (
            <Text fontSize="md">
              <b>{t('donation@cardWillBeSaved').split('(bold)')[0]}</b>
              {t('donation@cardWillBeSaved').split('(bold)')[1]}
            </Text>
          ) : (
            <>
              {user.id !== 'guest' && (
                <Checkbox {...register('attachCard')}>
                  <Text fontSize="md">
                    <b>
                      {
                        t('donation@attachCardForFutureDonations').split(
                          '(bold)'
                        )[0]
                      }
                    </b>
                    {
                      t('donation@attachCardForFutureDonations').split(
                        '(bold)'
                      )[1]
                    }
                  </Text>
                </Checkbox>
              )}
            </>
          )}
        </Flex>
      )}
    </Flex>
  )
}

export default CheckoutForm

const cardElementOptions = {
  style: {
    base: {
      fontSize: '16px',
      fontSmoothing: 'antialiased',
    },
    invalid: {
      iconColor: '#FFC7EE',
      color: '#FFC7EE',
    },
  },
  hidePostalCode: true,
  disableLink: true,
  iconStyle: 'default',
  placeholder: '',
  disabled: false,
}

const SingleInputWithLabel = ({ children, label, ...props }) => (
  <Flex direction="column" mt={2} {...props}>
    <Text fontSize="md" color="gray.700" mb={2}>
      {label}
    </Text>
    <Flex
      h={{ base: '38px', sm: '48px' }}
      w="full"
      borderRadius={8}
      borderColor="border.400"
      borderWidth="1px"
      borderStyle="solid"
      alignItems="center"
      px={3}
    >
      {children}
    </Flex>
  </Flex>
)
