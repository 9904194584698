import React, { useEffect } from 'react'
import { Flex, Spinner } from '@chakra-ui/react'
import { useNavigation } from 'pages'
import {
  useCustomToast,
  useQueryParams,
  useRetrieveAccount,
  useUpdateProject,
} from 'hooks'

const CheckStripeConnection = () => {
  const toast = useCustomToast()
  const params = useQueryParams()
  const { navigationPush } = useNavigation()
  const projectId = params.get('projectId')
  const accountId = params.get('accountId')
  const { updateProject } = useUpdateProject()
  const { mutate: retrieveAccount } = useRetrieveAccount()
  useEffect(() => {
    retrieveAccount(
      { accountId },
      {
        onSuccess(res) {
          console.log('SUCCESS RETRIEVE', res)
          const detailsSubmittedStatus = res.account.details_submitted
          if (detailsSubmittedStatus) {
            updateProject(
              { id: projectId, isStripeOnBoardingDone: true },
              {
                onSuccess: () => {
                  toast({
                    title: 'Success',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                  })
                  navigationPush('/')
                },
                onError: () => {
                  toast({
                    title: 'Something went wrong',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                  })
                },
              }
            )
          } else {
            toast({
              title: "You didn't fill in all fields",
              status: 'error',
              duration: 3000,
              isClosable: true,
            })
            navigationPush(`/edit-fundraisers/${projectId}/content`)
          }
        },
        onError(res) {
          console.log('ERROR', res)
        },
      }
    )
  }, [])

  return (
    <Flex
      width="100%"
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner size="lg" />
    </Flex>
  )
}

export default CheckStripeConnection
