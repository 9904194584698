import React from 'react'
import { Box } from '@chakra-ui/react'

export const DisabledBox = (props) => {
  return (
    <Box
      w="full"
      h="full"
      pos="absolute"
      top={0}
      bottom={0}
      right={0}
      left={0}
      zIndex={500}
      bg="#ffffffb3"
      cursor="not-allowed"
      {...props}
    />
  )
}
