import {
  listReports as listProjectReports,
  reportsByProjectId,
  reportsByHasImage as reportsByHasImageQuery,
} from '../../graphql/queries'
import {
  createReport as createProjectReport,
  deleteReport as deleteProjectReport,
  updateReport as updateProjectReport,
} from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api'
const client = generateClient()

const reportService = () => {
  const createReport = async (input) => {
    await client.graphql({
      query: createProjectReport,
      variables: {
        input: { ...input, type: 'report' },
      },
    })
  }

  const deleteReport = async (input) => {
    await client.graphql({ query: deleteProjectReport, variables: { input } })
  }

  const editReport = async (input) => {
    await client.graphql({ query: updateProjectReport, variables: { input } })
  }

  const listReports = async () =>
    client.graphql({ query: listProjectReports, variables: { limit: 10 } })

  const listReportsByDate = () =>
    client.graphql({
      query: reportsByHasImageQuery,
      variables: {
        limit: 10,
        sortDirection: 'DESC',
        hasImage: 'true',
      },
    })

  const getReportsByProjectId = async (variables) => {
    const projects = await client.graphql({
      query: reportsByProjectId,
      variables,
    })
    return projects
  }

  return {
    createReport,
    deleteReport,
    editReport,
    listReports,
    getReportsByProjectId,
    listReportsByDate,
  }
}

export default reportService
