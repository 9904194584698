import React from 'react'
import Space from './Space'
import { NotificationList } from 'components/Notification/NotificationList'
import { useTranslation } from 'contexts/TranslationContext'

const Notifications = () => {
  const { t } = useTranslation()
  return (
    <Space title={t('profile@notifications')} px={{ base: 0, sm: '32px' }}>
      <NotificationList />
    </Space>
  )
}

export default Notifications
