//** Icons
import { ReactComponent as X } from 'assets/icons/x.svg'
import { ReactComponent as Linkedin } from 'assets/icons/linkedin_light.svg'
import { ReactComponent as Globe } from 'assets/icons/globe.svg'
import { ReactComponent as FB } from 'assets/icons/fb_light.svg'

export const GetSocialMediaIcon = ({ url }) => {
  if (url.includes('facebook.com') || url.includes('fb.com')) {
    return <FB />
  }
  if (url.includes('linkedin.com')) {
    return <Linkedin />
  }
  if (url.includes('x.com') || url.includes('twitter.com')) {
    return <X />
  }
  return <Globe />
}
