import {
  Box,
  Button,
  Flex,
  IconButton,
  InputGroup,
  InputRightElement,
  Popover,
  Text,
} from '@chakra-ui/react'
import debounce from 'lodash.debounce'
import { InputField } from 'components/InputField'
import { PopoverWrapper } from './PopoverWrapper'

//** Icons
import { ReactComponent as BigPlus } from 'assets/icons/big_plus.svg'
import { ReactComponent as SmallPlus } from 'assets/icons/small_plus.svg'
import { ReactComponent as Social } from 'assets/icons/social.svg'
import { ReactComponent as Delete } from 'assets/icons/round_close.svg'
import { ReactComponent as Plus } from 'assets/icons/plus.svg'
import { useEffect, useMemo, useState } from 'react'

const Header = ({ isEmptyFields, tt, language }) =>
  isEmptyFields ? (
    <>
      <Flex align="center">
        <Social />
        <Text ml={3} fontSize="md" color="gray.800">
          {tt('Social media links', language)}
        </Text>
      </Flex>
      <SmallPlus />
    </>
  ) : (
    <BigPlus />
  )

export const AddSocialsPopover = ({
  tt,
  isEmptyFields,
  socials,
  removeSocial,
  updateSocial,
  onAddField,
  isInvalid,
  trigger,
  LIMIT,
  isDisabled,
  language,
  register,
}) => {
  const [isAddDisabled, setIsDisabled] = useState(
    socials?.length === 1 && socials?.[0]?.url === ''
  )

  useEffect(() => {
    setIsDisabled(socials?.length === 1 && socials?.[0]?.url === '')
  }, [socials])

  const onAdd = (onClose) => {
    socials.forEach((field, index) => {
      if (field.url) {
        updateSocial(index, { ...field, added: true })
      }
    })
    trigger('socials')
    onClose()
  }
  const debouncedChangeHandler = useMemo(
    () =>
      debounce((value) => setIsDisabled(!value && socials.length === 1), 300),
    [socials]
  )

  return (
    <Popover placement="bottom">
      {({ isOpen, onClose }) => (
        <PopoverWrapper
          w={isEmptyFields ? 'full' : 12}
          contentProps={{
            minW: { base: '277px', sm: 'max-content' },
            width: { sm: '277px' },
          }}
          justify={isEmptyFields ? 'space-between' : 'center'}
          isOpen={isOpen}
          isInvalid={isInvalid}
          isDisabled={isDisabled}
          Header={() => (
            <Header tt={tt} isEmptyFields={isEmptyFields} language={language} />
          )}
          wrapperProps={{ flex: isEmptyFields ? 1 : 'unset' }}
        >
          <Text fontSize="lg" color="gray.800" fontWeight={700} mb={2}>
            {tt('Add a link', language)} ({socials.length}/{LIMIT})
          </Text>
          {socials.map((field, index) => (
            <InputGroup key={field.id}>
              <InputField
                mb={3}
                {...register(`socials.${index}.url`, {
                  onBlur: (e) => {
                    updateSocial(index, {
                      ...field,
                      url: e.target.value,
                    })
                  },
                })}
                onChange={(e) => debouncedChangeHandler(e.target.value)}
                defaultValue={field.url}
              />
              {socials.length > 1 && (
                <InputRightElement>
                  <IconButton
                    zIndex={4}
                    pointerEvents="all"
                    onMouseUp={() => removeSocial(index)}
                  >
                    <Delete />
                  </IconButton>
                </InputRightElement>
              )}
              <input
                style={{ display: 'none' }}
                type="checkbox"
                checked={field.added}
                {...register(`socials.${index}.added`)}
              />
            </InputGroup>
          ))}

          {socials.length < LIMIT && (
            <Button
              mb={4}
              fontSize="md"
              color="gray.800"
              onClick={onAddField}
              textTransform="none"
              alignSelf="flex-start"
            >
              <Box mr={2}>
                <Plus width="24px" />
              </Box>
              {tt('Add another link', language)}
            </Button>
          )}
          <Button
            w="full"
            variant="blue"
            onClick={() => onAdd(onClose)}
            isDisabled={isAddDisabled}
          >
            {tt('Add', language)}
          </Button>
        </PopoverWrapper>
      )}
    </Popover>
  )
}
