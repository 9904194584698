import { createContent, deleteContent, updateContent } from 'graphql/mutations'
import {
  getContent as getContentQuery,
  contentBySk,
  expertByEmail,
  expertBySlug,
} from 'graphql/queries'
import { generateClient } from 'aws-amplify/api'
const client = generateClient()

const AppSyncService = () => {
  const getExperts = async (variables) => {
    const appSyncResponse = await client.graphql({
      query: contentBySk,
      variables: {
        pk: 'expert',
        limit: variables.limit,
        nextToken: variables.nextToken,
      },
    })
    return appSyncResponse.data.contentBySk
  }
  const getExpertByEmail = async (email) => {
    const appSyncResponse = await client.graphql({
      query: expertByEmail,
      variables: {
        email,
      },
    })
    return appSyncResponse.data.expertByEmail.items[0]
  }
  const getNeeds = async () => {
    const appSyncResponse = await client.graphql({
      query: contentBySk,
      variables: { pk: 'need' },
    })
    return appSyncResponse.data.contentBySk.items
  }

  const getItemById = async (id) => {
    const appSyncResponse = await client.graphql({
      query: getContentQuery,
      variables: { id },
    })
    return appSyncResponse.data.getContent
  }
  const getItemBySlug = async (slug) => {
    const appSyncResponse = await client.graphql({
      query: expertBySlug,
      variables: { slug },
    })
    return appSyncResponse.data.expertBySlug.items[0]
  }

  const getGiftCard = async () => {
    const appSyncResponse = await client.graphql({
      query: contentBySk,
      variables: { pk: 'gift-card' },
    })
    return appSyncResponse.data.contentBySk.items
  }

  const getHomepageBlog = async () => {
    const appSyncResponse = await client.graphql({
      query: contentBySk,
      variables: { pk: 'blog' },
    })
    return appSyncResponse.data.contentBySk.items
  }

  const createContentItem = async (input) => {
    const appSyncResponse = await client.graphql({
      query: createContent,
      variables: { input },
    })
    return appSyncResponse
  }
  const updateContentItem = async (input) => {
    if (input.pk === 'expert') {
      input.spheres = input.spheres.map(({ title_en, title_hy, title_ru }) => ({
        title_en: title_en?.trim() || null,
        title_hy: title_hy?.trim() || null,
        title_ru: title_ru?.trim() || null,
      }))
    }

    const appSyncResponse = await client.graphql({
      query: updateContent,
      variables: { input },
    })
    return appSyncResponse
  }

  const deleteContentItem = async (input) => {
    const appSyncResponse = await client.graphql({
      query: deleteContent,
      variables: { input },
    })
    return appSyncResponse
  }

  return {
    getExperts,
    getExpertByEmail,
    getNeeds,
    getItemById,
    getGiftCard,
    getHomepageBlog,
    getItemBySlug,
    createContentItem,
    updateContentItem,
    deleteContentItem,
  }
}

export default AppSyncService
