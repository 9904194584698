import React from 'react'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { Link, useNavigation } from 'pages'
import { LANG_EN_SHORT, useTranslation } from 'contexts/TranslationContext'
import amplitude from 'amplitude-js'
import { useModal } from 'providers/ModalProvider'
import { useUser } from 'hooks'
import { useScreenSize } from 'contexts'

export default function Header() {
  const { isMobile } = useScreenSize()
  const { t, language } = useTranslation()
  const { openModal } = useModal()
  const { user } = useUser()
  const { navigationPush } = useNavigation()

  const onNeedExpertClick = () => {
    if (isMobile) {
      return
    }
    amplitude.getInstance().logEvent('Apply expert page button click')
    console.log('sent event to amplitude: Apply expert page button click')
    if (user.id === 'guest') {
      localStorage.setItem('redirect', '/profile/expert/card')
      !isMobile && openModal('signIn', null, false, () => {})
    } else {
      navigationPush('/profile/expert/card')
    }
  }

  return (
    <Flex
      w="full"
      overflow="hidden"
      h={{ lg: '428px' }}
      bg={{ lg: 'white', base: '#F3F3F3' }}
      flexDirection="column"
      alignItems={{ sm: 'center', base: 'flex-start' }}
      justifyContent={{ sm: 'center', base: 'flex-start' }}
      pt={{ xl: 0, base: 5 }}
      px={{ xl: 0, base: 5 }}
      pb={{ xl: 0, base: 4 }}
    >
      <Flex alignItems="center">
        <Box
          display={{ md: 'none', base: 'block' }}
          w="4px"
          h="58px"
          bg="#F49D22"
          borderRadius="10px"
        />
        <Text
          as="h1"
          fontSize={{ md: '6xl', base: '5xl' }}
          lineHeight="34px"
          fontWeight="medium"
          color="gray.800"
          ml={{ md: 0, base: 2 }}
        >
          {t('experts@headerTitle')}
        </Text>
      </Flex>
      <Text mt="18px" fontSize="lg" color="gray.700" maxW="538px">
        {t('experts@headerText')}
      </Text>
      <Flex
        w="100%"
        mt={{ md: 8, base: 0 }}
        position={{ md: 'static', base: 'fixed' }}
        left={0}
        bottom={0}
        py={{ md: '0', base: '18px' }}
        px={{ md: '0', base: '29px' }}
        backgroundColor={{ md: 'white', base: 'gray.300' }}
        zIndex="10"
        justifyContent={{ md: 'center', base: 'center' }}
      >
        <Button
          minW={{ md: '220px', base: !user.expertId ? 'auto' : 'full' }}
          as={Link}
          to="/initiator-form"
          variant="border"
          mr={!user.expertId ? 4 : 0}
          textTransform="auto"
          fontSize={{
            sm: 'md',
            base: language === LANG_EN_SHORT ? 'md' : 'sm',
          }}
          fontWeight="bold"
          bg="transparent"
          color="gray.800"
          onClick={() => {
            amplitude.getInstance().logEvent('Need expert page button click')
            console.log('sent event to amplitude')
          }}
        >
          {t('experts@needButtonText')}
        </Button>
        {!user.expertId && (
          <Button
            minW={{ md: '220px', base: 'auto' }}
            variant="blue"
            bg="blue.400"
            border="none"
            borderColor="none"
            as={!isMobile ? Button : Link}
            to={
              user?.id === 'guest'
                ? '/sign-in?from=/profile/expert/card'
                : '/profile/expert/card'
            }
            onClick={onNeedExpertClick}
          >
            <Text
              ml={1.5}
              fontSize={{
                sm: 'md',
                base: language === LANG_EN_SHORT ? 'md' : 'sm',
              }}
              fontWeight="bold"
              color="white"
              textTransform="none"
            >
              {t('experts@applyButtonText')}
            </Text>
          </Button>
        )}
      </Flex>
    </Flex>
  )
}
