import { useRef } from 'react'
import { Helmet } from 'react-helmet-async'

export const DefaultHead = () => (
  <Helmet>
    <title>reArmenia</title>
    <meta name="description" content="Collaboration Platform" />
    <meta property="og:title" content="reArmenia" />
    <meta property="og:description" content="Collaboration Platform" />
    <meta
      property="og:image"
      content={`${process.env.REACT_APP_BASE_URL}/rearmenia.jpg`}
    />
  </Helmet>
)

export default function MetaTags({
  title,
  description,
  image = `${process.env.REACT_APP_BASE_URL}/rearmenia.jpg`,
}) {
  const metaTagsPositionUpdated = useRef(false)

  const onChangeClientState = () => {
    if (!metaTagsPositionUpdated.current) {
      const { head } = document
      const metaTags = [...head.querySelectorAll('meta[data-rh]')]

      metaTags.forEach((tag) => {
        head.insertBefore(tag, head.firstChild)
      })
      metaTagsPositionUpdated.current = true
    }
  }

  return (
    <Helmet onChangeClientState={onChangeClientState}>
      <meta charSet="utf-8" />
      <meta property="og:image" content={image} />
      <meta property="og:image:alt" content={title} />
      <meta property="og:image:width" content="800" />
      <meta property="og:image:height" content="800" />
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta
        name="facebook-domainverification"
        content={process.env.REACT_APP_FACEBOOK_DOMAIN_VERIFICATION_KEY}
      />
      <title>{title}</title>
      <meta
        property="og:url"
        content={process.env.REACT_APP_BASE_URL + `${window.location.pathname}`}
      />

      <meta
        property="fb:app_id"
        content={process.env.REACT_APP_FACEBOOK_APP_ID}
      />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="reArmenia" />
      <meta property="og:locale" content="en_US" />
    </Helmet>
  )
}
