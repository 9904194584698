import React from 'react'
import { Box, Flex, HStack, Text } from '@chakra-ui/react'
import Menu from '../Menu'
import { Link, useNavigation } from 'pages'
import { ReactComponent as Logo } from 'assets/icons/darkLogo.svg'
import OnlyLogo from './OnlyLogo'
import Web from './Web'
import { ABOUT_DROPDOWN, NAV_LINKS } from '../../constants'
import { NavLink } from './NavLink'
import { useTranslation } from 'contexts/TranslationContext'
import { BlueSelect } from 'components/Select/BlueSelect'

const OtherNavbar = ({
  shadow = '0 2px 26px 0 rgba(0, 0, 0, 0.1)',
  isGuest,
  currentPath,
  username,
  onClick,
  disableLinks,
}) => {
  const { navigationPush } = useNavigation()
  const aboutOptionSelect = (option) => {
    if (typeof onClick === 'function') {
      onClick({ from: 'dropdownLink', link: `/${option}` })
    } else {
      navigationPush(`/${option}`)
    }
  }

  const { t } = useTranslation()
  if (currentPath === 'donation' || currentPath === 'gift-card') {
    return <OnlyLogo isGuest={isGuest} username={username} />
  }

  return (
    <Box
      position="relative"
      zIndex="1000"
      w="full"
      as="header"
      bg="white"
      boxShadow={shadow}
      height="80px"
    >
      <Box mx="auto" px={{ base: '24px', md: '60px' }} h="100%">
        <Flex
          as="nav"
          alignItems="center"
          h="100%"
          w="100%"
          justifyContent="space-between"
        >
          <Box
            w="100%"
            justifyContent="space-between"
            align="center"
            display={{ base: 'flex', md: 'none' }}
          >
            <Menu isLink black={+true} isGuest={isGuest} />
          </Box>
          <Flex>
            <Box
              position={{ base: 'absolute', md: 'initial' }}
              left={{ base: 0, md: 'initial' }}
              right={{ base: 0, md: 'initial' }}
              mx="auto !important"
              my="0"
              width="144px"
              onClick={onClick}
              as={Link}
              to="/"
            >
              <Box>
                <Logo width="100%" />
              </Box>
            </Box>
            <HStack ml="16" spacing="6" display={{ base: 'none', md: 'flex' }}>
              {NAV_LINKS.map((link, index) =>
                link.active === 'about' ? (
                  <BlueSelect
                    key={`${link}-${index}`}
                    width="unset"
                    display="flex"
                    type="language"
                    variant="noBorder"
                    label={t(`about@aboutUs`)}
                    options={ABOUT_DROPDOWN.map(({ value, title }) => ({
                      value,
                      title: t(title),
                    }))}
                    selectOption={aboutOptionSelect}
                    offset={[0, 7]}
                  />
                ) : link.active === 'experts' ? (
                  <Flex key={link.title} alignItems="center" mr={4}>
                    <Box
                      key={link.title}
                      fontWeight="bold"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderColor="transparent"
                      transition="all 0.2s"
                      mr={2}
                    >
                      <NavLink.Desktop
                        key={link.title}
                        onClick={onClick}
                        to={link.to}
                        active={currentPath === link.active}
                      >
                        {t(link.title)}
                      </NavLink.Desktop>
                    </Box>
                    <Flex
                      w="26px"
                      h="12px"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="16px"
                      bg="orange.400"
                    >
                      <Text
                        fontSize="8px"
                        fontWeight="medium"
                        color="white"
                        textTransform="uppercase"
                      >
                        {t('expertsPopup@tag')}
                      </Text>
                    </Flex>
                  </Flex>
                ) : (
                  <NavLink.Desktop
                    key={link.title}
                    onClick={onClick}
                    to={link.to}
                    active={currentPath === link.active}
                  >
                    {t(link.title)}
                  </NavLink.Desktop>
                )
              )}
            </HStack>
          </Flex>
          <Web
            disableLinks={disableLinks}
            onClick={onClick}
            isGuest={isGuest}
            currentPath={currentPath}
          />
        </Flex>
      </Box>
    </Box>
  )
}

export default OtherNavbar
