import React from 'react'
import { Heading, Box, HStack } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import TestimonialCard from 'components/TestimonialCard'
import { useListTestimonials } from 'hooks'

const Testimonials = () => {
  const { t, language } = useTranslation()
  const { data } = useListTestimonials()

  if (!data || data[`items_${language}`].length === 0) {
    return <></>
  }

  return (
    <Box mt="60px" py={10} bg="gray.200">
      <Heading px="5" as="h2" mb="6" fontSize="1.625rem">
        {t('testimonials')}
      </Heading>
      <HStack
        alignItems="baseline"
        overflowX="scroll"
        flexWrap="nowrap"
        px="5"
        position="relative"
        spacing="5"
      >
        {data[`items_${language}`].map((item, index) => (
          <Box
            minW="280px"
            key={`homepage-testimonials-mobile-${item.id}-${index}`}
          >
            <TestimonialCard descriptionNumberOfLines={3} card={item} />
          </Box>
        ))}
      </HStack>
    </Box>
  )
}

export default Testimonials
