import React, { useEffect, useState } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { Link } from 'pages'
import { ReactComponent as Logo } from 'assets/icons/logo.svg'
import { ReactComponent as BlueLogo } from 'assets/icons/blue_logo.svg'
import Web from './Web'
import Menu from '../Menu'

const HomeNavbar = ({ isGuest, isHomepage }) => {
  const [scrollStatus, setScrollStatus] = useState(false)

  const onScroll = () => {
    if (window.scrollY > 50) {
      setScrollStatus(true)
    } else {
      setScrollStatus(false)
    }
  }

  useEffect(() => {
    if (isHomepage) {
      document.addEventListener('scroll', onScroll)
    }

    return () => document.removeEventListener('scroll', onScroll)
  }, [onScroll])

  return (
    <Box
      as="header"
      bg={scrollStatus ? 'white' : 'transparent'}
      position="fixed"
      top="0"
      left="0"
      width="full"
      height="80px"
      zIndex="1000"
    >
      <Box height="100%" mx="auto" px={{ base: '24px', lg: '60px' }}>
        <Flex
          height="100%"
          as="nav"
          aria-label="Site navigation"
          align="center"
          justify="space-between"
        >
          <Menu isGuest={isGuest} display={{ base: 'flex', lg: 'none' }} />

          <Box
            position={{ base: 'absolute', md: 'initial' }}
            left={{ base: 0, md: 'initial' }}
            right={{ base: 0, md: 'initial' }}
            mx="auto !important"
            my="0"
            minW="166px"
            w="166px"
          >
            <Box width={scrollStatus ? '125px' : '166px'} transition="0.4s">
              <Link to="/">
                {scrollStatus ? (
                  <BlueLogo width="100%" />
                ) : (
                  <Logo width="100%" />
                )}
              </Link>
            </Box>
          </Box>

          <Web
            isGuest={isGuest}
            scrollStatus={scrollStatus}
            display={{ base: 'none', md: 'flex' }}
            ml={8}
          />
        </Flex>
      </Box>
    </Box>
  )
}

export default HomeNavbar
