import React, { useEffect, useState, useCallback } from 'react'
import * as Sentry from '@sentry/react'
import UserContext from './UserContext'
import { useNavigation } from 'pages'
import {
  useLocalStorage,
  useAuthStateChange,
  useUserFactory,
  useUserUpdateSubscription,
} from 'hooks'

export const guestUser = {
  id: 'guest',
  username: 'guest',
  email: 'guest',
  firstName: 'guest',
  lastName: 'guest',
  emailVerified: false,
  isAdmin: false,
  seenSubscribeModal: false,
}

const useGetCachedUser = () => {
  const { get } = useLocalStorage()
  const getCachedUser = () => {
    try {
      let userCache = get('rearmenia_user')
      if (userCache) {
        userCache = JSON.parse(userCache)
        return { user: userCache, loading: false }
      } else {
        return { user: guestUser, loading: true }
      }
    } catch (err) {
      console.log(err)
      return { user: guestUser, loading: true }
    }
  }

  return {
    cachedUser: getCachedUser(),
  }
}
const UserProvider = ({ children }) => {
  const userUseCase = useUserFactory()
  const { cachedUser } = useGetCachedUser()
  const [userObj, setUserObj] = useState(cachedUser)
  const [company, setCompany] = useState({})
  const { navigationPush } = useNavigation()
  const { subscribeToUserUpdates } = useUserUpdateSubscription({
    userId: userObj?.user?.id,
    setUserObj,
    isAdmin: userObj?.user?.isAdmin,
  })
  const retrieveUser = useCallback(async () => {
    const loginUser = async (signedInUser) => {
      const companyResponse = await userUseCase.getCompany(
        signedInUser.email.split('@')[1]
      )
      setCompany(companyResponse)
      setUserObj({ user: signedInUser, loading: false })
      subscribeToUserUpdates(signedInUser.id)
    }

    const reportError = (signedInUser, errors) => {
      console.log(
        '>>> User logged in but we have errors as well, errors:',
        errors
      )

      const infoForSentry = {
        user: {
          id: signedInUser.id,
          email: signedInUser.email,
        },
        errors: errors,
      }

      Sentry.withScope((scope) => {
        scope.setExtra('Extra Info', infoForSentry)
        Sentry.captureMessage('User logged in but we have errors as well')
      })
    }

    try {
      const signedInUser = await userUseCase.getUser()
      loginUser(signedInUser)
    } catch (e) {
      // @INFO: even if we have error but user was fetched successfully, let's login user and
      //        report about error, to understand what is problem with current user.
      const items = e?.data?.usersByEmail?.items

      if (Array.isArray(items) && items.length > 0) {
        const signedInUser = items[0]
        const errors = e?.errors || ['unknown error']

        reportError(signedInUser, errors)
        loginUser(signedInUser)
      } else {
        console.log('❌', e)
        setUserObj({ user: guestUser, loading: false })
      }
    }
  }, [])

  const onAuthStateChange = (event) => {
    switch (event) {
      case 'autoSignIn':
        retrieveUser()
        break
      default:
        break
    }
  }

  useAuthStateChange(onAuthStateChange)

  useEffect(() => {
    retrieveUser()
  }, [])

  useEffect(() => {
    if (userObj?.user?.id !== 'guest') {
      const redirectLocal = localStorage.getItem('redirect')
      if (redirectLocal) {
        localStorage.removeItem('redirect')
        navigationPush(redirectLocal)
      }
    }
  }, [userObj])
  return (
    <UserContext.Provider
      value={{
        setUser: setUserObj,
        user: userObj.user,
        setUserLoggedIn: retrieveUser,
        getUser: retrieveUser,
        isLoading: userObj.loading,
        company,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
