import Wrapper from 'components/WrapperMobile'
import { useTranslation } from 'contexts/TranslationContext'

const Fundraisers = ({ projects, isLoading, fundraisersRef }) => {
  const { t } = useTranslation()

  return (
    <Wrapper
      type="fundraisers"
      swiper={projects.sort(
        (a, b) => b.total.goalMetPercentage - a.total.goalMetPercentage
      )}
      isLoading={isLoading}
      fromSwiper={true}
      title={t('Fundraisers')}
      showAll={true}
      wrapperRef={fundraisersRef}
    />
  )
}

export default Fundraisers
