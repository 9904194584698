import { forwardRef } from 'react'
import { Box, Stack, StackDivider, Flex, Container } from '@chakra-ui/react'
import { Copyright } from './Copyright'
import { LinkGrid } from './LinkGrid'
import { LinkGridMobile } from './LinkGridMobile'
import { SocialMediaLinks } from './SocialMediaLinks'
import { Link } from 'pages'
import { useTranslation, LANGUAGES } from 'contexts/TranslationContext'
import { BlueSelect } from 'components/Select/BlueSelect'
import { ReactComponent as Logo } from 'assets/icons/darkLogo.svg'
import { SubscribeForm } from './SubscribeForm'
import { useCurrencySettings } from 'contexts/CurrencySettingsContext'
import { SUPPORTED_CURRENCIES } from 'contexts/CurrencySettingsContext'

export const Footer = forwardRef((props, ref) => {
  const { t, language, setLanguage } = useTranslation()
  const selectOption = (option) => setLanguage(option)
  const { currency, changeDefaultCurrency } = useCurrencySettings()

  return (
    <Box
      w="full"
      borderTop="1px solid #dddddd"
      as="footer"
      role="contentinfo"
      mx="auto"
      pt={{ base: 8, sm: 20 }}
      pb="28px"
      bg="white"
      ref={ref}
      display={{ base: 'block', sm: 'block' }}
    >
      <Container maxW="7xl">
        <Stack
          spacing="28px"
          divider={<StackDivider borderColor="border.100" />}
        >
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            alignItems={{ sm: 'flex-end' }}
            pb={{ base: '8', sm: '10' }}
          >
            <Flex direction="column" justifyContent="space-between" flex="1">
              <Flex
                align={{ base: 'start', sm: 'unset' }}
                direction="column"
                mb={{ base: '2rem', sm: '3rem' }}
              >
                <Logo />
                <Flex>
                  <BlueSelect
                    variant="border"
                    width="215px"
                    display="flex"
                    type="language"
                    label={t(`language@${language}Label`)}
                    options={Object.keys(LANGUAGES).map((key) => ({
                      title: t(`language@${key}Label`),
                      value: key,
                    }))}
                    selectOption={selectOption}
                    currentSelectedOption={t(`language@${language}Label`)}
                    offset={[0, -6]}
                  />

                  <BlueSelect
                    variant="border"
                    width="85px"
                    ml="5"
                    display="flex"
                    type="currency"
                    label={currency.current}
                    options={SUPPORTED_CURRENCIES.map((option) => ({
                      title: t(`currency@${option}`),
                      value: option,
                    }))}
                    selectOption={changeDefaultCurrency}
                    currentSelectedOption={t(`currency@${currency.current}`)}
                    offset={[0, -6]}
                  />
                </Flex>
              </Flex>
              <Box display={{ base: 'none', lg: 'block' }}>
                <SocialMediaLinks />
              </Box>
            </Flex>
            <Stack
              alignItems={{ base: 'center', sm: 'flex-start' }}
              direction={{ base: 'column', md: 'row' }}
            >
              <LinkGrid
                display={{ base: 'none', sm: 'flex' }}
                flexDirection={{ base: 'none', sm: 'column' }}
                spacing={{ base: '6', md: '6', lg: '8' }}
              />
              <LinkGridMobile
                flexDirection="row"
                wrap="wrap"
                display={{ base: 'flex', sm: 'none' }}
                spacing={{ base: '4' }}
              />
              {/* TODO: UNCOMMENT WHEN GIFTS CARD WILL BE ENABLED */}
              {/* <GiftCard /> */}
              <SubscribeForm
                width={{ base: 'full', md: 'sm' }}
                mt={10}
                display={{ base: 'none', sm: 'block' }}
              />
              <Box
                style={{ marginTop: '2rem' }}
                display={{ base: 'block', lg: 'none' }}
              >
                <SocialMediaLinks />
              </Box>
            </Stack>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Copyright />
            <Flex alignItems="center">
              <Link to="/terms-and-conditions">
                <Box fontSize="sm">{t('footer@termsConditions')}</Box>
              </Link>
              <Box>・</Box>
              <Link to="/privacy-policy">
                <Box fontSize="sm">{t('footer@privacyPolicy')}</Box>
              </Link>
            </Flex>
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
})
