import { useMutation } from 'react-query'
import { notificationUseCase } from '../../core/factories'

const useUpdateNotification = () => {
  const {
    mutate: updateNotification,
    data,
    isLoading,
  } = useMutation(notificationUseCase.updateNotification)

  return {
    updateNotification,
    isLoading,
    data,
  }
}

export default useUpdateNotification
