import React from 'react'
import ReactDOM from 'react-dom/client'
import ReactGA from 'react-ga4'
import { Amplify } from 'aws-amplify'
import { defaultStorage } from 'aws-amplify/utils'
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito'
import awsConfig from './aws-config'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { GA_TRACKING_ID } from './config.js'
import App from './App'
import reportWebVitals from './reportWebVitals'

Amplify.configure({
  ...awsConfig,
  Auth: {
    Cognito: {
      userPoolId: awsConfig.aws_user_pools_id,
      userPoolClientId: awsConfig.aws_user_pools_web_client_id,
      loginWith: {
        oauth: {
          ...awsConfig.oauth,
          redirectSignIn: awsConfig.oauth.redirectSignIn.split(','),
          redirectSignOut: awsConfig.oauth.redirectSignOut.split(','),
        },
      },
    },
  },
})

cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage)
const root = ReactDOM.createRoot(document.getElementById('root'))
const script = document.createElement('script')
script.type = 'text/javascript'
document.getElementsByTagName('head')[0].appendChild(script)
root.render(<App />)

if (process.env.NODE_ENV === 'development') {
  console.log(`GoogleAnalytics disabled in development mode`)
  console.log(`Sentry disabled in development mode`)
} else {
  ReactGA.initialize(GA_TRACKING_ID)
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Integrations.BrowserTracing(),
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// serviceWorkerRegistration.register();
