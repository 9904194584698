import { useQuery } from 'react-query'
import { transactionUseCase } from '../../core/factories'

const useGetTransactionByEmailDate = (variables) =>
  useQuery(
    ['transaction-history'],
    () => transactionUseCase.getTransactionsByEmailDate(variables),
    {
      refetchOnWindowFocus: false,
    }
  )

export default useGetTransactionByEmailDate
