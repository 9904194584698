import { Flex, Heading, Text, Switch } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useUpdateUser } from 'hooks'
import React, { useState } from 'react'

export default function Notification({ user }) {
  const [notificationSettings, setNotificationSettings] = useState(
    safeParser(user.notificationSettings)
  )

  const { t } = useTranslation()
  const { updateUser, isLoading } = useUpdateUser()

  const NOTIFICATION_SETTINGS = [
    {
      title: 'donor',
      children: ['successfulDonations', 'failedDonations', 'donorUpdate'],
    },
    {
      title: 'investor',
      children: [
        'successfulInvestments',
        'failedInvestments',
        'investorUpdate',
      ],
    },
  ]

  return (
    <Flex direction="column" gap={{ base: 7, sm: 8 }}>
      {NOTIFICATION_SETTINGS.map(({ title, children }) => (
        <Flex direction="column" key={title}>
          <Heading
            mb="22px"
            fontSize={{ base: 'lg', sm: '2xl' }}
            fontWeight={600}
          >
            {t(`profile@settings-notification@${title}`)}
          </Heading>
          <Flex
            direction="column"
            w="860px"
            maxW="full"
            bg="white"
            gap="26px"
            py={{ base: 4, sm: '26px' }}
            px={{ base: 4, sm: 8 }}
            borderRadius={8}
          >
            {children.map((key) => (
              <Flex key={key} justify="space-between" align="center">
                <Text fontSize={{ base: 'md', sm: 'lg' }}>
                  {t(`profile@settings-notification@${title}@${key}`)}
                </Text>
                <Switch
                  size={{ base: 'sm', sm: 'lg' }}
                  variant="medium"
                  isChecked={notificationSettings[key]}
                  isDisabled={isLoading}
                  onChange={() => {
                    setNotificationSettings({
                      ...notificationSettings,
                      [key]: !notificationSettings[key],
                    })
                    updateUser({
                      id: user?.id,
                      notificationSettings: JSON.stringify({
                        ...notificationSettings,
                        [key]: !notificationSettings[key],
                      }),
                    })
                  }}
                />
              </Flex>
            ))}
          </Flex>
        </Flex>
      ))}
    </Flex>
  )
}

const safeParser = (obj) => {
  let result = {}
  try {
    if (obj?.length > 2) {
      result = JSON.parse(obj)
    }
  } catch (error) {
    console.log('---', error)
    result = {}
  }
  return result
}
