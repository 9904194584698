import { Flex, Spinner } from '@chakra-ui/react'
import { useNavigation } from 'pages'
import { useUser, useProjectsByUserId } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import {
  useDeleteDraft,
  useCollaborationsDraftsByUserId,
} from 'core/Draft/hooks'
import { useModal } from 'providers/ModalProvider'
import Space from '../Space'
import Collaborations from './Collaborations'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import Cosmo from '../Cosmo'
import TabsLayout from 'components/Layouts/TabsLayout'

const TABS = [
  {
    value: 'connection-requests',
    title: 'profile@dashboard@initiator@connectionRequests',
    Panel: () => (
      <Cosmo workspaceId={process.env.REACT_APP_COSMO_INITIATOR_PAGE_ID} />
    ),
  },
  {
    value: 'collaborations',
    title: 'profile@dashboard@initiator@collaborations',
    Panel: Collaborations,
  },
  {
    value: 'archived',
    title: 'Archived',
    Panel: () => (
      <Cosmo
        workspaceId={`${process.env.REACT_APP_COSMO_INITIATOR_PAGE_ID}?type=archived`}
      />
    ),
  },
]

const InitiatorSpace = () => {
  const { tab } = useParams()
  const { navigationPush } = useNavigation()
  const { user } = useUser()
  const { t } = useTranslation()
  const { projects, isLoading } = useProjectsByUserId({
    owner: user.id,
    filter: {
      isCollaboration: { eq: true },
    },
  })

  const { data: draftData, refetch } = useCollaborationsDraftsByUserId(user.id)
  const { mutate: deleteDraft, isLoading: isDraftDeletionLoading } =
    useDeleteDraft()
  const { openModal, closeModal } = useModal()
  const onDraftDelete = (id) => {
    openModal('deleteDraft', null, false, () => {}, {
      onDelete: () => {
        deleteDraft(id, {
          onSuccess: () => {
            refetch()
          },
          onError: (err) => {
            console.log('error', err)
          },
          onSettled: () => {
            closeModal()
          },
        })
      },
      isDraftDeletionLoading,
    })
  }

  const onChange = (tab) =>
    navigationPush(`/profile/initiator-space/${TABS[tab].value}`)
  if (isLoading)
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        margin="auto"
        h={{ md: '100%', base: '300px' }}
      >
        <Spinner color="blue.400" />
      </Flex>
    )

  return (
    <>
      <Space title={t('profile@initiatorSpace')}>
        <TabsLayout
          defaultIndex={TABS.findIndex(({ value }) => value === tab)}
          onChange={onChange}
          TABS={TABS}
          projects={projects}
          onDraftDelete={onDraftDelete}
          draftData={draftData}
          t={t}
        />
      </Space>
    </>
  )
}

export default InitiatorSpace
