import {
  LANG_HY_LONG,
  LANG_RU_SHORT,
  useTranslation,
} from 'contexts/TranslationContext'
import React, { useEffect } from 'react'

function RedirectFallback({ fileName }) {
  const { language } = useTranslation()
  useEffect(() => {
    let redirectURL = `https://rearmenia.com/${fileName}`
    if (language === LANG_RU_SHORT) {
      redirectURL += `-rs`
    }
    if (language === LANG_HY_LONG) {
      redirectURL += `-am`
    }
    redirectURL += '.html'
    window.location.replace(redirectURL)
  }, [])
  return <div></div>
}

const WithType =
  (fileName) =>
  ({ props }) =>
    <RedirectFallback fileName={fileName} {...props} />

export default WithType
