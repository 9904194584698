import { uploadData } from 'aws-amplify/storage'

const filesService = () => {
  const uploadFiles = (data) =>
    Promise.all(
      data.map((item) => {
        if (item.size / 1024 > 10000) {
          throw new Error('File is above 10mb!')
        }

        try {
          const result = uploadData({
            key: item.name,
            data: item,
          }).result
          return result
        } catch (error) {
          console.log('❌ ', error)
          return error
        }
      })
    )

  return {
    uploadFiles,
  }
}

export default filesService
