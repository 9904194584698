import React from 'react'
import { ModalBody, ModalCloseButton, ModalHeader } from '@chakra-ui/react'
import ListBasketSubscriptions from 'components/ListBasketSubscriptions'
import { useTranslation } from 'contexts/TranslationContext'

export default function ListBasketSubscriptionsModal() {
  const { t } = useTranslation()

  return (
    <>
      <ModalHeader
        pt={8}
        px={8}
        pb={0}
        fontSize="4xl"
        fontWeight="bold"
        color="gray.800"
        textTransform="capitalize"
      >
        {t('profile@dashboard@investor@recurring@manageSubs')}
      </ModalHeader>

      <ModalCloseButton
        _active={{ background: 'transparent' }}
        _hover={{ background: 'transparent' }}
        top="35px"
        right="22px"
      />
      <ModalBody p={8}>
        <ListBasketSubscriptions />
      </ModalBody>
    </>
  )
}
