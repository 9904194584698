import { Stack, Text, useDisclosure } from '@chakra-ui/react'
import Dropdown from 'components/Dropdown'
import { BlueSelect } from 'components/Select/BlueSelect'
import { useTranslation } from 'contexts/TranslationContext'
import { CATEGORY, REGIONS } from '_constants'

export default function Filters({ filters, onFilterChange }) {
  const { t, language } = useTranslation()
  const { isOpen, onToggle, onClose } = useDisclosure()

  const onLocationSelect = (location) => {
    onFilterChange('location', location === filters.location ? '' : location)
    onClose()
  }

  const onCategorySelect = (category) => {
    onFilterChange('category', category === filters.category ? '' : category)
    onClose()
  }

  return (
    <Dropdown
      btnTitle="Filter"
      isOpen={isOpen}
      onToggle={onToggle}
      onClose={onClose}
    >
      <Stack py={6} spacing={4}>
        <Text>{t('By Category')}</Text>
        <BlueSelect
          type="category"
          label={
            filters?.category
              ? t(`category@${filters?.category}`)
              : t('Select a Category')
          }
          width="100%"
          variant="border"
          menuButtonProps={{ m: 0, backgroundColor: 'white' }}
          selectOption={onCategorySelect}
          currentSelectedOption={t(`category@${filters?.category}`)}
          selectedOption={filters.category}
          options={CATEGORY[language].map((option) => ({
            title: t(`category@${option}`),
            value: option,
          }))}
        />
      </Stack>
      <Stack py={6} spacing={4}>
        <Text>{t('By Location')}</Text>
        <BlueSelect
          type="region"
          label={
            filters?.location
              ? t(`region@${filters?.location}`)
              : t('Select a Location')
          }
          width="100%"
          variant="border"
          menuButtonProps={{ m: 0, backgroundColor: 'white' }}
          selectOption={onLocationSelect}
          currentSelectedOption={t(`region@${filters?.location}`)}
          selectedOption={filters.location}
          options={REGIONS.map((region) => ({
            title: t('region@' + region),
            value: region,
          }))}
        />
      </Stack>
    </Dropdown>
  )
}
