import { Flex, Text, Radio } from '@chakra-ui/react'

const CardSavingSelection = ({ text, isSelected, Icon, ...props }) => (
  <Flex
    h="64px"
    p="22px"
    align="center"
    borderRadius={{ base: '12px', sm: '8px' }}
    borderWidth="1px"
    borderColor={isSelected ? 'blue.300' : 'border.500'}
    position="relative"
    alignItems="center"
    justifyContent="space-between"
    cursor={isSelected ? 'default' : 'pointer'}
    _hover={{
      'button[datatype="remove"]': {
        opacity: 1,
      },
    }}
    {...props}
  >
    <Flex alignItems="center">
      {Icon}
      <Text ml={4} color="black.100" fontSize="md">
        {text}
      </Text>
    </Flex>

    <Flex alignItems="center">
      <Radio value="1" isChecked={isSelected}></Radio>
    </Flex>
  </Flex>
)

export default CardSavingSelection
