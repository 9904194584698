const Text = {
  variants: {
    'heading-1': {
      fontSize: 'lg',
      fontWeight: '700',
    },
    'body-1': {
      fontSize: 'sm',
      fontWeight: '400',
    },
  },
}

export default Text
