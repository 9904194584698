import { FormControl, Text } from '@chakra-ui/react'
import { RadioGroup } from './RadioGroup'
import { useTranslation } from 'contexts/TranslationContext'

export default function Tip({
  price,
  handleAmount,
  other,
  setValue,
  donation,
  PRICE,
  overwrittenCurrency,
}) {
  const { t } = useTranslation()

  return (
    <FormControl>
      <Text
        fontSize="xl"
        fontWeight="semibold"
        color="gray.800"
        bg="white"
        zIndex={2}
        mb={0}
      >
        {t('donation@donateToReArmenia')}
      </Text>
      <Text mb="6" color="gray.700" fontSize="md">
        {t('donation@donateToReArmeniaFee')}
      </Text>
      <RadioGroup
        width="100%"
        name="amount"
        price={price}
        defaultValue="20$"
        options={PRICE}
        onChange={handleAmount}
        other={other}
        setValue={setValue}
        donation={donation}
        overwrittenCurrency={overwrittenCurrency}
        t={t}
      />
    </FormControl>
  )
}
