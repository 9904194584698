import React from 'react'
import { Box } from '@chakra-ui/layout'

const CustomSwitch = ({
  isChecked,
  onChange,
  width = '48px',
  height = '24px',
  thumbWidth = '20px',
  thumbHeight = '20px',
  bg = 'gray.500',
  checkedColor = 'gray.800',
  uncheckedColor = 'white',
  spacing = '2px',
}) => {
  return (
    <Box
      width={width}
      height={height}
      backgroundColor={bg}
      borderRadius="20px"
      position="relative"
      cursor="pointer"
      onClick={onChange}
    >
      <Box
        width={thumbWidth}
        height={thumbHeight}
        margin={spacing}
        backgroundColor={isChecked ? checkedColor : uncheckedColor}
        borderRadius="50%"
        position="absolute"
        top="0"
        left={
          isChecked
            ? `calc(100% - ${thumbWidth} - ${spacing} - ${spacing})`
            : '0'
        }
        transition="0.3s"
      ></Box>
    </Box>
  )
}

export default CustomSwitch
