import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Box, VStack, useDisclosure, Button } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import ReportCard from 'components/UpdateCard'
import { DeleteReportModal } from 'modals'
import EditReportModal from 'modals/EditReportModal'
import useEmptyReport from 'components/ReportEditor/useEmptyReport'
import { useQueryParams } from 'hooks'
import { polyfill, scrollIntoView } from 'seamless-scroll-polyfill'

const Report = ({
  project,
  reports,
  isReportsLoading,
  user,
  refetchReports,
  showAvatar = true,
}) => {
  const queryParams = useQueryParams()
  const selectedReport = (id) => queryParams.get('reportId') === id

  const generateEmptyReport = useEmptyReport()
  const [action, setAction] = useState('edit')
  const { language, t } = useTranslation()
  const {
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
    isOpen: isDeleteModalOpen,
  } = useDisclosure()
  const {
    onOpen: onEditModalOpen,
    onClose: onEditModalClose,
    isOpen: isEditModalOpen,
  } = useDisclosure()
  const [activeReport, setActiveReport] = useState()

  const isEditingVisible = useMemo(() => {
    if (!user) {
      return false
    }
    if (user.isAdmin || project?.owner === user.id) {
      return true
    }
    return false
  }, [user, project])

  const onReportControl = ({ report, action }) => {
    setActiveReport(report)
    setAction(action)
    if (action === 'delete') {
      onDeleteModalOpen()
      return
    }
    onEditModalOpen()

    return
  }
  const ref = useRef()
  useEffect(() => {
    if (!ref.current) {
      return
    }
    polyfill()
    scrollIntoView(ref.current, {
      behavior: 'smooth',
    })
  }, [isReportsLoading, reports])
  return (
    <Box>
      {isEditingVisible && (
        <Button
          color="orange.400"
          variant="transparent"
          m={4}
          onClick={() => {
            onReportControl({ report: generateEmptyReport(), action: 'create' })
          }}
        >
          {t('report@addReport')}
        </Button>
      )}
      {!isReportsLoading && reports && reports?.length > 0 ? (
        <VStack spacing={6}>
          {reports.map((report) => (
            <ReportCard
              key={report.id}
              t={t}
              onUpdateControl={onReportControl}
              isEditingVisible={isEditingVisible}
              update={report}
              creatorImage={project.creatorImage}
              creatorName={project.creatorName}
              language={language}
              ref={selectedReport(report.id) ? ref : null}
              showAvatar={showAvatar}
              isReport
            />
          ))}
        </VStack>
      ) : (
        <Box
          fontWeight="800"
          my="12"
          display="block"
          fontSize="6xl"
          color="blue.300"
          as="span"
        >
          {t('project@reports')}{' '}
          <Box as="span" fontWeight="400">
            {t('project@reportsText')}
          </Box>
        </Box>
      )}
      {isEditingVisible && (
        <>
          <DeleteReportModal
            isOpen={isDeleteModalOpen}
            onClose={onDeleteModalClose}
            refetchReports={refetchReports}
            report={activeReport}
          />
          <EditReportModal
            projectId={project.id}
            isOpen={isEditModalOpen}
            onClose={onEditModalClose}
            refetchReports={refetchReports}
            report={activeReport}
            createdAt={activeReport?.createdAt}
            action={action}
          />
        </>
      )}
    </Box>
  )
}

export default Report
