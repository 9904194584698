import { Flex, Heading } from '@chakra-ui/react'
const Header = ({ title }) => {
  return (
    <Flex
      h="120px"
      alignItems="center"
      borderBottom="1px solid"
      borderColor="border.100"
      px={9}
    >
      <Heading fontSize="5xl" fontWeight={500}>
        {title}
      </Heading>
    </Flex>
  )
}

export default Header
