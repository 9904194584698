const draftRepo = (DraftAppSyncService) => {
  const createDraft = (input) => DraftAppSyncService.createDraft(input)
  const updateDraft = (input) => DraftAppSyncService.updateDraft(input)
  const deleteDraft = (id) => DraftAppSyncService.deleteDraft(id)
  const getDraftById = (id) => DraftAppSyncService.getDraftById(id)

  const getFundraiserDraftsByUserId = (userId) =>
    DraftAppSyncService.getFundraiserDraftsByUserId(userId)

  const getCollaborationDraftsByUserId = (userId) =>
    DraftAppSyncService.getCollaborationDraftsByUserId(userId)

  return {
    createDraft,
    updateDraft,
    deleteDraft,
    getDraftById,
    getFundraiserDraftsByUserId,
    getCollaborationDraftsByUserId,
  }
}

export default draftRepo
