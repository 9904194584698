import { useMemo } from 'react'
import { VStack, HStack, Text, Image, Divider } from '@chakra-ui/react'
import { Link } from 'pages'
import ImageWrapper from 'components/ImageWrapper'
import { useTranslation } from 'contexts/TranslationContext'
import { PEER_TO_PEER_OCCASION_ENUMS } from 'constants/fundraiser'

export default function PeerToPeerCard({ project, peerProject }) {
  const { t, language } = useTranslation()

  const heading = useMemo(() => {
    if (
      project?.occasion ===
      PEER_TO_PEER_OCCASION_ENUMS.PEER_TO_PEER_OCCASION_BIRTHDAY
    ) {
      return `${t('Welcome to birthday peer to peer project FIRST PART')} ${
        project[`creatorName_${language}`]
      }${t('Welcome to birthday peer to peer project SECOND PART')}`
    }

    return `${t('Welcome to other peer to peer project FIRST PART')} ${
      project[`creatorName_${language}`]
    }${t('Welcome to other peer to peer project SECOND PART')}`
  }, [
    project?.occasion,
    peerProject?.ownerName,
    peerProject?.lastName,
    language,
  ])

  return (
    <VStack
      as={Link}
      to={`/fundraisers/${peerProject.slug || peerProject.id}`}
      target="_bank"
      spacing={{ base: 0, md: 8 }}
      pt={{ base: 0, md: 6 }}
      pb={3}
    >
      <VStack
        spacing={4}
        py={{ base: 6, md: 4 }}
        pl={{ base: 6, md: 4 }}
        pr={{ base: 4, md: 7 }}
        bg="gray.400"
        borderRadius={{ base: 0, md: '16px' }}
        alignItems="flex-start"
      >
        <Text
          color="blue.400"
          fontSize={{ base: 'lg', md: '3xl' }}
          fontWeight="700"
        >
          {heading}
        </Text>
        <HStack spacing={4}>
          {peerProject?.cover ? (
            <ImageWrapper
              src={`/thumbnail/small/${peerProject?.cover}`}
              alt={peerProject.applicationTitle}
              borderRadius={{ base: '10px', md: '8px' }}
              h={{ base: '80px', md: '70px' }}
              w={{ base: '96px', md: '120px' }}
              objectFit="cover"
            />
          ) : (
            <Image
              objectFit="cover"
              borderRadius={{ base: '10px', md: '8px' }}
              h={{ base: '80px', md: '70px' }}
              w={{ base: '96px', md: '120px' }}
              ignoreFallback={true}
              src="/assets/images/noImg.svg"
            />
          )}
          <VStack spacing={2} alignItems="flex-start">
            <Text
              noOfLines={2}
              fontSize="xl"
              fontWeight="700"
              color="gray.800"
              textDecoration="underline"
            >
              {peerProject[`title_${language}`]}
            </Text>
            <Text
              noOfLines={1}
              fontSize="lg"
              fontWeight="400"
              color="gray.1000"
            >
              {peerProject[`tagline_${language}`]}
            </Text>
          </VStack>
        </HStack>
      </VStack>
      <Divider borderColor="border.400" />
    </VStack>
  )
}
