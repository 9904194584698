import {
  LANG_HY_SHORT,
  LANG_RU_SHORT,
  useTranslation,
} from 'contexts/TranslationContext'
import { useEffect } from 'react'

const RedirectWithLanguage = ({ fileName }) => {
  const { language } = useTranslation()

  useEffect(() => {
    let redirectURL = `https://rearmenia.com/${fileName}`
    if (language === LANG_RU_SHORT) {
      redirectURL += '-rs'
    }

    if (language === LANG_HY_SHORT) {
      redirectURL += '-am'
    }
    redirectURL += '.html'
    window.location.replace(redirectURL)
  }, [])
  return <></>
}

const WithType =
  (fileName) =>
  ({ props }) =>
    <RedirectWithLanguage fileName={fileName} {...props} />

export default WithType
