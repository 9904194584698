const successStoryRepo = (contentfulService) => {
  const listSuccessStories = async () => contentfulService.list('successStory')

  const getSuccessStory = async (slug) =>
    contentfulService.get({ contentType: 'successStory', slug })

  return {
    listSuccessStories,
    getSuccessStory,
  }
}

export default successStoryRepo
