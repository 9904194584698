import { GridItem, Box } from '@chakra-ui/react'
import { Link } from 'pages'
import {
  thousandSeparator,
  convert,
  useCurrencySettings,
} from 'contexts/CurrencySettingsContext'
import ProjectCard from 'components/ProjectCard'
import { useTranslation } from 'contexts/TranslationContext'
import { calculateProjectDurationLeft } from 'hooks'

const CARD_HEIGHT = 451

export default function ProjectItem({ project, onChooseProject }) {
  const { t, language } = useTranslation()
  const { currency, settings } = useCurrencySettings()

  const projectCardBottomActions = [
    {
      text: 'Learn more',
      props: {
        variant: 'border',
        minW: '50%',
        as: Link,
        to: `/fundraisers/${project.slug || project.id}`,
        target: '_blank',
        rel: 'noopener noreferrer',
      },
    },
    {
      text: 'Choose',
      props: {
        variant: 'green',
        maxW: '50%',
        onClick: () => onChooseProject(project),
      },
    },
  ]

  return (
    <GridItem colSpan={4} role="group">
      <Box
        position="relative"
        h={CARD_HEIGHT}
        transition="transform 300ms"
        _groupHover={{ zIndex: 1, transform: 'scale(1.04)' }}
      >
        <Box position="absolute" w="100%">
          <ProjectCard
            isImageLazyLoad={false}
            card={project}
            currency={currency}
            settings={settings}
            language={language}
            goalPrice={project.activeGoal?.amount || project.goal}
            goalText={t('goal')}
            raisedText={t('raised')}
            monthlyGoalText={t('monthlyGoal')}
            calculatedProjectDurationLeft={calculateProjectDurationLeft({
              t,
              campaignImplementorPeriod_en:
                project.campaignImplementorPeriod_en,
              fundraisingType: project.fundraisingType,
            })}
            raisedMoney={thousandSeparator(
              convert({
                amount: project.amount,
                currencyRate: settings?.currency[currency.current],
              }),
              currency.current
            )}
            bottomHoverActions={projectCardBottomActions}
          />
        </Box>
      </Box>
    </GridItem>
  )
}
