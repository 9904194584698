const expertUseCase = (expertRepo) => {
  const createExpertInitConnection = (params) =>
    expertRepo.createExpertInitConnection(params)
  const getConnection = async (id) => {
    if (!id) {
      return null
    }
    return expertRepo.getConnection(id)
  }
  const updateConnection = (params) => expertRepo.updateConnection(params)
  return {
    createExpertInitConnection,
    getConnection,
    updateConnection,
  }
}

export default expertUseCase
