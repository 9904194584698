const notificationRepo = (notificationService) => {
  const listNotificationsByUserId = async (variables) =>
    notificationService.listNotificationsByUserId(variables)

  const updateNotification = async (variables) =>
    notificationService.updateNotification(variables)

  return {
    listNotificationsByUserId,
    updateNotification,
  }
}

export default notificationRepo
