import { Button, Heading, Text, Box, Flex, IconButton } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as SuccessIcon } from 'assets/icons/expert_success.svg'
import { ReactComponent as FailIcon } from 'assets/icons/expert_fail.svg'
import { useMemo } from 'react'
import {
  useQueryParams,
  useStringManipulations,
  useUpdateUser,
  useUser,
} from 'hooks'
import { useNavigation } from 'pages'
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg'
import { ReactComponent as LeftIcon } from 'assets/icons/arrowLeftNew.svg'
import { useGetExpertById } from 'core/Content/hooks'

export default function ExpertNotificationModalMobile() {
  const { t, language } = useTranslation()
  const { findNamingTranslation } = useStringManipulations()
  const params = useQueryParams()
  const type = params.get('type')
  const { updateUser } = useUpdateUser()
  const { setUser, user } = useUser()
  const { data: expert } = useGetExpertById(user?.expertId || user?.email)
  const { navigationPush } = useNavigation()
  const heading = useMemo(() => {
    if (type === 'approved') {
      return 'Congratulations!'
    }
    if (type === 'rejected') {
      return 'Your application is rejected'
    }
    if (type === 'pending') {
      return t(
        `Thank you, [name], for your willingness to make positive changes in Armenia!`
      ).replace(
        '[name]',
        findNamingTranslation({
          parentObject: expert,
          value: 'firstName',
          language,
        })
      )
    }
  }, [type])
  const onModalClose = () => {
    updateUser(
      {
        cognitoId: user.cognitoId,
        id: user.id,
        isSeenExpertProfileModal: true,
      },
      {
        onSuccess: (response) => {
          setUser((oldUser) => ({
            ...oldUser,
            user: response.data.updateUser,
          }))
          navigationPush('/profile/expert/card')
        },
      }
    )
  }
  const description = useMemo(() => {
    if (type === 'approved') {
      return 'Your application to become an expert has been approved.'
    }
    if (type === 'rejected') {
      return 'We are sorry, but your application to become an expert is rejected. You can always update your information and apply again.'
    }
    if (type === 'pending') {
      return 'We’ve sent you an email about the further steps.'
    }
  }, [type])
  return (
    <>
      <Flex
        flexDir="column"
        justifyContent="space-between"
        h="calc(100vh - 100px)"
        mt={8}
        px="8"
        textAlign="center"
      >
        <Flex>
          <IconButton onClick={onModalClose}>
            <LeftIcon />
          </IconButton>
        </Flex>
        <Box>
          <Flex justifyContent="center">
            <Icon type={type} />
          </Flex>
          <Heading>{t(heading)}</Heading>
          <Text>{t(description)}</Text>
        </Box>
        <Button
          variant="blue"
          minW="full"
          onClick={onModalClose}
          id="draft_modal_save"
        >
          {t(type === 'approved' ? 'OK, GREAT' : 'OK')}
        </Button>
      </Flex>
    </>
  )
}

const Icon = ({ type }) => {
  if (type === 'rejected') {
    return <FailIcon />
  }
  if (type === 'approved') {
    return <SuccessIcon />
  }

  return <EmailIcon />
}
