import { useState, useCallback } from 'react'
import {
  Button,
  Input,
  Flex,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react'
import { useNavigation } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as WhiteSearch } from 'assets/icons/search.svg'
import { ReactComponent as BlackSearch } from 'assets/icons/blackSearch.svg'
import { ReactComponent as Close } from 'assets/icons/closeNew.svg'
import { AnimatePresence, motion } from 'framer-motion'

export default function HeaderSearch({
  xl,
  white = true,
  search: parentSearch,
  setSearch: setParentSearch,
}) {
  const { navigationPush } = useNavigation()
  const [search, setSearch] = useState({})
  const { t } = useTranslation()

  const onSearch = useCallback(() => {
    let queryParams = ''
    for (const [key, value] of Object.entries(search)) {
      queryParams += `&${key}=${value}`
    }
    navigationPush(`/search${queryParams.replace('&', '?')}`)
  }, [search])

  return (
    <>
      {xl && (
        <Flex
          alignItems="center"
          justifyContent="flex-end"
          pr={parentSearch ? '2' : '0'}
          paddingLeft="1rem"
          maxW={parentSearch ? '635px' : '50px'}
          height={parentSearch ? '64px' : '50px'}
          flex="1"
          color="black"
        >
          <AnimatePresence>
            {parentSearch && (
              <motion.div
                initial={{ opacity: '0', width: '300px' }}
                animate={{ opacity: 1, width: '400px' }}
                transition={{ duration: '0.6' }}
              >
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={white ? <WhiteSearch /> : <BlackSearch />}
                  />
                  <Input
                    background="transparent"
                    color={white ? 'white' : 'black'}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') onSearch()
                    }}
                    placeholder={t('nav@searchByKeyword')}
                    _focus={{
                      outline: 'none',
                    }}
                    _placeholder={{
                      color: white ? 'whiteAlpha.700' : 'gray.700',
                      fontWeight: '400',
                    }}
                    border="none"
                    id="header_search_input"
                    onChange={(e) =>
                      setSearch((search) => ({
                        ...search,
                        text: e.target.value,
                      }))
                    }
                  />
                </InputGroup>
              </motion.div>
            )}
          </AnimatePresence>
          <motion.div
            transition={{ duration: '0.3' }}
            animate={{
              transform: `translateX(${parentSearch ? '20px' : '0px'})`,
            }}
          >
            <Button
              onClick={() => setParentSearch(!parentSearch)}
              w="50px"
              h="50px"
              borderRadius="100%"
              id="header_search_button"
            >
              {parentSearch ? (
                <Close width="20px" color={white ? 'white' : 'black'} />
              ) : white ? (
                <WhiteSearch />
              ) : (
                <BlackSearch />
              )}
            </Button>
          </motion.div>
        </Flex>
      )}
    </>
  )
}
