import { useMutation } from 'react-query'
import { updateUseCase } from '../../core/factories'

const useEditUpdate = () => {
  const { mutate, data, isLoading } = useMutation(updateUseCase.editUpdate)

  return {
    mutate,
    isLoading,
    data,
  }
}

export default useEditUpdate
