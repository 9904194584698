import { imageRepo } from 'core/repositories'
import projectUseCase from './projectUseCase'
import {
  ProjectService,
  DonationSubscriptionService,
  imageService,
} from 'core/services'

const projectFactory = ({ appSyncRequest, auth, api }) => {
  return projectUseCase(
    ProjectService(appSyncRequest, auth, api),
    imageRepo(imageService()),
    DonationSubscriptionService()
  )
}

export default projectFactory
