import React, { useEffect } from 'react'
import { Box, ModalBody, ModalCloseButton, Select } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import ExpertCard from 'components/ExpertCard'

export default function ExpertCardModal({
  expert,
  closeModal,
  onStatusChange,
}) {
  const { language } = useTranslation()
  useEffect(() => {
    if (!expert) {
      closeModal()
    }
  }, [expert])
  return (
    <Box>
      <ModalCloseButton
        _active={{ background: 'transparent' }}
        _hover={{ background: 'transparent' }}
      />
      <ModalBody w="full">
        <Box mt={8}>
          {expert && (
            <>
              {' '}
              <Select
                defaultValue={expert.expert_status}
                onChange={(e) => {
                  onStatusChange({
                    id: expert.id,
                    updatedStatus: e.target.value,
                    userId: expert.userId,
                  })
                }}
              >
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </Select>
              <ExpertCard
                slug={expert?.slug}
                key={expert?.id}
                id={expert?.id}
                image={
                  expert?.imageUrl?.includes('https://')
                    ? expert.imageUrl
                    : `${process.env.REACT_APP_S3_BUCKET_URL}/public/${expert.imageUrl}`
                }
                workTime={expert?.hrsPerWeek}
                firstName={
                  expert[`firstName_${language}`] ||
                  expert?.firstName_en ||
                  expert?.firstName_hy ||
                  expert?.firstName_ru
                }
                socials={expert.socialLinks}
                lastName={
                  expert[`lastName_${language}`] ||
                  expert?.lastName_en ||
                  expert?.lastName_hy ||
                  expert?.lastName_ru
                }
                position={
                  expert[`title_${language}`] ||
                  expert?.title_en ||
                  expert?.title_hy ||
                  expert?.title_ru
                }
                linkedin={expert?.linkedinLink}
                otherLink={expert?.link}
                description={
                  expert[`description_${language}`] ||
                  expert?.description_en ||
                  expert?.description_hy ||
                  expert?.description_ru
                }
                languages={expert?.selectedLanguage?.map(
                  ({ language }) => language
                )}
                expertise={expert?.spheres}
                directions={expert?.interestedIn}
                getInvolvedStatus={expert?.getInvolvedStatus}
                email={expert?.email}
                phone={expert?.phone}
                openModal={() => {}}
                lang={language}
                index={1}
                noButton
                isVolunteer={expert.isVolunteer}
              />
            </>
          )}
        </Box>
      </ModalBody>
    </Box>
  )
}
