import { HStack, useRadioGroup, Box, Flex } from '@chakra-ui/react'
import * as React from 'react'
import { RadioOption } from './RadioOption'

export const RadioGroup = ({
  name,
  options,
  onChange,
  defaultValue,
  price,
  currency,
  ...rest
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    onChange,
    defaultValue,
  })

  return (
    <HStack
      height="61px"
      py="6px"
      borderRadius="12px"
      spacing="2"
      overflow="hidden"
      bg="#EAECEF"
      px="2"
      {...getRootProps(rest)}
    >
      {options.map((value) => (
        <RadioOption key={value} {...getRadioProps({ value })}>
          <Flex alignItems="center" direction="column" borderRadius="12px">
            <Box fontWeight="600" as="span" fontSize="lg">
              {`${value}%`}
            </Box>
            <Box as="span" fontSize="sm" textAlign="center" lineHeight="12px">
              {Number((price * value) / 100).toFixed(2) + ` ${currency}`}
            </Box>
          </Flex>
        </RadioOption>
      ))}
    </HStack>
  )
}
