import { useEffect } from 'react'
import { useProject } from '../../ProjectContext'
import {
  reValidatePeerFormFields,
  PEER_MULTI_LANG_FIELD_AND_LANGUAGE_COMBINATIONS,
} from './validator'

export default function useRevalidatePeerForm(watch, errors, onRevalidate) {
  const { isPeerToPeerProject } = useProject()

  let reValidateDependencyArray = []

  if (isPeerToPeerProject) {
    reValidateDependencyArray =
      PEER_MULTI_LANG_FIELD_AND_LANGUAGE_COMBINATIONS.map((field) =>
        watch(field)
      )
  }

  useEffect(() => {
    if (isPeerToPeerProject) {
      reValidatePeerFormFields(watch, onRevalidate)
    }
  }, [errors, ...reValidateDependencyArray])
}
