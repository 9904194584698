import React, { useRef, useEffect, useState } from 'react'
import { LANGUAGES_SHORT, useTranslation } from 'contexts/TranslationContext'
import {
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Heading,
  Modal,
  ModalOverlay,
} from '@chakra-ui/react'
import {
  useCustomToast,
  useEditReport,
  useEditReportWithDate,
  useObjectManipulations,
} from 'hooks'
import ReportEditor from 'components/ReportEditor'
import useEmptyReport from 'components/ReportEditor/useEmptyReport'
import useCreateReport from 'hooks/report/useCreateReport'

function getStringFromHtml(strHtml) {
  const span = document.createElement('span')
  span.innerHTML = strHtml
  return span.textContent || span.innerText
}

const EditReportModal = ({
  onClose,
  isOpen,
  report,
  refetchReports,
  createdAt,
  action,
  projectId,
}) => {
  const { t, language } = useTranslation()
  const toast = useCustomToast()
  const { deleteKey } = useObjectManipulations()
  const generateEmptyReport = useEmptyReport()
  const reportRef = useRef(report)
  const { mutate: editReport, isLoading: isEditLoading } = useEditReport()
  const { mutate: editReportWithDate, isLoading: isEditWDateLoading } =
    useEditReportWithDate()
  const [errors, setErrors] = useState({})

  const { mutate: createReport, isLoading: isCreateLoading } = useCreateReport()

  const onReport = () => {
    const input = reportRef.current
    delete input.formattedDate
    delete input.fromNow
    input.language = language

    deleteKey(input, '__typename')
    if (Object.keys(errors).length) {
      console.log('❌', errors)
      return
    }

    if (
      input.data_en.includes(';base64,') ||
      input.data_ru.includes(';base64,') ||
      input.data_hy.includes(';base64,')
    ) {
      return toast({ status: 'error', title: 'Invalid content' })
    }
    if (
      !(
        getStringFromHtml(input.data_en).length &&
        getStringFromHtml(input.data_ru).length &&
        getStringFromHtml(input.data_hy).length
      )
    ) {
      return toast({
        status: 'error',
        title: 'Please fill reports in 3 languages',
      })
    }
    LANGUAGES_SHORT.forEach(
      (langForData) =>
        (input[`data_${langForData}`] = input[`data_${langForData}`].replaceAll(
          '<img ',
          '<img style="max-width: 100%;"'
        ))
    )
    if (action === 'create') {
      input.projectId = projectId
      createReport(input, {
        onSuccess: () => {
          toast({ status: 'success', title: t('report@publishSuccessMessage') })
          refetchReports()
          onClose()
        },
        onError: (err) => {
          toast({ status: 'error', title: 'Something went wrong' })
          console.log(err)
        },
      })
      return
    }

    const [oldCreatedAt, nextDate] = [
      new Date(createdAt).toISOString(),
      new Date(reportRef.current.createdAt).toISOString(),
    ]

    if (oldCreatedAt !== nextDate) {
      editReportWithDate(
        { oldCreatedAt, ...input },
        {
          onSuccess: () => {
            toast({ status: 'success', title: t('report@editSuccessMessage') })
            refetchReports()
            onClose()
          },
          onError: (err) => {
            toast({ status: 'error', title: 'Something went wrong' })
            console.log(err)
          },
        }
      )
      return
    }

    editReport(input, {
      onSuccess: () => {
        toast({ status: 'success', title: t('report@editSuccessMessage') })
        refetchReports()
        onClose()
      },
      onError: (err) => {
        toast({ status: 'error', title: 'Something went wrong' })
        console.log(err)
      },
    })
  }

  useEffect(() => {
    reportRef.current = report || generateEmptyReport()
  }, [report])

  const isLoading = isEditLoading || isEditWDateLoading || isCreateLoading
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="3xl"
      isCentered={window.innerHeight > 730}
    >
      <ModalOverlay />
      <ModalContent borderRadius="16px">
        <ModalHeader>
          <Heading fontWeight="bold" fontSize="lg">
            {action === 'create'
              ? t('report@creatingTitle')
              : t('report@editingTitle')}
          </Heading>
        </ModalHeader>
        <ModalCloseButton onClick={onClose} id="edit_report_modal_close" />
        <ModalBody pb={4}>
          <ReportEditor
            report={report}
            errors={errors}
            setErrors={setErrors}
            reportRef={reportRef}
          />
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} id="edit_report_modal_discard">
            {t('report@discard')}
          </Button>
          <Button
            variant="transparent"
            ml={4}
            color="green.300"
            isLoading={isLoading}
            onClick={onReport}
            id="edit_report_modal_save"
          >
            {action === 'create' ? t('report@publish') : t('report@save')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EditReportModal
