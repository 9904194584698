import { useEffect } from 'react'
import { Menu, MenuButton, MenuList, MenuItem, Button } from '@chakra-ui/react'
import { ReactComponent as ArrowDown } from 'assets/icons/angleDown.svg'
import { useTranslation } from 'contexts/TranslationContext'
import React, { useState } from 'react'
import { STATUS_ENUMS } from '_constants'

export default function StatusSelect({ defaultStatus, project, onChange }) {
  const { t } = useTranslation()
  const [selectedStatus, setSelectedStatus] = useState(defaultStatus)
  useEffect(() => {
    setSelectedStatus(defaultStatus)
  }, [defaultStatus])

  return (
    <Menu isLazy>
      <MenuButton
        textTransform="capitalize"
        fontWeight="normal"
        as={Button}
        leftIcon={<ArrowDown width="15px" height="10px" />}
      >
        {t(`status@${selectedStatus}`)}
      </MenuButton>
      <MenuList height="300px" overflowY="scroll">
        {Object.entries(STATUS_ENUMS['fundraisers']).map(([key]) => {
          if (
            key === 'live' &&
            (!project.tagline_en || !project.tagline_hy || !project.tagline_ru)
          ) {
            return null
          }
          return (
            <MenuItem
              key={`menu-${key}`}
              textTransform="capitalize"
              onClick={() => {
                setSelectedStatus(key)
                onChange({ status: key, id: project.id })
              }}
            >
              {t(`status@${key}`)}
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}
