const transactionRepo = (transactionService) => {
  const getTransaction = async (id) => {
    if (!id) {
      throw Error('ID is required')
    }
    return transactionService.getTransaction(id)
  }

  const listTransactions = async (variables) =>
    transactionService.listTransactions(variables)

  const listTransactionsByGiftCardCode = async (variables) => {
    return transactionService.listTransactionsByGiftCardCode(variables)
  }

  const listTransactionsByDate = async (variables) => {
    return transactionService.listTransactionsByDate(variables)
  }

  const listTransactionsByPeerProjectId = async (variables) => {
    return transactionService.listTransactionsByPeerProjectId(variables)
  }

  const listTransactionsByTopDonation = async (variables) => {
    return transactionService.listTransactionsByTopDonation(variables)
  }

  const createTransaction = async (input) =>
    transactionService.createTransaction(input)
  const updateTransaction = async (input) =>
    transactionService.updateTransaction(input)

  const searchTransactions = (condition) =>
    transactionService.searchTransactions(condition)

  const getTransactionsByEmailDate = (variables) =>
    transactionService.getTransactionsByEmailDate(variables)

  const getUserTransactionsByProjectIdEmail = (variables) =>
    transactionService.getUserTransactionsByProjectIdEmail(variables)
  return {
    searchTransactions,
    createTransaction,
    getTransaction,
    listTransactions,
    listTransactionsByGiftCardCode,
    listTransactionsByDate,
    listTransactionsByTopDonation,
    getTransactionsByEmailDate,
    updateTransaction,
    getUserTransactionsByProjectIdEmail,
    listTransactionsByPeerProjectId,
  }
}

export default transactionRepo
