import { HStack, Square } from '@chakra-ui/react'

const options = [200, 500, 1000, 1500, 2500, 3000]

export default function DonationAmountOptions({ value, onChange }) {
  return (
    <HStack
      justifyContent={{ base: 'center', md: 'space-between' }}
      flexFlow="wrap"
    >
      {options.map((option) => (
        <Square
          key={`option-${option}`}
          borderRadius="8px"
          px="5"
          py="2"
          fontSize="sm"
          transition="all 0.2s"
          cursor="pointer"
          border="1px solid"
          borderColor="#DDDDDD"
          _hover={{
            bg: 'blue.400',
            color: 'white',
          }}
          bg={value === option ? 'blue.400' : 'white'}
          color={value === option ? 'white' : 'gray.800'}
          _focus={{ shadow: '0 12px 14px 0 rgba(1,47,115,0.1)' }}
          onClick={() => onChange(option)}
        >
          {option}$
        </Square>
      ))}
    </HStack>
  )
}
