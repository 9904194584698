import { useMemo } from 'react'
import moment from 'moment'
import { GridItem, Flex, Text, Button } from '@chakra-ui/react'
import { Link, useNavigation } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import {
  useCurrencySettings,
  thousandSeparator,
  convert,
} from 'contexts/CurrencySettingsContext'
import ProjectCard from 'components/ProjectCard'
import { calculateProjectDurationLeft } from 'hooks'

export default function FilteredData({ data, filterStatus, type }) {
  const { t, language } = useTranslation()
  const { currency, settings, changeDefaultCurrency } = useCurrencySettings()
  const { navigationPush } = useNavigation()

  const filteredData = useMemo(() => {
    if (filterStatus[0] === 'all') return data
    return data?.filter((row) => filterStatus.includes(row.status))
  }, [data, filterStatus])

  const emptyTranslation = useMemo(() => {
    const dataType = type === 'fundraisers' ? 'Project' : 'Initiative'

    return t(`profile@no${dataType}`)
  }, [type, filterStatus])

  return (
    <>
      {filteredData
        ?.sort((a, b) => moment.utc(b.createdAt).diff(moment.utc(a.createdAt)))
        .map((slide, index) => (
          <GridItem key={`row-slide-${slide.id}-index-${index}`}>
            <ProjectCard
              card={slide}
              goalPrice={slide.activeGoal?.amount || slide.goal}
              raisedMoney={thousandSeparator(
                convert({
                  amount: slide.amount,
                  currencyRate: settings?.currency[currency.current],
                }),
                currency.current
              )}
              thousandSeparatorText={thousandSeparator(
                currency.current === 'AMD'
                  ? slide.amount + Number(slide.updateAmount)
                  : slide.amount /
                      Number(settings?.currency[currency.current]) +
                      Number(slide.updateAmount),
                currency.current
              )}
              width="full"
              type={type}
              calculatedProjectDurationLeft={calculateProjectDurationLeft({
                t,
                campaignImplementorPeriod_en:
                  slide.campaignImplementorPeriod_en,
                fundraisingType: slide.fundraisingType,
              })}
              isMobile
              language={language}
              currency={currency}
              settings={settings}
              changeDefaultCurrency={changeDefaultCurrency}
              navigationPush={navigationPush}
              cardStatusText={t(`status@${slide.status}`)}
              cardCategoryText={t(`category@${slide.category}`)}
              editText={t('edit')}
              unsubscribeText={t('unsubscribe')}
              monthlyGoalText={t('monthlyGoal')}
              raisedText={t('raised')}
              collaborationProposalsText={t(
                slide?.collaboratorsTotal === 1
                  ? 'collaboration proposal'
                  : 'collaboration proposals'
              )}
              collaborationProposalsTextRuEdit={t('collaboration proposals')
                .split('...')[1]
                .replace('запросов', 'запроса')}
            />
          </GridItem>
        ))}
      <Flex
        w="full"
        position="absolute"
        flexDirection="column"
        justify="center"
        align="center"
      >
        {filteredData?.length < 1 && (
          <Flex>
            <Text>{emptyTranslation}</Text>
          </Flex>
        )}
        {data?.length < 1 && (
          <Link to={`/create-${type}`}>
            <Button color="blue.400" variant="transparent" right="0">
              {t(
                `profile@create${
                  type === 'fundraisers' ? 'Project' : 'Initiative'
                }`
              )}
            </Button>
          </Link>
        )}
      </Flex>
    </>
  )
}
