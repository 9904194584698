import { useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'contexts/TranslationContext'
import { useQueryParams } from 'hooks'
import Application from './Application'
import Content from './Content'
import Rewards from './Rewards'
import Updates from './Updates'
import Reports from './Reports'
import Donors from './Donors'

export default function useRoutes({
  status,
  transactions,
  isEditMode,
  isPeerToPeerProject,
}) {
  const { t } = useTranslation()
  const location = useLocation()
  const { projectId, peerProjectId } = useParams()
  const params = useQueryParams()
  const page = location.pathname.split('/')[2].split('-')[1]
  const showBoth = params.has('showBoth')
  const hasTransactions = transactions?.length

  if (isPeerToPeerProject) {
    if (isEditMode) {
      const routes = [
        {
          path: `/:lang/edit-peer-to-peer-fundraiser/:projectId/content`,
          to: `/edit-peer-to-peer-fundraiser/${projectId}/content`,
          component: Content,
          label: t('createEditProject@content'),
        },
      ]

      if (
        [
          'live',
          'ended',
          'implemented',
          'launching_soon',
          'waiting_for_transfer',
          'closed',
          'terminated',
        ].includes(status)
      ) {
        routes.push({
          path: `/:lang/edit-peer-to-peer-fundraiser/:projectId/updates`,
          to: `/edit-peer-to-peer-fundraiser/${projectId}/updates`,
          component: Updates,
          label: t('updates'),
        })
        routes.push({
          path: `/:lang/edit-peer-to-peer-fundraiser/:projectId/reports`,
          to: `/edit-peer-to-peer-fundraiser/${projectId}/reports`,
          component: Reports,
          label: t('reports'),
        })
      }

      if (hasTransactions) {
        routes.push({
          path: `/:lang/edit-peer-to-peer-fundraiser/:projectId/donors`,
          to: `/edit-peer-to-peer-fundraiser/${projectId}/donors`,
          component: Donors,
          label: t('Donors'),
        })
      }

      return routes
    }

    return [
      {
        path: `/:lang/create-peer-to-peer-fundraiser/:peerProjectId/content`,
        to: `/create-peer-to-peer-fundraiser/${peerProjectId}/content`,
        component: Content,
        label: t('createEditProject@content'),
      },
    ]
  }

  const routes = [
    {
      path: [
        '/:lang/edit-fundraisers/:projectId/application',
        '/:lang/edit-collaborations/:projectId/application',
      ],
      to: `/edit-${page}/${projectId}/application`,
      component: Application,
      label: t('createEditProject@application'),
    },
  ]

  if (status !== 'rejected_by_ss' && status !== 'submitted') {
    routes.splice(1, 0, {
      path: `/:lang/edit-${page}/:projectId/content`,
      to: `/edit-${page}/${projectId}/content`,
      component: Content,
      label: t('createEditProject@content'),
    })
  }
  if (
    status !== 'rejected_by_ss' &&
    status !== 'submitted' &&
    page !== 'collaborations'
  ) {
    routes.splice(2, 0, {
      path: `/:lang/edit-${page}/:projectId/rewards`,
      to: `/edit-${page}/${projectId}/rewards`,
      component: Rewards,
      label: t('createEditProject@rewards'),
    })
  }
  if (
    [
      'live',
      'ended',
      'implemented',
      'launching_soon',
      'waiting_for_transfer',
      'closed',
      'terminated',
    ].includes(status)
  ) {
    routes.splice(4, 0, {
      path: `/:lang/edit-${page}/:projectId/updates`,
      to: `/edit-${page}/${projectId}/updates`,
      component: Updates,
      label: t('updates'),
    })
    routes.splice(5, 0, {
      path: `/:lang/edit-${page}/:projectId/reports`,
      to: `/edit-${page}/${projectId}/reports`,
      component: Reports,
      label: t('reports'),
    })
  }

  if (hasTransactions) {
    routes.push({
      path: `/:lang/edit-${page}/:projectId/donors`,
      to: `/edit-${page}/${projectId}/donors`,
      component: Donors,
      label: t('Donors'),
    })
  }

  if (showBoth) {
    return routes.map((route) => ({ ...route, to: route.to + '?showBoth' }))
  }

  return routes
}
