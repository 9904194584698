import React from 'react'

export default function FbPixel({ pixelId }) {
  return (
    <img
      height="1"
      width="1"
      style={{ display: 'none' }}
      alt="fb-pixel"
      src={`https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`}
    />
  )
}
