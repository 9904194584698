import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Divider,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react'
import { Link } from '../../pages'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { ReactComponent as Lock } from '../../assets/icons/blue-lock.svg'

const TabItem = ({
  label,
  Icon,
  pathname,
  children,
  divider,
  heading,
  onClick,
  ArrowRight,
  onClose,
  color,
  fontWeight,
  isLocked,
  t,
}) => {
  const location = useLocation()

  if (children) {
    return (
      <Accordion allowMultiple>
        <AccordionItem border="none" px="30px">
          <AccordionButton
            minH={12}
            p={2}
            _expanded={{ bg: 'transparent' }}
            _focus={{ boxShadow: 'none', bg: 'transparent' }}
          >
            <Flex flex={1}>
              {Icon && <Icon style={{ marginRight: '12px' }} />}
              <Text fontWeight={400} fontSize="lg" mr={2} textAlign="left">
                {label}
              </Text>
            </Flex>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel py={3} pl="34px" overflow="visible">
            {children.map(({ label, pathname }) => (
              <Link to={pathname} key={`${pathname}-${label}`}>
                <Flex
                  fontWeight={400}
                  fontSize="lg"
                  alignItems="center"
                  py={2.5}
                  color={
                    location.pathname.includes(pathname.split('/')[2])
                      ? 'blue.400'
                      : 'gray.800'
                  }
                >
                  {label}
                </Flex>
              </Link>
            ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    )
  }

  if (divider) {
    return <Divider h="1px" bg="gray.600" my={'4'} opacity={1} />
  }

  if (heading) {
    return (
      <Heading
        fontWeight={400}
        fontSize="md"
        color="gray.650"
        h={12}
        px={5}
        display="flex"
        alignItems="center"
      >
        {heading}
      </Heading>
    )
  }

  if (onClick) {
    return (
      <Flex
        h={12}
        px="32px"
        color={color}
        fontWeight={fontWeight}
        fontSize="lg"
        alignItems="center"
        as="button"
        onClick={onClick}
      >
        {Icon && (
          <Icon
            style={{
              marginRight: '12px',
              width: '24px',
              height: '24px',
              color: color,
            }}
          />
        )}

        {label}
      </Flex>
    )
  }

  return (
    <Flex
      fontWeight={fontWeight}
      fontSize="lg"
      alignItems="center"
      as={Link}
      to={pathname}
      onClick={onClose}
      h={12}
      px={8}
      color={isLocked ? 'blue.400' : color}
      pos="relative"
      outline="none"
    >
      {isLocked ? (
        <Lock
          style={{
            marginRight: '12px',
            width: '24px',
            height: '24px',
          }}
        />
      ) : Icon ? (
        <Icon
          style={{
            marginRight: '12px',
            width: '24px',
            height: '24px',
            color: color,
          }}
        />
      ) : (
        ''
      )}
      {label}
      {isLocked && (
        <Text
          px={1}
          border="1px solid"
          borderColor="blue.400"
          fontSize="xs"
          fontWeight={700}
          borderRadius={2}
          ml={3}
          color="blue.400"
        >
          {t('unlock')}
        </Text>
      )}
      {ArrowRight && (
        <ArrowRight
          style={{
            width: '10px',
            height: '10px',
            position: 'absolute',
            right: '32px',
          }}
        />
      )}
    </Flex>
  )
}

export default TabItem
