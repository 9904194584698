import React, { useEffect } from 'react'
import { Box, AspectRatio, Flex } from '@chakra-ui/react'
import Header from './Header'
import Information from './Information'
import Tabs from './Tabs'
import Perks from './Perks'
import Share from './Share'
import NavbarButtons from './NavbarButtons'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as Play } from 'assets/icons/play2.svg'
import ImageWrapper from 'components/ImageWrapper'
import ProjectHelperInfo from 'components/ProjectHelperInfo'
import amplitude from 'amplitude-js'
import { useQueryParams } from 'hooks'

const MobileProject = ({
  project,
  projectId,
  play,
  setPlay,
  getId,
  tabsSectionRef,
  url,
  param,
  updates,
  isUpdatesLoading,
  giftCardMode,
  reports,
  peerProject,
  isReportsLoading,
}) => {
  const { language, t } = useTranslation()
  const queryParams = useQueryParams()

  useEffect(() => {
    if (queryParams.get('tab') === 'story' && project?.isProject) {
      amplitude
        .getInstance()
        .logEvent('Mobile fundraisers details page story tab view')
      console.log(
        'sent event to amplitude: Mobile fundraisers details page story tab view'
      )
    }
    if (queryParams.get('tab') === 'updates' && project?.isProject) {
      amplitude
        .getInstance()
        .logEvent('Mobile fundraisers details page updates tab view')
      console.log(
        'sent event to amplitude: Mobile fundraisers details page updates tab view'
      )
    }
  }, [queryParams.get('tab'), project])

  return (
    <Box>
      <Header project={project} />
      <AspectRatio h="211px" w="100%" ratio={16 / 9}>
        {project && project[`video_${language}`] ? (
          play ? (
            <Flex
              height="100%"
              width="100%"
              zIndex="5"
              allow="autoplay"
              as="iframe"
              title="Armenia"
              allowFullScreen
              src={`https://www.youtube.com/embed/${getId(
                project[`video_${language}`]
              )}${url}`}
            />
          ) : (
            <Flex
              bgImage={`url(https://img.youtube.com/vi/${getId(
                project[`video_${language}`]
              )}/0.jpg)`}
              height="100%"
              bgSize="cover"
              bgPos="center"
              bgRepeat="no-repeat"
              width="100%"
            >
              <Play
                cursor="pointer"
                onClick={() => setPlay(true)}
                p="5"
                backgroundColor="rgba(255, 255, 255, .5)"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="12px"
                width="50px"
                height="50px"
              />
            </Flex>
          )
        ) : project?.cover ? (
          <ImageWrapper
            src={`/thumbnail/medium/${project.cover_key || project.cover}`}
            alt="Project"
            style={{ objectFit: 'cover' }}
            width="100%"
            height="100%"
          />
        ) : (
          <Box bg="border.100" width="100%" height="100%" />
        )}
      </AspectRatio>
      <Box flex="1" px="5" pt="4">
        <Information initiator={false} project={project} />
      </Box>
      {project.fundraisingType === 'recurring' && !project.isCompany && (
        <ProjectHelperInfo
          title={t('project@recurringHelperTitle')}
          description={t('project@recurringHelperDescription')}
          mx={4}
          px={7}
        />
      )}

      {project.isCollaboration && (
        <ProjectHelperInfo
          title={t('collaboration@recurringHelperTitle')}
          description={t('collaboration@recurringHelperDescription')}
          mx={4}
          px={7}
        />
      )}
      <Tabs
        tabsSectionRef={tabsSectionRef}
        updates={updates}
        isUpdatesLoading={isUpdatesLoading}
        reports={reports}
        isReportsLoading={isReportsLoading}
        project={project}
        peerProject={peerProject}
        projectId={projectId}
      />
      <Perks param={param} project={project} />
      <Box py="10">
        <Share project={project} />
      </Box>

      {project.status === 'live' && (
        <NavbarButtons
          param={param}
          project={project}
          peerProject={peerProject}
          giftCardMode={giftCardMode}
        />
      )}
    </Box>
  )
}

export default MobileProject
