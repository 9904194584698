const giftCardUseCase = (giftCardRepo) => {
  const getGiftCard = async (id) => {
    const response = await giftCardRepo.getGiftCard(id)
    return response.data.getGiftCard
  }
  const listGiftCards = async (variables) =>
    giftCardRepo.listGiftCards(variables)

  const listGiftCardsByCode = async (variables) => {
    const giftCardResponse = await giftCardRepo.listGiftCardsByCode(variables)
    const giftCard = giftCardResponse.data.giftCardsByCode.items[0]
    return giftCard || null
  }

  const createGiftCard = async (input) => giftCardRepo.createGiftCard(input)
  const updateGiftCard = async (input) => giftCardRepo.updateGiftCard(input)

  return {
    createGiftCard,
    getGiftCard,
    listGiftCards,
    listGiftCardsByCode,
    updateGiftCard,
  }
}

export default giftCardUseCase
