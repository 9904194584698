import { Flex, Switch as ChakraSwitch, Text } from '@chakra-ui/react'
import React from 'react'

export default function Switch({
  label,
  onChange = () => {},
  containerProps,
  isChecked,
  setChecked,
  ...rest
}) {
  const handleChange = () => {
    setChecked(!isChecked)
    onChange(!isChecked)
  }

  return (
    <Flex alignItems="center" {...containerProps}>
      <ChakraSwitch
        size={{ base: 'md', sm: 'sm' }}
        mr={2}
        {...rest}
        isChecked={isChecked}
        onChange={handleChange}
      />
      {label && (
        <Text
          onClick={handleChange}
          cursor="pointer"
          fontSize={{ base: 'lg', sm: 'md' }}
        >
          {label}
        </Text>
      )}
    </Flex>
  )
}
