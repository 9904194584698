import { Icon, Circle } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { useBasket } from 'contexts/BasketContext'
import { ReactComponent as BasketIcon } from 'assets/icons/basket.svg'
import { ReactComponent as BasketFilledIcon } from 'assets/icons/basket-fill.svg'
import { Link } from 'pages'

export default function BasketIconButton({ variant = 'black' }) {
  const { basket } = useBasket()
  const location = useLocation()

  const isBasketPage = location.pathname.includes('/basket')

  return (
    <Link to="/basket/items" display="flex" position="relative">
      <Icon
        as={isBasketPage ? BasketFilledIcon : BasketIcon}
        __css={{ path: { fill: variant } }}
        w="24px"
        h="24px"
      />
      {basket?.length > 0 && (
        <Circle
          size="11px"
          border="1px solid"
          borderColor="white"
          bg="red.400"
          position="absolute"
          top={0.5}
          right={0.5}
        />
      )}
    </Link>
  )
}
