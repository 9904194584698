import lodash from 'lodash'
import deepdash from 'deepdash-es'

export const useObjectManipulations = () => {
  const _ = deepdash(lodash)

  const deleteKey = (obj, key) => {
    _.eachDeep(obj, (child, prop, parent, ctx) => {
      if (prop === key) {
        delete parent[prop]
        return false
      }
      return true
    })
  }
  const safeParser = (obj) => {
    let result = {}
    if (!obj) {
      return result
    }
    try {
      if (obj.length > 2) {
        result = JSON.parse(obj)
      }
    } catch (error) {
      console.log('---', error)
      result = {}
    }
    return result
  }

  return { deleteKey, safeParser }
}
export default useObjectManipulations
