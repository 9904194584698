import { useCallback, useEffect, useState } from 'react'
import {
  VStack,
  Button,
  Flex,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'contexts/TranslationContext'
import { useUser, useCreateTransaction, useUpdateProject } from 'hooks'
import { InputField } from 'components/InputField'
import { BlueSelect } from 'components/Select/BlueSelect'
import {
  SUPPORTED_CURRENCIES,
  useCurrencySettings,
} from 'contexts/CurrencySettingsContext'
import {
  TRANSACTION_STATUS,
  TRANSACTION_STATUS_DESCRIPTION,
} from 'pages/Donation/constants'

export default function ManualTransaction({ project, projectIds }) {
  const [transactionCurrency, setTransactionCurrency] = useState('AMD')
  const [currencyRate, setCurrencyRate] = useState(1)

  const { t, language } = useTranslation()
  const { user } = useUser()
  const { settings } = useCurrencySettings()
  const { isLoading: isProjectUpdateLoading } = useUpdateProject()
  const { mutate: createTransaction, isLoading: isCreateTransactionLoading } =
    useCreateTransaction()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      amount: 1,
      donationToReArmenia: 0,
    },
  })
  const updateCurrency = (curr) => {
    setTransactionCurrency(curr)
    setValue('donationToProject', 0)
    setValue('donationToReArmenia', 0)
  }
  // const getTotalInput = (project, amount) => {
  //   const totalInput = {
  //     ...project.total,
  //     total: project.total.total + amount,
  //     oneTime: project.total.oneTime + amount,
  //     monthlyOneTime: JSON.parse(project.total.monthlyOneTime),
  //   }
  //   const currentDate = new Date()
  //   const month = currentDate.getMonth() + 1
  //   const year = currentDate.getFullYear()
  //   const monthlyOneTimeObjectKey = `${month}-${year}`

  //   if (totalInput.monthlyOneTime[monthlyOneTimeObjectKey]) {
  //     totalInput.monthlyOneTime[monthlyOneTimeObjectKey] += amount
  //   }

  //   if (project.fundraisingType === 'recurring') {
  //     const amount =
  //       totalInput.subscriptions +
  //       (totalInput.monthlyOneTime[monthlyOneTimeObjectKey] || 0)
  //     const goal = project.activeGoal
  //     totalInput.goalMetPercentage = (amount * 100) / goal.amount
  //   } else {
  //     if (project.goals && project.goals.length > 0 && project.activeGoal) {
  //       const goal = project.activeGoal
  //       totalInput.goalMetPercentage = (totalInput.total * 100) / goal.amount
  //     } else {
  //       totalInput.goalMetPercentage =
  //         (totalInput.total * 100) / (project.goal || project.totalAmount)
  //     }
  //   }

  //   totalInput.monthlyOneTime = JSON.stringify(totalInput.monthlyOneTime)

  //   return totalInput
  // }

  useEffect(() => {
    if (transactionCurrency === 'AMD') {
      setCurrencyRate(1)
      return
    }
    if (settings.currency[transactionCurrency]) {
      setCurrencyRate(settings.currency[transactionCurrency])
    }
  }, [transactionCurrency])

  const onSubmit = useCallback(
    async (values) => {
      const rate = currencyRate
      const donationToProject = Number(values.donationToProject) * rate
      const donationToReArmenia = Number(values.donationToReArmenia) * rate
      const totalAmount = donationToProject + donationToReArmenia
      const createdAt = new Date().toISOString()
      const input = {
        amount: totalAmount,
        userId: user.id,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        projectImage: project?.cover || project?.creatorImage,
        donationToProject,
        donationToReArmenia: donationToReArmenia,
        projectId: projectIds.projectToDonateId,
        isManual: true,
        currency: transactionCurrency,
        currencyRate,
        language,
        ['projectTitle_' + language]: project[`title_${language}`],
        createdAt,
        updatedAt: createdAt,
        status: TRANSACTION_STATUS.DONE,
        statusDescription: TRANSACTION_STATUS_DESCRIPTION.DONE,
      }

      if (projectIds.peerProjectId) {
        input.peerProjectId = projectIds.peerProjectId
      }

      createTransaction(input, {
        onSuccess: () => {
          window.location.reload()
        },
      })
    },
    [project, transactionCurrency, settings, currencyRate, projectIds]
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack alignItems="flex-start" spacing="4" width="full">
        <InputField
          isDisabled={isProjectUpdateLoading || isCreateTransactionLoading}
          label={t('payment@firstName')}
          errorMessage={t('payment@firstNameErrorMessage')}
          isInvalid={!!errors.firstName}
          {...register('firstName', { required: true })}
        />
        <InputField
          isDisabled={isProjectUpdateLoading || isCreateTransactionLoading}
          label={t('payment@lastName')}
          errorMessage={t('payment@lastNameErrorMessage')}
          {...register('lastName')}
        />
        <InputField
          isDisabled={isProjectUpdateLoading || isCreateTransactionLoading}
          label={t('payment@email')}
          errorMessage={t('payment@emailErrorMessage')}
          isInvalid={!!errors.email}
          {...register('email', { required: true })}
        />
        <InputGroup>
          <InputLeftElement
            width="100px"
            ml={4}
            top="25px"
            children={
              <BlueSelect
                variant="big"
                display="flex"
                type="currency"
                label={transactionCurrency}
                options={SUPPORTED_CURRENCIES.map((option) => ({
                  title: t(`currency@${option}`),
                  value: option,
                }))}
                currentSelectedOption={t(`currency@${transactionCurrency}`)}
                selectOption={updateCurrency}
                width="100px"
              />
            }
          />
          <InputField
            pl="100px"
            isDisabled={isProjectUpdateLoading || isCreateTransactionLoading}
            label={t('donation@reArmeniaTip')}
            pattern="[0-9]*"
            isInvalid={errors.donationToReArmenia}
            {...register('donationToReArmenia', { pattern: /[0-9]/ })}
          />
        </InputGroup>
        <InputGroup alignItems="center">
          <InputLeftElement
            width="100px"
            ml={4}
            top="25px"
            children={
              <BlueSelect
                variant="big"
                display="flex"
                type="currency"
                label={transactionCurrency}
                options={SUPPORTED_CURRENCIES.map((option) => ({
                  title: t(`currency@${option}`),
                  value: option,
                }))}
                currentSelectedOption={t(`currency@${transactionCurrency}`)}
                selectOption={updateCurrency}
                width="100px"
              />
            }
          />
          <InputField
            isDisabled={isProjectUpdateLoading || isCreateTransactionLoading}
            type="number"
            label={t('donation@yourDonation')}
            isInvalid={!!errors.donationToProject}
            errorMessage={t('donation@yourDonationRequired')}
            pattern="[0-9]*"
            inputLeft
            pl="100px"
            {...register('donationToProject', {
              required: true,
              pattern: /[0-9]/,
              min: 1,
            })}
          />
        </InputGroup>

        <Flex w="100%" justifyContent="flex-end">
          <Button
            variant="green"
            type="submit"
            isLoading={isProjectUpdateLoading || isCreateTransactionLoading}
          >
            {t('submit')}
          </Button>
        </Flex>
      </VStack>
    </form>
  )
}
