import React, { Fragment, useState } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Flex,
  Image,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import {
  useDonationSubscriptionsByUserEmail,
  useUnsubscribeFromBasketItem,
  useUser,
} from 'hooks'
import ImageWrapper from 'components/ImageWrapper'

export default function ListBasketSubscriptions() {
  const { t, language } = useTranslation()
  const { user, getUser } = useUser()
  const { donationSubscriptions, isLoading, refetch } =
    useDonationSubscriptionsByUserEmail(
      {
        userEmail: user.email,
        filter: { basketId: { attributeExists: true } },
        sortDirection: 'DESC',
      },
      false
    )
  const { mutateAsync: unsubscribe, isLoading: isUnsubscribeLoading } =
    useUnsubscribeFromBasketItem()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [activeCard, setActiveCard] = useState(null)

  const onAlertOpen = (e, subscription, basketItem) => {
    e.preventDefault()
    setActiveCard({ subscription, basketItem })
    onOpen()
  }

  const onUnsubscribe = async (e, data) => {
    e.stopPropagation()
    try {
      await unsubscribe({
        subscriptionId: data.subscription.id,
        projectId: data.basketItem.projectId,
      })
      onClose()
      getUser()
      refetch()
    } catch (e) {
      console.log('>>> UNSUBSCRIBE ERROR', e)
    }
  }

  return (
    <>
      {isLoading ? (
        <Center h="100%">
          <Spinner />
        </Center>
      ) : (
        <VStack spacing={4} align="stretch" p={{ base: 5, md: 0 }}>
          {donationSubscriptions?.map((subscription) => (
            <Fragment key={`manage-subscriptions-${subscription.id}`}>
              {subscription.basket?.map((basketItem) => (
                <Flex
                  key={`manage-subscriptions-${subscription.id}-${basketItem.projectId}`}
                  p={4}
                  justify="space-between"
                  boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0.06), 0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0px 16px 6px 0px rgba(0, 0, 0, 0.01), 0px 25px 7px 0px rgba(0, 0, 0, 0.00)"
                  rounded="lg"
                >
                  <Flex align="center" mr="5px">
                    <DonationImage image={basketItem?.projectCover} />

                    <Text
                      fontWeight={600}
                      fontSize="lg"
                      color="gray.800"
                      noOfLines={1}
                      height="fit-content"
                    >
                      {basketItem[`projectTitle_${language}`]}
                    </Text>
                  </Flex>

                  <Flex>
                    {basketItem?.unsubscribedDate ? (
                      <Button
                        height={12}
                        minH={12}
                        textTransform="none"
                        variant="lightGray"
                        fontSize="lg"
                        _hover={{}}
                        disabled
                        px={6}
                        flexShrink={0}
                      >
                        {t('profile@dashboard@investor@recurring@unsubscribed')}
                      </Button>
                    ) : (
                      <Button
                        height={12}
                        minH={12}
                        textTransform="none"
                        fontSize="lg"
                        variant="white"
                        px={6}
                        flexShrink={0}
                        onClick={(e) =>
                          onAlertOpen(e, subscription, basketItem)
                        }
                      >
                        {t('profile@dashboard@investor@recurring@unsubscribe')}
                      </Button>
                    )}
                  </Flex>
                </Flex>
              ))}
            </Fragment>
          ))}
        </VStack>
      )}
      <AlertDialogModal
        t={t}
        onClose={onClose}
        data={activeCard}
        isOpen={isOpen}
        isLoading={isUnsubscribeLoading}
        onUnsubscribe={onUnsubscribe}
      />
    </>
  )
}

const AlertDialogModal = ({
  t,
  onClose,
  isOpen,
  onUnsubscribe,
  isLoading,
  data = null,
}) => {
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent p={10}>
        <AlertDialogBody
          p={0}
          mb={5}
          fontSize="2xl"
          fontWeight={600}
          color="black"
        >
          {t('profile@dashboard@investor@recurring@areYouSure')}
        </AlertDialogBody>
        <AlertDialogFooter p={0}>
          <Button
            isDisabled={isLoading}
            minH="20px"
            minW="100px"
            variant="white"
            onClick={onClose}
          >
            {t('profile@dashboard@investor@recurring@no')}
          </Button>
          <Button
            onClick={(e) => onUnsubscribe(e, data)}
            minH="20px"
            minW="100px"
            variant="blue"
            colorScheme="red"
            ml={3}
            isLoading={isLoading}
            isDisabled={isLoading}
          >
            {t('profile@dashboard@investor@recurring@yes')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

const DonationImage = ({ image }) => (
  <Box w="50px" h="50px" mr={3} flexShrink={0}>
    {image ? (
      <ImageWrapper
        borderRadius="50%"
        cursor="pointer"
        objectFit="cover"
        width="50px"
        bgColor="border.100"
        h="50px"
        ignoreFallback={true}
        src={`/thumbnail/small/${image}`}
      />
    ) : (
      <Image
        cursor="pointer"
        objectFit="cover"
        borderRadius="50%"
        bgColor="border.100"
        ignoreFallback={true}
        src="/assets/images/noImg.svg"
      />
    )}
  </Box>
)
