import { useState } from 'react'
import {
  InputGroup,
  InputLeftAddon,
  Input,
  Icon,
  Spinner,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as SearchSmallIcon } from 'assets/icons/searchSmall.svg'
import { useDebounce, useLazyEffect } from 'hooks'

export default function Search({ isFetching, onSearch }) {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')

  const debouncedValue = useDebounce(search, 500)

  const onChange = (e) => setSearch(e.target.value)

  useLazyEffect(() => {
    if (typeof onSearch === 'function') {
      onSearch(debouncedValue)
    }
  }, [debouncedValue])

  return (
    <InputGroup
      backgroundColor="gray.400"
      h="72px"
      borderRadius="16px"
      border="none"
    >
      <InputLeftAddon
        h="inherit"
        backgroundColor="inherit"
        borderRadius="inherit"
        border="inherit"
        pr={2}
        w="44px"
      >
        {isFetching ? (
          <Spinner w="17px" h="17px" />
        ) : (
          <Icon as={SearchSmallIcon} h="24px" w="24px" />
        )}
      </InputLeftAddon>
      <Input
        h="inherit"
        borderRadius="inherit"
        border="inherit"
        pl={0}
        placeholder={t('Find a Fundraiser to support to')}
        value={search}
        onChange={onChange}
      />
    </InputGroup>
  )
}
