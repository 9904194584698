import {
  LANG_EN_SHORT,
  LANG_HY_SHORT,
  LANG_RU_SHORT,
} from 'contexts/TranslationContext'

const PEER_MULTI_LANG_REQUIRED_FIELDS = ['creatorName', 'title', 'tagline']

const LANGUAGES = [LANG_EN_SHORT, LANG_RU_SHORT, LANG_HY_SHORT]
export const PEER_MULTI_LANG_FIELD_AND_LANGUAGE_COMBINATIONS =
  PEER_MULTI_LANG_REQUIRED_FIELDS.reduce((acc, field) => {
    LANGUAGES.forEach((lang) => {
      acc.push(`${field}_${lang}`)
    })

    return acc
  }, [])

export function validatePeerFormData(data) {
  const GENERAL_FIELDS = ['goal', 'phoneNumber']

  const generalValues = Object.keys(data).reduce((acc, field) => {
    if (GENERAL_FIELDS.includes(field)) {
      acc[field] = data[field]
    }
    return acc
  }, {})
  const invalidGeneralFields = Object.keys(generalValues).filter(
    (field) => !generalValues[field]
  )
  const isGeneralFieldsValid = invalidGeneralFields.length === 0

  // detect missing lang -> field
  const { order: invalidFieldOrdering, invalidFields: invalidMultiLangFields } =
    LANGUAGES.reduce(
      (acc, language) => {
        const invalidLangsForFiled = PEER_MULTI_LANG_REQUIRED_FIELDS.reduce(
          (acc2, field) => {
            const fieldName = `${field}_${language}`
            if (!data[fieldName]) {
              acc2.push(field)
            }
            return acc2
          },
          []
        )

        if (invalidLangsForFiled.length > 0) {
          acc.order[language] = invalidLangsForFiled.length
          acc.invalidFields[language] = invalidLangsForFiled
        }

        return acc
      },
      { order: {}, invalidFields: {} }
    )
  const isMultiLangFieldsValid = Object.keys(invalidFieldOrdering).length === 0

  const sortedValidLanguagesData = []
  for (const language in invalidFieldOrdering) {
    sortedValidLanguagesData.push({
      language: language,
      count: invalidFieldOrdering[language],
    })
  }

  sortedValidLanguagesData.sort((a, b) => a.count - b.count) // most valid lang fields first

  const sortedInvalidLanguages = sortedValidLanguagesData.map(
    (item) => item.language
  )

  const isOneLanguageValidOfMultiLangFields =
    sortedInvalidLanguages.length < LANGUAGES.length

  return {
    sortedInvalidLanguages,
    isGeneralFieldsValid,
    invalidGeneralFields,
    isMultiLangFieldsValid,
    invalidMultiLangFields,
    isOneLanguageValidOfMultiLangFields,
  }
}

export function reValidatePeerFormFields(watch, onRevalidate) {
  const form = watch()
  for (const multiLangField of PEER_MULTI_LANG_REQUIRED_FIELDS) {
    let filledInAnyLanguage = false
    for (const lang of LANGUAGES) {
      const result = form[`${multiLangField}_${lang}`]
      if (!filledInAnyLanguage) {
        filledInAnyLanguage = !!result
      }
    }
    if (filledInAnyLanguage) {
      onRevalidate(LANGUAGES.map((l) => `${multiLangField}_${l}`))
    }
  }
}
