import { useState, useEffect } from 'react'
import { Button, useClipboard } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import useCustomToast from 'hooks/useCustomToast'

export default function ActNow({
  buttonRef = null,
  initiative,
  fontWeight = 'bold',
  width = 'full',
  height = '56px',
  onOpen,
}) {
  const [sharingLink, setShareLink] = useState('')
  const { t, language } = useTranslation()
  const { hasCopied } = useClipboard(sharingLink)
  const toast = useCustomToast()

  useEffect(() => {
    if (!initiative) return
    setShareLink(
      `${process.env.REACT_APP_BASE_URL}/${language}/collaborations/${
        initiative.slug || initiative.id
      }`
    )
  }, [initiative])

  useEffect(() => {
    if (!hasCopied) return
    toast({
      title: t('copied'),
      status: 'success',
      duration: 5000,
      isClosable: true,
    })
  }, [hasCopied])

  return (
    <Button
      ref={(el) => {
        if (!el || !buttonRef) return
        buttonRef.current = el.getClientRects()[0]
      }}
      textTransform="capitalize"
      minH={height}
      borderRadius="8px"
      maxW="200px"
      display={{ base: 'inline-flex', sm: 'inline-flex' }}
      width={width}
      fontWeight={fontWeight}
      variant="blue"
      onClick={() => onOpen()}
    >
      {t('support')}
    </Button>
  )
}
