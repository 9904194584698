import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { ReactComponent as GiftIcon } from 'assets/icons/gift.svg'
import { useTranslation } from 'contexts/TranslationContext'

export default function GiftCardBanner({
  border,
  giftCardAmount,
  giftCardCurrency,
  ...props
}) {
  const { t } = useTranslation()
  return (
    <>
      <Flex
        w="full"
        background="blue.200"
        px={4}
        py={2}
        minH="48px"
        align="center"
        borderRadius="8px"
        fontSize="lg"
        {...props}
      >
        <Box width="25px" mr={2}>
          <GiftIcon />
        </Box>{' '}
        <Text color="white" fontWeight={600} ml={2}>
          {t('giftCard@modal@title')}{' '}
          {giftCardAmount
            ? t(`giftCardBannerAmount`).replace(
                '[amount]',
                `${giftCardAmount} ${giftCardCurrency}`
              )
            : t('giftCard@modal@description')}
        </Text>
      </Flex>
      {border && <Divider borderColor="border.100" my={8} />}
    </>
  )
}
