import { Box, Flex } from '@chakra-ui/react'
import { ReactComponent as CheckedIcon } from 'assets/icons/checkedWhite.svg'
import { ReactComponent as UnCheckedIcon } from 'assets/icons/unchecked.svg'
import { ReactComponent as CheckedCheckboxIcon } from 'assets/icons/checkboxChecked.svg'

const MenuItem = ({ isChecked, children, variant, ...rest }) => (
  <Flex
    bg={variant === 'white' ? 'white' : 'blue.400'}
    w="full"
    py={2}
    px={variant === 'white' ? 4 : 0}
    cursor="pointer"
    _hover={{
      background: {
        base: 'none',
        sm: variant === 'white' ? 'gray.300' : '#264d8c',
      },
    }}
    alignItems="center"
    {...rest}
  >
    {variant === 'white' ? (
      <Box mr={3}>
        {isChecked ? <CheckedCheckboxIcon /> : <UnCheckedIcon />}
      </Box>
    ) : (
      <Box mx={3} w="11px">
        {isChecked && <CheckedIcon />}
      </Box>
    )}
    <Box>{children} </Box>
  </Flex>
)

export default MenuItem
