import React from 'react'
import { Flex, Heading, Box } from '@chakra-ui/react'
import { ReactComponent as Close } from 'assets/icons/closeBlack.svg'
import { useHistory } from 'react-router-dom'
import Payment from 'components/Payment'
import { useTranslation } from 'contexts/TranslationContext'

const PaymentsPage = () => {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <Flex direction="column">
      <Flex
        justify="center"
        position="relative"
        flexDirection="column"
        alignItems="center"
      >
        <Heading
          py="8"
          fontWeight="bold"
          alignSelf="center"
          maxW="80%"
          textAlign="center"
          fontSize="20px"
          as="h1"
        >
          {t('donationModal@heading')}
        </Heading>
        <Box
          as="p"
          my={2}
          mx={4}
          fontSize="lg"
          fontWeight="300"
          textAlign="center"
        >
          {t('donationModal@note')}
        </Box>
        <Flex
          onClick={() => history.goBack()}
          top="20px"
          right="20px"
          position="absolute"
          height="20px"
          width="20px"
        >
          <Close style={{ width: '100%', height: '100%' }} />
        </Flex>
      </Flex>
      <Payment />
    </Flex>
  )
}

export default PaymentsPage
