import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, A11y } from 'swiper'
import { Box, Flex, Button, Heading, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { Link } from 'pages'
import { ReactComponent as Left } from 'assets/icons/leftWhite.svg'
import { ReactComponent as Right } from 'assets/icons/rightWhite.svg'
import ImageWrapper from 'components/ImageWrapper'
// Import Swiper styles
import 'swiper/scss'
import './styles.scss'

SwiperCore.use([Navigation, A11y])

export default ({ slides, type }) => {
  const [active, setActive] = useState(1)
  const [feature, setFeature] = useState()
  const navPrevButton = useRef(null)
  const navNextButton = useRef(null)
  const { t, language } = useTranslation()

  const onBeforeInit = (Swiper) => {
    if (typeof Swiper.params.navigation !== 'boolean') {
      const navigation = Swiper.params.navigation
      navigation.prevEl = navPrevButton.current
      navigation.nextEl = navNextButton.current
    }
  }

  useEffect(() => {
    if (slides && slides[0]) {
      const featured = {}

      if (type === 'news') {
        featured.id = slides[active - 1]?.id
        featured.summary = slides[active - 1]?.description
        featured.title = slides[active - 1]?.title
        featured.type = 'news'
        featured.slug = slides[active - 1].slug
      } else {
        featured.id = slides[active - 1]?.id
        featured.summary = slides[active - 1][`tagline_${language}`]
        featured.title = slides[active - 1][`title_${language}`]
        featured.type = slides[active - 1].isProject
          ? 'fundraisers'
          : 'collaborations'
        featured.slug = slides[active - 1].slug
      }

      setFeature(featured)
    }
  }, [slides, active, language, type])
  return (
    <Swiper
      onBeforeInit={onBeforeInit}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      style={{ height: '100%', width: '100%' }}
      onSlideChange={(swiper) => setActive(swiper.activeIndex + 1)}
    >
      {slides?.map((slide) => (
        <SwiperSlide key={slide.id}>
          <Flex
            bg="linear-gradient(180deg, #000000 0%, rgba(0,0,0,0) 100%)"
            position="absolute"
            top="0"
            left="0"
            w="full"
            zIndex={1}
            opacity="0.35"
            h="320px"
          />
          <Flex
            position="absolute"
            insetX="0"
            insetY="0"
            w="full"
            h="full"
            overflow="hidden"
            align="center"
          >
            <Box position="relative" w="full" h="full">
              <ImageWrapper
                src={`/thumbnail/hd/${slide.cover}`}
                alt="Main Image"
                w="full"
                h="full"
                objectFit="cover"
                objectPosition="top bottom"
                position="absolute"
              />
            </Box>
          </Flex>
        </SwiperSlide>
      ))}

      <Flex
        zIndex={5}
        alignItems="flex-end"
        bottom="0"
        left="0"
        position="absolute"
      >
        <Flex flex="1" flexWrap="wrap" justifyContent="space-between">
          <Box
            mx={{ base: 'auto', md: '0' }}
            zIndex="5"
            px={{ base: '0', sm: '8', md: '0' }}
            width="100%"
            maxW={{ base: '340px', sm: '500px' }}
          >
            <Heading
              ml="8"
              color="white"
              fontSize="lg"
              as="h1"
              letterSpacing="3.05px"
              display={type === 'news' ? 'none' : 'block'}
              textTransform="upperCase"
            >
              {t('featured')}
            </Heading>
            <Heading
              ml="8"
              color="white"
              fontSize="lg"
              as="h1"
              letterSpacing="3.05px"
              display={type === 'news' ? 'block' : 'none'}
            >
              {t('FEATURED NEWS')}
            </Heading>
            <Link
              className="swiper-no-swiping"
              to={`/${feature?.type}/${feature?.slug || feature?.id}`}
            >
              <Flex
                w="full"
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                px="2rem"
                pt="1.5rem"
                pb="1rem"
                mt="4"
                minH="191px"
                background="white"
              >
                <Box>
                  <Heading
                    color="black"
                    fontSize={{ base: 'xl', sm: '5xl' }}
                    as="h2"
                  >
                    {feature?.title}
                  </Heading>
                  <Text my="4" color="gray.700" fontSize="md" noOfLines={4}>
                    {feature?.summary}
                  </Text>
                </Box>
                <Button
                  fontSize="lg"
                  display={type === 'news' ? 'none' : 'block'}
                >
                  {feature?.type === 'initiative'
                    ? t('homepage@seeInitiative')
                    : t('homepage@seeProject')}
                </Button>
                <Button
                  fontSize="md"
                  display={type === 'news' ? 'block' : 'none'}
                >
                  {t('READ MORE')}
                </Button>
              </Flex>
            </Link>
            <Flex
              className="swiper-no-swiping"
              flex="1"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Flex height="80px" w={{ base: '100%', md: 'unset' }}>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  minW="117px"
                  px="1.4rem"
                  background="black"
                  color="black"
                >
                  <Box as="span" color="white" fontSize="30px">
                    {active} /&nbsp;
                  </Box>
                  <Box as="span" color="white" fontSize="30px">
                    {slides?.length}
                  </Box>
                </Flex>
                <Flex
                  pr="10"
                  background="black"
                  alignItems="center"
                  justifyContent="space-between"
                  pointerEvents="all"
                >
                  <Button
                    mr="32px"
                    borderRadius="none"
                    h="full"
                    color="white"
                    w="full"
                    ref={navPrevButton}
                  >
                    <Left />
                  </Button>
                  <Button
                    borderRadius="none"
                    h="full"
                    w="full"
                    ref={navNextButton}
                  >
                    <Right />
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Swiper>
  )
}
