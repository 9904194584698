import React from 'react'
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react'
import { useNavigation } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'

export default function ExpertsApplyModal() {
  const { navigationPush } = useNavigation()
  const { t } = useTranslation()
  return (
    <Box pt={7} px={7} pb={7} maxW="500px" borderRadius="16px">
      <Image
        src="/assets/images/close.png"
        ml={3}
        onClick={() => navigationPush('/experts')}
      />
      <Flex flexDirection="column" alignItems="center">
        <Image src="/assets/images/success.png" />
        <Text
          as="h1"
          p={4}
          lineHeight="32px"
          mt={5}
          fontSize="6xl"
          fontWeight="medium"
          color="gray.800"
          textAlign="center"
        >
          {t('expertModal@title')}
        </Text>
        <Text p={4} fontSize="md" color="gray.700" textAlign="center">
          {t('expertModal@desc')}
        </Text>
      </Flex>
      <Box px={5}>
        <Button
          w="100%"
          mt={8}
          variant="blue"
          borderRadius="xl"
          fontSize="md"
          fontWeight="bold"
          onClick={() => navigationPush('/experts')}
        >
          {t('ok')}
        </Button>
      </Box>
    </Box>
  )
}
