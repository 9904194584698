import ListBasketSubscriptionsComponent from 'components/ListBasketSubscriptions'
import Navbar from 'components/Navbar'
import { useTranslation } from 'contexts/TranslationContext'

export default function ListBasketSubscriptionsPage() {
  const { t } = useTranslation()

  return (
    <>
      <Navbar
        title={t('profile@dashboard@investor@recurring@subscriptions')}
        backTo="/profile/history/non-profit"
      />
      <ListBasketSubscriptionsComponent />
    </>
  )
}
