import { Suspense, lazy, Fragment } from 'react'
import { Center, Container, Grid, GridItem, Spinner } from '@chakra-ui/react'
import { useHistory, useParams } from 'react-router-dom'
import { MainLayout } from 'components/Layouts'
import { Footer } from 'components/Footer'
import Header from 'components/MobileHeader'
import Stepper from 'components/Stepper'
import { useBasket } from 'contexts/BasketContext'
import { useScreenSize } from 'contexts'
import { useTranslation } from 'contexts/TranslationContext'
import { useNavigation } from 'pages'
import { useUser } from 'hooks'
const BasketStep = lazy(() => import('./BasketStep'))
const CheckoutStep = lazy(() => import('./CheckoutStep'))

const EmptyState = lazy(() => import('./EmptyState'))

const steps = [
  {
    label: 'Basket',
    tab: 'items',
    Component: BasketStep,
  },
  {
    label: 'Checkout',
    tab: 'checkout',
    Component: CheckoutStep,
  },
  {
    label: 'Success',
    tab: 'success',
    Component: Fragment,
  },
]

export default function Basket() {
  const { basket } = useBasket()
  const { tab } = useParams()
  const { isMobile } = useScreenSize()
  const { t } = useTranslation()
  const history = useHistory()
  const { navigationPush } = useNavigation()
  const { isLoading } = useUser()
  const stepIndex = steps.findIndex((step) => step.tab === tab)
  const step = steps[stepIndex]

  const onBack = () => {
    history.goBack()
  }

  const onNext = () => {
    navigationPush(`/basket/${steps[stepIndex + 1].tab}`)
  }

  return (
    <MainLayout>
      {isMobile && <Header onBack={onBack} heading={t('Charity Basket')} />}
      <Container
        px={{ base: '6', md: '3.75rem' }}
        pt={12}
        pb={20}
        w="full"
        maxW="7xl"
      >
        <Grid templateColumns="repeat(12, 1fr)">
          <GridItem colStart={{ base: 0, md: 5 }} colSpan={{ base: 12, md: 4 }}>
            <Stepper
              steps={steps.map((step) => step.label)}
              activeStep={stepIndex}
            />
          </GridItem>
        </Grid>
        {isLoading ? (
          <Center mt={12}>
            <Spinner />
          </Center>
        ) : (
          <>
            {basket?.length > 0 && step ? (
              <Suspense>
                <step.Component onNext={onNext} />
              </Suspense>
            ) : (
              <Suspense>
                <EmptyState />
              </Suspense>
            )}
          </>
        )}
      </Container>
      <Footer />
    </MainLayout>
  )
}
