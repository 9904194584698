import moment from 'moment'

export const calcDuplicateTransaction = (
  total,
  maxAmount,
  donationToProject
) => {
  if (maxAmount - total > donationToProject) {
    return donationToProject * 2
  }

  const amountToDuplicate = maxAmount - total
  const amount = amountToDuplicate - donationToProject

  return amount + amountToDuplicate * 2
}

export const normalizeInvestmentResponse = (transactions) => {
  transactions.items = transactions.items.map((item) => {
    item.formattedDate = moment(item.createdAt).format('MMMM DD, YYYY')
    return item
  })

  return transactions
}

export const investmentParams = ({
  limit = 10,
  sortDirection = 'DESC',
  email,
  isRecurring,
  projectIdList,
  nextToken,
}) => ({
  limit,
  sortDirection,
  email,
  filter: {
    or: projectIdList.map((id) => ({ projectId: { eq: id } })),
    and: {
      status: { eq: 'DONE' },
      subscriptionId: { attributeExists: isRecurring },
    },
  },
  nextToken,
})

export const fetchItemsWhileEmpty = async ({ params, request }) => {
  let result = {}
  let itemsLength = 0
  let localNextToken = params.nextToken

  do {
    const response = await request({ ...params, nextToken: localNextToken })
    if (response) {
      result = response
      itemsLength = result.data.transactionsByEmailDate.items?.length
      localNextToken = result.data.transactionsByEmailDate.nextToken
    }
  } while (itemsLength === 0 && localNextToken)

  return result
}
