import { post } from 'aws-amplify/api'
import { getResponseToJSON } from 'hooks/useResponseManipulations'

const stripeService = () => {
  const paymentIntent = async (params) => {
    const res = post({
      apiName: 'stripePaymentAPI',
      path: '/payment-intent',
      options: {
        body: params,
      },
    })

    return await getResponseToJSON(res)
  }

  const connectAccount = async (params) => {
    const res = post({
      apiName: 'stripePaymentAPI',
      path: '/connect',
      options: {
        body: params,
      },
    })
    return await getResponseToJSON(res)
  }

  const continueConnectAccount = async (params) => {
    const res = post({
      apiName: 'stripePaymentAPI',
      path: '/connect-continue',
      options: {
        body: params,
      },
    })
    return await getResponseToJSON(res)
  }

  const retrieveAccount = async (params) => {
    const res = post({
      apiName: 'stripePaymentAPI',
      path: '/retrieve-account',
      options: {
        body: params,
      },
    })
    return await getResponseToJSON(res)
  }

  const confirmPayment = async (params) => {
    const res = post({
      apiName: 'stripePaymentAPI',
      path: '/confirm',
      options: {
        body: params,
      },
    })
    return await getResponseToJSON(res)
  }

  const createCustomer = async (params) => {
    const res = post({
      apiName: 'stripePaymentAPI',
      path: '/create-customer',
      options: {
        body: params,
      },
    })
    return await getResponseToJSON(res)
  }

  const attachPmToCustomer = async (params) => {
    const res = post({
      apiName: 'stripePaymentAPI',
      path: '/attach-pm-to-customer',
      options: {
        body: params,
      },
    })
    return await getResponseToJSON(res)
  }

  const retrieveCustomer = async (params) => {
    const res = post({
      apiName: 'stripePaymentAPI',
      path: '/retrieve-customer',
      options: {
        body: params,
      },
    })
    return await getResponseToJSON(res)
  }

  const createSource = async (params) => {
    const res = post({
      apiName: 'stripePaymentAPI',
      path: '/create-source',
      options: {
        body: params,
      },
    })
    return await getResponseToJSON(res)
  }

  const sendOneTimeEmail = async (params) => {
    const res = post({
      apiName: 'stripePaymentAPI',
      path: '/send-one-time-email',
      options: {
        body: params,
      },
    })
    return await getResponseToJSON(res)
  }

  return {
    paymentIntent,
    connectAccount,
    continueConnectAccount,
    retrieveAccount,
    confirmPayment,
    createCustomer,
    attachPmToCustomer,
    retrieveCustomer,
    createSource,
    sendOneTimeEmail,
  }
}

export default stripeService
