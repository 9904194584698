import React from 'react'
import { HStack, Flex, Heading } from '@chakra-ui/react'
import { ReactComponent as Settings } from 'assets/icons/settings.svg'
import { ReactComponent as Back } from '../../../../assets/icons/back.svg'
import { useNavigation } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'

const Header = () => {
  const { navigationPush } = useNavigation()
  const { t } = useTranslation()
  return (
    <HStack px="3" alignItems="center" justifyContent="space-between" py="12px">
      <Flex
        onClick={() => navigationPush('/profile/fundraisers')}
        width="30px"
        height="30px"
        justifyContent="center"
        alignItems="center"
      >
        <Back width="10px" height="20px" />
      </Flex>
      <Heading fontSize="xl" as="h2">
        {t('My Collaborations')}
      </Heading>
      <Flex
        onClick={() => navigationPush('/profile/settings')}
        width="30px"
        height="30px"
        justifyContent="center"
        alignItems="center"
      >
        <Settings width="30" height="30px" />
      </Flex>
    </HStack>
  )
}

export default Header
