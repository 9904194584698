const {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} = require('react')

const buildState = (width) => {
  const sizeMobile = 485
  const sizeTablet = 768
  const sizeTabletLarge = 992
  const sizeDesktop = 1280

  return {
    screenWidth: width,
    isMobile: width < sizeMobile,
    isTablet: width >= sizeMobile && width < sizeTablet,
    isLargeTablet: width >= sizeTablet && width < sizeTabletLarge,
    isDesktop: width >= sizeTabletLarge && width < sizeDesktop,
    isLargeDesktop: width >= sizeDesktop,
  }
}

const ScreenSizeContext = createContext(buildState(window.innerWidth))

export const useScreenSize = () => useContext(ScreenSizeContext)

export function ScreenSizeProvider({ children }) {
  const [width, setWidth] = useState(window.innerWidth)
  const refTimer = useRef()

  const onResize = useCallback(() => {
    clearTimeout(refTimer.current)
    refTimer.current = setTimeout(() => {
      setWidth(window.innerWidth)
    }, 50)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  return (
    <ScreenSizeContext.Provider value={buildState(width)}>
      {children}
    </ScreenSizeContext.Provider>
  )
}
