import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
const { Flex, Text } = require('@chakra-ui/react')

export default function Disclaimer({ isFundraiser, text }) {
  return (
    <Flex
      hidden={!isFundraiser}
      w="full"
      bg="orange.100"
      py={3}
      px={4}
      mb={6}
      alignItems="flex-start"
      borderRadius={6}
    >
      <InfoIcon
        width="20px"
        fill="#DD6B20"
        style={{ flexShrink: 0, marginTop: 4 }}
      />
      <Text ml={3} fontSize="lg" fontWeight={700}>
        {text}
      </Text>
    </Flex>
  )
}
