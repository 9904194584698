import React, { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useProjectsByPopular, useProjectsByFeatured } from 'hooks'
import Web from './web'
import Mobile from './mobile'
import MetaTags from 'components/MetaTags'
import { useTranslation } from 'contexts/TranslationContext'
import { useScreenSize } from 'contexts'

const isCollaboration = (project) =>
  project.collaborations && !!project.collaborations.length
const isFundraising = (project) =>
  project.budgetFiles && !!project.budgetFiles.length
const sortFeatured = (project, nextProject) =>
  project.sequence - nextProject.sequence

const Homepage = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { projects: featured, isLoading: isFeaturedLoading } =
    useProjectsByFeatured({
      featured: 'true',
    })
  const { projects: popular, isLoading: isPopularLoading } =
    useProjectsByPopular({
      popular: 'true',
    })
  useEffect(() => {
    if (!featured || !popular) return
    featured?.map((item) =>
      queryClient.setQueryData(['project_slug', item.slug], item)
    )
    popular?.map((item) =>
      queryClient.setQueryData(['project_slug', item.slug], item)
    )
  }, [featured, popular])

  const { isMobile } = useScreenSize()
  if (isMobile) {
    return (
      <>
        <MetaTags
          title={t('meta@home@title')}
          description={t('meta@home@description')}
        />
        <Mobile
          isPopularLoading={isPopularLoading}
          isProjectsLoading={isFeaturedLoading}
          projects={popular?.filter(isFundraising) || []}
          initiatives={popular?.filter(isCollaboration) || []}
          featuredProjects={featured?.sort(sortFeatured) || []}
        />
      </>
    )
  }
  return (
    <>
      <MetaTags
        title={t('meta@home@title')}
        description={t('meta@home@description')}
      />
      <Web
        isPopularLoading={isPopularLoading}
        isProjectsLoading={isFeaturedLoading}
        projects={popular?.filter(isFundraising) || []}
        initiatives={popular?.filter(isCollaboration) || []}
        featuredProjects={featured?.sort(sortFeatured) || []}
      />
    </>
  )
}

export default Homepage
