import axios from 'axios'

const geoService = () => {
  const getRequestData = async () =>
    (
      await axios.get(
        'https://geolocation-db.com/json/f2e84010-e1e9-11ed-b2f8-6b70106be3c8'
      )
    ).data

  return {
    getRequestData,
  }
}

export default geoService
