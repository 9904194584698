import Wrapper from 'components/Wrapper'
import { useTranslation } from 'contexts/TranslationContext'

const PopularInitiatives = ({ initiatives, isLoading }) => {
  const { t } = useTranslation()
  return (
    <Wrapper
      type="collaborations"
      swiper={initiatives.sort(
        (item, next) => next.collaboratorsTotal - item.collaboratorsTotal
      )}
      isLoading={isLoading}
      squareColor="orange.400"
      title={t('Collaborations')}
    />
  )
}

export default PopularInitiatives
