import React from 'react'
import {
  Box,
  Image,
  Flex,
  Heading,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import LazyLoad from 'react-lazyload'
import { useTranslation } from 'contexts/TranslationContext'

const TestimonialCard = ({ card, descriptionNumberOfLines, ...props }) => {
  const { language } = useTranslation()

  return (
    <Box style={{ margin: 0, padding: 0 }} w="100%" {...props}>
      <Box overflow="hidden">
        <Box
          borderRadius="16px"
          position="relative"
          width="80px"
          boxShadow="5px 8px 13px 0 rgba(0,0,0,0.15)"
          height={card?.avatar ? '80px' : 'auto'}
        >
          {card?.avatar ? (
            <LazyLoad height="80px" once>
              <Image
                cursor="pointer"
                borderRadius="16px"
                objectFit="cover"
                width="100%"
                bgColor="border.100"
                height="80px"
                ignoreFallback={true}
                src={card.avatar}
              />
            </LazyLoad>
          ) : (
            <Box
              cursor="pointer"
              borderRadius="16px"
              objectFit="cover"
              width="100%"
              bgColor="border.100"
              height="100%"
            />
          )}
        </Box>
        <Flex
          height="60%"
          pt="4"
          pb="6"
          flexDirection="column"
          justifyContent={card.avatar ? 'space-between' : 'normal'}
        >
          <Box minH={card.avatar ? '135px' : 'auto'} width="100%">
            <Heading
              key={`card-title-${language}`}
              noOfLines={2}
              color={mode('black', 'white')}
              as="h2"
              fontSize={{ base: 'lg', sm: '3xl' }}
            >
              {card.fullName}
            </Heading>
            <Text mt={1} fontSize="sm" color="gray.700">
              {card.profession}
            </Text>
            <Text
              key={`card-description-${language}`}
              noOfLines={descriptionNumberOfLines}
              mt="2"
              mb="2"
              fontSize="md"
              color="gray.700"
            >
              {card.description}
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

export default TestimonialCard
