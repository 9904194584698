const Checkbox = {
  baseStyle: {
    icon: {},
    label: {},
    control: {
      borderColor: 'border.200',
      borderWidth: 1,
      borderRadius: 3,
      _checked: {
        _disabled: {
          bg: 'border.200',
          bgColor: 'border.200',
          borderColor: 'border.200',
        },
        borderColor: 'orange.400',
        bg: 'orange.400',
        bgColor: 'orange.400',
        _hover: {
          borderColor: '#F49D22',
          bg: '#F49D22',
          bgColor: '#F49D22',
        },
      },
      _hover: {
        borderColor: 'orange.400',
      },
    },
  },
}

export default Checkbox
