import React from 'react'
import { Stack, Box, Heading, Text, Flex } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { InputField } from 'components/InputField'
import { TextAreaComponent } from 'components/TextAreaComponent'
import { Select } from 'components/Select'
import { CATEGORY, REGIONS } from '../../_constants'

const Basic = ({ register, errors, oldId }) => {
  const { t, language } = useTranslation()
  return (
    <Box maxW="600px" width="100%">
      <Heading mb="6" fontSize="6xl" as="h2">
        {t('Basic')}
      </Heading>
      {!!oldId && (
        <Text mb="8" color="gray.700" fontSize="md">
          {t('Collaboration ID: ')} {oldId}
        </Text>
      )}
      <Stack mb="5.5rem" spacing="6">
        <InputField
          isDisabled={!!oldId}
          isInvalid={!!errors.applicationTitle}
          label={t('createEditProject@projectTitle') + '*'}
          description={t('createEditProject@projectTitleText')}
          errorMessage={t('createEditProject@thisFieldIsRequired')}
          maxLength={80}
          {...register('applicationTitle', {
            required: true,
            maxLength: 80,
          })}
        />

        <TextAreaComponent
          isInvalid={!!errors.summary}
          title={t('createEditProject@projectSummary') + '*'}
          description={t('createEditProject@projectSummaryText')}
          errorMessage={t('createEditProject@thisFieldIsRequired')}
          minLength={250}
          maxLength={2000}
          {...register('summary', {
            required: true,
            minLength: 250,
            maxLength: 2000,
          })}
        />
        <Flex flexDir="column">
          <Text fontSize="xl" fontWeight="700" zIndex={2}>
            {t('createEditProject@projectRegion') + '*'}
            <Text fontSize="sm" fontWeight={400} mt={1} mb={4}>
              {t('createEditProject@projectRegionText')}
            </Text>
          </Text>
          <Select
            isDisabled={!!oldId}
            type="region"
            {...register('region', { required: true })}
            isInvalid={!!errors.region}
            errorMessage={t('error@region')}
            placeholder={t('createEditProject@region')}
            options={REGIONS}
          />
        </Flex>
        <Flex flexDir="column">
          <Text fontSize="xl" fontWeight="700" zIndex={2}>
            {t('createEditProject@projectCategory') + '*'}
            <Text fontSize="sm" fontWeight={400} mt={1} mb={4}>
              {t('createEditProject@projectCategoryText')}
            </Text>
          </Text>
          <Select
            isDisabled={!!oldId}
            type="category"
            {...register('category', { required: true })}
            isInvalid={!!errors.category}
            options={CATEGORY[language]}
            errorMessage={t('error@category')}
            placeholder={t('createEditProject@category')}
          />
        </Flex>
        <TextAreaComponent
          isInvalid={!!errors.problem}
          title={t('createEditProject@projectProblem') + '*'}
          description={t('createEditProject@projectProblemText')}
          errorMessage={t('createEditProject@thisFieldIsRequired')}
          minLength={150}
          maxLength={1000}
          {...register('problem', {
            required: true,
            minLength: 150,
            maxLength: 1000,
          })}
        />
        <TextAreaComponent
          isInvalid={!!errors.solution}
          title={t('createEditProject@projectSolution') + '*'}
          description={t('createEditProject@projectSolutionText')}
          errorMessage={t('createEditProject@thisFieldIsRequired')}
          minLength={150}
          maxLength={1000}
          {...register('solution', {
            required: true,
            minLength: 150,
            maxLength: 1000,
          })}
        />
        <TextAreaComponent
          isInvalid={!!errors.projectTeam}
          errorMessage={t('createEditProject@thisFieldIsRequired')}
          title={t('createEditProject@teamBehindProject') + '*'}
          description={t('projectCreate@TeamBehindText')}
          minLength={150}
          maxLength={1000}
          {...register('projectTeam', {
            required: true,
            minLength: 150,
            maxLength: 1000,
          })}
        />
      </Stack>
    </Box>
  )
}

export default Basic
