const expertRepo = (expertService) => {
  const createExpertInitConnection = (params) =>
    expertService.createExpertInitConnection(params)
  const updateConnection = (params) => expertService.updateConnection(params)
  const getConnection = (params) => expertService.getConnection(params)

  return {
    createExpertInitConnection,
    updateConnection,
    getConnection,
  }
}

export default expertRepo
