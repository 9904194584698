const exportRepo = (exportService) => {
  const exportTable = (table) => exportService.exportTable(table)
  const exportApplications = () => exportService.exportApplications()
  const exportTableWithParams = (params) =>
    exportService.exportTableWithParams(params)

  return {
    exportTable,
    exportApplications,
    exportTableWithParams,
  }
}

export default exportRepo
