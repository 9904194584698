const Menu = {
  baseStyle: {
    list: {
      zIndex: '10000',
      bg: 'blue.400',
      overflow: 'hidden',
      color: 'white',
      fontSize: 'md',
      borderRadius: '16px',
      py: '3',
      border: 'none',
    },
    button: {
      fontSize: 'md',
    },
    item: {
      height: '8',
      bg: 'blue.400',
      _hover: {
        bg: 'rgba(255, 255,255, 0.15)',
      },
      _focus: {
        bg: 'rgba(255, 255,255, 0.15)',
      },
    },
  },
  variants: {
    border: {
      button: {
        sizes: {
          base: {
            display: 'none',
          },
          sm: {
            display: 'block',
          },
        },
        color: 'black',
        fill: 'white',
        mt: 8,
        borderColor: 'border.100',
        borderRadius: '12px',
        borderWidth: 1,
      },
    },
    white: {
      button: {
        sizes: {
          base: {
            display: 'none',
          },
          sm: {
            display: 'block',
          },
        },
        color: 'black',
        fill: 'white',
        mt: 8,
        borderColor: 'border.100',
        borderRadius: '8px',
        borderWidth: 1,
      },
      list: {
        zIndex: '10000',
        bg: 'white',
        overflow: 'hidden',
        color: 'gray.800',
        fontSize: 'md',
        borderRadius: '16px',
        py: '3',
        border: 'none',
      },
      item: {
        height: '8',
        bg: 'gray.800',
        _hover: {
          bg: 'rgba(255, 255,255, 0.15)',
        },
        _focus: {
          bg: 'rgba(255, 255,255, 0.15)',
        },
      },
    },
  },
}

export default Menu
