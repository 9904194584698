import React from 'react'
import { Button, HStack } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'

const BottomButtons = ({ type, setType, oldId }) => {
  const { t } = useTranslation()
  return (
    <HStack
      zIndex="1000"
      bg="white"
      w="full"
      bottom="0"
      left="0"
      borderTop="1px solid"
      borderColor="border.100"
      justifyContent="space-between"
      pt="3"
      pb="2.125rem"
      px="3"
      position="fixed"
      spacing="3"
    >
      {!type.fundraising && (
        <Button
          size="md"
          mr="5"
          variant="blue"
          onClick={() => setType({ ...type, fundraising: true })}
        >
          + {t('Fundraising')}
        </Button>
      )}
      {(type.fundraising || type.collaboration) && (
        <Button
          w={type.fundraising && type.collaboration ? 'full' : '50%'}
          size="md"
          type="submit"
          variant={type.fundraising ? 'blue' : 'orange'}
        >
          {t('submit')}
        </Button>
      )}
      {!type.collaboration && !oldId && (
        <Button
          size="md"
          variant="orange"
          onClick={() => setType({ ...type, collaboration: true })}
        >
          + {t('Collaboration')}
        </Button>
      )}
    </HStack>
  )
}

export default BottomButtons
