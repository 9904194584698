import React, { useEffect } from 'react'
import {
  Box,
  Button,
  Flex,
  Image,
  SimpleGrid,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { InputField } from 'components/InputField'
import { useForm } from 'react-hook-form'
import { useNavigation } from 'pages'
import useConnectExpert from 'hooks/expert'
import { useQueryParams, useUser } from 'hooks'
import { useGetExpertById } from 'core/Content/hooks'
import { useCallback } from 'react'
import { useTranslation } from 'contexts/TranslationContext'
import { TextAreaComponent } from 'components/TextAreaComponent'

export default function ExpertsConnectModal() {
  const { navigationPush } = useNavigation()
  const { t, language } = useTranslation()
  const { user } = useUser()
  const queryParams = useQueryParams()
  const expertId = queryParams.get('id')
  const { data: expert, isLoading } = useGetExpertById(expertId)

  const defaultValues = {}
  if (user.id !== 'guest') {
    defaultValues.firstName = user.firstName
    defaultValues.lastName = user.lastName
    defaultValues.email = user.email
  }
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    if (user.id !== 'guest') {
      setValue('firstName', user.firstName)
      setValue('lastName', user.lastName)
      setValue('email', user.email)
    }
  }, [user.id])

  const { mutate: connect, isLoading: isConnectLoading } = useConnectExpert()
  const onSubmit = useCallback(
    (formData) => {
      const input = {
        adminStatus: 'not_sent',
        expertStatus: 'not_sent',
        initiatorFirstName: formData.firstName,
        initiatorLastName: formData.lastName,
        initiatorEmail: formData.email,
        initiatorPhone: formData.phone,
        initiatorLink: formData.link,
        initiatorMessage: formData.message,
        initiatorSupport: formData.support,
        expertFirstName: expert.firstName_en,
        expertLastName: expert.lastName_en,
        expertEmail: expert.email,
        expertPhone: expert.phone,
        expertLinkedinLink: expert.linkedinLink,
        expertLink: expert.link,
        expertImageUrl: expert.imageUrl,
        expertTitle: expert.title_en,
        expertType: expert.getInvolvedStatus,
        expertHours: expert.hrsPerWeek,
        expertSpheres: JSON.stringify(expert.spheres),
        expertDirections: JSON.stringify(expert.interestedIn),
        expertOccupation: expert.title_en,
        expertLanguage: '',
        projectTitle: formData.projectTitle,
        language,
      }

      connect(input, {
        onSuccess() {
          navigationPush('/experts/applyModal')
        },
        onError(err) {
          console.log('err', err)
        },
      })
    },
    [expert]
  )

  const onError = (error) => {
    console.log(error)
  }
  if (isLoading) {
    return (
      <Flex
        width="100%"
        minHeight="100vh"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner size="lg" />
      </Flex>
    )
  }
  return (
    <Box maxW="500px" borderRadius="16px" px={5} py={7}>
      <Flex flexDirection="column-reverse">
        <Text
          pt={7}
          pr={7}
          pb={0}
          fontSize="3xl"
          fontWeight="bold"
          color="gray.800"
        >
          {t('connectForm@title')}
        </Text>
        <Image
          w="30px"
          h="30px"
          src="/assets/images/close.png"
          onClick={() => navigationPush('/experts')}
        />
      </Flex>
      <Box py={3} borderY="1px solid" borderColor="gray.500">
        <Text fontSize="md" color="gray.800">
          {t('connectForm@description')}
        </Text>
      </Box>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacingX="16px"
          mt={2}
          alignItems="center"
        >
          <InputField
            formControlProps={{ mt: 5 }}
            label={t('connectForm@firstName')}
            size="sm"
            borderRadius="lg"
            borderColor="gray.500"
            isInvalid={errors.firstName}
            errorMessage={t('signUp@firstNameErrorMessage')}
            {...register('firstName', {
              required: {
                value: true,
              },
            })}
          />
          <InputField
            formControlProps={{ mt: 5 }}
            label={t('connectForm@lastName')}
            size="sm"
            borderRadius="lg"
            borderColor="gray.500"
            isInvalid={errors.lastName}
            errorMessage={t('signUp@lastNameErrorMessage')}
            {...register('lastName', {
              required: {
                value: true,
                message: `t('signUp@lastNameErrorMessage')`,
              },
            })}
          />
        </SimpleGrid>
        <InputField
          formControlProps={{ mt: 5 }}
          label={t('connectForm@emailAddress')}
          size="sm"
          borderRadius="lg"
          borderColor="gray.500"
          isInvalid={errors.email}
          errorMessage={errors?.mail?.message}
          {...register('email', {
            required: {
              value: true,
              message: t('signUp@emailErrorMessage'),
            },
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: t('createEditInitiative@emailError'),
            },
          })}
        />
        <InputField
          formControlProps={{ mt: 5 }}
          label={t('connectForm@phoneNumber')}
          size="sm"
          borderRadius="lg"
          borderColor="gray.500"
          isInvalid={errors.phone}
          errorMessage={t('profile@settings-phoneNumberErrorMessage')}
          {...register('phone', {
            required: {
              value: true,
              message: t('signUp@phoneNumberErrorMessage'),
            },
          })}
        />
        <InputField
          formControlProps={{ mt: 5 }}
          label={t('connectForm@mediaLinks')}
          size="sm"
          borderRadius="lg"
          borderColor="gray.500"
          {...register('link', {
            required: false,
          })}
        />
        <InputField
          formControlProps={{ mt: 5 }}
          label={t('connectForm@projectTitle')}
          size="sm"
          borderRadius="lg"
          borderColor="gray.500"
          isInvalid={errors.projectTitle}
          errorMessage={t('field is required')}
          {...register('projectTitle', {
            required: {
              value: true,
              message: t('field is required'),
            },
          })}
        />
        <TextAreaComponent
          isInvalid={!!errors.message}
          formControlProps={{ mt: 5, mb: 10 }}
          description={t('connectForm@projectDescription')}
          errorMessage={t('field is required')}
          maxLength={1000}
          {...register('message', {
            required: true,
            maxLength: 1000,
          })}
        />
        <TextAreaComponent
          isInvalid={!!errors.support}
          formControlProps={{ mt: 5, mb: 4 }}
          description={t('connectForm@projectSupport')}
          errorMessage={t('field is required')}
          maxLength={1000}
          {...register('support', {
            required: true,
            maxLength: 1000,
          })}
        />
      </form>
      <Box>
        <Button
          w="100%"
          mt={6}
          variant="blue"
          borderRadius="xl"
          fontSize="md"
          fontWeight="bold"
          isLoading={isConnectLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {t('send')}
        </Button>
      </Box>
    </Box>
  )
}
