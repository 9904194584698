import {
  Button,
  chakra,
  Input,
  Box,
  useColorModeValue,
  Heading,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'contexts/TranslationContext'
import * as React from 'react'
import { useNavigation } from 'pages'
import useCustomToast from 'hooks/useCustomToast'
import { useSubscribeNews } from 'hooks'
const Subscribe = (props) => {
  const { register, handleSubmit } = useForm()
  const { t, language } = useTranslation()
  const toast = useCustomToast()
  const { navigationPush } = useNavigation()
  const { isLoading, mutate: subscribeMailerLiteNews } = useSubscribeNews()

  const onSubscribe = async ({ email }) => {
    await subscribeMailerLiteNews(
      { email: email.toLowerCase(), language },
      {
        onSuccess: () => {
          navigationPush('/subscribe/thank-you')
          toast({
            title: t('Subscribed'),
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
        },
        onError: (e) => {
          toast({
            title: 'You are already subscribed!',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
          console.log('subscribe error', e)
        },
      }
    )
  }
  return (
    <chakra.form {...props} onSubmit={handleSubmit(onSubscribe)}>
      <Box pt="5rem" pb="5.5rem" px="1.875rem">
        <Heading mb="5" textAlign="center" as="h2" fontSize="3xl">
          {t('Subscribe to get updates')}
        </Heading>
        <Input
          height="48px"
          _focus={{ outline: 'none' }}
          borderColor="border.100"
          placeholder={t('Enter your email')}
          borderRadius="12px"
          {...register('email', { required: true })}
          focusBorderColor={useColorModeValue('blue.400', 'blue.300')}
          _placeholder={{
            opacity: 0.6,
            color: useColorModeValue('gray.700', 'whiteAlpha.700'),
          }}
        />
        <Button
          mt="3"
          isLoading={isLoading}
          type="submit"
          width="full"
          variant="blue"
          flexShrink={0}
        >
          {t('Subscribe')}
        </Button>
      </Box>
    </chakra.form>
  )
}

export default Subscribe
