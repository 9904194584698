import React, { useRef, useState } from 'react'
import { Button, Box, Flex, Stack, Text } from '@chakra-ui/react'
import { FaFile, FaTimesCircle } from 'react-icons/fa'
import { useUploadFiles } from '../../hooks'
import { useTranslation } from 'contexts/TranslationContext'
import FileUpload from 'components/FileUpload'
import { ReactComponent as Attach } from '../../assets/icons/attach.svg'

const FilesUpload = ({
  setValue,
  getValues,
  filesWrapperProps,
  files,
  description,
}) => {
  const { mutate: filesUpload } = useUploadFiles()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const inputRef = useRef(null)
  const { t } = useTranslation()

  const handleClick = () => inputRef.current?.click()
  const uploadFiles = (event) => {
    const { files } = event.target

    if (files && files[0]) {
      setLoading(true)
      const newFiles = Object.values(files)
      filesUpload(
        {
          data: newFiles,
        },
        {
          onSuccess: (data) => {
            setError('')
            const urls = data.map((image) => image.key)
            setValue('files', [...getValues('files'), ...urls])
            setLoading(false)
          },
          onError: (e) => {
            setError(e)
            setLoading(false)
          },
        }
      )
    }
  }

  const onRemove = (e, file) => {
    e.preventDefault()
    e.stopPropagation()

    setValue('files', [...getValues('files').filter((f) => f !== file)])
  }

  return (
    <Box>
      <Flex mb="1">
        <Attach />
        <Button
          ml="2"
          fontSize="sm"
          fontWeight="regular"
          display="flex"
          flexDir="column"
          alignItems="flex-start"
          textTransform="none"
          color={error ? 'red.400' : 'gray.800'}
          isLoading={loading}
          onClick={handleClick}
        >
          {error ? error : t('createEditProject@attachFile')}
          {description && (
            <Text
              color="gray.700"
              fontWeight={400}
              fontSize="sm"
              textAlign="left"
              whiteSpace="pre-wrap"
              mt="6px"
              textTransform="none"
            >
              {description}
            </Text>
          )}
        </Button>
      </Flex>
      <Box h="1px" w="100%" bg="border.100" my={5} />
      <Stack {...filesWrapperProps} spacing="2">
        {files.map((file) => (
          <Flex
            key={`edit-project-application-file-${file}`}
            borderWidth="1px"
            borderColor="border.100"
            borderRadius="7px"
            pl={3}
            py="14px"
            alignItems="center"
            position="relative"
            _hover={{
              button: {
                opacity: 1,
              },
            }}
          >
            <FaFile color="#CDCDCD" />
            <a
              style={{ marginLeft: '0.875rem' }}
              href={`${
                process.env.REACT_APP_S3_BUCKET_URL
              }/public/${file.replaceAll(' ', '+')}`}
              download
            >
              {file}
            </a>
            <Button
              position="absolute"
              right="12px"
              top="50%"
              transform="translateY(-50%)"
              bg="none"
              border="none"
              minW="auto"
              minH="auto"
              opacity="0"
              transition="opacity 200ms"
              onClick={(e) => onRemove(e, file)}
            >
              <FaTimesCircle color="#CDCDCD" />
            </Button>
          </Flex>
        ))}
      </Stack>
      <input
        multiple
        style={{ display: 'none' }}
        ref={inputRef}
        type="file"
        onChange={uploadFiles}
      />
    </Box>
  )
}

FileUpload.defaultProps = {
  getValues: () => {},
  setValue: () => {},
  filesWrapperProps: {},
}

export default FilesUpload
