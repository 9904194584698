import { post } from 'aws-amplify/api'
import { getResponseToJSON } from 'hooks/useResponseManipulations'

const ElasticService = () => {
  const getContentFromElastic = async (condition) => {
    let environment = process.env.REACT_APP_ENV
    if (environment === 'local') {
      environment = 'development'
    }
    const res = post({
      apiName: 'elastic',
      path: '/proxy',
      options: {
        body: {
          index: `content_${environment}`,
          path: '_search',
          method: 'POST',
          data: condition,
        },
      },
    })
    return await getResponseToJSON(res)
  }

  return { getContentFromElastic }
}

export default ElasticService
