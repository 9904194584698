import { listSettings as listSettingsQuery } from '../../graphql/queries'
import { generateClient } from 'aws-amplify/api'
const client = generateClient()

const settingsService = () => {
  const getSettings = async () => {
    const settings = await client.graphql({
      query: listSettingsQuery,
      variables: { limit: 1 },
    })

    return settings.data.listSettings.items[0]
  }

  return {
    getSettings,
  }
}

export default settingsService
