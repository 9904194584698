import { signInWithRedirect } from 'aws-amplify/auth'
import {
  Box,
  Button,
  Stack,
  Checkbox,
  Flex,
  IconButton,
  Text,
} from '@chakra-ui/react'
import { Link } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import { InputField } from 'components/InputField'
import { COUNTRY_LIST } from '_constants'
import { Select } from 'components/Select'
import { PasswordField } from 'components/PasswordField'
import { ReactComponent as Facebook } from 'assets/icons/authFacebook.svg'
import { ReactComponent as Google } from 'assets/icons/authGoogle.svg'

export const SignupForm = ({
  register,
  errors,
  handleSubmit,
  onSubmit,
  country,
  setCountry,
  phone,
  setPhone,
  phoneError,
  loading,
  password,
  user,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Flex justifyContent="center" mb={8}>
          <IconButton
            onClick={() => signInWithRedirect({ provider: 'Facebook' })}
            p="0"
            mx={3}
            icon={<Facebook />}
          />
          <IconButton
            onClick={() => signInWithRedirect({ provider: 'Google' })}
            p="0"
            mx={3}
            icon={<Google />}
          />
        </Flex>
        <Box
          as="span"
          display="block"
          textAlign="center"
          textTransform="uppercase"
          fontSize="md"
          color="gray.700"
          letterSpacing="0.05px"
          mb={6}
        >
          {t('or')}
        </Box>
        <Stack pb="4" spacing="6">
          <Flex direction={{ base: 'column', sm: 'row' }}>
            <Box
              w="full"
              mr={{ base: '0', sm: '8' }}
              mb={{ base: '8', sm: '0' }}
            >
              <InputField
                size="lg"
                errorMessage={t('signUp@firstNameErrorMessage')}
                label={t('signUp@firstName')}
                isInvalid={!!errors.firstName}
                {...register('firstName', { required: true })}
              />
            </Box>
            <InputField
              size="lg"
              errorMessage={t('signUp@lastNameErrorMessage')}
              label={t('signUp@lastName')}
              isInvalid={!!errors.lastName}
              {...register('lastName', { required: true })}
            />
          </Flex>
          <InputField
            size="lg"
            isInvalid={!!errors.email}
            label={t('signUp@email')}
            type="email"
            errorMessage={t(errors?.email?.message)}
            {...register('email', {
              required: {
                message: 'createEditProject@thisFieldIsRequired',
                value: true,
              },
              pattern: {
                message: 'createEditInitiative@emailError',
                value: /\S+@\S+\.\S+/,
              },
            })}
          />
          <Flex direction={{ base: 'column', sm: 'row' }}>
            {/* <SelectField
                        size="lg"
                        type="country"
                        display={{base: 'none', sm: 'none'}}
                        errorMessage={t('signUp@countryErrorMessage')}
                        label={t('signUp@country')}
                        placeholder={t('signUp@country')}
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        options={COUNTRY_OBJECTS} /> */}
            <Select
              size="lg"
              type="country"
              autoComplete="off"
              mb={{ base: '8', sm: '0' }}
              display={{ base: 'block', sm: 'block' }}
              errorMessage={t('signUp@countryErrorMessage')}
              label={t('signUp@country')}
              placeholder={t('signUp@country')}
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              options={COUNTRY_LIST}
            />
            <Box w="full" ml={{ base: '0', sm: '8' }}>
              <InputField
                autoComplete="off"
                size="lg"
                value={phone}
                pattern="[0-9+]*"
                isInvalid={phoneError}
                onChange={(e) =>
                  setPhone(e.target.value.replace(/[^0-9+]/g, ''))
                }
                errorMessage={t('signUp@phoneNumberErrorMessage')}
                label={t('signUp@phoneNumber')}
              />
            </Box>
          </Flex>
          <Box w="full" mr={{ base: '0', sm: '8' }} mb={{ base: '8', sm: '0' }}>
            <PasswordField
              size="lg"
              isInvalid={!!errors.password}
              {...register('password', { required: true })}
              errorMessage={t('signUp@passwordErrorMessage')}
              label={t('signUp@password')}
            />
          </Box>
          <PasswordField
            size="lg"
            isInvalid={!!errors.confirm_password}
            newPassword
            {...register('confirm_password', {
              required: true,
              pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
              minLength: 8,
              validate: (value) => value === password.current,
            })}
            errorMessage={t('signUp@confirmPasswordErrorMessage')}
            label={t('signUp@confirmPassword')}
          />
        </Stack>
        {user.id === 'guest' && (
          <Checkbox
            borderColor="border.100"
            mt={4}
            {...register('subscribeNews')}
          >
            <Text fontSize="sm" lineHeight="16px">
              {t('donation@subscribeOnDonateText').split('(bold)')[0]}
              <b>{t('donation@subscribeOnDonateText@bold')}</b>
              {t('donation@subscribeOnDonateText').split('(bold)')[1]}
            </Text>
          </Checkbox>
        )}
        <Box mt="33px" textAlign="center" fontSize="md" color="gray.900">
          {t('signUp@agreeToThe')}
          <br />
          <Link
            style={{
              color: '#1A1A1A',
              fontSize: '14px',
              fontWeight: 'bold',
              marginLeft: '5px',
              marginRight: '5px',
            }}
            target="_blank"
            to="/terms-and-conditions"
          >
            {t('signUp@termsConditions')}
          </Link>
          {t('signUp@andThe')}
          <Link
            style={{
              color: '#1A1A1A',
              fontSize: '14px',
              fontWeight: 'bold',
              marginLeft: '5px',
            }}
            target="_blank"
            to="/privacy-policy"
          >
            {t('signUp@privacyPolicy')}
          </Link>
        </Box>
        <Button
          isLoading={loading}
          mt={6}
          mb={4}
          w="full"
          type="submit"
          colorScheme="yellow"
          size="lg"
          variant="blue"
          fontSize="md"
          fontWeight="bold"
        >
          {t('signUp@submit')}
        </Button>
      </form>
      <Flex
        spacing="4"
        direction={{ base: 'column-reverse', md: 'row' }}
        mt="4"
        align="center"
        justify="center"
      >
        <Text color="gray.700" fontSize="sm" fontWeight="semibold">
          {t('signUp@haveAnAccount')}{' '}
          <Button fontSize="sm" color="orange.400">
            <Link to="/sign-in">{t('signUp@signIn')}</Link>
          </Button>
        </Text>
      </Flex>
    </>
  )
}
