import moment from 'moment'

const notificationUseCase = (notificationRepo) => {
  const listNotificationsByUserId = async (variables) => {
    if (variables?.userId === 'guest') {
      return null
    }
    const response = await notificationRepo.listNotificationsByUserId(variables)
    if (!response.data.notificationsByUserId.items.length) return null
    return response.data.notificationsByUserId.items.map((notification) => ({
      ...notification,
      formattedDate: moment(notification.createdAt).format('MMMM DD, YYYY'),
      fromNow: moment(notification.createdAt).fromNow(),
    }))
  }

  const updateNotification = async (variables) =>
    await notificationRepo.updateNotification(variables)

  return {
    listNotificationsByUserId,
    updateNotification,
  }
}

export default notificationUseCase
