import { Box, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import {
  useCurrencySettings,
  thousandSeparator,
} from 'contexts/CurrencySettingsContext'
import Header from './Header'
import { useGiftCard } from 'contexts/GiftCardContext'
import { useScreenSize } from 'contexts'

export default function Web({
  donation,
  isGiftCardDisabled,
  isContribution,
  project,
  giftCardMode,
  isBuyingGiftCard,
}) {
  const { isMobile } = useScreenSize()
  const { t } = useTranslation()
  const { currency } = useCurrencySettings()
  const { giftCard: giftCardInfo } = useGiftCard()

  return (
    <Box
      position="sticky"
      top="20px"
      maxW="573px"
      width="full"
      mt={{ base: 8, sm: 0 }}
    >
      <Box
        mx="auto"
        border={{
          base: isContribution ? 'none' : '1px solid #DDDDDD',
          sm: '1px solid #DDDDDD',
        }}
        borderColor="border.100"
        borderRadius="12px"
      >
        {(isBuyingGiftCard || !isMobile) && (
          <Header project={project} isContribution={isContribution} />
        )}
        {!giftCardMode && (
          <>
            {(isBuyingGiftCard || isMobile) && (
              <Box bg="#CDCDCD" w="full" height="1px" />
            )}
            <Flex direction="column" py={5} px={6}>
              <Flex justifyContent="space-between">
                <Box
                  fontWeight={isContribution ? '500' : 'regular'}
                  color="gray.800"
                  fontSize={isContribution ? 'xl' : 'lg'}
                >
                  {isBuyingGiftCard
                    ? t('giftCardAmount')
                    : t(
                        `donation@${
                          isGiftCardDisabled
                            ? 'yourMonthlyDonation'
                            : 'yourDonation'
                        }`
                      )}
                </Box>
                <Box
                  as="span"
                  fontWeight="medium"
                  fontSize="lg"
                  whiteSpace="nowrap"
                >
                  {thousandSeparator(Number(donation.amount), currency.current)}{' '}
                  {currency.current}
                </Box>
              </Flex>
              {!isContribution && (
                <Flex mb="2" justifyContent="space-between">
                  <Box fontWeight="regular" color="gray.800" fontSize="lg">
                    {isBuyingGiftCard
                      ? t('donation@reArmeniaTip')
                      : t(
                          `donation@${
                            isGiftCardDisabled
                              ? 'reArmeniaTipMonthly'
                              : 'reArmeniaTip'
                          }`
                        )}
                  </Box>
                  <Box
                    as="span"
                    fontWeight="medium"
                    fontSize="lg"
                    whiteSpace="nowrap"
                  >
                    {Number(donation.reArmenia).toFixed(2)} {currency.current}
                  </Box>
                </Flex>
              )}
              {!isContribution && (
                <Flex justifyContent="space-between">
                  <Box fontWeight="bold" color="gray.800" fontSize="lg">
                    {isBuyingGiftCard
                      ? t('donation@total')
                      : t(
                          `donation@${
                            isGiftCardDisabled ? 'totalMonthly' : 'total'
                          }`
                        )}
                  </Box>
                  <Box fontWeight="bold" fontSize="lg">
                    {thousandSeparator(
                      (Number(donation.reArmenia) ?? 0) +
                        (Number(donation.amount) ?? 0),
                      currency.current
                    )}{' '}
                    {currency.current}
                  </Box>
                </Flex>
              )}
            </Flex>
          </>
        )}
        {giftCardMode && (
          <Flex direction="column" pb={5} px={6}>
            <Flex
              justifyContent="space-between"
              borderTopWidth="1px"
              borderColor="border.100"
              fontWeight="bold"
              pt={4}
            >
              <Text>{t('giftCardAmount')}</Text>
              <Text>
                {giftCardInfo.amountWithCurrency} {giftCardInfo.currency}
              </Text>
            </Flex>
            <Flex justifyContent="space-between" pt={4}>
              <Text>{t('donation@yourDonation')}</Text>
              <Text>
                {Number(
                  Number(giftCardInfo.amountWithCurrency) - donation.reArmenia
                ).toFixed(2)}{' '}
                {giftCardInfo.currency}
              </Text>
            </Flex>
            <Flex justifyContent="space-between" pt={4}>
              <Text>{t('donation@reArmeniaTip')}</Text>
              <Text>
                {donation.reArmenia.toFixed(2)} {giftCardInfo.currency}
              </Text>
            </Flex>
          </Flex>
        )}
      </Box>
    </Box>
  )
}
