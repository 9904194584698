import React from 'react'
import { VStack } from '@chakra-ui/react'
import ProjectCard from 'components/ProjectCard'
import { useTranslation } from 'contexts/TranslationContext'
import {
  useCurrencySettings,
  thousandSeparator,
  convert,
} from 'contexts/CurrencySettingsContext'
import { useNavigation } from 'pages'
import { calculateProjectDurationLeft } from 'hooks'
import { categoriesFilter } from 'hooks/needs/helpers'

const All = ({
  initiatives,
  filterByCategory,
  needCategories,
  chosenCategories,
}) => {
  const { t, language } = useTranslation()
  const { currency, settings, changeDefaultCurrency } = useCurrencySettings()
  const { navigationPush } = useNavigation()

  return (
    <>
      <VStack w="full" spacing="10" pt="10" px="5">
        {initiatives
          .filter(({ status }) => status === 'live')
          .filter(filterByCategory)
          .map((card) => (
            <ProjectCard
              key={card.id}
              card={card}
              needCategories={needCategories?.filter(categoriesFilter(card))}
              inNeedOfText={t('inNeedOf')}
              thousandSeparatorText={thousandSeparator(
                currency.current === 'AMD'
                  ? card.amount + Number(card.updateAmount)
                  : card.amount / Number(settings?.currency[currency.current]) +
                      Number(card.updateAmount),
                currency.current
              )}
              width="full"
              type="collaborations"
              calculatedProjectDurationLeft={calculateProjectDurationLeft({
                t,
                campaignImplementorPeriod_en: card.campaignImplementorPeriod_en,
                fundraisingType: card.fundraisingType,
              })}
              isMobile
              language={language}
              currency={currency}
              settings={settings}
              changeDefaultCurrency={changeDefaultCurrency}
              navigationPush={navigationPush}
              cardStatusText={t(`status@${card.status}`)}
              cardCategoryText={t(`category@${card.category}`)}
              editText={t('edit')}
              unsubscribeText={t('unsubscribe')}
              monthlyGoalText={t('monthlyGoal')}
              raisedText={t('raised')}
              collaborationProposalsText={t(
                card?.collaboratorsTotal === 1
                  ? 'collaboration proposal'
                  : 'collaboration proposals'
              )}
              collaborationProposalsTextRuEdit={t('collaboration proposals')
                .split('...')[1]
                .replace('запросов', 'запроса')}
            />
          ))}
        {!chosenCategories?.length &&
          initiatives
            .filter(({ status }) => status === 'ended')
            .sort((a, b) => b.collaboratorsTotal - a.collaboratorsTotal)
            .map((card) => {
              return (
                <ProjectCard
                  key={card.id}
                  card={card}
                  raisedMoney={thousandSeparator(
                    convert({
                      amount: card.amount,
                      currencyRate: settings?.currency[currency.current],
                    }),
                    currency.current
                  )}
                  thousandSeparatorText={thousandSeparator(
                    currency.current === 'AMD'
                      ? card.amount + Number(card.updateAmount)
                      : card.amount /
                          Number(settings?.currency[currency.current]) +
                          Number(card.updateAmount),
                    currency.current
                  )}
                  width="full"
                  type="collaborations"
                  calculatedProjectDurationLeft={calculateProjectDurationLeft({
                    t,
                    campaignImplementorPeriod_en:
                      card.campaignImplementorPeriod_en,
                    fundraisingType: card.fundraisingType,
                  })}
                  isMobile
                  language={language}
                  currency={currency}
                  settings={settings}
                  changeDefaultCurrency={changeDefaultCurrency}
                  navigationPush={navigationPush}
                  cardStatusText={t(`status@${card.status}`)}
                  cardCategoryText={t(`category@${card.category}`)}
                  editText={t('edit')}
                  unsubscribeText={t('unsubscribe')}
                  monthlyGoalText={t('monthlyGoal')}
                  raisedText={t('raised')}
                  collaborationProposalsText={t(
                    card?.collaboratorsTotal === 1
                      ? 'collaboration proposal'
                      : 'collaboration proposals'
                  )}
                  collaborationProposalsTextRuEdit={t('collaboration proposals')
                    .split('...')[1]
                    .replace('запросов', 'запроса')}
                />
              )
            })}
        {!chosenCategories?.length &&
          initiatives
            .filter(({ status }) => status === 'closed')
            .map((card) => (
              <ProjectCard
                key={card.id}
                card={card}
                raisedMoney={thousandSeparator(
                  convert({
                    amount: card.amount,
                    currencyRate: settings?.currency[currency.current],
                  }),
                  currency.current
                )}
                thousandSeparatorText={thousandSeparator(
                  currency.current === 'AMD'
                    ? card.amount + Number(card.updateAmount)
                    : card.amount /
                        Number(settings?.currency[currency.current]) +
                        Number(card.updateAmount),
                  currency.current
                )}
                width="full"
                type="collaborations"
                calculatedProjectDurationLeft={calculateProjectDurationLeft({
                  t,
                  campaignImplementorPeriod_en:
                    card.campaignImplementorPeriod_en,
                  fundraisingType: card.fundraisingType,
                })}
                isMobile
                language={language}
                currency={currency}
                settings={settings}
                changeDefaultCurrency={changeDefaultCurrency}
                navigationPush={navigationPush}
                cardStatusText={t(`status@${card.status}`)}
                cardCategoryText={t(`category@${card.category}`)}
                editText={t('edit')}
                unsubscribeText={t('unsubscribe')}
                monthlyGoalText={t('monthlyGoal')}
                raisedText={t('raised')}
                collaborationProposalsText={t(
                  card?.collaboratorsTotal === 1
                    ? 'collaboration proposal'
                    : 'collaboration proposals'
                )}
                collaborationProposalsTextRuEdit={t('collaboration proposals')
                  .split('...')[1]
                  .replace('запросов', 'запроса')}
              />
            ))}
      </VStack>
    </>
  )
}

export default All
