import React, { useState } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Flex,
  Image,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import {
  useCustomToast,
  useDonationSubscriptionsByUserEmail,
  usePayment,
  useStringManipulations,
  useUpdateDonationSubscription,
} from 'hooks'
import ImageWrapper from 'components/ImageWrapper'

const isDevEnv = process.env.REACT_APP_ENV !== 'production'

export default function ListSubscriptions({ user, isBasket }) {
  const [activeCard, setActiveCard] = useState(null)
  const { t, language } = useTranslation()
  const { donationSubscriptions, isLoading, refetch } =
    useDonationSubscriptionsByUserEmail({
      userEmail: user?.email,
      sortDirection: 'DESC',
      filter: {
        basketId: { attributeExists: !!isBasket },
      },
    })

  const { findNamingTranslation } = useStringManipulations()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { updateDonationSubscription } = useUpdateDonationSubscription()
  const onAlertOpen = (e, donationSubscription) => {
    setActiveCard(donationSubscription)
    e.preventDefault()
    onOpen()
  }

  const onUnsubscribe = (e, donationSubscription) => {
    e.stopPropagation()
    updateDonationSubscription(
      {
        id: donationSubscription.id,
        unsubscribedDate: new Date().toISOString(),
      },
      {
        onSuccess: () => {
          refetch()
          onClose()
        },
        onError: (e) => console.log('>>> UNSUBSCRIBE ERROR', e),
      }
    )
  }

  // test charge
  const toast = useCustomToast()
  const { mutate: payment } = usePayment()

  const onTest = (subscription) => {
    console.log('TEST CHARGE : ', subscription)
    if (!isDevEnv) {
      return
    }
    const description = {
      SUBSCRIPTION: true,
      title: subscription?.project?.title_en,
      id: subscription?.project?.id,
      email: subscription?.userEmail,
    }

    const transactionInfo = {
      projectId: subscription?.project?.id,
      peerProjectId: subscription?.peerProjectId,
      projectTitle:
        subscription?.project?.title_en ||
        subscription?.project?.applicationTitle,
      amount:
        subscription?.donationToProject + subscription?.donationToReArmenia,
      donationToProject: subscription?.donationToProject,
      userId: user.id || 'guest',
      email: subscription?.userEmail,
      firstName: subscription?.firstName || user.firstName || 'guest',
      lastName: subscription?.lastName || user.lastName || 'guest',
      subscriptionId: subscription?.id,
      currency: subscription?.currency,
      currencyRate: subscription?.currencyRate,
    }
    let amount = subscription?.donationToProject
    if (subscription?.donationToReArmenia) {
      amount += subscription?.donationToReArmenia
      transactionInfo.donationToReArmenia = subscription?.donationToReArmenia
    }
    const body = {
      ...subscription,
      amount: Number(amount).toFixed(0) + '00',
      language: 'en',
      description: JSON.stringify(description),
      clientId: subscription.userEmail,
      bindingId: subscription.bindingId,
      functionName: 'bind-payment',
      transactionInfo,
    }
    toast({
      status: 'info',
      title: 'Charging...',
    })
    payment(
      { path: '/test-monthly', body },
      {
        onSuccess(res) {
          console.log('SUCCESS', res)
          toast({
            status: 'success',
            title: 'Successfully charged',
          })
        },
        onError(res) {
          console.log('ERROR', res)
          toast({
            status: 'error',
            title: 'Something went wrong, check console',
          })
        },
      }
    )
  }

  // test charge

  return (
    <>
      <ModalHeader
        pt={8}
        px={8}
        pb={0}
        fontSize="4xl"
        fontWeight="bold"
        color="gray.800"
        textTransform="capitalize"
      >
        {t('profile@dashboard@investor@recurring@manageSubs')}
      </ModalHeader>

      <ModalCloseButton
        _active={{ background: 'transparent' }}
        _hover={{ background: 'transparent' }}
        top="35px"
        right="22px"
      />
      <ModalBody p={8}>
        {isLoading ? (
          <Center h="100%">
            <Spinner />
          </Center>
        ) : (
          <VStack spacing={4} align="stretch">
            {donationSubscriptions?.map((sub) => (
              <Flex
                p={4}
                justify="space-between"
                boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0.06), 0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0px 16px 6px 0px rgba(0, 0, 0, 0.01), 0px 25px 7px 0px rgba(0, 0, 0, 0.00)"
                rounded="lg"
                key={
                  findNamingTranslation({
                    parentObject: sub.project,
                    value: 'title',
                    language,
                  }) || 'reArmenia'
                }
              >
                <Flex align="center" mr="5px">
                  <DonationImage image={sub?.image} />

                  <Text
                    fontWeight={600}
                    fontSize="lg"
                    color="gray.800"
                    noOfLines={1}
                    height="fit-content"
                  >
                    {(sub?.project &&
                      findNamingTranslation({
                        parentObject: sub.project,
                        value: 'title',
                        language,
                      })) ||
                      'reArmenia'}
                  </Text>
                </Flex>

                <Flex>
                  {sub?.unsubscribedDate ? (
                    <Button
                      height={12}
                      minH={12}
                      textTransform="none"
                      variant="lightGray"
                      fontSize="lg"
                      _hover={{}}
                      disabled
                      px={6}
                      flexShrink={0}
                    >
                      {t('profile@dashboard@investor@recurring@unsubscribed')}
                    </Button>
                  ) : (
                    <Button
                      height={12}
                      minH={12}
                      textTransform="none"
                      fontSize="lg"
                      variant="white"
                      px={6}
                      flexShrink={0}
                      onClick={(e) => onAlertOpen(e, sub)}
                    >
                      {t('profile@dashboard@investor@recurring@unsubscribe')}
                    </Button>
                  )}

                  {isDevEnv && !sub?.unsubscribedDate && (
                    <Button onClick={() => onTest(sub)} ml={3}>
                      TC
                    </Button>
                  )}
                </Flex>
              </Flex>
            ))}
          </VStack>
        )}
      </ModalBody>
      <AlertDialogModal
        t={t}
        onClose={onClose}
        donationSubscription={activeCard}
        isOpen={isOpen}
        onUnsubscribe={onUnsubscribe}
      />
    </>
  )
}

const DonationImage = ({ image }) => (
  <Box w="50px" h="50px" mr={3} flexShrink={0}>
    {image ? (
      <ImageWrapper
        borderRadius="50%"
        cursor="pointer"
        objectFit="cover"
        width="50px"
        bgColor="border.100"
        h="50px"
        ignoreFallback={true}
        src={`/thumbnail/small/${image}`}
      />
    ) : (
      <Image
        cursor="pointer"
        objectFit="cover"
        borderRadius="50%"
        bgColor="border.100"
        ignoreFallback={true}
        src="/assets/images/noImg.svg"
      />
    )}
  </Box>
)

const AlertDialogModal = ({
  t,
  onClose,
  isOpen,
  onUnsubscribe,
  isLoading,
  donationSubscription = null,
}) => {
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent p={10}>
        <AlertDialogBody
          p={0}
          mb={5}
          fontSize="2xl"
          fontWeight={600}
          color="black"
        >
          {t('profile@dashboard@investor@recurring@areYouSure')}
        </AlertDialogBody>
        <AlertDialogFooter p={0}>
          <Button
            isDisabled={isLoading}
            minH="20px"
            minW="100px"
            variant="white"
            onClick={onClose}
          >
            {t('profile@dashboard@investor@recurring@no')}
          </Button>
          <Button
            onClick={(e) => onUnsubscribe(e, donationSubscription)}
            minH="20px"
            minW="100px"
            variant="blue"
            colorScheme="red"
            ml={3}
            isLoading={isLoading}
            isDisabled={isLoading}
          >
            {t('profile@dashboard@investor@recurring@yes')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
