import { Flex, Image, Popover, Text } from '@chakra-ui/react'
import { PopoverWrapper } from './PopoverWrapper'

//** Icons
import { ReactComponent as ArrowDown } from 'assets/icons/small_chevron_down.svg'
import { ReactComponent as Languages } from 'assets/icons/languages.svg'
import { CheckboxGroupItems } from './ExpertProfileForm'
import COUNTRY_LIST from 'translations/countries.json'

const countryTransition = {
  Armenian: 'am',
  English: 'us',
  Russian: 'ru',
  French: 'fr',
  German: 'de',
}

const Header = ({ tt, watch, language }) => {
  const getFlagByLanguage = (lang) =>
    COUNTRY_LIST.find(({ code }) => code === countryTransition[lang]).image
  return (
    <>
      <Flex align="center">
        <Languages />
        <Text ml={3} fontSize="md" color="gray.800">
          {watch('selectedLanguage') ? (
            <Flex>
              {watch('selectedLanguage')?.map((countryLanguage) => (
                <Image
                  key={countryLanguage}
                  src={`/assets/flags/${getFlagByLanguage(countryLanguage)}`}
                  mr={1.5}
                  w="16px"
                />
              ))}
            </Flex>
          ) : (
            tt('Spoken languages', language)
          )}
        </Text>
      </Flex>
      <ArrowDown />
    </>
  )
}

export const LanguagesPopover = ({
  tt,
  control,
  watch,
  isInvalid,
  isDisabled,
  language,
}) => (
  <Popover placement="bottom-start">
    {({ isOpen }) => (
      <PopoverWrapper
        isInvalid={isInvalid}
        isDisabled={isDisabled}
        isOpen={isOpen}
        Header={() => <Header tt={tt} watch={watch} language={language} />}
      >
        <CheckboxGroupItems
          name="selectedLanguage"
          options={[
            {
              label: tt('expert@form@Armenian', language),
              value: 'Armenian',
            },
            {
              label: tt('expert@form@English', language),
              value: 'English',
            },
            {
              label: tt('expert@form@Russian', language),
              value: 'Russian',
            },
            { label: tt('expert@form@French', language), value: 'French' },
            { label: tt('expert@form@German', language), value: 'German' },
          ]}
          control={control}
        />
      </PopoverWrapper>
    )}
  </Popover>
)
