import {
  Flex,
  Text,
  Box,
  Radio,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Stack,
  ModalBody,
  useId,
  Heading,
  IconButton,
  Button,
} from '@chakra-ui/react'
import { ReactComponent as Arrow } from '../../assets/icons/arrowRight.svg'
import { ReactComponent as AngleDown } from '../../assets/icons/angleDown.svg'
import { ReactComponent as Close } from '../../assets/icons/closeBlack.svg'
import {
  CurrencyConverter,
  SUPPORTED_CURRENCIES,
} from 'contexts/CurrencySettingsContext'
import { useTranslation } from 'contexts/TranslationContext'

const CurrencySwitcherMobile = ({
  showDetails,
  currency,
  amount,
  changeDefaultCurrency,
  isDonation,
  defaultWidth,
  buttonStyles,
  footer = false,
}) => {
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const id = useId()
  return (
    <>
      <Button
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        borderColor="border.100"
        onClick={onOpen}
        width={defaultWidth}
        ml={footer || !isDonation ? 0 : 4}
        position="relative"
        zIndex="52"
        {...buttonStyles}
        _hover={{}}
      >
        <Text py="5" fontSize="xl" mr="2" color="gray.800">
          ({currency.current})
        </Text>
        <Box width="30px" align="center">
          {footer ? (
            <Arrow width="7px" height="12px" />
          ) : (
            <AngleDown
              style={{
                fill: isDonation ? 'black' : 'gray',
              }}
              width={8}
              height={8}
            />
          )}
        </Box>
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered="false"
        overflow="hidden"
      >
        <ModalOverlay />
        <ModalContent
          height="100vh"
          m="0"
          borderRadius={0}
          position="fixed"
          pt={12}
        >
          <ModalHeader px="4">
            <IconButton width="15px" onClick={onClose}>
              <Close width="15px" />
            </IconButton>
          </ModalHeader>
          <ModalBody px="8">
            <Heading>{t('currency@modalHeading')}</Heading>
            <Stack overflow="hidden" mt="8">
              {SUPPORTED_CURRENCIES.map((curr, key) => (
                <Flex
                  key={key}
                  fontWeight="600"
                  borderRadius="8px"
                  border="1px solid"
                  borderColor="border.100"
                  id={id}
                  px="4"
                  py="3"
                  width="full"
                  justifyContent="space-between"
                  fontSize="lg"
                  transition="all 0.2s"
                  cursor="pointer"
                  _checked={{
                    borderColor: 'blue.400',
                  }}
                  onClick={() => {
                    changeDefaultCurrency(curr)
                    onClose()
                  }}
                >
                  {t(`currency@${curr}`)}
                  {showDetails && (
                    <Text fontWeight="bold" fontSize="sm" pr="50px">
                      <CurrencyConverter amount={amount} currency={curr} />{' '}
                      {curr}
                    </Text>
                  )}
                  <Radio value={curr} isChecked={currency.current === curr} />
                </Flex>
              ))}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CurrencySwitcherMobile
