import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { ReactComponent as ArrowDown } from 'assets/icons/angleDown.svg'
import COUNTRY_LIST from 'translations/countries.json'
import { useTranslation } from 'contexts/TranslationContext'
const CountrySelect = (props) => {
  const { language } = useTranslation()
  const [isOpen, toggleMenu] = useState(false)
  const {
    label,
    description = '',
    labelProps,
    hidden,
    isInvalid,
    errorMessage,
    formControlProps,
    size = 'xl',
    setValue = () => {},
    getValues,
    isDisabled,
    countryCode = 'am',
    w = 'full',
  } = props
  const [countryIndex, setCountryIndex] = useState(
    COUNTRY_LIST.findIndex(({ code }) => code === countryCode)
  )
  useEffect(() => {
    setValue('country', COUNTRY_LIST[countryIndex])
    const phone = getValues('phone')
    const currentCountryCode = COUNTRY_LIST.find(
      ({ dialCode }) => dialCode === phone
    )
    if (!phone || currentCountryCode) {
      let value = COUNTRY_LIST[countryIndex]?.dialCode
      setValue('phone', value)
    }
  }, [countryIndex])
  return (
    <FormControl
      position="relative"
      display={hidden ? 'none' : 'block'}
      {...formControlProps}
    >
      {isInvalid && (
        <Box
          top="calc(100% + 5px)"
          fontSize="sm"
          fontWeight="semibold"
          position="absolute"
          color="red.400"
        >
          {errorMessage || isInvalid?.message}
        </Box>
      )}
      {label && (
        <FormLabel
          fontWeight={!description ? 400 : 700}
          fontSize={size}
          zIndex={2}
          {...labelProps}
        >
          {label}
          {description && (
            <Text fontSize="sm" fontWeight={400} mt={1} mb={4}>
              {description}
            </Text>
          )}
        </FormLabel>
      )}
      <Menu isLazy isOpen={isOpen}>
        <MenuButton
          isDisabled={isDisabled}
          variant="border"
          _hover={{}}
          as={Button}
          display="flex"
          textTransform="capitalize"
          fontWeight="normal"
          w={w}
          onClick={() => {
            toggleMenu((exVal) => !exVal)
          }}
          background="white"
        >
          <Flex alignItems="center" justifyContent="space-between">
            <Flex>
              {COUNTRY_LIST?.[countryIndex]?.image && (
                <Image
                  src={`/assets/flags/${COUNTRY_LIST?.[countryIndex]?.image}`}
                  mr={2}
                  w="16px"
                />
              )}
              <Text> {COUNTRY_LIST?.[countryIndex]?.[`name_${language}`]}</Text>
            </Flex>
            <ArrowDown width="8px" height="6px" />
          </Flex>
        </MenuButton>
        <MenuList w="full">
          <Stack
            w={{ base: '275px', sm: '370px' }}
            maxH={{ base: '50vh', sm: '400px' }}
            borderBottomRadius={16}
            overflowY="auto"
            sx={{
              '&::-webkit-scrollbar': {
                width: '8px',
                backgroundColor: `blue.400`,
                marginRight: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: `blue.200`,
                borderRadius: 20,
              },
            }}
          >
            {COUNTRY_LIST.map(({ id, image, ...rest }, index) => (
              <Flex
                pl={2}
                cursor="pointer"
                key={id}
                alignItems="center"
                _hover={{ sm: { bg: 'blue.300' }, base: {} }}
                bg={index === countryIndex ? 'blue.300' : 'blue.400'}
                onClick={() => {
                  setCountryIndex(index)
                  toggleMenu(false)
                }}
              >
                {' '}
                {image && (
                  <Image src={`/assets/flags/${image}`} mr={2} w="32px" />
                )}
                {rest[`name_${language}`]}
              </Flex>
            ))}
          </Stack>
        </MenuList>
      </Menu>
    </FormControl>
  )
}

export default CountrySelect
