import {
  Box,
  Grid,
  GridItem,
  Heading,
  Img,
  Text,
  HStack,
} from '@chakra-ui/react'
import { useTranslation, LANG_RU_SHORT } from 'contexts/TranslationContext'

export default function CharityBasketSection() {
  const { t, language } = useTranslation()

  return (
    <Grid
      templateColumns="repeat(12, 1fr)"
      px={{ base: 5, md: 0 }}
      pt={{ base: 7, md: 0 }}
    >
      <GridItem colSpan={{ base: 12, md: 8 }} colStart={{ base: 0, md: 3 }}>
        <Grid
          templateColumns="repeat(12, 1fr)"
          gap={4}
          borderBottomWidth={1}
          borderColor="gray.500"
          pb={4}
        >
          <GridItem
            colSpan={{ base: 10, md: 4 }}
            colStart={{ base: 2, md: 0 }}
            display="flex"
            justifyContent="center"
          >
            <Img
              src="/assets/images/charity.png"
              alt="Charity"
              width={{ base: '265px', md: '100%' }}
              height={{ base: '265px', md: 'auto' }}
            />
          </GridItem>
          <GridItem
            as={HStack}
            colSpan={{ base: 12, md: 8 }}
            gridRowStart={{ base: 1, md: 'inherit' }}
            spacing={2}
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Heading as="h2" size="3xl" color="blue.500">
              {t('Amplify Your Impact:')}
              {language !== LANG_RU_SHORT && (
                <>
                  <Box as="br" />
                  {t('Charity Basket')}
                </>
              )}
            </Heading>
            <Text color="gray.700">
              {t(
                'Easily support multiple vetted Nonprofits with your any-amount donations in a single transaction.'
              )}
            </Text>
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>
  )
}
