function jsonParseSafe(stringJson) {
  try {
    if (typeof stringJson !== 'string') {
      console.log('ERROR WHILE PARSING JSON, stringJson is not string')
      return null
    }
    const result = JSON.parse(stringJson)
    return result
  } catch (error) {
    console.log('ERROR WHILE PARSING JSON', error)
    return null
  }
}

const settingsUseCase = (settingsUseCase) => {
  const getSettings = async () => {
    const settings = await settingsUseCase.getSettings()

    if (settings?.currency) {
      settings.currency = jsonParseSafe(jsonParseSafe(settings.currency))
    }

    return settings
  }

  return {
    getSettings,
  }
}

export default settingsUseCase
