/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoId
      cosmoId
      customerId
      expertId
      email
      seenSubscribeModal
      firstName
      lastName
      phone
      country
      currency
      companyName
      city
      postalCode
      monetoryDonor
      aboutMe
      language
      shortDescription
      youtubeLink
      facebookLink
      linkedinLink
      websiteLink
      otherLinks
      creditCards {
        bindingId
        pan
        cardholderName
        expiration
        type
        isDefault
        __typename
      }
      isSeenExpertPopup
      unreadNotifications
      isReArmeniaInvestor
      isInitiator
      isFundraiserCreator
      isGoodraiserCreator
      investmentOneTimeTotal
      investmentRecurringTotal
      investmentBasketTotal
      investmentBasketMonthlyActive
      notificationSettings
      totalOneTimeDonation
      totalRecurringDonation
      selectedCategories
      selectedLocations
      stripeAttachedCards {
        id
        type
        last4
        __typename
      }
      charityBasket {
        projectId
        amount
        projectCover
        projectTitle_en
        projectTitle_ru
        projectTitle_hy
        currency
        currencyRate
        lastChargedAmount
        unsubscribedDate
        __typename
      }
      isSeenExpertProfileNotification
      isSeenExpertProfileModal
      lastSignedInAt
      createdAt
      updatedAt
      __typename
    }
  }
`
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoId
        cosmoId
        customerId
        expertId
        email
        seenSubscribeModal
        firstName
        lastName
        phone
        country
        currency
        companyName
        city
        postalCode
        monetoryDonor
        aboutMe
        language
        shortDescription
        youtubeLink
        facebookLink
        linkedinLink
        websiteLink
        otherLinks
        creditCards {
          bindingId
          pan
          cardholderName
          expiration
          type
          isDefault
          __typename
        }
        isSeenExpertPopup
        unreadNotifications
        isReArmeniaInvestor
        isInitiator
        isFundraiserCreator
        isGoodraiserCreator
        investmentOneTimeTotal
        investmentRecurringTotal
        investmentBasketTotal
        investmentBasketMonthlyActive
        notificationSettings
        totalOneTimeDonation
        totalRecurringDonation
        selectedCategories
        selectedLocations
        stripeAttachedCards {
          id
          type
          last4
          __typename
        }
        charityBasket {
          projectId
          amount
          projectCover
          projectTitle_en
          projectTitle_ru
          projectTitle_hy
          currency
          currencyRate
          lastChargedAmount
          unsubscribedDate
          __typename
        }
        isSeenExpertProfileNotification
        isSeenExpertProfileModal
        lastSignedInAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const usersByEmail = /* GraphQL */ `
  query UsersByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoId
        cosmoId
        customerId
        expertId
        email
        seenSubscribeModal
        firstName
        lastName
        phone
        country
        currency
        companyName
        city
        postalCode
        monetoryDonor
        aboutMe
        language
        shortDescription
        youtubeLink
        facebookLink
        linkedinLink
        websiteLink
        otherLinks
        creditCards {
          bindingId
          pan
          cardholderName
          expiration
          type
          isDefault
          __typename
        }
        isSeenExpertPopup
        unreadNotifications
        isReArmeniaInvestor
        isInitiator
        isFundraiserCreator
        isGoodraiserCreator
        investmentOneTimeTotal
        investmentRecurringTotal
        investmentBasketTotal
        investmentBasketMonthlyActive
        notificationSettings
        totalOneTimeDonation
        totalRecurringDonation
        selectedCategories
        selectedLocations
        stripeAttachedCards {
          id
          type
          last4
          __typename
        }
        charityBasket {
          projectId
          amount
          projectCover
          projectTitle_en
          projectTitle_ru
          projectTitle_hy
          currency
          currencyRate
          lastChargedAmount
          unsubscribedDate
          __typename
        }
        isSeenExpertProfileNotification
        isSeenExpertProfileModal
        lastSignedInAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      body
      userId
      language
      isRead
      image
      link
      createdAt
      updatedAt
      id
      __typename
    }
  }
`
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        body
        userId
        language
        isRead
        image
        link
        createdAt
        updatedAt
        id
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const notificationsByUserId = /* GraphQL */ `
  query NotificationsByUserId(
    $userId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        body
        userId
        language
        isRead
        image
        link
        createdAt
        updatedAt
        id
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getGiftCard = /* GraphQL */ `
  query GetGiftCard($id: ID!) {
    getGiftCard(id: $id) {
      id
      userId
      code
      amount
      from
      message
      toEmail
      image
      currency
      currencyRate
      paymentStatus
      language
      fromEmail
      used
      usedAt
      createdAt
      updatedAt
      __typename
    }
  }
`
export const listGiftCards = /* GraphQL */ `
  query ListGiftCards(
    $filter: ModelGiftCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGiftCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        code
        amount
        from
        message
        toEmail
        image
        currency
        currencyRate
        paymentStatus
        language
        fromEmail
        used
        usedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const giftCardsByCode = /* GraphQL */ `
  query GiftCardsByCode(
    $code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelGiftCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    giftCardsByCode(
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        code
        amount
        from
        message
        toEmail
        image
        currency
        currencyRate
        paymentStatus
        language
        fromEmail
        used
        usedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getLambdaInvocation = /* GraphQL */ `
  query GetLambdaInvocation($id: ID!) {
    getLambdaInvocation(id: $id) {
      id
      functionName
      awsRequestId
      createdAt
      updatedAt
      __typename
    }
  }
`
export const listLambdaInvocations = /* GraphQL */ `
  query ListLambdaInvocations(
    $filter: ModelLambdaInvocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLambdaInvocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        functionName
        awsRequestId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const lambdaInvocationByAWSRequestId = /* GraphQL */ `
  query LambdaInvocationByAWSRequestId(
    $awsRequestId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelLambdaInvocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lambdaInvocationByAWSRequestId(
      awsRequestId: $awsRequestId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        functionName
        awsRequestId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getSubscriptionCharge = /* GraphQL */ `
  query GetSubscriptionCharge($id: ID!) {
    getSubscriptionCharge(id: $id) {
      id
      subscriptionId
      subscriptionIdDate
      isFailedAttempt
      createdAt
      updatedAt
      __typename
    }
  }
`
export const listSubscriptionCharges = /* GraphQL */ `
  query ListSubscriptionCharges(
    $filter: ModelSubscriptionChargeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionCharges(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subscriptionId
        subscriptionIdDate
        isFailedAttempt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const subscribtionChargesBySubscriptionIdDate = /* GraphQL */ `
  query SubscribtionChargesBySubscriptionIdDate(
    $subscriptionIdDate: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSubscriptionChargeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subscribtionChargesBySubscriptionIdDate(
      subscriptionIdDate: $subscriptionIdDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subscriptionId
        subscriptionIdDate
        isFailedAttempt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getDonationSubscription = /* GraphQL */ `
  query GetDonationSubscription($id: ID!) {
    getDonationSubscription(id: $id) {
      id
      projectId
      peerProjectId
      bindingId
      userId
      userEmail
      basketId
      projectIds
      basket {
        projectId
        amount
        projectCover
        projectTitle_en
        projectTitle_ru
        projectTitle_hy
        currency
        currencyRate
        lastChargedAmount
        unsubscribedDate
        __typename
      }
      firstName
      image
      lastName
      donationToReArmenia
      projectTitle
      isAnonymous
      isInfoAnonymous
      donationToProject
      lastChargedAmount
      unsubscribedDate
      month
      creditCard {
        pan
        type
        stripePaymentMethod
        __typename
      }
      day
      stripeData
      currency
      language
      paymentMethod
      paypalSubscriptionId
      paypalCustomId
      currencyRate
      nextChargeDate
      lastChargedAt
      createdAt
      updatedAt
      __typename
    }
  }
`
export const listDonationSubscriptions = /* GraphQL */ `
  query ListDonationSubscriptions(
    $filter: ModelDonationSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDonationSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        peerProjectId
        bindingId
        userId
        userEmail
        basketId
        projectIds
        basket {
          projectId
          amount
          projectCover
          projectTitle_en
          projectTitle_ru
          projectTitle_hy
          currency
          currencyRate
          lastChargedAmount
          unsubscribedDate
          __typename
        }
        firstName
        image
        lastName
        donationToReArmenia
        projectTitle
        isAnonymous
        isInfoAnonymous
        donationToProject
        lastChargedAmount
        unsubscribedDate
        month
        creditCard {
          pan
          type
          stripePaymentMethod
          __typename
        }
        day
        stripeData
        currency
        language
        paymentMethod
        paypalSubscriptionId
        paypalCustomId
        currencyRate
        nextChargeDate
        lastChargedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const donationSubscriptionsByProjectId = /* GraphQL */ `
  query DonationSubscriptionsByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDonationSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    donationSubscriptionsByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        peerProjectId
        bindingId
        userId
        userEmail
        basketId
        projectIds
        basket {
          projectId
          amount
          projectCover
          projectTitle_en
          projectTitle_ru
          projectTitle_hy
          currency
          currencyRate
          lastChargedAmount
          unsubscribedDate
          __typename
        }
        firstName
        image
        lastName
        donationToReArmenia
        projectTitle
        isAnonymous
        isInfoAnonymous
        donationToProject
        lastChargedAmount
        unsubscribedDate
        month
        creditCard {
          pan
          type
          stripePaymentMethod
          __typename
        }
        day
        stripeData
        currency
        language
        paymentMethod
        paypalSubscriptionId
        paypalCustomId
        currencyRate
        nextChargeDate
        lastChargedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const donationSubscriptionsByPeerProjectId = /* GraphQL */ `
  query DonationSubscriptionsByPeerProjectId(
    $peerProjectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDonationSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    donationSubscriptionsByPeerProjectId(
      peerProjectId: $peerProjectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        peerProjectId
        bindingId
        userId
        userEmail
        basketId
        projectIds
        basket {
          projectId
          amount
          projectCover
          projectTitle_en
          projectTitle_ru
          projectTitle_hy
          currency
          currencyRate
          lastChargedAmount
          unsubscribedDate
          __typename
        }
        firstName
        image
        lastName
        donationToReArmenia
        projectTitle
        isAnonymous
        isInfoAnonymous
        donationToProject
        lastChargedAmount
        unsubscribedDate
        month
        creditCard {
          pan
          type
          stripePaymentMethod
          __typename
        }
        day
        stripeData
        currency
        language
        paymentMethod
        paypalSubscriptionId
        paypalCustomId
        currencyRate
        nextChargeDate
        lastChargedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const sortedDonationSubscriptionsByUserEmail = /* GraphQL */ `
  query SortedDonationSubscriptionsByUserEmail(
    $userEmail: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDonationSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sortedDonationSubscriptionsByUserEmail(
      userEmail: $userEmail
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        peerProjectId
        bindingId
        userId
        userEmail
        basketId
        projectIds
        basket {
          projectId
          amount
          projectCover
          projectTitle_en
          projectTitle_ru
          projectTitle_hy
          currency
          currencyRate
          lastChargedAmount
          unsubscribedDate
          __typename
        }
        firstName
        image
        lastName
        donationToReArmenia
        projectTitle
        isAnonymous
        isInfoAnonymous
        donationToProject
        lastChargedAmount
        unsubscribedDate
        month
        creditCard {
          pan
          type
          stripePaymentMethod
          __typename
        }
        day
        stripeData
        currency
        language
        paymentMethod
        paypalSubscriptionId
        paypalCustomId
        currencyRate
        nextChargeDate
        lastChargedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const donationSubscriptionsByBasketId = /* GraphQL */ `
  query DonationSubscriptionsByBasketId(
    $basketId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDonationSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    donationSubscriptionsByBasketId(
      basketId: $basketId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        peerProjectId
        bindingId
        userId
        userEmail
        basketId
        projectIds
        basket {
          projectId
          amount
          projectCover
          projectTitle_en
          projectTitle_ru
          projectTitle_hy
          currency
          currencyRate
          lastChargedAmount
          unsubscribedDate
          __typename
        }
        firstName
        image
        lastName
        donationToReArmenia
        projectTitle
        isAnonymous
        isInfoAnonymous
        donationToProject
        lastChargedAmount
        unsubscribedDate
        month
        creditCard {
          pan
          type
          stripePaymentMethod
          __typename
        }
        day
        stripeData
        currency
        language
        paymentMethod
        paypalSubscriptionId
        paypalCustomId
        currencyRate
        nextChargeDate
        lastChargedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const donationSubscriptionByPaypalSubscriptionId = /* GraphQL */ `
  query DonationSubscriptionByPaypalSubscriptionId(
    $paypalSubscriptionId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDonationSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    donationSubscriptionByPaypalSubscriptionId(
      paypalSubscriptionId: $paypalSubscriptionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        peerProjectId
        bindingId
        userId
        userEmail
        basketId
        projectIds
        basket {
          projectId
          amount
          projectCover
          projectTitle_en
          projectTitle_ru
          projectTitle_hy
          currency
          currencyRate
          lastChargedAmount
          unsubscribedDate
          __typename
        }
        firstName
        image
        lastName
        donationToReArmenia
        projectTitle
        isAnonymous
        isInfoAnonymous
        donationToProject
        lastChargedAmount
        unsubscribedDate
        month
        creditCard {
          pan
          type
          stripePaymentMethod
          __typename
        }
        day
        stripeData
        currency
        language
        paymentMethod
        paypalSubscriptionId
        paypalCustomId
        currencyRate
        nextChargeDate
        lastChargedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const donationSubsscriptionsByCreatedAt = /* GraphQL */ `
  query DonationSubsscriptionsByCreatedAt(
    $createdAt: AWSDateTime!
    $sortDirection: ModelSortDirection
    $filter: ModelDonationSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    donationSubsscriptionsByCreatedAt(
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        peerProjectId
        bindingId
        userId
        userEmail
        basketId
        projectIds
        basket {
          projectId
          amount
          projectCover
          projectTitle_en
          projectTitle_ru
          projectTitle_hy
          currency
          currencyRate
          lastChargedAmount
          unsubscribedDate
          __typename
        }
        firstName
        image
        lastName
        donationToReArmenia
        projectTitle
        isAnonymous
        isInfoAnonymous
        donationToProject
        lastChargedAmount
        unsubscribedDate
        month
        creditCard {
          pan
          type
          stripePaymentMethod
          __typename
        }
        day
        stripeData
        currency
        language
        paymentMethod
        paypalSubscriptionId
        paypalCustomId
        currencyRate
        nextChargeDate
        lastChargedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getFailedMonthlyAttempts = /* GraphQL */ `
  query GetFailedMonthlyAttempts($id: ID!) {
    getFailedMonthlyAttempts(id: $id) {
      id
      projectId
      userId
      email
      firstName
      lastName
      amount
      subscriptionId
      doneAttempts
      itRetryCanceled
      createdAt
      updatedAt
      __typename
    }
  }
`
export const listFailedMonthlyAttempts = /* GraphQL */ `
  query ListFailedMonthlyAttempts(
    $filter: ModelFailedMonthlyAttemptsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFailedMonthlyAttempts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        userId
        email
        firstName
        lastName
        amount
        subscriptionId
        doneAttempts
        itRetryCanceled
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const failedMonthlyAttemptsBySubscriptionId = /* GraphQL */ `
  query FailedMonthlyAttemptsBySubscriptionId(
    $subscriptionId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFailedMonthlyAttemptsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    failedMonthlyAttemptsBySubscriptionId(
      subscriptionId: $subscriptionId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        userId
        email
        firstName
        lastName
        amount
        subscriptionId
        doneAttempts
        itRetryCanceled
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getBindingCard = /* GraphQL */ `
  query GetBindingCard($id: ID!) {
    getBindingCard(id: $id) {
      id
      userEmail
      bindingId
      createdAt
      updatedAt
      __typename
    }
  }
`
export const listBindingCards = /* GraphQL */ `
  query ListBindingCards(
    $filter: ModelBindingCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBindingCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userEmail
        bindingId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const bindingCardsByUserId = /* GraphQL */ `
  query BindingCardsByUserId(
    $bindingId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBindingCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bindingCardsByUserId(
      bindingId: $bindingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userEmail
        bindingId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      userId
      creditCard {
        pan
        type
        stripePaymentMethod
        __typename
      }
      giftCardId
      transferredToId
      transferredFromId
      giftCardCode
      giftCardTransactionType
      email
      firstName
      lastName
      peerProjectId
      projectId
      projectTitle_en
      projectTitle_hy
      projectTitle_ru
      projectImage
      donationToReArmenia
      donationToProject
      giftCardAmount
      status
      orderStatus
      statusCode
      statusDescription
      amount
      orderId
      subscriptionId
      basketId
      perk {
        id
        title
        title_en
        title_hy
        title_ru
        description_en
        description_hy
        description_ru
        price
        image
        __typename
      }
      donationsCountWithEmail
      currency
      currencyRate
      matchedAmount
      matchedBy
      language
      isValid
      isManual
      isEdit
      isAnonymous
      isMonthlyCharge
      isStripe
      provider
      isInfoAnonymous
      projectClosedAt
      createdAt
      updatedAt
      __typename
    }
  }
`
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        creditCard {
          pan
          type
          stripePaymentMethod
          __typename
        }
        giftCardId
        transferredToId
        transferredFromId
        giftCardCode
        giftCardTransactionType
        email
        firstName
        lastName
        peerProjectId
        projectId
        projectTitle_en
        projectTitle_hy
        projectTitle_ru
        projectImage
        donationToReArmenia
        donationToProject
        giftCardAmount
        status
        orderStatus
        statusCode
        statusDescription
        amount
        orderId
        subscriptionId
        basketId
        perk {
          id
          title
          title_en
          title_hy
          title_ru
          description_en
          description_hy
          description_ru
          price
          image
          __typename
        }
        donationsCountWithEmail
        currency
        currencyRate
        matchedAmount
        matchedBy
        language
        isValid
        isManual
        isEdit
        isAnonymous
        isMonthlyCharge
        isStripe
        provider
        isInfoAnonymous
        projectClosedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const transactionsByGiftCardCode = /* GraphQL */ `
  query TransactionsByGiftCardCode(
    $giftCardCode: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByGiftCardCode(
      giftCardCode: $giftCardCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        creditCard {
          pan
          type
          stripePaymentMethod
          __typename
        }
        giftCardId
        transferredToId
        transferredFromId
        giftCardCode
        giftCardTransactionType
        email
        firstName
        lastName
        peerProjectId
        projectId
        projectTitle_en
        projectTitle_hy
        projectTitle_ru
        projectImage
        donationToReArmenia
        donationToProject
        giftCardAmount
        status
        orderStatus
        statusCode
        statusDescription
        amount
        orderId
        subscriptionId
        basketId
        perk {
          id
          title
          title_en
          title_hy
          title_ru
          description_en
          description_hy
          description_ru
          price
          image
          __typename
        }
        donationsCountWithEmail
        currency
        currencyRate
        matchedAmount
        matchedBy
        language
        isValid
        isManual
        isEdit
        isAnonymous
        isMonthlyCharge
        isStripe
        provider
        isInfoAnonymous
        projectClosedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const transactionsByEmailDate = /* GraphQL */ `
  query TransactionsByEmailDate(
    $email: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByEmailDate(
      email: $email
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        creditCard {
          pan
          type
          stripePaymentMethod
          __typename
        }
        giftCardId
        transferredToId
        transferredFromId
        giftCardCode
        giftCardTransactionType
        email
        firstName
        lastName
        peerProjectId
        projectId
        projectTitle_en
        projectTitle_hy
        projectTitle_ru
        projectImage
        donationToReArmenia
        donationToProject
        giftCardAmount
        status
        orderStatus
        statusCode
        statusDescription
        amount
        orderId
        subscriptionId
        basketId
        perk {
          id
          title
          title_en
          title_hy
          title_ru
          description_en
          description_hy
          description_ru
          price
          image
          __typename
        }
        donationsCountWithEmail
        currency
        currencyRate
        matchedAmount
        matchedBy
        language
        isValid
        isManual
        isEdit
        isAnonymous
        isMonthlyCharge
        isStripe
        provider
        isInfoAnonymous
        projectClosedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const transactionsByPeerProjectId = /* GraphQL */ `
  query TransactionsByPeerProjectId(
    $peerProjectId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByPeerProjectId(
      peerProjectId: $peerProjectId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        creditCard {
          pan
          type
          stripePaymentMethod
          __typename
        }
        giftCardId
        transferredToId
        transferredFromId
        giftCardCode
        giftCardTransactionType
        email
        firstName
        lastName
        peerProjectId
        projectId
        projectTitle_en
        projectTitle_hy
        projectTitle_ru
        projectImage
        donationToReArmenia
        donationToProject
        giftCardAmount
        status
        orderStatus
        statusCode
        statusDescription
        amount
        orderId
        subscriptionId
        basketId
        perk {
          id
          title
          title_en
          title_hy
          title_ru
          description_en
          description_hy
          description_ru
          price
          image
          __typename
        }
        donationsCountWithEmail
        currency
        currencyRate
        matchedAmount
        matchedBy
        language
        isValid
        isManual
        isEdit
        isAnonymous
        isMonthlyCharge
        isStripe
        provider
        isInfoAnonymous
        projectClosedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const transactionsByTopDonation = /* GraphQL */ `
  query TransactionsByTopDonation(
    $projectId: String!
    $amount: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByTopDonation(
      projectId: $projectId
      amount: $amount
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        creditCard {
          pan
          type
          stripePaymentMethod
          __typename
        }
        giftCardId
        transferredToId
        transferredFromId
        giftCardCode
        giftCardTransactionType
        email
        firstName
        lastName
        peerProjectId
        projectId
        projectTitle_en
        projectTitle_hy
        projectTitle_ru
        projectImage
        donationToReArmenia
        donationToProject
        giftCardAmount
        status
        orderStatus
        statusCode
        statusDescription
        amount
        orderId
        subscriptionId
        basketId
        perk {
          id
          title
          title_en
          title_hy
          title_ru
          description_en
          description_hy
          description_ru
          price
          image
          __typename
        }
        donationsCountWithEmail
        currency
        currencyRate
        matchedAmount
        matchedBy
        language
        isValid
        isManual
        isEdit
        isAnonymous
        isMonthlyCharge
        isStripe
        provider
        isInfoAnonymous
        projectClosedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const transactionsByTopProjectDonation = /* GraphQL */ `
  query TransactionsByTopProjectDonation(
    $projectId: String!
    $donationToProject: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByTopProjectDonation(
      projectId: $projectId
      donationToProject: $donationToProject
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        creditCard {
          pan
          type
          stripePaymentMethod
          __typename
        }
        giftCardId
        transferredToId
        transferredFromId
        giftCardCode
        giftCardTransactionType
        email
        firstName
        lastName
        peerProjectId
        projectId
        projectTitle_en
        projectTitle_hy
        projectTitle_ru
        projectImage
        donationToReArmenia
        donationToProject
        giftCardAmount
        status
        orderStatus
        statusCode
        statusDescription
        amount
        orderId
        subscriptionId
        basketId
        perk {
          id
          title
          title_en
          title_hy
          title_ru
          description_en
          description_hy
          description_ru
          price
          image
          __typename
        }
        donationsCountWithEmail
        currency
        currencyRate
        matchedAmount
        matchedBy
        language
        isValid
        isManual
        isEdit
        isAnonymous
        isMonthlyCharge
        isStripe
        provider
        isInfoAnonymous
        projectClosedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const transactionsByDate = /* GraphQL */ `
  query TransactionsByDate(
    $projectId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByDate(
      projectId: $projectId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        creditCard {
          pan
          type
          stripePaymentMethod
          __typename
        }
        giftCardId
        transferredToId
        transferredFromId
        giftCardCode
        giftCardTransactionType
        email
        firstName
        lastName
        peerProjectId
        projectId
        projectTitle_en
        projectTitle_hy
        projectTitle_ru
        projectImage
        donationToReArmenia
        donationToProject
        giftCardAmount
        status
        orderStatus
        statusCode
        statusDescription
        amount
        orderId
        subscriptionId
        basketId
        perk {
          id
          title
          title_en
          title_hy
          title_ru
          description_en
          description_hy
          description_ru
          price
          image
          __typename
        }
        donationsCountWithEmail
        currency
        currencyRate
        matchedAmount
        matchedBy
        language
        isValid
        isManual
        isEdit
        isAnonymous
        isMonthlyCharge
        isStripe
        provider
        isInfoAnonymous
        projectClosedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const transactionsByProjectIdEmail = /* GraphQL */ `
  query TransactionsByProjectIdEmail(
    $projectId: String!
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByProjectIdEmail(
      projectId: $projectId
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        creditCard {
          pan
          type
          stripePaymentMethod
          __typename
        }
        giftCardId
        transferredToId
        transferredFromId
        giftCardCode
        giftCardTransactionType
        email
        firstName
        lastName
        peerProjectId
        projectId
        projectTitle_en
        projectTitle_hy
        projectTitle_ru
        projectImage
        donationToReArmenia
        donationToProject
        giftCardAmount
        status
        orderStatus
        statusCode
        statusDescription
        amount
        orderId
        subscriptionId
        basketId
        perk {
          id
          title
          title_en
          title_hy
          title_ru
          description_en
          description_hy
          description_ru
          price
          image
          __typename
        }
        donationsCountWithEmail
        currency
        currencyRate
        matchedAmount
        matchedBy
        language
        isValid
        isManual
        isEdit
        isAnonymous
        isMonthlyCharge
        isStripe
        provider
        isInfoAnonymous
        projectClosedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const transactionsByOrderId = /* GraphQL */ `
  query TransactionsByOrderId(
    $orderId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByOrderId(
      orderId: $orderId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        creditCard {
          pan
          type
          stripePaymentMethod
          __typename
        }
        giftCardId
        transferredToId
        transferredFromId
        giftCardCode
        giftCardTransactionType
        email
        firstName
        lastName
        peerProjectId
        projectId
        projectTitle_en
        projectTitle_hy
        projectTitle_ru
        projectImage
        donationToReArmenia
        donationToProject
        giftCardAmount
        status
        orderStatus
        statusCode
        statusDescription
        amount
        orderId
        subscriptionId
        basketId
        perk {
          id
          title
          title_en
          title_hy
          title_ru
          description_en
          description_hy
          description_ru
          price
          image
          __typename
        }
        donationsCountWithEmail
        currency
        currencyRate
        matchedAmount
        matchedBy
        language
        isValid
        isManual
        isEdit
        isAnonymous
        isMonthlyCharge
        isStripe
        provider
        isInfoAnonymous
        projectClosedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const transactionsBySubscriptionId = /* GraphQL */ `
  query TransactionsBySubscriptionId(
    $subscriptionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsBySubscriptionId(
      subscriptionId: $subscriptionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        creditCard {
          pan
          type
          stripePaymentMethod
          __typename
        }
        giftCardId
        transferredToId
        transferredFromId
        giftCardCode
        giftCardTransactionType
        email
        firstName
        lastName
        peerProjectId
        projectId
        projectTitle_en
        projectTitle_hy
        projectTitle_ru
        projectImage
        donationToReArmenia
        donationToProject
        giftCardAmount
        status
        orderStatus
        statusCode
        statusDescription
        amount
        orderId
        subscriptionId
        basketId
        perk {
          id
          title
          title_en
          title_hy
          title_ru
          description_en
          description_hy
          description_ru
          price
          image
          __typename
        }
        donationsCountWithEmail
        currency
        currencyRate
        matchedAmount
        matchedBy
        language
        isValid
        isManual
        isEdit
        isAnonymous
        isMonthlyCharge
        isStripe
        provider
        isInfoAnonymous
        projectClosedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const transactionsByBasketId = /* GraphQL */ `
  query TransactionsByBasketId(
    $basketId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByBasketId(
      basketId: $basketId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        creditCard {
          pan
          type
          stripePaymentMethod
          __typename
        }
        giftCardId
        transferredToId
        transferredFromId
        giftCardCode
        giftCardTransactionType
        email
        firstName
        lastName
        peerProjectId
        projectId
        projectTitle_en
        projectTitle_hy
        projectTitle_ru
        projectImage
        donationToReArmenia
        donationToProject
        giftCardAmount
        status
        orderStatus
        statusCode
        statusDescription
        amount
        orderId
        subscriptionId
        basketId
        perk {
          id
          title
          title_en
          title_hy
          title_ru
          description_en
          description_hy
          description_ru
          price
          image
          __typename
        }
        donationsCountWithEmail
        currency
        currencyRate
        matchedAmount
        matchedBy
        language
        isValid
        isManual
        isEdit
        isAnonymous
        isMonthlyCharge
        isStripe
        provider
        isInfoAnonymous
        projectClosedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getUpdate = /* GraphQL */ `
  query GetUpdate($id: ID!, $createdAt: AWSDateTime!) {
    getUpdate(id: $id, createdAt: $createdAt) {
      id
      type
      projectId
      data_en
      data_ru
      data_hy
      hasImage
      language
      createdAt
      updatedAt
      __typename
    }
  }
`
export const listUpdates = /* GraphQL */ `
  query ListUpdates(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelUpdateFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUpdates(
      id: $id
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        type
        projectId
        data_en
        data_ru
        data_hy
        hasImage
        language
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const listUpdatesByDate = /* GraphQL */ `
  query ListUpdatesByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUpdateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUpdatesByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        projectId
        data_en
        data_ru
        data_hy
        hasImage
        language
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const updatesByProjectId = /* GraphQL */ `
  query UpdatesByProjectId(
    $projectId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUpdateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    updatesByProjectId(
      projectId: $projectId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        projectId
        data_en
        data_ru
        data_hy
        hasImage
        language
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const updatesByHasImage = /* GraphQL */ `
  query UpdatesByHasImage(
    $hasImage: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUpdateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    updatesByHasImage(
      hasImage: $hasImage
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        projectId
        data_en
        data_ru
        data_hy
        hasImage
        language
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getFeed = /* GraphQL */ `
  query GetFeed($id: ID!, $createdAt: AWSDateTime!) {
    getFeed(id: $id, createdAt: $createdAt) {
      id
      type
      projectId
      data_en
      data_ru
      data_hy
      hasImage
      language
      userId
      creatorName_en
      creatorName_ru
      creatorName_hy
      creatorImage
      createdAt
      updatedAt
      __typename
    }
  }
`
export const listFeeds = /* GraphQL */ `
  query ListFeeds(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelFeedFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFeeds(
      id: $id
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        type
        projectId
        data_en
        data_ru
        data_hy
        hasImage
        language
        userId
        creatorName_en
        creatorName_ru
        creatorName_hy
        creatorImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const listFeedByDate = /* GraphQL */ `
  query ListFeedByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        projectId
        data_en
        data_ru
        data_hy
        hasImage
        language
        userId
        creatorName_en
        creatorName_ru
        creatorName_hy
        creatorImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const feedByProjectId = /* GraphQL */ `
  query FeedByProjectId(
    $projectId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedByProjectId(
      projectId: $projectId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        projectId
        data_en
        data_ru
        data_hy
        hasImage
        language
        userId
        creatorName_en
        creatorName_ru
        creatorName_hy
        creatorImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const feedByHasImage = /* GraphQL */ `
  query FeedByHasImage(
    $hasImage: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedByHasImage(
      hasImage: $hasImage
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        projectId
        data_en
        data_ru
        data_hy
        hasImage
        language
        userId
        creatorName_en
        creatorName_ru
        creatorName_hy
        creatorImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const feedByUserId = /* GraphQL */ `
  query FeedByUserId(
    $userId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        projectId
        data_en
        data_ru
        data_hy
        hasImage
        language
        userId
        creatorName_en
        creatorName_ru
        creatorName_hy
        creatorImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getReport = /* GraphQL */ `
  query GetReport($id: ID!, $createdAt: AWSDateTime!) {
    getReport(id: $id, createdAt: $createdAt) {
      id
      type
      projectId
      data_en
      data_ru
      data_hy
      hasImage
      language
      createdAt
      updatedAt
      __typename
    }
  }
`
export const listReports = /* GraphQL */ `
  query ListReports(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReports(
      id: $id
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        type
        projectId
        data_en
        data_ru
        data_hy
        hasImage
        language
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const listReportsByDate = /* GraphQL */ `
  query ListReportsByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportsByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        projectId
        data_en
        data_ru
        data_hy
        hasImage
        language
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const reportsByProjectId = /* GraphQL */ `
  query ReportsByProjectId(
    $projectId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportsByProjectId(
      projectId: $projectId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        projectId
        data_en
        data_ru
        data_hy
        hasImage
        language
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const reportsByHasImage = /* GraphQL */ `
  query ReportsByHasImage(
    $hasImage: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportsByHasImage(
      hasImage: $hasImage
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        projectId
        data_en
        data_ru
        data_hy
        hasImage
        language
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      peerProjectId
      occasion
      slug
      budgetFiles
      applicationTitle
      oldCollaborationId
      sequence
      title_en
      title_ru
      title_hy
      tagline_en
      tagline_ru
      tagline_hy
      campaignImplementorPeriod_en
      campaignImplementorPeriod_ru
      campaignImplementorPeriod_hy
      owner
      ownerName
      ownerLastName
      ownerEmail
      region
      summary
      isSubmittedByLegalEntity
      organizationName
      organizationLegalForm
      problem
      solution
      duration
      durationType
      fundraisingType
      isInKind
      isFeatured
      isUrgent
      isHidden
      isPopular
      isTestProject
      isIndividual
      isLive
      isProject
      isCollaboration
      isOneTimeDisabled
      isCompany
      noCTA
      popular
      featured
      video_en
      video_ru
      video_hy
      creatorName_en
      creatorName_ru
      creatorName_hy
      creatorSocialLink
      amount
      goal
      image
      files
      total {
        oneTime
        monthlyOneTime
        subscriptions
        subscriptionsTransactions
        giftCard
        total
        goalMetPercentage
        __typename
      }
      totalAmount
      budgetBreakdown
      contactFullName
      contactEmail
      phoneNumber
      similarProjects
      partnership
      projectTeam
      otherDetails
      links
      mediaLink
      creatorImage
      cover
      metaImage
      metaImage_ru
      metaImage_hy
      metaImage_en
      TIN
      category
      status
      language
      perks {
        id
        title
        title_en
        title_hy
        title_ru
        description_en
        description_hy
        description_ru
        price
        image
        __typename
      }
      updates {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      updates_en
      updates_ru
      updates_hy
      reports {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      reports_en
      reports_ru
      reports_hy
      content_en
      content_ru
      content_hy
      support
      collaborations {
        image
        needs
        needs_en
        needs_hy
        needs_ru
        needsDescription
        needsDescription_en
        needsDescription_hy
        needsDescription_ru
        __typename
      }
      goals {
        amount
        description_en
        description_ru
        description_hy
        __typename
      }
      implementor {
        id
        email
        fullName
        __typename
      }
      collaborators {
        id
        email
        fullName
        __typename
      }
      collaboratorsTotal
      initiatorImage
      initiatorName_en
      initiatorName_ru
      initiatorName_hy
      phases
      beneficiaries
      risks
      objective
      keyResults
      topDonors {
        email
        fullName
        totalAmount
        __typename
      }
      fbPixel
      stripeId
      isStripeOnBoardingDone
      isIdramOnBoardingDone
      otherPlayers
      startDate
      endDate
      statusUpdatedAt
      publishedAt
      createdAt
      updatedAt
      __typename
    }
  }
`
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        peerProjectId
        occasion
        slug
        budgetFiles
        applicationTitle
        oldCollaborationId
        sequence
        title_en
        title_ru
        title_hy
        tagline_en
        tagline_ru
        tagline_hy
        campaignImplementorPeriod_en
        campaignImplementorPeriod_ru
        campaignImplementorPeriod_hy
        owner
        ownerName
        ownerLastName
        ownerEmail
        region
        summary
        isSubmittedByLegalEntity
        organizationName
        organizationLegalForm
        problem
        solution
        duration
        durationType
        fundraisingType
        isInKind
        isFeatured
        isUrgent
        isHidden
        isPopular
        isTestProject
        isIndividual
        isLive
        isProject
        isCollaboration
        isOneTimeDisabled
        isCompany
        noCTA
        popular
        featured
        video_en
        video_ru
        video_hy
        creatorName_en
        creatorName_ru
        creatorName_hy
        creatorSocialLink
        amount
        goal
        image
        files
        total {
          oneTime
          monthlyOneTime
          subscriptions
          subscriptionsTransactions
          giftCard
          total
          goalMetPercentage
          __typename
        }
        totalAmount
        budgetBreakdown
        contactFullName
        contactEmail
        phoneNumber
        similarProjects
        partnership
        projectTeam
        otherDetails
        links
        mediaLink
        creatorImage
        cover
        metaImage
        metaImage_ru
        metaImage_hy
        metaImage_en
        TIN
        category
        status
        language
        perks {
          id
          title
          title_en
          title_hy
          title_ru
          description_en
          description_hy
          description_ru
          price
          image
          __typename
        }
        updates {
          id
          data_en
          data_ru
          data_hy
          createdAt
          updatedAt
          __typename
        }
        updates_en
        updates_ru
        updates_hy
        reports {
          id
          data_en
          data_ru
          data_hy
          createdAt
          updatedAt
          __typename
        }
        reports_en
        reports_ru
        reports_hy
        content_en
        content_ru
        content_hy
        support
        collaborations {
          image
          needs
          needs_en
          needs_hy
          needs_ru
          needsDescription
          needsDescription_en
          needsDescription_hy
          needsDescription_ru
          __typename
        }
        goals {
          amount
          description_en
          description_ru
          description_hy
          __typename
        }
        implementor {
          id
          email
          fullName
          __typename
        }
        collaborators {
          id
          email
          fullName
          __typename
        }
        collaboratorsTotal
        initiatorImage
        initiatorName_en
        initiatorName_ru
        initiatorName_hy
        phases
        beneficiaries
        risks
        objective
        keyResults
        topDonors {
          email
          fullName
          totalAmount
          __typename
        }
        fbPixel
        stripeId
        isStripeOnBoardingDone
        isIdramOnBoardingDone
        otherPlayers
        startDate
        endDate
        statusUpdatedAt
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const byPeerProjectId = /* GraphQL */ `
  query ByPeerProjectId(
    $peerProjectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byPeerProjectId(
      peerProjectId: $peerProjectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        peerProjectId
        occasion
        slug
        budgetFiles
        applicationTitle
        oldCollaborationId
        sequence
        title_en
        title_ru
        title_hy
        tagline_en
        tagline_ru
        tagline_hy
        campaignImplementorPeriod_en
        campaignImplementorPeriod_ru
        campaignImplementorPeriod_hy
        owner
        ownerName
        ownerLastName
        ownerEmail
        region
        summary
        isSubmittedByLegalEntity
        organizationName
        organizationLegalForm
        problem
        solution
        duration
        durationType
        fundraisingType
        isInKind
        isFeatured
        isUrgent
        isHidden
        isPopular
        isTestProject
        isIndividual
        isLive
        isProject
        isCollaboration
        isOneTimeDisabled
        isCompany
        noCTA
        popular
        featured
        video_en
        video_ru
        video_hy
        creatorName_en
        creatorName_ru
        creatorName_hy
        creatorSocialLink
        amount
        goal
        image
        files
        total {
          oneTime
          monthlyOneTime
          subscriptions
          subscriptionsTransactions
          giftCard
          total
          goalMetPercentage
          __typename
        }
        totalAmount
        budgetBreakdown
        contactFullName
        contactEmail
        phoneNumber
        similarProjects
        partnership
        projectTeam
        otherDetails
        links
        mediaLink
        creatorImage
        cover
        metaImage
        metaImage_ru
        metaImage_hy
        metaImage_en
        TIN
        category
        status
        language
        perks {
          id
          title
          title_en
          title_hy
          title_ru
          description_en
          description_hy
          description_ru
          price
          image
          __typename
        }
        updates {
          id
          data_en
          data_ru
          data_hy
          createdAt
          updatedAt
          __typename
        }
        updates_en
        updates_ru
        updates_hy
        reports {
          id
          data_en
          data_ru
          data_hy
          createdAt
          updatedAt
          __typename
        }
        reports_en
        reports_ru
        reports_hy
        content_en
        content_ru
        content_hy
        support
        collaborations {
          image
          needs
          needs_en
          needs_hy
          needs_ru
          needsDescription
          needsDescription_en
          needsDescription_hy
          needsDescription_ru
          __typename
        }
        goals {
          amount
          description_en
          description_ru
          description_hy
          __typename
        }
        implementor {
          id
          email
          fullName
          __typename
        }
        collaborators {
          id
          email
          fullName
          __typename
        }
        collaboratorsTotal
        initiatorImage
        initiatorName_en
        initiatorName_ru
        initiatorName_hy
        phases
        beneficiaries
        risks
        objective
        keyResults
        topDonors {
          email
          fullName
          totalAmount
          __typename
        }
        fbPixel
        stripeId
        isStripeOnBoardingDone
        isIdramOnBoardingDone
        otherPlayers
        startDate
        endDate
        statusUpdatedAt
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const projectBySlug = /* GraphQL */ `
  query ProjectBySlug(
    $slug: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        peerProjectId
        occasion
        slug
        budgetFiles
        applicationTitle
        oldCollaborationId
        sequence
        title_en
        title_ru
        title_hy
        tagline_en
        tagline_ru
        tagline_hy
        campaignImplementorPeriod_en
        campaignImplementorPeriod_ru
        campaignImplementorPeriod_hy
        owner
        ownerName
        ownerLastName
        ownerEmail
        region
        summary
        isSubmittedByLegalEntity
        organizationName
        organizationLegalForm
        problem
        solution
        duration
        durationType
        fundraisingType
        isInKind
        isFeatured
        isUrgent
        isHidden
        isPopular
        isTestProject
        isIndividual
        isLive
        isProject
        isCollaboration
        isOneTimeDisabled
        isCompany
        noCTA
        popular
        featured
        video_en
        video_ru
        video_hy
        creatorName_en
        creatorName_ru
        creatorName_hy
        creatorSocialLink
        amount
        goal
        image
        files
        total {
          oneTime
          monthlyOneTime
          subscriptions
          subscriptionsTransactions
          giftCard
          total
          goalMetPercentage
          __typename
        }
        totalAmount
        budgetBreakdown
        contactFullName
        contactEmail
        phoneNumber
        similarProjects
        partnership
        projectTeam
        otherDetails
        links
        mediaLink
        creatorImage
        cover
        metaImage
        metaImage_ru
        metaImage_hy
        metaImage_en
        TIN
        category
        status
        language
        perks {
          id
          title
          title_en
          title_hy
          title_ru
          description_en
          description_hy
          description_ru
          price
          image
          __typename
        }
        updates {
          id
          data_en
          data_ru
          data_hy
          createdAt
          updatedAt
          __typename
        }
        updates_en
        updates_ru
        updates_hy
        reports {
          id
          data_en
          data_ru
          data_hy
          createdAt
          updatedAt
          __typename
        }
        reports_en
        reports_ru
        reports_hy
        content_en
        content_ru
        content_hy
        support
        collaborations {
          image
          needs
          needs_en
          needs_hy
          needs_ru
          needsDescription
          needsDescription_en
          needsDescription_hy
          needsDescription_ru
          __typename
        }
        goals {
          amount
          description_en
          description_ru
          description_hy
          __typename
        }
        implementor {
          id
          email
          fullName
          __typename
        }
        collaborators {
          id
          email
          fullName
          __typename
        }
        collaboratorsTotal
        initiatorImage
        initiatorName_en
        initiatorName_ru
        initiatorName_hy
        phases
        beneficiaries
        risks
        objective
        keyResults
        topDonors {
          email
          fullName
          totalAmount
          __typename
        }
        fbPixel
        stripeId
        isStripeOnBoardingDone
        isIdramOnBoardingDone
        otherPlayers
        startDate
        endDate
        statusUpdatedAt
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const projectByUserId = /* GraphQL */ `
  query ProjectByUserId(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectByUserId(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        peerProjectId
        occasion
        slug
        budgetFiles
        applicationTitle
        oldCollaborationId
        sequence
        title_en
        title_ru
        title_hy
        tagline_en
        tagline_ru
        tagline_hy
        campaignImplementorPeriod_en
        campaignImplementorPeriod_ru
        campaignImplementorPeriod_hy
        owner
        ownerName
        ownerLastName
        ownerEmail
        region
        summary
        isSubmittedByLegalEntity
        organizationName
        organizationLegalForm
        problem
        solution
        duration
        durationType
        fundraisingType
        isInKind
        isFeatured
        isUrgent
        isHidden
        isPopular
        isTestProject
        isIndividual
        isLive
        isProject
        isCollaboration
        isOneTimeDisabled
        isCompany
        noCTA
        popular
        featured
        video_en
        video_ru
        video_hy
        creatorName_en
        creatorName_ru
        creatorName_hy
        creatorSocialLink
        amount
        goal
        image
        files
        total {
          oneTime
          monthlyOneTime
          subscriptions
          subscriptionsTransactions
          giftCard
          total
          goalMetPercentage
          __typename
        }
        totalAmount
        budgetBreakdown
        contactFullName
        contactEmail
        phoneNumber
        similarProjects
        partnership
        projectTeam
        otherDetails
        links
        mediaLink
        creatorImage
        cover
        metaImage
        metaImage_ru
        metaImage_hy
        metaImage_en
        TIN
        category
        status
        language
        perks {
          id
          title
          title_en
          title_hy
          title_ru
          description_en
          description_hy
          description_ru
          price
          image
          __typename
        }
        updates {
          id
          data_en
          data_ru
          data_hy
          createdAt
          updatedAt
          __typename
        }
        updates_en
        updates_ru
        updates_hy
        reports {
          id
          data_en
          data_ru
          data_hy
          createdAt
          updatedAt
          __typename
        }
        reports_en
        reports_ru
        reports_hy
        content_en
        content_ru
        content_hy
        support
        collaborations {
          image
          needs
          needs_en
          needs_hy
          needs_ru
          needsDescription
          needsDescription_en
          needsDescription_hy
          needsDescription_ru
          __typename
        }
        goals {
          amount
          description_en
          description_ru
          description_hy
          __typename
        }
        implementor {
          id
          email
          fullName
          __typename
        }
        collaborators {
          id
          email
          fullName
          __typename
        }
        collaboratorsTotal
        initiatorImage
        initiatorName_en
        initiatorName_ru
        initiatorName_hy
        phases
        beneficiaries
        risks
        objective
        keyResults
        topDonors {
          email
          fullName
          totalAmount
          __typename
        }
        fbPixel
        stripeId
        isStripeOnBoardingDone
        isIdramOnBoardingDone
        otherPlayers
        startDate
        endDate
        statusUpdatedAt
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const projectBypopular = /* GraphQL */ `
  query ProjectBypopular(
    $popular: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectBypopular(
      popular: $popular
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        peerProjectId
        occasion
        slug
        budgetFiles
        applicationTitle
        oldCollaborationId
        sequence
        title_en
        title_ru
        title_hy
        tagline_en
        tagline_ru
        tagline_hy
        campaignImplementorPeriod_en
        campaignImplementorPeriod_ru
        campaignImplementorPeriod_hy
        owner
        ownerName
        ownerLastName
        ownerEmail
        region
        summary
        isSubmittedByLegalEntity
        organizationName
        organizationLegalForm
        problem
        solution
        duration
        durationType
        fundraisingType
        isInKind
        isFeatured
        isUrgent
        isHidden
        isPopular
        isTestProject
        isIndividual
        isLive
        isProject
        isCollaboration
        isOneTimeDisabled
        isCompany
        noCTA
        popular
        featured
        video_en
        video_ru
        video_hy
        creatorName_en
        creatorName_ru
        creatorName_hy
        creatorSocialLink
        amount
        goal
        image
        files
        total {
          oneTime
          monthlyOneTime
          subscriptions
          subscriptionsTransactions
          giftCard
          total
          goalMetPercentage
          __typename
        }
        totalAmount
        budgetBreakdown
        contactFullName
        contactEmail
        phoneNumber
        similarProjects
        partnership
        projectTeam
        otherDetails
        links
        mediaLink
        creatorImage
        cover
        metaImage
        metaImage_ru
        metaImage_hy
        metaImage_en
        TIN
        category
        status
        language
        perks {
          id
          title
          title_en
          title_hy
          title_ru
          description_en
          description_hy
          description_ru
          price
          image
          __typename
        }
        updates {
          id
          data_en
          data_ru
          data_hy
          createdAt
          updatedAt
          __typename
        }
        updates_en
        updates_ru
        updates_hy
        reports {
          id
          data_en
          data_ru
          data_hy
          createdAt
          updatedAt
          __typename
        }
        reports_en
        reports_ru
        reports_hy
        content_en
        content_ru
        content_hy
        support
        collaborations {
          image
          needs
          needs_en
          needs_hy
          needs_ru
          needsDescription
          needsDescription_en
          needsDescription_hy
          needsDescription_ru
          __typename
        }
        goals {
          amount
          description_en
          description_ru
          description_hy
          __typename
        }
        implementor {
          id
          email
          fullName
          __typename
        }
        collaborators {
          id
          email
          fullName
          __typename
        }
        collaboratorsTotal
        initiatorImage
        initiatorName_en
        initiatorName_ru
        initiatorName_hy
        phases
        beneficiaries
        risks
        objective
        keyResults
        topDonors {
          email
          fullName
          totalAmount
          __typename
        }
        fbPixel
        stripeId
        isStripeOnBoardingDone
        isIdramOnBoardingDone
        otherPlayers
        startDate
        endDate
        statusUpdatedAt
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const projectByFeatured = /* GraphQL */ `
  query ProjectByFeatured(
    $featured: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectByFeatured(
      featured: $featured
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        peerProjectId
        occasion
        slug
        budgetFiles
        applicationTitle
        oldCollaborationId
        sequence
        title_en
        title_ru
        title_hy
        tagline_en
        tagline_ru
        tagline_hy
        campaignImplementorPeriod_en
        campaignImplementorPeriod_ru
        campaignImplementorPeriod_hy
        owner
        ownerName
        ownerLastName
        ownerEmail
        region
        summary
        isSubmittedByLegalEntity
        organizationName
        organizationLegalForm
        problem
        solution
        duration
        durationType
        fundraisingType
        isInKind
        isFeatured
        isUrgent
        isHidden
        isPopular
        isTestProject
        isIndividual
        isLive
        isProject
        isCollaboration
        isOneTimeDisabled
        isCompany
        noCTA
        popular
        featured
        video_en
        video_ru
        video_hy
        creatorName_en
        creatorName_ru
        creatorName_hy
        creatorSocialLink
        amount
        goal
        image
        files
        total {
          oneTime
          monthlyOneTime
          subscriptions
          subscriptionsTransactions
          giftCard
          total
          goalMetPercentage
          __typename
        }
        totalAmount
        budgetBreakdown
        contactFullName
        contactEmail
        phoneNumber
        similarProjects
        partnership
        projectTeam
        otherDetails
        links
        mediaLink
        creatorImage
        cover
        metaImage
        metaImage_ru
        metaImage_hy
        metaImage_en
        TIN
        category
        status
        language
        perks {
          id
          title
          title_en
          title_hy
          title_ru
          description_en
          description_hy
          description_ru
          price
          image
          __typename
        }
        updates {
          id
          data_en
          data_ru
          data_hy
          createdAt
          updatedAt
          __typename
        }
        updates_en
        updates_ru
        updates_hy
        reports {
          id
          data_en
          data_ru
          data_hy
          createdAt
          updatedAt
          __typename
        }
        reports_en
        reports_ru
        reports_hy
        content_en
        content_ru
        content_hy
        support
        collaborations {
          image
          needs
          needs_en
          needs_hy
          needs_ru
          needsDescription
          needsDescription_en
          needsDescription_hy
          needsDescription_ru
          __typename
        }
        goals {
          amount
          description_en
          description_ru
          description_hy
          __typename
        }
        implementor {
          id
          email
          fullName
          __typename
        }
        collaborators {
          id
          email
          fullName
          __typename
        }
        collaboratorsTotal
        initiatorImage
        initiatorName_en
        initiatorName_ru
        initiatorName_hy
        phases
        beneficiaries
        risks
        objective
        keyResults
        topDonors {
          email
          fullName
          totalAmount
          __typename
        }
        fbPixel
        stripeId
        isStripeOnBoardingDone
        isIdramOnBoardingDone
        otherPlayers
        startDate
        endDate
        statusUpdatedAt
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getProjectSystem = /* GraphQL */ `
  query GetProjectSystem($id: ID!) {
    getProjectSystem(id: $id) {
      id
      projectId
      userId
      mailerLiteApiKey
      iDramApiKey
      iDramAccountId
      createdAt
      updatedAt
      __typename
    }
  }
`
export const listProjectSystems = /* GraphQL */ `
  query ListProjectSystems(
    $filter: ModelProjectSystemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectSystems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        projectId
        userId
        mailerLiteApiKey
        iDramApiKey
        iDramAccountId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const projectSystemByProjectId = /* GraphQL */ `
  query ProjectSystemByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectSystemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectSystemByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        userId
        mailerLiteApiKey
        iDramApiKey
        iDramAccountId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getDraft = /* GraphQL */ `
  query GetDraft($id: ID!) {
    getDraft(id: $id) {
      id
      budgetFiles
      applicationTitle
      owner
      ownerName
      ownerLastName
      region
      summary
      isSubmittedByLegalEntity
      creatorSocialLink
      organizationName
      organizationLegalForm
      problem
      solution
      phases
      beneficiaries
      risks
      objective
      keyResults
      isOneTimeDisabled
      otherPlayers
      phoneNumber
      occasion
      creatorName_hy
      creatorName_ru
      creatorName_en
      peerProjectId
      files
      fundraisingType
      isInKind
      category
      projectTeam
      requestedAmount
      contactFullName
      email
      phone
      links
      mediaLink
      TIN
      terms_and_conditions
      privacy_policy
      collaborations {
        image
        needs
        needs_en
        needs_hy
        needs_ru
        needsDescription
        needsDescription_en
        needsDescription_hy
        needsDescription_ru
        __typename
      }
      goals {
        amount
        description_en
        description_ru
        description_hy
        __typename
      }
      isProject
      isCollaboration
      startDate
      endDate
      createdAt
      updatedAt
      __typename
    }
  }
`
export const listDrafts = /* GraphQL */ `
  query ListDrafts(
    $filter: ModelDraftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDrafts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        budgetFiles
        applicationTitle
        owner
        ownerName
        ownerLastName
        region
        summary
        isSubmittedByLegalEntity
        creatorSocialLink
        organizationName
        organizationLegalForm
        problem
        solution
        phases
        beneficiaries
        risks
        objective
        keyResults
        isOneTimeDisabled
        otherPlayers
        phoneNumber
        occasion
        creatorName_hy
        creatorName_ru
        creatorName_en
        peerProjectId
        files
        fundraisingType
        isInKind
        category
        projectTeam
        requestedAmount
        contactFullName
        email
        phone
        links
        mediaLink
        TIN
        terms_and_conditions
        privacy_policy
        collaborations {
          image
          needs
          needs_en
          needs_hy
          needs_ru
          needsDescription
          needsDescription_en
          needsDescription_hy
          needsDescription_ru
          __typename
        }
        goals {
          amount
          description_en
          description_ru
          description_hy
          __typename
        }
        isProject
        isCollaboration
        startDate
        endDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const draftsByUserId = /* GraphQL */ `
  query DraftsByUserId(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDraftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    draftsByUserId(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        budgetFiles
        applicationTitle
        owner
        ownerName
        ownerLastName
        region
        summary
        isSubmittedByLegalEntity
        creatorSocialLink
        organizationName
        organizationLegalForm
        problem
        solution
        phases
        beneficiaries
        risks
        objective
        keyResults
        isOneTimeDisabled
        otherPlayers
        phoneNumber
        occasion
        creatorName_hy
        creatorName_ru
        creatorName_en
        peerProjectId
        files
        fundraisingType
        isInKind
        category
        projectTeam
        requestedAmount
        contactFullName
        email
        phone
        links
        mediaLink
        TIN
        terms_and_conditions
        privacy_policy
        collaborations {
          image
          needs
          needs_en
          needs_hy
          needs_ru
          needsDescription
          needsDescription_en
          needsDescription_hy
          needsDescription_ru
          __typename
        }
        goals {
          amount
          description_en
          description_ru
          description_hy
          __typename
        }
        isProject
        isCollaboration
        startDate
        endDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      domain
      amount
      period
      from
      to
      projectIds
      createdAt
      updatedAt
      __typename
    }
  }
`
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        domain
        amount
        period
        from
        to
        projectIds
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const companyByDomain = /* GraphQL */ `
  query CompanyByDomain(
    $domain: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companyByDomain(
      domain: $domain
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        domain
        amount
        period
        from
        to
        projectIds
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getInitiative = /* GraphQL */ `
  query GetInitiative($id: ID!) {
    getInitiative(id: $id) {
      id
      slug
      owner
      ownerName
      ownerLastName
      contactEmail
      implementor {
        id
        email
        fullName
        __typename
      }
      region
      other
      applicationTitle
      about
      support
      partnership
      fullName
      phoneNumber
      image
      organizationName
      organizationLegalForm
      description
      TIN
      links
      summary
      files
      isFeatured
      isPopular
      isUrgent
      category
      collaborators {
        id
        email
        fullName
        __typename
      }
      status
      cover
      requiredSupport
      title_en
      title_ru
      title_hy
      tagline_en
      tagline_ru
      tagline_hy
      embedVideo_en
      embedVideo_ru
      embedVideo_hy
      content_en
      content_ru
      content_hy
      initiatorImage
      initiatorName_en
      initiatorName_ru
      initiatorName_hy
      updates {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      updates_en
      updates_ru
      updates_hy
      reports {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      reports_en
      reports_ru
      reports_hy
      collaboratorsTotal
      createdAt
      updatedAt
      __typename
    }
  }
`
export const listInitiatives = /* GraphQL */ `
  query ListInitiatives(
    $filter: ModelInitiativeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInitiatives(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        slug
        owner
        ownerName
        ownerLastName
        contactEmail
        implementor {
          id
          email
          fullName
          __typename
        }
        region
        other
        applicationTitle
        about
        support
        partnership
        fullName
        phoneNumber
        image
        organizationName
        organizationLegalForm
        description
        TIN
        links
        summary
        files
        isFeatured
        isPopular
        isUrgent
        category
        collaborators {
          id
          email
          fullName
          __typename
        }
        status
        cover
        requiredSupport
        title_en
        title_ru
        title_hy
        tagline_en
        tagline_ru
        tagline_hy
        embedVideo_en
        embedVideo_ru
        embedVideo_hy
        content_en
        content_ru
        content_hy
        initiatorImage
        initiatorName_en
        initiatorName_ru
        initiatorName_hy
        updates {
          id
          data_en
          data_ru
          data_hy
          createdAt
          updatedAt
          __typename
        }
        updates_en
        updates_ru
        updates_hy
        reports {
          id
          data_en
          data_ru
          data_hy
          createdAt
          updatedAt
          __typename
        }
        reports_en
        reports_ru
        reports_hy
        collaboratorsTotal
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const initiativeBySlug = /* GraphQL */ `
  query InitiativeBySlug(
    $slug: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInitiativeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    initiativeBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        owner
        ownerName
        ownerLastName
        contactEmail
        implementor {
          id
          email
          fullName
          __typename
        }
        region
        other
        applicationTitle
        about
        support
        partnership
        fullName
        phoneNumber
        image
        organizationName
        organizationLegalForm
        description
        TIN
        links
        summary
        files
        isFeatured
        isPopular
        isUrgent
        category
        collaborators {
          id
          email
          fullName
          __typename
        }
        status
        cover
        requiredSupport
        title_en
        title_ru
        title_hy
        tagline_en
        tagline_ru
        tagline_hy
        embedVideo_en
        embedVideo_ru
        embedVideo_hy
        content_en
        content_ru
        content_hy
        initiatorImage
        initiatorName_en
        initiatorName_ru
        initiatorName_hy
        updates {
          id
          data_en
          data_ru
          data_hy
          createdAt
          updatedAt
          __typename
        }
        updates_en
        updates_ru
        updates_hy
        reports {
          id
          data_en
          data_ru
          data_hy
          createdAt
          updatedAt
          __typename
        }
        reports_en
        reports_ru
        reports_hy
        collaboratorsTotal
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getContent = /* GraphQL */ `
  query GetContent($id: ID!) {
    getContent(id: $id) {
      id
      pk
      sk
      sequence
      jsonPageData
      title_en
      title_ru
      title_hy
      type
      image
      cv
      keywords
      firstName_en
      firstName_hy
      firstName_ru
      lastName_en
      lastName_hy
      lastName_ru
      isPrivate
      isVolunteer
      description_en
      description_hy
      description_ru
      spheres {
        title_en
        title_hy
        title_ru
        __typename
      }
      socialLinks
      interestedIn {
        interested_in
        __typename
      }
      getInvolvedStatus
      hrsPerWeek
      selectedLanguage {
        language
        language_other
        __typename
      }
      isEngFilled
      isArmFilled
      isRusFilled
      additionalInfo
      expert_status
      email
      slug
      phone
      imageUrl
      linkedinLink
      link
      location_en
      location_ru
      location_hy
      language
      countryCode
      userId
      publishedAt
      createdAt
      updatedAt
      __typename
    }
  }
`
export const listContents = /* GraphQL */ `
  query ListContents(
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        pk
        sk
        sequence
        jsonPageData
        title_en
        title_ru
        title_hy
        type
        image
        cv
        keywords
        firstName_en
        firstName_hy
        firstName_ru
        lastName_en
        lastName_hy
        lastName_ru
        isPrivate
        isVolunteer
        description_en
        description_hy
        description_ru
        spheres {
          title_en
          title_hy
          title_ru
          __typename
        }
        socialLinks
        interestedIn {
          interested_in
          __typename
        }
        getInvolvedStatus
        hrsPerWeek
        selectedLanguage {
          language
          language_other
          __typename
        }
        isEngFilled
        isArmFilled
        isRusFilled
        additionalInfo
        expert_status
        email
        slug
        phone
        imageUrl
        linkedinLink
        link
        location_en
        location_ru
        location_hy
        language
        countryCode
        userId
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const contentBySk = /* GraphQL */ `
  query ContentBySk(
    $pk: String!
    $sortDirection: ModelSortDirection
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contentBySk(
      pk: $pk
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pk
        sk
        sequence
        jsonPageData
        title_en
        title_ru
        title_hy
        type
        image
        cv
        keywords
        firstName_en
        firstName_hy
        firstName_ru
        lastName_en
        lastName_hy
        lastName_ru
        isPrivate
        isVolunteer
        description_en
        description_hy
        description_ru
        spheres {
          title_en
          title_hy
          title_ru
          __typename
        }
        socialLinks
        interestedIn {
          interested_in
          __typename
        }
        getInvolvedStatus
        hrsPerWeek
        selectedLanguage {
          language
          language_other
          __typename
        }
        isEngFilled
        isArmFilled
        isRusFilled
        additionalInfo
        expert_status
        email
        slug
        phone
        imageUrl
        linkedinLink
        link
        location_en
        location_ru
        location_hy
        language
        countryCode
        userId
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const expertByEmail = /* GraphQL */ `
  query ExpertByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    expertByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pk
        sk
        sequence
        jsonPageData
        title_en
        title_ru
        title_hy
        type
        image
        cv
        keywords
        firstName_en
        firstName_hy
        firstName_ru
        lastName_en
        lastName_hy
        lastName_ru
        isPrivate
        isVolunteer
        description_en
        description_hy
        description_ru
        spheres {
          title_en
          title_hy
          title_ru
          __typename
        }
        socialLinks
        interestedIn {
          interested_in
          __typename
        }
        getInvolvedStatus
        hrsPerWeek
        selectedLanguage {
          language
          language_other
          __typename
        }
        isEngFilled
        isArmFilled
        isRusFilled
        additionalInfo
        expert_status
        email
        slug
        phone
        imageUrl
        linkedinLink
        link
        location_en
        location_ru
        location_hy
        language
        countryCode
        userId
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const expertBySlug = /* GraphQL */ `
  query ExpertBySlug(
    $slug: String!
    $sortDirection: ModelSortDirection
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    expertBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pk
        sk
        sequence
        jsonPageData
        title_en
        title_ru
        title_hy
        type
        image
        cv
        keywords
        firstName_en
        firstName_hy
        firstName_ru
        lastName_en
        lastName_hy
        lastName_ru
        isPrivate
        isVolunteer
        description_en
        description_hy
        description_ru
        spheres {
          title_en
          title_hy
          title_ru
          __typename
        }
        socialLinks
        interestedIn {
          interested_in
          __typename
        }
        getInvolvedStatus
        hrsPerWeek
        selectedLanguage {
          language
          language_other
          __typename
        }
        isEngFilled
        isArmFilled
        isRusFilled
        additionalInfo
        expert_status
        email
        slug
        phone
        imageUrl
        linkedinLink
        link
        location_en
        location_ru
        location_hy
        language
        countryCode
        userId
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getExpertInitiatorConnection = /* GraphQL */ `
  query GetExpertInitiatorConnection($id: ID!) {
    getExpertInitiatorConnection(id: $id) {
      id
      adminStatus
      expertStatus
      initiatorFirstName
      initiatorLastName
      initiatorEmail
      initiatorPhone
      initiatorLink
      initiatorMessage
      initiatorSupport
      projectTitle
      expertFirstName
      expertLastName
      expertEmail
      expertPhone
      expertLinkedinLink
      expertLink
      expertImageUrl
      expertTitle
      expertMessage
      expertType
      expertHours
      expertSpheres
      expertDirections
      expertOccupation
      expertLanguage
      connectionStatus
      files
      language
      createdAt
      updatedAt
      __typename
    }
  }
`
export const listExpertInitiatorConnections = /* GraphQL */ `
  query ListExpertInitiatorConnections(
    $filter: ModelExpertInitiatorConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExpertInitiatorConnections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        adminStatus
        expertStatus
        initiatorFirstName
        initiatorLastName
        initiatorEmail
        initiatorPhone
        initiatorLink
        initiatorMessage
        initiatorSupport
        projectTitle
        expertFirstName
        expertLastName
        expertEmail
        expertPhone
        expertLinkedinLink
        expertLink
        expertImageUrl
        expertTitle
        expertMessage
        expertType
        expertHours
        expertSpheres
        expertDirections
        expertOccupation
        expertLanguage
        connectionStatus
        files
        language
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const connectionByEmail = /* GraphQL */ `
  query ConnectionByEmail(
    $expertEmail: String!
    $sortDirection: ModelSortDirection
    $filter: ModelExpertInitiatorConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    connectionByEmail(
      expertEmail: $expertEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        adminStatus
        expertStatus
        initiatorFirstName
        initiatorLastName
        initiatorEmail
        initiatorPhone
        initiatorLink
        initiatorMessage
        initiatorSupport
        projectTitle
        expertFirstName
        expertLastName
        expertEmail
        expertPhone
        expertLinkedinLink
        expertLink
        expertImageUrl
        expertTitle
        expertMessage
        expertType
        expertHours
        expertSpheres
        expertDirections
        expertOccupation
        expertLanguage
        connectionStatus
        files
        language
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getSettings = /* GraphQL */ `
  query GetSettings($id: ID!) {
    getSettings(id: $id) {
      id
      currency
      version
      bigQueryLastSyncDate
      payPayAccessToken
      payPayAccessExpires
      createdAt
      updatedAt
      __typename
    }
  }
`
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $filter: ModelSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        currency
        version
        bigQueryLastSyncDate
        payPayAccessToken
        payPayAccessExpires
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getNeeds = /* GraphQL */ `
  query GetNeeds($id: ID!) {
    getNeeds(id: $id) {
      id
      title_en
      title_ru
      title_hy
      type
      image
      keywords
      createdAt
      updatedAt
      __typename
    }
  }
`
export const listNeeds = /* GraphQL */ `
  query ListNeeds(
    $filter: ModelNeedsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNeeds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title_en
        title_ru
        title_hy
        type
        image
        keywords
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
