const reportRepo = (reportService) => {
  const getReportsByProjectId = (variables) =>
    reportService.getReportsByProjectId(variables)

  const editReport = (input) => reportService.editReport(input)

  const deleteReport = (input) => reportService.deleteReport(input)

  const createReport = (input) => reportService.createReport(input)

  const listReportsByDate = () => reportService.listReportsByDate()
  return {
    getReportsByProjectId,
    createReport,
    deleteReport,
    editReport,
    listReportsByDate,
  }
}

export default reportRepo
