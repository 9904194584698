import { Button, Flex, Spinner, Text } from '@chakra-ui/react'
import { useTransactions } from 'contexts'
import { useTranslation } from 'contexts/TranslationContext'
import {
  CurrencyConverter,
  useCurrencySettings,
} from 'contexts/CurrencySettingsContext'
import ListView from 'components/ListView'
import Export from './Export'
import { FUNDRAISER_PEER_TO_PEER } from 'constants/fundraiser'

function FixedWidthText({ children, width = '100px' }) {
  return (
    <Text
      display="block"
      textAlign="center"
      maxW={width}
      overflow="hidden"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      title={children}
    >
      {children}
    </Text>
  )
}

export default function DonorsList({ project, isLoadingProject }) {
  const { data, isMoreLoading, setLoadMore, nextToken } = useTransactions()
  const { t, language } = useTranslation()
  const { currency } = useCurrencySettings()

  return (
    <Flex direction="column">
      <Flex justify="space-between" alignItems="center">
        {!isLoadingProject && (
          <Text color="black" fontSize="2xl" fontWeight={700}>
            {t('createEditProject@donors@totalDonations')}{' '}
            {CurrencyConverter({
              amount: project.total?.total || 0,
              currency: currency.current,
              withSymbol: true,
            })}
          </Text>
        )}
        <Export
          isPeerProject={project.fundraisingType === FUNDRAISER_PEER_TO_PEER}
        />
      </Flex>
      <ListView
        head={[
          t('dashboard@name'),
          t('dashboard@surname'),
          t('profile@settings-profile@email'),
          t('profile@dashboard@donor@oneTime@amount'),
          t('createEditProject@reward'),
          t('date'),
        ]}
        body={data.map(
          ({
            id,
            firstName,
            lastName,
            email,
            amount,
            donationToProject,
            currencyRate,
            currency,
            perk,
            createdAt,
            isInfoAnonymous,
          }) => [
            <FixedWidthText key={id}>{firstName}</FixedWidthText>,
            <FixedWidthText key={id}>{lastName}</FixedWidthText>,
            <FixedWidthText key={id} width="200px">
              {isInfoAnonymous ? '********' : email}
            </FixedWidthText>,
            <FixedWidthText key={id}>
              {CurrencyConverter({
                amount: donationToProject || amount,
                rate: currencyRate,
                currency,
                withSymbol: true,
              })}
            </FixedWidthText>,
            <FixedWidthText key={id} width="180px">
              {!perk ? '-' : perk?.[`title_${language}`]}
            </FixedWidthText>,
            <FixedWidthText key={id} width="180px">
              {new Date(createdAt).toLocaleString()}
            </FixedWidthText>,
          ]
        )}
        tdProps={{ maxWidth: 'auto !important' }}
        tableContainerProps={{ marginTop: 5 }}
      />
      {isMoreLoading && <Spinner color="blue.300" alignSelf="center" mt={5} />}
      {nextToken && !isMoreLoading && (
        <Button
          mt="4"
          minH="40px"
          w={{ base: 'full', sm: '417px' }}
          variant="white"
          isLoading={isMoreLoading}
          onClick={setLoadMore}
          alignSelf="center"
        >
          {t('seeMore')}
        </Button>
      )}
    </Flex>
  )
}
