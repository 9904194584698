import { feedByUserId } from '../../graphql/queries'
import { generateClient } from 'aws-amplify/api'
const client = generateClient()

const feedService = () => {
  const listFeedByUserId = async (variables) =>
    await client.graphql({ query: feedByUserId, variables })

  return {
    listFeedByUserId,
  }
}

export default feedService
