import {
  FormControl,
  FormLabel,
  Input,
  InputRightElement,
  Box,
  useColorModeValue as mode,
  InputGroup,
  Flex,
  ListItem,
  ListIcon,
  UnorderedList,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { ReactComponent as View } from 'assets/icons/view.svg'
import { ReactComponent as ViewClose } from 'assets/icons/viewClose.svg'
import { ReactComponent as Check } from 'assets/icons/checked.svg'
import { useTranslation } from 'contexts/TranslationContext'

export const PasswordField = React.forwardRef((props, ref) => {
  const {
    label,
    hidden,
    isInvalid,
    maxLength,
    char,
    newPassword = false,
    size = 'md',
    onUpdate,
    notValidate,
    labelProps = {},
    ...rest
  } = props
  const { t } = useTranslation()
  const [type, setType] = useState(false)
  const [validation, setValidation] = useState({
    chars: true,
    number: true,
    uppercase: true,
    lowercase: true,
  })
  return (
    <FormControl position="relative" display={hidden ? 'none' : 'block'}>
      {!!maxLength && (
        <Box
          position="absolute"
          fontWeight="semibold"
          fontSize="sm"
          color="gray.700"
          top="calc(100% + 5px)"
        >
          {char} / {maxLength}
        </Box>
      )}
      <FormLabel
        whiteSpace="nowrap"
        fontSize={size === 'md' ? 'sm' : 'md'}
        fontWeight="regular"
        color="black"
        bg={{
          base: mode('white', 'gray.800'),
          md: mode('white', 'gray.700'),
        }}
        zIndex={2}
        {...labelProps}
      >
        {label}
      </FormLabel>
      <InputGroup
        size="lg"
        _focus={{ borderColor: 'blue.300' }}
        _hover={{ borderColor: 'blue.300' }}
      >
        <Input
          type={type ? 'text' : 'password'}
          sx={{ boxShadow: 'none !important' }}
          maxLength={maxLength}
          isInvalid={isInvalid}
          hidden={hidden}
          borderColor="border.100"
          borderWidth="1px"
          ref={ref}
          size={size}
          fontSize="lg"
          {...rest}
          onChange={(e) => {
            if (notValidate) {
              onUpdate(e)
              return
            }
            setValidation({
              ...validation,
              ...{
                chars: e.target.value.length >= 8,
                number: /\d/.test(e.target.value),
                uppercase: /[A-Z]/.test(e.target.value),
                lowercase: /[a-z]/.test(e.target.value),
                charsLength: e.target.value.length,
              },
            })
          }}
        />
        <InputRightElement
          onClick={() => setType(!type)}
          children={type ? <ViewClose height="25px" /> : <View height="25px" />}
        />
      </InputGroup>

      {newPassword && (
        <UnorderedList
          color="gray.700"
          fontSize="xs"
          display="flex"
          mt={4}
          ml={0}
        >
          <Flex direction="column" mr="30px">
            <ListItem
              color={!validation.chars ? 'red.400' : 'inherit'}
              listStyleType={
                validation.chars && validation.charsLength ? 'none' : 'initial'
              }
              ml={validation.chars && validation.charsLength ? 0 : '18px'}
            >
              {validation.chars && validation.charsLength && (
                <ListIcon as={Check} color="green.500" />
              )}
              {t('Minimum 8 characters')}
            </ListItem>
            <ListItem
              color={!validation.uppercase ? 'red.400' : 'inherit'}
              listStyleType={
                validation.uppercase && validation.charsLength
                  ? 'none'
                  : 'initial'
              }
              ml={validation.uppercase && validation.charsLength ? 0 : '18px'}
            >
              {validation.uppercase && validation.charsLength && (
                <ListIcon as={Check} color="green.500" />
              )}
              {t('At least one uppercase letter (A-Z)')}
            </ListItem>
          </Flex>
          <Flex direction="column">
            <ListItem
              color={!validation.lowercase ? 'red.400' : 'inherit'}
              listStyleType={
                validation.lowercase && validation.charsLength
                  ? 'none'
                  : 'initial'
              }
              ml={validation.lowercase && validation.charsLength ? 0 : '18px'}
            >
              {validation.lowercase && validation.charsLength && (
                <ListIcon as={Check} color="green.500" />
              )}
              {t('At least one lowercase letter (a-z)')}
            </ListItem>
            <ListItem
              color={!validation.number ? 'red.400' : 'inherit'}
              listStyleType={
                validation.number && validation.charsLength ? 'none' : 'initial'
              }
              ml={validation.number && validation.charsLength ? 0 : '18px'}
            >
              {validation.number && validation.charsLength && (
                <ListIcon as={Check} color="green.500" />
              )}
              {t('At lease one number (0-9)')}
            </ListItem>
          </Flex>
        </UnorderedList>
      )}
    </FormControl>
  )
})

PasswordField.displayName = 'PasswordField'
