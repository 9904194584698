import React from 'react'
import { Text, Button } from '@chakra-ui/react'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'

const OrangeCountButton = ({
  text,
  onClick = () => {},
  x = true,
  ...props
}) => {
  return (
    <Button
      background="orange.400"
      color="white"
      borderRadius="50px"
      px={3}
      py={1}
      onClick={onClick}
      h="24px"
      {...props}
    >
      <Text fontSize="md" fontWeight={500} mr={x ? 2 : 0} textTransform="none">
        {text}
      </Text>
      {x && <CloseIcon width="10px" />}
    </Button>
  )
}

export default OrangeCountButton
