import React, { Suspense } from 'react'
import { Tabs, Tab, TabList, TabPanel, TabPanels } from '@chakra-ui/react'
import { useUser } from 'hooks'
import NoScrollbar from 'components/NoScrollbar'
import { useTranslation } from 'contexts/TranslationContext'
import { PROJECT_TYPE_NON_PROFIT } from 'constants/project'

const TabsLayout = ({ defaultIndex, onChange, TABS, ...props }) => {
  const { user } = useUser()
  const { t } = useTranslation()

  return (
    <Tabs
      defaultIndex={defaultIndex}
      onChange={onChange}
      h="100%"
      display="flex"
      flexDir="column"
      flex={1}
    >
      <TabList
        borderBottom={0}
        borderRadius={{ base: 8, sm: 0 }}
        overflowX={{ base: 'scroll', md: 'hidden' }}
        sx={{
          '&::-webkit-scrollbar': {
            width: '0px',
            height: '0px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'transparent',
          },
        }}
        overflowY="hidden"
        bg="transparent"
        p={{ base: 1.5, sm: 0 }}
      >
        {TABS.map(({ title }) => (
          <SingleTab key={title} title={t(title)} />
        ))}
      </TabList>
      <TabPanels flex={1} display="flex">
        {TABS.map(({ value, Panel }) => (
          <TabPanel
            key={value}
            flex={1}
            px={{ base: 0, sm: 1.5 }}
            py={{ base: 8, sm: 12 }}
            maxW="full"
          >
            <Suspense>
              <Panel
                user={user}
                isBasket={value === PROJECT_TYPE_NON_PROFIT}
                fundraisingType={value}
                {...props}
              />
            </Suspense>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}

export default TabsLayout

const SingleTab = ({ title }) => (
  <NoScrollbar
    Component={Tab}
    py={{ base: 2, sm: 3 }}
    px={{ base: 2, sm: 1 }}
    minW={{ base: '106px', sm: '140px' }}
    h={{ base: '32px', sm: '50px' }}
    id="a"
    borderColor="border.100"
    _active={{ backgroundColor: 'transparent' }}
    _focus={{
      outline: 'none',
    }}
    _selected={{
      color: 'blue.400 !important',
      fontWeight: 'bold !important',
      borderRadius: '0 !important',
      borderColor: 'blue.400 !important',
      backgroundColor: 'transparent !important',
    }}
    whiteSpace="pre"
    mb={{ base: -0.5, sm: 0 }}
    fontSize={{ base: 'md', sm: 'lg' }}
    flexShrink={0}
  >
    {title}
  </NoScrollbar>
)
