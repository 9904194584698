import React from 'react'
import { Container } from '@chakra-ui/react'
import ContactInfo from './ContactInfo'
import Fields from './Fields'
import { Footer } from 'components/Footer'
import { MainLayout } from 'components/Layouts'
import { useTranslation } from 'contexts/TranslationContext'
import MetaTags from 'components/MetaTags'
import Header from './Header'

const ContactUs = () => {
  const { t } = useTranslation()
  return (
    <>
      <MetaTags
        title={t('meta@contact@title')}
        description={t('meta@contact@description')}
      />
      <MainLayout>
        <Container maxW="8xl" px={0}>
          <Header />
          <Fields />
          <ContactInfo />
        </Container>
        <Footer />
      </MainLayout>
    </>
  )
}

export default ContactUs
