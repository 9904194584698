import { listNeeds } from '../../graphql/queries'
import { generateClient } from 'aws-amplify/api'
const client = generateClient()

const needsService = () => {
  const listNeedsCategory = async () => client.graphql({ query: listNeeds })
  return { listNeedsCategory }
}

export default needsService
