import { useTranslation } from 'contexts/TranslationContext'
import {
  Flex,
  Text,
  Button,
  Divider,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Tooltip,
} from '@chakra-ui/react'
import amplitude from 'amplitude-js'
import MultiSelect from 'components/MultiSelect'
import { useStringManipulations } from 'hooks'
import { CATEGORY } from '_constants'
import CountrySelect from 'components/CountrySelectFilters'
import { BlueSelect } from 'components/Select/BlueSelect'
import { useState, forwardRef, useEffect } from 'react'
import OrangeCountButton from './OrangeButton'
import { useScreenSize } from 'contexts'

const ListItem = ({ text, isChecked, count = '', ...props }) => {
  const { isMobile } = useScreenSize()
  return isMobile ? (
    <Button
      h="24px"
      lineHeight="24px"
      borderRadius={16}
      border="1px solid"
      borderColor={isChecked ? 'orange.400' : 'border.400'}
      bg={isChecked ? 'orange.400' : 'transparent'}
      px={3}
      fontSize="sm"
      fontWeight={400}
      textTransform="none"
      color={isChecked ? 'white' : 'gray.700'}
      mr={1}
      mb={2}
      noOfLines={1}
      _hover={{
        background: 'transparent',
        color: 'orange.400',
        borderColor: 'orange.400',
      }}
      sx={{ _hover: {} }}
      {...props}
    >
      {`${text} ${count}`}
    </Button>
  ) : (
    <Tooltip label={text} placement="top" hasArrow bg="gray.900">
      <Button
        h="24px"
        lineHeight="24px"
        borderRadius={16}
        border="1px solid"
        borderColor={isChecked ? 'orange.400' : 'border.400'}
        bg={isChecked ? 'orange.400' : 'transparent'}
        px={3}
        fontSize="sm"
        fontWeight={400}
        textTransform="none"
        color={isChecked ? 'white' : 'gray.700'}
        mr={1}
        mb={2}
        noOfLines={1}
        _hover={{
          background: 'transparent',
          color: 'orange.400',
          borderColor: 'orange.400',
        }}
        {...props}
      >
        {`${text} ${count}`}
      </Button>
    </Tooltip>
  )
}

const AccordionFilter = ({
  children,
  label,
  localFiltersLength,
  resetClick,
  ...props
}) => {
  return (
    <Accordion defaultIndex={[0]} allowMultiple {...props}>
      <AccordionItem
        sx={{
          '> *': {
            overflow: 'visible !important',
          },
        }}
        border="none"
      >
        <AccordionButton h="72px" pl={3} as={Box} cursor="pointer">
          <Flex flex={1} alignItems="center">
            <Text fontSize="md" fontWeight={600} mr={2} textAlign="left">
              {label}
            </Text>
            <OrangeCountButton
              text={localFiltersLength}
              onClick={resetClick}
              x={!!localFiltersLength}
            />
          </Flex>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={6} overflow="visible">
          {children}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

const SideFilters = forwardRef(
  (
    {
      expertiseOptions,
      localFilters,
      setLocalFilters,
      countries,
      someFiltersSelected,
      totalText,
      ...props
    },
    ref
  ) => {
    const EXPERTISE_LIMIT = 10
    const { t, language } = useTranslation()
    const { isMobile } = useScreenSize()
    const { lowerCase } = useStringManipulations()
    const [selectedTimeframe, setSelectedTimeframe] = useState({
      label: t('All'),
      value: '',
    })
    const [countryIndex, setCountryIndex] = useState(0)

    useEffect(() => {
      if (!localFilters.hrsPerWeek) {
        setSelectedTimeframe({
          label: t('All'),
          value: '',
        })
      }
      if (!localFilters.countryCode) {
        setCountryIndex(0)
      }
    }, [localFilters, language])

    const LANGUAGES = [
      { label: t('expert@form@Armenian'), value: 'Armenian' },
      { label: t('expert@form@English'), value: 'English' },
      { label: t('expert@form@Russian'), value: 'Russian' },
    ]

    const HOURS = [
      { label: t('All'), value: '' },
      { label: t('expert@form@2h'), value: '2h' },
      { label: t('expert@form@3-5h'), value: '3-5h' },
      { label: t('expert@form@6-8h'), value: '6-8h' },
      { label: t('expert@form@9+h'), value: '9+h' },
    ]

    const expertiseChosen = ({ title_en }) =>
      lowerCase(localFilters.categories).includes(lowerCase(title_en))

    const sortExpertise = (a, b) => {
      if (isMobile && expertiseChosen(b) !== expertiseChosen(a)) {
        return expertiseChosen(b) - expertiseChosen(a)
      }
      if (a.count !== b.count) {
        return b.count - a.count
      }

      if (a[`title_${language}`] < b[`title_${language}`]) {
        return -1
      }
      if (a[`title_${language}`] > b[`title_${language}`]) {
        return 1
      }
      return 0
    }
    const directionChosen = (category) =>
      localFilters.directions.includes(category)
    const sortDirection = (a, b) => {
      if (isMobile && directionChosen(b) !== directionChosen(a)) {
        return directionChosen(b) - directionChosen(a)
      }
      if (t(a) < t(b)) {
        return -1
      }
      if (t(a) > t(b)) {
        return 1
      }
      return 0
    }

    return (
      <Box
        w={{ base: 'full', sm: '264px' }}
        flexShrink={0}
        mr={{ base: 0, sm: 'calc(16px + 48px)' }}
        maxH={{ sm: 'calc(100vh - 8px)' }}
        h="fit-content"
        {...props}
      >
        <Flex
          bg="gray.300"
          w={{ base: 'full', sm: '264px' }}
          direction="column"
          ref={ref}
          pt={2}
          pb={{ base: 6, sm: 2 }}
          borderRadius={12}
        >
          <Box
            overflowY="scroll"
            mr={-4}
            pr={2}
            sx={{
              '::-webkit-scrollbar': {
                width: '8px',
              },
              '::-webkit-scrollbar-track': {
                background: '#F5F4F7',
                borderRadius: '8px',
              },
              '::-webkit-scrollbar-thumb': {
                background: '#CDCDCD',
                borderRadius: '4px',
              },
              '::-webkit-scrollbar-thumb:hover': {
                background: '#555',
              },
            }}
          >
            <Flex
              p={4}
              justifyContent="space-between"
              alignItems="center"
              display={{ base: 'none', sm: 'flex' }}
            >
              <Text fontSize="2xl" fontWeight={700}>
                {t('filters@filterBy')}
              </Text>

              <OrangeCountButton
                text={totalText()}
                x={someFiltersSelected}
                onClick={() =>
                  setLocalFilters({
                    isVolunteer: false,
                    categories: [],
                    directions: [],
                    countryCode: '',
                    languages: [],
                    hrsPerWeek: '',
                    search: null,
                    appliedFilters: 0,
                  })
                }
              />
            </Flex>

            <AccordionFilter
              label={t('filters@expertiseIn')}
              localFiltersLength={
                expertiseOptions?.filter((expertise) =>
                  expertiseChosen(expertise)
                ).length
              }
              resetClick={(event) => {
                event.preventDefault()
                setLocalFilters({ ...localFilters, categories: [] })
              }}
              borderTop={{ base: 'none', sm: '1px solid' }}
              borderColor={{ base: 'transparent', sm: 'border.100' }}
            >
              <Flex flexWrap="wrap">
                {expertiseOptions
                  ?.sort(sortExpertise)
                  ?.slice(0, EXPERTISE_LIMIT)
                  ?.map((expertise) => (
                    <ListItem
                      key={expertise[`title_${language}`]}
                      count={expertise.count}
                      isChecked={expertiseChosen(expertise)}
                      text={expertise[`title_${language}`]}
                      onClick={() => {
                        let updatedSelection = [...localFilters.categories]
                        if (expertiseChosen(expertise)) {
                          updatedSelection = updatedSelection.filter(
                            (t) =>
                              !lowerCase(expertise.tags_en.split(',')).includes(
                                lowerCase(t)
                              )
                          )
                        } else {
                          updatedSelection = [
                            ...updatedSelection,
                            ...expertise.tags_en.split(','),
                          ]
                        }
                        setLocalFilters({
                          ...localFilters,
                          categories: updatedSelection,
                        })
                      }}
                    />
                  ))}
                {expertiseOptions
                  ?.slice(EXPERTISE_LIMIT)
                  ?.filter((e) => expertiseChosen(e))
                  ?.map((expertise) => (
                    <ListItem
                      key={expertise[`title_${language}`]}
                      count={expertise.count}
                      isChecked={true}
                      text={expertise[`title_${language}`]}
                      onClick={() => {
                        let updatedSelection = [...localFilters.categories]
                        updatedSelection = updatedSelection.filter(
                          (t) =>
                            !lowerCase(expertise.tags_en.split(',')).includes(
                              lowerCase(t)
                            )
                        )
                        setLocalFilters({
                          ...localFilters,
                          categories: updatedSelection,
                        })
                      }}
                    />
                  ))}
              </Flex>
              <Box
                onClick={() => {
                  amplitude.getInstance().logEvent('Expertise click')
                  console.log('sent event to amplitude (Expertise click)')
                }}
              >
                <MultiSelect
                  options={expertiseOptions}
                  selectedOptions={localFilters.categories}
                  lang={language}
                  label={t('expertise')}
                  inputPlaceholder={t('expertiseSearchPlaceholder')}
                  containerProps={{
                    height: '40px',
                    marginTop: '8px',
                    sx: { '> *': { height: '100%', background: 'white' } },
                  }}
                  onChange={(categories) => {
                    amplitude.getInstance().logEvent('Expertise option chosen')
                    console.log(
                      'sent event to amplitude (Expertise option chosen)'
                    )
                    setLocalFilters({ ...localFilters, categories })
                  }}
                  disabledSelectedCount
                />
              </Box>
            </AccordionFilter>

            <AccordionFilter
              label={t('filters@directions')}
              localFiltersLength={localFilters.directions.length}
              resetClick={(event) => {
                event.preventDefault()
                setLocalFilters({ ...localFilters, directions: [] })
              }}
              borderTop="1px solid"
              borderColor="border.100"
            >
              <Flex flexWrap="wrap">
                {CATEGORY[language].sort(sortDirection).map((category) => (
                  <ListItem
                    key={t(`category@` + category)}
                    isChecked={directionChosen(category)}
                    text={t(`category@` + category)}
                    onClick={() => {
                      let updatedSelection = [...localFilters.directions]
                      if (directionChosen(category)) {
                        updatedSelection = updatedSelection.filter(
                          (t) => t !== category
                        )
                      } else {
                        updatedSelection.push(category)
                      }
                      setLocalFilters({
                        ...localFilters,
                        directions: updatedSelection,
                      })
                    }}
                  />
                ))}
              </Flex>
            </AccordionFilter>
            <Divider borderColor="border.100" opacity={1} />
            <Flex px={3} direction="column" py={6}>
              <Flex flex={1} mb={4} alignItems="center">
                <Text fontSize="md" fontWeight={600} mr={2}>
                  {t('filters@location')}
                </Text>
                <OrangeCountButton
                  text={localFilters.countryCode ? 1 : 0}
                  onClick={(event) => {
                    event.preventDefault()
                    setLocalFilters({ ...localFilters, countryCode: null })
                  }}
                  x={!!localFilters.countryCode}
                />
              </Flex>
              <CountrySelect
                countryIndex={countryIndex}
                setCountryIndex={setCountryIndex}
                setValue={(countryCode) =>
                  setLocalFilters({ ...localFilters, countryCode })
                }
                inputPlaceholder={t('filters@selectLocation')}
                countries={countries}
              />
            </Flex>
            <Divider borderColor="border.100" opacity={1} />
            <Flex flexWrap="wrap" px={3} py={6} direction="column">
              <Flex flex={1} mb={4} alignItems="center">
                <Text fontSize="md" fontWeight={600} mr={2}>
                  {t('filters@language')}
                </Text>
                <OrangeCountButton
                  text={localFilters.languages.length}
                  onClick={(event) => {
                    event.preventDefault()
                    setLocalFilters({ ...localFilters, languages: [] })
                  }}
                  x={!!localFilters.languages.length}
                />
              </Flex>
              <Flex>
                {LANGUAGES.map(({ label, value }) => (
                  <ListItem
                    key={label}
                    isChecked={localFilters.languages.includes(value)}
                    text={label}
                    onClick={() => {
                      let updatedSelection = [...localFilters.languages]
                      if (localFilters.languages.includes(value)) {
                        updatedSelection = updatedSelection.filter(
                          (t) => t !== value
                        )
                      } else {
                        updatedSelection.push(value)
                      }
                      setLocalFilters({
                        ...localFilters,
                        languages: updatedSelection,
                      })
                    }}
                  />
                ))}
              </Flex>
            </Flex>
            <Divider borderColor="border.100" opacity={1} />
            <Flex flexWrap="wrap" px={3} py={6} direction="column">
              <Flex flex={1} mb={4}>
                <Text fontSize="md" fontWeight={600} mr={2}>
                  {t('filters@timeframe')}
                </Text>
              </Flex>
              <Flex pb={{ base: 10, sm: 0 }}>
                <BlueSelect
                  variant="border"
                  width="full"
                  bg="white"
                  borderRadius="8px"
                  h="48px"
                  label={selectedTimeframe.label}
                  options={HOURS.map(({ label, value }) => ({
                    title: label,
                    value,
                  }))}
                  menuProps={{
                    placement: !isMobile ? 'bottom' : 'top',
                  }}
                  sx={{
                    '> button': {
                      marginTop: '0 !important',
                      height: '100%',
                      '[label]': { fontWeight: 400 },
                    },
                  }}
                  selectOption={(selected) => {
                    setSelectedTimeframe(
                      HOURS.find((e) => e.value === selected)
                    )
                    const selectedObj = { ...{ hrsPerWeek: selected } }
                    setLocalFilters({
                      ...localFilters,
                      ...selectedObj,
                    })
                  }}
                  currentSelectedOption={selectedTimeframe.label}
                />
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </Box>
    )
  }
)

export default SideFilters
