import {
  createGiftCard as createGiftCardMutation,
  updateGiftCard as updateGiftCardMutation,
} from 'graphql/mutations'
import {
  getGiftCard as getGiftCardQuery,
  listGiftCards as listGiftCardsQuery,
  giftCardsByCode as giftCardsByCodeQuery,
} from 'graphql/queries'
import { generateClient } from 'aws-amplify/api'
const client = generateClient()

const giftCardService = () => {
  const createGiftCard = async (input) => {
    const response = await client.graphql({
      query: createGiftCardMutation,
      variables: { input },
    })
    return response.data.createGiftCard
  }
  const updateGiftCard = async (input) => {
    const response = await client.graphql({
      query: updateGiftCardMutation,
      variables: { input },
    })
    return response?.data?.updateGiftCard
  }

  const getGiftCard = (id) =>
    client.graphql({ query: getGiftCardQuery, variables: { id } })

  const listGiftCards = (variables) =>
    client.graphql({ query: listGiftCardsQuery, variables })

  const listGiftCardsByCode = (variables) =>
    client.graphql({ query: giftCardsByCodeQuery, variables })

  return {
    createGiftCard,
    getGiftCard,
    listGiftCards,
    listGiftCardsByCode,
    updateGiftCard,
  }
}

export default giftCardService
