import { useMemo, useState } from 'react'
import {
  Container,
  Flex,
  HStack,
  Tag,
  TagLabel,
  TagCloseButton,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import Header from './Header'
import { CATEGORY } from '_constants'
import { Footer } from 'components/Footer'
import './index.scss'
import { BlueSelect } from 'components/Select/BlueSelect'
import { MainLayout } from 'components/Layouts'
import FundTabs from './Tabs'

const AllProductsWrapper = ({
  children,
  type,
  filters = { priceMax: null, priceMin: 0 },
  setFilters = () => {},
  noBorder,
}) => {
  const { t, language } = useTranslation()
  const [price, setPrice] = useState({
    min: 0,
    max: null,
  })

  const selectOption = (option) => {
    setFilters({ ...filters, category: option })
  }

  useMemo(() => {
    if (
      filters.priceMax === null &&
      (filters.priceMin === '0' || filters.priceMin === 0)
    ) {
      setPrice({ min: 0, max: '' })
    }
  }, [filters])

  const onResetFilters = () =>
    setFilters({
      ...filters,
      category: '',
      region: '',
      priceMin: 0,
      priceMax: null,
      duration: '',
    })

  return (
    <MainLayout>
      <Container px={{ base: '6', md: '3.75rem' }} w="full" maxW="8xl">
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          borderBottom={noBorder ? 'none' : '1px solid'}
          borderColor="border.100"
          height="70px"
        >
          {type === 'fundraisers' && (
            <FundTabs filters={filters} setFilters={setFilters} />
          )}
          <Flex display={{ base: 'none', sm: 'flex' }}>
            <Header
              onResetFilters={onResetFilters}
              price={price}
              setPrice={setPrice}
              filters={filters}
              setFilters={setFilters}
              type={type}
            />
          </Flex>

          <BlueSelect
            width="unset"
            display="flex"
            type="category"
            label={t('filters@category')}
            options={CATEGORY[language].map((option) => ({
              title: t(`category@${option}`),
              value: option,
            }))}
            selectOption={selectOption}
            currentSelectedOption={t(`category@${filters.category}`)}
            offset={[3, -23]}
          />
        </Flex>
        <HStack spacing="4" mt="6">
          {filters.category && (
            <Tag
              h="32px"
              size="sm"
              color="black"
              borderRadius="8px"
              variant="solid"
              colorScheme="gray"
            >
              <TagLabel>{t(`category@${filters.category}`)}</TagLabel>
              <TagCloseButton
                onClick={() => setFilters({ ...filters, category: '' })}
              />
            </Tag>
          )}
          {filters.region && (
            <Tag
              h="32px"
              size="sm"
              color="black"
              borderRadius="8px"
              variant="solid"
              colorScheme="gray"
            >
              <TagLabel>{t(`region@${filters.region}`)}</TagLabel>
              <TagCloseButton
                onClick={() => setFilters({ ...filters, region: '' })}
              />
            </Tag>
          )}
          {filters.duration && (
            <Tag
              h="32px"
              size="sm"
              color="black"
              borderRadius="8px"
              variant="solid"
              colorScheme="gray"
            >
              <TagLabel>{t(`projectTiming@${filters.duration}`)}</TagLabel>
              <TagCloseButton
                onClick={() => setFilters({ ...filters, duration: '' })}
              />
            </Tag>
          )}
          {filters.priceMin && (
            <Tag
              h="32px"
              size="sm"
              color="black"
              borderRadius="8px"
              variant="solid"
              colorScheme="gray"
            >
              <TagLabel>{t('min') + ' ' + filters.priceMin}</TagLabel>
              <TagCloseButton
                onClick={() => setFilters({ ...filters, priceMin: 0 })}
              />
            </Tag>
          )}
          {filters.priceMax && (
            <Tag
              h="32px"
              size="sm"
              color="black"
              borderRadius="8px"
              variant="solid"
              colorScheme="gray"
            >
              <TagLabel>{t('max') + ' ' + filters.priceMax}</TagLabel>
              <TagCloseButton
                onClick={() => setFilters({ ...filters, priceMax: null })}
              />
            </Tag>
          )}
        </HStack>
        {children}
      </Container>
      <Footer />
    </MainLayout>
  )
}

export default AllProductsWrapper
