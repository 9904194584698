import { Flex, Button, Heading, Box } from '@chakra-ui/react'
import { Link } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import LazyLoad from 'react-lazyload'

const CreateProject = () => {
  const { t } = useTranslation()

  return (
    <Box w="full">
      <LazyLoad height={217} width="full" once>
        <Box
          position="relative"
          height={217}
          w="full"
          bgImage="url('/assets/images/work.jpg')"
          bgSize="cover"
          bgPos="center"
          bgRepeat="no-repeat"
        >
          <Box
            position="absolute"
            background="linear-gradient(180deg, rgba(1,47,115,0) 0%, #012F73 100%)"
            top="0"
            left="0"
            w="full"
            h="full"
            zIndex="5"
          />
        </Box>
      </LazyLoad>
      <Flex
        mt="-1px"
        position="relative"
        zIndex="10"
        pb="3.75rem"
        bg="blue.400"
        justifyContent="center"
        align="center"
        px="1.875rem"
        flexDirection="column"
      >
        <Heading
          mt="-8"
          color="white"
          mb="1.375rem"
          fontSize="3xl"
          as="h2"
          dangerouslySetInnerHTML={{
            __html: t('homepage@createProjectHeading'),
          }}
        ></Heading>
        <Link style={{ width: 'full' }} to="/pre-create-project">
          <Button
            mb="4"
            boxShadow="0 12px 24px 0 rgba(0,0,0,0.25)"
            width="full"
            variant="orange"
            w="315px"
          >
            {t('homepage@createProjectButton1Text')}
          </Button>
        </Link>
      </Flex>
    </Box>
  )
}

export default CreateProject
