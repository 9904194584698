import { Flex, Spinner, Text } from '@chakra-ui/react'
import { useNavigation } from 'pages'
import { useUser, useProjectsByUserId } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import { useDeleteDraft, useFundraisersDraftsByUserId } from 'core/Draft/hooks'
import { useModal } from 'providers/ModalProvider'
import Space from '../Space'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import TabsLayout from 'components/Layouts/TabsLayout'
import {
  FUNDRAISER_ONE_TIME,
  FUNDRAISER_PEER_TO_PEER,
  FUNDRAISER_RECURRING,
} from 'constants/fundraiser'
import { useScreenSize } from 'contexts'
import TabPanel from './TabPanel'
import { PROJECT_TYPE_NON_PROFIT } from 'constants/project'

const TABS = [
  {
    value: FUNDRAISER_ONE_TIME,
    title: 'dashboard@oneTime',
    Panel: TabPanel,
  },
  {
    value: FUNDRAISER_PEER_TO_PEER,
    title: 'Peer-to-peer',
    Panel: TabPanel,
  },
  {
    value: FUNDRAISER_RECURRING,
    title: 'dashboard@recurring',
    Panel: TabPanel,
  },
  {
    value: PROJECT_TYPE_NON_PROFIT,
    title: 'Nonprofits',
    Panel: TabPanel,
  },
]

const FundraiserCreator = () => {
  const { tab } = useParams()
  const { navigationPush } = useNavigation()
  const { user } = useUser()
  const { t } = useTranslation()
  const { isMobile } = useScreenSize()
  const { projects, isLoading } = useProjectsByUserId({
    owner: user.id,
    limit: 100,
    filter: {
      isProject: { eq: true },
    },
  })

  const { data: draftData, refetch } = useFundraisersDraftsByUserId(user.id)
  const { mutate: deleteDraft, isLoading: isDraftDeletionLoading } =
    useDeleteDraft()
  const { openModal, closeModal } = useModal()
  const onDraftDelete = (id) => {
    openModal('deleteDraft', null, false, () => {}, {
      onDelete: () => {
        deleteDraft(id, {
          onSuccess: () => {
            refetch()
          },
          onError: (err) => {
            console.log('error', err)
          },
          onSettled: () => {
            closeModal()
          },
        })
      },
      isDraftDeletionLoading,
    })
  }

  const onChange = (tab) =>
    navigationPush(`/profile/fundraisers/${TABS[tab].value}`)

  if (isLoading)
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        margin="auto"
        h={{ md: '100%', base: '300px' }}
      >
        <Spinner color="blue.400" />
      </Flex>
    )

  return (
    <>
      {isMobile ? (
        <Flex h="100vh" flexDirection="column" justifyContent="center" px={5}>
          <Text
            textAlign="center"
            lineHeight="tall"
            fontSize="lg"
            color="gray.700"
          >
            {t('profile@mobileSpace@empty1')}
          </Text>
          <Text
            textAlign="center"
            lineHeight="tall"
            fontSize="lg"
            color="gray.700"
          >
            {t('profile@mobileSpace@empty2')}
          </Text>
        </Flex>
      ) : (
        <Space title={t('profile@fundraisers')}>
          <TabsLayout
            defaultIndex={TABS.findIndex(({ value }) => value === tab)}
            onChange={onChange}
            TABS={TABS}
            projects={projects}
            onDraftDelete={onDraftDelete}
            draftData={draftData}
            t={t}
          />
        </Space>
      )}
    </>
  )
}

export default FundraiserCreator
