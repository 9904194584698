import React, { useState } from 'react'
import {
  Stack,
  Heading,
  Button,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'contexts/TranslationContext'
import { authUseCase } from 'core/factories'
import { InputField } from 'components/InputField'
import { useQueryParams } from 'hooks'
import { PasswordField } from 'components/PasswordField'
import useCustomToast from 'hooks/useCustomToast'

const VerifyResetPasswordModal = ({ setModal, email: emailFromProps }) => {
  const toast = useCustomToast()
  const params = useQueryParams()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      code: '',
      password: '',
    },
  })
  const { t } = useTranslation()
  const [isLoading, toggleLoading] = useState(false)

  const onNewPass = async (data) => {
    if (isLoading) return
    toggleLoading(true)
    try {
      await authUseCase.forgetPasswordSubmit({
        email: emailFromProps || params.get('email'),
        code: data.code,
        password: data.password,
      })
      toast({
        status: 'success',
        title: t('resetPassword@successMessage'),
        isClosable: true,
        duration: 5000,
      })
      setModal({ name: 'signIn' })
    } catch (e) {
      toast({
        status: 'error',
        title: t(`resetPassword@${e.code}`),
        isClosable: true,
        duration: 5000,
      })
    } finally {
      toggleLoading(false)
    }
  }

  return (
    <>
      <ModalHeader marginTop="15px">
        <Heading fontSize="3xl" as="h2" fontWeight="extrabold">
          {t('resetPassword@heading')}
        </Heading>
      </ModalHeader>
      <ModalCloseButton
        color="#DDD"
        _active={{ background: 'transparent' }}
        _hover={{ background: 'transparent' }}
        id="verify_reset_modal_close"
      />
      <ModalBody mb={4}>
        <form onSubmit={handleSubmit(onNewPass)}>
          <Stack spacing="6">
            <InputField
              size="lg"
              label="email"
              value={params.get('email')}
              hidden={true}
              placeholder="code"
              id="verify_reset_modal_email"
            />
            <InputField
              size="lg"
              label={t('resetPassword@code')}
              errorMessage={t('resetPassword@codeRequired')}
              isInvalid={!!errors.code}
              {...register('code', { required: true })}
              name="code"
              autoComplete="off"
              autofill="off"
              id="verify_reset_modal_code"
            />
            <PasswordField
              size="lg"
              label={t('resetPassword@newPassword')}
              errorMessage={t('resetPassword@newPasswordRequired')}
              isInvalid={!!errors.password}
              {...register('password', { required: true })}
              autoComplete="new-password"
              newPassword
              id="verify_reset_modal_newPassword"
            />
            <Button
              w="full"
              variant="blue"
              type="submit"
              isLoading={isLoading}
              id="verify_reset_modal_submit"
            >
              {t('resetPassword@submit')}
            </Button>
          </Stack>
        </form>
      </ModalBody>
    </>
  )
}

export default VerifyResetPasswordModal
