import { HStack, Stack, Text, Icon, Image } from '@chakra-ui/react'
import ImageWrapper from 'components/ImageWrapper'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as Pencil } from 'assets/icons/pencil.svg'
import { useModal } from 'providers/ModalProvider'
import { CHOOSE_PEER_TO_PEER_FUNDRAISER_MODAL } from 'constants/modals'

export default function PeerToPeerFundraiserCard({
  project,
  note,
  isEditMode,
  onChangePeerToPeerFundraiser,
}) {
  const { language, t } = useTranslation()
  const { openModal } = useModal()

  const onEditClick = () => {
    openModal(
      CHOOSE_PEER_TO_PEER_FUNDRAISER_MODAL,
      null,
      false,
      () => {},
      { onChoose: onChangePeerToPeerFundraiser },
      { maxWidth: '1096px', minH: '50vh' },
      false
    )
  }

  return (
    <Stack spacing={note ? 2 : 0}>
      {note && (
        <Text variant="body-1" color="gray.800">
          {t(note)}
        </Text>
      )}
      <HStack
        borderRadius="16px"
        p={4}
        bg="gray.400"
        justifyContent="space-between"
        alignItems="center"
      >
        <HStack spacing={4}>
          {project?.cover ? (
            <ImageWrapper
              src={`/thumbnail/small/${project?.cover}`}
              alt={project.applicationTitle}
              borderRadius="8px"
              h="45px"
              w="78px"
              objectFit="cover"
            />
          ) : (
            <Image
              objectFit="cover"
              borderRadius="8px"
              h="45px"
              w="78px"
              ignoreFallback={true}
              src="/assets/images/noImg.svg"
            />
          )}
          <Stack spacing={1}>
            <Text variant="heading-1" color="gray.800" noOfLines={1}>
              {project[`title_${language}`]}
            </Text>
            <Text variant="body-1" color="gray.800" noOfLines={2}>
              {project[`tagline_${language}`]}
            </Text>
          </Stack>
        </HStack>
        {!isEditMode && (
          <Icon
            as={Pencil}
            h="24px"
            w="24px"
            cursor="pointer"
            onClick={onEditClick}
          />
        )}
      </HStack>
    </Stack>
  )
}
