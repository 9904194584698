import React from 'react'
import { Box, Flex, Skeleton, SkeletonText } from '@chakra-ui/react'

const ProjectSkeletonCard = () => (
  <Box>
    <Box position="relative" width="100%" height="184px">
      <Skeleton borderRadius="16px" width="100%" height="100%" />
    </Box>
    <Flex
      height="60%"
      p="3"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box minH="173px" width="100%">
        <SkeletonText mt="2" noOfLines={2} spacing="4" />
      </Box>
      <Skeleton mt="1" height="6px" borderRadius="3px" />
    </Flex>
  </Box>
)

export default ProjectSkeletonCard
