import { useQuery } from 'react-query'
import { transactionUseCase } from '../../core/factories'

const useGetRecurringTransactionByEmailDate = (variables) =>
  useQuery(
    ['recurring-transaction-history'],
    () => transactionUseCase.getTransactionsByEmailDate(variables),
    {
      refetchOnWindowFocus: false,
    }
  )

export default useGetRecurringTransactionByEmailDate
