// TODO: this file will have all configuration variables
// we won't use anything directly from process.env, we should export whatever is needed from here
// check some examples below
import awsConfig from './aws-config'

const awsConfigVariables = getAwsConfigVariables()

export const ENV = process.env.REACT_APP_ENV
export const SIGNIN_REDIRECT_URI = awsConfigVariables.redirectSignInUrl
export const SIGNOUT_REDIRECT_URI = awsConfigVariables.redirectSignOutUrl
// TODO: import all S3_BUCKET_URL references from here
export const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL
export const GA_TRACKING_ID = 'UA-209317014-1'

function getAwsConfigVariables() {
  const environment = process.env.REACT_APP_ENV

  const [
    localRedirectSignIn,
    developmentRedirectSignIn,
    productionRedirectSignIn,
  ] = awsConfig.oauth.redirectSignIn.split(',')

  const [
    localRedirectSignOut,
    developmentRedirectSignOut,
    productionRedirectSignOut,
  ] = awsConfig.oauth.redirectSignOut.split(',')

  const redirectSignInUrl = {
    local: localRedirectSignIn,
    development: developmentRedirectSignIn,
    production: productionRedirectSignIn,
  }[environment]

  const redirectSignOutUrl = {
    local: localRedirectSignOut,
    development: developmentRedirectSignOut,
    production: productionRedirectSignOut,
  }[environment]

  return {
    redirectSignOutUrl,
    redirectSignInUrl,
  }
}
