import {
  createExpertInitiatorConnection,
  updateExpertInitiatorConnection,
} from 'graphql/mutations'
import { getExpertInitiatorConnection } from 'graphql/queries'
import { generateClient } from 'aws-amplify/api'
const client = generateClient()

const expertService = () => {
  const createExpertInitConnection = async (input) => {
    const response = await client.graphql({
      query: createExpertInitiatorConnection,
      variables: { input },
    })
    return response.data.createExpertInitiatorConnection
  }
  const getConnection = async (id) => {
    const response = await client.graphql({
      query: getExpertInitiatorConnection,
      variables: { id },
    })
    return response.data.getExpertInitiatorConnection
  }
  const updateConnection = async (input) => {
    const response = await client.graphql({
      query: updateExpertInitiatorConnection,
      variables: { input },
    })
    return response.data.updateExpertInitiatorConnection
  }

  return {
    createExpertInitConnection,
    getConnection,
    updateConnection,
  }
}

export default expertService
