import React, { useEffect, useState } from 'react'
import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import LazyLoad from 'react-lazyload'
import { Link } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'

const UpdateCard = ({
  html,
  width = '280px',
  isLoading,
  projectId,
  updateId,
}) => {
  const [card, setCard] = useState()
  const { language } = useTranslation()

  useEffect(() => {
    if (html) {
      const regex = /src="([^"]+)"/
      const match = html.match(regex)
      let image = match[0]
      let title = html
        .split('<')
        .filter(
          (item) =>
            item.includes('h1>') && !item.includes('/h1>') && item.length > 3
        )[0]
      let describe = html
        .split('<')
        .filter(
          (item) =>
            item.includes('p>') && !item.includes('/p>') && item.length > 3
        )[0]
      image = image?.slice(5, image.length - 1)
      title = title?.slice(3)
      describe = describe?.slice(2)

      if (!title && !describe && !image) {
        return
      }
      setCard({
        title,
        image,
        describe,
      })
    }
  }, [])
  if (isLoading || !card) return <></>
  return (
    <Box
      as={Link}
      width={width}
      to={`/fundraisers/${projectId}?tab=updates&updateId=${updateId}`}
    >
      <LazyLoad height="160px">
        {card?.image?.includes('youtube') ? (
          <Box
            as="iframe"
            position="relative"
            height="160px"
            width={width}
            src={card?.image}
            borderRadius="12px"
            boxShadow="0 12px 24px 0 rgba(0,0,0,0.15)"
            bgColor="border.100"
          ></Box>
        ) : (
          <Box
            position="relative"
            height="160px"
            width={width}
            borderRadius="12px"
            boxShadow="0 12px 24px 0 rgba(0,0,0,0.15)"
            bgColor="border.100"
            bgImage={`url(${card?.image})`}
            bgSize="cover"
            bgPos="center"
            bgRepeat="no-repeat"
          ></Box>
        )}
      </LazyLoad>
      <Flex w={width} p="3" flexDirection="column">
        <Box width="100%">
          <Heading noOfLines={2} as="h2" fontWeight="700" fontSize="lg">
            {card?.title}
          </Heading>
          <Text
            key={`card-tagline-${language}`}
            mt="2"
            mb="2"
            noOfLines={3}
            fontSize="md"
            color="rgba(26, 26, 26, 0.6)"
          >
            {card?.describe}
          </Text>
        </Box>
      </Flex>
    </Box>
  )
}

export default UpdateCard
