import { Flex, Box, Image, Heading } from '@chakra-ui/react'
import UserInfo from 'components/UserInfo'
import { useTranslation } from 'contexts/TranslationContext'
import ImageWrapper from 'components/ImageWrapper'
import { useQueryParams } from 'hooks'
import { getImageUrl } from 'pages/SelectGiftCard/helpers'

const Header = ({ project, isContribution }) => {
  const { t, language } = useTranslation()
  const queryParams = useQueryParams()
  return (
    <Box px={6} py={12}>
      <Flex
        flexDirection={{ base: 'column', md: 'row' }}
        alignItems="center"
        justify="space-between"
      >
        <Box maxW="500px" mt={{ base: 4, sm: 0 }} mr={2}>
          {isContribution ? (
            <Flex>
              <Image
                cursor="pointer"
                objectFit="cover"
                borderTopRadius="12px"
                width="100%"
                bgColor="border.100"
                h="184px"
                ignoreFallback={true}
                src="/assets/images/contribution.svg"
                borderRadius="10px"
                minW="146px"
                height="84px"
              />
              <Heading mb="5" fontSize="sm" as="h2" pl="10px">
                <Box fontWeight="normal" as="span" fontSize="sm">
                  {t('contribute@youAreSupportingReArmenia').split('(...)')[0]}
                </Box>
                {t('rearmenia')}
                <Box fontWeight="normal" as="span" fontSize="sm">
                  {t('contribute@youAreSupportingReArmenia').split('(...)')[1]}
                </Box>
              </Heading>
            </Flex>
          ) : (
            <>
              {!queryParams.has('giftCard') && (
                <Heading mb="5" fontSize="sm" as="h2">
                  {project?.title_en
                    ? project[`title_${language}`]
                    : project?.applicationTitle || 'Gift card'}
                </Heading>
              )}

              {!project?.isCompany && project?.owner && (
                <UserInfo
                  name={project[`creatorName_${language}`]}
                  image={project?.creatorImage}
                />
              )}
            </>
          )}
        </Box>
        <Box align="center" position="relative">
          {(project?.cover || project?.coverKey) && (
            <ImageWrapper
              src={`/thumbnail/small/${project?.coverKey || project?.cover}`}
              fallbackSrc={'/assets/images/noIg.svg'}
              alt="donation"
              objectFit="cover"
              borderRadius="12px"
              w="146px"
              height="84px"
            />
          )}
          {queryParams.has('giftCard') && (
            <Image
              src={getImageUrl(queryParams.get('giftCard'))}
              alt="gift card"
              objectFit="cover"
              borderRadius="12px"
              minW={{ base: '295px', sm: '320px' }}
              height="190"
            />
          )}
        </Box>
      </Flex>
    </Box>
  )
}

export default Header
