import { Grid, Stack, Flex, Button } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import ProjectItem from './ProjectItem'

export default function Projects({
  projects,
  hasNextPage,
  isFetchingNextPage,
  onLoadMore,
  onChooseProject,
}) {
  const { t } = useTranslation()

  return (
    <Stack spacing={3}>
      <Grid templateColumns="repeat(12, 1fr)" gap={6} pb={24}>
        {projects?.map((project) => (
          <ProjectItem
            key={project.id}
            project={project}
            onChooseProject={onChooseProject}
          />
        ))}
      </Grid>
      {hasNextPage && (
        <Flex justifyContent="center" mt={2}>
          <Button
            variant="blue"
            isLoading={isFetchingNextPage}
            onClick={onLoadMore}
          >
            {t('Load more')}
          </Button>
        </Flex>
      )}
    </Stack>
  )
}
