import React from 'react'
import { useTranslation } from 'contexts/TranslationContext'
import {
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Heading,
  Modal,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { useDeleteUpdate, useCustomToast } from 'hooks'

const DeleteUpdateModal = ({ onClose, isOpen, update, refetchUpdates }) => {
  const { t } = useTranslation()
  const { mutate: deleteUpdate, isLoading } = useDeleteUpdate()
  const toast = useCustomToast()
  const onRemove = () => {
    deleteUpdate(
      { id: update.id, createdAt: update.createdAt },
      {
        onSuccess: (data) => {
          console.log(data)
          refetchUpdates()
          toast({ title: t('update@deleteSuccessMessage'), status: 'success' })
          onClose()
        },
        onError: (err) => {
          console.log(err)
          toast({ title: 'Something went wrong', status: 'error' })
        },
      }
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      isCentered={window.innerHeight > 730}
    >
      <ModalOverlay />
      <ModalContent borderRadius="16px">
        <ModalHeader>
          <Heading fontWeight="bold" fontSize="lg">
            {t('update@deletingTitle')}
          </Heading>
        </ModalHeader>
        <ModalCloseButton onClick={onClose} id="delete_update_modal_close" />
        <ModalBody pb={4}>
          <Text fontSize="lg">{t('update@subtitle')}</Text>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} id="delete_update_modal_discard">
            {t('update@discard')}
          </Button>
          <Button
            variant="transparent"
            ml={4}
            color="red.400"
            isLoading={isLoading}
            onClick={onRemove}
            id="delete_update_modal_remove"
          >
            {t('update@remove')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DeleteUpdateModal
