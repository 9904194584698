import { Stack, Text, Box, Flex } from '@chakra-ui/react'
import { InputField } from 'components/InputField'

export default function PaymentGuestUserForm({
  register,
  errors,
  title = '',
  firstName = '',
  lastName = '',
  email = '',
  emailErrorMessage = '',
}) {
  return (
    <>
      <Box mt={8} mb="4">
        <Text fontWeight="600" fontSize="xl">
          {title}
        </Text>
      </Box>

      <Stack direction={{ base: 'column' }} spacing="4" mb={2.5}>
        <Flex flexDir={{ base: 'column', md: 'row' }}>
          <InputField
            size="sm"
            isInvalid={!!errors.firstName}
            label={firstName + '*'}
            labelProps={{ marginBottom: 1.5, padding: 0 }}
            {...register('firstName', {
              required: true,
            })}
            borderRadius="8px"
            formControlProps={{
              margin: { base: '10px 0', sm: '0 10px 0 0' },
            }}
            maxLength={50}
          />
          <InputField
            size="sm"
            isInvalid={!!errors.lastName}
            label={lastName + '*'}
            labelProps={{ marginBottom: 1.5, padding: 0 }}
            {...register('lastName', {
              required: true,
            })}
            borderRadius="8px"
            maxLength={50}
          />
        </Flex>
        <InputField
          size="sm"
          isInvalid={!!errors.email}
          label={email + '*'}
          labelProps={{ marginBottom: 1.5, padding: 0 }}
          errorMessage={emailErrorMessage}
          {...register('email', {
            required: {
              message: 'createEditProject@thisFieldIsRequired',
              value: true,
            },
            pattern: {
              message: 'createEditInitiative@emailError',
              value: /\S+@\S+\.\S+/,
            },
          })}
          borderRadius="8px"
        />
      </Stack>
    </>
  )
}
