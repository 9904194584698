import React from 'react'
import { Heading, Box, HStack, Button } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import UserCard from './UserCard'
import { Link } from 'pages'
import NoScrollbar from 'components/NoScrollbar'

const DATA = [
  {
    name: 'david',
    lastName: 'hakobyan',
    position: 'advisorToMinister',
    member: 'chairman',
    photo: 'davidakopyan.jpg',
  },
  {
    name: 'james',
    lastName: 'Tufenkian',
    position: 'philanthropist',
    member: 'board',
    photo: 'jamestufenkian.jpg',
  },
  {
    name: 'narek',
    lastName: 'vardanyan',
    position: 'tcf',
    member: 'board',
    photo: 'narekvardanyan.jpg',
  },
  {
    name: 'mariam',
    lastName: 'hambardzumyan',
    position: 'global',
    member: 'board',
    photo: 'mariamhambardzumyan.jpg',
  },
]

const BoardOfTrusters = () => {
  const { t } = useTranslation()
  return (
    <Box>
      <Heading px="5" as="h2" mb="6" fontSize="1.625rem">
        {t('about@boardTrust')}
      </Heading>
      <NoScrollbar
        Component={HStack}
        alignItems="baseline"
        overflowX="scroll"
        flexWrap="nowrap"
        // ml="5"
        px="5"
        position="relative"
        spacing="5"
      >
        {DATA.map((item) => (
          <Box minW="140px" key={item.name}>
            <UserCard
              photo={item.photo}
              name={item.name}
              position={item.position}
              lastName={item.lastName}
              member={item.member}
            />
          </Box>
        ))}
      </NoScrollbar>
      <Link to="/about-us">
        <Button ml="5" color="blue.300" variant="transparent">
          {t('see everyone')}
        </Button>
      </Link>
    </Box>
  )
}

export default BoardOfTrusters
