import { useRef, useState } from 'react'
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { useSwipeable } from 'react-swipeable'
import AddEditBasketForm from 'components/AddEditBasketForm'
import { useScreenSize } from 'contexts'

export default function AddEditBasket({
  modal,
  projectId,
  maxWidth,
  closeModal,
}) {
  const { isMobile } = useScreenSize()
  const [isOpen, setIsOpen] = useState(modal?.isOpen)
  const handlers = useSwipeable({
    onSwipedDown: () => !refInputFocused.current && setIsOpen(false),
  })
  const refInputFocused = useRef(false)

  const onSuccess = () => {
    if (isMobile) {
      return setIsOpen(false)
    }
    closeModal()
  }

  const onFocus = () => {
    refInputFocused.current = true
  }

  const onBlur = () => {
    refInputFocused.current = false
  }

  if (isMobile) {
    return (
      <Drawer
        isOpen={isOpen}
        placement="bottom"
        onClose={() => setIsOpen(false)}
        onCloseComplete={closeModal}
      >
        <DrawerOverlay />
        <DrawerContent
          {...handlers}
          borderTopLeftRadius={16}
          borderTopRightRadius={16}
          height="100%"
          maxHeight="560px"
        >
          <DrawerCloseButton />
          <DrawerBody pt={12}>
            <AddEditBasketForm
              projectId={projectId}
              onSuccess={onSuccess}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    )
  }

  return (
    <Box pt={12} px={4} maxW={maxWidth}>
      <ModalCloseButton
        w="auto"
        h="auto"
        p={3}
        _active={{ background: 'transparent' }}
        _hover={{ background: 'transparent' }}
      />
      <ModalBody pt={0} px={0} pb={4}>
        <AddEditBasketForm projectId={projectId} onSuccess={onSuccess} />
      </ModalBody>
    </Box>
  )
}
