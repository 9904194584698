import React, { useState } from 'react'
import { signInWithRedirect } from 'aws-amplify/auth'
import {
  Button,
  Stack,
  Text,
  IconButton,
  Flex,
  Box,
  Heading,
  HStack,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { Link, useNavigation } from 'pages'
import { PasswordField } from 'components/PasswordField'
import { InputField } from 'components/InputField'
import { useTranslation } from 'contexts/TranslationContext'
import { useQueryParams, useSignIn, useUser } from 'hooks'
import { ReactComponent as Facebook } from 'assets/icons/authFacebook.svg'
import { ReactComponent as Google } from 'assets/icons/authGoogle.svg'
import { ReactComponent as Close } from 'assets/icons/closeBlack.svg'
import useCustomToast from 'hooks/useCustomToast'
import amplitude from 'amplitude-js'

export const SigninForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
  })
  const queryParams = useQueryParams()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const { signIn } = useSignIn()
  const { getUser } = useUser()
  const { navigationPush, navigationReplace } = useNavigation()
  const toast = useCustomToast()

  const from =
    queryParams.get('from') !== 'null' ? queryParams.get('from') : '/'

  const submit = (data) => {
    setLoading(true)
    signIn(
      {
        ...data,
        email: data.email.toLowerCase(),
      },
      {
        onSuccess: async () => {
          await getUser()
          if (queryParams.get('token')) {
            return navigationPush(
              `/collaboration-request?token=${queryParams.get(
                'token'
              )}&initiativeId=${queryParams.get(
                'initiativeId'
              )}&email=${queryParams.get('email')}&response=${queryParams.get(
                'response'
              )}`
            )
          }
          if (from) {
            if (from === '/donation') {
              const redirectURL = localStorage.getItem('redirectURL')
              localStorage.removeItem('redirectURL')
              navigationReplace(redirectURL || '/')
              return
            }
            navigationReplace(from)
            return
          }
          navigationReplace('/')
          amplitude.getInstance().logEvent('signin_mobile')
          console.log('sent event to amplitude(signin_mobile)')
        },
        onError: (err) => {
          setLoading(false)
          if (err.__type === 'UserNotConfirmedException') {
            navigationPush(`/verify?email=${data.email}`)
          }
          toast({
            title: t(err.message),
            status: 'error',
            duration: 5000,
            position: 'top',
          })
        },
      }
    )
  }
  const onError = (d) => {
    console.log(d)
  }
  const onGoogleSignIn = () => {
    amplitude.getInstance().logEvent('signingmail_mobile')
    console.log('sent event to amplitude(signingmail_mobile)')

    signInWithRedirect({ provider: 'Google' })
  }
  const onFacebookSignIn = () => {
    amplitude.getInstance().logEvent('signinfb_mobile')
    console.log('sent event to amplitude(signinfb_mobile)')

    signInWithRedirect({ provider: 'Facebook' })
  }
  return (
    <Box pb="5rem">
      <Flex
        w="full"
        justifyContent="center"
        position="relative"
        alignItems="center"
        mb="10"
        borderBottom="1px solid"
        borderColor="border.100"
        py="3"
      >
        <Flex
          position="absolute"
          left="12px"
          onClick={() => navigationPush(getBackUrl(from))}
          width="30px"
          height="30px"
          alignItems="center"
        >
          <Close width="14px" height="14px" />
        </Flex>
        <Heading as="h1" textAlign="center" fontSize="3xl">
          {t('login@heading')}
        </Heading>
      </Flex>
      <HStack justifyContent="center" mb="10" spacing="2">
        <IconButton
          width="59px"
          h="59px"
          onClick={onFacebookSignIn}
          p="0"
          icon={<Facebook width="59px" height="59px" />}
        />
        <IconButton
          width="59px"
          h="59px"
          onClick={onGoogleSignIn}
          p="0"
          icon={<Google width="59px" height="59px" />}
        />
      </HStack>
      <Box px="5">
        {' '}
        <form onSubmit={handleSubmit(submit, onError)}>
          {(!!errors.password || !!errors.email) && (
            <Text mb="4" ml="2" color="red.400">
              {t('Credentials are required')}
            </Text>
          )}
          <Stack spacing="6">
            <InputField
              size="lg"
              label={t('login@email')}
              {...register('email', {
                required: true,
                pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
              })}
            />
            <PasswordField
              id="signin_input_password"
              size="lg"
              label={t('login@password')}
              onUpdate={(e) => {
                setValue('password', e.target.value)
              }}
              {...register('password', { required: true })}
              notValidate
            />
            <Button
              display="inline-block"
              mt="3"
              mb="30px"
              color="gray.800"
              textAlign="left"
              textTransform="initial"
              fontSize="sm"
              fontWeight="semibold"
            >
              <Link to="/forgot-password">{t('login@forgotPassword')}</Link>
            </Button>
            <Button
              isLoading={loading}
              mb="4"
              w="full"
              type="submit"
              size="lg"
              variant="blue"
              colorScheme="blue"
            >
              {t('login@heading')}
            </Button>
            <Flex
              mt={8}
              py={4}
              px={6}
              justifyContent="center"
              alignItems="center"
            >
              <Text
                display="inline-block"
                textAlign="center"
                color="gray.700"
                fontSize="sm"
                fontWeight="semibold"
                mr={1}
              >
                {t('login@dontHaveAccount')}{' '}
                <Button color="orange.400">
                  <Link
                    to={`/sign-up${
                      from ? `?from=${encodeURIComponent(from)}` : ''
                    }`}
                  >
                    {t('login@signUp')}
                  </Link>
                </Button>
              </Text>
            </Flex>
          </Stack>
        </form>
      </Box>
    </Box>
  )
}

const getBackUrl = (from) => {
  if (from === '/expert-profile-mobile') {
    return '/experts'
  }

  if (from === '/pre-create-project') {
    return '/'
  }

  if (from) {
    return `/${from}`
  }

  return '/'
}
