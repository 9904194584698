import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigation } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import Impact from './Impact'
import OneTime from './OneTime'
import Recurring from './Recurring'
import Endowment from './Endowment'
import Settings from './Settings'
import Space from '../Space'
import TabsLayout from 'components/Layouts/TabsLayout'

const TABS = [
  {
    value: 'impact',
    title: 'profile@dashboard@investor@impact',
    Panel: Impact,
  },
  {
    value: 'oneTime',
    title: 'profile@dashboard@investor@oneTime',
    Panel: OneTime,
  },
  {
    value: 'recurring',
    title: 'profile@dashboard@investor@recurring',
    Panel: Recurring,
  },
  {
    value: 'endowment',
    title: 'profile@dashboard@investor@endowment',
    Panel: Endowment,
  },
  {
    value: 'settings',
    title: 'profile@dashboard@investor@settings',
    Panel: Settings,
  },
]

const Invest = () => {
  const { t } = useTranslation()
  const { tab: tabParam } = useParams()
  const { navigationPush } = useNavigation()

  const onChange = (tab) => navigationPush(`/profile/invest/${TABS[tab].value}`)

  const defaultIndex = useMemo(() => {
    const tabIndex = TABS.findIndex(({ value }) => value === tabParam)
    return tabIndex === -1 ? 0 : tabIndex
  }, [])

  return (
    <Space title={t('profile@investorSpace')}>
      <TabsLayout {...{ defaultIndex, onChange, TABS }} />
    </Space>
  )
}

export default Invest
