import React from 'react'
import { useTranslation } from 'contexts/TranslationContext'
import {
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  ModalHeader,
  Heading,
} from '@chakra-ui/react'

export default function DeleteDraftModal({
  closeModal,
  onDelete,
  isDraftDeletionLoading,
}) {
  const { t } = useTranslation()
  return (
    <>
      <ModalHeader>
        <Heading fontWeight="bold" fontSize="lg">
          {t('draft@deletingTitle')}
        </Heading>
      </ModalHeader>
      <ModalCloseButton onClick={closeModal} id="delete_update_modal_close" />
      <ModalBody pb={4}>
        <Text fontSize="lg">{t('draft@subtitle')}</Text>
      </ModalBody>

      <ModalFooter>
        <Button onClick={closeModal} id="delete_update_modal_discard">
          {t('update@discard')}
        </Button>
        <Button
          variant="transparent"
          ml={4}
          color="red.400"
          isLoading={isDraftDeletionLoading}
          onClick={() => {
            onDelete()
          }}
          id="delete_draft"
        >
          {t('update@remove')}
        </Button>
      </ModalFooter>
    </>
  )
}
