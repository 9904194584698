import { Box, Flex, Heading, HStack } from '@chakra-ui/react'
import React from 'react'
import { ReactComponent as Back } from 'assets/icons/arrowLeftNew.svg'
import { useTranslation } from 'contexts/TranslationContext'
import { useHistory } from 'react-router-dom'

export default function Header() {
  const { t } = useTranslation()
  const history = useHistory()
  return (
    <HStack
      py="12px"
      pt={8}
      px="3"
      //   borderBottom="1px solid"
      borderColor="border.100"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex
        onClick={() => history.goBack()}
        alignItems="center"
        justifyContent="center"
        width="30px"
      >
        <Back width="10px" height="20px" />
      </Flex>

      <Heading as="h1" fontSize="xl" fontWeight={600}>
        {t('giftCard')}
      </Heading>
      <Box align="center" width="30px"></Box>
    </HStack>
  )
}
