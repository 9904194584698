import { Box } from '@chakra-ui/react'
import { Link } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as Back } from 'assets/icons/arrowLeftNew.svg'

export default function BackButton({ to, ...props }) {
  const { t } = useTranslation()
  return (
    <Box
      mb="8"
      display="flex"
      minH="20px"
      minW="20px"
      fontSize="md"
      fontWeight="500"
      {...props}
    >
      <Box as={Link} to={to} display="flex" alignItems="center">
        <Back width="10px" height="17px" />
        <Box ml="3">{t('donation@back')}</Box>
      </Box>
    </Box>
  )
}
