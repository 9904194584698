import React, { forwardRef } from 'react'
import { Box } from '@chakra-ui/react'
import ExpertCard from 'components/ExpertCard'
import { useTranslation } from 'contexts/TranslationContext'
import COUNTRY_LIST from 'translations/countries.json'
import { useStringManipulations } from 'hooks'

const CardList = forwardRef(
  ({ experts, localFilters, setLocalFilters, expertBySlug }, ref) => {
    const { findNamingTranslation } = useStringManipulations()
    const { language } = useTranslation()
    if (!experts) {
      return null
    }

    return (
      <Box w="full" ref={ref}>
        {expertBySlug ? (
          <ExpertCard
            slug={expertBySlug?.slug}
            id={expertBySlug.id}
            image={
              expertBySlug?.imageUrl?.includes('https://')
                ? expertBySlug.imageUrl
                : `${process.env.REACT_APP_S3_BUCKET_URL}/public/${expertBySlug.imageUrl}`
            }
            workTime={expertBySlug?.hrsPerWeek}
            firstName={findNamingTranslation({
              parentObject: expertBySlug,
              value: 'firstName',
              language,
            })}
            lastName={findNamingTranslation({
              parentObject: expertBySlug,
              value: 'lastName',
              language,
            })}
            position={findNamingTranslation({
              parentObject: expertBySlug,
              value: 'title',
              language,
            })}
            socials={expertBySlug?.socialLinks}
            linkedin={expertBySlug?.linkedinLink}
            otherLink={expertBySlug?.link}
            description={findNamingTranslation({
              parentObject: expertBySlug,
              value: 'description',
              language,
            })}
            location={
              findNamingTranslation({
                parentObject: expertBySlug,
                value: 'location',
                language,
              }) ||
              COUNTRY_LIST.find(
                (country) =>
                  country.code === (expertBySlug?.countryCode || 'am')
              )[`name_${language}`]
            }
            expertise={expertBySlug?.spheres}
            languages={expertBySlug?.selectedLanguage?.map(
              ({ language }) => language
            )}
            directions={expertBySlug?.interestedIn}
            getInvolvedStatus={expertBySlug?.getInvolvedStatus}
            email={expertBySlug?.email}
            phone={expertBySlug?.phone}
            lang={language}
            isVolunteer={expertBySlug.isVolunteer}
            localFilters={localFilters}
            setLocalFilters={setLocalFilters}
            fromCardList
          />
        ) : (
          experts.map((expert, index) => (
            <ExpertCard
              slug={expert?.slug}
              socials={expert?.socialLinks}
              key={expert?.id}
              id={expert?.id}
              image={
                expert?.imageUrl?.includes('https://')
                  ? expert.imageUrl
                  : `${process.env.REACT_APP_S3_BUCKET_URL}/public/${expert.imageUrl}`
              }
              workTime={expert?.hrsPerWeek}
              firstName={findNamingTranslation({
                parentObject: expert,
                value: 'firstName',
                language,
              })}
              lastName={findNamingTranslation({
                parentObject: expert,
                value: 'lastName',
                language,
              })}
              position={findNamingTranslation({
                parentObject: expert,
                value: 'title',
                language,
              })}
              linkedin={expert?.linkedinLink}
              otherLink={expert?.link}
              description={findNamingTranslation({
                parentObject: expert,
                value: 'description',
                language,
              })}
              location={
                findNamingTranslation({
                  parentObject: expert,
                  value: 'location',
                  language,
                }) ||
                COUNTRY_LIST.find(
                  (country) => country.code === (expert?.countryCode || 'am')
                )[`name_${language}`]
              }
              expertise={expert?.spheres}
              languages={expert?.selectedLanguage?.map(
                ({ language }) => language
              )}
              directions={expert?.interestedIn}
              getInvolvedStatus={expert?.getInvolvedStatus}
              email={expert?.email}
              phone={expert?.phone}
              lang={language}
              index={index}
              isVolunteer={expert?.isVolunteer}
              localFilters={localFilters}
              setLocalFilters={setLocalFilters}
              fromCardList
            />
          ))
        )}
      </Box>
    )
  }
)

export default CardList
