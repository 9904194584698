import { useCallback, useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  VKShareButton,
} from 'react-share'
import {
  Box,
  Heading,
  HStack,
  Stack,
  Text,
  Button,
  useDisclosure,
  Flex,
} from '@chakra-ui/react'
import UserInfo from 'components/UserInfo'
import Progress from 'components/Progress'
import PerkCard from 'components/PerkCard'
import { Link, useNavigation } from 'pages'
import DonateModal from './DonateModal'
import DonationList from './DonationList'
import TypeCheckboxGroup from './TypeCheckboxGroup'
import ActNow from './ActNow'
import { useTranslation } from 'contexts/TranslationContext'
import ProjectHelperInfo from 'components/ProjectHelperInfo'
import { ReactComponent as FB } from 'assets/icons/transparentFacebook.svg'
import { ReactComponent as Linkedin } from 'assets/icons/transparentLinkedin.svg'
import { ReactComponent as Twitter } from 'assets/icons/transparentTwitter.svg'
import { ReactComponent as VK } from 'assets/icons/transparentVk.svg'
import { ReactComponent as Copy } from 'assets/icons/copy.svg'
import FixedNavbar from './FixedNavbar'
import useCustomToast from 'hooks/useCustomToast'
import {
  useCurrencySettings,
  CurrencyConverter,
  thousandSeparator,
  SUPPORTED_CURRENCIES,
  SUPPORTED_CURRENCY_SYMBOLS,
  convert,
} from 'contexts/CurrencySettingsContext'
import { ReactComponent as User } from 'assets/icons/blueUser.svg'
import ListItem from 'components/ListItem'
import {
  calculateProjectDurationLeft,
  useUpdateUser,
  useUser,
  useUtm,
} from 'hooks'
import AmountWithCurrency from 'components/AmountWithCurrency'
import { PROGRESS_VISIBLE_STATUSES } from '_constants'
import { useGiftCard } from 'contexts/GiftCardContext'
import NeedCard from './NeedCard'
import MessageModal from './ActNow/MessageModal'
import amplitude from 'amplitude-js'
import BasketAction from 'components/ProjectCard/BasketAction'
import { PROJECT_TYPE_NON_PROFIT } from 'constants/project'

const Information = ({
  project,
  peerProject,
  projectIds,
  implementor = false,
  infoSectionRef,
}) => {
  const location = useLocation()
  const param = useUtm(location)
  const { currency, settings, changeDefaultCurrency } = useCurrencySettings()
  const [sharingLink, setShareLink] = useState('')
  const { navigationPush } = useNavigation()
  const { t, language } = useTranslation()
  const [selectedCollaboration, setSelectedCollaboration] = useState({})
  const { user } = useUser()
  const { updateUser } = useUpdateUser()
  const {
    onOpen: onOpenNeedModal,
    isOpen: isOpenNeedModal,
    onClose: onCloseNeedModal,
  } = useDisclosure()
  const {
    onOpen: onOpenDonateModal,
    isOpen: isOpenDonateModal,
    onClose: onCloseDonateModal,
  } = useDisclosure()
  const toast = useCustomToast()
  const { giftCardMode } = useGiftCard()
  const buttonRef = useRef()

  const onPerkSelect = useCallback(
    (index) => {
      let query = param ? param + '&' : '?'
      const currentCurrency =
        currency.current !== 'AMD' ? settings.currency[currency.current] : 1
      const perkPrice = +(
        project?.perks[index].price / currentCurrency
      ).toFixed(2)
      let url = `/donation${query}perkIndex=${index}&projectId=${projectIds.projectToDonateId}&amount=${perkPrice}`
      if (projectIds.peerProjectId) {
        url += `&peerProjectId=${projectIds.peerProjectId}`
      }
      navigationPush(url)
    },
    [project, currency]
  )

  const checkPerk = () => {
    if (giftCardMode) {
      let url = `/donation?projectId=${projectIds.projectToDonateId}${
        param || ''
      }`
      if (projectIds.peerProjectId) {
        url += `&peerProjectId=${projectIds.peerProjectId}`
      }
      navigationPush(url)
      return
    }
    if (project?.perks?.length > 0) {
      onOpenDonateModal()
    } else {
      let url = `/donation?projectId=${projectIds.projectToDonateId}${
        param || ''
      }`
      if (projectIds.peerProjectId) {
        url += `&peerProjectId=${projectIds.peerProjectId}`
      }
      navigationPush(url)
    }
    amplitude.getInstance().logEvent('Donate details page button click')
    console.log('sent event to amplitude: Donate details page button click')
  }

  async function share() {
    if ('clipboard' in navigator) {
      toast({
        title: t('copied'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      return await navigator.clipboard.writeText(window.location.href)
    } else {
      toast({
        title: t('copied'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      return document.execCommand('copy', true, window.location.href)
    }
  }

  const handleDefaultCurrencyChange = (currency) => {
    changeDefaultCurrency(currency)
    if (user.id && user?.id !== 'guest') {
      updateUser({
        id: user.id,
        currency,
      })
    }
  }

  useEffect(() => {
    if (!project) return
    setShareLink(window.location.href)
  }, [])

  if (!project) {
    return <></>
  }

  return (
    <>
      <FixedNavbar
        infoSectionRef={infoSectionRef}
        buttonRef={buttonRef}
        project={project}
        checkPerk={checkPerk}
      />
      <DonateModal
        param={param}
        project={project}
        isOpen={isOpenDonateModal}
        onClose={onCloseDonateModal}
      />
      <MessageModal
        selectedCollaboration={selectedCollaboration}
        setSelectedCollaboration={setSelectedCollaboration}
        initiative={project}
        isOpen={isOpenNeedModal}
        onClose={onCloseNeedModal}
      />
      <Flex
        mb="5"
        fontSize="sm"
        alignItems="center"
        textTransform="uppercase"
        fontWeight="bold"
        color="blue.400"
        as="span"
      >
        {!project.isCompany && t(`status@${project.status}`)}
        {!project.isCompany && <Box mx="3">|</Box>}
        {t(`category@${project.category}`)}
      </Flex>
      <TypeCheckboxGroup project={project} />
      <Heading mb="3" fontSize="5xl" as="h1">
        {project && project[`title_${language}`]
          ? project[`title_${language}`]
          : project.applicationTitle}
      </Heading>
      <Text mb="5" fontSize="lg" opacity="0.6">
        {project[`tagline_${language}`]}
      </Text>
      {!project.isCompany ? (
        implementor ? (
          <>
            <UserInfo
              name={`${implementor.firstName} ${implementor.lastName}`}
              info={t('implementor')}
            />
          </>
        ) : (
          <UserInfo
            link={project?.creatorSocialLink}
            image={project?.creatorImage}
            name={project[`creatorName_${language}`]}
          />
        )
      ) : (
        <Flex alignItems="flex-end">
          <Text mr={2} fontSize="md" color="">
            {t('Raised')}{' '}
          </Text>
          <Progress
            goalText={t('goal')}
            project={project}
            raisedMoney={thousandSeparator(
              convert({
                amount: project.amount,
                currencyRate: settings?.currency[currency.current],
              }),
              currency.current
            )}
            singlePage
            thousandSeparatorText={thousandSeparator(
              currency.current === 'AMD'
                ? project.amount + Number(project.updateAmount)
                : project.amount /
                    Number(settings?.currency[currency.current]) +
                    Number(project.updateAmount),
              currency.current
            )}
            options={SUPPORTED_CURRENCIES.map((option) => ({
              title: t(`currency@${option}`),
              amount: `${CurrencyConverter({
                currency: option,
                amount: project.amount,
              })} ${option}`,
              value: option,
            }))}
            currentSelectedOption={t(`currency@${currency.current}`)}
            monthlyGoalText={t('goal')}
            goalPrice={project.activeGoal?.amount || project.goal}
            raisedText={t('project@recurringCurrentlyAmount')}
            calculatedProjectDurationLeft={calculateProjectDurationLeft({
              t,
              campaignImplementorPeriod_en:
                project.campaignImplementorPeriod_en,
              fundraisingType: project.fundraisingType,
            })}
            currency={currency}
            settings={settings}
            changeDefaultCurrency={handleDefaultCurrencyChange}
          />
        </Flex>
      )}
      {!!project?.collaboratorsTotal && project?.collaboratorsTotal > 0 && (
        <Flex
          alignItems="center"
          mt={5}
          pt={5}
          borderTop="1px solid"
          borderColor="#D5D5D5"
        >
          <User width="36px" height="36px" />
          <Text ml="3" fontWeight="700" fontSize="md">
            <Box fontWeight="500" color="gray.700" as="span">
              {project?.collaboratorsTotal === 1 ? (
                <>
                  {t('collaboration proposal').split('...')[0]}
                  <Text fontWeight="bold" color="black" as="span">
                    {project?.collaboratorsTotal}
                  </Text>{' '}
                  {t('collaboration proposal').split('...')[1]}
                </>
              ) : (
                <>
                  {t('collaboration proposals').split('...')[0]}
                  <Text fontWeight="bold" color="black" as="span">
                    {project?.collaboratorsTotal}
                  </Text>{' '}
                  {t('collaboration proposals').split('...')[1]}
                </>
              )}
            </Box>
          </Text>
        </Flex>
      )}
      {project.isProject &&
        PROGRESS_VISIBLE_STATUSES.includes(project.status) &&
        !project.isCompany && (
          <Progress
            goalText={t('goal')}
            project={project}
            raisedMoney={thousandSeparator(
              convert({
                amount: project.amount,
                currencyRate: settings?.currency[currency.current],
              }),
              currency.current
            )}
            singlePage
            thousandSeparatorText={thousandSeparator(
              currency.current === 'AMD'
                ? project.amount + Number(project.updateAmount)
                : project.amount /
                    Number(settings?.currency[currency.current]) +
                    Number(project.updateAmount),
              currency.current
            )}
            options={SUPPORTED_CURRENCIES.map((option) => ({
              title: t(`currency@${option}`),
              amount: `${CurrencyConverter({
                currency: option,
                amount: project.amount,
              })} ${option}`,
              value: option,
            }))}
            currentSelectedOption={t(`currency@${currency.current}`)}
            monthlyGoalText={t('goal')}
            goalPrice={project.activeGoal?.amount || project.goal}
            raisedText={t('project@recurringCurrentlyAmount')}
            calculatedProjectDurationLeft={calculateProjectDurationLeft({
              t,
              campaignImplementorPeriod_en:
                project.campaignImplementorPeriod_en,
              fundraisingType: project.fundraisingType,
            })}
            currency={currency}
            settings={settings}
            changeDefaultCurrency={handleDefaultCurrencyChange}
          />
        )}
      {project?.status === 'live' && (
        <HStack
          spacing="4"
          pt="2"
          my="12"
          direction={{ base: 'column', sm: 'row' }}
          alignItems="center"
          justifyContent="space-between"
        >
          {project.isCollaboration && !project.noCTA && (
            <ActNow
              buttonRef={buttonRef}
              initiative={project}
              onOpen={onOpenNeedModal}
            />
          )}
          {project.isProject && (
            <Stack
              w={
                project.fundraisingType === PROJECT_TYPE_NON_PROFIT
                  ? '230px'
                  : '100%'
              }
            >
              {giftCardMode &&
              !peerProject?.id &&
              (!project?.isOneTimeDisabled ||
                project?.fundraisingType === 'oneTime') ? (
                <Link to={`/donation?projectId=${project.id}`}>
                  <Button
                    width="100%"
                    maxW="240px"
                    h="56px"
                    borderRadius="12px"
                    variant="green"
                    ref={(el) => {
                      if (!el) return
                      const rect = el.getBoundingClientRect()
                      const parentRect =
                        el.offsetParent?.getBoundingClientRect()
                      buttonRef.current = {
                        bottom: parentRect?.bottom - rect.bottom,
                        height: rect.height,
                        left: rect.left - parentRect?.left,
                        right: parentRect?.right - rect.right,
                        top: rect.top - parentRect?.top,
                        y: rect.top - parentRect?.top,
                        width: rect.width,
                      }
                    }}
                  >
                    {t('redeem')}
                  </Button>
                </Link>
              ) : (
                <>
                  {' '}
                  <Button
                    ref={(el) => {
                      if (!el) return
                      const rect = el.getBoundingClientRect()
                      const parentRect =
                        el.offsetParent?.getBoundingClientRect()
                      buttonRef.current = {
                        bottom: parentRect?.bottom - rect.bottom,
                        height: rect.height,
                        left: rect.left - parentRect?.left,
                        right: parentRect?.right - rect.right,
                        top: rect.top - parentRect?.top,
                        y: rect.top - parentRect?.top,
                        width: rect.width,
                      }
                    }}
                    display={{ base: 'none', sm: 'inline-flex' }}
                    onClick={checkPerk}
                    width="100%"
                    maxW="230px"
                    h="56px"
                    borderRadius="12px"
                    variant="green"
                  >
                    {t('project@donate')}
                  </Button>
                  <Link
                    to={
                      project?.perks?.length > 0
                        ? `/donate/${project?.id}`
                        : `/donation?projectId=${projectIds.projectToDonateId}` +
                          projectIds.peerProjectId
                        ? `&peerProjectId=${projectIds.peerProjectId}`
                        : ''
                    }
                  >
                    <Button
                      display={{ base: 'inline-flex', sm: 'none' }}
                      width="100%"
                      maxW="230px"
                      h="56px"
                      borderRadius="12px"
                      variant="green"
                    >
                      {t('project@donate')}
                    </Button>
                  </Link>
                </>
              )}
              {project.fundraisingType === PROJECT_TYPE_NON_PROFIT && (
                <BasketAction project={project} height="56px" />
              )}
            </Stack>
          )}

          <HStack
            mb={{ base: '12', sm: '0' }}
            ml={{ base: '0', sm: '4' }}
            spacing={{ base: '10', sm: '4' }}
          >
            <Button w="20px" onClick={share}>
              <Copy />
            </Button>
            <FacebookShareButton url={sharingLink} description={'reArmenia'}>
              <FB />
            </FacebookShareButton>
            <TwitterShareButton url={sharingLink} description={'reArmenia'}>
              <Twitter />
            </TwitterShareButton>
            <LinkedinShareButton url={sharingLink} description={'reArmenia'}>
              <Linkedin />
            </LinkedinShareButton>
            <VKShareButton url={sharingLink} description={'reArmenia'}>
              <VK />
            </VKShareButton>
          </HStack>
        </HStack>
      )}
      {project.fundraisingType === 'recurring' && !project.isCompany && (
        <ProjectHelperInfo
          title={t('project@recurringHelperTitle')}
          description={t('project@recurringHelperDescription')}
        />
      )}
      {project.isCollaboration && (
        <ProjectHelperInfo
          title={t('collaboration@recurringHelperTitle')}
          description={t('collaboration@recurringHelperDescription')}
        />
      )}
      {!project?.collaborations ||
      project?.collaborations?.length === 0 ||
      project.noCTA ? (
        <></>
      ) : (
        <Box mb={10}>
          <Text fontSize="md" fontWeight={500} my={4}>
            {t('collaboration needs')}
          </Text>
          {project.collaborations.map((collaboration, index) => (
            <NeedCard
              key={`collaboration_${index}`}
              collaboration={collaboration}
              t={t}
              lang={language}
              selectAction={() => {
                onOpenNeedModal()
                setSelectedCollaboration(collaboration)
              }}
              ended={project.status !== 'live'}
            />
          ))}
        </Box>
      )}
      {(project.isCompany && !!project?.goals) ||
        (!project?.goals?.length !== 0 && (
          <Box mb={10}>
            {project.goals.map((goal, index) => (
              <ListItem
                key={`goal-${goal.amount}-${goal.description_en}-${index}`}
                data={goal}
                index={index}
                reachedText={t('reached')}
                goalText={t(
                  project?.fundraisingType === 'oneTime'
                    ? 'goal'
                    : 'monthlyGoal'
                )}
                language={language}
                currency={currency}
                goalAmount={CurrencyConverter({
                  amount: goal.amount,
                  currency: currency.current,
                })}
                SUPPORTED_CURRENCY_SYMBOLS={SUPPORTED_CURRENCY_SYMBOLS}
                dataLength={project.goals.length}
                variant={'goal'}
                Amount={
                  <AmountWithCurrency
                    amount={goal.amount}
                    currency={currency.current}
                    currencyRate={settings?.currency[currency.current]}
                  />
                }
                t={t}
              />
            ))}
          </Box>
        ))}
      {user.isAdmin && (
        <Button as={Link} to={`/edit-transactions/${project.id}`}>
          {t('Edit transactions')}
        </Button>
      )}
      <DonationList project={project} thousandSeparator={thousandSeparator} />
      {project?.perks?.length > 0 && (
        <>
          <Heading mb="5" fontSize="3xl" as="h3">
            {t('project@rewards')}
          </Heading>
          {project?.perks.map((perk, index) => {
            return (
              <PerkCard
                key={`perk_${index}`}
                isDisabled={giftCardMode}
                image={perk.image}
                title={perk[`title_${language}`] ?? ''}
                price={perk.price ?? 0}
                onSelect={() => onPerkSelect(index)}
                description={perk[`description_${language}`] ?? ''}
                isSelectable={!giftCardMode && project.status === 'live'}
              />
            )
          })}
        </>
      )}
    </>
  )
}

export default Information
