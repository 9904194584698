import { Flex } from '@chakra-ui/react'
import Header from './Header'
import { useScreenSize } from 'contexts'

const Space = ({ title, children, ...props }) => {
  const { isMobile } = useScreenSize()

  return (
    <Flex
      direction="column"
      flex={1}
      bg="#F3F3F3"
      borderTopLeftRadius={{ base: 0, sm: '30px' }}
    >
      {!isMobile && <Header title={title} />}
      <Flex
        py={{ base: '20px', sm: '32px' }}
        px={{ base: '20px', lg: '32px' }}
        direction="column"
        flex={1}
        maxW="1216px"
        minH={{ base: 'calc(100vh - 48px)', sm: 'unset' }}
        {...props}
      >
        {children}
      </Flex>
    </Flex>
  )
}

export default Space
