const fontSizes = {
  xs: '0.625rem', // 10px
  sm: '0.75rem', // 12px
  md: '0.875rem', // 14px
  lg: '1rem', // 16px
  xl: '1.125rem', // 18px
  '2xl': '1.25rem', //20px
  '3xl': '1.375rem', // 22px
  '4xl': '1.5rem', // 24px
  '5xl': '1.75rem', // 28px
  '6xl': '2rem', // 32px
  '7xl': '2.5rem', // 40px
}

export default fontSizes
