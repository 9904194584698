import React from 'react'
import { Box, Flex, HStack, Heading, Text } from '@chakra-ui/react'
import { ReactComponent as Back } from 'assets/icons/back.svg'
import { ReactComponent as Checked } from 'assets/icons/checked_blue.svg'
import {
  LANG_EN_SHORT,
  LANG_HY_SHORT,
  LANG_RU_SHORT,
  useTranslation,
} from 'contexts/TranslationContext'
import { useQueryParams } from 'hooks'
import { useNavigation } from 'pages'

export default function LanguageSwitcherMobile() {
  const { t, language, setLanguage } = useTranslation()
  const queryParams = useQueryParams()
  const { navigationPush } = useNavigation()

  const from = queryParams.get('from') || '/'

  const LANGS = [
    {
      lang: LANG_EN_SHORT,
      title: t('expert@form@English'),
    },
    {
      lang: LANG_RU_SHORT,
      title: t('expert@form@Russian'),
    },
    {
      lang: LANG_HY_SHORT,
      title: t('expert@form@Armenian'),
    },
  ]

  return (
    <>
      <HStack
        py="3"
        borderBottom="1px solid"
        borderColor="border.100"
        px="3"
        alignItems="center"
        justifyContent="space-between"
        display={{ base: 'flex', sm: 'none' }}
      >
        <Flex
          onClick={() =>
            navigationPush(from + '?drawer=open' || '/?drawer=open')
          }
          alignItems="center"
          justifyContent="center"
          width="30px"
        >
          <Back width="10px" height="20px" />
        </Flex>

        <Heading as="h1" fontSize="3xl">
          {t('filters@language')}
        </Heading>
        <Box align="center" width="30px"></Box>
      </HStack>
      <Flex direction="column" py={4} px={6}>
        {LANGS.map(({ lang, title }) => (
          <LanguageField
            key={lang}
            language={language}
            thisLanguage={lang}
            title={title}
            onClick={() => setLanguage(lang)}
          />
        ))}
      </Flex>
    </>
  )
}

const LanguageField = ({ language, thisLanguage, title, onClick }) => (
  <Flex justify="space-between" h={12} p={1} align="center" onClick={onClick}>
    <Text
      color={language === thisLanguage ? 'blue.400' : '#696A6F'}
      fontWeight={600}
    >
      {title}
    </Text>
    {language === thisLanguage && <Checked />}
  </Flex>
)
