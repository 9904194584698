import React, { useState, useCallback } from 'react'
import { ImCamera } from 'react-icons/im'
import { Flex, Box, Spinner } from '@chakra-ui/react'
import FileUpload from 'components/FileUpload'
import ImageWrapper from 'components/ImageWrapper'

export default function ImageUploader({
  image: defaultImage,
  type,
  label,
  name,
  setValue,
  isInvalid,
  ...rest
}) {
  const [isLoading, toggleLoading] = useState()
  const [image, setImage] = useState()

  const onUpload = useCallback((key) => {
    setValue(name, key)
    setImage(key)
  }, [])

  const imageUrl = image ? `/${image}` : `/thumbnail/small/${defaultImage}`

  return (
    <FileUpload
      accept={'image/*'}
      multiple={false}
      noPreview={true}
      onUpload={onUpload}
      toggleLoading={toggleLoading}
    >
      <Flex
        {...rest}
        cursor="pointer"
        flexDirection={{
          base: type === 'image' ? 'row' : 'column-reverse',
          md: 'row',
        }}
        alignItems={{
          base: type === 'image' ? 'center' : 'column',
          md: 'center',
        }}
        borderColor={isInvalid && 'red.100'}
      >
        <Flex
          position="relative"
          alignItems="center"
          justifyContent="center"
          w={type === 'image' ? '80px' : '100%'}
          h={type === 'image' ? '80px' : '184px'}
          maxW={type === 'image' ? '80px' : '324px'}
          bg="blue.400"
          borderRadius={type === 'image' ? '100px' : '16px'}
          overflow="hidden"
        >
          {image || defaultImage ? (
            <ImageWrapper
              src={imageUrl}
              alt="Creator image"
              position="absolute"
              top={0}
              left={0}
              w="100%"
              h="100%"
              objectFit="cover"
              transition="opacity 300ms"
              opacity={isLoading ? 0.3 : 1}
            />
          ) : (
            !isLoading && <ImCamera size="24px" color="white" />
          )}
          {isLoading && <Spinner color="white" size="md" />}
        </Flex>
        <Box
          as="span"
          ml={2}
          color="gray.800"
          fontSize="sm"
          mb={{ base: type === 'image' ? 0 : 2, md: 0 }}
        >
          {label}
        </Box>
      </Flex>
    </FileUpload>
  )
}
