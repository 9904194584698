import { Square, useId, useRadio } from '@chakra-ui/react'
import * as React from 'react'

export const RadioOption = (props) => {
  const { getInputProps, getCheckboxProps, getLabelProps } = useRadio(props)
  const id = useId()

  return (
    <label style={{ width: '100%', height: '100%' }} {...getLabelProps()}>
      <input {...getInputProps()} aria-labelledby={id} />
      <Square
        id={id}
        px="4"
        borderRadius="10px"
        width="100%"
        h="100%"
        fontSize="lg"
        transition="all 0.2s"
        cursor="pointer"
        color="gray.800"
        _hover={{
          bg: 'rgba(1, 47, 117, 0.15)',
        }}
        _checked={{
          bg: 'white',
        }}
        {...getCheckboxProps(props)}
      />
    </label>
  )
}
