import { createContext } from 'react'
import { guestUser } from './UserProvider'

const UserContext = createContext({
  user: guestUser,
  isLoading: false,
  setUser: () => {},
  setUserLoggedIn: async () => {},
  getUser: () => {},
  company: {},
})

export default UserContext
