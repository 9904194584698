export const jsonSafeParse = (json) => {
  try {
    return JSON.parse(json)
  } catch (e) {
    return null
  }
}

export const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
}

export function parseApiError(errorMessage) {
  if (typeof errorMessage !== 'string') {
    return null
  }
  if (!errorMessage.startsWith('err.')) {
    return null
  }

  const [name, type, ...params] = errorMessage
    .replace(/^err\./g, '')
    .split(/::/g)

  const paramsStr = params.join('::')
  let data = type === 'obj' ? JSON.parse(paramsStr) : paramsStr

  return { name, type, data }
}
