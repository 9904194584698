/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cloud_logic_custom: [
    {
      name: 'elastic',
      endpoint:
        'https://jbihk6ipa4.execute-api.us-east-1.amazonaws.com/devtest',
      region: 'us-east-1',
    },
    {
      name: 'email',
      endpoint:
        'https://9x8dmkswl5.execute-api.us-east-1.amazonaws.com/devtest',
      region: 'us-east-1',
    },
    {
      name: 'export',
      endpoint:
        'https://ikt6qnybyf.execute-api.us-east-1.amazonaws.com/devtest',
      region: 'us-east-1',
    },
    {
      name: 'mailerLiteAPI',
      endpoint:
        'https://po0qm6mjz2.execute-api.us-east-1.amazonaws.com/devtest',
      region: 'us-east-1',
    },
    {
      name: 'paymentAPI',
      endpoint:
        'https://dt1dap6rs9.execute-api.us-east-1.amazonaws.com/devtest',
      region: 'us-east-1',
    },
    {
      name: 'sharingAPI',
      endpoint:
        'https://4894e1men6.execute-api.us-east-1.amazonaws.com/devtest',
      region: 'us-east-1',
    },
    {
      name: 'stripePaymentAPI',
      endpoint:
        'https://wjxrwa1tbc.execute-api.us-east-1.amazonaws.com/devtest',
      region: 'us-east-1',
    },
    {
      name: 'syncPrismicWithDDB',
      endpoint:
        'https://j9xn7vxuj6.execute-api.us-east-1.amazonaws.com/devtest',
      region: 'us-east-1',
    },
    {
      name: 'user',
      endpoint:
        'https://pxo5av2oy8.execute-api.us-east-1.amazonaws.com/devtest',
      region: 'us-east-1',
    },
  ],
  aws_appsync_graphqlEndpoint:
    'https://naewn2ba4vcildfbn5cf7l6sby.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-mbqlkzza4bbezdfz34z7t34vga',
  aws_cognito_identity_pool_id:
    'us-east-1:426bb2d8-413a-441c-8469-dc33c060b155',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_zpTGSyp1I',
  aws_user_pools_web_client_id: '16bou3uhphac7ia49e1ukibcdr',
  oauth: {
    domain: 'auth.staging.rearmenia.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn:
      'http://localhost:3000/,https://staging.rearmenia.com/,https://rearmenia.com/',
    redirectSignOut:
      'http://localhost:3000/,https://staging.rearmenia.com/,https://rearmenia.com/',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: ['FACEBOOK', 'GOOGLE'],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_user_files_s3_bucket:
    'rearmeniae4cf535a688d4c98a974b86967d6a64f205855-devtest',
  aws_user_files_s3_bucket_region: 'us-east-1',
}

export default awsmobile
