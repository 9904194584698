import { Button, Stack, Text, Flex, useToast, Spinner } from '@chakra-ui/react'
import HorizontalProjectCardWithProgress from 'components/HorizontalProjectCardWithProgress'
import { useTranslation } from 'contexts/TranslationContext'
import DonationAmountOptions from './DonationAmountOptions'
import { useForm } from 'react-hook-form'
import DonationInput from 'components/DonationInput'
import {
  useCurrencySettings,
  convertTo,
} from 'contexts/CurrencySettingsContext'
import { useBasket } from 'contexts/BasketContext'
import { useGetProject } from 'hooks'

export default function AddEditBasketForm({
  projectId,
  onSuccess,
  onFocus,
  onBlur,
}) {
  const {
    addToBasket,
    updateBasketItem,
    isCreateLoading,
    isUpdateLoading,
    isInBasket,
  } = useBasket()
  const { data: project, isLoading } = useGetProject({ projectId })
  const toast = useToast()
  const itemInBasket = isInBasket(projectId)
  const { t } = useTranslation()
  const { currency, settings } = useCurrencySettings()
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amount: itemInBasket?.amount
        ? convertTo({
            amount: itemInBasket.amount,
            from: itemInBasket.currency,
            to: currency.current,
            settings,
          })
        : 0,
    },
  })

  const onChange = (value) =>
    setValue(
      'amount',
      convertTo({ amount: value, from: 'USD', to: currency.current, settings })
    )

  const projectAmount =
    Number(watch('amount')) * (settings?.currency[currency.current] || 1)

  const onSubmit = async (values) => {
    const input = {
      amount: values.amount,
      projectId: project.id,
      projectCover: project.cover,
      projectTitle_en: project.title_en,
      projectTitle_ru: project.title_ru,
      projectTitle_hy: project.title_hy,
      currency: currency.current,
      currencyRate: settings?.currency[currency.current] || 1,
    }

    if (itemInBasket) {
      await updateBasketItem(input)
    } else {
      await addToBasket(input)
    }
    toast({
      title: t('Added'),
      status: 'success',
      position: 'top-right',
      duration: 2000,
    })
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
  }

  if (isLoading) {
    return (
      <Flex h="461px" justifyContent="center" alignItems="center">
        <Spinner size="lg" />
      </Flex>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <HorizontalProjectCardWithProgress
        project={{
          ...project,
          amount: projectAmount + Number(project.amount || 0),
        }}
      />
      <Stack spacing={4} mt={8}>
        <Text fontWeight="500">{t('donation@recurring@inputLabel')}</Text>
        <DonationAmountOptions onChange={onChange} />
        <DonationInput
          autoFocus={false}
          register={register}
          errors={errors}
          watch={watch}
          setValue={setValue}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </Stack>
      <Button
        type="submit"
        variant="blue"
        w="100%"
        borderRadius="12px"
        mt={7}
        isLoading={isCreateLoading || isUpdateLoading}
        isDisabled={isCreateLoading || isUpdateLoading}
      >
        {t(itemInBasket ? 'Confirm' : 'Add to Basket')}
      </Button>
    </form>
  )
}
