import { HStack, useRadioGroup } from '@chakra-ui/react'
import * as React from 'react'
import { RadioOption } from './RadioOption'

export const RadioGroup = (props) => {
  const { name, options, onChange, defaultValue, ...rest } = props
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    onChange,
    defaultValue,
  })

  return (
    <HStack
      borderRadius="14px"
      border="1px solid"
      borderColor="#DDDDDD"
      spacing={{ base: 2, md: 4 }}
      {...getRootProps(rest)}
    >
      {Object.entries(options).map(([key, value]) => (
        <RadioOption key={key} {...getRadioProps({ value: key })}>
          {value}
        </RadioOption>
      ))}
    </HStack>
  )
}
