import { useQuery } from 'react-query'
import { donationUseCase } from '../../core/factories'

const useCheckPaypalOrder = ({
  transactionId,
  paypalToken,
  paypalSubscriptionId,
  isSubscription,
}) =>
  useQuery(
    [
      'check-basket-order',
      transactionId,
      paypalToken,
      paypalSubscriptionId,
      isSubscription,
    ],
    () =>
      donationUseCase.checkPaypalOrder({
        transactionId,
        paypalToken,
        paypalSubscriptionId,
        isSubscription,
      })
  )

export default useCheckPaypalOrder
