import React from 'react'
import { HStack, Flex } from '@chakra-ui/react'
import { ReactComponent as Share } from 'assets/icons/share.svg'
import { ReactComponent as Back } from '../../../assets/icons/back.svg'
import { useNavigation } from 'pages'
import { useHistory } from 'react-router-dom'
import { LANGUAGES, useTranslation } from 'contexts/TranslationContext'
import { BlueSelect } from 'components/Select/BlueSelect'
import BasketIconButton from 'components/BasketIconButton'

const Header = ({ project }) => {
  const { goBack, action } = useHistory()
  const { t, language, setLanguage } = useTranslation()
  const selectOption = (option) => setLanguage(option)
  const { navigationPush } = useNavigation()

  const navigateBack = () => {
    const isOpenedWithURL =
      action === 'POP' || !document.referrer || window.history.length === 1
    if (isOpenedWithURL) {
      return navigationPush(
        `/${project?.isCollaboration ? 'collaborations' : 'fundraisers'}`
      )
    }
    goBack()
  }

  const share = async () => {
    const domain = process.env.REACT_APP_BASE_URL
    const idOrSlug = project.slug || project.id
    return navigator.share({
      url: `${domain}/${language}/fundraisers/${idOrSlug}`,
      text: project[`title_${language}`],
    })
  }

  return (
    <HStack px="3" alignItems="center" justifyContent="space-between" py="7px">
      <Flex
        onClick={navigateBack}
        width="30px"
        height="30px"
        justifyContent="center"
        alignItems="center"
      >
        <Back width="10px" height="20px" />
      </Flex>
      <Flex alignItems="center">
        <BlueSelect
          itemsAutofocusDisabled
          width="auto"
          display="flex"
          type="language"
          variant="noBorder"
          label={t(`language@${language}LabelShort`)}
          options={Object.keys(LANGUAGES).map((key) => ({
            title: t(`language@${key}Label`),
            value: key,
            key,
          }))}
          selectOption={selectOption}
          currentSelectedOption={t(`language@${language}Label`)}
          offset={[0, 7]}
          mr={4}
        />
        <BasketIconButton />
        <Flex
          onClick={share}
          width="30px"
          height="30px"
          justifyContent="center"
          alignItems="center"
        >
          <Share />
        </Flex>
      </Flex>
    </HStack>
  )
}

export default Header
