import React, { useEffect, useState } from 'react'
import { Box, Flex, Heading, Container } from '@chakra-ui/react'
import LazyLoad from 'react-lazyload'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as Play } from 'assets/icons/play.svg'

const LINKS = {
  en: 'https://www.youtube.com/embed/eX-DWi564xI',
  hy: 'https://www.youtube.com/embed/MjYHrgl4o4Y',
  ru: 'https://www.youtube.com/embed/wrI-hkkjH1I',
}

const Explaining = () => {
  const { t, language } = useTranslation()
  const [play, setPlay] = React.useState(false)
  const [current, setCurrent] = useState()

  useEffect(() => {
    setCurrent(LINKS[language])
  }, [language])

  return (
    <Box
      className="wrapper"
      mt={{ base: '6rem', md: '188px' }}
      mb="5rem"
      bg="#FAFAFA"
    >
      <Container
        position="relative"
        top={{ base: '0', md: '-88px' }}
        maxW="6xl"
      >
        <Flex direction={{ base: 'column', md: 'row' }}>
          {/* <Box
            mr={{ base: '4', sm: '14' }}
            bg="#002D78"
            height="46px"
            width={{ base: '12px', sm: '32px' }}
          /> */}
          <Flex alignItems="center" justifyContent="center">
            <Heading my="4" fontSize={{ base: '3xl', sm: '2.5rem' }} as="h2">
              {t('How to collaborate on reArmenia')}
            </Heading>
          </Flex>
          <Flex
            minW="360px"
            align="center"
            justifyContent="center"
            margin="auto"
            maxW="750px"
            borderRadius="16px"
            width="100%"
            overflow="hidden"
            height={{ base: '350px', sm: '390px' }}
            position="relative"
          >
            <Flex
              height="100%"
              width="100%"
              zIndex="5"
              allow="autoplay"
              as="iframe"
              title="Armenia"
              allowFullScreen
              src={
                play
                  ? `${current}?autoplay=1&controls=1&enablejsapi=1&rel=0`
                  : ''
              }
            />
            <LazyLoad height={390} offset={100} once>
              <Flex
                onClick={() => setPlay(true)}
                display={play ? 'none' : 'flex'}
                align="center"
                justifyContent="center"
                zIndex={'10'}
                top="0"
                cursor="pointer"
                left="0"
                width="100%"
                height="100%"
                position="absolute"
                bgImage="url(/assets/images/explanation.jpeg)"
                bgSize="cover"
                bgPos="center"
                bgRepeat="no-repeat"
              >
                <Box
                  p="5"
                  backgroundColor="rgba(255, 255, 255, .5)"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="20px"
                  width="80px"
                  height="80px"
                >
                  <Play width="30px" />
                </Box>
              </Flex>
            </LazyLoad>
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

export default Explaining
