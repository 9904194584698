import React from 'react'
import { Box } from '@chakra-ui/react'
import Header from './Header'
import History from './History'

const MobileHistory = () => {
  return (
    <Box>
      <Header />
      <History />
    </Box>
  )
}

export default MobileHistory
