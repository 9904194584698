import React, { useState } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as Arrow } from 'assets/icons/arrowRightBlue.svg'
import { ReactComponent as Discord } from 'assets/icons/discord.svg'
import { ReactComponent as Logo } from 'assets/icons/smallLogo.svg'
import CreditCards from 'components/CreditCards'
import { useUpdateDonationSubscription } from 'hooks'
import { useReArmeniaRecurringDonationSubscriptions } from 'hooks'
import { useLocation } from 'react-router-dom'

export default function Settings({ user }) {
  const { t, language } = useTranslation()
  const location = useLocation()
  const [activeCard, setActiveCard] = useState(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { updateDonationSubscription } = useUpdateDonationSubscription()

  const { donationSubscriptions, refetch } =
    useReArmeniaRecurringDonationSubscriptions({
      filter: {
        unsubscribedDate: {
          attributeExists: false,
        },
        and: {
          userEmail: { eq: user.email },
        },
      },
    })

  const newestSubscription = donationSubscriptions?.[0]
  const onAlertOpen = (e, donationSubscription) => {
    setActiveCard(donationSubscription)
    e.preventDefault()
    onOpen()
  }

  const onUnsubscribe = (e, donationSubscription) => {
    e.stopPropagation()
    updateDonationSubscription(
      {
        id: donationSubscription.id,
        unsubscribedDate: new Date().toISOString(),
      },
      {
        onSuccess: () => {
          onClose()
          refetch()
        },
        onError: (e) => console.log('>>> UNSUBSCRIBE ERROR', e),
      }
    )
  }

  return (
    <Flex direction="column" maxW="600px">
      <Heading as="h2" fontSize="lg" mb={6}>
        {t('profile@dashboard@investor@settings@savedCards')}
      </Heading>

      <CreditCards
        fullRedirectUrl={location.pathname.slice(3)}
        removable
        showIcons
        privacyPolicy={false}
        makeDefault
        scheme="gray"
      />

      <Heading as="h2" fontSize="lg" mt={8} mb={6}>
        {t('profile@dashboard@investor@settings@community')}
      </Heading>

      <Flex
        px={5}
        py={4}
        justifyContent="space-between"
        alignItems="center"
        bg="white"
        borderRadius={8}
        h="72px"
        as="a"
        target="_blank"
        rel="noopener noreferrer"
        href="https://discord.gg/adn5mgmNUg"
      >
        <Flex alignItems="center">
          <Discord />
          <Text fontSize="md" fontWeight={600} color="gray.800" ml={4}>
            {t('profile@dashboard@investor@settings@investorClub')}
          </Text>
        </Flex>

        <Arrow />
      </Flex>

      {!!newestSubscription && (
        <>
          <Heading as="h2" fontSize="lg" mt={8} mb={6}>
            {t('profile@dashboard@investor@settings@subscription')}
          </Heading>

          <Center
            bg="white"
            py={6}
            px={5}
            borderRadius={8}
            border="1px solid"
            borderColor="border.100"
            flexDir="column"
          >
            <Flex alignItems="center">
              <Logo />

              <Text fontWeight={500} color="blue.400" ml={3} fontSize="lg">
                {t('reArmeniaCollabPlatform')}
              </Text>
            </Flex>

            <Divider h="1px" bg="gray.500" mt={4} mb="22px" />

            <Text color="gray.700" fontSize="lg" mb={4} textAlign="center">
              {t('profile@dashboard@investor@settings@subscriptionDescription')}
            </Text>

            <Button
              variant="transparent"
              onClick={(e) => onAlertOpen(e, newestSubscription)}
              color="blue.400"
              fontWeight={500}
            >
              {t('profile@dashboard@investor@settings@unsubscribe')}
            </Button>
          </Center>
        </>
      )}

      <AlertDialogModal
        t={t}
        language={language}
        onClose={onClose}
        donationSubscription={activeCard}
        isOpen={isOpen}
        onUnsubscribe={onUnsubscribe}
      />
    </Flex>
  )
}

const AlertDialogModal = ({
  t,
  language,
  onClose,
  isOpen,
  onUnsubscribe,
  isLoading,
  donationSubscription = null,
  project,
}) => {
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>{t('card@alertWarningHeading')}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          {t('card@unsubscribeAlertWarningDescription').replace(
            'PROJECT_NAME',
            (project && project[`title_${language}`]) || 'reArmenia'
          )}
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            isDisabled={isLoading}
            minH="20px"
            minW="100px"
            variant="transparent"
            onClick={onClose}
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={(e) => onUnsubscribe(e, donationSubscription)}
            minH="20px"
            minW="100px"
            variant="green"
            colorScheme="red"
            ml={3}
            isLoading={isLoading}
            isDisabled={isLoading}
          >
            {t('unsubscribe')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
