const fistNamePart = (searchArr) => `
boolean isFirstNameMatch = ${searchArr
  .map(
    (search) =>
      `(doc['firstName_en.keyword'].size() != 0 && doc['firstName_en.keyword'].value.toLowerCase().contains('${search}')) || (doc['firstName_hy.keyword'].size() != 0 && doc['firstName_hy.keyword'].value.toLowerCase().contains('${search}')) || (doc['firstName_ru.keyword'].size() != 0 && doc['firstName_ru.keyword'].value.toLowerCase().contains('${search}'))`
  )
  .join(' || ')};
`
const lastNamePart = (searchArr) => `
boolean isLastNameMatch = ${searchArr
  .map(
    (search) =>
      `(doc['lastName_en.keyword'].size() != 0 && doc['lastName_en.keyword'].value.toLowerCase().contains('${search}')) || (doc['lastName_hy.keyword'].size() != 0 && doc['lastName_hy.keyword'].value.toLowerCase().contains('${search}')) || (doc['lastName_ru.keyword'].size() != 0 && doc['lastName_ru.keyword'].value.toLowerCase().contains('${search}'))`
  )
  .join(' || ')}; 
`

const spheresPart = (searchArr) => `
boolean isSphereMatch = ${searchArr
  .map(
    (search) =>
      `(doc['experts_search.keyword'].size() != 0 && doc['experts_search.keyword'].value.toLowerCase().contains('${search}'))`
  )
  .join(' || ')}; `

const spheresSearchArr = (searchArr = []) =>
  searchArr.map((search) => spheresSearch(search)).join('\n')

const spheresSearch = (searchString) =>
  `if(doc['experts_search.keyword'].size() != 0 && doc['experts_search.keyword'].value.toLowerCase().contains('${searchString}'.toLowerCase())) {
    expertiseCount++;
  }`
const directionsSearchArr = (searchArr = []) =>
  searchArr.map((search) => directionsSearch(search)).join('\n')

const directionsSearch = (searchString) =>
  `if(doc['directions_search.keyword'].size() != 0 && doc['directions_search.keyword'].value.toLowerCase().contains('${searchString}'.toLowerCase())) {
    directionsCount++;
  }`

export const expertsSort = ({ search, directions = [], categories = [] }) => {
  const searchArr = search.toLowerCase().split(' ')
  return `
    int expertiseCount = 0;
    int directionsCount = 0;
    ${spheresSearchArr(categories)}
    ${directionsSearchArr(directions)}

    ${fistNamePart(searchArr)}
    ${lastNamePart(searchArr)}
    ${spheresPart(searchArr)}

    int result = expertiseCount + directionsCount;

    if (isFirstNameMatch && isLastNameMatch && isSphereMatch) {
        result += 10;
    } else if (isFirstNameMatch && isSphereMatch) {
        result += 6;
    } else if (isFirstNameMatch && isLastNameMatch) {
        result += 5;
    } else if (isLastNameMatch && isSphereMatch) {
        result += 4;
    } else if (isFirstNameMatch) {
        result += 3;
    } else if (isLastNameMatch) {
        result += 2;
    } else if (isSphereMatch) {
        result += 1;
    }
    
    return result;`
}
export const expertiseSort = ({ directions = [], categories = [] }) => {
  return `
    int expertiseCount = 0;
    int directionsCount = 0;
    ${spheresSearchArr(categories)}
    ${directionsSearchArr(directions)}
    return expertiseCount + directionsCount;`
}
