import { ButtonGroup, IconButton } from '@chakra-ui/react'
import * as React from 'react'
import { ReactComponent as Facebook } from 'assets/icons/facebook.svg'
import { ReactComponent as Instagram } from 'assets/icons/instagram.svg'
import { ReactComponent as Linkedin } from 'assets/icons/telegram.svg'
import { ReactComponent as Twitter } from 'assets/icons/twitter.svg'
import { ReactComponent as Youtube } from 'assets/icons/youtube.svg'

export const SocialMediaLinks = (props) => (
  <ButtonGroup spacing={8} color="gray.600" {...props}>
    <IconButton
      p="0"
      w="42px"
      as="a"
      href="https://www.facebook.com/reArmenia"
      aria-label="facebook"
      icon={<Facebook />}
    />
    <IconButton
      p="0"
      w="42px"
      as="a"
      href="https://www.instagram.com/re_armenia"
      aria-label="instagram"
      icon={<Instagram />}
    />
    <IconButton
      p="0"
      w="42px"
      as="a"
      href="https://t.me/reArmenia_official"
      aria-label="Telegram"
      icon={<Linkedin />}
    />
    <IconButton
      p="0"
      w="42px"
      as="a"
      href="https://twitter.com/re_armenia"
      aria-label="Twitter"
      icon={<Twitter />}
    />
    <IconButton
      p="0"
      w="42px"
      as="a"
      href="https://www.youtube.com/channel/UCg_oC0HaSYZRyjvbzpTZI1Q?fbclid=IwAR26A0ToKxmhjj1C8VYVZRq0Y4d-iP6juWCK_5ztPC7AKLeX74zYtVo_Ats"
      aria-label="youtube"
      icon={<Youtube />}
    />
  </ButtonGroup>
)
