import { Box, Heading, Text } from '@chakra-ui/react'

export default function ProjectHelperInfo({ title, description, ...rest }) {
  return (
    <Box
      background="#EAECEF"
      paddingY={5}
      paddingX={10}
      marginY={10}
      borderRadius="12px"
      {...rest}
    >
      <Heading fontSize="md" as="h2" mb={2}>
        {title}
      </Heading>
      <Text fontSize="sm" as="h3">
        {description}
      </Text>
    </Box>
  )
}
