import { Box, Heading } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import CreditCards from 'components/CreditCards'

export default function Payments() {
  const { t } = useTranslation()

  return (
    <Box px={5} py={7}>
      <Heading as="h1" fontSize="3xl" mb={6}>
        {t('profile@settings-payments-attached-cards')}
      </Heading>
      <CreditCards removable showIcons privacyPolicy={false} makeDefault />
    </Box>
  )
}
