import {
  normalizeInput,
  normalizeListResponse,
  normalizeResponse,
} from './helpers'

const draftUseCase = (draftRepo) => {
  const createDraft = (input) => draftRepo.createDraft(normalizeInput(input))

  const updateDraft = (input) => draftRepo.updateDraft(normalizeInput(input))

  const deleteDraft = (id) => draftRepo.deleteDraft(id)

  const getDraftById = async (id) => {
    if (!id) {
      return {}
    }
    const response = await draftRepo.getDraftById(id)
    let drafts = response.data.getDraft
    return normalizeResponse(drafts)
  }

  const getCollaborationDraftsByUserId = async (userId) => {
    if (!userId) {
      return []
    }
    const response = await draftRepo.getCollaborationDraftsByUserId(userId)
    return normalizeListResponse(response)
  }

  const getFundraiserDraftsByUserId = async (userId) => {
    if (!userId) {
      return []
    }
    const response = await draftRepo.getFundraiserDraftsByUserId(userId)
    return normalizeListResponse(response)
  }

  return {
    createDraft,
    updateDraft,
    deleteDraft,
    getDraftById,
    getCollaborationDraftsByUserId,
    getFundraiserDraftsByUserId,
  }
}

export default draftUseCase
