import React, { useCallback } from 'react'
import { Box, Heading } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import PerkCard from '../../../components/PerkCard'
import { useNavigation } from 'pages'
import { useCurrencySettings } from 'contexts/CurrencySettingsContext'
import { useGiftCard } from 'contexts/GiftCardContext'

const Perks = ({ project, param }) => {
  const { t, language } = useTranslation()
  const { navigationPush } = useNavigation()
  const { settings, currency } = useCurrencySettings()
  const { giftCardMode } = useGiftCard()
  const onPerkSelect = useCallback(
    (index) => {
      let query = param ? param + '&' : '?'
      const currentCurrency =
        currency.current !== 'AMD' ? settings.currency[currency.current] : 1
      const perkPrice = (project?.perks[index].price / currentCurrency).toFixed(
        2
      )
      navigationPush(
        `/donation${query}perkIndex=${index}&projectId=${project?.id}&amount=${perkPrice}`
      )
    },
    [project, currency]
  )
  return (
    <Box mt="10" px="5">
      {project?.perks?.length > 0 && (
        <>
          <Heading mb="6" fontSize="3xl" as="h3">
            {t('project@rewards')}
          </Heading>
          {project?.perks.map((perk, index) => (
            <PerkCard
              key={`perk-${perk.title}-${index}`}
              image={perk.imageKey ?? perk.image}
              title={perk[`title_${language}`] ?? ''}
              description={perk[`description_${language}`] ?? ''}
              price={perk.price ?? 0}
              onSelect={() => onPerkSelect(index)}
              isSelectable={!giftCardMode && project.status === 'live'}
            />
          ))}
        </>
      )}
    </Box>
  )
}

export default Perks
