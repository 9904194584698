import React from 'react'
import {
  Box,
  Button,
  Flex,
  Image,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Text,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'

export default function ApplyExpertModal({ closeModal }) {
  const { t } = useTranslation()

  return (
    <Box pt={16} px={7} pb={7} maxW="500px">
      <ModalCloseButton
        w="30px"
        h="30px"
        top={4}
        right={4}
        _active={{ background: 'transparent' }}
        _hover={{ background: 'transparent' }}
      />
      <ModalBody pt={0}>
        <Flex flexDirection="column" alignItems="center">
          <Image src="/assets/images/success.png" />
          <Text
            as="h1"
            p={{ base: 0, md: 2 }}
            lineHeight="32px"
            mt={5}
            fontSize="6xl"
            fontWeight="medium"
            color="gray.800"
            textAlign="center"
          >
            {t('expertModal@title')}
          </Text>
          <Text mt={6} fontSize="md" color="gray.700" textAlign="center">
            {t('expertModal@desc')}
          </Text>
        </Flex>
      </ModalBody>
      <ModalFooter pb={1} px={0.5}>
        <Button
          w="100%"
          mt={2}
          variant="blue"
          borderRadius="xl"
          fontSize="md"
          fontWeight="bold"
          onClick={closeModal}
        >
          {t('ok')}
        </Button>
      </ModalFooter>
    </Box>
  )
}
