import React from 'react'
import { Box, Heading, Text, Flex, Button } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { Link } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import { InputField } from 'components/InputField'
import { ReactComponent as Close } from 'assets/icons/closeBlack.svg'

const MobileForgotPassword = ({
  register,
  onRestore,
  errors,
  handleSubmit,
  isLoading,
}) => {
  const history = useHistory()
  const { t } = useTranslation()
  return (
    <Box>
      <Flex
        position="relative"
        py="3"
        borderBottom="1px solid"
        borderColor="border.100"
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          position="absolute"
          left="12px"
          h="30px"
          width="30px"
          onClick={() => history.goBack()}
          alignItems="center"
        >
          <Close width="14px" height="14px" />
        </Flex>
        <Heading as="h1" textAlign="center" fontSize="3xl">
          {t('forgotPassword@heading')}
        </Heading>
      </Flex>
      <Flex
        flexDirection="column"
        justifyContent="center"
        style={{ minHeight: 'calc(100vh - 54px)' }}
        rounded="xl"
        bg="white"
        px="5"
      >
        <form onSubmit={handleSubmit(onRestore)}>
          <InputField
            borderRadius="12px"
            size="lg"
            errorMessage={t('forgotPassword@emailErrorMessage')}
            label={t('forgotPassword@email')}
            isInvalid={!!errors.email}
            {...register('email', {
              required: true,
              pattern: /\S+@\S+\.\S+/,
            })}
          />
          <Button
            marginTop="2rem"
            w="full"
            variant="blue"
            type="submit"
            isLoading={isLoading}
          >
            {t('forgotPassword@submit')}
          </Button>
          <Flex
            display="flex"
            spacing="4"
            direction="column-reverse"
            mt="6"
            align="center"
            justify="space-between"
          >
            <Text color="gray.700" fontSize="sm" fontWeight="semibold">
              {t('forgotPassword@haveAnAccount')}{' '}
              <Button fontSize="sm" color="orange.400">
                <Link to="/sign-in">{t('forgotPassword@signIn')}</Link>
              </Button>
            </Text>
          </Flex>
        </form>
      </Flex>
    </Box>
  )
}

export default MobileForgotPassword
