import { Flex, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import SearchBar from './SearchBar'
import amplitude from 'amplitude-js'
import { useScreenSize } from 'contexts'

export default function Filters({
  onSearch,
  searchBarRef,
  defaultValue,
  totalText,
  someFiltersSelected,
  setLocalFilters,
}) {
  const [amplitudeSearched, setAmplitudeSearched] = useState()
  const { isMobile } = useScreenSize()

  return (
    <Flex
      w={{ base: 'full', sm: '6xl' }}
      mx="auto"
      px={5}
      justifyContent="space-between"
      alignItems="center"
      minH={12}
      mt={{ base: 10, sm: 14 }}
      mb={{ base: 4, sm: 6 }}
    >
      <Flex justifyContent="flex-end">
        <Text display={{ sm: 'none' }} mt={3} color="blue.400" fontSize="md">
          {totalText()}
        </Text>
      </Flex>
      {!isMobile && (
        <Flex>
          <Flex>
            <SearchBar
              onSearch={(e) => {
                if (!amplitudeSearched) {
                  amplitude.getInstance().logEvent('Expert search')
                  setAmplitudeSearched(true)
                  console.log('sent event to amplitude (Expert search)')
                }
                onSearch(e)
              }}
              searchBarRef={searchBarRef}
              someFiltersSelected={someFiltersSelected}
              setLocalFilters={setLocalFilters}
              defaultValue={defaultValue}
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}
