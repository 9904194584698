import { Flex } from '@chakra-ui/react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import CheckoutForm from './CheckoutForm'
import { useCurrencySettings } from 'contexts/CurrencySettingsContext'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PROMISE)

export default function StripeCard({
  setPaymentMethod,
  getValues,
  setValue,
  clearErrors,
  paymentMethod,
  isRecurring,
  register,
  errors,
  user,
  amount = 100,
  setTokenId,
  setNewStripeCard,
  isInvalid,
}) {
  const { currency } = useCurrencySettings()
  const options = {
    mode: 'payment',
    amount: Math.max(Math.round(amount * 100), 100),
    currency: currency.current.toLowerCase(),
    appearance,
  }

  return (
    <Flex justifyContent="space-between" w="full">
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm
          setPaymentMethod={setPaymentMethod}
          getValues={getValues}
          clearErrors={clearErrors}
          setValue={setValue}
          errors={errors}
          isRecurring={isRecurring}
          register={register}
          user={user}
          paymentMethod={paymentMethod}
          setTokenId={setTokenId}
          setNewStripeCard={setNewStripeCard}
          isInvalid={isInvalid}
        />
      </Elements>
    </Flex>
  )
}

const appearance = {
  theme: 'flat',
  variables: {
    fontFamily: ' "Gill Sans", sans-serif',
    borderRadius: '8px',
    colorPrimary: '#272727',
    colorBackground: '#ffffff',
    colorText: '#30313d',
    colorDanger: '#df1b41',
  },
  rules: {
    '.AccordionItem': {
      padding: '20px 24px',
      border: '1px solid #EAECEF',
      borderRadius: '8px',
    },
  },
}
