import { draftsByUserId, getDraft as getDraftQuery } from 'graphql/queries'
import {
  createDraft as createDraftMutation,
  updateDraft as updateDraftMutation,
  deleteDraft as deleteDraftMutation,
} from 'graphql/mutations'
import { generateClient } from 'aws-amplify/api'
const client = generateClient()

const DraftAppSyncService = () => {
  const createDraft = (input) =>
    client.graphql({ query: createDraftMutation, variables: { input } })

  const updateDraft = (input) =>
    client.graphql({ query: updateDraftMutation, variables: { input } })

  const deleteDraft = (id) =>
    client.graphql({ query: deleteDraftMutation, variables: { input: { id } } })

  const getDraftById = (id) =>
    client.graphql({ query: getDraftQuery, variables: { id } })

  const getFundraiserDraftsByUserId = (userId) =>
    client.graphql({
      query: draftsByUserId,
      variables: {
        owner: userId,
        filter: { isProject: { eq: true } },
      },
    })
  const getCollaborationDraftsByUserId = (userId) =>
    client.graphql({
      query: draftsByUserId,
      variables: {
        owner: userId,
        filter: { isCollaboration: { eq: true } },
      },
    })
  return {
    createDraft,
    updateDraft,
    deleteDraft,
    getDraftById,
    getFundraiserDraftsByUserId,
    getCollaborationDraftsByUserId,
  }
}

export default DraftAppSyncService
