import React, { useEffect, useState } from 'react'
import {
  Tabs as ChakraTabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useUser, useQueryParams } from 'hooks'
import { useLocation, useHistory } from 'react-router-dom'
import Story from './Story'
import Details from './Details'
import ManualTransaction from './ManualTransaction'
import Update from './Update'
import Report from './Report'

const Tabs = ({
  project,
  peerProject,
  projectIds,
  updates,
  isUpdatesLoading,
  refetchUpdates,
  reports,
  isReportsLoading,
  refetchReports,
}) => {
  const [tabIndex, setTabIndex] = useState(0)
  const tabs = ['story', 'updates', 'reports', 'details', 'manualTransaction']
  const { user } = useUser()
  const { t } = useTranslation()
  const queryParams = useQueryParams()
  const history = useHistory()
  const { pathname } = useLocation()

  const reportsUpdatesCondition = [
    'live',
    'launching_soon',
    'closed',
    'terminated',
    'ended',
    'implemented',
    'waiting_for_transfer_confirmation',
  ].includes(project.status)

  const handleTabsChange = (index) => {
    console.log(tabs[index])
    if (queryParams.get('tab') !== tabs[index]) {
      history.replace(`${pathname}?tab=${tabs[index]}`)
    }
    setTabIndex(index)
  }
  useEffect(() => {
    const tab = queryParams.get('tab')
    if (tab) {
      setTabIndex(tabs.indexOf(tab))
      return
    }
    if (
      project?.status === 'ended' ||
      project?.status === 'closed' ||
      project?.status === 'terminated'
    ) {
      setTabIndex(tabs.indexOf('updates'))
      history.replace(`${pathname}?tab=updates`)
      return
    }
    if (project?.status === 'implemented') {
      setTabIndex(tabs.indexOf('reports'))
      history.replace(`${pathname}?tab=reports`)
      return
    }
    history.replace(`${pathname}?tab=story`)
  }, [project])
  return (
    <ChakraTabs maxW="735px" index={tabIndex} onChange={handleTabsChange}>
      <TabList
        sx={{
          '&': {
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
          },
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        overflowX={{ base: 'scroll', sm: 'visible' }}
        overflowY={{ base: 'hidden', sm: 'visible' }}
        borderColor="border.100"
      >
        <Tab
          _active={{ backgroundColor: 'transparent' }}
          _focus={{ outline: 'none' }}
          _selected={{
            color: 'blue.400',
            borderColor: 'blue.400',
            fontWeight: 'bold',
          }}
          py="5"
        >
          {t('story')}
        </Tab>
        {reportsUpdatesCondition && (
          <>
            <Tab
              _active={{ backgroundColor: 'transparent' }}
              _focus={{ outline: 'none' }}
              _selected={{
                color: 'blue.400',
                borderColor: 'blue.400',
                fontWeight: 'bold',
              }}
            >
              {t('updates')}
            </Tab>
            <Tab
              _active={{ backgroundColor: 'transparent' }}
              _focus={{ outline: 'none' }}
              _selected={{
                color: 'blue.400',
                borderColor: 'blue.400',
                fontWeight: 'bold',
              }}
            >
              {t('reports')}
            </Tab>
          </>
        )}
        {user?.isAdmin && (
          <Tab
            _active={{ backgroundColor: 'transparent' }}
            _focus={{ outline: 'none' }}
            _selected={{
              color: 'blue.400',
              borderColor: 'blue.400',
              fontWeight: 'bold',
            }}
            py="5"
          >
            {t('project@details')}
          </Tab>
        )}
        {user?.isAdmin && project?.isProject && (
          <Tab
            _active={{ backgroundColor: 'transparent' }}
            _focus={{ outline: 'none' }}
            _selected={{
              color: 'blue.400',
              borderColor: 'blue.400',
              fontWeight: 'bold',
            }}
            py="5"
          >
            {t('Manual transactions')}
          </Tab>
        )}
      </TabList>
      <TabPanels>
        <TabPanel>
          <Story project={project} peerProject={peerProject} />
        </TabPanel>

        {reportsUpdatesCondition && (
          <TabPanel>
            <Update
              refetchUpdates={refetchUpdates}
              updates={updates}
              user={user}
              isUpdatesLoading={isUpdatesLoading}
              project={project}
            />
          </TabPanel>
        )}
        {reportsUpdatesCondition && (
          <TabPanel>
            <Report
              refetchReports={refetchReports}
              reports={reports}
              user={user}
              isReportsLoading={isReportsLoading}
              project={project}
            />
          </TabPanel>
        )}
        {user?.isAdmin && (
          <TabPanel>
            <Details project={project} />
          </TabPanel>
        )}
        {user?.isAdmin && project?.isProject && (
          <TabPanel>
            <ManualTransaction project={project} projectIds={projectIds} />
          </TabPanel>
        )}
      </TabPanels>
    </ChakraTabs>
  )
}

export default Tabs
