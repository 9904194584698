import {
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverBody,
  Icon,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as AngleUp } from 'assets/icons/angleTop.svg'

export default function Dropdown({
  btnTitle,
  isOpen,
  onToggle,
  onClose,
  children,
}) {
  const { t } = useTranslation()

  return (
    <Popover isOpen={isOpen} onClose={onClose} closeOnBlur={true}>
      <PopoverTrigger>
        <Button
          w="264px"
          variant="grayV2"
          display="flex"
          justifyContent="space-between"
          borderBottomLeftRadius={isOpen ? 0 : '12px'}
          borderBottomRightRadius={isOpen ? 0 : '12px'}
          onClick={onToggle}
        >
          {t(btnTitle)}
          <Icon
            as={AngleUp}
            h="8px"
            transform={`rotate(${!isOpen ? '180' : '0'}deg)`}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        w="264px"
        mt="-2"
        borderTopLeftRadius={0}
        borderTopRightRadius={0}
        borderBottomRadius="12px"
        borderWidth={1}
        borderTopWidth={0}
        borderColor="gray.600"
        bg="gray.300"
      >
        <PopoverBody>{children}</PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
