import {
  getProject,
  projectBySlug,
  projectByUserId,
  projectBypopular,
  projectByFeatured,
} from '../../graphql/queries'
import {
  createProject as create,
  updateProject as update,
  deleteProject as removeProject,
  subscribeToProjectNewsletter as subscribeToProjectNewsletterMutation,
  createMailerLiteFields as createMailerLiteFieldsMutation,
} from '../../graphql/mutations'
import { getResponseToJSON } from 'hooks/useResponseManipulations'

const ProjectService = (appSyncRequest, auth, api) => {
  const createProject = async (project) => {
    const currentAuthSession = await auth.fetchAuthSession()
    const jwtToken = currentAuthSession.tokens.idToken.toString()
    return await appSyncRequest({
      query: create,
      variables: { input: project },
      authToken: jwtToken,
      authMode: 'userPool',
    })
  }

  const updateProject = async (project) => {
    const currentAuthSession = await auth.fetchAuthSession()
    const jwtToken = currentAuthSession.tokens.idToken.toString()
    return await appSyncRequest({
      query: update,
      variables: { input: project },
      authToken: jwtToken,
      authMode: 'userPool',
    })
  }

  const updateProjectStatus = async ({ id, status }) => {
    const currentAuthSession = await auth.fetchAuthSession()
    const jwtToken = currentAuthSession.tokens.idToken.toString()
    await appSyncRequest({
      query: update,
      variables: { input: { id, status } },
      authToken: jwtToken,
      authMode: 'userPool',
    })
  }

  const updateProjectPerks = async ({ id, perks }) => {
    const currentAuthSession = await auth.fetchAuthSession()
    const jwtToken = currentAuthSession.tokens.idToken.toString()
    await appSyncRequest({
      query: update,
      variables: { input: { id, perks } },
      authToken: jwtToken,
      authMode: 'userPool',
    })
  }

  const retrieveProject = async (id) => {
    const project = await appSyncRequest({
      query: getProject,
      variables: { id },
    })

    return project.data.getProject
  }

  const getProjectBySlug = async (slug) => {
    const project = await appSyncRequest({
      query: projectBySlug,
      variables: { slug },
    })
    return project.data.projectBySlug.items[0] || null
  }

  const deleteProject = async (project) => {
    const currentAuthSession = await auth.fetchAuthSession()
    const jwtToken = currentAuthSession.tokens.idToken.toString()
    await appSyncRequest({
      query: removeProject,
      variables: { input: project },
      authToken: jwtToken,
      authMode: 'userPool',
    })
  }

  const getProjectsFromElastic = async (condition) => {
    let environment = process.env.REACT_APP_ENV
    if (environment === 'local') {
      environment = 'development'
    }
    const result = api.post({
      apiName: 'elastic',
      path: '/proxy',
      options: {
        body: {
          index: `project_${environment}`,
          path: '_search',
          method: 'POST',
          data: condition,
        },
      },
    })

    const response = await getResponseToJSON(result)
    if (response?.hits.total) {
      response.hits.meta = {
        total: response.hits.total.value,
        size: condition.size,
        from: condition.from,
      }
    }
    return response
  }

  const getProjectsByUserId = (condition) =>
    appSyncRequest({ query: projectByUserId, variables: condition })

  const getProjectsByPopular = (condition) =>
    appSyncRequest({ query: projectBypopular, variables: condition })

  const getProjectsByFeatured = (condition) =>
    appSyncRequest({ query: projectByFeatured, variables: condition })

  const subscribeToProjectNewsletter = ({ email, projectId }) =>
    appSyncRequest({
      query: subscribeToProjectNewsletterMutation,
      variables: { input: { email, projectId } },
    })

  const createMailerLiteFields = async ({ projectId }) => {
    const currentAuthSession = await auth.fetchAuthSession()
    const jwtToken = currentAuthSession.tokens.idToken.toString()

    return appSyncRequest({
      query: createMailerLiteFieldsMutation,
      variables: { input: { projectId } },
      authToken: jwtToken,
      authMode: 'userPool',
    })
  }

  return {
    createProject,
    updateProject,
    retrieveProject,
    updateProjectPerks,
    updateProjectStatus,
    deleteProject,
    getProjectBySlug,
    getProjectsFromElastic,
    getProjectsByUserId,
    getProjectsByPopular,
    getProjectsByFeatured,
    subscribeToProjectNewsletter,
    createMailerLiteFields,
  }
}

export default ProjectService
