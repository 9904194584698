import React, { useEffect, useMemo, useRef, useState } from 'react'
import { MainLayout } from 'components/Layouts'
import { Footer } from 'components/Footer'
import Header from './Header'
import CardList from './CardList'
import { useHistory, useParams } from 'react-router-dom'
import { LANG_EN_SHORT, useTranslation } from 'contexts/TranslationContext'
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  IconButton,
  Image,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react'
import amplitude from 'amplitude-js'
import debounce from 'lodash.debounce'
import { useQueryParams, useStringManipulations, useUser } from 'hooks'
import { useNavigation } from 'pages'
import {
  useContentFromElastic,
  useGetExpertBySlug,
  useGetExpertsCategories,
  useGetExpertsCountries,
} from 'core/Content/hooks'
import { expertsQuery } from 'elastic/queries'

import { ReactComponent as Facebook } from 'assets/icons/facebook.svg'
import { ReactComponent as Instagram } from 'assets/icons/instagram.svg'
import { ReactComponent as Linkedin } from 'assets/icons/telegram.svg'
import { ReactComponent as Twitter } from 'assets/icons/twitter.svg'
import { ReactComponent as FilterIcon } from 'assets/icons/filterMobile.svg'
import SearchBar from './SearchBar'
import Filters from './Filters'
import MobileFilter from './MobileFilters'
import SideFilters from './SideFilters'
import { polyfill, scrollIntoView } from 'seamless-scroll-polyfill'
import _ from 'lodash'
import Navbar from 'components/Navbar'
import MetaTags from 'components/MetaTags'
import { useModal } from 'providers/ModalProvider'
import { useScreenSize } from 'contexts'

const useGetCachedExperts = () => {
  let cachedResponse = localStorage.getItem('experts_cache')
  if (cachedResponse) {
    try {
      cachedResponse = JSON.parse(cachedResponse)
    } catch (err) {
      cachedResponse = { items: [], total: 0 }
      console.log('ERROR WHILE PARSING CACHED EXPERTS', err)
    }
  }
  return cachedResponse || { items: [], total: 0 }
}
const SIZE = 6

export default function Experts() {
  const { isMobile } = useScreenSize()
  const { navigationReplace } = useNavigation()
  const { user } = useUser()
  const { slug } = useParams()
  const { openModal } = useModal()
  const { t, language } = useTranslation()
  const { data: expertBySlug, isLoading: isExpertWithSlugLoading } =
    useGetExpertBySlug(slug)
  const params = useQueryParams()

  const { push } = useHistory()

  const searchBarRef = useRef(null)
  const headerRef = useRef(null)
  const cardsParentRef = useRef(null)
  const loaderRef = useRef(null)
  const filtersRef = useRef(null)
  const filtersParentRef = useRef(null)
  const footerRef = useRef(null)
  const prevLocalFilters = useRef()

  const [options, setOptions] = useState([])
  const [amplitudeSearched, setAmplitudeSearched] = useState()

  const {
    isOpen: isMobileFiltersOpen,
    onOpen: openMobileFilters,
    onClose: closeMobileFilters,
  } = useDisclosure()
  const { data: categories } = useGetExpertsCategories()
  const { data: countries } = useGetExpertsCountries()
  const [page, setPage] = useState(Number(params.get('page')) || 1)

  const cachedResponse = useGetCachedExperts()

  const [localFilters, setLocalFilters] = useState({
    isVolunteer: false,
    categories: [],
    directions: [],
    countryCode: '',
    languages: [],
    hrsPerWeek: '',
    search: params.get('search'),
    appliedFilters: 0,
  })
  const someFiltersSelected = !_.isEqual(localFilters, {
    isVolunteer: false,
    categories: [],
    directions: [],
    countryCode: '',
    languages: [],
    hrsPerWeek: '',
    search: null,
    appliedFilters: 0,
  })

  const someNonSearchFiltersSelected = !_.isEqual(
    { ...localFilters, search: null },
    {
      isVolunteer: false,
      categories: [],
      directions: [],
      countryCode: '',
      languages: [],
      hrsPerWeek: '',
      search: null,
      appliedFilters: 0,
    }
  )

  const { lowerCase, findNamingTranslation } = useStringManipulations()

  const expertiseChosen = ({ title_en }) =>
    lowerCase(localFilters.categories).includes(lowerCase(title_en))

  const appliedFilters = useMemo(() => {
    let result = 0

    if (localFilters.isVolunteer) {
      result++
    }
    if (options?.filter((expertise) => expertiseChosen(expertise)).length) {
      result += options?.filter((expertise) =>
        expertiseChosen(expertise)
      ).length
    }
    if (localFilters.directions.length) {
      result += localFilters.directions.length
    }
    if (localFilters.countryCode) {
      result++
    }
    if (localFilters.hrsPerWeek) {
      result++
    }
    if (localFilters.languages.length) {
      result += localFilters.languages.length
    }
    return result
  }, [localFilters])

  const { data, isLoading, setFilters } = useContentFromElastic(
    expertsQuery({
      ...localFilters,
      size: SIZE,
      search: params.get('search'),
      isAdmin: user.isAdmin,
      appliedFilters,
    }),
    'get_content_experts',
    page,
    params.has('search')
  )
  const [experts, setExperts] = useState([])

  useEffect(() => {
    if (!isLoading && !!data?.items?.length) {
      setExperts(
        page === 1
          ? [...(data?.items || [])]
          : [...experts, ...(data?.items || [])]
      )
    }
  }, [data])

  useEffect(() => {
    if (!isLoading && experts.length) {
      onScroll()
    }
  }, [experts])

  const seeMoreVisible =
    !!experts && !!experts?.length && experts?.length !== data?.total

  const updateUrl = (search) => {
    const basePath = window.location.pathname
    let query = `?page=${page}`
    if (search && search.length > 2) {
      query += `&search=${search}`
    }
    push(`${basePath}${query}`)
  }

  const onSearch = (e) => {
    const search = e.target.value
    setPage(1)
    updateUrl(search)
    setLocalFilters({
      ...localFilters,
      search,
    })
  }

  const onScroll = () => {
    if (!filtersRef.current) {
      return
    }
    const { scrollY } = window
    const { offsetTop } = filtersParentRef.current
    const bottomOffset =
      window.innerHeight -
      footerRef.current?.getBoundingClientRect().bottom +
      footerRef.current?.clientHeight
    let styles = {}

    if (scrollY > offsetTop - 4) {
      styles = {
        position: 'fixed',
        top: '4px',
      }
    } else {
      styles = {
        position: 'static',
      }
    }
    styles.maxHeight = `calc(100vh - 8px - ${
      bottomOffset > 0 ? bottomOffset : 0
    }px)`
    Object.assign(filtersRef.current.style, styles)
  }

  useEffect(() => {
    if (isMobile) {
      return
    }
    document.addEventListener('scroll', onScroll)

    return () => document.removeEventListener('scroll', onScroll)
  }, [onScroll, isMobile])

  useEffect(() => {
    if (params.get('action') && params.get('action') === 'connect') {
      openModal('connect')
    }
  }, [params.get('action')])

  const totalText = (button) => {
    let expertCount = data?.total || 0
    const search = params.get('search')
    if (search && normalizedExperts?.length < 6 && !seeMoreVisible) {
      expertCount = normalizedExperts?.length || 0
    }
    if ((!isMobile || button) && language !== LANG_EN_SHORT) {
      return expertCount
    }
    return someFiltersSelected
      ? `${expertCount} ${t('found')}`
      : `${t('filters@total')} ${expertCount}`
  }

  const debouncedChangeHandler = useMemo(
    () => debounce(onSearch, 300),
    [localFilters]
  )
  const handlePaginationScroll = () => {
    const header = headerRef.current
    if (header) {
      polyfill()
      scrollIntoView(header)
    }
  }
  useEffect(() => {
    amplitude.getInstance().logEvent('Experts page view')
    const search = params.get('search')
    if (search) {
      if (searchBarRef.current) {
        searchBarRef.current.value = search
      }
    }
    return () => {
      debouncedChangeHandler.cancel()
    }
  }, [searchBarRef.current])

  useEffect(() => {
    let basePath = '/experts'
    if (slug) {
      basePath = `/experts/${slug}`
    }
    let query = `?page=${page}`
    const search = params.get('search')
    if (search) {
      query += `&search=${search}`
    }
    navigationReplace(basePath + query)
    setFilters(
      expertsQuery({
        ...localFilters,
        isAdmin: user.isAdmin,
        search,
        from: (page - 1) * SIZE,
        appliedFilters,
      })
    )
  }, [page])

  useEffect(() => {
    if (!categories?.length) {
      return
    }
    setOptions(categories)
  }, [categories])

  useEffect(() => {
    setPage(1)
    const updateFilters = {
      isAdmin: user.isAdmin,
      from: 0,
      isVolunteer: localFilters.isVolunteer,
      search: localFilters.search,
      appliedFilters,
    }
    if (localFilters.categories.length) {
      updateFilters.categories = localFilters.categories
    }
    if (localFilters.directions.length) {
      updateFilters.directions = localFilters.directions
    }
    if (localFilters.countryCode) {
      updateFilters.countryCode = localFilters.countryCode
    }
    if (localFilters.hrsPerWeek) {
      updateFilters.hrsPerWeek = localFilters.hrsPerWeek
    }
    if (localFilters.languages.length) {
      updateFilters.languages = localFilters.languages
    }
    if (searchBarRef.current) {
      searchBarRef.current.value = localFilters.search
    }
    if (
      !_.isEqual(localFilters, prevLocalFilters?.localFilters) &&
      prevLocalFilters?.localFilters
    ) {
      handlePaginationScroll()
      setExperts([])
    }
    prevLocalFilters.localFilters = { ...localFilters }
    updateUrl(updateFilters.search)
    setFilters(expertsQuery(updateFilters))
  }, [localFilters])

  useEffect(() => {
    if (!expertBySlug && !isExpertWithSlugLoading) {
      const basePath = '/experts'
      const query = `?page=${page}`
      navigationReplace(`${basePath}${query}`)
    }
  }, [expertBySlug, isExpertWithSlugLoading])

  const normalizedExperts = useMemo(() => {
    if (
      isLoading &&
      !appliedFilters &&
      page === 1 &&
      !params.has('search') &&
      cachedResponse.total > 0
    ) {
      return expertBySlug
        ? _.uniqBy(
            [
              expertBySlug,
              ...(cachedResponse?.items.filter(
                (e) => e.id !== expertBySlug.id
              ) || []),
            ],
            'id'
          )
        : cachedResponse?.items
    }
    if (experts?.length || data?.items?.length) {
      return !!expertBySlug && page === 1
        ? _.uniqBy(
            [
              expertBySlug,
              ...experts
                .filter((expert) => (!user.isAdmin ? !expert.isPrivate : true))
                .filter((expert) => expert.expert_status === 'approved')
                .filter((e) => e.id !== expertBySlug.id),
            ],
            'id'
          )
        : experts
            .filter((expert) => (!user.isAdmin ? !expert.isPrivate : true))
            .filter((expert) => expert.expert_status === 'approved')
    }
  }, [
    isLoading,
    appliedFilters,
    page,
    params,
    cachedResponse,
    expertBySlug,
    experts,
    data,
    user,
  ])

  return (
    <>
      {slug && expertBySlug && (
        <MetaTags
          title={`${findNamingTranslation({
            parentObject: expertBySlug,
            value: 'firstName',
            language,
          })} ${findNamingTranslation({
            parentObject: expertBySlug,
            value: 'lastName',
            language,
          })} | reArmenia`}
          description={expertBySlug[`description_${language}`]}
          image={
            expertBySlug?.imageUrl?.includes('https://')
              ? expertBySlug.imageUrl
              : `${process.env.REACT_APP_S3_BUCKET_URL}/public/${expertBySlug.imageUrl}`
          }
        />
      )}
      <MainLayout
        bg={{ base: 'gray.400', sm: 'unset' }}
        minH={{ base: 'calc(100vh - 50px)', sm: 'unset' }}
      >
        {isMobile && (
          <MobileFilter
            isOpen={isMobileFiltersOpen}
            onClose={closeMobileFilters}
            expertiseOptions={options}
            localFilters={localFilters}
            setLocalFilters={setLocalFilters}
            totalText={totalText}
            countries={countries}
            someFiltersSelected={someFiltersSelected}
          />
        )}
        {isMobile && <Navbar from="experts" title={t('experts')} />}
        <Box background="gray.400">
          <>
            <Header />
            <div ref={headerRef}></div>
            <Filters
              onSearch={debouncedChangeHandler}
              searchBarRef={searchBarRef}
              defaultValue={params.get('search')}
              totalText={totalText}
              someFiltersSelected={someNonSearchFiltersSelected}
              setLocalFilters={setLocalFilters}
            />
            {isMobile && (
              <Flex px={5} alignItems="center" w="full" mb={9}>
                <SearchBar
                  searchBarRef={searchBarRef}
                  onSearch={(e) => {
                    if (!amplitudeSearched) {
                      amplitude.getInstance().logEvent('Expert search')
                      setAmplitudeSearched(true)
                      console.log('sent event to amplitude: Expert search')
                    }
                    debouncedChangeHandler(e)
                  }}
                  someFiltersSelected={someNonSearchFiltersSelected}
                  setLocalFilters={setLocalFilters}
                  defaultValue={params.get('search')}
                />
                <IconButton
                  ml={6}
                  minW="25px"
                  background={appliedFilters ? 'gray.400' : 'none'}
                  p={1}
                  onClick={openMobileFilters}
                  position="relative"
                >
                  <>
                    <FilterIcon size={25} color="gray.800" />
                    {!!appliedFilters && (
                      <Flex
                        position="absolute"
                        background="orange.400"
                        color="white"
                        w="25px"
                        h="25px"
                        borderRadius="100px"
                        borderColor="white"
                        borderWidth="2px"
                        top="-5px"
                        right="-5px"
                        fontSize="sm"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {appliedFilters}
                      </Flex>
                    )}
                  </>
                </IconButton>
              </Flex>
            )}
          </>

          <Container
            maxW="6xl"
            mt={{ lg: 6, base: 0 }}
            display="flex"
            ref={filtersParentRef}
            position="relative"
            zIndex={1}
          >
            <SideFilters
              ref={filtersRef}
              countries={countries}
              expertiseOptions={options}
              localFilters={localFilters}
              setLocalFilters={setLocalFilters}
              display={{ base: 'none', sm: 'flex' }}
              minH={`calc(100vh - ${
                footerRef?.current?.getBoundingClientRect().height || 0
              }px)`}
              maxH={`max(calc(100vh - ${
                cardsParentRef?.current?.getBoundingClientRect().height <
                window.innerHeight
                  ? cardsParentRef?.current?.getBoundingClientRect().height
                  : 8
              }px),495px)`}
              someFiltersSelected={someFiltersSelected}
              totalText={totalText}
            />
            {isLoading &&
            !appliedFilters &&
            page === 1 &&
            !params.has('search') &&
            cachedResponse.total > 0 ? (
              <CardList
                ref={cardsParentRef}
                experts={normalizedExperts}
                expertBySlug={expertBySlug}
                localFilters={localFilters}
                setLocalFilters={setLocalFilters}
              />
            ) : !data?.items?.length && !experts.length && isLoading ? (
              <Flex w="full" justifyContent="center" my="52px" ref={loaderRef}>
                <Spinner color="blue.300" />
              </Flex>
            ) : normalizedExperts?.length ? (
              <Flex direction="column" w="full">
                <CardList
                  ref={cardsParentRef}
                  experts={normalizedExperts}
                  localFilters={localFilters}
                  expertBySlug={expertBySlug}
                  setLocalFilters={setLocalFilters}
                />
                {seeMoreVisible ? (
                  <>
                    {isLoading ? (
                      <Flex
                        w="full"
                        justifyContent="center"
                        my="52px"
                        ref={loaderRef}
                      >
                        <Spinner color="blue.300" />
                      </Flex>
                    ) : (
                      <Flex justifyContent="center" py="40px">
                        <Button
                          variant="white"
                          onClick={() => {
                            setPage(page + 1)
                          }}
                        >
                          {t('seeMore')}
                        </Button>
                      </Flex>
                    )}
                  </>
                ) : (
                  <Box h="40px"></Box>
                )}
              </Flex>
            ) : (
              <Box w="full">
                <Heading color="gray.800" fontWeight="400" fontSize="lg" mb={8}>
                  {t('experts@notFound')}
                </Heading>
                <Flex justifyContent="center" alignItems="center" w="full">
                  <Image src="/assets/images/expertsEmpty.png" w="540px" />
                </Flex>
              </Box>
            )}
          </Container>

          <Footer ref={footerRef} />
          <Box
            display={{ lg: 'none', base: 'flex' }}
            justifyContent="space-evenly"
            alignItems="center"
            w="100%"
            h="154px"
            mb="84px"
            bg="white"
          >
            <IconButton
              p="0"
              w="42px"
              as="a"
              href="https://www.facebook.com/reArmenia"
              aria-label="facebook"
              icon={<Facebook />}
            />
            <IconButton
              p="0"
              w="42px"
              as="a"
              href="https://www.instagram.com/re_armenia"
              aria-label="instagram"
              icon={<Instagram />}
            />
            <IconButton
              p="0"
              w="42px"
              as="a"
              href="https://t.me/reArmenia_official"
              aria-label="Telegram"
              icon={<Linkedin />}
            />
            <IconButton
              p="0"
              w="42px"
              as="a"
              href="https://twitter.com/re_armenia"
              aria-label="Twitter"
              icon={<Twitter />}
            />
          </Box>
        </Box>
      </MainLayout>
    </>
  )
}
