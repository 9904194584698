import { useEffect } from 'react'
import { Hub } from 'aws-amplify/utils'

export default function useAuthStateChange(callback) {
  useEffect(() => {
    const authListener = Hub.listen('auth', ({ payload }) => {
      const { event } = payload

      if (typeof callback === 'function') {
        callback(event)
      }
    })

    return () => {
      authListener()
    }
  }, [])
}
