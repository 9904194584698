import { CurrencyConverter } from 'contexts/CurrencySettingsContext'

const calcProgress = (amount, goal, currency) => {
  if (!currency) {
    return ((amount ?? 0) * 100) / (goal ?? 1)
  }
  let convertedGoal = CurrencyConverter({
    amount: goal,
    normalize: false,
    currency,
  })
  if (convertedGoal > 1) {
    convertedGoal = Math.round(convertedGoal)
  }
  const dynamicalAmount = ((amount ?? 0) * 100) / (convertedGoal ?? 1)
  return isNaN(dynamicalAmount) ? 0 : dynamicalAmount
}

export default function projectProgress(project, currency) {
  if (project.fundraisingType === 'oneTime' || !project.fundraisingType) {
    const goal =
      project.goals?.length > 0
        ? project.goals?.find((goal) => !goal.isReached) ||
          project.goals[project.goals?.length - 1]
        : { amount: project.goal || project.totalAmount }
    const progress = calcProgress(project?.amount, goal?.amount, currency)
    return {
      amount: project.amount || 0,
      status: project.status,
      goal: goal.amount,
      progress,
    }
  }

  const goal =
    project.goals?.length > 0
      ? project.goals.find((goal) => !goal.isReached) ||
        project.goals[project.goals.length - 1]
      : { amount: 0 }
  const amount = project.amount || 0

  return {
    amount: amount,
    progress: calcProgress(amount, goal?.amount, currency),
    goal: goal.amount || 0,
  }
}
