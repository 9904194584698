import React, { useRef, useState } from 'react'
import {
  Text,
  Button,
  ModalBody,
  ModalHeader,
  Heading,
  ModalFooter,
  ModalCloseButton,
} from '@chakra-ui/react'
import amplitude from 'amplitude-js'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'contexts/TranslationContext'
import { useSignUp, useUser, useValidateEmail } from 'hooks'
import Form from './Form'
import useCustomToast from 'hooks/useCustomToast'

const SignUpModal = ({ setModal }) => {
  const { user } = useUser()
  const toast = useCustomToast()
  const validateEmail = useValidateEmail()
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      subscribeNews: true,
    },
  })
  const { signUp } = useSignUp()
  const { t, language } = useTranslation()
  const password = useRef({})
  password.current = watch('password', '')

  const onSubmit = (data) => {
    const input = {
      ...data,
    }

    if (!validateEmail(input.email)) {
      return toast({
        status: 'error',
        title: 'Invalid email format',
        isClosable: true,
      })
    }

    const createdDate = new Date().toISOString()
    delete input.privacy_policy
    delete input.terms_and_conditions
    delete input.confirm_password
    setLoading(true)
    signUp(
      {
        ...input,
        email: data.email.toLowerCase(),
        createdAt: createdDate,
        updatedAt: createdDate,
        language,
      },
      {
        onSuccess: async () => {
          setLoading(false)

          toast({
            status: 'success',
            title: t('signUp@confirmationEmailMessage'),
            isClosable: true,
            duration: 5000,
          })
          localStorage.setItem('email', data.email.toLowerCase())
          localStorage.setItem('firstName', data.firstName)
          setModal({
            name: 'verify',
            modalProps: {
              email: data.email.toLowerCase(),
              firstName: data.firstName,
            },
          })
          amplitude.getInstance().logEvent('signup-desktop')
          console.log('sent event to amplitude(signup-desktop)')
        },
        onError: (e) => {
          setLoading(false)
          if (e.name === 'InvalidParameterException') {
            return toast({
              status: 'error',
              title: t('signUp@InvalidParameterException'),
              isClosable: true,
              duration: 5000,
            })
          }
          toast({
            status: 'error',
            title: t(`signUp@${e.name}`),
            isClosable: true,
            duration: 5000,
          })
          setLoading(false)
        },
      }
    )
  }

  return (
    <>
      <ModalHeader marginTop="15px">
        <Heading fontSize="3xl" as="h2" fontWeight="extrabold">
          {t('login@signUp')}
        </Heading>
      </ModalHeader>
      <ModalCloseButton
        color="#DDD"
        _active={{ background: 'transparent' }}
        _hover={{ background: 'transparent' }}
        id="signup_modal_close"
      />
      <ModalBody>
        <Form
          loading={loading}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          errors={errors}
          register={register}
          user={user}
        />
        <ModalFooter justifyContent="center" mb="3">
          <Text
            textAlign="center"
            color="gray.800"
            fontSize="sm"
            fontWeight="semibold"
          >
            {t('signUp@haveAnAccount')}{' '}
            <Button
              onClick={() => {
                setModal({ name: 'signIn' })
              }}
              color="orange.400"
              textTransform="initial"
              id="signup_link_signin"
            >
              {t('signUp@signIn')}
            </Button>
          </Text>
        </ModalFooter>
      </ModalBody>
    </>
  )
}

export default SignUpModal
