const needsUseCase = (needsRepo) => {
  const listNeedsCategory = async (input) => {
    const response = await needsRepo.listNeedsCategory(input)
    return response.data.listNeeds?.items
  }

  return {
    listNeedsCategory,
  }
}

export default needsUseCase
