import DraftAppSyncService from './services/DraftAppSyncService'
import draftRepo from './repos/draftRepo'
import draftUseCase from './draftUseCase'

// prettier-ignore
const draft = draftUseCase(
    draftRepo(DraftAppSyncService())
)

export default draft
