import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { ReactComponent as IdramIcon } from 'assets/icons/Idram.svg'
import { ReactComponent as Stripe } from 'assets/icons/stripe.svg'
export default function PoweredBy({ text, project, showStripe, ...props }) {
  return (
    <Flex alignItems="center" justifyContent="center" w="full" {...props}>
      <Text color="gray.600" fontWeight={600} fontSize="md" mr={2}>
        {text}
      </Text>
      <IdramIcon />
      {showStripe && <Stripe />}
    </Flex>
  )
}
