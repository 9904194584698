import { Stack, Text, RadioGroup, Radio } from '@chakra-ui/react'
import { PEER_TO_PEER_OCCASION_ENUMS } from 'constants/fundraiser'
import { useTranslation } from 'contexts/TranslationContext'

export default function Occasion({ value, onChange }) {
  const { t } = useTranslation()

  return (
    <RadioGroup value={Number(value)} onChange={onChange}>
      <Stack spacing={2}>
        <Text variant="body-1" color="gray.800">
          {t('What occasion are you starting the fundraiser for?')}
        </Text>
        <Radio
          value={PEER_TO_PEER_OCCASION_ENUMS.PEER_TO_PEER_OCCASION_BIRTHDAY}
        >
          <Text variant="body-1" color="gray.800">
            {t('Birthday')}
          </Text>
        </Radio>
        <Radio value={PEER_TO_PEER_OCCASION_ENUMS.PEER_TO_PEER_OCCASION_NO_ANY}>
          <Text variant="body-1" color="gray.800">
            {t('Without any occasion')}
          </Text>
        </Radio>
      </Stack>
    </RadioGroup>
  )
}
