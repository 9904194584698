import React from 'react'
import { Button, Heading, Stack } from '@chakra-ui/react'
import { InputField } from 'components/InputField'
import CountrySelect from 'components/CountrySelect'
import { getCountryByNameOrCode } from 'translations'

export default function Personal({
  user,
  register,
  errors,
  setValue,
  getValues,
  isLoading,
  t,
}) {
  return (
    <>
      <Heading lineHeight="base" fontSize="2xl" fontWeight="semibold">
        {t('profile@settings-profile@header')}
      </Heading>
      <Stack pb="12" mt="6" spacing="6" maxW="600px">
        <InputField
          w="321px"
          size="md"
          bg="white"
          labelProps={{ bg: 'transparent', color: 'gray.800' }}
          isInvalid={!!errors.firstName}
          errorMessage={t('Only letters are allowed.')}
          label={t('profile@settings-profile@firstName')}
          name="firstName"
          {...register('firstName', {
            required: true,
            pattern:
              /^(?!\s+$)[a-zA-Z\u0531-\u0556\u0561-\u0587\s\u0400-\u04FF]+$/,
          })}
        />
        <InputField
          w="321px"
          size="md"
          bg="white"
          labelProps={{ bg: 'transparent', color: 'gray.800' }}
          isInvalid={!!errors.lastName}
          errorMessage={t('Only letters are allowed.')}
          label={t('profile@settings-profile@lastName')}
          {...register('lastName', {
            required: true,
            pattern:
              /^(?!\s+$)[a-zA-Z\u0531-\u0556\u0561-\u0587\s\u0400-\u04FF]+$/,
          })}
        />
        <InputField
          w="321px"
          size="md"
          bg="white"
          labelProps={{ bg: 'transparent', color: 'gray.800' }}
          isDisabled
          errorMessage={t('profile@settings-emailErrorMessage')}
          label={t('profile@settings-profile@email')}
          {...register('email', { required: true })}
        />
        <CountrySelect
          w="242px"
          labelProps={{
            fontSize: 'md',
            fontWeight: 400,
            bg: 'transparent',
          }}
          label={t('profile@settings-profile@selectLabel')}
          isInvalid={!!errors.country}
          setValue={setValue}
          countryCode={
            getCountryByNameOrCode({ countryName: user?.country })?.code
          }
          getValues={getValues}
        />
        <Button
          w="331px"
          mt={10}
          textTransform="capitalize"
          isLoading={isLoading}
          type="submit"
          variant="blue"
        >
          {t('profile@settings-save')}
        </Button>
      </Stack>
    </>
  )
}
