import { createContext, useContext } from 'react'
import { createClient } from 'contentful'

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
})

const ContentfulContext = createContext({
  client,
})

export const useContentful = () => useContext(ContentfulContext)

export const ContentfulProvider = ({ children }) => {
  return (
    <ContentfulContext.Provider value={{ client }}>
      {children}
    </ContentfulContext.Provider>
  )
}
