import React from 'react'
import { Box, HStack, Button, Flex, IconButton } from '@chakra-ui/react'
import { Link } from '../../pages'
import { ReactComponent as Logo } from 'assets/icons/logo.svg'
import { ReactComponent as WhiteSearch } from 'assets/icons/whiteSearchSmall.svg'
import { ReactComponent as WhiteBars } from 'assets/icons/bars.svg'
import NotificationsButton from 'components/NotificationsIcon'
import BasketIconButton from 'components/BasketIconButton'

const HomepageNavbar = ({
  padding,
  bgColor,
  logoWidth,
  isGuest,
  onOpen,
  buttonDisplayStatus,
  t,
  user,
}) => (
  <Flex
    position="fixed"
    zIndex="1000"
    top="0"
    left="0"
    flexDirection="column"
    justifyContent="flex-start"
    alignItems="center"
    w="full"
    pt="2"
    transition="0.4s"
    pb={padding}
    bg={bgColor}
  >
    <HStack pt={4} position="absolute" left="12px" display="flex">
      <Link to="/search">
        <IconButton
          minW="unset"
          icon={<WhiteSearch width="24px" height="24px" />}
        />
      </Link>

      <Box display={{ base: 'block', md: 'none' }} mt="1px">
        <BasketIconButton variant="white" />
      </Box>
    </HStack>

    <Box width={logoWidth} transition="0.4s">
      <Logo width="100%" />
    </Box>

    <Flex
      minHeight="36px"
      position="absolute"
      right="12px"
      alignItems="flex-end"
      gap={3}
      pt={4}
    >
      {!isGuest && (
        <>
          <Link to="/profile/notifications">
            <NotificationsButton
              whiteVersion
              unreadNotifications={user?.unreadNotifications}
            />
          </Link>
          <Box display={{ base: 'none', md: 'block' }}>
            <BasketIconButton variant="white" />
          </Box>
        </>
      )}
      <IconButton
        minW="unset"
        icon={<WhiteBars width="24px" height="24px" />}
        onClick={onOpen}
      />
    </Flex>

    {isGuest && (
      <Box display={buttonDisplayStatus} w="100%" py={3.5} px={14} bg="white">
        <Link to="/sign-up">
          <Button variant="green" w="281px" textTransform="none">
            {t('homepage@header@button')}
          </Button>
        </Link>
      </Box>
    )}
  </Flex>
)

export default HomepageNavbar
