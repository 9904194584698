import React, { useEffect } from 'react'
import { Flex, Container, Text, Box } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { MainLayout } from 'components/Layouts'
import { Footer } from 'components/Footer'
import { ReactComponent as Reached } from 'assets/icons/reachedBig.svg'
import { ReactComponent as Close } from 'assets/icons/closeBlack.svg'
import { ReactComponent as Logo } from 'assets/icons/logoA.svg'
import { useNavigation } from 'pages'
import amplitude from 'amplitude-js'
import { useScreenSize } from 'contexts'

const ThankYouBasketSubscription = () => {
  const { t } = useTranslation()
  const { isMobile } = useScreenSize()
  // const { projects, isLoading, setFilters } = useProjects(
  //   similarProjectsQuery({
  //     category: project?.category,
  //     region: project?.region,
  //   }),
  //   true,
  //   'similar_fundraisers',
  //   'similar_projects_fundraisers'
  // )

  // useEffect(() => {
  //   if (!project) {
  //     return
  //   }
  //   setFilters(
  //     similarProjectsQuery({
  //       category: project?.category,
  //       region: project?.region,
  //       projectId: params.get('id'),
  //       isCollaboration,
  //     })
  //   )
  // }, [project])

  const { navigationPush } = useNavigation()

  useEffect(() => {
    amplitude.getInstance().logEvent('ThankYou  page view')
    console.log('sent event to amplitude: ThankYou  page view')
  }, [])

  return (
    <MainLayout>
      <Box display={{ base: 'flex', sm: 'none' }} py="0.375rem" pl="3" w="full">
        <Flex
          onClick={() => navigationPush('/')}
          w="30px"
          h="30px"
          align="center"
          justify="center"
        >
          <Close width="14px" height="14px" />
        </Flex>
      </Box>
      <Container
        mt={{ base: 'none', sm: '72px' }}
        display="flex"
        alignItems="center"
        flexDir="column"
        maxW="750px"
      >
        <Reached minH="218px" />
        <Text
          fontSize={{ base: '4xl', sm: '6xl' }}
          textAlign="center"
          fontWeight={{ base: 700, sm: 500 }}
          mt={4}
          mb={{ base: 8, sm: 6 }}
        >
          {t('thankyou@title')}
        </Text>
        <Text mt={2} textAlign="center" fontSize="md">
          {t(
            'Your monthly subscriptions are now active. Check your inbox for a confirmation email with full details.'
          )}
        </Text>
        <Box
          w="full"
          h="1px"
          bg="gray.400"
          my={6}
          display={{ base: 'block', sm: 'none' }}
        />
      </Container>
      {/* <SimilarProjects
        needCategories={needCategories}
        projects={projects?.items || []}
        isLoading={isLoading}
        isContribution={isContribution}
        isCollaboration={isCollaboration}
      /> */}
      <Flex
        px={{ base: 5, xl: 100 }}
        py={{ base: 5, sm: 10 }}
        position="relative"
        mt={{ base: 12, sm: '130px' }}
        mb={{ base: 10, sm: '176px' }}
        bg="gray.200"
      >
        <Flex flexDir="column" maxW="650px">
          <Text fontSize="6xl" fontWeight={500} mb="8">
            {t('thankYou@thankYouAboutReArmenia')}
          </Text>
          <Text lineHeight="32px">
            {t('thankYou@thankYouAboutReArmeniaText1')}
            <br />
            <br />
            {t('thankYou@thankYouAboutReArmeniaText2')}
          </Text>
        </Flex>
        <Flex
          position="absolute"
          top="-57px"
          right="164px"
          display={{ base: 'none', xl: 'block' }}
        >
          <Logo />
        </Flex>
      </Flex>
      {!isMobile && <Footer />}
    </MainLayout>
  )
}

export default ThankYouBasketSubscription
