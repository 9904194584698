import { Flex, Spinner } from '@chakra-ui/react'
import Report from 'pages/Project/Report'
import useGetReportsByProjectId from 'hooks/report/useGetProjectReports'
import { useGetProject, useUser } from 'hooks'
import { useHistory } from 'react-router-dom'

export default function Reports() {
  const { location } = useHistory()
  const projectId = location.pathname.split('/').at(-2)
  const { data: project } = useGetProject({ projectId })
  const {
    data: reports,
    refetch,
    isLoading,
  } = useGetReportsByProjectId({
    projectId,
    sortDirection: 'DESC',
  })
  const { user } = useUser()
  if (isLoading) {
    return (
      <Flex minH="520px" w="full" align="center" justifyContent="center">
        <Spinner color="blue.300" />
      </Flex>
    )
  }

  return (
    <Report
      showAvatar={false}
      user={user}
      refetchReports={refetch}
      reports={reports}
      project={project}
      isReportsLoading={isLoading}
    />
  )
}
