import { useEffect, useState } from 'react'
import { TabList, Tab, Tabs, Box } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useQueryParams } from 'hooks'

export const StyledTab = ({
  tabKey,
  title,
  setFilters,
  filters,
  mobile,
  ...props
}) => {
  const queryParams = useQueryParams()
  const { t } = useTranslation()
  const isActiveTab = filters?.type === tabKey

  if (mobile) {
    return (
      <Box
        mb="2"
        mr={2}
        bg={isActiveTab ? 'blue.300' : 'transparent'}
        fontSize="md"
        border="1px solid"
        borderRadius="12px"
        py={3}
        px={7}
        borderColor={isActiveTab ? 'blue.300' : 'border.100'}
        as="span"
        onClick={() => {
          if (queryParams.get('type') !== tabKey) {
            queryParams.set('type', tabKey)
            setFilters({ ...filters, type: tabKey })
          }
        }}
        {...props}
        color={isActiveTab ? 'white' : 'gray.800'}
      >
        {t(title)}
      </Box>
    )
  }

  return (
    <Tab
      _active={{ background: 'initial' }}
      _selected={{
        fontWeight: 'extrabold',
        color: 'blue.300',
        borderColor: 'blue.300',
      }}
      onClick={() => {
        if (queryParams.get('type') !== tabKey) {
          queryParams.set('type', tabKey)
          setFilters({ ...filters, type: tabKey })
        }
      }}
      fontWeight="semibold"
      color="gray.700"
      fontSize="lg"
      py="22px"
      px={8}
      {...props}
    >
      {t(title)}
    </Tab>
  )
}

export default function FundTabs({ setFilters, filters }) {
  const queryParams = useQueryParams()
  const [tabIndex, setTabIndex] = useState(0)
  const tabs = {
    oneTime: 'oneTime',
    recurring: 'recurring',
    'peer-to-peer': 'Peer-to-peer',
    'non-profit': 'Nonprofits',
    individuals: 'individuals',
  }

  useEffect(() => {
    if (filters.type) {
      setTabIndex(Object.keys(tabs).findIndex((tab) => tab === filters.type))
    }
    if (!queryParams.has('type')) {
      setFilters({ ...filters, type: Object.keys(tabs)[tabIndex] })
    }
  }, [])

  return (
    <Tabs height="100%" index={tabIndex} onChange={setTabIndex}>
      <TabList borderBottom="none">
        {Object.entries(tabs).map(([tabKey, tabTitle], index) => (
          <StyledTab
            key={tabKey + index}
            filters={filters}
            setFilters={setFilters}
            tabKey={tabKey}
            title={tabTitle}
          />
        ))}
      </TabList>
    </Tabs>
  )
}
