import { useMutation } from 'react-query'
import { authUseCase } from '../../core/factories'

const useConfirmSignUp = () => {
  const {
    mutate: confirmSignUp,
    isLoading,
    data,
    error,
  } = useMutation(authUseCase.confirmSignUp)

  return {
    confirmSignUp,
    isLoading,
    data,
    error,
  }
}

export default useConfirmSignUp
