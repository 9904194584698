import moment from 'moment'

const updateUseCase = (updateRepo) => {
  const saveProjectUpdates = async ({
    updates,
    editedUpdates,
    deletedUpdates,
    createdUpdates,
    projectId,
    updatesData,
  }) => {
    if (
      !deletedUpdates.current.length &&
      !editedUpdates.current.length &&
      !createdUpdates.current.length
    ) {
      throw new Error('Nothing to update')
    }
    if (!updatesData) {
      return await Promise.all(
        updates.map(({ ...update }) => {
          return updateRepo.createUpdate({
            projectId,
            ...update,
          })
        })
      )
    }
    if (deletedUpdates.current) {
      await Promise.all(
        deletedUpdates.current.map((id) => updateRepo.deleteUpdate(id))
      )
    }
    if (editedUpdates.current) {
      await Promise.all(
        editedUpdates.current.map((id) =>
          updateRepo.editUpdate({
            id,
            ...updates.find((update) => update.id === id),
          })
        )
      )
    }
    if (createdUpdates.current) {
      await Promise.all(
        createdUpdates.current.map((id) => {
          return updateRepo.createUpdate({
            projectId,
            ...updates.find((update) => update.id === id),
          })
        })
      )
    }

    return { success: true }
  }

  const listUpdatesByDate = async () => {
    const updatesResponse = await updateRepo.listUpdatesByDate()
    return updatesResponse.data.updatesByHasImage.items
  }

  const getUpdatesByProjectId = async (variables) => {
    if (!variables.projectId) {
      return []
    }
    const response = await updateRepo.getUpdatesByProjectId(variables)
    if (!response.data.updatesByProjectId.items.length) return null
    return response.data.updatesByProjectId.items.map((update) => ({
      ...update,
      formattedDate: moment(update.createdAt).format('MMMM DD, YYYY'),
      fromNow: moment(update.createdAt).fromNow(),
    }))
  }

  const deleteUpdate = (input) => updateRepo.deleteUpdate(input)

  const editUpdate = (input) => {
    if (
      input.data_en.includes('<img') &&
      input.data_ru.includes('<img') &&
      input.data_hy.includes('<img')
    ) {
      input = { ...input, hasImage: 'true' }
    } else {
      input = { ...input, hasImage: 'false' }
    }
    updateRepo.editUpdate(input)
  }

  const createUpdate = (input) => {
    if (
      input.data_en.includes('<img') &&
      input.data_ru.includes('<img') &&
      input.data_hy.includes('<img')
    ) {
      input = { ...input, hasImage: 'true' }
    } else {
      input = { ...input, hasImage: 'false' }
    }
    return updateRepo.createUpdate(input)
  }

  const editUpdateWithDate = async ({ id, ...input }) => {
    if (
      input.data_en.includes('<img') &&
      input.data_ru.includes('<img') &&
      input.data_hy.includes('<img')
    ) {
      input = { ...input, hasImage: 'true' }
    } else {
      input = { ...input, hasImage: 'false' }
    }

    const createdUpdate = await updateRepo.createUpdate({ id, ...input })

    return createdUpdate
  }

  return {
    createUpdate,
    editUpdate,
    deleteUpdate,
    listUpdatesByDate,
    getUpdatesByProjectId,
    editUpdateWithDate,
    saveProjectUpdates,
  }
}

export default updateUseCase
