import {
  Container,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import COUNTRY_LIST from 'translations/countries.json'
import { ReactComponent as Back } from 'assets/icons/back.svg'
import { MainLayout } from 'components/Layouts'
import {
  LANG_EN_SHORT,
  LANG_HY_SHORT,
  LANG_RU_SHORT,
  useTranslation,
} from 'contexts/TranslationContext'
import {
  useGetExpertById,
  useGetExpertsCategories,
  useUpdateContentItem,
} from 'core/Content/hooks'
import {
  useCustomToast,
  useObjectManipulations,
  useSessionStorage,
  useUser,
} from 'hooks'
import { useNavigation } from 'pages'

import ExpertProfileForm from 'pages/ExpertProfile/ExpertProfileForm'
import React, { useCallback, useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { InputField } from 'components/InputField'

export default function EditExpert() {
  const toast = useCustomToast()
  const { t, language } = useTranslation()
  const [tabIndex, setTabIndex] = useState(0)
  const [localLang, setLocalLang] = useState(null)
  const { get, put } = useSessionStorage()
  const [formAutoSave, setFormAutoSave] = useState({})
  const { user } = useUser()
  const { navigationPush } = useNavigation()
  const { deleteKey } = useObjectManipulations()
  const { id } = useParams()
  const { data: expert, refetch } = useGetExpertById(id)
  const { mutate: updateExpert, isLoading: isExpertUpdateLoading } =
    useUpdateContentItem()
  const [tabs, setTabs] = useState(['English', 'Armenian', 'Russian'])
  const [langs, setLangs] = useState([
    LANG_EN_SHORT,
    LANG_HY_SHORT,
    LANG_RU_SHORT,
  ])

  const [options, setOptions] = useState([])
  const { data: categories } = useGetExpertsCategories()

  const langLongForms = {
    [LANG_EN_SHORT]: 'English',
    [LANG_HY_SHORT]: 'Armenian',
    [LANG_RU_SHORT]: 'Russian',
  }

  const {
    register,
    trigger,
    resetField,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    getValues,
    control,
    setError,
    clearErrors,
    reset,
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: Object.keys(formAutoSave).length
      ? formAutoSave
      : {
          country: COUNTRY_LIST[7],
          countryIndex: 7,
          isPrivate: 'false',
          spheres: [],
          interestedIn: [],
          phone: expert?.phone,
          email: user.email,
          firstName_en: expert?.firstName_en || user.firstName,
          firstName_ru: expert?.firstName_ru || user.firstName,
          firstName_hy: expert?.firstName_hy || user.firstName,
          lastName_en: expert?.lastName_en || user.lastName,
          lastName_ru: expert?.lastName_ru || user.lastName,
          lastName_hy: expert?.lastName_hy || user.lastName,
          getInvolvedStatus: ['Volunteer'],
          socialLinks: expert?.socialLinks || [],
          socials: [
            {
              url: '',
              added: false,
            },
          ],
        },
  })
  const spheresArray = watch('spheres')

  const {
    fields: socials,
    append: appendSocial,
    remove: removeSocial,
    update: updateSocial,
  } = useFieldArray({
    control,
    name: 'socials',
    rules: {
      required: true,
    },
  })

  const noSocials = (socials) => {
    if (socials.length === 0 || (socials.length === 1 && !socials[0].url)) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (expert?.socialLinks.length > 0 && noSocials(socials)) {
      const newSocialsValue = expert?.socialLinks.map((url) => ({
        url,
        added: true,
      }))
      const resetAsync = async () =>
        await reset({
          socials: newSocialsValue,
        })
      resetAsync().then((e) => {
        console.log(e)
        resetField('socials', {
          defaultValue: newSocialsValue,
        })
      })
    }
  }, [expert])

  const {
    fields: spheres,
    append: appendSpheres,
    remove: removeSpheres,
    update: updateSpheres,
  } = useFieldArray({
    control,
    name: 'spheres',
    rules: {
      required: true,
    },
  })

  const {
    fields: customSpheres,
    append: appendCustomSpheres,
    remove: removeCustomSpheres,
    update: updateCustomSpheres,
  } = useFieldArray({
    control,
    name: 'customSpheres',
  })

  const validate = () => {
    let error = false
    const hrsPerWeek = getValues('hrsPerWeek')
    const selectedLanguage = getValues('selectedLanguage')
    const interestedIn = getValues('interestedIn')
    const imageUrl = getValues('imageUrl')

    clearErrors(['hrsPerWeek', 'spheres', 'selectedLanguage,interestedIn'])
    if (
      !spheresArray.every(
        (sphere) => sphere[`title_${localLang || language}`]?.length
      )
    ) {
      setError('spheres')
      error = true
    }
    if (!selectedLanguage?.length) {
      setError('selectedLanguage')
      error = true
    }
    if (!imageUrl) {
      setError('imageUrl')
      error = true
    }
    if (!hrsPerWeek?.length) {
      setError('hrsPerWeek')
      error = true
    }
    if (!interestedIn?.length) {
      setError('interestedIn')
      error = true
    }

    return error
  }
  const clearForm = useCallback(() => {}, [expert])
  useEffect(() => {
    if (!user.isAdmin) {
      navigationPush('/')
    }
  }, [user])
  const onSubmit = (data) => {
    let input = data
    input.isVolunteer = true
    input.getInvolvedStatus = 'Volunteer'
    input.interestedIn = data.interestedIn.map((interest) => ({
      interested_in: interest,
    }))
    input.spheres = [
      ...spheresArray,
      ...customSpheres.filter(({ added }) => added),
    ].map(({ title_en, title_ru, title_hy }) => ({
      title_en,
      title_ru: title_ru || title_en,
      title_hy: title_hy || title_en,
    }))

    input.selectedLanguage = data.selectedLanguage.map((language) => ({
      language,
      language_other: '',
    }))

    input.socialLinks = socials
      .filter(({ added }) => added)
      .map(({ url }) => url)
    if (input.socialLinks.length === 0) {
      setError('socials', { type: 'custom', message: 'Empty urls' })
      return
    }

    input.publishedAt = new Date().toISOString()

    if (
      input.description_en &&
      input.firstName_en &&
      input.lastName_en &&
      input.title_en
    ) {
      input.isEngFilled = true
    }
    if (
      input.description_hy &&
      input.firstName_hy &&
      input.lastName_hy &&
      input.title_hy
    ) {
      input.isArmFilled = true
    }
    if (
      input.description_ru &&
      input.firstName_ru &&
      input.lastName_ru &&
      input.title_ru
    ) {
      input.isRusFilled = true
    }
    input.language = language || LANG_EN_SHORT
    input.countryCode = input.country?.code
    input.pk = 'expert'
    removeExtraNames(input)
    delete input.country
    delete input.customSpheres
    delete input.countryIndex
    delete input.socials
    deleteKey(input, '__typename')

    input.id = expert.id
    if (expert.expert_status !== 'approved') {
      input.expert_status = 'pending'
    }
    updateExpert(input, {
      onSuccess(res) {
        console.log(res)
        refetch()
        toast({
          status: 'success',
          title: t('expert@successUpdateToast'),
        })
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      },
    })
    for (let i = customSpheres.length - 1; i >= 0; i--) {
      removeCustomSpheres(i)
    }
  }
  const onTabChange = (e) => {
    let updateLang = tabs[e]
    if (updateLang === 'English') {
      updateLang = LANG_EN_SHORT
    }
    if (updateLang === 'Armenian') {
      updateLang = LANG_HY_SHORT
    }
    if (updateLang === 'Russian') {
      updateLang = LANG_RU_SHORT
    }
    setFormAutoSave(watch())
    setLocalLang(updateLang)
    setTabIndex(e)
  }

  const removeExtraNames = (input) => {
    const { isEngFilled, isRusFilled, isArmFilled } = input
    if (!isEngFilled) {
      delete input.firstName_en
      delete input.lastName_en
    }
    if (!isRusFilled) {
      delete input.firstName_ru
      delete input.lastName_ru
    }
    if (!isArmFilled) {
      delete input.firstName_hy
      delete input.lastName_hy
    }
  }

  useEffect(() => {
    const cachedLang = get('language_expert_page')
    if (!!cachedLang && cachedLang !== language) {
      window.location.reload()
    }
    put('language_expert_page', language)
  }, [language])

  useEffect(() => {
    if (!expert) {
      return
    }
    let lang = LANG_EN_SHORT
    if (expert.isArmFilled) {
      lang = LANG_HY_SHORT
    }
    if (expert.isRusFilled) {
      lang = LANG_RU_SHORT
    }
    if (expert.isEngFilled) {
      lang = LANG_EN_SHORT
    }
    setTabs(
      tabs.sort((a, b) => {
        if (b === langLongForms[language]) {
          return 1
        }
        if (a === langLongForms[language]) {
          return -1
        }
        const aFilled =
          (a === 'Armenian' &&
            expert.isArmFilled !== null &&
            expert.isArmFilled) +
          (a === 'Russian' &&
            expert.isRusFilled !== null &&
            expert.isRusFilled) +
          (a === 'English' && expert.isEngFilled !== null && expert.isEngFilled)
        const bFilled =
          (b === 'Armenian' &&
            expert.isArmFilled !== null &&
            expert.isArmFilled) +
          (b === 'Russian' &&
            expert.isRusFilled !== null &&
            expert.isRusFilled) +
          (b === 'English' && expert.isEngFilled !== null && expert.isEngFilled)
        return bFilled - aFilled
      })
    )
    setLangs(
      langs.sort((a, b) => {
        if (b === language) {
          return 1
        }
        if (a === language) {
          return -1
        }
        const aFilled =
          (a === LANG_HY_SHORT &&
            expert.isArmFilled !== null &&
            expert.isArmFilled) +
          (a === LANG_RU_SHORT &&
            expert.isRusFilled !== null &&
            expert.isRusFilled) +
          (a === LANG_EN_SHORT &&
            expert.isEngFilled !== null &&
            expert.isEngFilled)
        const bFilled =
          (b === LANG_HY_SHORT &&
            expert.isArmFilled !== null &&
            expert.isArmFilled) +
          (b === 'Russian' &&
            expert.isRusFilled !== null &&
            expert.isRusFilled) +
          (b === LANG_EN_SHORT &&
            expert.isEngFilled !== null &&
            expert.isEngFilled)
        return bFilled - aFilled
      })
    )
    setLocalLang(lang)
    const getInvolvedStatus = ['Volunteer']
    reset({
      ...expert,
      selectedLanguage: expert.selectedLanguage?.map(
        ({ language }) => language
      ),
      spheres: expert.spheres,
      email: expert.email,
      interestedIn: expert.interestedIn.map(
        ({ interested_in }) => interested_in
      ),
      country: COUNTRY_LIST.find(({ code }) => code === expert.countryCode),
      countryIndex: COUNTRY_LIST.findIndex(
        ({ code }) => code === expert.countryCode
      ),
      isPrivate: String(expert.isPrivate),
      getInvolvedStatus,
    })
  }, [expert, user])
  useEffect(() => {
    if (!categories?.length) {
      return
    }
    setOptions(categories)
  }, [categories])
  return (
    <MainLayout>
      <Container mt={8} mb="500px" maxW="800px">
        <IconButton
          onClick={() => {
            navigationPush('/experts-dashboard')
          }}
        >
          <Back />
        </IconButton>
        {!!expert && (
          <>
            <InputField
              name="Sequence"
              label={t('Sequence')}
              placeholder={t('Sequence...')}
              type="number"
              min={0}
              {...register('sequence')}
            />
            <Tabs
              height="100%"
              index={tabIndex}
              onChange={onTabChange}
              as="form"
              onSubmit={handleSubmit(onSubmit)}
              isLazy
            >
              <TabList borderBottom="1px solid #DDD">
                {tabs.map((tabTitle, index) => (
                  <Tab
                    key={`tabTitle-${index}`}
                    _active={{ background: 'initial' }}
                    _selected={{
                      color: 'black',
                      borderColor: 'blue.300',
                    }}
                    color="gray.700"
                    fontSize="md"
                    minW="85px"
                    p={4}
                    fontWeight={500}
                  >
                    {t(`expert@form@${tabTitle}`)}
                  </Tab>
                ))}
              </TabList>
              <TabPanels px={4}>
                {tabs.map((languageCode, index) => (
                  <TabPanel
                    tabIndex={index}
                    key={`project-content-tab-panel-${languageCode}`}
                    p={0}
                  >
                    <ExpertProfileForm
                      clearErrors={clearErrors}
                      register={register}
                      setValue={setValue}
                      errors={errors}
                      expert={expert}
                      watch={watch}
                      getValues={getValues}
                      control={control}
                      language={langs[index]}
                      isSubmitLoading={isExpertUpdateLoading}
                      validate={validate}
                      clearForm={clearForm}
                      isDisabled={isExpertUpdateLoading}
                      expertiseOptions={options}
                      spheresArray={spheresArray}
                      {...{
                        socials,
                        trigger,
                        watch,
                        clearErrors,
                        register,
                        setValue,
                        clearForm,
                        control,
                        expert,
                        getValues,
                        errors,
                        appendSocial,
                        removeSocial,
                        updateSocial,
                        customSpheres,
                        appendCustomSpheres,
                        removeCustomSpheres,
                        updateCustomSpheres,
                        spheres,
                        appendSpheres,
                        removeSpheres,
                        updateSpheres,
                        spheresArray,
                      }}
                    />
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </>
        )}
      </Container>
    </MainLayout>
  )
}
