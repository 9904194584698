import React, { useState, useRef, useEffect } from 'react'
import {
  InputGroup,
  Input,
  InputRightElement,
  Box,
  FormControl,
  FormLabel,
  Text,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'

export default function RequestedAmount({ errors, register, setValue }) {
  const { t, language } = useTranslation()
  const inputRightElementRef = useRef(null)
  const [inputPaddingRight, setInputPaddingRight] = useState(0)
  useEffect(() => {
    if (!inputRightElementRef.current) return
    setInputPaddingRight(inputRightElementRef.current.clientWidth + 5)
  }, [inputRightElementRef, language])

  return (
    <FormControl id="requested-amount" position="relative">
      <FormLabel fontSize="xl" fontWeight="700" zIndex={2}>
        {t('createEditProject@requestedAmount')}
        <Text fontSize="sm" fontWeight={400} mt="4px" mb="16px">
          {t('createEditProject@requestedAmountText')}
        </Text>
      </FormLabel>
      <InputGroup>
        <Input
          paddingRight={`${inputPaddingRight}px`}
          _focus={{ borderColor: 'blue.400' }}
          _hover={{ borderColor: 'blue.400' }}
          borderColor="border.100"
          borderWidth="1px"
          sx={{ boxShadow: 'none !important' }}
          pattern="[0-9,]*"
          type="text"
          isInvalid={!!errors.requestedAmount}
          {...register('requestedAmount', {
            required: true,
            onChange: (e) => {
              const sanitizedValue = e.target.value.replace(/[^0-9]/g, '')
              setValue('requestedAmount', sanitizedValue)
            },
          })}
        />
        <InputRightElement
          ref={inputRightElementRef}
          w="auto"
          pointerEvents="none"
          color="black"
          pr={6}
          children={t('currency@amd')}
        />
        {!!errors.requestedAmount && (
          <Box
            top="calc(100% + 5px)"
            fontSize="sm"
            fontWeight="semibold"
            position="absolute"
            color="red.400"
          >
            {t('createEditProject@thisFieldIsRequired')}
          </Box>
        )}
      </InputGroup>
      <Text fontWeight={600} fontSize="md" mt={6}>
        {t('createEditProject@requestedAmountText2')}
      </Text>
    </FormControl>
  )
}
