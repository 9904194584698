import { MainLayout } from 'components/Layouts'
import { TransactionsProvider } from 'contexts'
import { useGetProject, useUser } from 'hooks'
import { useNavigation } from 'pages'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import TransactionList from './TransactionList'
import { Container, Text } from '@chakra-ui/react'

export default function TransactionDashboard() {
  const { user } = useUser()
  const { projectId } = useParams()
  const { navigationPush } = useNavigation()
  const { data: project } = useGetProject({ projectId })

  useEffect(() => {
    if (!user.isAdmin || !projectId) {
      navigationPush('/')
    }
  }, [user, projectId])

  if (!project) {
    return null
  }

  return (
    <MainLayout>
      <Container mt={8}>
        <Text>Transactions for {projectId}</Text>
        <TransactionsProvider project={project}>
          <TransactionList />
        </TransactionsProvider>
      </Container>
    </MainLayout>
  )
}
