import { Button, Flex, HStack, Img, Stack, Text } from '@chakra-ui/react'

export default function Variant1({
  logo,
  heading,
  description,
  buttonText,
  isLoading,
  onClick,
}) {
  return (
    <HStack
      p={6}
      borderRadius="16px"
      borderWidth={1}
      borderColor="gray.600"
      spacing={4}
    >
      <HStack spacing={4}>
        <Img
          src={`/assets/images/${logo}`}
          alt="Logo"
          w="44px"
          objectFit="contain"
        />
        <Stack spacing={1}>
          <Text as="span" fontWeight="500">
            {heading}
          </Text>
          <Text fontSize="md" color="gray.700">
            {description}
          </Text>
        </Stack>
      </HStack>
      <Flex w="150px" justifyContent="flex-end">
        <Button
          variant="border"
          size="sm"
          minH="33px"
          isDisabled={isLoading}
          isLoading={isLoading}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      </Flex>
    </HStack>
  )
}
