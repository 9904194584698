import { useQuery } from 'react-query'
import { reportUseCase } from '../../core/factories'

const useGetReportsByProjectId = (variables) => {
  const { error, refetch, isLoading, data } = useQuery(
    ['reports', variables],
    () => reportUseCase.getReportsByProjectId(variables)
  )

  return {
    error,
    refetch,
    isLoading,
    data,
  }
}

export default useGetReportsByProjectId
