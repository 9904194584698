import React from 'react'
import { Heading, Box, HStack, Button } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import BlogCard from 'components/BlogCard'
import { useListBlog } from 'hooks'
import { Link } from 'pages'

const Blog = () => {
  const { t, language } = useTranslation()
  const { data } = useListBlog()

  if (!data || data[`items_${language}`].length === 0) {
    return <></>
  }

  return (
    <Box mt="80px">
      <Heading px="5" as="h2" mb="6" fontSize="1.625rem">
        {t('blog')}
      </Heading>
      <HStack
        alignItems="baseline"
        overflowX="scroll"
        flexWrap="nowrap"
        pl="5"
        pr="10"
        mb={5}
        position="relative"
        spacing="5"
      >
        {data[`items_${language}`].slice(0, 3).map((item, index) => (
          <Box key={`homepage-blog-mobile-${item.id}-${index}`} minW="280px">
            <BlogCard card={item} />
          </Box>
        ))}
      </HStack>
      <Link to="/blog">
        <Button ml="5" color="blue.300" fontWeight="600" variant="transparent">
          {t('See all')}
        </Button>
      </Link>
    </Box>
  )
}

export default Blog
