import {
  listUpdates as listProjectUpdates,
  updatesByProjectId,
  updatesByHasImage as updatesByHasImageQuery,
} from '../../graphql/queries'
import {
  createUpdate as createProjectUpdate,
  deleteUpdate as deleteProjectUpdate,
  updateUpdate as updateProjectUpdate,
} from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api'
const client = generateClient()

const updateService = () => {
  const createUpdate = async (input) => {
    await client.graphql({
      query: createProjectUpdate,
      variables: {
        input: { ...input, type: 'update' },
      },
    })
  }

  const deleteUpdate = async (input) => {
    await client.graphql({ query: deleteProjectUpdate, variables: { input } })
  }

  const editUpdate = async (input) => {
    await client.graphql({ query: updateProjectUpdate, variables: { input } })
  }

  const listUpdates = async () =>
    client.graphql({ query: listProjectUpdates, variables: { limit: 10 } })

  const listUpdatesByDate = () =>
    client.graphql({
      query: updatesByHasImageQuery,
      variables: {
        limit: 10,
        sortDirection: 'DESC',
        hasImage: 'true',
      },
    })

  const getUpdatesByProjectId = async (variables) => {
    const projects = await client.graphql({
      query: updatesByProjectId,
      variables,
    })
    return projects
  }

  return {
    createUpdate,
    deleteUpdate,
    editUpdate,
    listUpdates,
    getUpdatesByProjectId,
    listUpdatesByDate,
  }
}

export default updateService
