import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import { InputField } from 'components/InputField'

//** Icons
import { GetSocialMediaIcon } from './GetSocialMediaIcon'
import { useState } from 'react'
import { DisabledBox } from 'components/DisabledBox'

export const EditSocialPopover = ({
  tt,
  update,
  remove,
  url,
  getValues,
  index,
  isDisabled,
  language,
}) => {
  const [value, setValue] = useState(url)

  return (
    <Popover isLazy onClose={() => setValue(getValues(`socials.${index}.url`))}>
      {({ isOpen }) => (
        <Box pos="relative">
          {isDisabled && <DisabledBox />}
          <PopoverTrigger>
            <Flex
              as="button"
              type="button"
              cursor="pointer"
              w={12}
              h={10}
              align="center"
              justify="center"
              p={2}
              border="1px solid"
              borderColor="border.400"
              borderRadius={8}
              bg={isOpen ? '#EAECEF' : 'white'}
            >
              <GetSocialMediaIcon url={url} />
            </Flex>
          </PopoverTrigger>
          <PopoverContent
            border="none"
            minW={{ base: '277px', sm: 'max-content' }}
            w={{ sm: '277px' }}
          >
            <PopoverBody
              display="flex"
              direction="column"
              p="16px !important"
              border="1px solid !important"
              borderColor="border.400 !important"
              borderRadius={8}
            >
              <Flex direction="column" w="full">
                <Text fontSize="lg" color="gray.800" fontWeight={700} mb={2}>
                  {tt('Edit the link', language)}
                </Text>

                <InputField
                  mb={4}
                  value={value}
                  defaultValue={getValues(`socials.${index}.url`)}
                  onChange={(e) => setValue(e.target.value)}
                />
                <Button
                  w="full"
                  variant="blue"
                  onClick={() =>
                    value
                      ? update(index, {
                          url: value,
                          added: true,
                        })
                      : remove(index)
                  }
                >
                  {tt('Confirm', language)}
                </Button>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Box>
      )}
    </Popover>
  )
}
