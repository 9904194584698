import React, { useEffect, useRef, useState } from 'react'
import {
  Flex,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Input,
  Box,
  useDisclosure,
} from '@chakra-ui/react'
import Filter from './Filter'
import { ReactComponent as Back } from '../../../assets/icons/back.svg'
import { ReactComponent as Search } from '../../../assets/icons/blackSearch.svg'
import { ReactComponent as Close } from '../../../assets/icons/closeBlack.svg'
import { ReactComponent as FilterIcon } from '../../../assets/icons/filterMobile.svg'
import { useNavigation } from 'pages'

const Header = ({ filters, setFilters }) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { navigationPush } = useNavigation()

  const [text, setText] = useState(filters.text)
  useEffect(() => {
    setText(filters.text)
  }, [filters.text])

  const refTimer = useRef()
  const onSearchChange = (event) => {
    setText(event.target.value)
    clearTimeout(refTimer.current)

    refTimer.current = setTimeout(() => {
      setFilters({ ...filters, text: event.target.value })
    }, 300)
  }

  return (
    <>
      <Filter
        filters={filters}
        setFilters={setFilters}
        isOpen={isOpen}
        onClose={onClose}
      />
      <Flex
        py="12px"
        pl="3"
        pr="3"
        mb="6"
        borderBottom="1px solid"
        borderColor="border.100"
        alignItems="center"
      >
        <Flex
          mr="3"
          h="30px"
          w="30px"
          onClick={() => navigationPush('/')}
          alignItems="center"
          justifyContent="center"
        >
          <Back />
        </Flex>
        <InputGroup size="sm" borderRadius="8px" overflow="hidden" bg="#ebebeb">
          <InputLeftElement children={<Search />} />
          <Input
            fontSize="lg"
            value={text}
            autoFocus
            onChange={onSearchChange}
            border="none"
          />
          <InputRightElement
            onClick={() => setFilters({ ...filters, text: '' })}
            display={filters?.text?.length > 0 ? 'flex' : 'none'}
            children={<Close height="10px" width="10px" />}
          />
        </InputGroup>
        <Box position="relative" onClick={onOpen} ml="3">
          <FilterIcon />
          {(!!filters?.region || !!filters.category) && (
            <Flex
              left="-5px"
              bottom="-5px"
              as="span"
              alignItems="center"
              justifyContent="center"
              position="absolute"
              borderRadius="100px"
              height="15px"
              width="15px"
              fontWeight="700"
              fontSize="sm"
              bg="blue.300"
              color="white"
            >
              {!!filters?.region && !!filters.category
                ? '2'
                : !!filters?.region || !!filters.category
                ? '1'
                : ''}
            </Flex>
          )}
          <Filter />
        </Box>
      </Flex>
    </>
  )
}

export default Header
