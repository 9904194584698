import {
  confirmSignUp,
  resendSignUpCode,
  signIn,
  resetPassword,
  confirmResetPassword,
  fetchAuthSession,
  signInWithRedirect,
  getCurrentUser,
  signUp,
  autoSignIn,
} from 'aws-amplify/auth'
import { post } from 'aws-amplify/api'
import { getResponseToJSON } from 'hooks/useResponseManipulations'
import { LANG_EN_SHORT } from 'contexts/TranslationContext'

const authService = () => {
  const signUpService = async ({ email, password, ...attrs }) => {
    const { isSignUpComplete, userId, nextStep } = await signUp({
      username: email,
      password,
      options: {
        userAttributes: {
          email,
          given_name: attrs.firstName,
          family_name: attrs.lastName,
          'custom:language': attrs?.language || LANG_EN_SHORT,
        },
        autoSignIn: true,
      },
    })
    return { isSignUpComplete, userId, nextStep }
  }
  const setPassword = async (body) => {
    const res = post({
      apiName: 'user',
      path: '/user/set-password',
      options: {
        body,
      },
    })
    return await getResponseToJSON(res)
  }

  const confirmSignUpService = async ({ email, code }) => {
    const { isSignUpComplete, userId, nextStep } = await confirmSignUp({
      username: email,
      confirmationCode: code,
    })
    try {
      const { isSignedIn } = await autoSignIn()
      return { isSignUpComplete, userId, nextStep, isSignedIn }
    } catch (err) {
      return err
    }
  }

  const resendSignUpService = async (email) => {
    await resendSignUpCode({ username: email })
  }

  const signInService = async ({ email, password }) =>
    await signIn({ username: email, password })

  const socialSignIn = async ({ provider }) => {
    return signInWithRedirect({ provider })
  }

  const forgetPassword = (email) => {
    return resetPassword({ username: email })
  }

  const forgetPasswordSubmit = async ({ email, code, password }) => {
    await confirmResetPassword({
      username: email,
      confirmationCode: code,
      newPassword: password,
    })
  }

  const getCognitoCurrentUser = async () => {
    const { signInDetails } = await getCurrentUser()
    const { tokens: session } = await fetchAuthSession()

    return {
      email: signInDetails?.loginId,
      session,
      authenticationFlowType: signInDetails?.authFlowType,
      signInUserSession: {
        accessToken: session.accessToken,
      },
    }
  }
  return {
    signUp: signUpService,
    resendSignUp: resendSignUpService,
    confirmSignUp: confirmSignUpService,
    forgetPassword,
    forgetPasswordSubmit,
    signIn: signInService,
    socialSignIn,
    setPassword,
    getCognitoCurrentUser,
  }
}

export default authService
