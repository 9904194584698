const imageUseCase = (imageRepo) => {
  const uploadImage = async (params) => imageRepo.uploadImage(params)

  const getImageUrl = async (key) => imageRepo.getImageUrl(key)

  return {
    uploadImage,
    getImageUrl,
  }
}

export default imageUseCase
