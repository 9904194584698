import React from 'react'
import { Box, Stack, Heading, Button, Flex } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { InputField } from 'components/InputField'
import { PasswordField } from 'components/PasswordField'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as Close } from 'assets/icons/closeBlack.svg'

const MobileResetPassword = ({
  register,
  onNewPass,
  errors,
  handleSubmit,
  isLoading,
  params,
}) => {
  const history = useHistory()
  const { t } = useTranslation()
  return (
    <Box>
      <Flex
        position="relative"
        py="3"
        borderBottom="1px solid"
        borderColor="border.100"
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          position="absolute"
          left="12px"
          onClick={() => history.goBack()}
          justifyContent="center"
          width="30px"
          alignItems="center"
        >
          <Close width="14px" height="14px" />
        </Flex>
        <Heading as="h1" textAlign="center" fontSize="3xl">
          {t('resetPassword@heading')}
        </Heading>
      </Flex>
      <Flex
        style={{ minHeight: 'calc(100vh - 54px)' }}
        flexDirection="column"
        justifyContent="center"
        px="5"
      >
        <form onSubmit={handleSubmit(onNewPass)}>
          <Stack spacing="6">
            <InputField
              borderRadius="12px"
              size="lg"
              label="email"
              value={params.get('email')}
              hidden={true}
              placeholder="code"
            />
            <InputField
              borderRadius="12px"
              size="lg"
              label={t('resetPassword@code')}
              errorMessage={t('resetPassword@codeRequired')}
              isInvalid={!!errors.code}
              {...register('code', { required: true })}
              name="code"
              autocomplemte="off"
              autofill="off"
            />
            <PasswordField
              size="lg"
              label={t('resetPassword@newPassword')}
              errorMessage={t('resetPassword@newPasswordRequired')}
              isInvalid={!!errors.password}
              {...register('password', { required: true })}
              autocomplemte="new-password"
              newPassword
            />
            <Button w="full" variant="blue" type="submit" isLoading={isLoading}>
              {t('resetPassword@submit')}
            </Button>
          </Stack>
        </form>
      </Flex>
    </Box>
  )
}

export default MobileResetPassword
