import moment from 'moment'

const feedUseCase = (feedRepo) => {
  const listFeedByUserId = async (input) => {
    const response = await feedRepo.listFeedByUserId(input)

    if (!response.data.feedByUserId.items.length) return null

    response.data.feedByUserId.items = response.data.feedByUserId.items.map(
      (feed) => ({
        ...feed,
        formattedDate: moment(feed.createdAt).format('MMMM DD, YYYY'),
        fromNow: moment(feed.createdAt).fromNow(),
      })
    )
    return response
  }

  return {
    listFeedByUserId,
  }
}

export default feedUseCase
