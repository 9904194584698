import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Heading,
  Center,
} from '@chakra-ui/react'

import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as Back } from '../../assets/icons/back.svg'
import SideFilters from './SideFilters'
import OrangeCountButton from './OrangeButton'

export default function MobileFilter({
  isOpen,
  onClose,
  expertiseOptions,
  localFilters,
  countries,
  setLocalFilters,
  totalText,
  someFiltersSelected,
}) {
  const { t } = useTranslation()

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="full">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader py={3} as={Center}>
            <DrawerCloseButton left={0}>
              <Back width="8px" height="20px" />
            </DrawerCloseButton>
            <Heading textAlign="center" fontSize="2xl">
              {t('filters@filterBy')}
            </Heading>
            <OrangeCountButton
              position="absolute"
              right="14px"
              text={totalText(true)}
              x={someFiltersSelected}
              onClick={() =>
                setLocalFilters({
                  isVolunteer: false,
                  categories: [],
                  directions: [],
                  countryCode: '',
                  languages: [],
                  hrsPerWeek: '',
                  search: null,
                  appliedFilters: 0,
                })
              }
            />
          </DrawerHeader>
          <DrawerBody p={0}>
            <SideFilters
              expertiseOptions={expertiseOptions}
              localFilters={localFilters}
              setLocalFilters={setLocalFilters}
              overflowX="hidden"
              totalText={totalText}
              countries={countries}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
