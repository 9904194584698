import { Box, Flex, Image, Text } from '@chakra-ui/react'
import NoScrollbar from 'components/NoScrollbar'

const NeedCategories = ({
  categories,
  lang,
  chooseCategory,
  chosenCategories,
  t,
}) => {
  return (
    <>
      <Text
        mb={8}
        fontSize={{ base: 'xl', lg: '4xl' }}
        fontWeight={700}
        mt={{ base: 8, lg: 0 }}
        ml={{ base: 4, lg: 0 }}
      >
        {t('chooseANeed')}
      </Text>
      <NoScrollbar
        Component={Box}
        overflow={{ base: 'scroll', lg: 'unset' }}
        pb={{ base: 0, lg: 8 }}
        borderBottomStyle={{ base: 'none', lg: 'solid' }}
        borderBottomWidth={{ base: 0, lg: 1 }}
        borderColor={{ base: 'transparent', lg: 'border.100' }}
      >
        <Flex
          flexWrap={{ base: 'nowrap', lg: 'wrap' }}
          w="fit-content"
          ml={{ base: 5, lg: 0 }}
        >
          {categories?.map((category, index) => (
            <Flex
              key={`category_${index}`}
              p={3}
              mt={1}
              h="100px"
              minW="220px"
              borderRadius={12}
              borderColor={
                chosenCategories.includes(category)
                  ? 'orange.400'
                  : 'border.400'
              }
              borderWidth={2}
              mr={8}
              cursor="pointer"
              onClick={() => chooseCategory(category)}
            >
              <Image
                borderRadius={12}
                objectFit="cover"
                w="80px"
                src={category.image.replace(`"`, '')}
                mr={2}
              />
              <Text
                fontSize="md"
                fontWeight={700}
                w="full"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {category['title_' + lang]}
              </Text>
            </Flex>
          ))}
        </Flex>
      </NoScrollbar>
    </>
  )
}

export default NeedCategories
