const donationUseCase = (donationRepo) => {
  const payment = (params) => donationRepo.payment(params)
  const unsubscribeFromBasketItem = (params) =>
    donationRepo.unsubscribeFromBasketItem(params)
  const checkOrderStatus = (params) => donationRepo.checkOrderStatus(params)
  const checkBasketOrderStatus = (params) =>
    donationRepo.checkBasketOrderStatus(params)
  const checkPaypalOrder = (params) => donationRepo.checkPaypalOrder(params)

  return {
    payment,
    checkOrderStatus,
    checkBasketOrderStatus,
    checkPaypalOrder,
    unsubscribeFromBasketItem,
  }
}

export default donationUseCase
