import { useCustomToast, useUpdateProject, useUpdateProjectSystem } from 'hooks'
import ApplicationCard from 'components/ApplicationCard'
import { useTranslation } from 'contexts/TranslationContext'

export default function IDram({ projectSystem }) {
  const { updateProject, isLoading: isLoadingProject } = useUpdateProject()
  const { mutate: updateProjectSystem, isLoading: isLoadingProjectSystem } =
    useUpdateProjectSystem()

  const toast = useCustomToast()
  const { t } = useTranslation()

  const onSubmit = ({ iDramAccountId, iDramApiKey }) => {
    const onSuccess = () => {
      updateProject(
        {
          id: projectSystem.projectId,
          isIdramOnBoardingDone: true,
        },
        {
          onSuccess: () => {
            toast({
              status: 'success',
              title: t('Successfully saved.'),
            })
          },
        }
      )
    }

    updateProjectSystem(
      {
        id: projectSystem.id,
        iDramAccountId: iDramAccountId,
        iDramApiKey: iDramApiKey,
      },
      { onSuccess }
    )
  }

  return (
    <ApplicationCard
      variant="variant1"
      logo="idram2.png"
      description="Give your community the option to donate through the IDram Payment System."
      heading="IDram"
      buttonText="Save"
      onSubmit={onSubmit}
      isLoading={isLoadingProjectSystem || isLoadingProject}
      inputs={[
        {
          fieldKey: 'iDramApiKey',
          placeholder: 'Secret Key',
          defaultValue: projectSystem?.iDramApiKey,
        },
        {
          fieldKey: 'iDramAccountId',
          placeholder: 'Account ID',
          defaultValue: projectSystem?.iDramAccountId,
        },
      ]}
    />
  )
}
