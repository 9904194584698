import { generateClient } from 'aws-amplify/api'
import { useTranslation } from 'contexts/TranslationContext'
import useCustomToast from './useCustomToast'

const appSyncClient = generateClient()

export default function useAppSyncRequest() {
  const toast = useCustomToast()
  const { t } = useTranslation()

  const appSyncRequest = async (data) => {
    try {
      if (data?.variables?.input) {
        data.variables.input = JSON.parse(
          JSON.stringify(data.variables.input, (key, value) => {
            if (value !== null) return value
          })
        )
      }

      return appSyncClient.graphql(data)
    } catch (e) {
      const title =
        e?.errors?.[0]?.errorType === 'Unauthorized'
          ? e?.errors?.[0]?.errorType
          : e?.errors?.[0]?.message
      toast({ status: 'error', title: t(title) })
      throw new Error(title)
    }
  }

  return appSyncRequest
}
