import React, { useEffect, useMemo } from 'react'
import { Box, Text } from '@chakra-ui/react'
import Header from './Header'
import { useTranslation } from 'contexts/TranslationContext'
import Wrapper from 'components/WrapperMobile'
import { useQueryParams } from 'hooks'

const MobileSearch = ({ projects, filters, setFilters }) => {
  const { t } = useTranslation()
  const collaborations = useMemo(
    () =>
      projects
        .filter((project) => project.isCollaboration)
        .sort((a, b) => a.sortOrder - b.sortOrder),
    [projects]
  )
  const fundraisers = useMemo(
    () =>
      projects
        .filter((project) => project.isProject)
        .sort((a, b) => a.sortOrder - b.sortOrder),
    [projects]
  )

  const queryString = useMemo(() => {
    const queryStringParams = Object.entries(filters)
      .reduce((acc, [key, value]) => {
        if (value) {
          acc.push(`${key}=${value}`)
        }
        return acc
      }, [])
      .join('&')

    return queryStringParams
  }, [filters])

  const queryParams = useQueryParams()
  useEffect(() => {
    queryParams.update(filters)
  }, [filters])

  return (
    <Box>
      <Header filters={filters} setFilters={setFilters} />
      <Text px="5" fontSize="sm">
        We found {collaborations?.length} collaborations and{' '}
        {fundraisers?.length} fundraising
      </Text>
      <Wrapper
        queryString={queryString}
        showAll={true}
        type="collaborations"
        swiper={collaborations}
        // isLoading={isLoading}
        fromSwiper={true}
        title={t('Collaborations')}
      />
      <Wrapper
        queryString={queryString}
        showAll={true}
        type="fundraisers"
        swiper={fundraisers}
        // isLoading={isLoading}
        fromSwiper={true}
        title={t('Fundraisers')}
      />
    </Box>
  )
}

export default MobileSearch
