import React from 'react'
import { Box } from '@chakra-ui/react'
import Header from './Header'
import TabsCollaborations from './TabsCollaborations'

const MyCollaborations = () => {
  return (
    <Box>
      <Header />
      <TabsCollaborations />
    </Box>
  )
}

export default MyCollaborations
