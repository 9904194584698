import React from 'react'
import { Box } from '@chakra-ui/react'

const Content = ({ html, ...props }) => (
  <Box
    sx={{
      img: {
        width: '100%',
        borderRadius: '8px',
        objectFit: 'cover',
      },
      'h1, h2, h3, h4, h5, h6, b': {
        fontWeight: 'bold',
        margin: '1em 0',
      },
      'p, ul, ol': {
        margin: '1rem 0',
      },
      h1: {
        fontSize: '4xl',
        lineHeight: '1.7em',
      },
      h2: {
        fontSize: '3xl',
        lineHeight: '1.5em',
      },
      h3: {
        fontSize: '4xl',
        lineHeight: '1.3em',
      },
      li: {
        marginLeft: '18px',
      },
      a: {
        textDecoration: 'underline !important',
        color: 'blue.300',
      },
      iframe: {
        width: '100%',
        minH: '300px',
      },
    }}
    dangerouslySetInnerHTML={{ __html: html }}
    {...props}
  />
)

export default Content
