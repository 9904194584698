import { uploadData, getUrl } from 'aws-amplify/storage'

const imageService = () => {
  const uploadImage = async ({ name, file }) => {
    if (file.name.includes('.gif')) {
      try {
        const result = uploadData({
          key: `gif/${name}`,
          data: file,
          options: {
            contentType: 'image',
          },
        }).result
        const { key } = await result
        return key
      } catch (error) {
        console.log('❌ ', error)
        return error
      }
    }

    try {
      const result = uploadData({
        key: name,
        data: file,
        options: {
          contentType: 'image',
        },
      }).result
      const { key } = await result
      return key
    } catch (error) {
      console.log('❌ ', error)
      return error
    }
  }

  const getImageUrl = async (key) => `${await getUrl(key)}`

  return {
    uploadImage,
    getImageUrl,
  }
}

export default imageService
