import React from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'contexts/TranslationContext'
import { useUser } from 'hooks'
import MobileMenu from 'components/MobileMenu'
import WebNavbar from './WebNavbar'
import { useScreenSize } from 'contexts'

const Navbar = ({
  shadow,
  disableLinks,
  onClick,
  title,
  backTo,
  titleProps = {},
}) => {
  const { user } = useUser()
  const location = useLocation()
  const { t } = useTranslation()
  const { isMobile } = useScreenSize()

  const isHomepage = location.pathname.length < 5
  const isGuest = user.id === 'guest'

  const currentTitle = location.pathname.includes('history')
    ? t('profile@donorSpace')
    : location.pathname.includes('invest')
    ? t('profile@investorSpace')
    : location.pathname.includes('settings')
    ? t('profile@settings')
    : location.pathname.includes('expert')
    ? t('profile@expertSpace')
    : location.pathname.includes('initiator')
    ? t('profile@initiatorSpace')
    : title

  const currentPath = location.pathname.includes('donation')
    ? 'donation'
    : location.pathname.includes('contact')
    ? 'contact'
    : location.pathname.includes('about')
    ? 'about'
    : location.pathname.includes('order-gift-card')
    ? 'gift-card'
    : location.pathname.includes('collaborations') &&
      !location.pathname.includes('profile') &&
      !location.pathname.includes('edit-')
    ? 'collaborations'
    : location.pathname.includes('fundraisers') &&
      !location.pathname.includes('profile') &&
      !location.pathname.includes('edit-')
    ? 'fundraisers'
    : location.pathname.includes('experts') &&
      !location.pathname.includes('profile') &&
      !location.pathname.includes('edit-')
    ? 'experts'
    : location.pathname.includes('select-gift-card')
    ? 'select-gift-card'
    : location.pathname.includes('gift-card')
    ? 'gift-card'
    : location.pathname

  if (isMobile) {
    return (
      <MobileMenu
        user={user}
        isHomepage={isHomepage}
        title={currentTitle}
        backTo={backTo}
        isGuest={isGuest}
        titleProps={titleProps}
      />
    )
  }

  return (
    <WebNavbar
      onClick={onClick}
      disableLinks={disableLinks}
      isHomepage={isHomepage}
      currentPath={currentPath}
      isGuest={isGuest}
      username={user.firstName}
      shadow={shadow}
    />
  )
}

export default Navbar
