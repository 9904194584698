import { useMutation } from 'react-query'
import { bindingCardUseCase } from '../../core/factories'

const useDeleteProject = () => {
  const { mutate: deleteBindingCard, isLoading } = useMutation(
    bindingCardUseCase.deleteBindingCard
  )

  return {
    deleteBindingCard,
    isLoading,
  }
}

export default useDeleteProject
