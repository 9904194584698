import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import Swiper from '../../../components/Swiper'

export const Header = ({ projects, isLoading }) => {
  return (
    <Box
      bg="blue.300"
      as="section"
      minH="70vh"
      position="relative"
      zIndex="1"
      h={{ base: '42rem', md: '70vh' }}
    >
      {!isLoading && (
        <>
          <Flex
            height="100%"
            flexDirection="column"
            justifyContent="space-between"
            position="relative"
            zIndex={1}
          >
            <Swiper slides={projects} />
          </Flex>
        </>
      )}
    </Box>
  )
}
