import React, { useEffect } from 'react'
import { Flex, Spinner } from '@chakra-ui/react'
import { useNavigation } from 'pages'
import { useCustomToast, useQueryParams } from 'hooks'
import { useParams } from 'react-router-dom'
import {
  useGetExpertConnectionById,
  useUpdateExpertConnection,
} from 'hooks/expert'
import { useTranslation } from 'contexts/TranslationContext'

export default function ConnectLoading() {
  const { t } = useTranslation()
  const { id } = useParams()
  const params = useQueryParams()
  const from = params.get('from')
  const type = params.get('type')
  const toast = useCustomToast()
  const { navigationPush } = useNavigation()
  const { data: connection, isLoading } = useGetExpertConnectionById(id)
  const { mutate: updateConnection } = useUpdateExpertConnection()
  useEffect(() => {
    if (isLoading || !connection) {
      return
    }
    const input = {
      id,
    }
    const updatingField = from === 'admin' ? 'adminStatus' : 'expertStatus'
    input[updatingField] = type === 'approve' ? 'approved' : 'rejected'

    if (connection[updatingField] !== 'pending') {
      toast({ title: `Already responded`, status: 'warning' })
      navigationPush('/')
      return
    }

    updateConnection(input, {
      onSuccess(data) {
        const action = input.expertStatus || input.adminStatus
        console.log('success', data)
        toast({ title: t(`${action} successfully`), status: 'success' })
        navigationPush('/')
      },
      onError(err) {
        console.log('err', err)
        toast({ title: `something went wrong`, status: 'error' })
        navigationPush('/')
      },
    })
  }, [connection])

  return (
    <Flex
      width="100%"
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner size="lg" />
    </Flex>
  )
}
