const donationRepo = (donationService) => {
  const payment = (params) => donationService.payment(params)
  const unsubscribeFromBasketItem = (params) =>
    donationService.unsubscribeFromBasketItem(params)
  const checkOrderStatus = (params) => donationService.checkOrderStatus(params)
  const checkBasketOrderStatus = (params) =>
    donationService.checkBasketOrderStatus(params)
  const checkPaypalOrder = (params) => donationService.checkPaypalOrder(params)

  return {
    payment,
    unsubscribeFromBasketItem,
    checkOrderStatus,
    checkBasketOrderStatus,
    checkPaypalOrder,
  }
}

export default donationRepo
