import React from 'react'
import { Button, Center, Heading, Text } from '@chakra-ui/react'
import { Link } from 'pages'
const EmptyState = ({
  title,
  description,
  button,
  link,
  disabled,
  bulletedDescription,
  maxW = '500px',
  mobileMT = '-112px',
  desktopMT = '82px',
}) => (
  <Center flexDir="column" maxW={maxW} mt={{ base: mobileMT, sm: desktopMT }}>
    {title && (
      <Heading
        fontSize={{ base: '2XL', sm: '4xl' }}
        color="gray.900"
        textAlign="center"
        mb={8}
      >
        {title}
      </Heading>
    )}
    <Text
      fontSize={{ base: 'lg', sm: '2xl' }}
      color="gray.700"
      textAlign="center"
    >
      {description}
    </Text>
    {!!bulletedDescription &&
      bulletedDescription.split('<br>').map((text) => (
        <Text
          key={text}
          fontSize={{ base: 'lg', sm: '2xl' }}
          color="gray.700"
          textAlign="left"
          w="full"
        >
          • {text} {'\n'}
        </Text>
      ))}
    {button && (
      <Button
        mt="26px"
        variant="green"
        as={disabled ? Button : Link}
        to={link}
        disabled={disabled}
        minW={{ base: 'full', sm: '226px' }}
      >
        {button}
      </Button>
    )}
  </Center>
)
export default EmptyState
