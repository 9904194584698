import React from 'react'
import { Text, Flex, Box, Heading, Container, Img } from '@chakra-ui/react'
import Navbar from 'components/Navbar'
import { Footer } from 'components/Footer'

const Privacy = () => {
  return (
    <Flex
      direction="column"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Navbar title="Privacy Policy" />
      <Box mb="3rem" bg="gray.800" as="section" position="relative" w="full">
        <Box h="full" py="32" position="relative" zIndex={1}>
          <Flex
            h="full"
            alignItems="center"
            maxW={{ base: 'xl', md: '4xl' }}
            mx="auto"
            px={{ base: '6', md: '8' }}
            color="white"
          >
            <Box maxW="3xl">
              <Heading
                as="h1"
                fontSize={{ base: '5xl', md: '6xl' }}
                fontWeight="extrabold"
              >
                Privacy Policy
              </Heading>
            </Box>
          </Flex>
        </Box>
        <Flex
          id="image-wrapper"
          position="absolute"
          insetX="0"
          insetY="0"
          w="full"
          h="full"
          overflow="hidden"
          align="center"
        >
          <Box position="relative" w="full" h="full">
            <Img
              src="https://images.unsplash.com/photo-1585909695677-2b0492f96e3b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              alt="Main Cover"
              w="full"
              h="full"
              objectFit="cover"
              objectPosition="center"
              position="absolute"
            />
            <Box
              position="absolute"
              w="full"
              h="full"
              bg="linear-gradient(134.85deg, #002D72 0%, rgba(0,45,114,0) 100%)"
            />
          </Box>
        </Flex>
      </Box>
      <Container pb="5rem" px={{ base: '6', md: '8' }} maxW="4xl">
        <Text textAlign="justify" lineHeight="8">
          All terms and definitions in this document have the meaning set forth
          in the Terms and Conditions
        </Text>
        <Text textAlign="justify" lineHeight="8">
          Your privacy is important to us, we respect your privacy regarding any
          information we may collect from you across our website
        </Text>
        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          1. Information we collect
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          When you visit our website, our servers may automatically log the
          standard data provided by your web browser. It may include your
          computer’s Internet Protocol (IP) address, your browser type and
          version, the pages you visit, the time and date of your visit, the
          time spent on each page, and other details. Device data We may also
          collect data about the device you’re using to access our website. This
          data may include the device type, operating system, unique device
          identifiers, device settings, and geo-location data. What we collect
          can depend on the individual settings of your device and software. We
          recommend checking the policies of your device manufacturer or
          software provider to learn what information they make available to us.
          Personal informationWe may ask for personal information, such as your:
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; Name
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; Email
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; Phone/mobile number
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; Home/Office/Mailing address
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; Payment information
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; Other
        </Text>
        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          2. Information Shared with Project Creators and Initiators
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          When you donate, subscribe, support a project, Project Creator or
          Initiator on the reArmenia collaboration platform or engage with their
          content in any way, you are agreeing to share personal information
          with that creator. This information includes:
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; Your full name.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; Your email address.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; Your physical address, including city, state/province, and
          country
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; Details about your support, donation, including the amount and
          start date, but not your full payment card information.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          You may also be invited to participate in surveys related to specific
          creators on reArmenia, such as when you subscribe, make a purchase, or
          cancel a subscription. If you choose to participate in these surveys,
          your responses may include personal data like your full name and email
          address, and they will be shared with the relevant creator. reArmenia
          charitable foundation is not responsible, and shall not be liable, for
          any Project creator’s or third party’s use of any Donor’s information.
        </Text>
        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          3. Legal bases for processing
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          We will process your personal information lawfully, fairly and in a
          transparent manner. We collect and process information about you only
          where we have legal bases for doing so.
        </Text>
        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          4. Collection and use of information
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          We may collect, hold, use and disclose information for the following
          purposes and personal information will not be further processed in a
          manner that is incompatible with these purposes:
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; to enable you to customise or personalise your experience of
          our website;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; to enable you to access and use our website, associated
          applications and associated social media platforms;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; to contact and communicate with you;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; for internal record keeping and administrative purposes;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; for analytics, market research and business development,
          including to operate and improve our website, associated applications
          and associated social media platforms;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; for advertising and marketing, including to send you
          promotional information about our products and services and
          information about third parties that we consider may be of interest to
          you; and
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; to comply with our legal obligations and resolve any disputes
          that we may have.
        </Text>
        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          5. Disclosure of personal information to third parties
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          We may disclose personal information to:
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; third party service providers for the purpose of enabling them
          to provide their services, including (without limitation) IT service
          providers, data storage, web-hosting and server providers, debt
          collectors, maintenance or problem-solving providers, marketing or
          advertising providers, professional advisors and payment systems
          operators;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; our employees, contractors and/or related entities;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; sponsors or promoters of any competition we run;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; third parties, including agents or sub-contractors, who assist
          us in providing information, products, services or direct marketing to
          you; and
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; third parties to collect and process data.
        </Text>
        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          6. International transfers of personal information
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          By providing us with your personal information, you consent to the
          disclosure to these overseas third parties. We will ensure that any
          transfer of personal information will be protected by appropriate
          safeguards. Where we transfer personal information, you acknowledge
          that third parties may not be subject to similar data protection.
          There are risks if any such third party engages in any act or practice
          that would contravene the data privacy laws in our jurisdiction and
          this might mean that you will not be able to seek redress under our
          jurisdiction’s privacy laws.
        </Text>
        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          7. Your rights and controlling your personal information
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          <Box as="span" fontWeight="bold">
            Choice and consent:
          </Box>{' '}
          By providing personal information to us, you consent to us collecting,
          holding, using and disclosing your personal information in accordance
          with this privacy policy. If you are under 16 years of age, you must
          have, and warrant to the extent permitted by law to us, that you have
          your parent or legal guardian’s permission to access and use the
          website and they (your parents or guardian) have consented to you
          providing us with your personal information. You do not have to
          provide personal information to us, however, if you do not, it may
          affect your use of this website or the products and/or services
          offered on or through it.{' '}
          <Box as="span" fontWeight="bold">
            Information from third parties:
          </Box>{' '}
          If we receive personal information about you from a third party, we
          will protect it as set out in this privacy policy. If you are a third
          party providing personal information about somebody else, you
          represent and warrant that you have such person’s consent to provide
          the personal information to us.{' '}
          <Box as="span" fontWeight="bold">
            Restrict:
          </Box>{' '}
          You may choose to restrict the collection or use of your personal
          information. If you have previously agreed to us using your personal
          information for direct marketing purposes, you may change your mind at
          any time by contacting us using the details below. If you ask us to
          restrict or limit how we process your personal information, we will
          let you know how the restriction affects your use of our website or
          products and services.{' '}
          <Box as="span" fontWeight="bold">
            Access and data portability:
          </Box>{' '}
          You may request details of the personal information that we hold about
          you. You may request a copy of the personal information we hold about
          you. You may request that we erase the personal information we hold
          about you at any time. You may also request that we transfer this
          personal information to another third party.{' '}
          <Box as="span" fontWeight="bold">
            Correction:
          </Box>{' '}
          If you believe that any information we hold about you is inaccurate,
          out of date, incomplete, irrelevant or misleading, please contact us
          using the details below. We will take reasonable steps to correct any
          information found to be inaccurate, incomplete, misleading or out of
          date.{' '}
          <Box as="span" fontWeight="bold">
            Notification of data breaches:
          </Box>{' '}
          We will comply laws applicable to us in respect of any data breach.{' '}
          <Box as="span" fontWeight="bold">
            Complaints:
          </Box>{' '}
          If you believe that we have breached a relevant data protection law
          and wish to make a complaint, please contact us using the details
          below and provide us with full details of the alleged breach. We will
          promptly investigate your complaint and respond to you, in writing,
          setting out the outcome of our investigation and the steps we will
          take to deal with your complaint. You also have the right to contact a
          regulatory body or data protection authority in relation to your
          complaint.
        </Text>
        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          8. Cookies
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          We use “cookies” to collect information about you and your activity
          across our site. A cookie is a small piece of data that our website
          stores on your computer, and accesses each time you visit, so we can
          understand how you use our site. This helps us serve you content based
          on preferences you have specified.
        </Text>
        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          9. Limits of our policy
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          Our website may link to external sites that are not operated by us.
          Please be aware that we have no control over the content and policies
          of those sites, and cannot accept responsibility or liability for
          their respective privacy practices.
        </Text>
        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          10. Changes to this policy
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          At our discretion, we may change our privacy policy to reflect current
          acceptable practices. Your continued use of this site after any
          changes to this policy will be regarded as acceptance of our practices
          around privacy and personal information.
        </Text>
      </Container>
      <Footer />
    </Flex>
  )
}

export default Privacy
