import { ReactComponent as Discover } from '../../assets/icons/discover.svg'
import { ReactComponent as DiscoverInactive } from '../../assets/icons/discoverInactive.svg'
import { ReactComponent as Collaborations } from '../../assets/icons/collaborations.svg'
import { ReactComponent as CollaborationsInactive } from '../../assets/icons/collaborationsInactive.svg'
import { ReactComponent as Fundraisers } from '../../assets/icons/fundraisers.svg'
import { ReactComponent as FundraisersInactive } from '../../assets/icons/fundraisersInactive.svg'

export const MOBILE_DATA = [
  {
    label: 'home',
    text: 'Discover',
    route: '/',
    icon: {
      active: <Discover />,
      inactive: <DiscoverInactive />,
    },
  },
  {
    label: 'fund',
    text: 'Fundraisers',
    route: '/fundraisers',
    icon: {
      active: <Fundraisers />,
      inactive: <FundraisersInactive />,
    },
  },
  {
    label: 'experts',
    text: 'experts',
    route: '/experts',
    icon: {
      active: <Collaborations />,
      inactive: <CollaborationsInactive />,
    },
  },
]

export const NAV_LINKS = [
  {
    title: 'Fundraisers',
    to: '/fundraisers?type=oneTime',
    active: 'fundraisers',
  },
  {
    title: 'nav@initiatives',
    to: '/collaborations',
    active: 'collaborations',
  },
  {
    title: 'experts',
    to: '/experts',
    active: 'experts',
  },
  {
    title: 'Gift card',
    to:
      process.env.REACT_APP_ENV !== 'production'
        ? '/select-gift-card'
        : '/gift-card',
    active:
      process.env.REACT_APP_ENV !== 'production'
        ? 'select-gift-card'
        : 'gift-card',
  },
  {
    title: 'About us',
    to: '/about-us',
    active: 'about',
  },
]

export const ABOUT_DROPDOWN = [
  {
    title: 'about@aboutUs',
    value: 'about-us',
  },
  {
    title: 'about@ourTeam',
    value: 'our-team',
  },
  {
    title: 'about@ourImpact',
    value: 'our-impact',
  },
]

export const MENU_TOP_LINKS = [
  { label: 'Fundraisers', href: '/fundraisers' },
  { label: 'Collaborations', href: '/collaborations' },
]

export const MENU_BOTTOM_LINKS = [
  { label: 'corporate', href: '/corporate' },
  { label: 'nav@about', href: '/about-us' },
]
