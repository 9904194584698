import {
  FUNDRAISER_ONE_TIME,
  FUNDRAISER_RECURRING,
  FUNDRAISER_PEER_TO_PEER,
} from 'constants/fundraiser'

const fundraisers = [
  {
    type: FUNDRAISER_ONE_TIME,
    heading: 'projectCreate@oneTime',
    body: 'projectCreate@oneTimeText',
  },
  {
    type: FUNDRAISER_RECURRING,
    heading: 'projectCreate@recurring',
    body: 'projectCreate@recurringText',
  },
  {
    type: FUNDRAISER_PEER_TO_PEER,
    heading: 'Peer-to-peer Fundraiser',
    body: "If you'd like to invite your friends to support any fundraiser presented on the reArmenia platform for your birthday (or any occasion), then select this option.",
  },
]

export default fundraisers
