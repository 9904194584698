import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, Heading, Spinner } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useGetTransactionByEmailDate, useUser } from 'hooks'
import List from 'components/List'
import { Link } from 'pages'

function History() {
  const { t, language } = useTranslation()
  const { user } = useUser()
  const [transactions, setTransactions] = useState([])
  const [filters, setFilters] = useState({
    email: user.email,
    limit: 10,
    nextToken: null,
    sortDirection: 'DESC',
    filter: { status: { eq: 'DONE' }, and: { projectId: { ne: 'giftCard' } } },
  })
  const {
    data: transactionsResponse,
    refetch,
    isLoading,
  } = useGetTransactionByEmailDate(filters)
  const onLoadNext = () => {
    if (transactionsResponse.nextToken) {
      refetch()
    }
  }
  useEffect(() => {
    if (!transactionsResponse?.items && !transactionsResponse?.nextToken) {
      return
    }
    setTransactions((oldTransactions) => [
      ...oldTransactions,
      ...(transactionsResponse?.items || []),
    ])
    setFilters((oldFilters) => ({
      ...oldFilters,
      nextToken: transactionsResponse.nextToken,
    }))
    if (
      !transactionsResponse?.items?.length &&
      !!transactionsResponse?.nextToken
    ) {
      refetch()
    }
  }, [transactionsResponse])

  useEffect(() => {
    return () => {
      setFilters({
        email: user.email,
        limit: 10,
        nextToken: null,
        sortDirection: 'DESC',
      })
      setTransactions([])
    }
  }, [])

  return (
    <Box
      my="4"
      width={{ base: '90%', sm: '95%' }}
      mx="auto"
      position="relative"
    >
      {isLoading ? (
        <Flex minH="520px" w="full" align="center" justifyContent="center">
          <Spinner color="blue.400" />
        </Flex>
      ) : (
        <>
          {transactions?.length > 0 ? (
            <List
              onLoadNext={onLoadNext}
              hasMore={transactionsResponse?.nextToken}
              transactions={transactions}
              t={t}
              lang={language}
            />
          ) : (
            <EmptyHistory
              title={t('profile@history@empty')}
              buttonText={t('profile@history@emptyButton')}
            />
          )}
        </>
      )}
    </Box>
  )
}

const EmptyHistory = ({ title, buttonText }) => (
  <Box textAlign="center">
    <Heading fontSize={{ base: 'xl', sm: '3xl' }} mb="8">
      {title}
    </Heading>
    <Button
      as={Link}
      to="/fundraisers?type=oneTime"
      variant="blue"
      minWidth="216px"
      height="48px"
    >
      {buttonText}
    </Button>
  </Box>
)

export default History
