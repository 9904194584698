import { Box, Button, Flex, Image, Text } from '@chakra-ui/react'

export default function NeedCard({
  collaboration,
  t,
  selectAction,
  viewMode,
  lang,
  ended = false,
  ...rest
}) {
  return (
    <Flex
      borderWidth={1}
      borderColor="gray.400"
      borderRadius={12}
      direction="column"
      align="flex-start"
      pt={viewMode ? '18px' : { base: 0, lg: '18px' }}
      pb="18px"
      px={viewMode ? 5 : { base: 0, lg: 5 }}
      mb={3}
      {...rest}
    >
      <Flex
        mb={viewMode ? 0 : { base: 4, lg: 0 }}
        direction={viewMode ? 'row' : { base: 'column', lg: 'row' }}
        w="full"
      >
        <Image
          borderRadius={8}
          borderBottomRadius={viewMode ? 8 : { base: 0, lg: 8 }}
          width={viewMode ? '100px' : { base: 'full', lg: '100px' }}
          minW="100px"
          height={viewMode ? '57px' : { base: '184px', lg: '57px' }}
          objectFit="cover"
          src={
            collaboration.image
              ? `${process.env.REACT_APP_S3_BUCKET_URL}/public/${collaboration.image}`
              : '/assets/images/noImg.svg'
          }
          mr={2}
        />
        <Box px={viewMode ? 0 : { base: 5, lg: 0 }}>
          <Text
            mt={viewMode ? 0 : { base: 3, lg: 0 }}
            fontSize={viewMode ? 'md' : { base: '2xl', lg: 'md' }}
            fontWeight={viewMode ? 500 : { base: 700, lg: 500 }}
            noOfLines={viewMode ? 3 : 'unset'}
          >
            {collaboration[`needs_${lang}`]}
          </Text>
          <Text
            mt={viewMode ? 0 : { base: 3, lg: 0 }}
            fontSize="sm"
            fontWeight={400}
            color="gray.700"
            noOfLines={viewMode ? 3 : 'unset'}
          >
            {collaboration[`needsDescription_${lang}`]}
          </Text>
        </Box>
      </Flex>
      {!viewMode && (
        <Button
          mr="4"
          color="orange.400"
          isDisabled={ended}
          variant="transparent"
          fontSize="md"
          alignSelf="flex-end"
          onClick={selectAction}
        >
          {t('select')}
        </Button>
      )}
    </Flex>
  )
}
