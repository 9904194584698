import { Box } from '@chakra-ui/react'
import { Footer } from 'components/Footer'
import { MainLayout } from 'components/Layouts'
import BackButton from 'components/BackButton'
import Header from './Header'
import { useScreenSize } from 'contexts'

export default function Layout({ children }) {
  const { isMobile } = useScreenSize()

  return (
    <MainLayout>
      {isMobile && <Header />}
      <Box
        minH="620px"
        w={{ base: 'full', md: '1140px' }}
        marginX="auto"
        mt={12}
        mb={{ base: 0, sm: '240px' }}
        ml={{ base: 6, xl: 'auto' }}
      >
        {!isMobile && <BackButton mb="0" to="/" />}
        {children}
      </Box>
      {!isMobile && <Footer />}
    </MainLayout>
  )
}
