import { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { useGetProject } from 'hooks'
import Tabs from 'components/Tabs'
import DonorsList from './DonorsList'
import Integrations from './Integrations'
import { FUNDRAISER_PEER_TO_PEER } from 'constants/fundraiser'

export default function Donors() {
  const [tabs, setTabs] = useState([])
  const [tab, setTab] = useState()
  const { projectId } = useParams()
  const { isLoading: isLoadingProject, data: project } = useGetProject({
    projectId,
  })

  const onTabChange = setTab

  useEffect(() => {
    if (!project) return
    if (project?.fundraisingType === FUNDRAISER_PEER_TO_PEER) {
      setTabs(['Donor List'])
    } else {
      setTabs(['Donor List', 'Integrations'])
    }
  }, [project])

  if (!project || tabs.length === 0) {
    return null
  }

  return (
    <Box>
      <Tabs tabs={tabs} onTabChange={onTabChange} />
      <Box pt={8}>
        {tab === 'Donor List' && (
          <DonorsList isLoadingProject={isLoadingProject} project={project} />
        )}
        {tab === 'Integrations' && <Integrations />}
      </Box>
    </Box>
  )
}
