import { Heading } from '@chakra-ui/react'
import * as React from 'react'

export const FooterHeading = (props) => (
  <Heading
    color="black"
    fontSize="md"
    fontWeight="500"
    as="h2"
    letterSpacing="wider"
    {...props}
  />
)
