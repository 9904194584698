import { useRef, useState, forwardRef } from 'react'
import { Input, InputGroup, Spinner } from '@chakra-ui/react'
import { useImage } from 'hooks'
import { useEffect } from 'react'
import ImageWrapper from 'components/ImageWrapper'

const FileUpload = forwardRef(
  (
    {
      isLoading,
      toggleLoading = () => {},
      accept,
      multiple,
      children,
      image: defaultImage,
      imageUrl,
      isDisabled,
      setValidatorValue = () => {},
      onUpload = () => {},
      name = 'file',
      noPreview = false,
      ...rest
    },
    validationRef
  ) => {
    const [loading, setLoading] = useState(false)
    const { uploadImage } = useImage()
    const [image, setImage] = useState()
    const inputRef = useRef(null)
    const handleClick = () => {
      if (!isDisabled) {
        inputRef.current?.click()
      }
    }
    const uploadFile = async (event) => {
      const files = event.target.files
      if (files && files[0]) {
        toggleLoading(true)
        try {
          const key = await uploadImage({
            name: `project_${Date.now()}`,
            file: files[0],
          })
          setImage(key)
          onUpload(key)
        } catch (e) {
          console.log('image upload error - ', e)
        } finally {
          toggleLoading(false)
        }
      }
    }

    useEffect(() => {
      if (!defaultImage) return
      ;(async () => {
        setLoading(true)
        setImage(defaultImage)
        setLoading(false)
      })()
    }, [defaultImage])

    useEffect(() => {
      if (!image) {
        return setValidatorValue(name, '')
      }
      setValidatorValue(name, '1')
    }, [image])
    const imageUrlCheck = image ? `/${image}` : `/thumbnail/small/${imageUrl}`
    return (
      <InputGroup
        minHeight="20px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        onClick={handleClick}
        w="auto"
        pos="relative"
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
      >
        {/* JUST FOR VALIDATION */}
        <Input {...rest} ref={validationRef} display="none" />

        <Input
          ref={(e) => (inputRef.current = e)}
          type="file"
          style={{ display: 'none' }}
          multiple={multiple || false}
          hidden
          accept={accept}
          onChange={uploadFile}
        />
        {(isLoading || loading) && !noPreview ? (
          <Spinner height="20px" width="20px" />
        ) : (
          children
        )}
        {(image || imageUrl) && !noPreview && (
          <ImageWrapper
            src={imageUrlCheck}
            opacity={isLoading || loading ? '0' : '1'}
            alt="Image"
            display="block"
            mt={3}
            maxWidth="300px"
            height="130px"
            objectFit="cover"
            borderRadius={7}
            ignoreFallback={true}
            onLoad={() => toggleLoading(false)}
          />
        )}
      </InputGroup>
    )
  }
)

export default FileUpload
