import { Button } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { useExportTableWithParams, useCustomToast, useUser } from 'hooks'
import { ReactComponent as Excel } from 'assets/icons/excel.svg'
import { useTranslation } from 'contexts/TranslationContext'

export default function Export({ isPeerProject }) {
  const { mutate: exportDonorsList, isLoading } = useExportTableWithParams()
  const { projectId } = useParams()
  const toast = useCustomToast()
  const { t } = useTranslation()
  const { user } = useUser()

  const onExportDonorsList = () => {
    exportDonorsList({ email: user?.email, projectId, isPeerProject })
    toast({
      title: t('toaster@exportedSuccessfully'),
      status: 'success',
      duration: 3000,
      isClosable: true,
    })
  }

  return (
    <Button
      py={2}
      px={4}
      h="38px"
      color="gray.800"
      fontWeight={500}
      fontSize="sm"
      borderRadius={8}
      border="1px solid"
      borderColor="border.100"
      isLoading={isLoading}
      isDisabled={isLoading}
      onClick={onExportDonorsList}
    >
      {t('Export')}
      <Excel style={{ marginLeft: '4px' }} />
    </Button>
  )
}
