import { useQuery } from 'react-query'
import { transactionUseCase } from '../../core/factories'

const useListTransactionsByDate = (variables, options) => {
  const data = useQuery(
    ['transactions-date', variables],
    () => transactionUseCase.listTransactionsByDate(variables),
    options
  )

  return data
}

export default useListTransactionsByDate
