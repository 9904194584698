import React from 'react'
import {
  Box,
  Heading,
  useColorModeValue as mode,
  Button,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { Link } from 'pages'
import { SignupForm } from './SignupForm'
import AuthWrapper from 'components/AuthWrapper'
import { ReactComponent as Close } from 'assets/icons/closeBlack.svg'

const Web = ({
  register,
  errors,
  handleSubmit,
  onSubmit,
  country,
  setCountry,
  phone,
  setPhone,
  phoneError,
  loading,
  password,
  user,
}) => {
  const { t } = useTranslation()

  return (
    <AuthWrapper
      ceoCompany="CEO of Kamala"
      ceoName="Samvel Papoyan"
      ceoDescription="“reArmenia is short description Though I’ll stop short of calling it a rule lorem ipsum`.”"
      url="/assets/images/auth.jpg"
    >
      <Box w="full" maxW="lg">
        <Link to="/">
          <Button
            display={{ base: 'inline-flex', md: 'none' }}
            position="absolute"
            top="20px"
            right="20px"
            variant="transparent"
          >
            <Close width="20px" />
          </Button>
        </Link>
        <Heading
          as="h1"
          fontSize="5xl"
          fontWeight="extrabold"
          letterSpacing="tight"
          textAlign="center"
        >
          {t('signUp@heading')}
        </Heading>

        <Box
          minW={{ md: '350px' }}
          rounded="xl"
          bg={{ md: mode('white', 'gray.700') }}
          pt={{ base: '8', md: '12' }}
          pb="8"
        >
          <SignupForm
            register={register}
            errors={errors}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            country={country}
            setCountry={setCountry}
            phone={phone}
            setPhone={setPhone}
            phoneError={phoneError}
            loading={loading}
            password={password}
            user={user}
          />
        </Box>
      </Box>
    </AuthWrapper>
  )
}

export default Web
