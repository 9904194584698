import { projectSystemByProjectId } from '../../graphql/queries'
import { updateProjectSystem as updateProjectSystemMutation } from '../../graphql/mutations'

const ProjectSystemService = (appSyncRequest, auth) => {
  const updateProjectSystem = async (projectSystem) => {
    const currentAuthSession = await auth.fetchAuthSession()
    const jwtToken = currentAuthSession.tokens.idToken.toString()

    return appSyncRequest({
      query: updateProjectSystemMutation,
      variables: { input: projectSystem },
      authToken: jwtToken,
      authMode: 'userPool',
    })
  }

  const getProjectSystemByProjectId = async (projectId) => {
    const currentAuthSession = await auth.fetchAuthSession()
    const jwtToken = currentAuthSession.tokens.idToken.toString()

    const projectSystem = await appSyncRequest({
      query: projectSystemByProjectId,
      variables: { projectId },
      authToken: jwtToken,
      authMode: 'userPool',
    })

    return projectSystem?.data?.projectSystemByProjectId?.items?.[0]
  }

  return {
    updateProjectSystem,
    getProjectSystemByProjectId,
  }
}

export default ProjectSystemService
