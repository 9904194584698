import React, { useState } from 'react'
import { Box, Stack, Heading, Button } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useNavigation } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import { authUseCase } from 'core/factories'
import AuthWrapper from 'components/AuthWrapper'
import { InputField } from 'components/InputField'
import { useQueryParams } from 'hooks'
import { PasswordField } from 'components/PasswordField'
import MobileResetPassword from './MobileResetPassword'
import useCustomToast from 'hooks/useCustomToast'
import { useScreenSize } from 'contexts'

const ResetPassword = () => {
  const toast = useCustomToast()
  const params = useQueryParams()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      code: '',
      password: '',
    },
  })
  const { t } = useTranslation()
  const [isLoading, toggleLoading] = useState(false)
  const { navigationReplace } = useNavigation()

  const onNewPass = async (data) => {
    if (isLoading) return
    toggleLoading(true)
    try {
      await authUseCase.forgetPasswordSubmit({
        email: params.get('email'),
        code: data.code,
        password: data.password,
      })
      toast({
        status: 'success',
        title: t('resetPassword@successMessage'),
        isClosable: true,
        duration: 5000,
      })
      navigationReplace('/sign-in')
    } catch (e) {
      toast({
        status: 'error',
        title: t(`resetPassword@${e.code || e.__type || e.name}`),
        isClosable: true,
        duration: 5000,
      })
    } finally {
      toggleLoading(false)
    }
  }

  const { isMobile } = useScreenSize()
  if (isMobile) {
    return (
      <MobileResetPassword
        register={register}
        onNewPass={onNewPass}
        errors={errors}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        params={params}
      />
    )
  }

  return (
    <AuthWrapper
      ceoCompany="CEO of Kamala"
      ceoName="Samvel Papoyan"
      ceoDescription="“reArmenia is short description Though I’ll stop short of calling it a rule lorem ipsum`.”"
      url="/assets/images/auth.jpg"
    >
      <Box w="full" maxW="lg">
        <Heading
          as="h1"
          fontSize="5xl"
          fontWeight="extrabold"
          letterSpacing="tight"
        >
          {t('resetPassword@heading')}
        </Heading>
        <Box
          minW={{ md: '350px' }}
          rounded="xl"
          bg="white"
          // px={{ md: '10' }}
          pt={{ base: '8', md: '12' }}
          pb="8"
        >
          <form onSubmit={handleSubmit(onNewPass)} style={{ width: 400 }}>
            <Stack spacing="6">
              <InputField
                size="lg"
                label="email"
                value={params.get('email')}
                hidden={true}
                placeholder="code"
              />
              <InputField
                size="lg"
                label={t('resetPassword@code')}
                errorMessage={t('resetPassword@codeRequired')}
                isInvalid={!!errors.code}
                {...register('code', { required: true })}
                name="code"
                autocomplemte="off"
                autofill="off"
              />
              <PasswordField
                size="lg"
                label={t('resetPassword@newPassword')}
                errorMessage={t('resetPassword@newPasswordRequired')}
                isInvalid={!!errors.password}
                newPassword
                {...register('password', { required: true })}
                autocomplemte="new-password"
              />
              <Button
                w="full"
                variant="blue"
                type="submit"
                isLoading={isLoading}
              >
                {t('resetPassword@submit')}
              </Button>
            </Stack>
          </form>
        </Box>
      </Box>
    </AuthWrapper>
  )
}

export default ResetPassword
