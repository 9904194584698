import React, { useEffect } from 'react'
import { Tabs, TabPanels, TabPanel, Spinner, Flex, Box } from '@chakra-ui/react'
import All from './All'
import { useInView } from 'react-intersection-observer'

const TabsCollaborations = ({
  total,
  initiatives,
  needCategories,
  onLoadMore,
  filterByCategory,
  chosenCategories,
}) => {
  const { ref: inViewRef, inView } = useInView()

  useEffect(() => {
    if (total === initiatives?.length) {
      return
    }
    if (inView) {
      onLoadMore()
    }
  }, [inView])
  return (
    <>
      <Tabs mt="2">
        <TabPanels>
          <TabPanel p="0">
            <All
              initiatives={initiatives}
              filterByCategory={filterByCategory}
              chosenCategories={chosenCategories}
              needCategories={needCategories}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      {total > initiatives?.length && (
        <Box
          position="absolute"
          bottom="30%"
          zIndex={1000}
          ref={inViewRef}
          w="full"
          h={20}
          visibility="hidden"
        />
      )}
      {total > initiatives?.length && (
        <Flex mt={5} w="full" align="center" justifyContent="center">
          <Spinner color="blue.300" />
        </Flex>
      )}
    </>
  )
}

export default TabsCollaborations
