import moment from 'moment'

const reportUseCase = (reportRepo) => {
  const saveProjectReports = async ({
    reports,
    editedReports,
    deletedReports,
    createdReports,
    projectId,
    reportsData,
  }) => {
    if (
      !deletedReports.current.length &&
      !editedReports.current.length &&
      !createdReports.current.length
    ) {
      throw new Error('Nothing to report')
    }
    if (!reportsData) {
      return await Promise.all(
        reports.map(({ ...report }) => {
          return reportRepo.createReport({
            projectId,
            ...report,
          })
        })
      )
    }
    if (deletedReports.current) {
      await Promise.all(
        deletedReports.current.map((id) => reportRepo.deleteReport(id))
      )
    }
    if (editedReports.current) {
      await Promise.all(
        editedReports.current.map((id) =>
          reportRepo.editReport({
            id,
            ...reports.find((report) => report.id === id),
          })
        )
      )
    }
    if (createdReports.current) {
      await Promise.all(
        createdReports.current.map((id) => {
          return reportRepo.createReport({
            projectId,
            ...reports.find((report) => report.id === id),
          })
        })
      )
    }

    return { success: true }
  }

  const listReportsByDate = async () => {
    const reportsResponse = await reportRepo.listReportsByDate()
    return reportsResponse.data.reportsByHasImage.items
  }

  const getReportsByProjectId = async (variables) => {
    if (!variables.projectId) {
      return []
    }
    const response = await reportRepo.getReportsByProjectId(variables)
    if (!response.data.reportsByProjectId.items.length) return null
    return response.data.reportsByProjectId.items.map((report) => ({
      ...report,
      formattedDate: moment(report.createdAt).format('MMMM DD, YYYY'),
      fromNow: moment(report.createdAt).fromNow(),
    }))
  }

  const deleteReport = (input) => reportRepo.deleteReport(input)

  const editReport = (input) => {
    if (
      input.data_en.includes('<img') &&
      input.data_ru.includes('<img') &&
      input.data_hy.includes('<img')
    ) {
      input = { ...input, hasImage: 'true' }
    } else {
      input = { ...input, hasImage: 'false' }
    }
    reportRepo.editReport(input)
  }

  const createReport = (input) => {
    if (
      input.data_en.includes('<img') &&
      input.data_ru.includes('<img') &&
      input.data_hy.includes('<img')
    ) {
      input = { ...input, hasImage: 'true' }
    } else {
      input = { ...input, hasImage: 'false' }
    }
    return reportRepo.createReport(input)
  }

  const editReportWithDate = async ({ id, ...input }) => {
    if (
      input.data_en.includes('<img') &&
      input.data_ru.includes('<img') &&
      input.data_hy.includes('<img')
    ) {
      input = { ...input, hasImage: 'true' }
    } else {
      input = { ...input, hasImage: 'false' }
    }
    const createdReport = await reportRepo.createReport({ id, ...input })

    return createdReport
  }

  return {
    createReport,
    editReport,
    deleteReport,
    listReportsByDate,
    getReportsByProjectId,
    editReportWithDate,
    saveProjectReports,
  }
}

export default reportUseCase
