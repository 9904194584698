import React from 'react'
import { Image } from '@chakra-ui/react'

const ImageWrapper = ({ src, ignoreFallback = true, ...props }) => (
  <Image
    {...props}
    src={`${process.env.REACT_APP_S3_BUCKET_URL}/public${src}`}
    fallbackSrc={'/assets/images/noImg.svg'}
    ignoreFallback={ignoreFallback}
  />
)

export default ImageWrapper
