/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cloud_logic_custom: [
    {
      name: 'elastic',
      endpoint: 'https://q4cht3r1m7.execute-api.us-east-1.amazonaws.com/demo',
      region: 'us-east-1',
    },
    {
      name: 'email',
      endpoint: 'https://1y29pom9nd.execute-api.us-east-1.amazonaws.com/demo',
      region: 'us-east-1',
    },
    {
      name: 'export',
      endpoint: 'https://9b0eos3y5l.execute-api.us-east-1.amazonaws.com/demo',
      region: 'us-east-1',
    },
    {
      name: 'mailerLiteAPI',
      endpoint: 'https://pivsxd1xc9.execute-api.us-east-1.amazonaws.com/demo',
      region: 'us-east-1',
    },
    {
      name: 'paymentAPI',
      endpoint: 'https://fdgzk59m3l.execute-api.us-east-1.amazonaws.com/demo',
      region: 'us-east-1',
    },
    {
      name: 'sharingAPI',
      endpoint: 'https://tye5di441a.execute-api.us-east-1.amazonaws.com/demo',
      region: 'us-east-1',
    },
    {
      name: 'stripePaymentAPI',
      endpoint: 'https://dww2fevlh4.execute-api.us-east-1.amazonaws.com/demo',
      region: 'us-east-1',
    },
    {
      name: 'syncPrismicWithDDB',
      endpoint: 'https://umrnbru6zi.execute-api.us-east-1.amazonaws.com/demo',
      region: 'us-east-1',
    },
    {
      name: 'user',
      endpoint: 'https://g7fe2mjdd2.execute-api.us-east-1.amazonaws.com/demo',
      region: 'us-east-1',
    },
  ],
  aws_appsync_graphqlEndpoint:
    'https://voaigmnbunhqlnf7yp6n6qyeea.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-nz7vuz2ko5f2dbpijrfqxszrwm',
  aws_cognito_identity_pool_id:
    'us-east-1:71037b5a-7804-491f-859c-c172feb1fae3',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_VruGboXhU',
  aws_user_pools_web_client_id: '5mngoucrcpdj0b2c6s6n5k1cpm',
  oauth: {
    domain: 'rearmenia668c7d55-668c7d55-demo.auth.us-east-1.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn:
      'http://localhost:3000/,https://staging.rearmenia.com/,https://rearmenia.com/',
    redirectSignOut:
      'http://localhost:3000/,https://staging.rearmenia.com/,https://rearmenia.com/',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: ['FACEBOOK', 'GOOGLE'],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_user_files_s3_bucket:
    'rearmeniae4cf535a688d4c98a974b86967d6a64f1c002-demo',
  aws_user_files_s3_bucket_region: 'us-east-1',
}

export default awsmobile
