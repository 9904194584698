import { Square, useId, useRadio } from '@chakra-ui/react'
import * as React from 'react'

export const RadioOption = (props) => {
  const { getInputProps, getCheckboxProps, getLabelProps } = useRadio(props)
  const id = useId()
  const { isChecked, ...rest } = props
  return (
    <label style={{ width: '100%', height: '100%' }} {...getLabelProps()}>
      <input {...getInputProps()} aria-labelledby={id} />
      <Square
        id={id}
        px={{ base: 0.5, sm: 4 }}
        borderRadius={4}
        maxW="120px"
        minW="80px"
        h={{ base: '48px', sm: '56px' }}
        fontSize="lg"
        transition="all 0.2s"
        cursor="pointer"
        color="gray.800"
        _hover={{
          bg: 'rgba(1, 47, 117, 0.15)',
        }}
        _checked={{
          bg: 'white',
        }}
        {...getCheckboxProps(rest)}
      />
    </label>
  )
}
