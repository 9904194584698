import {
  useCreateMailerLiteFields,
  useCustomToast,
  useUpdateProjectSystem,
} from 'hooks'
import ApplicationCard from 'components/ApplicationCard'
import { useTranslation } from 'contexts/TranslationContext'

export default function MailerLite({ project, projectSystem }) {
  const { mutate: updateProjectSystem, isLoading: isLoadingUpdate } =
    useUpdateProjectSystem()

  const { mutate: createMailerLiteFields, isLoading: isLoadingFields } =
    useCreateMailerLiteFields()

  const isLoading = isLoadingUpdate || isLoadingFields

  const toast = useCustomToast()
  const { t } = useTranslation()

  const onSubmit = ({ mailerLiteApiKey }) => {
    const onSuccess = () => {
      createMailerLiteFields(
        {
          projectId: project.id,
        },
        {
          onSuccess: () => {
            toast({
              status: 'success',
              title: t('Successfully saved.'),
            })
          },
        }
      )
    }

    updateProjectSystem(
      {
        id: projectSystem.id,
        mailerLiteApiKey: mailerLiteApiKey,
      },
      { onSuccess }
    )
  }

  return (
    <ApplicationCard
      variant="variant1"
      logo="mailerlite.png"
      heading="MailerLite"
      description="Effectively communicate with your donors using Email Marketing."
      buttonText="Save"
      isLoading={isLoading}
      onSubmit={onSubmit}
      inputs={[
        {
          fieldKey: 'mailerLiteApiKey',
          placeholder: 'API Key',
          defaultValue: projectSystem?.mailerLiteApiKey,
        },
      ]}
    />
  )
}
