import { Center, Flex, Spinner } from '@chakra-ui/react'
import { Notification } from './Notification'
import { useTranslation } from 'contexts/TranslationContext'
import {
  useListNotificationsByUserId,
  useUpdateNotification,
  useUpdateUser,
  useUser,
} from 'hooks'
import { useNavigation } from 'pages'
import EmptyState from 'components/EmptyState'

export const NotificationList = ({ onClick }) => {
  const { t, language } = useTranslation()
  const { user } = useUser()
  const { updateUser } = useUpdateUser()
  const { data, isLoading } = useListNotificationsByUserId({
    userId: user.id,
    sortDirection: 'DESC',
  })

  const { updateNotification } = useUpdateNotification()
  const { navigationPush } = useNavigation()
  return (
    <Flex direction="column">
      {isLoading ? (
        <Center h="100%">
          <Spinner />
        </Center>
      ) : data ? (
        data?.map((notification) => (
          <Notification
            key={notification.id}
            onClick={(data, link) => {
              if (typeof onClick === 'function') {
                onClick({ from: 'notification', link: link })
                return
              }
              updateNotification(data, {
                onSuccess: () => {
                  if (!notification.isRead) {
                    updateUser({
                      id: user.id,
                      unreadNotifications: (user?.unreadNotifications || 0) - 1,
                    })
                  }

                  navigationPush(link)
                },
                onError: (err) => {
                  console.log('❌', err)
                },
              })
            }}
            t={t}
            {...notification}
            language={language}
          />
        ))
      ) : (
        <Flex justifyContent="center" mb={6}>
          <EmptyState
            description={t('profile@notification@empty')}
            button=""
            mobileMT={0}
            desktopMT={0}
          />
        </Flex>
      )}
    </Flex>
  )
}
