import React from 'react'
import {
  Box,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Flex,
} from '@chakra-ui/react'
import { ReactComponent as AngleDown } from 'assets/icons/angleDown.svg'
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg'
import { useScreenSize } from 'contexts'

const MenuButtonBox = ({ children, variant, ...props }) => {
  if (variant === 'border') {
    return (
      <Box
        fontWeight="bold"
        justifyContent="space-between"
        px={4}
        py={2}
        {...props}
      >
        {children}
      </Box>
    )
  }

  if (variant === 'noBorder') {
    return (
      <Box fontWeight="600" {...props}>
        {children}
      </Box>
    )
  }

  if (variant === 'big') {
    return (
      <Box fontWeight="700" fontSize="3xl" {...props}>
        {children}
      </Box>
    )
  }

  return (
    <Box
      justifyContent="center"
      borderBottom="2px"
      borderColor="transparent"
      transition="all 0.2s"
      whiteSpace="nowrap"
      fontSize="lg"
      fontWeight="regular"
      bg="white"
      zIndex={2}
      px="2"
      {...props}
    >
      {children}
    </Box>
  )
}

export const BlueSelect = ({
  label,
  labelFontSize = 'unset',
  selectOption,
  options,
  isInvalid,
  errorMessage,
  display,
  type,
  white,
  width = 'auto',
  offset = [0, 0],
  amount = 0,
  selectedOption,
  currentSelectedOption,
  variant,
  maxW,
  isDisabled,
  minW,
  menuProps = {},
  menuButtonProps = {},
  itemsAutofocusDisabled = false,
  ...props
}) => {
  const { isMobile } = useScreenSize()
  const disableItemsAutofocus = isMobile || itemsAutofocusDisabled

  return (
    <Box
      width={width}
      maxW={maxW}
      minW={minW}
      display={display}
      justifyContent="flex-end"
      {...props}
    >
      {isInvalid && (
        <Box
          top="calc(100% + 5px)"
          fontSize="sm"
          left="2"
          fontWeight="semibold"
          position="absolute"
          color="red.400"
        >
          {errorMessage}
        </Box>
      )}
      <Menu offset={offset} variant={variant} closeOnSelect {...menuProps}>
        <MenuButton
          as={variant === 'big' ? Button : MenuButton}
          w={width}
          {...menuButtonProps}
        >
          <MenuButtonBox
            variant={variant}
            label={label}
            display="flex"
            alignItems="center"
            whiteSpace="pre"
            color={white ? 'white' : 'black'}
            fontSize={labelFontSize}
          >
            <Flex alignItems="center">{label}</Flex>

            <AngleDown
              fill={'black'}
              style={{ marginLeft: 10, fill: white ? '#ffffff' : '#272727' }}
              width={10}
              height={8}
            />
          </MenuButtonBox>
        </MenuButton>
        <MenuList
          boxShadow="0 12px 16px 0 rgba(0,0,0,0.15)"
          border="none"
          maxH="300px"
          overflowY="auto"
        >
          {options.map((option) => (
            <MenuItem
              isDisabled={isDisabled}
              key={`${type}-${option.title}`}
              onClick={() => selectOption(option.value)}
              background="blue.400"
              icon={
                <CheckIcon
                  opacity={
                    currentSelectedOption === option.title ||
                    selectedOption === option.value
                      ? 1
                      : 0
                  }
                  width="13px"
                />
              }
              onFocus={
                !disableItemsAutofocus ? undefined : (e) => e.target.blur()
              }
            >
              {option.title}
              {amount > 0 && (
                <>
                  {` | `}
                  {option.amount}
                </>
              )}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  )
}
