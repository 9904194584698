const Button = {
  // The styles all button have in common
  baseStyle: {
    py: '0',
    minHeight: '48px',
    width: 'auto',
    borderRadius: '8px',
    fontWeight: '600',
    textTransform: 'uppercase',
    fontSize: '16px',
    boxShadow: 'none',
    textAlign: 'center',
    _focus: {
      boxShadow: 'none',
    },
    _disabled: {
      filter: 'brightness(1)',
      opacity: 1,
      color: 'white',
      backgroundColor: 'gray.500',
    },
    _hover: {
      filter: 'brightness(1.15)',
      _disabled: {
        filter: 'brightness(1)',
        color: 'white',
        backgroundColor: 'gray.500',
      },
    },
    _active: {
      overflow: 'hidden',
      filter: 'brightness(0.85)',
    },
  },
  variants: {
    white: {
      color: 'black',
      backgroundColor: 'white',
      borderColor: 'border.100',
      borderWidth: 1,
    },
    black: {
      backgroundColor: 'gray.800',
      color: 'white',
    },
    blue: {
      backgroundColor: 'blue.400',
      color: 'white',
    },
    orange: {
      backgroundColor: 'orange.400',
      color: 'white',
    },
    green: {
      backgroundColor: 'green.400',
      color: 'white',
    },
    border: {
      backgroundColor: 'transparent',
      color: 'gray.800',
      border: '1px solid',
      borderColor: '#ddd',
      boxShadow: 'none',
      _hover: {
        opacity: 0.75,
      },
    },
    gray: {
      backgroundColor: 'gray.800',
      color: 'gray.800',
      boxShadow: 'none',
      _hover: {
        opacity: 0.75,
      },
    },
    lightGray: {
      backgroundColor: 'gray.500',
      color: 'white',
      boxShadow: 'none',
    },
    grayV2: {
      bg: 'gray.300',
      color: 'gray.800',
      borderColor: 'gray.600',
      borderWidth: 1,
      borderRadius: 12,

      _hover: {
        filter: 'none',
      },
    },
    transparent: {
      backgroundColor: 'transparent',
      color: 'blue.400',
      border: 'none',
      boxShadow: 'none',
      padding: 0,
      minHeight: 'unset',
      height: 'unset',
      _disabled: {
        filter: 'brightness(1)',
        color: 'gray.600',
        backgroundColor: 'transparent',
      },
      _hover: {
        _disabled: {
          filter: 'brightness(1)',
          color: 'gray.600',
          backgroundColor: 'transparent',
        },
      },
    },
    socialIcon: {
      w: '56px',
      height: '56px',
      backgroundColor: 'gray.400',
      borderRadius: '12px',
    },
  },
  // The default size and variant values
  defaultProps: {
    size: 'md',
    variant: 'transparent',
  },
  sizes: {
    sm: {
      field: {
        height: '36px',
      },
    },
    md: {
      field: {
        height: '40px',
      },
    },
    lg: {
      field: {
        height: '48px',
      },
    },
  },
}

export default Button
