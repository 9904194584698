import React from 'react'
import { Box, Flex, Spinner } from '@chakra-ui/react'
import _ from 'lodash'
import Tabs from './Tabs'
import Navbar from 'components/Navbar'
import { LANG_HY_SHORT } from 'contexts/TranslationContext'

const MobileCollaborations = ({
  initiatives,
  total,
  isLoading,
  onLoadMore,
  NeedCategories,
  language,
  needCategories,
  chooseCategory,
  chosenCategories,
  filterByCategory,
  t,
}) => {
  return (
    <Box pb="6rem">
      <Navbar
        title={t('Collaborations')}
        titleProps={{ fontSize: language === LANG_HY_SHORT ? '16px' : '20px' }}
      />
      <NeedCategories
        lang={language}
        categories={needCategories}
        chooseCategory={chooseCategory}
        chosenCategories={chosenCategories}
        t={t}
      />
      {isLoading && (!initiatives || initiatives?.length === 0) ? (
        <Flex minH="520px" w="full" align="center" justifyContent="center">
          <Spinner color="blue.300" />
        </Flex>
      ) : (
        <Tabs
          total={total}
          initiatives={_.uniqBy(initiatives, 'id')}
          onLoadMore={onLoadMore}
          needCategories={needCategories}
          filterByCategory={filterByCategory}
          chosenCategories={chosenCategories}
        />
      )}
    </Box>
  )
}

export default MobileCollaborations
