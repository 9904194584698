function getNextRecurringChargeDate() {
  const today = new Date()
  let year = today.getFullYear()
  let month = today.getMonth()

  month += 1 // Move to the next month
  if (month > 11) {
    month = 0 // January is 0, reset to January if it's currently December
    year += 1 // Increment the year for January of the next year
  }

  // Always return the first day of the next month
  return `${year}-${String(month + 1).padStart(2, '0')}-01`
}

module.exports.getNextRecurringChargeDate = getNextRecurringChargeDate
