const bindingCardRepo = (bindingCardService) => {
  const deleteBindingCard = async (id) =>
    bindingCardService.deleteBindingCard(id)

  return {
    deleteBindingCard,
  }
}

export default bindingCardRepo
