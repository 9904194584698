export const useStringManipulations = () => {
  const lowerCase = (data) => {
    if (typeof data === 'string') {
      return data.toLowerCase()
    }
    if (Array.isArray(data)) {
      return data.map((e) => e.toLowerCase())
    }
    return data
  }
  const findNamingTranslation = ({ parentObject, value, language }) =>
    parentObject?.[`${value}_${language}`] ||
    parentObject?.[`${value}_en`] ||
    parentObject?.[`${value}_hy`] ||
    parentObject?.[`${value}_ru`] ||
    parentObject?.[`${value}`]
  return { lowerCase, findNamingTranslation }
}
export default useStringManipulations
