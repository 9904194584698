import React from 'react'
import { Flex, Heading } from '@chakra-ui/react'
import { ReactComponent as Close } from 'assets/icons/close.svg'
import { useHistory } from 'react-router-dom'

const Header = ({ title }) => {
  const history = useHistory()
  return (
    <Flex
      w="full"
      borderBottom="1px solid"
      borderColor="border.100"
      justifyContent="center"
      position="relative"
      alignItems="center"
      py="3"
      mb={10}
    >
      <Flex
        position="absolute"
        left="12px"
        onClick={() => history.goBack()}
        width="30px"
        height="30px"
        alignItems="center"
      >
        <Close fill="black" width="15px" height="15px" />
      </Flex>
      <Heading as="h1" textAlign="center" fontSize="xl" fontWeight={500}>
        {title}
      </Heading>
    </Flex>
  )
}

export default Header
