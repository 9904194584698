import React from 'react'
import { Heading, Box, HStack } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import ProjectSkeletonCard from 'components/ProjectSkeletonCardMobile'
import NoScrollbar from 'components/NoScrollbar'
import UpdateCard from './UpdateCard'

const Updates = ({ updates, isLoading, updatesRef }) => {
  const { t, language } = useTranslation()
  return (
    <Box py="8">
      <Heading
        fontSize="1.625rem"
        fontWeight="700"
        as="h2"
        mb="6"
        px="5"
        textTransform="capitalize"
      >
        {t('updates')}
      </Heading>
      <NoScrollbar
        Component={HStack}
        mb="6"
        spacing="20px"
        pl="10"
        alignItems="flex-start"
        overflowX="scroll"
        overflowY="hidden"
        ref={updatesRef}
      >
        {isLoading
          ? [1, 2].map((item) => (
              <Box key={item} ml="-5" pr="5">
                <ProjectSkeletonCard />
              </Box>
            ))
          : updates?.map((item) => (
              <Box key={item.id} ml="-5" pr="5">
                <UpdateCard
                  projectId={item.projectId}
                  updateId={item.id}
                  html={item[`data_${language}`]}
                />
              </Box>
            ))}
      </NoScrollbar>
    </Box>
  )
}

export default Updates
