import React, { useEffect } from 'react'
import {
  Box,
  Button,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { InputField } from 'components/InputField'
import useConnectExpert from 'hooks/expert'
import { useTranslation } from 'contexts/TranslationContext'
import { useLocalStorage, useObjectManipulations } from 'hooks'
import { useUser } from 'hooks'
import { TextAreaComponent } from 'components/TextAreaComponent'

export default function ExpertsConnectModal({ expert, setModal }) {
  const { t, language } = useTranslation()
  const { get, remove } = useLocalStorage()
  const { user } = useUser()
  const { safeParser } = useObjectManipulations()
  const { mutate: connect, isLoading } = useConnectExpert()

  let cachedExpert = {}
  if (!expert && get('expertToConnect')) {
    cachedExpert = safeParser(get('expertToConnect'))
  }

  const getExpertKey = (key) => expert?.[key] || cachedExpert?.[key]

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })
  useEffect(() => {
    if (user.id !== 'guest') {
      setValue('firstName', user.firstName)
      setValue('lastName', user.lastName)
      setValue('email', user.email)
    }
  }, [user])

  const onSubmit = (formData) => {
    const input = {
      adminStatus: 'not_sent',
      expertStatus: 'not_sent',
      initiatorFirstName: formData.firstName,
      initiatorLastName: formData.lastName,
      initiatorEmail: formData.email,
      initiatorPhone: formData.phone,
      initiatorLink: formData.link,
      initiatorMessage: formData.message,
      initiatorSupport: formData.support,
      expertFirstName: getExpertKey('firstName'),
      expertLastName: getExpertKey('lastName'),
      expertEmail: getExpertKey('email'),
      expertPhone: getExpertKey('phone'),
      expertLinkedinLink: getExpertKey('linkedin'),
      expertLink: getExpertKey('otherLink'),
      expertImageUrl: getExpertKey('image'),
      expertTitle: getExpertKey('position'),
      expertType: getExpertKey('getInvolvedStatus'),
      expertHours: getExpertKey('workTime'),
      expertSpheres: JSON.stringify(getExpertKey('expertise')),
      expertDirections: JSON.stringify(getExpertKey('directions')),
      expertOccupation: getExpertKey('position'),
      expertLanguage: '',
      projectTitle: formData.projectTitle,
      language,
    }

    connect(input, {
      onSuccess() {
        setModal({ name: 'applyExpert' })
      },
      onError(err) {
        console.log('err', err)
      },
    })
    remove('expertToConnect')
  }
  const onError = (error) => {
    console.log(error)
  }

  return (
    <>
      <ModalHeader
        pt={7}
        px={7}
        pb={0}
        fontSize="3xl"
        fontWeight="bold"
        color="gray.800"
      >
        {t('connectForm@title')}
      </ModalHeader>
      <ModalCloseButton
        _active={{ background: 'transparent' }}
        _hover={{ background: 'transparent' }}
      />
      <ModalBody>
        <Box py={3} borderY="1px solid" borderColor="gray.500">
          <Text fontSize="md" color="gray.800">
            {t('connectForm@description')}
          </Text>
        </Box>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            spacingX="16px"
            mt={2}
            alignItems="center"
          >
            <InputField
              formControlProps={{ mt: 5 }}
              label={t('connectForm@firstName')}
              size="sm"
              borderRadius="lg"
              borderColor="gray.500"
              isInvalid={errors.firstName}
              errorMessage={t('signUp@firstNameErrorMessage')}
              {...register('firstName', {
                required: true,
              })}
            />
            <InputField
              formControlProps={{ mt: 5 }}
              label={t('connectForm@lastName')}
              size="sm"
              borderRadius="lg"
              borderColor="gray.500"
              isInvalid={errors.lastName}
              errorMessage={t('signUp@lastNameErrorMessage')}
              {...register('lastName', {
                required: true,
              })}
            />
          </SimpleGrid>
          <InputField
            mb={2}
            formControlProps={{ mt: 5 }}
            label={t('connectForm@emailAddress')}
            size="sm"
            borderRadius="lg"
            borderColor="gray.500"
            isInvalid={errors.email}
            errorMessage={errors?.mail?.message}
            {...register('email', {
              required: {
                value: true,
                message: t('signUp@emailErrorMessage'),
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: t('createEditInitiative@emailError'),
              },
            })}
          />
          <InputField
            mb={2}
            formControlProps={{ mt: 5 }}
            label={t('connectForm@phoneNumber')}
            size="sm"
            borderRadius="lg"
            borderColor="gray.500"
            isInvalid={errors.phone}
            errorMessage={t('profile@settings-phoneNumberErrorMessage')}
            {...register('phone', {
              required: true,
            })}
          />
          <InputField
            mb={2}
            formControlProps={{ mt: 5 }}
            label={t('connectForm@mediaLinks')}
            size="sm"
            borderRadius="lg"
            borderColor="gray.500"
            isInvalid={errors.link}
            {...register('link', {
              required: false,
            })}
          />
          <InputField
            mb={2}
            formControlProps={{ mt: 5 }}
            label={t('connectForm@projectTitle')}
            size="sm"
            borderRadius="lg"
            borderColor="gray.500"
            isInvalid={errors.projectTitle}
            errorMessage={t('field is required')}
            {...register('projectTitle', {
              required: true,
            })}
          />
          <TextAreaComponent
            isInvalid={!!errors.message}
            formControlProps={{ mt: 5, mb: 10 }}
            description={t('connectForm@projectDescription')}
            errorMessage={t('field is required')}
            maxLength={1000}
            {...register('message', {
              required: true,
              maxLength: 1000,
            })}
          />
          <TextAreaComponent
            isInvalid={!!errors.support}
            formControlProps={{ mt: 5, mb: 4 }}
            description={t('connectForm@projectSupport')}
            errorMessage={t('field is required')}
            maxLength={1000}
            {...register('support', {
              required: true,
              maxLength: 1000,
            })}
          />
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          w="100%"
          variant="blue"
          borderRadius="xl"
          fontSize="md"
          fontWeight="bold"
          isLoading={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {t('send')}
        </Button>
      </ModalFooter>
    </>
  )
}
