import React from 'react'
import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import Header from 'components/MobileHeader'
import Share from '../Share'
import Content from 'components/Content'
import Swiper from 'components/MobileSwiper'

const Mobile = ({ data, isLoading }) => {
  return (
    <Box pb={10}>
      <Header
        backTo="/blog"
        share={{
          title: data?.title,
          description: data?.description,
        }}
      />
      {!isLoading && (
        <Flex
          w="full"
          alignItems="flex-start"
          justifyContent="center"
          direction="column"
        >
          <Swiper images={data.images} />
          <Box pt="7" w="100%">
            <Box px="5">
              <Heading as="h2" fontSize="3xl">
                {data.title}
              </Heading>

              <Text
                fontSize="md"
                key={`project-createdAt`}
                my="4"
                color="gray.800"
              >
                {data.date}
              </Text>

              <Text fontSize="md" mb="9" color="rgba(26, 26, 26, 0.6)">
                {data.description}
              </Text>
            </Box>

            <Box
              sx={{
                img: {
                  borderRadius: '0 !important',
                },
                '> div > *:not(img)': {
                  padding: '0 20px',
                },
              }}
            >
              <Content html={data.content} />
            </Box>

            <Box px="5">
              <Share isMobile={true} />
            </Box>
          </Box>
        </Flex>
      )}
    </Box>
  )
}

export default Mobile
