import { Flex, Image, Text, VStack } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import React from 'react'

export default function ToRearmeniaTotal({ amount, currency, ...rest }) {
  const { t } = useTranslation()
  return (
    <VStack
      p={6}
      spacing={4}
      borderRadius="12px"
      borderColor="border.100"
      borderWidth="1px"
      {...rest}
    >
      <Flex w="full" alignItems="center" fontSize="sm">
        <Image src="/assets/images/rearmenia_avatar.png" />
        <Text fontWeight={600} ml={4}>
          {t('reArmeniaCollabPlatform')}
        </Text>
      </Flex>
      <Text color="gray.700" fontSize="sm">
        {t('reArmeniaMonthlyCharge').replace(
          '[x amount]',
          `${amount} ${currency}`
        )}
      </Text>
      <Flex
        justifyContent="space-between"
        w="full"
        fontWeight={600}
        fontSize="lg"
        pt={4}
        borderTopWidth="1px"
        borderTopColor="border.100"
      >
        <Text>{t('donation@yourDonation')}</Text>
        <Flex>
          <Text mr={2}>{amount}</Text>
          <Text color="gray.600">
            {currency}/{t('mo')}
          </Text>
        </Flex>
      </Flex>
    </VStack>
  )
}
