import React, { useState } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Flex,
  Image,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import {
  useDonationSubscriptionsByUserEmail,
  useStringManipulations,
  useUpdateDonationSubscription,
  useUser,
} from 'hooks'
import ImageWrapper from 'components/ImageWrapper'
import Navbar from 'components/Navbar'

export default function ListSubscriptions() {
  const [activeCard, setActiveCard] = useState(null)
  const { t, language } = useTranslation()
  const { user } = useUser()
  const { donationSubscriptions, isLoading, refetch } =
    useDonationSubscriptionsByUserEmail({
      sortDirection: 'DESC',
      userEmail: user?.email,
    })

  const { findNamingTranslation } = useStringManipulations()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { updateDonationSubscription } = useUpdateDonationSubscription()
  const onAlertOpen = (e, donationSubscription) => {
    setActiveCard(donationSubscription)
    e.preventDefault()
    onOpen()
  }

  const onUnsubscribe = (e, donationSubscription) => {
    e.stopPropagation()
    updateDonationSubscription(
      {
        id: donationSubscription.id,
        unsubscribedDate: new Date().toISOString(),
      },
      {
        onSuccess: () => {
          refetch()
          onClose()
        },
        onError: (e) => console.log('>>> UNSUBSCRIBE ERROR', e),
      }
    )
  }

  return (
    <>
      <Navbar
        title={t('profile@dashboard@investor@recurring@subscriptions')}
        backTo="/profile/history/monthly"
      />
      {isLoading ? (
        <Center h={{ base: 'calc(100vh - 48px)', sm: '100%' }}>
          <Spinner />
        </Center>
      ) : (
        <VStack
          spacing={4}
          align="stretch"
          p={5}
          bg="#F3F3F3"
          height="calc(100dvh - 48px)"
        >
          {donationSubscriptions?.map((sub) => (
            <Flex
              key={sub.image}
              p={4}
              justify="space-between"
              boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0.06), 0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0px 16px 6px 0px rgba(0, 0, 0, 0.01), 0px 25px 7px 0px rgba(0, 0, 0, 0.00)"
              rounded="lg"
              bg="white"
              align="center"
            >
              <Flex align="center" mr="5px">
                <DonationImage image={sub?.image} />

                <Text
                  fontWeight={600}
                  fontSize="lg"
                  color="gray.800"
                  noOfLines={1}
                  maxW="calc(100vw - 275px)"
                  height="fit-content"
                >
                  {(sub?.project &&
                    findNamingTranslation({
                      parentObject: sub.project,
                      value: 'title',
                      language,
                    })) ||
                    'reArmenia'}
                </Text>
              </Flex>

              {sub?.unsubscribedDate ? (
                <Button
                  height={10}
                  minH={10}
                  textTransform="none"
                  variant="lightGray"
                  fontSize="lg"
                  _hover={{}}
                  disabled
                  px="14px"
                  flexShrink={0}
                >
                  {t('profile@dashboard@investor@recurring@unsubscribed')}
                </Button>
              ) : (
                <Button
                  height={10}
                  minH={10}
                  textTransform="none"
                  variant="white"
                  fontSize="lg"
                  px="14px"
                  flexShrink={0}
                  onClick={(e) => onAlertOpen(e, sub)}
                >
                  {t('profile@dashboard@investor@recurring@unsubscribe')}
                </Button>
              )}
            </Flex>
          ))}
        </VStack>
      )}

      <AlertDialogModal
        t={t}
        onClose={onClose}
        donationSubscription={activeCard}
        isOpen={isOpen}
        onUnsubscribe={onUnsubscribe}
      />
    </>
  )
}

const DonationImage = ({ image }) => (
  <Box w="50px" h="50px" mr={3} flexShrink={0}>
    {image ? (
      <ImageWrapper
        borderRadius="50%"
        cursor="pointer"
        objectFit="cover"
        width="50px"
        bgColor="border.100"
        h="50px"
        ignoreFallback={true}
        src={`/thumbnail/small/${image}`}
      />
    ) : (
      <Image
        cursor="pointer"
        objectFit="cover"
        borderRadius="50%"
        bgColor="border.100"
        ignoreFallback={true}
        src="/assets/images/noImg.svg"
      />
    )}
  </Box>
)

const AlertDialogModal = ({
  t,
  onClose,
  isOpen,
  onUnsubscribe,
  isLoading,
  donationSubscription = null,
}) => {
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent px={5} py="22px" mx={5}>
        <AlertDialogBody
          p={0}
          mb={5}
          fontSize="lg"
          fontWeight={600}
          color="black"
        >
          {t('profile@dashboard@investor@recurring@areYouSure')}
        </AlertDialogBody>
        <AlertDialogFooter p={0}>
          <Button
            isDisabled={isLoading}
            minH="20px"
            minW="100px"
            variant="white"
            onClick={onClose}
          >
            {t('profile@dashboard@investor@recurring@no')}
          </Button>
          <Button
            onClick={(e) => onUnsubscribe(e, donationSubscription)}
            minH="20px"
            minW="100px"
            variant="blue"
            colorScheme="red"
            ml={3}
            isLoading={isLoading}
            isDisabled={isLoading}
          >
            {t('profile@dashboard@investor@recurring@yes')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
