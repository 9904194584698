import { imageUseCase } from '../../core/factories'

const useImage = () => {
  return {
    uploadImage: imageUseCase.uploadImage,
    getImageUrl: imageUseCase.getImageUrl,
  }
}

export default useImage
