import { Box, Stack, Text } from '@chakra-ui/react'
import { InputField } from 'components/InputField'
import { TextAreaComponent } from 'components/TextAreaComponent'
import { useTranslation } from 'contexts/TranslationContext'
import React from 'react'

export default function GiftCardForm({ register, errors, user, ...props }) {
  const { t } = useTranslation()
  return (
    <Box {...props}>
      <Stack direction={{ base: 'column' }} spacing="4">
        <Box
          mb="4"
          borderTopWidth={user.id === 'guest' ? 1 : 0}
          borderBottomWidth={1}
          borderColor="border.100"
          py={8}
        >
          <Text fontWeight="600" fontSize="xl">
            {t('deliveryInfo')}
          </Text>
          <InputField
            size="sm"
            isInvalid={!!errors.toEmail}
            label={t('recipientEmail') + '*'}
            labelProps={{ marginBottom: 1.5, padding: 0 }}
            {...register('toEmail', {
              required: true,
            })}
            borderRadius="8px"
            formControlProps={{
              margin: { base: '10px 0', sm: '0 10px 0 0' },
            }}
          />
        </Box>

        <Text fontWeight="600" fontSize="xl">
          {t('wantMessage')}
        </Text>
        <InputField
          size="sm"
          isInvalid={!!errors.from}
          label={t('from') + '*'}
          labelProps={{ marginBottom: 1.5, padding: 0 }}
          {...register('from', {
            required: true,
          })}
          borderRadius="8px"
        />

        <TextAreaComponent
          size="sm"
          isInvalid={!!errors.yourMessage}
          max
          description={t('addMessage')}
          maxLength={150}
          minLength={0}
          labelProps={{ marginBottom: 1.5, padding: 0 }}
          {...register('yourMessage', {})}
          borderRadius="8px"
        />
      </Stack>
    </Box>
  )
}
