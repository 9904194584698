import React, { useEffect } from 'react'
import { Flex, Spinner } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useNavigation } from 'pages'
import { useCheckOrder, useCustomToast, useQueryParams, useUser } from 'hooks'
import gtag from 'ga-gtag'
import amplitude from 'amplitude-js'
import { isMobileDevice } from 'utils'
import { PAYMENT_METHODS } from 'pages/Donation/constants'

function jsonParseSafe(stringJson) {
  try {
    if (typeof stringJson !== 'string') {
      console.log('ERROR WHILE PARSING JSON, stringJson is not string')
      return null
    }
    const result = JSON.parse(stringJson)
    return result
  } catch (error) {
    console.log('ERROR WHILE PARSING JSON', error)
    return null
  }
}

const redirectURL = (order, lang, success) => {
  const transaction = order.transaction
  const cachedDonatedForm = localStorage.getItem('donate-form')
  const donateForm = jsonParseSafe(cachedDonatedForm)

  if (!success || !transaction) {
    localStorage.removeItem('donate-form')

    const projectId = donateForm?.peerProjectId || donateForm?.projectId

    if (
      projectId &&
      donateForm?.amount &&
      donateForm?.firstName &&
      donateForm?.lastName &&
      donateForm?.email
    ) {
      return `/donation?projectId=${projectId}&amount=${donateForm?.amount}
      &firstName=${donateForm?.firstName}&lastName=${donateForm?.lastName}&email=${donateForm?.email}`
    }
  }
  if (order.orderStatus === 2) {
    if (transaction.giftCardTransactionType === 'buy') {
      return `/gift-card/thank-you`
    }

    if (donateForm?.peerProjectId) {
      return `/donate/thank-you?name=${transaction.firstName}&title=${encodeURI(
        donateForm?.peerProject?.[`title_${lang}`]
      )}&id=${
        donateForm?.peerProjectId
      }&monthly=${!!transaction.subscriptionId}&contribution=${
        transaction.projectTitle_en === 'rearmenia'
      }`
    }

    return `/donate/thank-you?name=${transaction.firstName}&title=${encodeURI(
      transaction[`projectTitle_${lang}`]
    )}&id=${
      transaction?.projectId
    }&monthly=${!!transaction.subscriptionId}&contribution=${
      transaction.projectTitle_en === 'rearmenia'
    }`
  }
  return '/'
}

const CheckTransaction = () => {
  const params = useQueryParams()
  const toast = useCustomToast()
  const { navigationReplace } = useNavigation()
  const { t, language } = useTranslation()
  const method =
    PAYMENT_METHODS[localStorage.getItem('paymentMethod')] ||
    PAYMENT_METHODS.ARCA_VISA_MASTER
  const {
    data: orderResponse,
    isLoading,
    error,
  } = useCheckOrder({
    orderId: params.get('orderId'),
    method,
  })
  const { getUser } = useUser()

  useEffect(() => {
    if (error) {
      amplitude
        .getInstance()
        .logEvent(`order-declined-${isMobileDevice() ? 'mobile' : 'desktop'}`)
      navigationReplace('/')
      toast({
        title: t(`payment@orderStatus-0`),
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
    if (isLoading) {
      return () => {}
    }

    const order = orderResponse.order || orderResponse

    const success = order.orderStatus === 2 && order.actionCode === 0
    const isCardAttached = order.transactionInfo?.attachCard
    if (order) {
      gtag('event', 'purchase', {
        transaction_id: order.orderNumber,
        affiliation: 'Rearmenia donation',
        value: order.amount / 100,
        currency: order.transaction.currency,
        items: [order.transaction],
      })
    }
    if (success) {
      if (isCardAttached) {
        toast({
          title: t(`thankyou@cardAttached@toaster`),
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      } else {
        toast({
          title: t(`payment@orderStatus-2`),
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      }
    } else {
      toast({
        title: t(`arca_message_action_code-${order.actionCode}`),
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }

    getUser()
    navigationReplace(redirectURL(order, language, success))
  }, [orderResponse, error])

  return (
    <Flex
      width="100%"
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner size="lg" />
    </Flex>
  )
}

export default CheckTransaction
