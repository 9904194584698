import React, { useEffect, useState } from 'react'
import { Box, Heading, Stack, Checkbox } from '@chakra-ui/react'
import { InputField } from 'components/InputField'
import { TextareaField } from 'components/TextareaFIeld'
import { Select } from 'components/Select'
import { useTranslation } from 'contexts/TranslationContext'
import { CATEGORY, REGIONS } from '../../../_constants'
import { useForm, useFieldArray } from 'react-hook-form'
import Files from './Files'
import BottomBar from '../BottomBar'
import Goals from 'components/GoalsForm'
import Options from 'components/OptionsForm'
import { BudgetFiles } from 'components/BudgetFiles'
import DatePickerGroup from './DatePickerGroup'
import NonProfitApplicationForm from './NonProfitApplicationForm'
import moment from 'moment'
import { FUNDRAISER_NONPROFIT } from 'constants/fundraiser'

const EditableApplication = ({ project, onUpdate, isOneTime }) => {
  const { t, language } = useTranslation()
  const [legal, setLegal] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    getValues,
    clearErrors,
    setValue,
    watch,
  } = useForm()
  const {
    fields: goalFields,
    append: appendGoal,
    remove: removeGoal,
  } = useFieldArray({
    control,
    name: 'goals',
  })
  const {
    fields: collaborationFields,
    append: appendCollaboration,
    remove: removeCollaboration,
  } = useFieldArray({
    control,
    name: 'collaborations',
  })

  const budgetFiles = watch('budgetFiles', [])
  const emptyNeeds = (collaboration) => collaboration.needs === ''

  const submit = (data) => {
    let goals = data.goals
    if (goals) {
      goals = goals.map((goal) => ({
        amount: parseFloat(goal.amount),
        description_en: goal.description_en,
        description_ru: goal.description_en,
        description_hy: goal.description_en,
      }))
    }

    let collaborations = data.collaborations
    if (collaborations) {
      setValidationErrors(collaborations.map(emptyNeeds))
    }
    if (!collaborations?.find(emptyNeeds)) {
      collaborations = collaborations.map((collaboration) => ({
        image: collaboration.image,
        needs: collaboration.needs,
        needs_en: collaboration.needs,
        needs_hy: collaboration.needs,
        needs_ru: collaboration.needs,
        needsDescription: collaboration.needsDescription,
        needsDescription_en: collaboration.needsDescription,
        needsDescription_hy: collaboration.needsDescription,
        needsDescription_ru: collaboration.needsDescription,
      }))

      const updateInput = {
        id: project.id,
        isSubmittedByLegalEntity: legal,
        summary: data.summary,
        applicationTitle: data.applicationTitle,
        organizationName: data.organizationName,
        organizationLegalForm: data.organizationLegalForm,
        region: data.region,
        problem: data.problem,
        solution: data.solution,
        contactFullName: data.contactFullName,
        contactEmail: data.email,
        phoneNumber: data.phone,
        otherDetails: data.otherDetails,
        links: data.links,
        TIN: data.TIN,
        category: data.category,
        totalAmount: Number(data.totalAmount),
        projectTeam: data.projectTeam,
        phases: data.phases,
        objective: data.objective,
        keyResults: data.keyResults,
        beneficiaries: data.beneficiaries,
        risks: data.risks,
        otherPlayers: data.otherPlayers,
        collaborations,
        goals,
        budgetFiles: data.budgetFiles,
        startDate: data.startDate
          ? moment(data.startDate).format('MM.DD.YYYY')
          : null,
        endDate: data.endDate
          ? moment(data.endDate).format('MM.DD.YYYY')
          : null,
      }

      if (project.fundraisingType === FUNDRAISER_NONPROFIT) {
        updateInput.mediaLink = data.mediaLink
        updateInput.applicationTitle = data.organizationName
      }

      onUpdate(updateInput)
    }
  }
  useEffect(() => {
    if (!project) return
    reset({
      category: project.category,
      region: project.region,
      problem: project.problem,
      summary: project.summary,
      solution: project.solution,
      contactFullName: project.contactFullName,
      email: project.contactEmail,
      phone: project.phoneNumber,
      applicationTitle: project.applicationTitle,
      links: project.links,
      otherDetails: project.otherDetails,
      organizationLegalForm: project.organizationLegalForm,
      organizationName: project.organizationName,
      isSubmittedByLegalEntity: project.isSubmittedByLegalEntity,
      totalAmount: project.totalAmount,
      goals: project?.goals ?? [],
      budgetFiles: project?.budgetFiles,
      collaborations: project?.collaborations ?? [],
      projectTeam: project?.projectTeam,
      phases: project.phases,
      objective: project.objective,
      keyResults: project.keyResults,
      beneficiaries: project.beneficiaries,
      risks: project.risks,
      otherPlayers: project.otherPlayers,
      files: project.files,
      TIN: project.TIN,
      mediaLink: project.mediaLink,
      startDate: project.startDate ? new Date(project.startDate) : null,
      endDate: project.endDate ? new Date(project.endDate) : null,
    })
    setLegal(project.isSubmittedByLegalEntity)
  }, [project])

  if (project.fundraisingType === FUNDRAISER_NONPROFIT) {
    return (
      <NonProfitApplicationForm
        onSubmit={handleSubmit(submit)}
        register={register}
        errors={errors}
        setValue={setValue}
      />
    )
  }

  return (
    <Box maxWidth="580px" width="100%">
      <form noValidate onSubmit={handleSubmit(submit)}>
        <Heading fontSize="5xl" as="h1">
          {t('createEditProject@projectApplication')}
        </Heading>
        <Stack my="6" spacing="6">
          <Heading as="h2" fontSize="xl">
            {t('Basic')}
          </Heading>
          <InputField
            isInvalid={!!errors.applicationTitle}
            label={t('createEditProject@projectTitle')}
            errorMessage={t('createEditProject@titleErrorMessage')}
            {...register('applicationTitle', { required: true })}
          />
          <TextareaField
            isInvalid={!!errors.summary}
            label={t('createEditProject@projectSummary')}
            errorMessage={t('createEditProject@projectSummaryRequired')}
            {...register('summary', { required: true })}
          />

          <Select
            type="region"
            {...register('region', { required: true })}
            isInvalid={!!errors.region}
            errorMessage={t('createEditProject@regionErrorMessage')}
            placeholder={t('createEditProject@region')}
            label={t('createEditProject@projectRegion')}
            options={REGIONS}
          />
          <Select
            type="category"
            {...register('category', { required: true })}
            isInvalid={!!errors.category}
            options={CATEGORY[language]}
            errorMessage={t('createEditProject@categoryErrorMessage')}
            placeholder={t('createEditProject@category')}
            label={t('createEditProject@projectCategory')}
          />

          <TextareaField
            isInvalid={!!errors.problem}
            {...register('problem', { required: true })}
            label={t('createEditProject@projectProblem')}
            errorMessage={t('createEditProject@projectProblemRequired')}
          />
          <TextareaField
            isInvalid={!!errors.solution}
            label={t('createEditProject@projectSolution')}
            errorMessage={t('createEditProject@solutionErrorMessage')}
            {...register('solution', { required: true })}
          />
          <TextareaField
            isInvalid={!!errors.projectTeam}
            errorMessage={t('createEditProject@projectProblemRequired')}
            label={t('createEditProject@teamBehindProject')}
            {...register('projectTeam')}
          />
          {project.isProject && (
            <>
              <Heading mb="5" as="h2" fontSize="xl">
                {t('details')}
              </Heading>
              <TextareaField
                isInvalid={!!errors.beneficiaries}
                label={t('createEditProject@beneficiaries')}
                errorMessage={t('createEditProject@beneficiariesErrorMessage')}
                {...register('beneficiaries', { required: true })}
              />
              <TextareaField
                isInvalid={!!errors.objective}
                label={t('createEditProject@objective')}
                errorMessage={t('createEditProject@objectiveErrorMessage')}
                {...register('objective', { required: true })}
              />
              <TextareaField
                isInvalid={!!errors.keyResults}
                label={t('createEditProject@keyResults')}
                errorMessage={t('createEditProject@keyResultErrorMessage')}
                {...register('keyResults', { required: true })}
              />
              <DatePickerGroup
                watch={watch}
                clearErrors={clearErrors}
                register={register}
                setValue={setValue}
                t={t}
                errors={errors}
              />
              {isOneTime && (
                <TextareaField
                  isInvalid={!!errors.phases}
                  label={t('createEditProject@phases')}
                  errorMessage={t('createEditProject@phasesErrorMessage')}
                  {...register('phases', { required: true })}
                />
              )}
              <TextareaField
                isInvalid={!!errors.risks}
                label={t('createEditProject@risks')}
                errorMessage={t('createEditProject@risksErrorMessage')}
                {...register('risks', { required: true })}
              />
              <TextareaField
                isInvalid={!!errors.otherPlayers}
                label={t('createEditProject@otherPlayers')}
                errorMessage={t('createEditProject@thisFieldIsRequired')}
                {...register('otherPlayers', { required: true })}
              />
              <Box>
                <Heading mb="5" as="h2" fontSize="xl">
                  {t('Fundraisers')}
                </Heading>
                {project.fundraisingType === 'oneTime' && (
                  <InputField
                    mb={2}
                    isInvalid={!!errors.totalAmount}
                    label={t('createEditProject@requestedAmount')}
                    errorMessage={t(
                      'createEditProject@requestedAmountErrorMessage'
                    )}
                    pattern="[0-9,]*"
                    type="text"
                    {...register('totalAmount', {
                      required: true,
                      onChange: (e) => {
                        const sanitizedValue = e.target.value.replace(
                          /[^0-9]/g,
                          ''
                        )
                        setValue('totalAmount', sanitizedValue)
                      },
                    })}
                  />
                )}

                <Box mt="4">
                  <Goals
                    isRequired={project.fundraisingType === 'recurring'}
                    goals={goalFields}
                    errors={errors}
                    remove={removeGoal}
                    register={register}
                    append={appendGoal}
                    getValues={getValues}
                    setValue={setValue}
                    language={language}
                    fundraisingTypeProp={project.fundraisingType}
                  />
                </Box>
              </Box>
              <BudgetFiles
                fieldKey="budgetFiles"
                data={budgetFiles}
                register={register}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                clearErrors={clearErrors}
              />
            </>
          )}
          {project.isCollaboration && (
            <Options
              validationErrors={validationErrors}
              collaborationFields={collaborationFields}
              appendCollaboration={appendCollaboration}
              removeCollaboration={removeCollaboration}
              register={register}
              getValues={getValues}
              setValue={setValue}
              isEdit
              imageUpload={false}
            />
          )}
          <Heading mb="5" as="h2" fontSize="xl">
            {t('Contact Information')}
          </Heading>
          <InputField
            isInvalid={!!errors.contactFullName}
            errorMessage={t(
              'createEditProject@contactPersonFullNameErrorMessage'
            )}
            label={t('createEditProject@contactPersonFullName')}
            {...register('contactFullName', { required: true })}
          />
          <Stack direction={{ base: 'column', sm: 'row' }} spacing="6">
            <InputField
              errorMessage={t('createEditProject@emailErrorMessage')}
              label={t('createEditProject@email')}
              isInvalid={!!errors.email}
              {...register('email', {
                required: true,
                pattern: /\S+@\S+\.\S+/,
              })}
            />
            <InputField
              errorMessage={t('createEditProject@phoneNumberErrorMessage')}
              label={t('createEditProject@phoneNumber')}
              type="number"
              pattern="[0-9+]"
              isInvalid={!!errors.phone}
              {...register('phone', { required: true })}
            />
          </Stack>
          <Checkbox
            alignItems="flex-start"
            isChecked={legal}
            _focusVisible={{ boxShadow: 'outline' }}
            _focus={{ boxShadow: 'none' }}
            value={legal}
            onChange={() => setLegal(!legal)}
          >
            {t('createEditProject@submitFormRequiredNote')}
          </Checkbox>
          {legal && (
            <>
              <InputField
                isInvalid={!!errors.organizationLegalForm}
                errorMessage={t(
                  'createEditProject@organizationLegalFormErrorMessage'
                )}
                label={t('createEditProject@organizationLegalForm')}
                {...register('organizationLegalForm', { required: true })}
              />
              <InputField
                {...register('tin', { required: true })}
                isInvalid={!!errors.tin}
                errorMessage={t('createEditProject@TINErrorMessage')}
                label={t('createEditProject@TIN')}
              />
            </>
          )}

          <InputField
            {...register('otherDetails')}
            label={t('createEditProject@otherDetails')}
          />
          <Files />
          <InputField
            {...register('links')}
            label={t('createEditProject@largestLinks')}
          />
        </Stack>
        <BottomBar />
      </form>
    </Box>
  )
}

export default EditableApplication
