import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'contexts/TranslationContext'
import {
  Box,
  Grid,
  GridItem,
  Flex,
  Image,
  Heading,
  Text,
  Spinner,
} from '@chakra-ui/react'
import MetaTags from 'components/MetaTags'
import { Footer } from 'components/Footer'
import Share from './Share'
import Mobile from './Mobile'
import { MainLayout } from 'components/Layouts'
import { useGetBlog } from 'hooks'
import Content from 'components/Content'
import { useScreenSize } from 'contexts'

const MetaTagsWrapper = ({ children, data }) => {
  const { t } = useTranslation()
  return (
    <>
      {data && (
        <MetaTags
          title={`${t('blog')} | ${data.title}`}
          description={data.description}
          image={data.image}
        />
      )}
      {children}
    </>
  )
}

const BlogSingle = () => {
  const { slug } = useParams()
  const { isMobile } = useScreenSize()
  const { data, isLoading } = useGetBlog({ slug })
  const { language } = useTranslation()

  if (isMobile) {
    return (
      <MetaTagsWrapper data={data}>
        <Mobile data={data} isLoading={isLoading} />
      </MetaTagsWrapper>
    )
  }

  return (
    <MetaTagsWrapper data={data}>
      <MainLayout>
        {isLoading ? (
          <Flex
            w="full"
            margin="auto"
            align="center"
            justifyContent="center"
            minH="500px"
          >
            <Spinner color="blue.400" />
          </Flex>
        ) : (
          <>
            <Grid templateColumns="repeat(12, 1fr)" justifyContent="center">
              <GridItem colStart={3} colSpan={8}>
                <Flex
                  w="full"
                  alignItems="flex-start"
                  justifyContent="center"
                  direction="column"
                  mx="auto"
                >
                  <Heading
                    pt="2.5rem"
                    key={`card-title-${language}`}
                    as="h2"
                    fontSize={{ base: 'lg', sm: '2.5rem' }}
                  >
                    {data.title}
                  </Heading>
                  <Text
                    fontSize="xl"
                    key={`project-createdAt`}
                    mt="4"
                    mb="3"
                    color="gray.800"
                  >
                    {data.date}
                  </Text>
                  <Text
                    fontSize="xl"
                    key={`project-tagline-${language}`}
                    mb="2.5rem"
                    color="rgba(26, 26, 26, 0.6)"
                  >
                    {data.description}
                  </Text>
                  <Image
                    borderRadius="16px"
                    src={data?.image}
                    alt="fundraiser"
                    style={{ objectFit: 'cover' }}
                    width="100%"
                    height="100%"
                  />
                  <Box w="100%">
                    <Content html={data.content} />
                  </Box>
                </Flex>
                <Share project={data} />
              </GridItem>
            </Grid>
          </>
        )}
        <Footer />
      </MainLayout>
    </MetaTagsWrapper>
  )
}

export default BlogSingle
