import Variant1 from './Variant1'
import Variant2 from './Variant2'

const variants = {
  variant1: Variant1,
  variant2: Variant2,
}

export default function ApplicationCard({ variant, ...rest }) {
  const Component = variants[variant]

  if (!Component) {
    return 'Invalid variant for ApplicationCard'
  }

  return <Component {...rest} />
}
