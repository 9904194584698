import { useEffect, useState } from 'react'
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  VKShareButton,
} from 'react-share'
import {
  Box,
  Heading,
  HStack,
  Text,
  Button,
  Flex,
  useDisclosure,
} from '@chakra-ui/react'
import UserInfo from 'components/UserInfo'
import DonateModal from '../DonateModal'
import Progress from 'components/Progress'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as FB } from 'assets/icons/transparentFacebook.svg'
import { ReactComponent as Linkedin } from 'assets/icons/transparentLinkedin.svg'
import { ReactComponent as Twitter } from 'assets/icons/transparentTwitter.svg'
import { ReactComponent as VK } from 'assets/icons/transparentVk.svg'
import { ReactComponent as Copy } from 'assets/icons/copy.svg'
import { ReactComponent as User } from 'assets/icons/blueUser.svg'
import useCustomToast from 'hooks/useCustomToast'
import ListItem from 'components/ListItem'
import { calculateProjectDurationLeft, useUpdateUser, useUser } from 'hooks'
import {
  useCurrencySettings,
  CurrencyConverter,
  thousandSeparator,
  SUPPORTED_CURRENCIES,
  SUPPORTED_CURRENCY_SYMBOLS,
  convert,
} from 'contexts/CurrencySettingsContext'
import { PROGRESS_VISIBLE_STATUSES } from '_constants'
import AmountWithCurrency from 'components/AmountWithCurrency'

const Information = ({ project, implementor = false }) => {
  const [sharingLink, setShareLink] = useState('')
  const { t, language } = useTranslation()
  const { isOpen: isOpenDonateModal, onClose: onCloseDonateModal } =
    useDisclosure()
  const toast = useCustomToast()
  const { currency, settings, changeDefaultCurrency } = useCurrencySettings()
  const { user } = useUser()
  const { updateUser } = useUpdateUser()

  async function share() {
    if ('clipboard' in navigator) {
      toast({
        title: t('copied'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      return await navigator.clipboard.writeText(window.location.href)
    } else {
      toast({
        title: t('copied'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      return document.execCommand('copy', true, window.location.href)
    }
  }

  const handleDefaultCurrencyChange = (currency) => {
    changeDefaultCurrency(currency)
    if (user.id && user?.id !== 'guest') {
      updateUser({
        id: user.id,
        currency,
      })
    }
  }

  useEffect(() => {
    if (!project) return
    setShareLink(window.location.href)
  }, [])

  if (!project) {
    return <></>
  }
  return (
    <>
      <DonateModal
        project={project}
        isOpen={isOpenDonateModal}
        onClose={onCloseDonateModal}
      />
      <Box
        mb="3"
        fontSize="sm"
        textTransform="uppercase"
        fontWeight="bold"
        color="blue.400"
        as="span"
      >
        {t(`category@${project.category}`)}
      </Box>
      <Heading mb="4" fontSize="3xl" as="h1">
        {project && project[`title_${language}`]
          ? project[`title_${language}`]
          : project.applicationTitle}
      </Heading>
      <Text mb="5" fontSize="lg" opacity="0.6">
        {project[`tagline_${language}`]}
      </Text>
      {!project.isCompany ? (
        implementor ? (
          <>
            <UserInfo
              name={`${implementor.firstName} ${implementor.lastName}`}
              info={t('implementor')}
            />
          </>
        ) : (
          <UserInfo
            link={project?.creatorSocialLink}
            image={project?.creatorImage}
            name={project[`creatorName_${language}`]}
          />
        )
      ) : (
        <Flex alignItems="flex-end">
          <Text mr={2} fontSize="md" color="">
            {t('Raised')}{' '}
          </Text>
          <Progress
            goalText={t('goal')}
            project={project}
            raisedMoney={thousandSeparator(
              convert({
                amount: project.amount,
                currencyRate: settings?.currency[currency.current],
              }),
              currency.current
            )}
            singlePage
            thousandSeparatorText={thousandSeparator(
              currency.current === 'AMD'
                ? project.amount + Number(project.updateAmount)
                : project.amount /
                    Number(settings?.currency[currency.current]) +
                    Number(project.updateAmount),
              currency.current
            )}
            options={SUPPORTED_CURRENCIES.map((option) => ({
              title: t(`currency@${option}`),
              amount: `${CurrencyConverter({
                currency: option,
                amount: project.amount,
              })} ${option}`,
              value: option,
            }))}
            currentSelectedOption={t(`currency@${currency.current}`)}
            monthlyGoalText={t('goal')}
            goalPrice={project.activeGoal?.amount || project.goal}
            raisedText={t('project@recurringCurrentlyAmount')}
            calculatedProjectDurationLeft={calculateProjectDurationLeft({
              t,
              campaignImplementorPeriod_en:
                project.campaignImplementorPeriod_en,
              fundraisingType: project.fundraisingType,
            })}
            currency={currency}
            settings={settings}
            changeDefaultCurrency={handleDefaultCurrencyChange}
          />
        </Flex>
      )}
      {project?.collaboratorsTotal && project?.collaboratorsTotal > 0 && (
        <Flex
          alignItems="center"
          mt={5}
          pt={5}
          borderTop="1px solid"
          borderColor="gray.500"
        >
          <User width="36px" height="36px" />
          <Text ml="3" fontWeight="700">
            <Box fontWeight="500" color="gray.700" as="span" fontSize="md">
              {project?.collaboratorsTotal === 1 ? (
                <>
                  {t('collaboration proposal').split('...')[0]}
                  <Text fontWeight="bold" color="black" as="span">
                    {project?.collaboratorsTotal}
                  </Text>{' '}
                  {t('collaboration proposal').split('...')[1]}
                </>
              ) : (
                <>
                  {t('collaboration proposals').split('...')[0]}
                  <Text fontWeight="bold" color="black" as="span">
                    {project?.collaboratorsTotal}
                  </Text>{' '}
                  {t('collaboration proposals').split('...')[1]}
                </>
              )}
            </Box>
          </Text>
        </Flex>
      )}
      {!project.isCompany &&
        !!project.isProject &&
        PROGRESS_VISIBLE_STATUSES.includes(project.status) && (
          <Progress
            goalText={t('goal')}
            project={project}
            raisedMoney={thousandSeparator(
              convert({
                amount: project.amount,
                currencyRate: settings?.currency[currency.current],
              }),
              currency.current
            )}
            singlePage
            thousandSeparatorText={thousandSeparator(
              currency.current === 'AMD'
                ? project.amount + Number(project.updateAmount)
                : project.amount /
                    Number(settings?.currency[currency.current]) +
                    Number(project.updateAmount),
              currency.current
            )}
            options={SUPPORTED_CURRENCIES.map((option) => ({
              title: t(`currency@${option}`),
              amount: `${CurrencyConverter({
                currency: option,
                amount: project.amount,
              })} ${option}`,
              value: option,
            }))}
            currentSelectedOption={t(`currency@${currency.current}`)}
            monthlyGoalText={t('goal')}
            goalPrice={project.activeGoal?.amount || project.goal}
            raisedText={t('project@recurringCurrentlyAmount')}
            calculatedProjectDurationLeft={calculateProjectDurationLeft({
              t,
              campaignImplementorPeriod_en:
                project.campaignImplementorPeriod_en,
              fundraisingType: project.fundraisingType,
            })}
            currency={currency}
            settings={settings}
            changeDefaultCurrency={handleDefaultCurrencyChange}
          />
        )}
      <HStack mt="6" spacing="4" alignItems="center">
        <Button w="20px" onClick={share}>
          <Copy />
        </Button>
        <FacebookShareButton
          width="30px"
          url={sharingLink}
          description={'reArmenia'}
        >
          <FB />
        </FacebookShareButton>
        <TwitterShareButton url={sharingLink} description={'reArmenia'}>
          <Twitter />
        </TwitterShareButton>
        <LinkedinShareButton url={sharingLink} description={'reArmenia'}>
          <Linkedin />
        </LinkedinShareButton>
        <VKShareButton url={sharingLink} description={'reArmenia'}>
          <VK />
        </VKShareButton>
      </HStack>
      {(project.isCompany && !!project?.goals) ||
        (project?.goals?.length !== 0 && (
          <Box my={10}>
            {project.goals.map((goal, index) => (
              <ListItem
                key={`goal-${goal.amount}-${goal.description_en}-${index}`}
                data={goal}
                index={index}
                reachedText={t('reached')}
                isMobile
                goalText={t(
                  project?.fundraisingType === 'oneTime'
                    ? 'goal'
                    : 'monthlyGoal'
                )}
                language={language}
                currency={currency}
                goalAmount={CurrencyConverter({
                  amount: goal.amount,
                  currency: currency.current,
                })}
                SUPPORTED_CURRENCY_SYMBOLS={SUPPORTED_CURRENCY_SYMBOLS}
                dataLength={project.goals.length}
                variant={'goal'}
                Amount={
                  <AmountWithCurrency
                    amount={goal.amount}
                    currency={currency.current}
                    currencyRate={settings?.currency[currency.current]}
                  />
                }
                t={t}
              />
            ))}
          </Box>
        ))}
    </>
  )
}

export default Information
