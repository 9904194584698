import {
  Button,
  Flex,
  Heading,
  Link,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react'
import { MainLayout } from 'components/Layouts'
import { useCustomToast, useUser } from 'hooks'
import { useNavigation } from 'pages'
import React, { useRef, useState } from 'react'
import useExportTable from './useExportTable'
import _ from 'lodash'
import { FaCloudDownloadAlt, FaCopy } from 'react-icons/fa'
const TABLES = [
  'Project-fybsaqoshrcsljyz7sygvof7g4-production',
  'User-fybsaqoshrcsljyz7sygvof7g4-production',
  'Transaction-fybsaqoshrcsljyz7sygvof7g4-production',
]

export default function ExportPage() {
  const { user } = useUser()
  const { mutate, isLoading, error } = useExportTable()
  const [tableName, setTableName] = useState()
  const { navigationPush } = useNavigation()
  const [urls, setUrls] = useState([])
  const toast = useCustomToast()
  const textRef = useRef(null)
  if (!user.isAdmin) {
    navigationPush('/')
  }
  const onExport = () => {
    console.log(tableName)
    mutate(tableName, {
      onSuccess: (data) => {
        console.log(data.url)
        setUrls((prev) => [...prev, data.url])
      },
      onError: (err) => {
        console.log('err')
        console.log(err)
      },
    })
  }
  const handleCopy = () => {
    if (textRef.current) {
      textRef.current.value = _.uniq(urls).join(`\n`)
      textRef.current.select()
      document.execCommand('copy')
      toast({
        title: 'Copied',
        description: 'Copied to clipboard',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
    }
  }
  return (
    <MainLayout>
      <Flex
        mt={24}
        w={{ base: '400px', sm: '600px' }}
        mx="auto"
        flexDir="column"
      >
        <Heading mb={4}>Export table from Database</Heading>
        {!!urls.length && (
          <Button rightIcon={<FaCopy />} onClick={handleCopy} my={4}>
            COPY ALL URLS
          </Button>
        )}
        <textarea
          type="text"
          ref={textRef}
          value="Text to be copied"
          readOnly
          // hidden
          style={{ position: 'absolute', left: '-9999px' }}
        />

        {error && <Text color="red">Ups! Something wen't wrong</Text>}

        {!!urls.length && (
          <VStack>
            {_.uniq(urls).map((url) => (
              <Flex key={url} alignItems="center">
                <Flex background="gray.400" p="3" fontSize="sm">
                  {url}
                </Flex>
                <Button
                  p={3}
                  rightIcon={<FaCloudDownloadAlt />}
                  as={Link}
                  href={url}
                  target="_blank"
                  color="green"
                  textAlign="center"
                >
                  DOWNLOAD
                </Button>
              </Flex>
            ))}
          </VStack>
        )}
        {isLoading && (
          <Text color="orange">exporting {tableName} Table from DynamoDB</Text>
        )}
        <VStack my={8} spacing={4}>
          <Select
            isDisabled={isLoading}
            placeholder="Select table"
            onChange={(e) => {
              setTableName(e.target.value)
            }}
          >
            {TABLES.map((table) => (
              <option value={table} key={table}>
                {table.split('-')[0]}s Table
              </option>
            ))}
          </Select>
          {tableName}
          <Button
            variant="blue"
            isDisabled={!tableName || isLoading}
            onClick={onExport}
            isLoading={isLoading}
          >
            Export
          </Button>
        </VStack>
      </Flex>
    </MainLayout>
  )
}
