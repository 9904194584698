import userUseCase from './userUseCase'
import UserService from '../../services/UserService'
import authRepo from '../../repositories/authRepo'
import AuthService from '../../services/authService'

const userFactory = ({ appSyncRequest, auth }) => {
  return userUseCase(UserService(appSyncRequest, auth), authRepo(AuthService()))
}

export default userFactory
