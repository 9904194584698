import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import content from '.'

export const useGetNeeds = () => useQuery(['needs'], content.getNeeds)

export const useGetExperts = (variables) =>
  useQuery([`experts`], () => content.getExperts(variables))

export const useGetExpertById = (id) =>
  useQuery([`expert-${id}`], () => content.getExpert(id))
export const useGetExpertBySlug = (slug) =>
  useQuery([`expert-${slug}`], () => content.getItemBySlug(slug))

export const useGetGiftCardsPageData = () =>
  useQuery(['gift-card'], content.getGiftCard)
export const useGetHomepageBlog = () =>
  useQuery(['blog'], content.getHomepageBlog)
export const useGetHomepageBlogById = (slug) =>
  useQuery([`blog_${slug}`], () => content.getItemBySlug(slug))

export const useGetHomepagePartners = () =>
  useQuery(['partners'], content.getHomepagePartners)
export const useGetHomepageImpacts = () =>
  useQuery(['impacts'], content.getHomepageImpacts)
export const useGetHomepageTestimonials = () =>
  useQuery(['testimonials'], content.getHomepageTestimonials)
export const useGetExpertsCategories = () =>
  useQuery(['categories'], content.getExpertsCategories)
export const useGetExpertsCountries = () =>
  useQuery(['countries'], content.useGetExpertsCountries)

export const useContentFromElastic = (
  initialFilter,
  key = 'get_content',
  page,
  hasSearch
) => {
  const [filters, setFilters] = useState(initialFilter)
  const { error, refetch, isLoading, data, ...rest } = useQuery(
    [key, filters],
    async () => {
      return await content.getContentFromElastic(filters, page, hasSearch)
    },
    {
      enabled: true,
    }
  )

  return {
    data,
    refetch,
    isLoading,
    filters,
    setFilters,
    error,
    ...rest,
  }
}

export const useCreateContentItem = () => useMutation(content.createContentItem)

export const useUpdateContentItem = () => useMutation(content.updateContentItem)

export const useDeleteContentItem = () => useMutation(content.deleteContentItem)
