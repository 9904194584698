import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  Flex,
  useDisclosure,
} from '@chakra-ui/react'

export default function NotificationBar({
  status = 'success',
  title,
  description,
  closable = true,
  onClose = () => {},
  defaultIsOpen = true,
  ...rest
}) {
  const { isOpen: isVisible, onClose: close } = useDisclosure({ defaultIsOpen })

  if (!isVisible) {
    return null
  }

  return (
    <Alert status={status} borderRadius="12px" {...rest}>
      <Flex justifyContent="space-between" alignItems="center" w="full">
        <Flex alignItems="center">
          {' '}
          <AlertIcon />
          <Box>
            {title && <AlertTitle fontWeight={600}>{title}</AlertTitle>}
            {description && <AlertDescription>{description}</AlertDescription>}
          </Box>
        </Flex>
        {closable && (
          <CloseButton
            alignSelf="flex-start"
            position="relative"
            right={0}
            top={0}
            onClick={() => {
              close()
              onClose()
            }}
          />
        )}
      </Flex>
    </Alert>
  )
}
