import { useState, useEffect, useContext } from 'react'
import { useMutation, useQuery } from 'react-query'
import { fetchAuthSession } from 'aws-amplify/auth'
import useAppSyncRequest from './useAppSyncRequest'
import userFactory from 'core/factories/user'
import { UserContext } from '../contexts'

export const useUserFactory = () => {
  const appSyncRequest = useAppSyncRequest()
  const auth = { fetchAuthSession }

  return userFactory({ appSyncRequest, auth })
}

export const useGetUser = () => {
  const userUseCase = useUserFactory()
  const [userId, setUserId] = useState('')

  const { error, data } = useQuery(
    ['user', userId],
    async () => {
      return await userUseCase.getUserById(userId)
    },
    {
      enabled: !!userId,
    }
  )

  const getUserById = (id) => {
    setUserId(id)
  }

  return {
    error,
    user: data,
    getUserById,
  }
}

export const useGetUserByEmail = (email) => {
  const userUseCase = useUserFactory()
  const query = useQuery(['userByEmail'], () =>
    userUseCase.getUserByEmail(email)
  )
  return query
}

export const useUpdateUser = () => {
  const userUseCase = useUserFactory()
  const {
    mutate: updateUser,
    mutateAsync: updateUserAsync,
    data,
    isLoading,
  } = useMutation(userUseCase.updateUser)

  return {
    updateUser,
    updateUserAsync,
    isLoading,
    data,
  }
}

export const useUserUpdateSubscription = ({ userId, setUserObj, isAdmin }) => {
  const userUseCase = useUserFactory()

  const [subUserId, setSubUserId] = useState(userId)

  useEffect(() => {
    if (subUserId === 'guest') {
      return
    }
    const subscription = userUseCase.onUserUpdateSubscription({
      userId: subUserId,
      callback: (data) => {
        if (data?.id === subUserId) {
          console.log(data)
          setUserObj((prev) => ({ ...prev, user: { ...data, isAdmin } }))
          localStorage.setItem(
            'rearmenia_user',
            JSON.stringify({ ...data, isAdmin })
          )
        }
      },
    })
    return () => {
      if (
        subscription?.unsubscribe &&
        typeof subscription.unsubscribe === 'function'
      ) {
        subscription.unsubscribe()
      }
    }
  }, [subUserId])

  return {
    subscribeToUserUpdates: setSubUserId,
  }
}

export const useGetUserByEmailAsync = () => {
  const userUseCase = useUserFactory()
  const mutation = useMutation(userUseCase.getUserByEmail)
  return mutation
}

export const useUser = () => useContext(UserContext)
