import { post } from 'aws-amplify/api'
import { getResponseToJSON } from 'hooks/useResponseManipulations'

const hashService = () => {
  const hash = async ({ text, secret, expiresIn }) => {
    const res = post({
      apiName: 'paymentAPI',
      path: '/sign',
      options: { body: { text, secret, expiresIn } },
    })
    return await getResponseToJSON(res)
  }

  const verify = async ({ token, secret }) => {
    const res = post({
      apiName: 'paymentAPI',
      path: '/verify',
      options: { body: { secret, token } },
    })
    return await getResponseToJSON(res)
  }

  return {
    hash,
    verify,
  }
}

export default hashService
