import * as React from 'react'
import { Text } from '@chakra-ui/layout'
import { useTranslation } from 'contexts/TranslationContext'

export const Copyright = (props) => {
  const { t } = useTranslation()
  return (
    <Text fontSize="sm" {...props}>
      {t('footer@copyRight').replace('2021', new Date().getFullYear())}
    </Text>
  )
}
