import { useCustomToast, useUpdateProject } from 'hooks'
import ApplicationCard from 'components/ApplicationCard'
import { useTranslation } from 'contexts/TranslationContext'

export default function MailerLite({ project }) {
  const { updateProject, isLoading } = useUpdateProject()
  const toast = useCustomToast()
  const { t } = useTranslation()

  const onSubmit = ({ fbPixel }) => {
    const onSuccess = () => {
      toast({
        status: 'success',
        title: t('Successfully saved.'),
      })
    }

    updateProject(
      {
        id: project.id,
        fbPixel: fbPixel,
      },
      { onSuccess }
    )
  }

  return (
    <ApplicationCard
      description="Measure, optimize, and build audiences for your ad campaigns."
      variant="variant1"
      logo="meta.png"
      heading="Meta Pixel"
      buttonText="Save"
      isLoading={isLoading}
      onSubmit={onSubmit}
      inputs={[
        {
          fieldKey: 'fbPixel',
          placeholder: 'Pixel base code',
          defaultValue: project?.fbPixel,
        },
      ]}
    />
  )
}
