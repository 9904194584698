import { Box } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'contexts/TranslationContext'
import NotificationBar from 'components/NotificationBar'
import { useUpdateUser, useUser } from 'hooks'

export default function Layout({ children, expert, ...props }) {
  const { user, setUser } = useUser()
  const { updateUser } = useUpdateUser()
  const onNotificationClose = () => {
    updateUser(
      {
        cognitoId: user.cognitoId,
        id: user.id,
        isSeenExpertProfileNotification: true,
      },
      {
        onSuccess: (response) =>
          setUser((oldUser) => ({
            ...oldUser,
            user: response.data.updateUser,
          })),
      }
    )
  }
  const { t } = useTranslation()
  const status = getAlertStatus(expert?.expert_status)
  return (
    <Box width="full">
      <Box {...props}>
        <Box mx={2}>
          {expert && !user.isSeenExpertProfileNotification && (
            <NotificationBar
              status={status}
              title={t(getAlertTitle(expert.expert_status))}
              description={
                expert.expert_status === 'pending' &&
                t(
                  "You can check the status of your application here. We'll also send you an email in case of any updates."
                )
              }
              width="fit-content"
              mt={4}
              onClose={onNotificationClose}
              closable={status !== 'info'}
            />
          )}

          {expert &&
            expert.expert_status === 'approved' &&
            !expert.isPrivate &&
            [expert.isArmFilled, expert.isEngFilled, expert.isRusFilled].filter(
              (t) => t
            ).length < 2 && (
              <NotificationBar
                status={'warning'}
                title={t('expert@notificationLang')}
                mt={4}
                closable={false}
              />
            )}
        </Box>

        {children}
      </Box>
    </Box>
  )
}

const getAlertStatus = (status) => {
  if (status === 'pending') {
    return 'info'
  }
  if (status === 'approved') {
    return 'success'
  }
  if (status === 'rejected') {
    return 'error'
  }
}

const getAlertTitle = (status) => {
  if (status === 'pending') {
    return 'Your application is being reviewed!'
  }
  if (status === 'approved') {
    return 'We are happy to have you join the reArmenia Community of Experts!'
  }
  if (status === 'rejected') {
    return 'You can update your information and apply again.'
  }
}
