export default function useLocalStorage() {
  const put = (key, value) => {
    if ('undefined' !== typeof window && window.localStorage) {
      window.localStorage.setItem(key, value)
    }
  }

  const remove = (key) => {
    if ('undefined' !== typeof window && window.localStorage) {
      window.localStorage.removeItem(key)
    }
  }

  const get = (key) => {
    if ('undefined' !== typeof window && window.localStorage) {
      return window.localStorage.getItem(key)
    }

    return undefined
  }

  return {
    put,
    get,
    remove,
  }
}
