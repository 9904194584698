import { fetchAuthSession } from 'aws-amplify/auth'
import { useQuery, useMutation } from 'react-query'
import projectSystemFactory from 'core/factories/projectSystem'
import useAppSyncRequest from './useAppSyncRequest'

export const useProjectSystemFactory = () => {
  const appSyncRequest = useAppSyncRequest()
  const auth = { fetchAuthSession }

  return projectSystemFactory({ appSyncRequest, auth })
}

export const useUpdateProjectSystem = () => {
  return useMutation(useProjectSystemFactory().updateProjectSystem)
}

export const useGetProjectSystemByProjectId = (projectId) => {
  const queryKey = ['projectSystem', projectId]
  const projectSystemUseCase = useProjectSystemFactory()

  const query = useQuery(queryKey, () =>
    projectSystemUseCase.getProjectSystemByProjectId(projectId)
  )

  return query
}
