import React, { Suspense, lazy, useMemo } from 'react'
import { Box, Flex, Heading, Text, Button, Image } from '@chakra-ui/react'
import { Link } from 'pages'
import ImageWrapper from 'components/ImageWrapper'
import Progress from 'components/Progress'
import { PROGRESS_VISIBLE_STATUSES, STATUS_ENUMS } from '_constants'
import {
  FUNDRAISER_NONPROFIT,
  FUNDRAISER_PEER_TO_PEER,
} from 'constants/fundraiser'
import ImageLazyLoadWrapper from './ImageLazyLoadWrapper'
import { LANG_RU_SHORT } from 'contexts/TranslationContext'

const BasketAction = lazy(() => import('./BasketAction'))
const BottomHoverActions = lazy(() => import('./BottomHoverActions'))

const createEditDraftParams = ({
  draftId,
  isProject,
  isCollaboration,
  fundraisingType,
}) => {
  let queryParams = `?draftId=${draftId}`
  if (isProject) {
    queryParams += `&isFundraiser=true&fundraisingType=${fundraisingType}`
  }
  if (isCollaboration) {
    queryParams += `&isCollaboration=true`
  }

  return queryParams
}

const Edit = ({
  card,
  type,
  unsubscribe,
  onAlertOpen,
  editText,
  unsubscribeText,
  donationSubscription,
  navigationPush,
}) => {
  const onEditClick = (e) => {
    e.preventDefault()

    if (card.fundraisingType === FUNDRAISER_PEER_TO_PEER) {
      return navigationPush(`/edit-peer-to-peer-fundraiser/${card.id}/content`)
    }
    navigationPush(
      `/edit-${type}/${card?.id}/application${
        card?.isCollaboration && (card?.totalAmount || card?.goals?.length > 0)
          ? '?showBoth'
          : ''
      }`
    )
  }

  return (
    <Flex mt="4" justifyContent="flex-end">
      {!unsubscribe ? (
        <Button
          mr="4"
          color="blue.400"
          textTransform="capitalize"
          variant="transparent"
          fontSize="md"
          onClick={onEditClick}
        >
          {editText}
        </Button>
      ) : (
        <Button
          mr="4"
          color="blue.400"
          textTransform="capitalize"
          variant="transparent"
          fontSize="md"
          onClick={(e) => onAlertOpen(e, donationSubscription)}
        >
          {unsubscribeText}
        </Button>
      )}
    </Flex>
  )
}
const EditDraft = ({
  card,
  onDraftDelete,
  remove,
  continueTranslation,
  navigationPush,
}) => {
  const onDraftContinue = () => {
    let path = '/create-project'
    if (card?.fundraisingType === FUNDRAISER_PEER_TO_PEER) {
      path = `/create-peer-to-peer-fundraiser/${card.peerProjectId}/content`
    } else if (card?.fundraisingType === FUNDRAISER_NONPROFIT) {
      path = `/create-project?isNonProfit=true&fundraisingType=non-profit&draftId=${card.id}`
    } else {
      path += createEditDraftParams({
        draftId: card?.id,
        isProject: card?.isProject,
        isCollaboration: card?.isCollaboration,
        fundraisingType: card?.fundraisingType,
      })
    }
    navigationPush(path)
  }

  return (
    <Flex mt="4" justifyContent="space-between">
      <Button
        mr="4"
        color="blue.400"
        textTransform="capitalize"
        variant="transparent"
        fontSize="md"
        onClick={onDraftContinue}
      >
        {continueTranslation}
      </Button>

      <Button
        mr="4"
        color="gray.700"
        textTransform="capitalize"
        variant="transparent"
        fontSize="md"
        onClick={() => {
          onDraftDelete(card?.id)
        }}
      >
        {remove}
      </Button>
    </Flex>
  )
}
const Needs = ({ needCategories, language, inNeedOfText }) => {
  return (
    <Box minH="24px" h="96px">
      <Text fontWeight={700} mb={2}>
        {inNeedOfText}
      </Text>
      <Flex wrap="wrap">
        {needCategories.slice(0, 5).map((need, index) => (
          <Box
            key={`${need[`title_${language}`]}_${index}`}
            maxW="100px"
            borderColor="border.100"
            borderWidth={1}
            py={1}
            px={3}
            borderRadius={4}
            mr={1}
            mb={1}
            fontSize="sm"
            noOfLines={1}
          >
            {need[`title_${language}`]}
          </Box>
        ))}
        {needCategories.length > 5 && <Text mt={2}>and others</Text>}
      </Flex>
    </Box>
  )
}

const ProjectCard = ({
  isImageLazyLoad = true,
  isMobile = false,
  card = {},
  edit = false,
  unsubscribe = false,
  needCategories = [],
  donationSubscription,
  onAlertOpen,
  UnsubscribeText,
  language,
  currency,
  onDraftDelete,
  created = '',
  continueTranslation = '',
  remove = '',
  startedFromText = '',
  monthlyPaidText = '',
  cardStatusText = '',
  cardCategoryText = '',
  editText = '',
  unsubscribeText = '',
  collaborationProposalsText = '',
  collaborationProposalsTextRuEdit = '',
  inNeedOfText = '',
  type = 'fundraisers',
  monthlyGoalText = '',
  raisedText = '',
  calculatedProjectDurationLeft,
  settings,
  changeDefaultCurrency,
  raisedMoney = 0,
  goalPrice = 0,
  thousandSeparatorText,
  isContribution,
  navigationPush,
  bodyBackground = 'white',
  bottomHoverActions,
  isBasketApplicable,
  ...props // to be continued... (շարունակելի...)
}) => {
  const isFundraiser = type === 'fundraisers'
  const showProgress = useMemo(() => {
    if (type === 'subscription') {
      return false
    }
    return (
      card?.isProject &&
      !unsubscribe &&
      PROGRESS_VISIBLE_STATUSES.includes(card?.status)
    )
  }, [card])

  return (
    <Box
      as={
        card?.status === 'draft' || isContribution || bottomHoverActions
          ? Box
          : Link
      }
      to={`/${type}/${card?.slug || card?.id}`}
      {...props}
    >
      <Box
        overflow="hidden"
        borderRadius="12px"
        border="1px solid"
        borderColor="border.100"
        minW="280px"
        mb={isMobile ? '10' : '0'}
        transition="height 300ms"
      >
        <Box overflow="hidden" position="relative" w="100%" h="184px">
          {cardStatusText && !card.isCompany && (
            <Box
              position="absolute"
              top="16px"
              left="20px"
              color="white"
              p="6px 14px"
              borderRadius="6px"
              fontSize="sm"
              fontWeight="bold"
              background={STATUS_ENUMS?.[type]?.[card?.status]?.color}
            >
              {cardStatusText}
            </Box>
          )}
          {card?.category && (
            <Flex
              left="20px"
              bottom="16px"
              position="absolute"
              alignItems="center"
            >
              <Heading
                color="white"
                key={`card-heading-${language}`}
                textTransform="uppercase"
                as="h3"
                fontSize="sm"
                letterSpacing="1px"
              >
                {cardCategoryText}
              </Heading>
            </Flex>
          )}

          <ImageLazyLoadWrapper isLazy={isImageLazyLoad} h="184px" once>
            {isContribution ? (
              <Image
                cursor="pointer"
                objectFit="cover"
                borderTopRadius="12px"
                width="100%"
                bgColor="border.100"
                h="184px"
                ignoreFallback={true}
                src="/assets/images/contribution.svg"
              />
            ) : card?.cover ? (
              <ImageWrapper
                borderTopRadius="12px"
                cursor="pointer"
                objectFit="cover"
                width="100%"
                bgColor="border.100"
                h="184px"
                ignoreFallback={true}
                src={`/thumbnail/small/${card?.cover}`}
              />
            ) : (
              <Image
                cursor="pointer"
                objectFit="cover"
                borderTopRadius="12px"
                width="100%"
                bgColor="border.100"
                h="184px"
                ignoreFallback={true}
                src="/assets/images/noImg.svg"
              />
            )}
          </ImageLazyLoadWrapper>
        </Box>
        <Flex
          h={card?.status === 'draft' ? 'auto' : '265px'}
          px={!isFundraiser && !!needCategories.length ? 0 : 5}
          pb={!isFundraiser && !!needCategories.length ? 0 : 5}
          pt="4"
          flexDirection="column"
          justifyContent="space-between"
          bg={bodyBackground}
        >
          <Box
            width="100%"
            px={!isFundraiser && !!needCategories.length ? 5 : 0}
          >
            {card?.collaboratorsTotal > 0 && (
              <Text mb={1} color="gray.700" fontSize="md">
                {language === LANG_RU_SHORT &&
                [2, 3, 4].includes(card?.collaboratorsTotal)
                  ? card?.collaboratorsTotal + collaborationProposalsTextRuEdit
                  : card?.collaboratorsTotal +
                    collaborationProposalsText.split('...')[1]}
              </Text>
            )}
            <Heading
              noOfLines={2}
              key={`card-title-${language}`}
              color="gray.800"
              as="h2"
              h="48px"
              fontSize="2xl"
            >
              {card?.[`title_${language}`] || card?.applicationTitle}
            </Heading>
            {!edit && (
              <Text
                key={`card-tagline-${language}`}
                noOfLines={2}
                mt="2"
                mb="2"
                fontSize="md"
                color="gray.700"
                lineHeight="18px"
              >
                {!unsubscribe ? (
                  card[`tagline_${language}`]
                ) : (
                  <UnsubscribeText
                    unsubscribe={unsubscribe}
                    currency={currency}
                    startedFromText={startedFromText}
                    monthlyPaidText={monthlyPaidText}
                  />
                )}
              </Text>
            )}
          </Box>
          <Box
            bg={
              !isFundraiser && !!needCategories.length
                ? 'gray.200'
                : 'transparent'
            }
            px={!isFundraiser && !!needCategories.length ? 5 : 0}
            pb={!isFundraiser && !!needCategories.length ? 5 : 0}
            pt={!isFundraiser && !!needCategories.length ? 2 : 0}
          >
            {showProgress &&
              isFundraiser &&
              card.fundraisingType !== FUNDRAISER_NONPROFIT && (
                <Progress
                  project={card}
                  raisedMoney={raisedMoney}
                  thousandSeparatorText={thousandSeparatorText}
                  monthlyGoalText={monthlyGoalText}
                  goalPrice={goalPrice}
                  raisedText={raisedText}
                  calculatedProjectDurationLeft={calculatedProjectDurationLeft}
                  currency={currency}
                  settings={settings}
                  changeDefaultCurrency={changeDefaultCurrency}
                />
              )}

            {isBasketApplicable && card.status === 'live' && (
              <Suspense>
                <Box mt={3}>
                  <BasketAction project={card} />
                </Box>
              </Suspense>
            )}

            {!isFundraiser && !!needCategories.length && (
              <Needs
                needCategories={needCategories}
                language={language}
                inNeedOfText={inNeedOfText}
              />
            )}
            {card?.status === 'draft' ? (
              <>
                <Text fontSize="md" fontWeight={300}>
                  {created.replace('...', card?.formattedCreationDate)}
                </Text>
                <EditDraft
                  card={card}
                  remove={remove}
                  continueTranslation={continueTranslation}
                  navigationPush={navigationPush}
                  onDraftDelete={onDraftDelete}
                />
              </>
            ) : (
              <>
                {(edit || unsubscribe) && (
                  <Edit
                    card={card}
                    type={type}
                    editText={editText}
                    unsubscribeText={unsubscribeText}
                    unsubscribe={unsubscribe}
                    onAlertOpen={onAlertOpen}
                    navigationPush={navigationPush}
                    donationSubscription={donationSubscription}
                  />
                )}
              </>
            )}
          </Box>
        </Flex>
        {bottomHoverActions && (
          <BottomHoverActions bottomHoverActions={bottomHoverActions} />
        )}
      </Box>
    </Box>
  )
}

export default ProjectCard
