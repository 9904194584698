import React from 'react'
import { Box, Image, Heading, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import LazyLoad from 'react-lazyload'

const UserCard = ({ name, lastName, position, member, photo }) => {
  const { t } = useTranslation()
  return (
    <Box>
      <LazyLoad height={240}>
        <Image
          mb="3"
          borderRadius="12px"
          objectFit="cover"
          w="full"
          height="160px"
          src={`/assets/images/${photo}`}
        />
      </LazyLoad>
      <Heading mb="2" fontSize="lg" as="h2">
        {t(`about@${name}`)}
        <br />
        {t(`about@${lastName}`)}
      </Heading>
      <Text fontSize="sm" color="gray.700" mb="5">
        {t(`about@${position}`)}
        <Text>{member && t(`about@${member}`)}</Text>
      </Text>
    </Box>
  )
}

export default UserCard
