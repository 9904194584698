const Switch = {
  baseStyle: {
    thumb: {
      bg: 'white',
    },
    track: {
      bg: 'gray.400',
      p: '4px',
      _checked: {
        bg: 'blue.200',
      },
    },
  },
  sizes: {},
  variants: {
    medium: {
      thumb: {
        bg: 'white',
      },
      track: {
        bg: 'gray.400',
        p: '2px',
        _checked: {
          bg: 'blue.200',
        },
      },
    },
    gray: {
      thumb: {
        bg: 'gray.800',
      },
      track: {
        bg: 'gray.400',
        p: '2px',
        _checked: {
          bg: 'gray.500',
        },
      },
    },
  },
  defaultProps: {},
}

export default Switch
