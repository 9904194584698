import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetSuccessStory } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import MetaTags from 'components/MetaTags'
import Mobile from './Mobile'

const MetaTagsWrapper = ({ children, data }) => {
  const { t } = useTranslation()
  return (
    <>
      {data && (
        <MetaTags
          title={`${t('story')} | ${data.title} | reArmenia`}
          description={data.description}
          image={data.image}
        />
      )}
      {children}
    </>
  )
}

const SuccessStory = () => {
  const { slug } = useParams()
  const { language } = useTranslation()
  const { data, isLoading } = useGetSuccessStory(slug, language)
  return (
    <MetaTagsWrapper data={data}>
      <Mobile data={data} isLoading={isLoading} />
    </MetaTagsWrapper>
  )
}

export default SuccessStory
