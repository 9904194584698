import {
  Flex,
  Tabs,
  Tab,
  TabList,
  Checkbox,
  HStack,
  Tag,
  TagLabel,
  TagCloseButton,
} from '@chakra-ui/react'
import { BlueSelect } from 'components/Select/BlueSelect'
import { useTranslation } from 'contexts/TranslationContext'
import {
  ADMIN_STATUSES,
  CATEGORY,
  GENERAL_USER_VISIBLE_STATUSES,
} from '_constants'
import SearchInput from './SearchInput'

const Header = ({
  tabIndex,
  setTabIndex,
  tabs,
  localFilters,
  setLocalFilters,
  searchRef,
  type = 'applications',
  isLoading,
}) => {
  const { t, language } = useTranslation()
  return (
    <>
      <Flex
        flexWrap="nowrap"
        mb="8"
        mt="4"
        borderBottom="1px solid #CDCDCD"
        justifyContent={
          type === 'applications' || type === 'fundraisers'
            ? 'space-between'
            : 'flex-end'
        }
        alignItems="center"
      >
        {(type === 'applications' || type === 'fundraisers') && (
          <Tabs height="100%" index={tabIndex} onChange={setTabIndex}>
            <TabList borderBottom="none">
              {tabs.map((tabTitle, index) => (
                <Tab
                  isDisabled={isLoading}
                  key={`tabTitle-${index}`}
                  _active={{ background: 'initial' }}
                  _selected={{
                    fontWeight: 'extrabold',
                    color: 'blue.300',
                    borderColor: 'blue.300',
                  }}
                  fontWeight="semibold"
                  color="gray.700"
                  fontSize="16px"
                  width="200px"
                  p="22px"
                  textTransform="capitalize"
                >
                  {t(tabTitle)}
                </Tab>
              ))}
            </TabList>
          </Tabs>
        )}

        <HStack alignItems="center" spacing={6} py="2">
          {type === 'applications' && (
            <Checkbox
              isDisabled={isLoading}
              visibility={tabIndex === 0 ? 'normal' : 'hidden'}
              value={localFilters.isRecurringChecked}
              onChange={() => {
                setLocalFilters({
                  ...localFilters,
                  isRecurringChecked: !localFilters.isRecurringChecked,
                })
              }}
            >
              {t('dashboard@recurring')}
            </Checkbox>
          )}
          <BlueSelect
            isDisabled={isLoading}
            display="flex"
            type="category"
            label={t('dashboard@category')}
            options={CATEGORY[language].map((option) => ({
              title: t(`category@${option}`),
              value: option,
            }))}
            selectOption={(category) => {
              setLocalFilters({ ...localFilters, category })
            }}
            filterType={'category'}
            currentSelectedOption={t(`category@${localFilters.category}`)}
            minWidth="100px"
            mr={0}
          />
          <BlueSelect
            display="flex"
            type="status"
            label={t('dashboard@status')}
            options={(type === 'applications'
              ? ADMIN_STATUSES
              : GENERAL_USER_VISIBLE_STATUSES
            ).map((option) => ({
              title: t(`status@${option}`),
              value: option,
            }))}
            selectOption={(status) => {
              setLocalFilters({ ...localFilters, status })
            }}
            filters={localFilters}
            filterType={'status'}
            currentSelectedOption={t(`status@${localFilters.status}`)}
            minWidth="100px"
            mr={0}
          />
          <SearchInput
            isDisabled={isLoading}
            placeholder={t('dashboard@searchPlaceholder')}
            onSearch={(search) => {
              setLocalFilters({ ...localFilters, search })
            }}
            searchRef={searchRef}
          />
        </HStack>
      </Flex>
      <HStack mb={4}>
        {localFilters.category && (
          <Tag
            h="32px"
            size="sm"
            color="black"
            borderRadius="8px"
            variant="solid"
            colorScheme="gray"
          >
            <TagLabel>{t(`category@${localFilters.category}`)}</TagLabel>
            <TagCloseButton
              onClick={() => setLocalFilters({ ...localFilters, category: '' })}
            />
          </Tag>
        )}
        {localFilters.status && (
          <Tag
            h="32px"
            size="sm"
            color="black"
            borderRadius="8px"
            variant="solid"
            colorScheme="gray"
          >
            <TagLabel>{t(`status@${localFilters.status}`)}</TagLabel>
            <TagCloseButton
              onClick={() => setLocalFilters({ ...localFilters, status: '' })}
            />
          </Tag>
        )}
        {localFilters.search && (
          <Tag
            h="32px"
            size="sm"
            color="black"
            borderRadius="8px"
            variant="solid"
            colorScheme="gray"
          >
            <TagLabel>Search: {localFilters.search}</TagLabel>
            <TagCloseButton
              onClick={() => setLocalFilters({ ...localFilters, search: '' })}
            />
          </Tag>
        )}
      </HStack>
    </>
  )
}

export default Header
