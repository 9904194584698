import { Button, chakra, Input, Stack, HStack, Box } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'contexts/TranslationContext'
import * as React from 'react'
import { FooterHeading } from './FooterHeading'
import { useNavigation } from 'pages'
import useCustomToast from 'hooks/useCustomToast'
import { useSubscribeNews } from 'hooks'

export const SubscribeForm = (props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()
  const { t, language } = useTranslation()
  const { navigationPush } = useNavigation()
  const toast = useCustomToast({})
  const { mutate: subscribeMailerLiteNews, isLoading } = useSubscribeNews()
  const onSubscribe = async ({ email }) => {
    await subscribeMailerLiteNews(
      { email, language },
      {
        onSuccess: () => {
          navigationPush('/subscribe/thank-you')
          toast({
            title: t('Subscribed'),
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
        },
        onError: (e) => {
          toast({
            title: 'You are already subscribed!',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
          console.log('subscribe error', e)
        },
      }
    )
  }
  return (
    <chakra.form
      style={{ width: '100%', maxWidth: '437px' }}
      {...props}
      onSubmit={handleSubmit(onSubscribe)}
    >
      <Stack spacing="4">
        <FooterHeading
          fontWeight="700"
          textAlign={{ base: 'center', sm: 'end' }}
          fontSize={{ base: '3xl', sm: '4xl' }}
          letterSpacing="none"
        >
          {t('Stay abreast of the latest updates')}
        </FooterHeading>
        <Box
          textAlign={{ base: 'center', sm: 'end' }}
          fontSize="md"
          color="gray.700"
          letterSpacing="wider"
          as="p"
        >
          {t(
            'Keep an eye on projects that improve life in Armenia,get updates on current ones, and more from reArmenia!'
          )}
        </Box>
        <HStack
          padding="8.5px"
          borderRadius="12px"
          border="1px solid"
          borderColor={errors?.email ? 'red.400' : 'border.100'}
          alignItems="center"
        >
          <Input
            px={2}
            _focus={{ outline: 'none' }}
            border="none"
            bg="white"
            placeholder={t('Your Email')}
            {...register('email', { required: true })}
          />
          <Button
            isLoading={isLoading}
            px="10"
            type="submit"
            variant="orange"
            flexShrink={0}
          >
            {t('Subscribe')}
          </Button>
        </HStack>
      </Stack>
    </chakra.form>
  )
}
