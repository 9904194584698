const authRepo = (authService) => {
  const signUp = (signInParams) => authService.signUp(signInParams)
  const resendSignUp = async (email) => authService.resendSignUp(email)
  const confirmSignUp = (confirmParams) =>
    authService.confirmSignUp(confirmParams)
  const forgetPassword = (email) => authService.forgetPassword(email)
  const forgetPasswordSubmit = (params) =>
    authService.forgetPasswordSubmit(params)
  const signIn = (signInParams) => authService.signIn(signInParams)
  const socialSignIn = ({ provider }) => authService.socialSignIn({ provider })
  const setPassword = (body) => authService.setPassword(body)
  const getCognitoCurrentUser = () => authService.getCognitoCurrentUser()
  return {
    signUp,
    resendSignUp,
    confirmSignUp,
    forgetPassword,
    forgetPasswordSubmit,
    signIn,
    setPassword,
    socialSignIn,
    getCognitoCurrentUser,
  }
}

export default authRepo
