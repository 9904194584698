import React, { useEffect, useMemo, useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import {
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Heading,
} from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import {
  LANG_EN_SHORT,
  LANG_HY_SHORT,
  LANG_RU_SHORT,
  useTranslation,
} from 'contexts/TranslationContext'
import FormData from './FormData'
import { useProject } from '../ProjectContext'
import { useNavigation } from 'pages'
import BottomBar from '../BottomBar'
import {
  useConnectAccount,
  useContinueConnectAccount,
  useCreateProject,
  useCustomToast,
  useObjectManipulations,
  useQueryParams,
  useUser,
  useBeforeUnload,
} from 'hooks'
import { CONTENT_HINTS } from '_constants'
import {
  PEER_TO_PEER_OCCASION_ENUMS,
  FUNDRAISER_PEER_TO_PEER,
} from 'constants/fundraiser'
import { PROJECT_STATUS_SUBMITTED } from 'constants/project'
import { validatePeerFormData } from './FormData/validator'
import useRevalidatePeerForm from './FormData/useRevalidatePeerForm'
import { useGetDraftById, useSaveDraft } from 'core/Draft/hooks'
import { normalizeDraft } from 'core/Draft/helpers'
import _ from 'lodash'

const Content = () => {
  const { t, language } = useTranslation()
  const queryParams = useQueryParams()
  const draftId = queryParams.get('draftId')
  const { data: draftResponse } = useGetDraftById(draftId)
  const {
    project,
    peerProject,
    onUpdate,
    isCreatingPeerToPeerProject,
    isPeerToPeerProject,
  } = useProject()
  const { mutate: createProject, isLoading: isPeerProjectCreateLoading } =
    useCreateProject()
  const location = useLocation()
  const { deleteKey } = useObjectManipulations()
  const [toastCoolDown, setToastCoolDown] = useState(0)
  const toast = useCustomToast()
  const [date, setDate] = useState(new Date())
  const [tabIndex, setTabIndex] = useState(0)
  const { user } = useUser()
  const { mutate: connectAccount, isLoading: isConnectLoading } =
    useConnectAccount()
  const {
    mutate: continueConnectAccount,
    isLoading: isContinueConnectLoading,
  } = useContinueConnectAccount()
  const { navigationReplace, navigationPush } = useNavigation()

  const {
    register,
    getValues,
    setValue,
    watch,
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    setFocus,
  } = useForm({
    defaultValues: isCreatingPeerToPeerProject
      ? { occasion: PEER_TO_PEER_OCCASION_ENUMS.PEER_TO_PEER_OCCASION_BIRTHDAY }
      : {
          id: project?.id,
          title_en: project?.title_en,
          title_ru: project?.title_ru,
          title_hy: project?.title_hy,
          tagline_en: project?.tagline_en,
          tagline_ru: project?.tagline_ru,
          tagline_hy: project?.tagline_hy,
          video_en: project?.video_en,
          video_ru: project?.video_ru,
          video_hy: project?.video_hy,
          creatorName_en: project?.creatorName_en,
          creatorName_ru: project?.creatorName_ru,
          creatorName_hy: project?.creatorName_hy,
          creatorSocialLink: project?.creatorSocialLink,
          goal: project?.goal,
          content_en: project?.content_en || CONTENT_HINTS.data_en,
          content_ru: project?.content_ru || CONTENT_HINTS.data_ru,
          content_hy: project?.content_hy || CONTENT_HINTS.data_hy,
          creatorImage: project?.creatorImage,
          cover: project?.cover,
          goals: project?.goals ?? [],
          collaborations: project?.collaborations ?? [],
          totalAmount: project?.totalAmount,
          fundraisingType: project?.fundraisingType,
          isOneTimeDisabled: !project?.isOneTimeDisabled,
          fbPixel: project?.fbPixel,
          occasion: project?.occasion,
          phoneNumber: project?.phoneNumber,
        },
  })

  const {
    saveDraft,
    isCreateDraftLoading,
    isUpdateDraftLoading,
    isDraftSaved,
  } = useSaveDraft()

  const {
    fields: goalFields,
    append: appendGoal,
    remove: removeGoal,
  } = useFieldArray({
    control,
    name: 'goals',
  })
  const {
    fields: collaborationsFields,
    append: appendCollaborations,
    remove: removeCollaborations,
  } = useFieldArray({
    control,
    name: 'collaborations',
  })

  const onChangePeerToPeerFundraiser = (newProject) => {
    navigationReplace(
      location.pathname
        .replace(`/${language}`, '')
        .replace(peerProject.id, newProject.id) + location.search
    )
  }

  const onConnectStripeAccount = () => {
    const projectId = project.id
    connectAccount(
      { projectId },
      {
        onSuccess(res) {
          console.log('SUCCESS CONNECT', res)
          onUpdate({ id: projectId, stripeId: res.account.id })
          window.location.href = res.accountLink.url
        },
        onError(res) {
          console.log('ERROR', res)
        },
      }
    )
  }
  const onConnectContinueStripeAccount = ({ accountId }) => {
    const projectId = project.id
    continueConnectAccount(
      { accountId, projectId },
      {
        onSuccess(res) {
          console.log('SUCCESS CONTINUE', res)
          window.location.href = res.accountLink.url
        },
        onError(res) {
          console.log('ERROR', res)
        },
      }
    )
  }
  const tabs = useMemo(
    () => [LANG_HY_SHORT, LANG_EN_SHORT, LANG_RU_SHORT],
    [language]
  )

  const handleTabsChange = async (index) => {
    setTabIndex(index)
  }

  const onSaveAsDraft = async () => {
    const formData = getValues()
    const input = {
      ...formData,
      owner: user.id,
      isProject: true,
      fundraisingType: FUNDRAISER_PEER_TO_PEER,
      peerProjectId: peerProject.id,
    }

    deleteKey(input, '__typename')
    await saveDraft({
      input,
      callback: () => {},
    })
  }

  const onSubmit = (values) => {
    if (
      values.content_hy?.includes(
        `${process.env.REACT_APP_S3_BUCKET_URL}/public/content`
      )
    ) {
      values.content_hy = values.content_hy.replaceAll(
        `${process.env.REACT_APP_S3_BUCKET_URL}/public/content`,
        `${process.env.REACT_APP_S3_BUCKET_URL}/public/thumbnail/medium/content`
      )
    }
    if (
      values.content_en?.includes(
        `${process.env.REACT_APP_S3_BUCKET_URL}/public/content`
      )
    ) {
      values.content_en = values.content_en.replaceAll(
        `${process.env.REACT_APP_S3_BUCKET_URL}/public/content`,
        `${process.env.REACT_APP_S3_BUCKET_URL}/public/thumbnail/medium/content`
      )
    }
    if (
      values.content_ru?.includes(
        `${process.env.REACT_APP_S3_BUCKET_URL}/public/content`
      )
    ) {
      values.content_ru = values.content_ru.replaceAll(
        `${process.env.REACT_APP_S3_BUCKET_URL}/public/content`,
        `${process.env.REACT_APP_S3_BUCKET_URL}/public/thumbnail/medium/content`
      )
    }

    date.setHours(23)
    date.setMinutes(59)
    date.setSeconds(59)
    date.setMilliseconds(999)
    values.campaignImplementorPeriod_en = date.toISOString()

    if (values.goals) {
      values.goals = values.goals.map((goal) => ({
        amount: goal.amount,
        description_en: goal.description_en,
        description_ru: goal.description_ru,
        description_hy: goal.description_hy,
      }))
    }
    if (!values.totalAmount) {
      delete values.totalAmount
    }
    if (
      values.content_hy?.includes(';base64,') ||
      values.content_ru?.includes(';base64,') ||
      values.content_en?.includes(';base64,')
    ) {
      return toast({ status: 'error', title: 'Invalid content' })
    }
    values.isOneTimeDisabled = !values.isOneTimeDisabled
    deleteKey(values, '__typename')

    if (isPeerToPeerProject) {
      const validationResponse = validatePeerFormData(values)
      validationResponse.invalidGeneralFields?.forEach((field) =>
        setError(field, { message: 'This field is required' })
      )
      Object.keys(validationResponse.invalidMultiLangFields).forEach((lang) => {
        validationResponse.invalidMultiLangFields[lang].forEach((field) => {
          setError(`${field}_${lang}`, { message: 'This field is required' })
        })
      })

      if (
        !validationResponse.isGeneralFieldsValid ||
        !validationResponse.isOneLanguageValidOfMultiLangFields
      ) {
        // @INFO: THERE IS AN ERROR IN PEER PROJECT EDIT/CREATE FORM
        return setTabIndex(
          tabs.findIndex(
            (tab) => tab === validationResponse.sortedInvalidLanguages[0]
          )
        )
      }
    }

    if (isCreatingPeerToPeerProject) {
      const onSuccess = () => {
        navigationPush('/done')
      }

      const creationDate = new Date().toISOString()

      const input = {
        ...values,
        owner: user.id,
        ownerName: user.firstName,
        ownerLastName: user.lastName,
        ownerEmail: user.email,
        language,
        amount: 0,
        isProject: true,
        isCollaboration: false,
        isCompany: false,
        isTestProject: false,
        isHidden: false,
        isStripeOnBoardingDone: false,
        createdAt: creationDate,
        publishedAt: creationDate,
        statusUpdatedAt: creationDate,
        peerProjectId: peerProject.id,
        fundraisingType: FUNDRAISER_PEER_TO_PEER,
        status: PROJECT_STATUS_SUBMITTED,
        category: peerProject.category,
      }

      createProject(input, { onSuccess })
    } else {
      onUpdate(values)
    }
  }

  const onError = (data) => {
    console.log('❌ ERRORS:', data)

    const includesLanguageError = Object.keys(data)
      .map((errKey) => data[errKey]?.message)
      .includes(t(`typeIn@${tabs[tabIndex]}`))

    if (includesLanguageError && !toastCoolDown) {
      toast({
        title: t(`typeIn@${tabs[tabIndex]}`),
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })
      setToastCoolDown(true)
      setTimeout(() => {
        setToastCoolDown(false)
      }, 5000)
    }
  }

  const onBeforeUnload = (event) => {
    event.preventDefault()
    event.returnValue = ''
    return ''
  }

  const removeBeforeUnload = useBeforeUnload(
    [isDraftSaved.current],
    onBeforeUnload
  )

  useEffect(() => {
    if (isCreatingPeerToPeerProject) {
      const localDraft = normalizeDraft(getValues())
      isDraftSaved.current = _.isEqual(localDraft, draftResponse)

      if (isDraftSaved.current) {
        removeBeforeUnload()
      }
    }
  }, [isDraftSaved.current, draftResponse, isCreatingPeerToPeerProject])

  useEffect(() => {
    if (!project || isCreatingPeerToPeerProject) return
    const newDate = new Date(project.campaignImplementorPeriod_en)
    project.campaignImplementorPeriod_en && setDate(newDate)
  }, [isCreatingPeerToPeerProject, project])

  useEffect(() => {
    if (!draftResponse || !draftResponse?.id) return
    reset({
      ...draftResponse,
    })
  }, [draftResponse])

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a
    }, null)

    if (firstError) {
      setFocus(firstError)
    }
  }, [errors, setFocus])

  const onRevalidate = (fields) => fields.forEach((field) => clearErrors(field))

  useRevalidatePeerForm(watch, errors, onRevalidate)

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onError)}
      style={{ paddingBottom: '5rem', flex: '1' }}
    >
      <Box>
        <Heading as="h1">
          {t(
            `${
              isCreatingPeerToPeerProject
                ? 'Peer-to-peer Fundraiser'
                : 'createEditProject@updateContent'
            }`
          )}
        </Heading>
        <Box as="span" display="block" color="gray.700" fontSize="md" mt={2}>
          {t(
            `${
              isCreatingPeerToPeerProject
                ? 'Complete the details and invite your friends to join you in making a collective impact.'
                : 'createEditProject@updateContentNote'
            }`
          )}
        </Box>
        <Tabs mt={6} isLazy={true} index={tabIndex} onChange={handleTabsChange}>
          <TabList width="100%" borderColor="border.100" pb="1px" mb={10}>
            {tabs.map((languageCode, index) => (
              <Tab
                key={`project-content-tab-list-${languageCode}`}
                tabIndex={index}
                color="gray.700"
                fontSize="xl"
                pb={4}
                _focus={{
                  outline: 'none',
                }}
                _selected={{
                  color: 'black',
                  fontWeight: 'bold',
                  borderColor: '#012F73',
                }}
                tabData={`lang_tab_${languageCode}`}
              >
                {t(`language@${languageCode}Label`)}
              </Tab>
            ))}
          </TabList>

          <TabPanels>
            {tabs.map((languageCode, index) => (
              <TabPanel
                key={`project-content-tab-panel-${languageCode}`}
                tabIndex={index}
                p={0}
              >
                <FormData
                  project={project}
                  goals={goalFields}
                  appendGoal={appendGoal}
                  removeGoal={removeGoal}
                  collaborations={collaborationsFields}
                  appendCollaborations={appendCollaborations}
                  removeCollaborations={removeCollaborations}
                  date={date}
                  tabIndex={index}
                  validationErrors={errors}
                  errors={errors}
                  setDate={setDate}
                  lang={languageCode}
                  register={register}
                  image={watch('creatorImage')}
                  cover={watch('cover')}
                  watch={watch}
                  getValues={getValues}
                  setValue={setValue}
                  isConnectLoading={isConnectLoading}
                  isContinueConnectLoading={isContinueConnectLoading}
                  onConnectStripeAccount={onConnectStripeAccount}
                  onConnectContinueStripeAccount={
                    onConnectContinueStripeAccount
                  }
                  onChangePeerToPeerFundraiser={onChangePeerToPeerFundraiser}
                />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
      <BottomBar
        submitButtonText={t('submit')}
        isCreatingPeerToPeerProject={isCreatingPeerToPeerProject}
        isPeerProjectCreateOrUpdateLoading={isPeerProjectCreateLoading}
        isDraftLoading={isCreateDraftLoading || isUpdateDraftLoading}
        onSaveAsDraft={onSaveAsDraft}
      />
    </form>
  )
}
export default Content
