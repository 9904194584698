import { post, get } from 'aws-amplify/api'
import { getResponseToJSON } from 'hooks/useResponseManipulations'

const exportService = () => {
  const exportTable = async (tableName) => {
    const res = get({
      apiName: 'export',
      path: `/exportTable?table=${tableName}`,
    })
    return await getResponseToJSON(res)
  }

  const exportTableWithParams = async (params) => {
    const res = post({
      apiName: 'export',
      path: '/exportTableWithParams',
      options: { body: params },
    })
    return await getResponseToJSON(res)
  }

  const exportApplications = async () => {
    const res = get({
      apiName: 'export',
      path: '/exportApplications',
    })
    return await getResponseToJSON(res)
  }
  return { exportTable, exportApplications, exportTableWithParams }
}

export default exportService
