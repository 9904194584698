import React from 'react'
import {
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react'
import { ReactComponent as Avatar } from 'assets/icons/avatar.svg'
import { ReactComponent as BlueLogo } from 'assets/icons/blue_logo.svg'
import { Link } from '../../pages'

const MenuDrawerLayout = ({ isOpen, onClose, isGuest, t, user, children }) => (
  <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
    <DrawerOverlay />

    <DrawerContent maxW="336px">
      <DrawerBody p={0}>
        <Flex direction="column" pb="20px">
          {isGuest ? (
            <GuestDrawerHeader t={t} />
          ) : (
            <UserDrawerHeader
              userFullName={`${user.firstName} ${user.lastName}`}
            />
          )}

          {children}
          {!isGuest && (
            <Center w="full" mb={3} mt={7}>
              <BlueLogo height="48px" />
            </Center>
          )}
        </Flex>
      </DrawerBody>

      {isGuest && <MenuDrawerFooter t={t} />}
    </DrawerContent>
  </Drawer>
)

export default MenuDrawerLayout

const GuestDrawerHeader = ({ t }) => (
  <Flex direction="column" pt={8} pb={3} px={5} align="center">
    <BlueLogo height="48px" />

    <Heading as="h3" color="gray.700" align="center" mt={5} lineHeight="27px">
      {t('nav@headerTitle')}
    </Heading>

    <Text
      color="gray.700"
      fontSize="md"
      align="center"
      mt={5}
      lineHeight="17px"
    >
      {t('nav@headerText')}
    </Text>
  </Flex>
)

const UserDrawerHeader = ({ userFullName }) => (
  <Flex direction="column" pt={8} px={5}>
    <Avatar height="50px" width="50px" />

    <Heading fontSize="2xl" fontWeight={700} as="h3" mt={5}>
      {userFullName}
    </Heading>
  </Flex>
)

const MenuDrawerFooter = ({ t }) => (
  <DrawerFooter
    height="88px"
    boxShadow="0px -1px 2px 0px rgba(0, 0, 0, 0.10), 0px -4px 4px 0px rgba(0, 0, 0, 0.09), 0px -8px 5px 0px rgba(0, 0, 0, 0.05), 0px -14px 6px 0px rgba(0, 0, 0, 0.01), 0px -22px 6px 0px rgba(0, 0, 0, 0.00)"
    bgColor="white"
    px={5}
    py={0}
  >
    <Button
      variant="green"
      width="full"
      textTransform="none"
      as={Link}
      to="/sign-up"
      rounded="xl"
    >
      {t('nav@signUp')}
    </Button>

    <Button
      variant="white"
      width="full"
      textTransform="none"
      ml="14px"
      as={Link}
      to="/sign-in"
      rounded="xl"
    >
      {t('nav@signIn')}
    </Button>
  </DrawerFooter>
)
