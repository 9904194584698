import { Heading, Stack, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'

export default function Header() {
  const { t } = useTranslation()
  return (
    <Stack spacing={4}>
      <Heading fontSize="6xl" as="h2" color="blue.400">
        {t('Charity Basket Available')}
      </Heading>
      <Text fontWeight="600" fontSize="md" color="gray.700">
        {t(
          "Increase the chances of attracting new donors to your organization's fundraising efforts."
        )}
      </Text>
      <Text fontWeight="400" fontSize="sm" color="gray.700">
        *
        {t(
          'With this opportunity, donors support multiple vetted Nonprofits with any-amount donations in a single transaction.'
        )}
      </Text>
    </Stack>
  )
}
