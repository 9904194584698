import { useCallback, useEffect, useState, useMemo } from 'react'
import { Flex, Checkbox, Box, Select, Button } from '@chakra-ui/react'
import { useUser, useUpdateProject } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import StatusMenu from 'components/StatusMenu'
import { Link } from 'pages'
import { FUNDRAISER_PEER_TO_PEER } from 'constants/fundraiser'

export default function TypeCheckboxGroup({ project }) {
  const { t } = useTranslation()
  const { user } = useUser()
  const { updateProject, isLoading: isUpdateLoading } = useUpdateProject()
  const [data, setData] = useState()

  const editUrl = useMemo(() => {
    if (project.fundraisingType === FUNDRAISER_PEER_TO_PEER) {
      return `/edit-peer-to-peer-fundraiser/${project.id}/content`
    }

    if (project.isProject) {
      return `/edit-fundraisers/${project.id}/application`
    }

    return `/edit-collaborations/${project.id}/application`
  }, [project])

  const onUpdateProject = useCallback(
    (key, value) => {
      if (!project) return
      setData((data) => ({ ...data, [key]: value }))
      let update = {
        id: project.id,
        [key]: value,
      }
      if (value === 'live') {
        update.publishedAt = new Date().toISOString()
      }
      updateProject(update, {
        onSuccess: (response) => setData(response.data.updateProject),
      })
    },
    [project]
  )

  useEffect(() => {
    if (!project) return
    setData(project)
  }, [project])

  if (!user.isAdmin || !data) {
    return <></>
  }

  return (
    <Box my="4">
      <Flex mb="6" flexWrap="wrap">
        <Select
          variant="unstyled"
          maxW="120px"
          value={data.sequence}
          onChange={(e) => onUpdateProject('sequence', e.target.value)}
          placeholder="Sequence"
        >
          {[1, 2, 3, 4, 5].map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </Select>
        <Checkbox
          mr="4"
          isDisabled={isUpdateLoading}
          isChecked={data.popular === 'true'}
          colorScheme="green"
          onChange={() =>
            onUpdateProject(
              'popular',
              data.popular === 'true' ? 'false' : 'true'
            )
          }
        >
          {t('popular')}
        </Checkbox>
        <Checkbox
          mr="4"
          isDisabled={isUpdateLoading}
          isChecked={data.isCompany}
          colorScheme="green"
          onChange={() =>
            onUpdateProject('isCompany', data.isCompany ? 'false' : 'true')
          }
        >
          {t('Company')}
        </Checkbox>
        <Checkbox
          mr="4"
          isDisabled={isUpdateLoading}
          isChecked={data.isUrgent}
          colorScheme="red"
          onChange={() => onUpdateProject('isUrgent', !data.isUrgent)}
        >
          {t('urgent')}
        </Checkbox>
        <Checkbox
          mt={4}
          isDisabled={isUpdateLoading}
          isChecked={data.featured === 'true'}
          colorScheme="blue"
          onChange={() =>
            onUpdateProject(
              'featured',
              data.featured === 'true' ? 'false' : 'true'
            )
          }
        >
          {t('featured')}
        </Checkbox>
        <Checkbox
          ml={2}
          mt={4}
          isDisabled={isUpdateLoading}
          isChecked={data.isTestProject}
          colorScheme="blue"
          onChange={() => onUpdateProject('isTestProject', !data.isTestProject)}
        >
          {t('Test Project')}
        </Checkbox>
        <Checkbox
          ml={2}
          mt={4}
          isDisabled={isUpdateLoading}
          isChecked={data.isHidden}
          colorScheme="blue"
          onChange={() => onUpdateProject('isHidden', !data.isHidden)}
        >
          {t('Hide')}
        </Checkbox>
        <Checkbox
          ml={2}
          mt={4}
          isDisabled={isUpdateLoading}
          isChecked={data.noCTA}
          colorScheme="blue"
          onChange={() => {
            onUpdateProject('noCTA', !data.noCTA)
          }}
        >
          {t('no CTA')}
        </Checkbox>
        <Checkbox
          ml={2}
          mt={4}
          isDisabled={isUpdateLoading}
          isChecked={data.isIndividual}
          colorScheme="blue"
          onChange={() => {
            onUpdateProject('isIndividual', !data.isIndividual)
          }}
        >
          {t('isIndividual')}
        </Checkbox>
        <Checkbox
          ml={2}
          mt={4}
          isDisabled={isUpdateLoading}
          isChecked={data.isStripeOnBoardingDone}
          colorScheme="blue"
          onChange={() => {
            onUpdateProject(
              'isStripeOnBoardingDone',
              !data.isStripeOnBoardingDone
            )
            onUpdateProject('stripeId', data.stripeId ? null : 'reArmenia')
          }}
        >
          {t('Stripe')}
        </Checkbox>
      </Flex>
      <Flex>
        <StatusMenu
          type="fundraisers"
          card={data}
          onValueChange={async (status) => {
            onUpdateProject('status', status)
          }}
        />
        <Link to={editUrl}>
          <Button ml={4} color="orange.400" variant="transparent">
            {t('edit')}
          </Button>
        </Link>
      </Flex>
    </Box>
  )
}
