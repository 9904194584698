import { MainLayout } from 'components/Layouts'
import { useSignToken, useUser } from 'hooks'
import { useNavigation } from 'pages'
import React, { useEffect, useRef } from 'react'
import { Box } from '@chakra-ui/react'

export default function CosmoAdmin() {
  const { user } = useUser()
  const { navigationPush } = useNavigation()
  const { mutate: signToken } = useSignToken()
  const iframeRef = useRef(null)

  const sendDataToIframe = () => {
    console.log('sendDataToIframe')
    signToken(
      {
        text: JSON.stringify({
          iat: Date.now(),
          email: process.env.REACT_APP_COSMO_ADMIN_EMAIL,
        }),
        secret: 'cosmo',
        expiresIn: '1h',
      },
      {
        onSuccess: (res) => {
          console.log('JWT SIGN RESPONSE >>> ', res)
          const data = {
            token: res,
            email: process.env.REACT_APP_COSMO_ADMIN_EMAIL,
          }

          // Send the data to the iframe using postMessage
          console.log('data from page : ', data)
          iframeRef.current.contentWindow.postMessage(
            data,
            process.env.REACT_APP_COSMO_DOMAIN
          )
        },
      }
    )
  }

  useEffect(() => {
    const iframe = iframeRef.current

    const handleIframeLoad = () => {
      sendDataToIframe()
    }

    iframe.addEventListener('load', handleIframeLoad)

    return () => {
      iframe.removeEventListener('load', handleIframeLoad)
    }
  }, [])
  useEffect(() => {
    if (!user.isAdmin) {
      navigationPush('/')
    }
  }, [user])

  return (
    <MainLayout>
      <Box
        width="full"
        height={{
          base: '100dvh',
          sm: 'calc(100dvh - 80px)',
        }}
      >
        <iframe
          ref={iframeRef}
          src={`${process.env.REACT_APP_COSMO_DOMAIN}/platform/admin`}
          title="Contracts Workspace"
          width="100%"
          height="100%"
        />
      </Box>
    </MainLayout>
  )
}
