const hashUseCase = (hashRepo) => {
  const sign = (params) => hashRepo.hash(params)
  const verify = (params) => hashRepo.verify(params)
  return {
    sign,
    verify,
  }
}

export default hashUseCase
