import React, { useEffect, useState, useCallback } from 'react'
import { Box, ModalBody, ModalCloseButton, Text, Flex } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useSearchProjects } from 'hooks'
import { useNavigation } from 'pages'
import Search from './Search'
import Filters from './Filters'
import Projects from './Projects'
import { PROJECT_STATUS_LIVE } from 'constants/project'
import { FUNDRAISER_PEER_TO_PEER } from 'constants/fundraiser'

const SIZE = 9

const convertToElasticQuery = (filters) => {
  const query = {
    query: {
      bool: {
        must_not: [
          {
            match: {
              fundraisingType: FUNDRAISER_PEER_TO_PEER,
            },
          },
        ],
        must: [
          {
            term: {
              status: PROJECT_STATUS_LIVE,
            },
          },
          {
            term: {
              isProject: true,
            },
          },
          {
            term: {
              isTestProject: false,
            },
          },
          {
            term: {
              isHidden: false,
            },
          },
        ],
      },
    },
    size: SIZE,
  }

  if (filters.location) {
    query.query.bool.must.push({
      term: {
        region: filters.location,
      },
    })
  }

  if (filters.category) {
    query.query.bool.must.push({
      term: {
        category: filters.category,
      },
    })
  }

  if (filters?.search) {
    const fieldsToSearch = [
      'title_en',
      'title_ru',
      'title_hy',
      'tagline_en',
      'tagline_hy',
      'tagline_ru',
      'applicationTitle',
    ]

    query.query.bool.must.push({
      multi_match: {
        query: filters.search,
        type: 'phrase_prefix',
        fields: fieldsToSearch,
      },
    })
  }

  return query
}

export default function ChoosePeerToPeerFundraiser({ closeModal, onChoose }) {
  const { t } = useTranslation()
  const [filters, setFilters] = useState({})
  const [projects, setProjects] = useState([])
  const { navigationPush } = useNavigation()
  const {
    data: projectsResponse,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isLoading,
  } = useSearchProjects(convertToElasticQuery(filters))

  const onFilterChange = useCallback(
    (key, value) =>
      setFilters((oldFilters) => ({ ...oldFilters, [key]: value })),
    [setFilters]
  )

  const onSearch = useCallback((value) => onFilterChange('search', value), [])

  const onLoadMore = useCallback(() => {
    fetchNextPage()
  }, [])

  const onChooseProject = useCallback(
    (project) => {
      closeModal({}, null, '', false, false)
      onChoose(project)
    },
    [navigationPush]
  )

  useEffect(() => {
    if (projectsResponse?.pages) {
      setProjects(() => {
        const sanitizedProjectResponseItems =
          projectsResponse?.pages?.flatMap((page) => page.items) || []
        return sanitizedProjectResponseItems
      })
    }
  }, [projectsResponse])

  return (
    <Box pt={12} px={4} maxW="1096px">
      <ModalCloseButton
        w="auto"
        h="auto"
        p={3}
        _active={{ background: 'transparent' }}
        _hover={{ background: 'transparent' }}
      />
      <ModalBody pt={0} px={0} minH="80vh">
        <Search isFetching={isFetching || isLoading} onSearch={onSearch} />
        <Flex mt={8} mb={6} alignItems="center" justifyContent="space-between">
          <Text color="gray.700" w="100%" maxW="60%">
            {t(
              "Choose the fundraiser you're most eager to support, fill in the necessary details, and await our prompt confirmation."
            )}
          </Text>
          <Filters filters={filters} onFilterChange={onFilterChange} />
        </Flex>
        <Projects
          projects={projects}
          total={projectsResponse?.pages?.[0]?.total?.value}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          onLoadMore={onLoadMore}
          onChooseProject={onChooseProject}
        />
      </ModalBody>
    </Box>
  )
}
