const authUseCase = (authRepo) => {
  const signUp = async (signUpParams) => {
    const response = await authRepo.signUp(signUpParams)
    return response
  }
  const setPassword = (body) => authRepo.setPassword(body)
  const resendSignUp = async (email) => authRepo.resendSignUp(email)
  const confirmSignUp = (confirmParams) => authRepo.confirmSignUp(confirmParams)
  const forgetPassword = (email) => authRepo.forgetPassword(email)
  const forgetPasswordSubmit = (params) => authRepo.forgetPasswordSubmit(params)
  const signIn = async (signInParams) => authRepo.signIn(signInParams)
  const fbSignIn = () => authRepo.socialSignIn({ provider: 'Facebook' })
  const googleSignIn = () => authRepo.socialSignIn({ provider: 'Google' })

  return {
    signUp,
    resendSignUp,
    confirmSignUp,
    forgetPassword,
    forgetPasswordSubmit,
    signIn,
    fbSignIn,
    googleSignIn,
    setPassword,
  }
}

export default authUseCase
