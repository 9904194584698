import { useEffect } from 'react'
import { useTranslation } from 'contexts/TranslationContext'
import { useQueryParams } from 'hooks'
import ThankYouPageTemplate from './ThankYouPageTemplate'

function ThankYouIDram() {
  const params = useQueryParams()

  const getParam = (name) => params.get('app:' + name) || params.get(name)

  const projectId = getParam('projectId')
  const transactionId = getParam('transactionId')
  const language = getParam('language')

  const { setLanguage } = useTranslation()
  useEffect(() => {
    setLanguage(language)
  }, [language])

  const isCollaboration = params.get('collaboration')?.trim() === 'true'

  return (
    <ThankYouPageTemplate
      isCollaboration={isCollaboration}
      projectId={projectId}
      transactionId={transactionId}
      analyticEvents={{
        amplitude: 'ThankYou iDram page view',
      }}
    />
  )
}

export default ThankYouIDram
