import { useQuery } from 'react-query'
import { transactionUseCase } from '../../core/factories'

const useListTransactionsByPeerProjectId = (variables, options) => {
  const data = useQuery(
    ['transactions-by-peer-project-id', variables],
    () => transactionUseCase.listTransactionsByPeerProjectId(variables),
    options
  )

  return data
}

export default useListTransactionsByPeerProjectId
