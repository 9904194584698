import {
  Box,
  FormLabel,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { InputField } from 'components/InputField'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as ChainIcon } from 'assets/icons/chain.svg'
import Header from '../Header'
import FileFormField from './FileFormField'

export default function NonProfitDetails({
  register,
  errors,
  clearErrors,
  setValue,
  getValues,
  watch,
}) {
  const { t } = useTranslation()
  return (
    <Box maxW="600px" width="100%">
      <Header />
      <Stack mb="5.5rem" spacing="12" mt={10}>
        <InputField
          isInvalid={!!errors.contactFullName}
          label={`5. ${t('Contact person (name, surname)')}*`}
          titleProps={{ fontWeight: 'bold', fontSize: 'md' }}
          errorMessage={t('createEditProject@thisFieldIsRequired')}
          maxLength={80}
          {...register('contactFullName', { required: true })}
        />
        <InputField
          isInvalid={!!errors.phone}
          titleProps={{ fontWeight: 'bold', fontSize: 'md' }}
          label={`6. ${t('Phone number')}*`}
          errorMessage={t('createEditProject@thisFieldIsRequired')}
          type="number"
          pattern="[0-9+]"
          {...register('phone', { required: true })}
        />
        <InputField
          isInvalid={!!errors.email}
          label={`7. ${t('Email')}*`}
          titleProps={{ fontWeight: 'bold', fontSize: 'md' }}
          errorMessage={t(errors?.email?.message)}
          maxLength={80}
          {...register('email', {
            required: {
              message: 'createEditProject@thisFieldIsRequired',
              value: true,
            },
            pattern: {
              message: 'createEditInitiative@emailError',
              value: /\S+@\S+\.\S+/,
            },
          })}
        />
        <InputField
          label={`8. ${t(
            'If you have any media coverage about the organization, please attach them here.'
          )}`}
          titleProps={{ fontWeight: 'bold', fontSize: 'md' }}
          Icon={ChainIcon}
          maxLength={80}
          {...register('mediaLink')}
        />
        <Stack>
          <FormLabel
            bg={{
              base: mode('white', 'gray.800'),
              md: mode('white', 'gray.700'),
            }}
            zIndex={2}
            fontWeight={'bold'}
            fontSize="md"
          >
            <Text as={'span'}>{`9. ${t("Organization's charter")}*`}</Text>
          </FormLabel>
          <FileFormField
            label="Upload"
            register={register}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            data={watch('files')}
            fieldKey={'files'}
            clearErrors={clearErrors}
          />
        </Stack>
        <InputField
          label={`10. ${t(
            'Helpful Links (Website, Social Media Pages, etc.)'
          )}`}
          titleProps={{ fontWeight: 'bold', fontSize: 'md' }}
          Icon={ChainIcon}
          maxLength={80}
          {...register('links')}
        />
      </Stack>
    </Box>
  )
}
