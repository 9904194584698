import { useSignToken, useUser } from 'hooks'
import React, { useEffect, useRef } from 'react'
import { Box } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'

const languageTransformer = {
  hy: 'am',
}

export default function Cosmo({ workspaceId, ...props }) {
  const { user } = useUser()
  const { mutate: signToken } = useSignToken()
  const { language } = useTranslation()
  const iframeRef = useRef(null)

  const sendDataToIframe = () => {
    console.log('sendDataToIframe')
    signToken(
      {
        text: JSON.stringify({
          iat: Date.now(),
          email: user.email,
        }),
        secret: 'cosmo',
        expiresIn: '1h',
      },
      {
        onSuccess: (res) => {
          console.log('JWT SIGN RESPONSE >>> ', res)
          const data = {
            // userId: user.cosmoId,
            token: res,
            email: user.email,
          }

          // Send the data to the iframe using postMessage
          console.log('data from page : ', data)
          iframeRef.current.contentWindow.postMessage(
            data,
            process.env.REACT_APP_COSMO_DOMAIN
          )
        },
      }
    )
  }

  useEffect(() => {
    const iframe = iframeRef.current

    const handleIframeLoad = () => {
      sendDataToIframe()
    }

    iframe.addEventListener('load', handleIframeLoad)

    return () => {
      iframe.removeEventListener('load', handleIframeLoad)
    }
  }, [])

  return (
    <Box
      width="full"
      height={{
        base: 'calc(100dvh - 48px)',
        sm: 'calc(100dvh - 80px)',
      }}
      {...props}
    >
      <iframe
        ref={iframeRef}
        src={`${process.env.REACT_APP_COSMO_DOMAIN}/${
          languageTransformer?.[language] || language
        }/platform/workspace/${workspaceId}`}
        title="Contracts Workspace"
        width="100%"
        height="100%"
      />
    </Box>
  )
}
