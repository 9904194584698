import { useMemo } from 'react'
import moment from 'moment'
import { Grid, GridItem, Flex } from '@chakra-ui/react'
import { useNavigation } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import ProjectCard from 'components/ProjectCard'
import {
  useCurrencySettings,
  thousandSeparator,
  convert,
} from 'contexts/CurrencySettingsContext'
import { calculateProjectDurationLeft } from 'hooks'
import EmptyState from 'components/EmptyState'
import { PROJECT_TYPE_NON_PROFIT } from 'constants/project'

export default function FilteredData({
  data,
  filterStatus,
  type,
  fundraisingType,
  isInKind,
  onDraftDelete,
  emptyButtonText,
  desktopMT,
}) {
  const { t, language } = useTranslation()
  const { currency, settings, changeDefaultCurrency } = useCurrencySettings()
  const { navigationPush } = useNavigation()

  const filteredData = useMemo(() => {
    if (filterStatus[0] === 'all') return data
    return data?.filter((row) => filterStatus.includes(row.status))
  }, [data, filterStatus])

  const emptyTranslation = useMemo(() => {
    const dataType = type === 'fundraisers' ? 'Project' : 'Initiative'
    const projectType = fundraisingType === 'oneTime' ? 'oneTime' : 'recurring'
    return type === 'fundraisers'
      ? t(`profile@no${dataType}@${projectType}`)
      : isInKind
      ? t(`profile@no${dataType}@isInKind`)
      : t(`profile@no${dataType}`)
  }, [type, filterStatus])
  return (
    <>
      <Grid
        templateColumns={{
          xl: 'repeat(3, 1fr)',
          md: 'repeat(2, 1fr)',
          base: 'repeat(1, 1fr)',
        }}
        gap={8}
      >
        {filteredData
          ?.sort((a, b) =>
            moment.utc(b.createdAt).diff(moment.utc(a.createdAt))
          )
          ?.map((slide, index) => (
            <GridItem key={`row-slide-${slide.id}-index-${index}`} colSpan={1}>
              <ProjectCard
                card={slide}
                goalPrice={slide.activeGoal?.amount || slide.goal}
                raisedMoney={thousandSeparator(
                  convert({
                    amount: slide.amount,
                    currencyRate: settings?.currency[currency.current],
                  }),
                  currency.current
                )}
                thousandSeparatorText={thousandSeparator(
                  currency.current === 'AMD'
                    ? slide.amount + Number(slide.updateAmount)
                    : slide.amount /
                        Number(settings?.currency[currency.current]) +
                        Number(slide.updateAmount),
                  currency.current
                )}
                edit={true}
                calculatedProjectDurationLeft={calculateProjectDurationLeft({
                  t,
                  campaignImplementorPeriod_en:
                    slide.campaignImplementorPeriod_en,
                  fundraisingType: slide.fundraisingType,
                })}
                type={type}
                created={t('created')}
                continueTranslation={t('Continue')}
                remove={t('Remove')}
                language={language}
                currency={currency}
                onDraftDelete={onDraftDelete}
                settings={settings}
                changeDefaultCurrency={changeDefaultCurrency}
                navigationPush={navigationPush}
                cardStatusText={t(`status@${slide.status}`)}
                cardCategoryText={t(`category@${slide.category}`)}
                editText={t('edit')}
                unsubscribeText={t('unsubscribe')}
                monthlyGoalText={t('monthlyGoal')}
                raisedText={t('raised')}
                collaborationProposalsText={t(
                  slide?.collaboratorsTotal === 1
                    ? 'collaboration proposal'
                    : 'collaboration proposals'
                )}
                collaborationProposalsTextRuEdit={t('collaboration proposals')
                  .split('...')[1]
                  .replace('запросов', 'запроса')}
                bodyBackground="#F3F3F3"
              />
            </GridItem>
          ))}
      </Grid>
      {filteredData?.length < 1 && (
        <Flex justifyContent="center">
          <EmptyState
            description={emptyTranslation}
            button={emptyButtonText}
            link={
              fundraisingType === PROJECT_TYPE_NON_PROFIT
                ? '/create-project?isNonProfit=true&fundraisingType=nonProfit'
                : '/pre-create-project'
            }
            desktopMT={desktopMT}
          />
        </Flex>
      )}
    </>
  )
}
