import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useProject } from '../../ProjectContext'
import FilesUpload from 'components/FilesUpload'

export default function Files() {
  const { project, onUpdate } = useProject()
  const { setValue, getValues, reset, watch } = useForm({
    defaultValues: {
      files: project?.files || [],
    },
  })
  const files = watch('files', [])

  useEffect(() => {
    if (!project) return
    reset({ files: project.files })
  }, [project])

  const onUpload = useCallback(
    (key, value) => {
      setValue(key, value)
      const input = {
        id: project.id,
        files: value,
      }
      onUpdate(input)
    },
    [project]
  )

  return (
    <>
      <FilesUpload files={files} setValue={onUpload} getValues={getValues} />
    </>
  )
}
