import { I18n } from 'aws-amplify/utils'

import msgEnUs from './messages/en-us.json'
import msgRuRu from './messages/ru-ru.json'
import msgHyAm from './messages/hy-am.json'
import COUNTRY_LIST from 'translations/countries.json'

export const configure = () => {
  I18n.configure({
    supportedLngs: ['en-US', 'ru-RU', 'hy-AM'],
    fallbackLng: 'en-US',
  })

  I18n.putVocabularies({
    'en-US': msgEnUs,
    'ru-RU': msgRuRu,
    'hy-AM': msgHyAm,
  })
}

export const getCountryByNameOrCode = ({ countryName, countryCode }) =>
  countryName
    ? COUNTRY_LIST.find(({ name_en, name_hy, name_ru }) =>
        [name_en, name_hy, name_ru].includes(countryName)
      )
    : COUNTRY_LIST.find(({ code }) => countryCode === code)
