import { Box } from '@chakra-ui/react'

export const LABEL_PROPS = {
  as: 'span',
  fontSize: 'md',
  fontWeight: '500',
  color: 'gray.800',
  lineHeight: '18px',
  display: 'block',
}

export const TEXT_PROPS = {
  as: 'p',
  fontSize: 'md',
  color: 'gray.700',
  lineHeight: '18px',
}

export default function InfoBlock({ value, label }) {
  if (!value) {
    return null
  }
  return (
    <Box>
      <Box {...LABEL_PROPS} mt={4} mb={1}>
        {label}
      </Box>
      <Box {...TEXT_PROPS} mt={4}>
        {value}
      </Box>
    </Box>
  )
}
