import React from 'react'
import { Flex, Heading, IconButton } from '@chakra-ui/react'
import { ReactComponent as Search } from 'assets/icons/searchSmall.svg'
import { ReactComponent as Bars } from 'assets/icons/blackBars.svg'
import { Link } from 'pages'
import { ReactComponent as Back } from 'assets/icons/backArrow.svg'
import NotificationsButton from 'components/NotificationsIcon'
import BasketIconButton from 'components/BasketIconButton'

const DashboardNavbar = ({
  backTo,
  title = '',
  isGuest,
  onOpen,
  titleProps = {},
  user,
}) => (
  <Flex
    minH={12}
    w="full"
    py={3}
    px={4}
    bg="white"
    justify="space-between"
    align="center"
  >
    {backTo && (
      <Link to={backTo}>
        <Back width="24px" height="24 px" />
      </Link>
    )}
    <Heading fontSize="2xl" fontWeight={500} {...titleProps}>
      {title}
    </Heading>

    <Flex alignItems="center" gap={3}>
      <Link to="/search">
        <IconButton minW="unset" icon={<Search width="24px" height="24px" />} />
      </Link>
      {!isGuest && (
        <>
          <Link to="/profile/notifications">
            <NotificationsButton
              unreadNotifications={user?.unreadNotifications}
            />
          </Link>
          <BasketIconButton />
        </>
      )}
      <IconButton
        minW="unset"
        icon={<Bars width="24px" height="24px" />}
        onClick={onOpen}
      />
    </Flex>
  </Flex>
)

export default DashboardNavbar
