import { useRef } from 'react'
import {
  Box,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useProject } from '../ProjectContext'

export default function Remove({ tabProps, isLast, isProject }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { t } = useTranslation()
  const { onRemove, isRemoveLoading } = useProject()
  const cancelRef = useRef()

  const onRemoveClick = (e) => {
    e.preventDefault()
    onOpen()
  }

  return (
    <>
      <Box as="a" onClick={onRemoveClick} {...tabProps}>
        {t('remove')}
      </Box>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{t('card@alertWarningHeading')}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>{t('card@alertWarningDescription')}</AlertDialogBody>
          <AlertDialogFooter>
            <Button
              isDisabled={isRemoveLoading}
              minH="20px"
              minW="100px"
              color="red"
              variant="transparent"
              ref={cancelRef}
              onClick={onClose}
            >
              {t('no')}
            </Button>
            <Button
              onClick={() => onRemove({ isLast, isProject })}
              minH="20px"
              minW="100px"
              variant="green"
              colorScheme="red"
              ml={3}
              isLoading={isRemoveLoading}
              isDisabled={isRemoveLoading}
            >
              {t('yes')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
