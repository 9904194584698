const imageRepo = (imageService) => {
  const uploadImage = async (params) => imageService.uploadImage(params)

  const getImageUrl = async (key) => imageService.getImageUrl(key)

  return {
    uploadImage,
    getImageUrl,
  }
}

export default imageRepo
