import { useContext, createContext, useEffect, useState, useMemo } from 'react'
import _ from 'lodash'
import {
  useListTransactionsByDate,
  useListTransactionsByPeerProjectId,
} from 'hooks'
import { TRANSACTION_STATUS } from 'pages/Donation/constants'
import { FUNDRAISER_PEER_TO_PEER } from 'constants/fundraiser'

const TransactionsContext = createContext({
  setLoadMore: () => {},
  isMoreLoading: false,
  nextToken: null,
  data: [],

  peerToPeerData: [],
  isPeerToPeerMoreLoading: false,
  fetchPeerToPeer: () => {},
  peerToPeerNextToken: null,
})

export const useTransactions = () => useContext(TransactionsContext)

const useLoadTransactionsQuery = (
  project,
  nextToken,
  isPeerToPeerFundraiser
) => {
  const transactionsQuery = useListTransactionsByDate(
    {
      projectId: project.id,
      sortDirection: 'DESC',
      limit: 50,
      nextToken,
      filter: {
        status: {
          eq: TRANSACTION_STATUS.DONE,
        },
      },
    },
    { enabled: !isPeerToPeerFundraiser }
  )

  const byPeerToPeerProjectTransactionsQuery =
    useListTransactionsByPeerProjectId(
      {
        peerProjectId: project.id,
        sortDirection: 'DESC',
        limit: 50,
        nextToken,
        filter: {
          status: {
            eq: TRANSACTION_STATUS.DONE,
          },
        },
      },
      { enabled: isPeerToPeerFundraiser }
    )

  if (isPeerToPeerFundraiser) {
    return byPeerToPeerProjectTransactionsQuery
  }

  return transactionsQuery
}

const useLoadTransactions = (
  project,
  isPeerToPeerFundraiser,
  peerToPeerTransactions
) => {
  const [data, setData] = useState([])
  const [isMoreLoading, setIsMoreLoading] = useState(false)
  const [nextToken, setNextToken] = useState()
  const { data: transactionsResponse, refetch: refetchTransactionsList } =
    useLoadTransactionsQuery(
      project,
      nextToken,
      isPeerToPeerFundraiser,
      peerToPeerTransactions
    )

  const fetch = () => {
    setIsMoreLoading(true)
    setNextToken(transactionsResponse.nextToken)
    if (transactionsResponse.nextToken) {
      refetchTransactionsList(transactionsResponse.nextToken)
    }
  }

  useEffect(() => {
    if (transactionsResponse?.items) {
      setData(_.uniqBy([...data, ...transactionsResponse.items], 'id'))
      setIsMoreLoading(false)
      if (nextToken === undefined) {
        setNextToken(transactionsResponse.nextToken)
      }
      if (!transactionsResponse.nextToken) {
        setNextToken(null)
      }
    }
  }, [transactionsResponse])

  return {
    data,
    isMoreLoading,
    fetch,
    nextToken,
  }
}

export const TransactionsProvider = ({ children, project }) => {
  const isPeerToPeerFundraiser =
    project?.fundraisingType === FUNDRAISER_PEER_TO_PEER

  const transactions = useLoadTransactions(project, isPeerToPeerFundraiser)

  const transactionsForPeerToPeer = useLoadTransactions(
    project,
    isPeerToPeerFundraiser
  )

  const providerValue = useMemo(() => {
    if (isPeerToPeerFundraiser) {
      return {
        ...transactionsForPeerToPeer,
        setLoadMore: transactionsForPeerToPeer.fetch,
      }
    }

    return {
      setLoadMore: transactions.fetch,
      isMoreLoading: transactions.isMoreLoading,
      nextToken: transactions.nextToken,
      data: transactions.data,
    }
  }, [isPeerToPeerFundraiser, transactions, transactionsForPeerToPeer])

  return (
    <TransactionsContext.Provider value={providerValue}>
      {children}
    </TransactionsContext.Provider>
  )
}
