import React from 'react'
import { useUser } from 'hooks'
import { Flex, Heading, Text, Button, HStack } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import MobileFeatureSwiper from 'components/MobileFeatureSwiper'
import { Link } from 'pages'
import Navbar from 'components/Navbar'

const Header = ({ projects, isLoading }) => {
  const { t } = useTranslation()
  const { user } = useUser()

  return (
    <>
      {user.id === 'guest' && (
        <Flex
          flexDirection="column"
          pt="3.375rem"
          pb="20"
          alignItems="center"
          justifyContent="center"
          px="5"
        >
          <Heading
            as="h1"
            mb="6"
            fontWeight="600"
            fontSize="3xl"
            textAlign="center"
          >
            {t('footer@note')}
          </Heading>
          <Text mb="10" fontSize="md" textAlign="center">
            {t(
              'reArmenia is a collaboration platform that gives an opportunity to Armenians all over the world to unite, put together their resources and make effective changes in Armenia by raising issues related to different spheres or coming up with proper solutions.'
            )}
          </Text>

          <HStack spacing="3">
            <Link to="/sign-up">
              <Button w="150px" variant="blue" fontSize="md">
                {t('Join us now')}
              </Button>
            </Link>
            <Link to="/about-us">
              <Button w="150px" variant="border" fontSize="md">
                {t('About us')}
              </Button>
            </Link>
          </HStack>
        </Flex>
      )}
      <MobileFeatureSwiper isLoading={isLoading} projects={projects} />
      <Navbar from="home" />
    </>
  )
}

export default Header
