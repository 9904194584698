const projectSystemUseCase = (ProjectSystemService) => {
  const updateProjectSystem = (input) =>
    ProjectSystemService.updateProjectSystem(input)

  const getProjectSystemByProjectId = (projectId) => {
    if (!projectId) {
      console.log(
        '>>> [projectSystemUseCase] getProjectSystemByProjectId: projectId is required'
      )

      return null
    }

    return ProjectSystemService.getProjectSystemByProjectId(projectId)
  }

  return {
    updateProjectSystem,
    getProjectSystemByProjectId,
  }
}

export default projectSystemUseCase
