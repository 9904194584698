const exportUseCase = (exportRepo) => {
  const exportTable = (table) => exportRepo.exportTable(table)
  const exportApplications = () => exportRepo.exportApplications()
  const exportTableWithParams = (params) =>
    exportRepo.exportTableWithParams(params)

  return {
    exportTable,
    exportApplications,
    exportTableWithParams,
  }
}

export default exportUseCase
