import {
  Box,
  Flex,
  GridItem,
  Heading,
  SimpleGrid,
  Spinner,
  Text,
} from '@chakra-ui/react'
import ProjectCard from 'components/ProjectCard'
import {
  convert,
  thousandSeparator,
  useCurrencySettings,
} from 'contexts/CurrencySettingsContext'
import { useTranslation } from 'contexts/TranslationContext'
import { projectsByIds } from 'elastic/queries'
import { calculateProjectDurationLeft, useProjects, useUser } from 'hooks'
import { useNavigation } from 'pages'
import React from 'react'

export default function Matching() {
  const { company } = useUser()
  const { projects, isLoading } = useProjects(
    projectsByIds(company.projectIds),
    true,
    'company_fundraisers',
    'company_fundraisers'
  )
  const { t, language } = useTranslation()
  const { currency, settings } = useCurrencySettings()
  const { navigationPush } = useNavigation()
  console.log(company)
  console.log(projects)
  if (isLoading) {
    return (
      <Box mt={8}>
        <MatchingHeading
          title={t('matching@title')}
          companyTitleText={t('matching@companyTitleText').replace(
            '[companyName]',
            company.name
          )}
          companyAmountText={t('matching@companyAmountText')
            .replace('[amount]', company.amount)
            .replace('[period]', company.period)}
        />
        <Flex
          w="full"
          minHeight="540px"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner color="blue.300" />
        </Flex>
      </Box>
    )
  }
  return (
    <Box mt={8}>
      <MatchingHeading
        title={t('matching@title')}
        companyTitleText={t('matching@companyTitleText').replace(
          '[companyName]',
          company.name
        )}
        companyAmountText={t('matching@companyAmountText')
          .replace('[amount]', company.amount)
          .replace('[period]', company.period)}
      />
      <SimpleGrid
        w="full"
        columns={{
          base: 1,
          md: 2,
          xl: 3,
        }}
        spacing="8"
        mt={8}
      >
        <>
          {projects?.items.length ? (
            <>
              {projects.items.map((project, index) => (
                <GridItem key={`row-project-${project.id}-index-${index}`}>
                  <ProjectCard
                    card={project}
                    goalPrice={project.activeGoal?.amount || project.goal}
                    raisedMoney={thousandSeparator(
                      convert({
                        amount: project.amount,
                        currencyRate: settings?.currency[currency.current],
                      }),
                      currency.current
                    )}
                    thousandSeparatorText={thousandSeparator(
                      currency.current === 'AMD'
                        ? project.amount + Number(project.updateAmount)
                        : project.amount /
                            Number(settings?.currency[currency.current]) +
                            Number(project.updateAmount),
                      currency.current
                    )}
                    edit={false}
                    calculatedProjectDurationLeft={calculateProjectDurationLeft(
                      {
                        t,
                        campaignImplementorPeriod_en:
                          project.campaignImplementorPeriod_en,
                        fundraisingType: project.fundraisingType,
                      }
                    )}
                    created={t('created')}
                    continueTranslation={t('Continue')}
                    remove={t('Remove')}
                    language={language}
                    currency={currency}
                    settings={settings}
                    navigationPush={navigationPush}
                    cardStatusText={t(`status@${project.status}`)}
                    cardCategoryText={t(`category@${project.category}`)}
                    editText={t('edit')}
                    unsubscribeText={t('unsubscribe')}
                    monthlyGoalText={t('monthlyGoal')}
                    raisedText={t('raised')}
                    collaborationProposalsText={t(
                      project?.collaboratorsTotal === 1
                        ? 'collaboration proposal'
                        : 'collaboration proposals'
                    )}
                    collaborationProposalsTextRuEdit={t(
                      'collaboration proposals'
                    )
                      .split('...')[1]
                      .replace('запросов', 'запроса')}
                  />
                </GridItem>
              ))}
            </>
          ) : (
            'No results'
          )}
        </>
      </SimpleGrid>
    </Box>
  )
}

function MatchingHeading({
  title,
  companyTitleText,
  companyAmountText,
  ...props
}) {
  return (
    <Box maxW="620px" {...props}>
      <Heading fontWeight={500}>{title}</Heading>
      <Text color="gray.700" mb="4">
        {companyTitleText}
      </Text>
      <Text color="gray.700">{companyAmountText}</Text>
    </Box>
  )
}
