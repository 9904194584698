const Table = {
  baseStyle: {
    thead: {
      tr: {
        bg: '#F3F3F3',
        h: '72px',
        th: {
          borderColor: '#CDCDCD',
          color: 'black',
          fontWeight: 'bold',
          fontSize: '16px',
          maxWidth: '208px',
          p: '26px 40px',
          textTransform: 'none',
        },
      },
    },
    tbody: {
      tr: {
        h: '72px',
        td: {
          borderColor: '#CDCDCD',
          color: 'black',
          fontSize: '14px',
          maxWidth: '208px',
          overflow: 'hidden',
        },
      },
    },
  },
}

export default Table
