import React from 'react'
import { Button, Center, Flex, Heading, Spinner, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import EmptyState from 'components/EmptyState'
import InvestmentCard from './InvestmentCard'
import { BlueSelect } from 'components/Select/BlueSelect'
import {
  CurrencyConverter,
  SUPPORTED_CURRENCIES,
  SUPPORTED_CURRENCY_SYMBOLS,
  useCurrencySettings,
} from 'contexts/CurrencySettingsContext'
import useListInvestments from './useListInvestments'
const DEFAULT_PROJECT_ID = '6d530a79-e071-49b6-95ea-51323f1d850c'
export default function OneTime({ user }) {
  const { t } = useTranslation()
  const { currency, changeDefaultCurrency } = useCurrencySettings()
  const { isLoading, isRefetching, onLoadNext, items, nextToken } =
    useListInvestments({
      email: user.email,
      isRecurring: false,
    })

  return (
    <>
      {isLoading ? (
        <Center h="100%">
          <Spinner />
        </Center>
      ) : items && items?.length > 0 && !!user?.isReArmeniaInvestor ? (
        <Flex
          direction="column"
          alignItems="flex-start"
          sx={{ '> div': { width: '100%' } }}
        >
          <Flex alignItems="center" mb={12}>
            <Heading color="gray.700" fontSize="2xl" fontWeight={800} mr={2}>
              {t('profile@dashboard@investor@oneTime@totalInvestment')}:
            </Heading>
            <Text color="gray.900" fontSize="2xl" fontWeight={800}>
              {CurrencyConverter({
                currency: currency.current,
                amount: Math.round(+user?.investmentOneTimeTotal),
              })}
            </Text>
            <BlueSelect
              variant="noBorder"
              display="flex"
              type="currency"
              amount={Math.round(+user?.investmentOneTimeTotal)}
              label={SUPPORTED_CURRENCY_SYMBOLS[currency.current]}
              options={SUPPORTED_CURRENCIES.map((option) => ({
                title: t(`currency@${option}`),
                amount: `${CurrencyConverter({
                  currency: option,
                  amount: Math.round(+user?.investmentOneTimeTotal),
                })} ${option}`,
                value: option,
              }))}
              selectOption={changeDefaultCurrency}
              currentSelectedOption={t(`currency@${currency.current}`)}
              labelFontSize="xl"
            />
          </Flex>
          <Heading fontSize="2xl" fontWeight={600} color="gray.800" mt={0}>
            {t('profile@dashboard@investor@oneTime@investmentHistory')}{' '}
          </Heading>
          <Flex direction="column" w="full">
            {items.map(
              ({ amount, donationToProject, formattedDate, creditCard }) => (
                <InvestmentCard
                  key={formattedDate}
                  amount={
                    SUPPORTED_CURRENCY_SYMBOLS[currency.current] +
                    ' ' +
                    CurrencyConverter({
                      currency: currency.current,
                      amount: amount || donationToProject,
                    })
                  }
                  formattedDate={formattedDate}
                  creditCard={creditCard?.[0]}
                  t={t}
                />
              )
            )}
            {nextToken && (
              <>
                {isRefetching ? (
                  <Flex
                    w={{ base: 'full', sm: '417px' }}
                    justifyContent="center"
                    my="52px"
                  >
                    <Spinner color="blue.300" />
                  </Flex>
                ) : (
                  <Button
                    mt="4"
                    minH="40px"
                    w={{ base: 'full', sm: '417px' }}
                    variant="white"
                    isLoading={isLoading}
                    onClick={onLoadNext}
                  >
                    {t('seeMore')}
                  </Button>
                )}
              </>
            )}
          </Flex>
        </Flex>
      ) : (
        <Center h="100%">
          <EmptyState
            description={t(
              'profile@dashboard@investor@oneTime@emptyDescription'
            )}
            button={t('profile@dashboard@investor@oneTime@emptyButton')}
            link={`/donation?projectId=${DEFAULT_PROJECT_ID}`}
          />
        </Center>
      )}
    </>
  )
}
