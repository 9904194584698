import {
  Flex,
  Heading,
  Button,
  Container,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { Link } from 'pages'
import BlogCard from 'components/BlogCard'
import { useTranslation } from 'contexts/TranslationContext'
import { useListBlog } from 'hooks'

export default function Blog() {
  const { t, language } = useTranslation()
  const { data } = useListBlog()

  if (!data || data[`items_${language}`].length === 0) {
    return <></>
  }

  return (
    <Container maxW="7xl" mb="10rem" pt={{ base: '3rem', sm: '6.25rem' }}>
      <Flex alignItems="center" justifyContent="space-between" mb={10}>
        <Heading
          fontSize={{ base: '3xl', sm: '2.5rem' }}
          as="h2"
          wordBreak="break-word"
          textTransform="capitalize"
        >
          {t('homepage@blog')}
        </Heading>
        <Link to="/blog">
          <Button>{t('seeAll')}</Button>
        </Link>
      </Flex>
      <Grid templateColumns="repeat(12, 1fr)" gap={8}>
        {data &&
          data[`items_${language}`].slice(0, 3).map((row) => (
            <GridItem colSpan={4} key={`blog-item-${row.id}`}>
              <BlogCard card={row} />
            </GridItem>
          ))}
      </Grid>
    </Container>
  )
}
