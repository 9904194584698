import { useMutation } from 'react-query'
import { useUser } from '../user'
import { useUserFactory } from '../user'
import { guestUser } from '../../contexts/UserContext/UserProvider'

const useSignOut = () => {
  const { setUser } = useUser()
  const userUseCase = useUserFactory()

  const { mutate } = useMutation(userUseCase.signOut)

  const signOut = () => {
    setUser((user) => ({ ...user, user: guestUser }))
    localStorage.removeItem('rearmenia_user')
    mutate()
  }

  return signOut
}

export default useSignOut
