import React from 'react'
import { Flex, Box, Button, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import {
  useCurrencySettings,
  CurrencyConverter,
} from 'contexts/CurrencySettingsContext'
import ImageWrapper from 'components/ImageWrapper'

const PerkCard = ({
  image,
  title,
  price,
  description,
  isSelectable = true,
  convertPrice = true,
  onSelect = () => {},
  ...rest
}) => {
  const { t } = useTranslation()
  const { currency } = useCurrencySettings()
  return (
    <Flex
      onClick={isSelectable ? onSelect : () => {}}
      cursor={isSelectable ? 'pointer' : 'default'}
      mt="4"
      alignItems="flex-start"
      border="1px solid"
      p={{ base: 0, sm: 4 }}
      borderColor="border.100"
      borderRadius="12px"
      flexDir={{ base: 'column', sm: 'row' }}
      {...rest}
    >
      <ImageWrapper
        mr={{ base: 0, sm: 4 }}
        objectFit="cover"
        width={{ base: 'full', sm: '196px' }}
        height={{ base: '184', sm: '111px' }}
        src={`/thumbnail/small/${image}`}
        alt="reward"
        minW="60px"
        borderTopRadius="8px"
        borderBottomRadius={{ base: 0, sm: '8px' }}
      />
      <Flex
        flex="1"
        overflow="hidden"
        alignItems="flex-start"
        flexDirection="column"
        p={{ base: 4, sm: 0 }}
        w={{ base: 'full', sm: 'auto' }}
      >
        <Box maxW="100%" fontWeight="700" fontSize={{ base: 'xl', sm: 'xl' }}>
          <Text
            lineHeight="1em"
            fontSize={{ base: '4xl', sm: '2xl' }}
            fontWeight="bold"
            as="span"
          >
            {title}
          </Text>
        </Box>
        <Box>
          <Text as="span" fontWeight="bold" fontSize="lg">
            {t('perk@donate')}{' '}
            {convertPrice ? (
              <CurrencyConverter currency={currency.current} amount={price} />
            ) : (
              price
            )}
            &nbsp;
            {currency.current}
          </Text>
          {description && <Box fontSize="sm">{description}</Box>}
        </Box>
        {isSelectable && (
          <Button
            mt={2}
            ml="auto"
            minH="unset"
            height="unset"
            color="orange.400"
            variant="transparent"
            onClick={onSelect}
          >
            {t('select')}
          </Button>
        )}
      </Flex>
    </Flex>
  )
}

export default PerkCard
