import { Spinner, GridItem, SimpleGrid, Heading, Flex } from '@chakra-ui/react'
import BlogCard from 'components/BlogCard'
import { useTranslation } from 'contexts/TranslationContext'
import { useListBlog } from 'hooks'
import MobileBlog from './MobileBlog'
import { MainLayout } from 'components/Layouts'
import MetaTags from 'components/MetaTags'
import { useScreenSize } from 'contexts'

const Blog = () => {
  const { t, language } = useTranslation()
  const { data, isLoading } = useListBlog()
  const { isMobile } = useScreenSize()

  if (isMobile) {
    return (
      <>
        <MetaTags
          title={t('meta@blog@title')}
          description={t('meta@blog@description')}
        />
        <MobileBlog data={data} isLoading={isLoading} />
      </>
    )
  }

  return (
    <>
      <MetaTags
        title={t('meta@blog@title')}
        description={t('meta@blog@description')}
      />
      <MainLayout>
        {/* <Header isLoading={isLoading} data={data} /> */}
        {isLoading ? (
          <Flex minH="520px" w="full" align="center" justifyContent="center">
            <Spinner color="blue.300" />
          </Flex>
        ) : (
          <SimpleGrid
            px={10}
            minH="720px"
            position="relative"
            w="full"
            pt="3.375rem"
            pb="20"
            columns={{
              base: 1,
              md: 2,
              lg: 3,
            }}
            spacing="8"
          >
            {data && data[`items_${language}`].length > 0 ? (
              data[`items_${language}`].map((card) => (
                <GridItem key={`blog-card-${card.id}`} colSpan={1}>
                  <BlogCard card={card} />
                </GridItem>
              ))
            ) : (
              <Flex
                top="0"
                left="0"
                position="absolute"
                alignItems="center"
                justifyContent="center"
                h="full"
                w="full"
              >
                <Heading as="h2" fontSize="5xl" color="blue.400">
                  {t('noResults')}
                </Heading>
              </Flex>
            )}
          </SimpleGrid>
        )}
        {/* {filteredProjects?.length > 1 && (
        <Flex
          alignItems="center"
          justifyContent="center"
          mb="9.5rem"
          mt="3.125rem"
        >
          <Button height="56px" width="248px" variant="border" fontSize="lg">
            {t('See more')}
          </Button>
        </Flex>
      )} */}
      </MainLayout>
    </>
  )
}

export default Blog
