import { expertUseCase } from 'core/factories'

const { useMutation, useQuery } = require('react-query')

export const useConnectExpert = () =>
  useMutation(expertUseCase.createExpertInitConnection)
export const useUpdateExpertConnection = () =>
  useMutation(expertUseCase.updateConnection)

export const useGetExpertConnectionById = (id) =>
  useQuery([`connection-${id}`], () => expertUseCase.getConnection(id))

export default useConnectExpert
