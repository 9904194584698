import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { Box, Heading, Container, Flex, Button } from '@chakra-ui/react'
import { useCurrencySettings } from 'contexts/CurrencySettingsContext'
import { useTranslation } from 'contexts/TranslationContext'
import { projectProgress } from 'hooks'
import ActNow from './ActNow'
import AmountWithCurrency from 'components/AmountWithCurrency'
import { useGiftCard } from 'contexts/GiftCardContext'

const FixedNavbar = ({ project, checkPerk, buttonRef, infoSectionRef }) => {
  const [nav, setNav] = useState('none')
  const { currency, settings } = useCurrencySettings()
  const { t } = useTranslation()
  const progressData = projectProgress(project, currency?.current)
  const { giftCardMode } = useGiftCard()
  const onScroll = () => {
    if (!buttonRef.current) {
      return
    }
    const current = window.scrollY
    const { y } = buttonRef.current
    if (current > y + 10) {
      setNav('block')
    }
    if (current < y) {
      setNav('none')
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', onScroll)

    return () => document.removeEventListener('scroll', onScroll)
  }, [onScroll])
  if (!project.isProject) {
    return null
  }
  return (
    <motion.div
      initial={{
        top: '-100%',
        position: 'fixed',
        width: '500px',
        left: infoSectionRef?.current?.getBoundingClientRect().x,
        zIndex: '50',
      }}
      animate={{ top: nav === 'block' ? '0' : '-100%' }}
      transition={{ duration: 0.5, stiffness: 1000 }}
    >
      <Flex justifyContent="center">
        <Container
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          bg="white"
          height="64px"
          w="500px"
          boxShadow="0px 0px 8px rgba(0,0,0,0.2)"
          borderBottomRadius="8px"
          m="0"
        >
          <Flex
            w="100%"
            justifyContent={!!project.isProject ? 'space-between' : 'flex-end'}
          >
            {!!project.isProject && (
              <Flex mr="10" alignItems="center">
                <Flex alignItems="center">
                  <Heading
                    color="black"
                    as="h2"
                    fontSize="md"
                    display="flex"
                    alignItems="center"
                  >
                    <AmountWithCurrency
                      currency={currency.current}
                      currencyRate={settings?.currency[currency.current]}
                      amount={project.amount}
                    />
                  </Heading>
                </Flex>
                <Box fontSize="md" w="max-content">
                  &nbsp;{'|'}&nbsp;
                  {t('goal')}&nbsp;
                  <AmountWithCurrency
                    currency={currency.current}
                    currencyRate={settings?.currency[currency.current]}
                    amount={progressData.goal}
                  />
                </Box>
              </Flex>
            )}
            {!!project.isProject && (
              <>
                <Button
                  mr={project.isCollaboration ? '3' : '0'}
                  textTransform="capitalize"
                  display={{ base: 'none', sm: 'inline-flex' }}
                  onClick={checkPerk}
                  minH="40px"
                  h="40px"
                  width="110px"
                  borderRadius="8px"
                  variant="green"
                  fontWeight="semibold"
                >
                  {giftCardMode &&
                  (!project?.isOneTimeDisabled ||
                    project?.fundraisingType === 'oneTime')
                    ? t('redeem')
                    : t('project@donate')}
                </Button>
              </>
            )}
            {project.isCollaboration && !project.noCTA && (
              <ActNow
                textTransform="capitalize"
                fontWeight="semibold"
                height="40px"
                width="110px"
                project={project}
                initiative={project}
              />
            )}
          </Flex>
        </Container>
      </Flex>
    </motion.div>
  )
}

export default FixedNavbar
