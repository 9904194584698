import { createContext, useContext, useState, useEffect } from 'react'

const GiftCardContext = createContext({
  giftCardMode: false,
  giftCardAmount: 0,
  setGiftCardMode: () => {},
  giftCard: {},
})

export const useGiftCard = () => useContext(GiftCardContext)

export const GiftCardContextProvider = ({ children }) => {
  const [giftCardMode, setGiftCardMode] = useState(false)
  const [giftCard, setGiftCard] = useState({})

  useEffect(() => {
    if (!giftCard) {
      return
    }
    if (giftCard?.code)
      localStorage.setItem('giftCard', JSON.stringify(giftCard))
  }, [giftCard])

  useEffect(() => {
    if (giftCardMode) {
      localStorage.setItem('giftCardMode', true)
      return
    }
  }, [giftCardMode])

  useEffect(() => {
    const localGiftCardMode = localStorage.getItem('giftCardMode') === 'true'
    if (localGiftCardMode) {
      let localGiftCard = localStorage.getItem('giftCard')
      try {
        localGiftCard = JSON.parse(localGiftCard)
      } catch (err) {
        console.log(err)
      }

      setGiftCard(localGiftCard)
      setGiftCardMode(true)
    }
  }, [])

  return (
    <GiftCardContext.Provider
      value={{
        giftCardMode,
        setGiftCardMode,
        giftCard,
        setGiftCard,
      }}
    >
      {children}
    </GiftCardContext.Provider>
  )
}
