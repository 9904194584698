import AppSyncService from './AppSyncService'
import contentRepo from './contentRepo'
import contentUseCase from './contentUseCase'
import ElasticService from './ElasticService'

// prettier-ignore
const content = contentUseCase(
    contentRepo(AppSyncService(), ElasticService())
)

export default content
