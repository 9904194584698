import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useUser, useUpdateUser } from 'hooks'
import { convertTo, useCurrencySettings } from './CurrencySettingsContext'

export const BasketContext = createContext({
  basket: [],
  addToBasket: () => {},
  updateBasketItem: () => {},
  removeFromBasket: () => {},
  isInBasket: () => {},
  total: 0,
  totalAmountInAmd: 0,
  isCreateLoading: false,
  isUpdateLoading: false,
  isRemoveLoading: false,
})

export const useBasket = () => useContext(BasketContext)

export function BasketProvider({ children }) {
  const { user } = useUser()
  const [basket, setBasket] = useState(user?.charityBasket || [])
  const [isCreateLoading, setIsCreateLoading] = useState(false)
  const [isUpdateLoading, setIsUpdateLoading] = useState(false)
  const [isRemoveLoading, setIsRemoveLoading] = useState(false)
  const { updateUserAsync } = useUpdateUser()
  const { settings } = useCurrencySettings()

  const totalAmountInAmd = useMemo(() => {
    return basket.reduce(
      (acc, item) =>
        acc +
        convertTo({
          amount: Number(item.amount),
          from: item.currency,
          to: 'AMD',
          settings,
        }),
      0
    )
  }, [basket])

  const addToBasket = async (item) => {
    try {
      setIsCreateLoading(true)
      const updatedBasket = [...basket, item]
      setBasket(updatedBasket)

      const updateUserInput = {
        id: user.id,
        charityBasket: updatedBasket,
      }

      await updateUserAsync(updateUserInput)
    } catch (e) {
      console.log('>>> error adding to basket', e)
    } finally {
      setIsCreateLoading(false)
    }
  }

  const updateBasketItem = async (item) => {
    try {
      setIsUpdateLoading(true)
      const updatedBasket = [...basket]
      const indexofItem = updatedBasket.findIndex(
        (i) => i.projectId === item.projectId
      )
      updatedBasket[indexofItem] = item
      setBasket(updatedBasket)

      const updateUserInput = {
        id: user.id,
        charityBasket: updatedBasket,
      }

      await updateUserAsync(updateUserInput)
    } catch (e) {
      console.log('>>> error updating item in basket', e)
    } finally {
      setIsUpdateLoading(false)
    }
  }

  const removeFromBasket = async (projectId) => {
    try {
      setIsRemoveLoading(projectId)
      const updatedBasket = [...basket].filter((i) => i.projectId !== projectId)
      setBasket(updatedBasket)

      const updateUserInput = {
        id: user.id,
        charityBasket: updatedBasket,
      }

      await updateUserAsync(updateUserInput)
    } catch (e) {
      console.log('>>> error removing from basket', e)
    } finally {
      setIsRemoveLoading(false)
    }
  }

  const isInBasket = (projectId) => {
    return basket.find((item) => item.projectId === projectId)
  }

  useEffect(() => {
    setBasket(user?.charityBasket || [])
  }, [user])

  return (
    <BasketContext.Provider
      value={{
        basket,
        addToBasket,
        updateBasketItem,
        removeFromBasket,
        isInBasket,
        total: basket?.length,
        totalAmountInAmd,
        isCreateLoading,
        isUpdateLoading,
        isRemoveLoading,
      }}
    >
      {children}
    </BasketContext.Provider>
  )
}
