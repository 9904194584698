import React from 'react'
import { HStack, Flex, Heading } from '@chakra-ui/react'
import { ReactComponent as Back } from 'assets/icons/closeBlack.svg'
import { useTranslation } from 'contexts/TranslationContext'
import { useHistory } from 'react-router-dom'

const Header = () => {
  const { t } = useTranslation()
  const history = useHistory()
  return (
    <HStack
      w="full"
      borderBottom="1px solid"
      borderColor="border.100"
      alignItems="center"
      position="relative"
      justifyContent="center"
      py="12px"
    >
      <Flex
        position="absolute"
        left="12px"
        onClick={() => history.goBack()}
        width="30px"
        height="30px"
        justifyContent="center"
        alignItems="center"
      >
        <Back width="14px" height="14px" />
      </Flex>
      <Heading pl="8" textAlign="center" fontSize="3xl" as="h2">
        {t('project@donateProject')}
      </Heading>
    </HStack>
  )
}

export default Header
