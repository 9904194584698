import development from './devtest'
import production from './production'
import demo from './demo'

const config = {
  development,
  production,
  demo,
}

export default config[process.env.REACT_APP_ENV || 'development']
