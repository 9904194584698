import { FormControl, Text } from '@chakra-ui/react'
import { RadioGroup } from './RadioGroup'

const ReArmeniaTip = ({ price, options, onChange, currency, t }) => {
  return (
    <FormControl>
      <Text
        fontSize="xl"
        fontWeight="semibold"
        color="gray.800"
        bg="white"
        zIndex={2}
        mb={0}
      >
        {t('donation@donateToReArmenia')}
      </Text>
      <Text mb="6" color="gray.700" fontSize="md">
        {t('donation@donateToReArmeniaFee')}
      </Text>
      <RadioGroup
        name="amount"
        price={price}
        defaultValue="7.5"
        options={options}
        onChange={onChange}
        currency={currency}
      />
    </FormControl>
  )
}

export default ReArmeniaTip
