const updateRepo = (updateService) => {
  const getUpdatesByProjectId = (variables) =>
    updateService.getUpdatesByProjectId(variables)

  const editUpdate = (input) => updateService.editUpdate(input)

  const deleteUpdate = (input) => updateService.deleteUpdate(input)

  const createUpdate = (input) => updateService.createUpdate(input)

  const listUpdatesByDate = () => updateService.listUpdatesByDate()
  return {
    getUpdatesByProjectId,
    createUpdate,
    deleteUpdate,
    editUpdate,
    listUpdatesByDate,
  }
}

export default updateRepo
