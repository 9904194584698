import { useEffect } from 'react'

export default function useBeforeUnload(dependencies = [], handleBeforeUnload) {
  const unload = () =>
    window.removeEventListener('beforeunload', handleBeforeUnload)

  useEffect(() => {
    // Add event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload)

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [...dependencies, handleBeforeUnload]) // Dependency array to re-run the effect if the callback changes

  return unload
}
