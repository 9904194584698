import { deleteBindingCard as removeBindingCard } from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api'
const client = generateClient()

const bindingCardService = () => {
  const deleteBindingCard = async (id) => {
    await client.graphql({
      query: removeBindingCard,
      variables: { input: { id } },
    })
  }

  return {
    deleteBindingCard,
  }
}

export default bindingCardService
