import { Link as ReactLink } from 'react-router-dom'
import { HStack, Image, Checkbox, Link } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { BANK_CARD_TYPES } from '_constants'

const Wrapper = ({
  privacyPolicy,
  read,
  toggleRead,
  error,
  setError,
  showIcons,
  children,
}) => {
  const { t } = useTranslation()

  const onChange = (e) => {
    toggleRead(e.target.value)
    if (e.target.value) {
      setError(false)
    }
  }

  return (
    <>
      {showIcons && (
        <HStack mb={4} spacing={1} align="center" height="20px">
          {BANK_CARD_TYPES.map((bankCardType) => (
            <Image
              key={`bank-card-type-${bankCardType}`}
              src={`/assets/images/${bankCardType}.png`}
              alt={bankCardType}
              width="20px"
              height="14px"
            />
          ))}
        </HStack>
      )}
      {children}
      {privacyPolicy && (
        <Checkbox
          mt={8}
          mb={1}
          value={read}
          borderColor={error ? 'red.400' : 'border.200'}
          onChange={onChange}
        >
          {t('profile@settings-payments-have-read')}&nbsp;
          <Link as={ReactLink} target="_blank" to="/privacy-policy">
            {t('profile@settings-payments-privacy-policy')}
          </Link>
        </Checkbox>
      )}
    </>
  )
}

export default Wrapper
