import React, { Fragment, useEffect, useMemo } from 'react'
import { Route as ReactRoute, useLocation, useHistory } from 'react-router-dom'
import { useUser } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'

const Route = ({ path, component, secured, Layout, exact }) => {
  const history = useHistory()
  const location = useLocation()
  const { isLoading, user } = useUser()
  const { language } = useTranslation()

  const useUserAuthenticated = useMemo(() => user.email !== 'guest', [user])

  useEffect(() => {
    if (secured && !isLoading && !useUserAuthenticated) {
      history.replace(`/${language}/sign-in?from=${path.slice(6)}`)
    }
  }, [history, location, secured, path, isLoading, useUserAuthenticated])

  if (isLoading) {
    return <Fragment />
  }
  if (
    location.pathname === '/en' ||
    location.pathname === '/en/' ||
    location.pathname === '/ru' ||
    location.pathname === '/ru/' ||
    location.pathname === '/hy' ||
    location.pathname === '/hy/' ||
    location.pathname.includes('pre-create-project')
  ) {
    document
      .querySelector("meta[name='theme-color']")
      ?.setAttribute('content', '#002D78')
  } else {
    document
      .querySelector("meta[name='theme-color']")
      ?.setAttribute('content', '#ffffff')
  }
  return (
    <Layout>
      <ReactRoute exact={exact} path={path} component={component} />
    </Layout>
  )
}

export default Route
