import React from 'react'
import { Box, Flex, HStack, Heading, Text } from '@chakra-ui/react'
import { ReactComponent as Back } from 'assets/icons/back.svg'
import { ReactComponent as Checked } from 'assets/icons/checked_blue.svg'
import { useTranslation } from 'contexts/TranslationContext'
import { useQueryParams } from 'hooks'
import { useNavigation } from 'pages'
import {
  SUPPORTED_CURRENCIES,
  useCurrencySettings,
} from 'contexts/CurrencySettingsContext'

export default function CurrencySwitcherMobile() {
  const { t } = useTranslation()
  const queryParams = useQueryParams()
  const { navigationPush } = useNavigation()
  const { currency, changeDefaultCurrency } = useCurrencySettings()

  const from = queryParams.get('from')

  return (
    <>
      <HStack
        py="3"
        borderBottom="1px solid"
        borderColor="border.100"
        px="3"
        alignItems="center"
        justifyContent="space-between"
        display={{ base: 'flex', sm: 'none' }}
      >
        <Flex
          onClick={() =>
            navigationPush(from + '?drawer=open' || '/?drawer=open')
          }
          alignItems="center"
          justifyContent="center"
          width="30px"
        >
          <Back width="10px" height="20px" />
        </Flex>

        <Heading as="h1" fontSize="3xl">
          {t('currency')}
        </Heading>
        <Box align="center" width="30px"></Box>
      </HStack>
      <Flex direction="column" py={4} px={6}>
        {SUPPORTED_CURRENCIES.map((option) => (
          <LanguageField
            key={option}
            currency={currency.current}
            value={option}
            title={t(`currency@${option}`)}
            onClick={() => changeDefaultCurrency(option)}
          />
        ))}
      </Flex>
    </>
  )
}

const LanguageField = ({ currency, value, title, onClick }) => (
  <Flex justify="space-between" h={12} p={1} align="center" onClick={onClick}>
    <Text color={currency === value ? 'blue.400' : '#696A6F'} fontWeight={600}>
      {title}
    </Text>
    {currency === value && <Checked />}
  </Flex>
)
