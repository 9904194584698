import React, { useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  Stack,
} from '@chakra-ui/react'
import { InputField } from 'components/InputField'
import { useForm } from 'react-hook-form'
import useUpdateTransaction from 'hooks/transaction/useUpdateTransaction'
import { useCustomToast, useLocalStorage, useObjectManipulations } from 'hooks'

export default function EditTransactionModal({
  isOpen,
  onClose,
  transaction,
  onSuccessfulSubmit,
}) {
  const { put, get, remove } = useLocalStorage()
  const { register, formState: errors, reset, handleSubmit } = useForm({})
  const { deleteKey } = useObjectManipulations()
  const { mutate: updateTransaction, isLoading } = useUpdateTransaction()

  const toast = useCustomToast()
  const onSubmit = (data) => {
    data.isEdit = true
    deleteKey(data, '__typename')

    updateTransaction(
      { ...data, id: transaction.id },
      {
        onSuccess: () => {
          put('transactionEditSuccess', true)
          onSuccessfulSubmit()
        },
        onError: (error) => {
          toast({
            title: 'Something went wrong',
            description: error.message,
            status: 'error',
            isClosable: true,
          })
          console.log(error)
        },
      }
    )
  }

  useEffect(() => {
    if (transaction) {
      reset({
        id: transaction.id,
        firstName: transaction.firstName,
        lastName: transaction.lastName,
        email: transaction.email,
        donationToProject: transaction.donationToProject,
        donationToReArmenia: transaction.donationToReArmenia,
        currency: transaction.currency,
        currencyRate: transaction.currencyRate,
      })
    }
  }, [transaction, reset])

  useEffect(() => {
    if (get('transactionEditSuccess') === 'true') {
      toast({
        title: 'Transaction updated successfully',
        status: 'success',
        isClosable: true,
      })
      remove('transactionEditSuccess')
    }
  }, [])
  return (
    <Modal size="xl" isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        w="full"
        position="relative"
        pb="4"
        maxW="532px"
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <ModalHeader>Edit Transaction</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <InputField
              size="md"
              label={'First Name'}
              isInvalid={!!errors.firstName}
              {...register('firstName', { required: true })}
            />
            <InputField
              size="md"
              label={'Last Name'}
              isInvalid={!!errors.lastName}
              {...register('lastName', { required: true })}
            />
            <InputField
              size="md"
              label={'Email'}
              isInvalid={!!errors.email}
              {...register('email', { required: true })}
            />
            <InputField
              size="md"
              label={'Donation To Project (AMD)'}
              isInvalid={!!errors.donationToProject}
              type="number"
              {...register('donationToProject', { required: true })}
            />
            <InputField
              size="md"
              label={'Donation To ReArmenia (AMD)'}
              isInvalid={!!errors.donationToReArmenia}
              {...register('donationToReArmenia', { required: true })}
            />
            <InputField
              size="md"
              label={'Currency'}
              isInvalid={!!errors.currency}
              {...register('currency', { required: true })}
            />
            <InputField
              size="md"
              label={'Currency Rate'}
              isInvalid={!!errors.currencyRate}
              {...register('currencyRate', { required: true })}
            />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="blue"
            onClick={onClose}
            mr={2}
            isDisabled={isLoading}
          >
            Close
          </Button>
          <Button
            variant="green"
            type="submit"
            isLoading={isLoading}
            isDisabled={isLoading}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
