import React, { useEffect } from 'react'
import amplitude from 'amplitude-js'

export default function AmplitudeProvider({ children }) {
  useEffect(() => {
    //  TODO: ADD API KEY
    amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY)
  }, [])
  return <>{children}</>
}
