import React, { useEffect, useState } from 'react'
import { Box, Flex, Heading } from '@chakra-ui/react'
import LazyLoad from 'react-lazyload'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as Play } from 'assets/icons/play.svg'

const LINKS = {
  en: 'https://www.youtube.com/embed/eX-DWi564xI',
  hy: 'https://www.youtube.com/embed/MjYHrgl4o4Y',
  ru: 'https://www.youtube.com/embed/wrI-hkkjH1I',
}

const Explaining = () => {
  const { t, language } = useTranslation()
  const [play, setPlay] = React.useState(false)
  const [current, setCurrent] = useState()

  useEffect(() => {
    setCurrent(LINKS[language])
  }, [language])

  return (
    <>
      <Flex
        align="center"
        justifyContent="center"
        margin="auto"
        width="100%"
        overflow="hidden"
        height="200px"
        position="relative"
      >
        <Flex
          height="100%"
          width="100%"
          zIndex="5"
          allow="autoplay"
          as="iframe"
          title="Armenia"
          allowFullScreen
          src={
            play ? `${current}?autoplay=1&controls=1&enablejsapi=1&rel=0` : ''
          }
        />
        <LazyLoad height={520} offset={100} once>
          <Flex
            onClick={() => setPlay(true)}
            display={play ? 'none' : 'flex'}
            align="center"
            justifyContent="center"
            zIndex={'10'}
            top="0"
            cursor="pointer"
            left="0"
            width="100%"
            height="100%"
            position="absolute"
            bgImage="url(/assets/images/explanation.jpeg)"
            bgSize="cover"
            bgPos="center"
            bgRepeat="no-repeat"
          >
            <Box
              backgroundColor="rgba(255, 255, 255, .15)"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="12px"
              width="50px"
              height="50px"
            >
              <Play width="18.75px" />
            </Box>
          </Flex>
        </LazyLoad>
      </Flex>
      <Heading fontSize="3xl" mt="4" mb="5.375rem" px="5" as="h2">
        {t('How to collaborate on reArmenia')}
      </Heading>
    </>
  )
}

export default Explaining
