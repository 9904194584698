import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Flex, Spinner, SimpleGrid } from '@chakra-ui/react'

const Loader = () => (
  <Flex
    w="full"
    margin="auto"
    align="center"
    justifyContent="center"
    position="absolute"
    bottom="50px"
  >
    <Spinner color="blue.400" />
  </Flex>
)

export default function ProjectList({ children, fetchMore, length, hasMore }) {
  return (
    <SimpleGrid
      w="full"
      pt="12"
      mb={20}
      columns={{
        base: 1,
        md: 2,
        lg: 3,
        xl: 4,
      }}
      spacing="30px"
      as={InfiniteScroll}
      dataLength={length}
      next={fetchMore}
      hasMore={hasMore}
      loader={<Loader />}
      hasChildren={hasMore}
      overflow="hidden"
    >
      {children}
    </SimpleGrid>
  )
}
