import { useEffect, useState } from 'react'
import { Tabs as ChakraTabs, TabList } from '@chakra-ui/react'
import { useHistory, useLocation } from 'react-router-dom'
import { useQueryParams } from 'hooks'
import Tab from './Tab'

const getDefaultTab = (tab, tabs) => {
  if (!tab) {
    return 0
  }

  const indexOfActiveTab = tabs.indexOf(tab)
  if (indexOfActiveTab > -1) {
    return tabs[indexOfActiveTab]
  }

  return 0
}

export default function Tabs({ tabs, onTabChange }) {
  const queryParams = useQueryParams()
  const [tabIndex, setTabIndex] = useState(
    getDefaultTab(queryParams.get('tab'), tabs)
  )
  const history = useHistory()
  const { pathname } = useLocation()

  const onClick = (title) => {
    if (queryParams.get('tab') !== title) {
      history.replace(`${pathname}?tab=${title}`)
      setTabIndex(tabs.indexOf(title))
    }
  }

  useEffect(() => {
    if (queryParams.has('tab')) {
      setTabIndex(tabs.indexOf(queryParams.get('tab')))
    }
  }, [])

  useEffect(() => {
    if (tabs?.[tabIndex]) {
      onTabChange(tabs[tabIndex])
    }
  }, [tabIndex])

  return (
    <ChakraTabs height="100%" index={tabIndex} onChange={setTabIndex}>
      <TabList borderColor="gray.600">
        {tabs.map((tabTitle, index) => (
          <Tab
            key={`tab-${tabTitle}-${index}`}
            title={tabTitle}
            onClick={onClick}
          />
        ))}
      </TabList>
    </ChakraTabs>
  )
}
