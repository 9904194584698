import React, { useState } from 'react'
import {
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/react'
import { ReactComponent as ArrowDown } from 'assets/icons/angleDown.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import COUNTRY_LIST from 'translations/countries.json'
import { useTranslation } from 'contexts/TranslationContext'
import { useScreenSize } from 'contexts'

const CountrySelect = ({
  setValue = () => {},
  inputPlaceholder,
  countryIndex = 0,
  setCountryIndex = () => {},
  countries,
}) => {
  const { isMobile } = useScreenSize()
  const { language } = useTranslation()
  const [search, setSearch] = useState()

  const menuItems = [
    {
      id: 0,
      code: '',
      name_en: 'Select location',
      name_hy: 'Ընտրել տարածք',
      name_ru: 'Выбрать местоположение',
      image: '',
      dialCode: '',
    },
    ...COUNTRY_LIST.filter(({ code }) => countries?.includes(code)),
  ]
  return (
    <Menu
      closeOnBlur={!isMobile}
      placement={!isMobile ? 'bottom' : 'top'}
      isLazy
    >
      {({ onClose }) => (
        <>
          <MenuButton
            variant="border"
            _hover={{}}
            as={Button}
            display="flex"
            textTransform="capitalize"
            fontWeight="normal"
            w="full"
            background="white"
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
              overflow="hidden"
            >
              <Flex>
                {menuItems[countryIndex].image && (
                  <Image
                    src={`/assets/flags/${menuItems[countryIndex].image}`}
                    mr={2}
                    w="16px"
                  />
                )}
                <Text> {menuItems[countryIndex][`name_${language}`]}</Text>
              </Flex>
              <ArrowDown width="8px" height="6px" />
            </Flex>
          </MenuButton>
          <MenuList w="full">
            <Box p={2} borderTopRadius={16}>
              <InputGroup>
                {search?.length && (
                  <InputRightElement
                    children={
                      <IconButton
                        onClick={() => {
                          setSearch('')
                        }}
                      >
                        <CloseIcon width="10px" fill="gray.700" />
                      </IconButton>
                    }
                  />
                )}
                <Input
                  background={search?.length ? '#C1D5EC' : '#6495CE'}
                  border={search?.length ? '1px solid #6495CE' : 'none'}
                  placeholder={inputPlaceholder}
                  autoFocus={!isMobile}
                  color="black"
                  onChange={(e) => {
                    setSearch(e.target.value)
                  }}
                  value={search}
                  _placeholder={{
                    color: 'blue.400',
                    fontSize: 'md',
                  }}
                  mx="auto"
                />
              </InputGroup>
            </Box>
            <Stack
              w="275px"
              maxH={{ base: '50vh', sm: '330px' }}
              borderBottomRadius={16}
              overflowY="auto"
              sx={{
                '&::-webkit-scrollbar': {
                  width: '8px',
                  backgroundColor: `blue.400`,
                  marginRight: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: `blue.200`,
                  borderRadius: 20,
                },
              }}
            >
              <>
                {menuItems
                  ?.filter((f) =>
                    search
                      ? f?.[`name_${language}`]
                          ?.toLowerCase()
                          ?.includes(search.toLowerCase())
                      : true
                  )
                  ?.map(({ id, image, ...rest }, index) => (
                    <Flex
                      pl={2}
                      cursor="pointer"
                      key={id}
                      alignItems="center"
                      _hover={{ sm: { bg: 'blue.300' }, base: {} }}
                      bg={index === countryIndex ? 'blue.300' : 'blue.400'}
                      minH={8}
                      onClick={() => {
                        setValue(rest.code)
                        setCountryIndex(index)
                        onClose()
                        return
                      }}
                    >
                      {image && (
                        <Image src={`/assets/flags/${image}`} mr={2} w="32px" />
                      )}
                      {rest[`name_${language}`]}
                    </Flex>
                  ))}
              </>
            </Stack>
          </MenuList>
        </>
      )}
    </Menu>
  )
}

export default CountrySelect
