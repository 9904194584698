import React from 'react'
import { Heading, Box, Text } from '@chakra-ui/react'
import CreditCards from 'components/CreditCards'
import { useTranslation } from 'contexts/TranslationContext'

export default function AttachedCards({
  watch,
  setPaymentMethod,
  getValues,
  setValue,
  onChange,
  donationPage,
  clientSecret,
  isRecurring,
  isContribution,
  showStripe,
  register,
  errors,
  isGiftCardDisabled,
  showGiftCard,
  clearErrors,
  trigger,
  setTokenId,
  setNewStripeCard,
  isBorderVisible = true,
}) {
  const { t } = useTranslation()

  return (
    <Box
      borderTopWidth={isBorderVisible ? 1 : 0}
      borderBottomWidth={isBorderVisible ? 1 : 0}
      borderColor="blue.400"
      mt={8}
      py={8}
    >
      {showStripe && (
        <>
          <Heading as="h2" fontSize="2xl" mb={1.5} fontWeight={500}>
            {t('donation@selectMethod')}
          </Heading>
          <Text mb={4} fontSize={{ base: 'md', sm: 'lg' }}>
            {t('donation@forUSCitizens')}
          </Text>
        </>
      )}

      <CreditCards
        removable={false}
        page="donation"
        paymentMethod={watch('paymentMethod')}
        amount={Number(watch('amount')) + Number(watch('reArmenia'))}
        onClick={onChange}
        setPaymentMethod={setPaymentMethod}
        clearErrors={clearErrors}
        trigger={trigger}
        getValues={getValues}
        setValue={setValue}
        register={register}
        errors={errors}
        isRecurring={isRecurring}
        isContribution={isContribution}
        showStripe={showStripe}
        showGiftCard={showGiftCard}
        isGiftCardDisabled={isGiftCardDisabled}
        donationPage={donationPage}
        clientSecret={clientSecret}
        setTokenId={setTokenId}
        setNewStripeCard={setNewStripeCard}
      />
    </Box>
  )
}
