import { useQuery } from 'react-query'
import { needsUseCase } from '../../core/factories'

const useGetNeeds = (variables) => {
  const { error, refetch, isLoading, data } = useQuery(
    ['needs', variables],
    () => needsUseCase.listNeedsCategory(variables)
  )

  return {
    error,
    refetch,
    isLoading,
    data,
  }
}

export default useGetNeeds
