const contentRepo = (AppSyncService, ElasticService) => {
  const getNeeds = (input) => AppSyncService.getNeeds(input)
  const getExperts = (variables) => AppSyncService.getExperts(variables)
  const getItemById = (input) => AppSyncService.getItemById(input)
  const getItemBySlug = (input) => AppSyncService.getItemBySlug(input)
  const getGiftCard = (input) => AppSyncService.getGiftCard(input)
  const getHomepageBlog = (input) => AppSyncService.getHomepageBlog(input)
  const getExpertsCategories = (input) => AppSyncService.getItemById(input)
  const createContentItem = (input) => AppSyncService.createContentItem(input)
  const updateContentItem = (input) => AppSyncService.updateContentItem(input)

  const getContentFromElastic = (condition) =>
    ElasticService.getContentFromElastic(condition)

  const deleteContentItem = (condition) =>
    AppSyncService.deleteContentItem(condition)
  const getExpertByEmail = (condition) =>
    AppSyncService.getExpertByEmail(condition)

  return {
    getNeeds,
    updateContentItem,
    getExperts,
    getItemById,
    getGiftCard,
    getHomepageBlog,
    getContentFromElastic,
    getExpertsCategories,
    createContentItem,
    deleteContentItem,
    getExpertByEmail,
    getItemBySlug,
  }
}

export default contentRepo
