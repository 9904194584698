import { useQuery } from 'react-query'
import { transactionUseCase } from '../../core/factories'

const useGetUserInvestments = (variables, cacheKey) => {
  return useQuery(
    [cacheKey],
    () => transactionUseCase.getUserInvestments(variables),
    {
      refetchOnWindowFocus: false,
    }
  )
}

export default useGetUserInvestments
