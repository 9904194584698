import { useEffect, useState } from 'react'
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  VKShareButton,
} from 'react-share'
import { HStack, Text, Flex, Button } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as FB } from 'assets/icons/transparentFacebook.svg'
import { ReactComponent as Linkedin } from 'assets/icons/transparentLinkedin.svg'
import { ReactComponent as Twitter } from 'assets/icons/transparentTwitter.svg'
import { ReactComponent as VK } from 'assets/icons/transparentVk.svg'
import { ReactComponent as Copy } from 'assets/icons/copy.svg'
import useCustomToast from 'hooks/useCustomToast'

const Share = ({ isMobile }) => {
  const [sharingLink, setShareLink] = useState('')
  const { t } = useTranslation()

  const toast = useCustomToast()

  async function share() {
    if ('clipboard' in navigator) {
      toast({
        title: t('copied'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      return await navigator.clipboard.writeText(window.location.href)
    } else {
      toast({
        title: t('copied'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      return document.execCommand('copy', true, window.location.href)
    }
  }

  useEffect(() => {
    setShareLink(
      process.env.REACT_APP_FACEBOOK_SHARE_LINK + window.location.href
    )
  }, [])

  return (
    <Flex
      mt="8"
      mb="9"
      alignItems={{ base: 'flex-start', sm: 'center' }}
      justifyContent="flex-start"
      direction={{ base: 'column', sm: 'row' }}
    >
      <Text fontSize="xl" fontWeight="bold" pr="7" mb={{ base: 5, sm: 0 }}>
        {t('Share')}
        {!isMobile && ':'}
      </Text>
      <HStack spacing="1.125rem">
        <Button w="20px" onClick={share}>
          <Copy />
        </Button>
        <FacebookShareButton url={sharingLink} description={'reArmenia'}>
          <FB />
        </FacebookShareButton>
        <TwitterShareButton url={sharingLink} description={'reArmenia'}>
          <Twitter />
        </TwitterShareButton>
        <LinkedinShareButton url={sharingLink} description={'reArmenia'}>
          <Linkedin />
        </LinkedinShareButton>
        <VKShareButton url={sharingLink} description={'reArmenia'}>
          <VK />
        </VKShareButton>
      </HStack>
    </Flex>
  )
}

export default Share
