import React, { useMemo, useState, useCallback } from 'react'
import { ImCamera } from 'react-icons/im'
import {
  Box,
  Spinner,
  Text,
  Stack,
  Image,
  Circle,
  Flex,
} from '@chakra-ui/react'
import FileUpload from 'components/FileUpload'
import { ReactComponent as Camera } from 'assets/icons/camera.svg'

export default function ProfilePictureUpload({
  image: defaultImage = 'assets/images/avatar.png',
  name,
  setValue,
  isInvalid,
  clearErrors,
  isDisabled,
  ...rest
}) {
  const [isLoading, toggleLoading] = useState()
  const [image, setImage] = useState()
  const onUpload = useCallback((key) => {
    if (key) {
      clearErrors('imageUrl')
    }
    setValue(name, key)
    setImage(key)
  }, [])

  const imageUrl = useMemo(() => {
    let path = image ? image : defaultImage
    if (!path?.includes('http')) {
      path = `${process.env.REACT_APP_S3_BUCKET_URL}/public/` + path
    }
    if (path.includes('assets/images/avatar.png')) {
      path = '/assets/images/avatar.png'
    }
    return path
  }, [image, defaultImage])

  return (
    <Flex justify="center" cursor="pointer" {...rest}>
      <FileUpload
        accept={'image/*'}
        multiple={false}
        noPreview={true}
        onUpload={onUpload}
        toggleLoading={toggleLoading}
        isDisabled={isDisabled}
      >
        {isDisabled && (
          <Box
            position="absolute"
            top={0}
            bottom={0}
            right={0}
            left={0}
            zIndex={500}
            background={'#0000009e'}
            w="152px"
            h="152px"
            borderRadius="50%"
          />
        )}
        <Circle
          size="152px"
          bg="gray.500"
          overflow="hidden"
          pos="relative"
          boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 6px 13px 0px rgba(0, 0, 0, 0.1), 0px 24px 24px 0px rgba(0, 0, 0, 0.09), 0px 55px 33px 0px rgba(0, 0, 0, 0.05), 0px 98px 39px 0px rgba(0, 0, 0, 0.01), 0px 153px 43px 0px rgba(0, 0, 0, 0)"
          _after={{
            content: "''",
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            border: `4px solid`,
            borderColor: isInvalid ? 'red.400' : 'white',
            borderRadius: '50%',
            boxSizing: 'border-box',
          }}
        >
          {isLoading ? (
            <Spinner color="white" size="md" />
          ) : (
            <Image
              src={imageUrl}
              alt="Creator image"
              position="absolute"
              top={0}
              left={0}
              w="100%"
              h="100%"
              objectFit="cover"
              transition="opacity 300ms"
              opacity={isLoading ? 0.3 : 1}
            />
          )}
        </Circle>
        <Circle
          position="absolute"
          size="32px"
          bg="white"
          bottom="4px"
          right="4px"
        >
          <Camera />
        </Circle>
      </FileUpload>
    </Flex>
  )
}

export const Layer = ({ label1, label2, ...rest }) => {
  return (
    <Stack
      justifyContent="center"
      display="flex"
      color="white"
      textAlign="center"
      {...rest}
    >
      <Box marginX="auto" my={0}>
        <ImCamera size="24px" color="white" />
      </Box>
      <Text fontSize="14px">{label1}</Text>
      <Text fontSize="12px">{label2}</Text>
    </Stack>
  )
}
