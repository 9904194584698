import {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react'
import { useLocalStorage, useGetSettings, useGetUserGeo, useUser } from 'hooks'
import { LANGUAGES, useTranslation } from './TranslationContext'
import COUNTRY_LIST from 'translations/countries.json'

export const thousandSeparator = (
  number,
  currency = 'AMD',
  toFixed = true,
  rate = 1
) => {
  if (!number) return 0
  return Number(number)
    .toFixed(currency === 'AMD' || !toFixed || !(number % rate) ? 0 : 2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const LOCAL_STORAGE_DEFAULT_CURRENCY_KEY = 'default_currency'
const LOCAL_STORAGE_SETTINGS_KEY = 'settings_cache'

export const SUPPORTED_CURRENCIES = ['AMD', 'RUB', 'USD', 'EUR']
export const SUPPORTED_CURRENCY_SYMBOLS = {
  AMD: '֏',
  RUB: '₽',
  USD: '$',
  EUR: '€',
}

export const CurrencySettingsContext = createContext({
  translatedCurrency: '',
  currency: { current: '', default: '' },
  settings: {},
  changeDefaultCurrency: () => {},
  convertCurrency: () => {},
})

export const useCurrencySettings = () => useContext(CurrencySettingsContext)

export const CurrencyConverter = ({
  amount,
  currency,
  normalize = true,
  withSymbol,
}) => {
  const { settings } = useCurrencySettings()

  let price = Number(amount)
  if (currency === 'RUB') {
    price = amount / (settings?.currency ? settings?.currency['RUB'] : 0)
  }
  if (currency === 'USD') {
    price = amount / (settings?.currency ? settings?.currency['USD'] : 0)
  }
  if (currency === 'EUR') {
    price = amount / (settings?.currency ? settings?.currency['EUR'] : 0)
  }
  let result = normalize ? thousandSeparator(price, currency) : price

  if (withSymbol) {
    result += ` ${SUPPORTED_CURRENCY_SYMBOLS[currency || 'AMD']}`
  }
  return result
}
export const convertTo = ({ amount, from, to, settings }) => {
  // const { settings } = useCurrencySettings() //TODO errors even with ConvertTo uppercase :/

  let price = Number(amount)
  if (from === to) {
    return amount
  }
  if (from === 'AMD') {
    return price / (settings?.currency?.[to] || 1)
  } else {
    const amdPrice = price * (settings?.currency?.[from] || 1)
    return amdPrice / (settings?.currency?.[to] || 1)
  }
}

export const convert = ({ amount, currencyRate = 1 }) => {
  return amount / currencyRate
}

const useGetUserCachedSettings = () => {
  const { get, put } = useLocalStorage()
  const getCachedSettings = () => {
    try {
      const settings_cache = safeParser(get(LOCAL_STORAGE_SETTINGS_KEY))
      return settings_cache
    } catch (err) {
      console.log(err)
      return null
    }
  }

  return {
    cachedSettings: getCachedSettings(),
    putSettingsOnCache: (settings) => {
      if (settings) {
        put(LOCAL_STORAGE_SETTINGS_KEY, JSON.stringify(settings))
      }
    },
  }
}

export const SettingsProvider = ({ children }) => {
  const { t, language } = useTranslation()
  const { put, get } = useLocalStorage()
  const { user } = useUser()
  const { cachedSettings } = useGetUserCachedSettings()
  const [settings, setSettings] = useState(cachedSettings)

  const [currency, setCurrency] = useState({
    current:
      user?.currency ||
      get(LOCAL_STORAGE_DEFAULT_CURRENCY_KEY) ||
      LANGUAGES[language].currency,
    default: get(LOCAL_STORAGE_DEFAULT_CURRENCY_KEY),
  })
  const changeDefaultCurrency = useCallback(
    (value) => {
      setCurrency({ default: value, current: value })
      put(LOCAL_STORAGE_DEFAULT_CURRENCY_KEY, value)
    },
    [setCurrency]
  )
  const { data: settingsResponse, isLoading } = useGetSettings()
  const { isLoading: geoLoading, data: userGeo } = useGetUserGeo()
  const translatedCurrency = useMemo(() => {
    return t(`currency@${currency?.current?.toLowerCase()}`)
  }, [language, currency])
  useEffect(() => {
    if (user?.currency) {
      changeDefaultCurrency(user?.currency)
    }
  }, [user])

  const compareLocalVersionWithDbVersion = (dbSettings) => {
    const localVersion = get('VERSION')
    const dbVersion = dbSettings.version
    if (!dbVersion || localVersion !== dbVersion) {
      console.log('CLEARING LOCAL STORAGE...')
      localStorage.clear()
      console.log('LOCAL STORAGE CLEARED')
      if (dbVersion) {
        put('VERSION', dbVersion)
      }
      window.location.reload(true)
    }
  }

  useEffect(() => {
    if (geoLoading) {
      return
    }
    if (!get(LOCAL_STORAGE_DEFAULT_CURRENCY_KEY) && !currency.default) {
      setCurrency({
        current:
          COUNTRY_LIST.find(
            (country) =>
              country?.code?.toLowerCase() ===
              userGeo?.country_code?.toLowerCase()
          )?.currency || 'USD',
        default: null,
      })
    }
  }, [geoLoading])

  useEffect(() => {
    if (!settingsResponse) {
      return
    }
    compareLocalVersionWithDbVersion(settingsResponse)
    put(LOCAL_STORAGE_SETTINGS_KEY, JSON.stringify(settingsResponse))
    setSettings(settingsResponse)
  }, [settingsResponse])

  useEffect(() => {
    setCurrency({
      current:
        get(LOCAL_STORAGE_DEFAULT_CURRENCY_KEY) || LANGUAGES[language].currency,
      default: get(LOCAL_STORAGE_DEFAULT_CURRENCY_KEY),
    })
  }, [language])

  if (isLoading) {
    return null
  }
  return (
    <CurrencySettingsContext.Provider
      value={{
        isLoading,
        settings,
        currency,
        changeDefaultCurrency,
        translatedCurrency,
      }}
    >
      {children}
    </CurrencySettingsContext.Provider>
  )
}

const safeParser = (obj) => {
  let result = obj
  try {
    if (obj.length > 2) {
      result = JSON.parse(obj)
    }
  } catch (error) {
    console.log('---', error)
    result = obj
  }
  return result
}
