import { useMutation } from 'react-query'
import { reportUseCase } from '../../core/factories'

const useCreateReport = () => {
  const { mutate, data, isLoading } = useMutation(reportUseCase.createReport)

  return {
    mutate,
    isLoading,
    data,
  }
}

export default useCreateReport
