import { useMutation } from 'react-query'
import { authUseCase } from '../../core/factories'

const useForgetPassword = () => {
  const {
    mutate: forgetPassword,
    isLoading,
    data,
    error,
  } = useMutation(authUseCase.forgetPassword)

  return {
    forgetPassword,
    isLoading,
    data,
    error,
  }
}

export default useForgetPassword
