import React, { useState } from 'react'
import {
  Box,
  Flex,
  HStack,
  Button,
  Text,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  Popover,
} from '@chakra-ui/react'
import { Link, useNavigation } from 'pages'
import HeaderSearch from '../../../HeaderSearch'
import ProfileDropdown from '../ProfileDropdown'
import { ABOUT_DROPDOWN, NAV_LINKS } from '../../constants'
import { useTranslation, LANGUAGES } from 'contexts/TranslationContext'
import { BlueSelect } from 'components/Select/BlueSelect'
import { useModal } from 'providers/ModalProvider'
import { NotificationList } from 'components/Notification/NotificationList'
import { useUser } from 'hooks'
import NotificationsButton from 'components/NotificationsIcon'
import BasketIconButton from 'components/BasketIconButton'

const Web = ({ isGuest, scrollStatus, ...props }) => {
  const { t, language, setLanguage } = useTranslation()
  const [search, setSearch] = useState(false)
  const { user } = useUser()
  const { openModal } = useModal()
  const selectOption = (option) => setLanguage(option)
  const { navigationPush } = useNavigation()
  const aboutOptionSelect = (option) => {
    navigationPush(`/${option}`)
  }

  return (
    <Flex
      flex="1"
      justifyContent={{ md: 'flex-end', lg: 'space-between' }}
      {...props}
    >
      <Flex fontSize="md" display={{ base: 'none', lg: 'flex' }}>
        {NAV_LINKS.map((link, index) => (
          <React.Fragment key={link.title}>
            {link.active === 'about' ? (
              <BlueSelect
                key={`${link.active}_${index}`}
                width="unset"
                display="flex"
                type="language"
                variant="noBorder"
                white={scrollStatus ? false : true}
                label={t(`about@aboutUs`)}
                options={ABOUT_DROPDOWN.map(({ value, title }) => ({
                  value,
                  title: t(title),
                }))}
                selectOption={aboutOptionSelect}
                offset={[0, 7]}
              />
            ) : link.active === 'experts' ? (
              <Flex alignItems="center" mr={4} key={`${link.active}_${index}`}>
                <Box
                  key={link.title}
                  fontWeight="bold"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderColor="transparent"
                  transition="all 0.2s"
                  mr={2}
                  color={scrollStatus ? 'gray.800' : 'white'}
                >
                  <Link to={link.to}>{t(link.title)}</Link>
                </Box>

                <Flex
                  w="26px"
                  h="12px"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="16px"
                  bg="orange.400"
                >
                  <Text
                    fontSize="8px"
                    fontWeight="medium"
                    color={scrollStatus ? 'gray.800' : 'white'}
                    textTransform="uppercase"
                  >
                    {t('expertsPopup@tag')}
                  </Text>
                </Flex>
              </Flex>
            ) : (
              <Box
                key={link.title}
                fontWeight="bold"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderColor="transparent"
                transition="all 0.2s"
                color={scrollStatus ? 'gray.800' : 'white'}
                mr={4}
              >
                <Link to={link.to}>{t(link.title)}</Link>
              </Box>
            )}
          </React.Fragment>
        ))}
      </Flex>

      <HStack spacing="5" color="white" fontSize="md">
        <HeaderSearch
          search={search}
          setSearch={setSearch}
          xl={true}
          white={scrollStatus ? false : true}
        />

        {!isGuest && (
          <>
            <NotificationsPopover
              unreadNotifications={user?.unreadNotifications}
              scrollStatus={scrollStatus}
              t={t}
            />
            <BasketIconButton variant={scrollStatus ? 'black' : 'white'} />
          </>
        )}

        {!search && (
          <>
            <BlueSelect
              width="unset"
              display="flex"
              type="language"
              variant="noBorder"
              white={scrollStatus ? false : true}
              label={t(`language@${language}LabelShort`)}
              currentSelectedOption={t(`language@${language}Label`)}
              options={Object.keys(LANGUAGES).map((key) => ({
                title: t(`language@${key}Label`),
                value: key,
              }))}
              selectOption={selectOption}
              offset={[0, 7]}
            />
            {isGuest && (
              <>
                <Button
                  color={scrollStatus ? 'gray.800' : 'white'}
                  textTransform="initial"
                  fontWeight="600"
                  _hover={{ color: 'white' }}
                  onClick={() => {
                    openModal('signIn')
                  }}
                >
                  {t('nav@signIn')}
                </Button>

                <Button
                  color={scrollStatus ? 'gray.800' : 'white'}
                  textTransform="initial"
                  fontWeight="600"
                  _hover={{ color: 'white' }}
                  onClick={() => {
                    openModal('signUp')
                  }}
                >
                  {t('followProcess@buttonText')}
                </Button>
              </>
            )}
            {!isGuest && <ProfileDropdown black={scrollStatus && true} />}
          </>
        )}
      </HStack>
    </Flex>
  )
}

export default Web

const NotificationsPopover = ({
  scrollStatus,
  unreadNotifications = [],
  t,
}) => {
  return (
    <Popover>
      <PopoverTrigger>
        <NotificationsButton
          unreadNotifications={unreadNotifications}
          whiteVersion={!scrollStatus}
        />
      </PopoverTrigger>

      <PopoverContent w="483px" borderRadius={24} overflow="hidden">
        <PopoverHeader
          p="26px"
          fontSize="4xl"
          color="gray.800"
          fontWeight={700}
        >
          {t('profile@notifications')}
        </PopoverHeader>

        <PopoverBody p={0} maxH="calc(100vh - 180px)" overflowY="scroll">
          <NotificationList />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
