import React from 'react'
import {
  Box,
  Button,
  Stack,
  Checkbox,
  Flex,
  IconButton,
  Text,
} from '@chakra-ui/react'
import amplitude from 'amplitude-js'
import { Link } from 'pages'
import { InputField } from 'components/InputField'
import { PasswordField } from 'components/PasswordField'
import { ReactComponent as Facebook } from 'assets/icons/authFacebook.svg'
import { ReactComponent as Google } from 'assets/icons/authGoogle.svg'
import { useTranslation } from 'contexts/TranslationContext'
import { authUseCase } from 'core/factories'

export default function Forms({
  loading,
  handleSubmit,
  onSubmit,
  errors,
  register,
  user,
}) {
  const { t } = useTranslation()

  const onGoogleSignUp = () => {
    amplitude.getInstance().logEvent('signupgmail_desktop')
    console.log('sent event to amplitude(signupgmail_desktop)')

    authUseCase.googleSignIn()
  }
  const onFacebookSignUp = () => {
    amplitude.getInstance().logEvent('signupfb_desktop')
    console.log('sent event to amplitude(signupfb_desktop)')

    authUseCase.fbSignIn()
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Flex mb={4}>
        <IconButton
          onClick={onFacebookSignUp}
          p="0"
          w="60px"
          h="60px"
          mr="16px"
          icon={<Facebook />}
          id="signup_button_facebook"
        />
        <IconButton
          onClick={onGoogleSignUp}
          p="0"
          w="60px"
          h="60px"
          icon={<Google />}
          id="signup_button_google"
        />
      </Flex>
      <Box
        as="span"
        display="block"
        fontSize="md"
        color="gray.800"
        letterSpacing="0.05px"
        fontWeight="500"
        mb={4}
      >
        {t('login@orLogin')}
      </Box>
      <Stack mb="2" spacing="5">
        <Flex direction={{ base: 'column', sm: 'row' }}>
          <Box w="full" mr={{ base: '0', sm: '3' }} mb={{ base: '8', sm: '0' }}>
            <InputField
              size="md"
              id="signup_input_firstname"
              errorMessage={t('signUp@firstNameErrorMessage')}
              label={t('signUp@firstName')}
              isInvalid={!!errors.firstName}
              {...register('firstName', { required: true })}
            />
          </Box>
          <InputField
            size="md"
            id="signup_input_lastname"
            errorMessage={t('signUp@lastNameErrorMessage')}
            label={t('signUp@lastName')}
            isInvalid={!!errors.lastName}
            {...register('lastName', { required: true })}
          />
        </Flex>
        <InputField
          size="md"
          isInvalid={!!errors.email}
          id="signup_input_email"
          label={t('signUp@email')}
          maxLength={80}
          errorMessage={t(errors?.email?.message)}
          {...register('email', {
            required: {
              message: 'createEditProject@thisFieldIsRequired',
              value: true,
            },
            pattern: {
              message: 'createEditInitiative@emailError',
              value: /\S+@\S+\.\S+/,
            },
          })}
        />
        <Flex direction={{ base: 'column', sm: 'row' }}>
          <Box w="full">
            <PasswordField
              size="lg"
              id="signup_input_password"
              isInvalid={!!errors.password}
              newPassword
              {...register('password', {
                required: true,
                pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
                minLength: 8,
              })}
              label={t('signUp@password')}
            />
          </Box>
        </Flex>
      </Stack>
      {user.id === 'guest' && (
        <Checkbox
          borderColor="border.100"
          mt={4}
          {...register('subscribeNews')}
        >
          <Text fontSize="sm" lineHeight="16px">
            {t('donation@subscribeOnDonateText').split('(bold)')[0]}
            <b>{t('donation@subscribeOnDonateText@bold')}</b>
            {t('donation@subscribeOnDonateText').split('(bold)')[1]}
          </Text>
        </Checkbox>
      )}
      <Box mt="33px" textAlign="center" fontSize="md" color="gray.900">
        {t('signUp@agreeToThe')}
        <br />
        <Link
          style={{
            color: '#1A1A1A',
            fontSize: '14px',
            fontWeight: 'bold',
            marginLeft: '5px',
            marginRight: '5px',
          }}
          target="_blank"
          to="/terms-and-conditions"
        >
          {t('signUp@termsConditions')}
        </Link>
        {t('signUp@andThe')}
        <Link
          style={{
            color: '#1A1A1A',
            fontSize: '14px',
            fontWeight: 'bold',
            marginLeft: '5px',
          }}
          target="_blank"
          to="/privacy-policy"
        >
          {t('signUp@privacyPolicy')}
        </Link>
      </Box>
      <Button
        id="signup_input_button"
        isLoading={loading}
        w="full"
        mt={6}
        mb={4}
        type="submit"
        colorScheme="yellow"
        size="lg"
        variant="blue"
      >
        {t('signUp@submit')}
      </Button>
    </form>
  )
}
