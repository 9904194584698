import { HStack, Stack, Text, Image, Box } from '@chakra-ui/react'
import {
  useCurrencySettings,
  thousandSeparator,
  convert,
} from 'contexts/CurrencySettingsContext'
import ImageWrapper from 'components/ImageWrapper'
import Progress from 'components/Progress'
import { useTranslation } from 'contexts/TranslationContext'
import { calculateProjectDurationLeft } from 'hooks'

export default function HorizontalProjectCardWithProgress({ project }) {
  const { currency, settings, changeDefaultCurrency } = useCurrencySettings()
  const { language, t } = useTranslation()

  return (
    <Stack
      borderRadius="16px"
      p={4}
      spacing={4}
      bg="gray.400"
      justifyContent="space-between"
      alignItems="center"
    >
      <HStack spacing={4} w="100%">
        {project?.cover ? (
          <ImageWrapper
            src={`/thumbnail/small/${project?.cover}`}
            alt={project.applicationTitle}
            borderRadius="8px"
            h="45px"
            w="78px"
            objectFit="cover"
          />
        ) : (
          <Image
            objectFit="cover"
            borderRadius="8px"
            h="45px"
            w="78px"
            ignoreFallback={true}
            src="/assets/images/noImg.svg"
          />
        )}
        <Stack spacing={1}>
          <Text variant="heading-1" color="gray.800" noOfLines={1}>
            {project[`title_${language}`]}
          </Text>
          <Text variant="body-1" color="gray.800" noOfLines={2}>
            {project[`tagline_${language}`]}
          </Text>
        </Stack>
      </HStack>
      <Box w="100%">
        <Progress
          goalPrice={project.activeGoal?.amount || project.goal}
          raisedMoney={thousandSeparator(
            convert({
              amount: project.amount,
              currencyRate: settings?.currency[currency.current],
            }),
            currency.current
          )}
          thousandSeparatorText={thousandSeparator(
            currency.current === 'AMD'
              ? project.amount + Number(project.updateAmount)
              : project.amount / Number(settings?.currency[currency.current]) +
                  Number(project.updateAmount),
            currency.current
          )}
          project={project}
          monthlyGoalText={t('monthlyGoal')}
          raisedText={t('raised')}
          calculatedProjectDurationLeft={calculateProjectDurationLeft({
            t,
            campaignImplementorPeriod_en: project.campaignImplementorPeriod_en,
            fundraisingType: project.fundraisingType,
          })}
          currency={currency}
          settings={settings}
          changeDefaultCurrency={changeDefaultCurrency}
        />
      </Box>
    </Stack>
  )
}
