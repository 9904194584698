import { useMutation } from 'react-query'
import { reportUseCase } from '../../core/factories'

const useEditReportWithDate = () => {
  const { mutate, data, isLoading } = useMutation(
    reportUseCase.editReportWithDate
  )

  return {
    mutate,
    isLoading,
    data,
  }
}

export default useEditReportWithDate
