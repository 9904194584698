import { useQueryParams } from 'hooks'
import ThankYouPageTemplate from './ThankYouPageTemplate'

function ThankYouStripe() {
  const params = useQueryParams()

  const isCollaboration = params.get('collaboration')?.trim() === 'true'
  const projectId = params.get('id')
  const transactionId = params.get('transactionId')

  return (
    <ThankYouPageTemplate
      isCollaboration={isCollaboration}
      projectId={projectId}
      transactionId={transactionId}
      analyticEvents={{
        amplitude: 'ThankYou stripe page view',
      }}
    />
  )
}

export default ThankYouStripe
