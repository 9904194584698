import React from 'react'
import { Stack, Box, Heading, Text, Checkbox } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import Goals from 'components/GoalsForm'
import { BudgetFiles } from 'components/BudgetFiles'
import RequestedAmount from './RequestedAmount'

const Fundraiser = ({
  register,
  errors,
  setValue,
  tabIndex,
  goals,
  fundraisingType,
  append,
  remove,
  oldId,
  getValues,
  budgetFiles,
  clearErrors,
}) => {
  const { t } = useTranslation()
  return (
    <Box maxW="600px" width="100%">
      <Box display={'flex'}>
        <Heading mb="6" fontSize="6xl" as="h2">
          {fundraisingType === 'oneTime'
            ? t('projectCreate@oneTime')
            : t('project@recurringHelperTitle')}
        </Heading>
      </Box>
      {!!oldId && (
        <Text mb="8" color="gray.700" fontSize="md">
          {t('Collaboration ID:')} {oldId}
        </Text>
      )}
      {fundraisingType === 'recurring' && (
        <>
          <Text fontWeight={600} fontSize="md" color="gray.800">
            {t('projectCreate@recurringText1')}
          </Text>
          <Text fontSize="md" pt={5} pb="30px">
            {t('projectCreate@recurringText2')}
          </Text>
        </>
      )}
      <Stack spacing="6">
        {fundraisingType === 'oneTime' ? (
          <>
            <RequestedAmount
              errors={errors}
              register={register}
              setValue={setValue}
            />
            <Box mt="32px !important">
              <Goals
                goals={goals}
                tabIndex={goals}
                errors={errors}
                remove={remove}
                register={register}
                append={append}
                getValues={getValues}
                setValue={setValue}
              />
            </Box>
          </>
        ) : (
          <Goals
            goals={goals}
            tabIndex={tabIndex}
            errors={errors}
            remove={remove}
            register={register}
            append={append}
            getValues={getValues}
            setValue={setValue}
            isRequired={true}
          />
        )}
        {fundraisingType === 'recurring' && (
          <>
            <Box h="1px" w="100%" bg="border.100" mt="32px" mb="24px" />

            <Checkbox
              alignItems="flex-start"
              _focusVisible={{ boxShadow: 'outline' }}
              _focus={{ boxShadow: 'none' }}
              {...register(`isOneTimeDisabled`)}
            >
              {t('projectCreate@allowOneTime')}
            </Checkbox>
          </>
        )}
        <Box h="1px" w="100%" bg="border.100" mt="32px" mb="24px" />
        <BudgetFiles
          fieldKey="budgetFiles"
          data={budgetFiles}
          register={register}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          clearErrors={clearErrors}
          required
        />
      </Stack>
    </Box>
  )
}

export default Fundraiser
