import React, { useCallback, useState, useMemo } from 'react'
import { post } from 'aws-amplify/api'
import { useForm } from 'react-hook-form'
import {
  Text,
  Box,
  VStack,
  Flex,
  Checkbox,
  Button,
  Img,
  HStack,
} from '@chakra-ui/react'
import { RadioGroup } from './RadioGroup'
import { useTranslation } from 'contexts/TranslationContext'
import { Link } from 'pages'
import { useValidateEmail, useUser, useSubscribeNews } from 'hooks'
import { InputField } from '../InputField'
import useCustomToast from 'hooks/useCustomToast'
import { getResponseToJSON } from 'hooks/useResponseManipulations'

const PaymentForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
  })
  const { t, language } = useTranslation()
  const toast = useCustomToast()
  const validateEmail = useValidateEmail()
  const [amount, setAmount] = useState('More')
  const [loading, setLoading] = useState(false)
  const { user } = useUser()
  const { mutate: subscribeMailerLiteNews } = useSubscribeNews()
  const price = useMemo(
    () => ({
      '2,000 AMD': '2,000 AMD',
      '5,000 AMD': '5,000 AMD',
      '10,000 AMD': '10,000 AMD',
      more: t('other'),
    }),
    []
  )

  const handleAmount = (e) => {
    if (e === '2,000 AMD') {
      e = 2000
    } else if (e === '5,000 AMD') {
      e = 5000
    } else if (e === '10,000 AMD') {
      e = 10000
    }
    setAmount(e)
  }

  const submit = useCallback(
    async (data) => {
      if (!!data.email && !validateEmail(data.email)) {
        return toast({
          title: 'Invalid email format',
          status: 'error',
          isCloseable: true,
          duration: 5000,
        })
      }
      data.amount = amount
      if (amount === 'more') data.amount = data.more
      const inputAmount = parseInt(data.amount)

      const body = {
        amount: parseInt(`${inputAmount + '00'}`),
        language: 'en',
        description: `Donation for reArmenia,${
          user.id === 'guest' ? data.email : user.email
        }`,
        redirectUrl: `${window.location.protocol}//${window.location.host}/${language}/check-transaction`,
        transactionInfo: {
          projectId: 'transaction-to-reArmenia',
          projectTitle: 'reArmenia',
          donationToProject: 0,
          donationToReArmenia: parseInt(data.amount),
          userId: user?.id ?? '',
          email: user.id === 'guest' ? data.email.trim() : user?.email,
          firstName: user.id === 'guest' ? data.firstName : user?.firstName,
          lastName: user.id === 'guest' ? data.lastName : user?.lastName,
          currency: 'AMD',
          currencyRate: 1,
        },
      }

      if (
        !body.transactionInfo.email ||
        !body.transactionInfo.firstName ||
        !body.transactionInfo.lastName
      ) {
        return toast({
          title: 'First name, last name and email are required!',
          status: 'error',
          isCloseable: true,
          duration: 5000,
        })
      }
      setLoading(true)

      try {
        const res = post({
          apiName: 'paymentAPI',
          path: '/payment',
          options: {
            body,
          },
        })
        const data = await getResponseToJSON(res)

        await subscribeMailerLiteNews(
          {
            email: data.email?.toLowerCase(),
            language,
            lastName: body.transactionInfo.lastName,
            firstName: body.transactionInfo.firstName,
          },
          {
            onError: (e) => {
              console.log('subscribe error', e)
            },
          }
        )

        const result = JSON.parse(data.data)
        setLoading(false)
        if (result.formUrl) {
          window.location = result.formUrl.replace('_binding', '')
        }
      } catch (e) {
        console.log(e)
      }
    },
    [language, amount]
  )

  return (
    <form onSubmit={handleSubmit(submit)}>
      <VStack
        alignItems="flex-start"
        spacing="8"
        width="full"
        p={{ base: 4, sm: 6, md: 8 }}
      >
        <RadioGroup
          defaultValue="More"
          width="100%"
          name="amount"
          options={price}
          onChange={handleAmount}
        />
        {amount === 'more' && (
          <InputField
            label={t('payment@currency')}
            errorMessage={t('payment@amountRequired')}
            isInvalid={!!errors.more}
            pattern="[0-9]*"
            {...register('more', { required: true, pattern: /[0-9]/ })}
          />
        )}
        {user.id === 'guest' && (
          <>
            <HStack w="full" spacing="4">
              <InputField
                label={t('payment@firstName')}
                errorMessage={t('payment@firstNameErrorMessage')}
                isInvalid={!!errors.firstName}
                {...register('firstName', { required: true })}
              />
              <InputField
                label={t('payment@lastName')}
                errorMessage={t('payment@lastNameErrorMessage')}
                isInvalid={!!errors.lastName}
                {...register('lastName', { required: true })}
              />
            </HStack>
            <InputField
              label={t('payment@email')}
              errorMessage={t('payment@emailErrorMessage')}
              isInvalid={!!errors.email}
              {...register('email', { required: true })}
            />
          </>
        )}
        <Checkbox
          isInvalid={!!errors.terms}
          {...register('terms', { required: true })}
        >
          <Box fontSize="sm" as="span">
            {t('payment@agreeToThe')}
            <Box zIndex="10" color="blue.400" as="span">
              <Link target="_blank" to="/terms-and-conditions">
                {' '}
                {t('payment@terms')}
              </Link>
            </Box>
            {t('payment@and')}
            <Box color="blue.400" as="span">
              <Link target="_blank" to="/privacy-policy">
                {' '}
                {t('payment@privacyPolicy')}
              </Link>
            </Box>
          </Box>
        </Checkbox>
        <Box borderBottom="1px solid" borderColor="#DDDDDD" w="full" />
        <Flex w="full" alignItems="center" flexDirection="column">
          <Box minH="20px">
            <Img height="20px" src="/assets/images/cards.png" alt="cards" />
          </Box>
          <Text mt="6" mb="4" fontSize="sm">
            {t('payment@allRightsReserved').replace(
              '2021',
              new Date().getFullYear()
            )}
          </Text>
          {/* <Text mb="4" fontWeight="bold" fontSize="sm">
            {t('payment@weProtect')}
          </Text> */}
          {/* <Box minH="40px">
            <Img
              maxH="40px"
              src="/assets/images/security.jpeg"
              alt="security"
            />
          </Box> */}
        </Flex>
        <Button
          isLoading={loading}
          mt="4"
          w="full"
          type="submit"
          variant="green"
        >
          {t('donation@donate')}
        </Button>
      </VStack>
    </form>
  )
}

export default PaymentForm
