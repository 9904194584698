import { Button, Flex, Image, Text } from '@chakra-ui/react'
import { ReactComponent as Avatar } from 'assets/icons/avatar.svg'
import { ReactComponent as ExpertIcon } from 'assets/icons/dashboardExpertIconLight.svg'
import { Link } from 'pages'

export const Notification = ({
  id,
  body,
  fromNow,
  image,
  isRead,
  link,
  onClick,
  t,
  language,
}) => {
  const defaultIcons = {
    expert: ExpertIcon,
  }

  const textNormalizer = ({ username, description }) => {
    if (!username) {
      return <Text>{description}</Text>
    }

    return (
      <Text>
        {description?.split('username')?.[0]}
        <b>{username}</b>
        {description?.split('username')?.[1]}
      </Text>
    )
  }
  const { username, description, approve, reject, imageSrc } = JSON.parse(body)

  return (
    <Flex
      w="full"
      bg={isRead ? 'transparent' : '#d9e0ead9'}
      px={4}
      py={{ base: 2, sm: 5 }}
      minH={24}
      borderBottom="1px solid"
      borderColor="border.100"
      onClick={() => onClick({ id, isRead: true }, link)}
      cursor="pointer"
      className="notification"
    >
      {image ? (
        <Image
          borderRadius="50%"
          objectFit="cover"
          w="40px"
          h="40px"
          src={image}
        />
      ) : imageSrc ? (
        <DynamicIconRenderer Component={defaultIcons[imageSrc]} />
      ) : (
        <Avatar height="40px" width="40px" />
      )}
      <Flex direction="column" ml={3} flex={1}>
        <Text fontSize="xl" color="gray.800">
          {textNormalizer({
            username,
            description: description?.[language] || description, //TODO Remove when old notifications deleted
          })}
        </Text>
        <Text mt={2} color="#949191">
          {fromNow}
        </Text>
        {approve && reject && (
          <Flex mt={2.5} mb={1}>
            <Button
              as={Link}
              px={6}
              to={reject}
              w={{ sm: '232px' }}
              variant="white"
              mr={2}
            >
              {t('reject')}
            </Button>
            <Button
              as={Link}
              px={6}
              to={approve}
              w={{ sm: '232px' }}
              variant="blue"
            >
              {t('approve')}
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

const DynamicIconRenderer = ({ Component }) => (
  <Component borderRadius="50%" objectFit="cover" w="40px" h="40px" />
)
