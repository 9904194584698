import React, { useEffect, useState } from 'react'
import { Heading, Box, Button, Stack, Flex } from '@chakra-ui/react'
import { post } from 'aws-amplify/api'
import { useHistory } from 'react-router-dom'
import { InputField } from 'components/InputField'
import {
  useCollabRequest,
  useUser,
  useQueryParams,
  useUpdateProject,
  useGetProject,
} from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import { TextareaField } from 'components/TextareaFIeld'
import { ReactComponent as Close } from 'assets/icons/closeBlack.svg'
import { useNavigation } from 'pages'
import useCustomToast from 'hooks/useCustomToast'

import { useForm } from 'react-hook-form'
import NeedCard from 'pages/Project/NeedCard'
import { getResponseToJSON } from 'hooks/useResponseManipulations'

const MobileActNow = () => {
  const queryParams = useQueryParams()
  const { data: initiative, refetch } = useGetProject({
    projectId: queryParams.get('InitiativeId') ?? '',
  })
  const history = useHistory()
  const toast = useCustomToast()
  const { user } = useUser()
  const { t, language } = useTranslation()
  const { updateProject: updateInitiative } = useUpdateProject()
  const { collaboratorRequest, isLoading } = useCollabRequest()

  const { navigationPush } = useNavigation()
  const [selectedCollaboration, setSelectedCollaboration] = useState({})
  useEffect(() => {
    if (queryParams.get('need') && initiative) {
      setSelectedCollaboration(
        initiative?.collaborations?.filter(
          (e) => e.needs_en === queryParams.get('need')
        )[0]
      )
    }
  }, [initiative])

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const onSend = (data) => {
    const fullname =
      user.id === 'guest'
        ? `${data.firstName} ${data.lastName}`
        : `${user.firstName} ${user.lastName}`

    const addresses = initiative?.implementor?.email
      ? [initiative.implementor.email]
      : [initiative?.contactEmail]
    updateInitiative(
      {
        id: initiative.id,
        collaboratorsTotal: initiative.collaboratorsTotal
          ? initiative.collaboratorsTotal + 1
          : 1,
      },
      {
        onSuccess: () => refetch(),
      }
    )
    collaboratorRequest(
      {
        userId: user.id,
        initiativeId: initiative?.id ?? '',
        addresses,
        contactEmail: data.email || (user.email ?? ''),
        implementorFullName: initiative.contactFullName,
        applicationTitle: initiative.applicationTitle,
        fullname,
        isCollab: true,
        phoneNumber: data.phoneNumber,
        description: data.description,
        language,
        need: selectedCollaboration[`needs_${language}`],
        needDescription: selectedCollaboration[`needsDescription_${language}`],
      },
      {
        onSuccess: async () => {
          toast({
            title: t('initiative@messageSent'),
            status: 'success',
            isClosable: true,
            duration: 5000,
          })
          const body = {
            id: initiative.id,
            receiverEmail: data.email || (user.email ?? ''),
            fullname,
            applicationTitle: initiative.applicationTitle,
          }
          try {
            const res = post({
              apiName: 'email',
              path: '/collabThankYou',
              options: {
                body,
              },
            })
            await getResponseToJSON(res)
          } catch (e) {
            console.log(e)
          }

          navigationPush(`
          /donate/thank-you?name=${
            user.id === 'guest'
              ? `${data.firstName} ${data.lastName}`
              : `${user.firstName} ${user.lastName}`
          }&title=${initiative?.['title_' + language] ?? ''}&id=${
            initiative.id
          }&collaboration=true
          `)
        },
      }
    )
  }

  return (
    <Box>
      <Flex
        w="full"
        justifyContent="center"
        position="relative"
        alignItems="center"
        mb="10"
        borderBottom="1px solid"
        borderColor="border.100"
        py="3"
      >
        <Flex
          position="absolute"
          left="12px"
          onClick={() => history.goBack()}
          width="30px"
          height="30px"
          alignItems="center"
        >
          <Close width="14px" height="14px" />
        </Flex>
        <Heading maxW="320px" as="h1" textAlign="center" fontSize="3xl">
          {selectedCollaboration[`needs_${language}`]
            ? t('collaborationProposal')
            : t('selectANeed')}
        </Heading>
      </Flex>
      <Box px="5">
        <form onSubmit={handleSubmit(onSend)}>
          <Stack spacing="5" pb="100px">
            {selectedCollaboration[`needs_${language}`] ? (
              <>
                <NeedCard
                  collaboration={selectedCollaboration}
                  t={t}
                  lang={language}
                  viewMode
                />
                {user.id === 'guest' && (
                  <>
                    <InputField
                      isInvalid={errors.firstName}
                      errorMessage={t('createEditProject@thisFieldIsRequired')}
                      label={t('dashboard@name') + '*'}
                      {...register('firstName', {
                        required: true,
                      })}
                      size="sm"
                    />
                    <InputField
                      isInvalid={errors.lastName}
                      errorMessage={t('createEditProject@thisFieldIsRequired')}
                      label={t('dashboard@surname') + '*'}
                      {...register('lastName', {
                        required: true,
                      })}
                      size="sm"
                    />
                    <InputField
                      isInvalid={errors.email}
                      errorMessage={t(errors?.email?.message)}
                      label={t('dashboard@email') + '*'}
                      {...register('email', {
                        required: {
                          message: 'createEditProject@thisFieldIsRequired',
                          value: true,
                        },
                        pattern: {
                          message: 'createEditInitiative@emailError',
                          value: /\S+@\S+\.\S+/,
                        },
                      })}
                      size="sm"
                    />
                  </>
                )}
                <InputField
                  label={t('dashboard@phoneNumber')}
                  {...register('phoneNumber')}
                  size="sm"
                />
                <TextareaField
                  resize="none"
                  height="140px"
                  {...register('description')}
                  label={`${t('additionalInfo')}`}
                />
              </>
            ) : (
              <>
                {initiative?.collaborations.map((collaboration) => (
                  <NeedCard
                    key={collaboration.index}
                    collaboration={collaboration}
                    t={t}
                    lang={language}
                    selectAction={() => setSelectedCollaboration(collaboration)}
                  />
                ))}
              </>
            )}
          </Stack>
          {selectedCollaboration?.needs && (
            <Flex
              zIndex="1000"
              bg="white"
              w="full"
              bottom="0"
              left="0"
              borderTop="1px solid"
              borderColor="border.100"
              justifyContent="space-between"
              pt="3"
              pb="2.125rem"
              px="3"
              position="fixed"
              spacing="3"
            >
              <Button
                isDisabled={!selectedCollaboration[`needs_${language}`]}
                isLoading={isLoading}
                w="full"
                variant="blue"
                type="submit"
              >
                {t('send')}
              </Button>{' '}
            </Flex>
          )}
        </form>
      </Box>
    </Box>
  )
}

export default MobileActNow
