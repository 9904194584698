import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useNavigation } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import { useCustomToast, useUpdateUser, useUser } from 'hooks'
import Space from '../Space'
import Profile from './Profile'
import Account from './Account'
import Notification from './Notification'
import { getCountryByNameOrCode } from 'translations'
import TabsLayout from 'components/Layouts/TabsLayout'

const TABS = [
  {
    value: 'profile-settings',
    title: 'profile@settings-profile',
    Panel: Profile,
  },
  {
    value: 'account-settings',
    title: 'profile@settings-account',
    Panel: Account,
  },
  {
    value: 'notification-management',
    title: 'profile@settings-notification',
    Panel: Notification,
  },
]

const Settings = () => {
  const { t, language, setLanguage } = useTranslation()
  const toast = useCustomToast()
  const { tab } = useParams()
  const { navigationPush } = useNavigation()
  const { user, getUser } = useUser()
  const { updateUser, isLoading } = useUpdateUser()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      ...user,
    },
  })

  const submit = (data) => {
    const { firstName, lastName, id, country, language, currency } = data
    const input = {
      id,
      firstName: firstName.replace(/\s+/g, ' ').trim(),
      lastName: lastName.replace(/\s+/g, ' ').trim(),
      country,
      cognitoId: user.cognitoId,
      language,
      currency,
    }
    input.country = getCountryByNameOrCode({
      countryCode: data?.country?.code || 'am',
    })?.name_en

    updateUser(input, {
      onSuccess: () => {
        getUser()
        toast({
          status: 'success',
          title: t('profile@updatedSuccessfully'),
          isClosable: true,
          duration: 5000,
        })
      },
      onError: (e) => console.log(e),
    })
  }

  const onChange = (tab) =>
    navigationPush(`/profile/settings/${TABS[tab].value}`)

  useEffect(() => {
    if (user) {
      reset(user)
    }
  }, [user])

  return (
    <>
      <Space title={t('profile@settings')}>
        <form onSubmit={handleSubmit(submit)}>
          <TabsLayout
            defaultIndex={TABS.findIndex(({ value }) => value === tab)}
            onChange={onChange}
            TABS={TABS}
            user={user}
            register={register}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            isLoading={isLoading}
            t={t}
            language={language}
            setLanguage={setLanguage}
          />
        </form>
      </Space>
    </>
  )
}

export default Settings
