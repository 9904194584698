import React from 'react'
import {
  Box,
  Button,
  Stack,
  useColorModeValue as mode,
  Flex,
  Heading,
} from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'contexts/TranslationContext'
import { InputField } from 'components/InputField'
import { ReactComponent as Close } from 'assets/icons/closeBlack.svg'

const MobileVerify = ({
  handleVerify,
  onVerify,
  verifyErrors,
  verify,
  loading,
  onResend,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  return (
    <Box>
      <Flex
        py="3"
        justifyContent="center"
        borderBottom="1px solid"
        borderColor="border.100"
        alignItems="center"
      >
        <Flex
          position="absolute"
          left="12px"
          onClick={() => history.goBack()}
          height="30px"
          width="30px"
          alignItems="center"
        >
          <Close width="14px" height="14px" />
        </Flex>
        <Heading as="h1" textAlign="center" fontSize="3xl">
          {t('verify@submitButtonText')}
        </Heading>
      </Flex>
      <Flex
        flexDirection="column"
        justifyContent="center"
        style={{ minHeight: 'calc(100vh - 54px)' }}
        px="5"
      >
        <form onSubmit={handleVerify(onVerify)}>
          <Stack spacing="8">
            <InputField
              borderRadius="12px"
              size="lg"
              isInvalid={!!verifyErrors.code}
              errorMessage={t('verify@codeRequired')}
              {...verify('code', { required: true })}
              label={t('verify@code')}
            />
            <Button
              isLoading={loading}
              type="submit"
              variant="blue"
              size="lg"
              fontSize="md"
            >
              {t('verify@submitButtonText')}
            </Button>
            <Box
              as="button"
              type="button"
              onClick={onResend}
              color={mode('blue.600', 'blue.200')}
              mx="auto"
            >
              {t('verify@resendCode')}
            </Box>
          </Stack>
        </form>
      </Flex>
    </Box>
  )
}

export default MobileVerify
