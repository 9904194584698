import { useQuery } from 'react-query'
import { updateUseCase } from 'core/factories'

const useListUpdates = () => {
  const { error, refetch, isLoading, data } = useQuery('updates', () =>
    updateUseCase.listUpdatesByDate()
  )

  return {
    error,
    refetch,
    isLoading,
    data,
  }
}

export default useListUpdates
