import React, { useState, forwardRef, useRef, useEffect } from 'react'
import {
  FormControl,
  FormLabel,
  Textarea,
  Box,
  Text,
  Heading,
} from '@chakra-ui/react'
import useAutoSizeTextArea from './useAutoSizeTextArea'

const chars = (char, minLength, maxLength) => {
  if (!char) {
    return `${minLength}-${maxLength}`
  }
  if (minLength && maxLength) {
    if (char < minLength) {
      return `${char} characters (minimum ${minLength} characters)`
    }
    return `${char} characters (maximum ${maxLength} characters)`
  }
  if (maxLength) {
    return `${char} / ${maxLength}`
  }

  return ''
}

export const TextAreaComponent = forwardRef((props, ref) => {
  const {
    title,
    description,
    isInvalid,
    minLength = 0,
    maxLength,
    errorMessage,
    titleProps = {},
    formControlProps = {},
    defaultValue,
    ...rest
  } = props
  const [isFocus, setIsFocus] = useState(false)
  const [char, setChar] = useState(defaultValue?.length || 0)

  useEffect(() => {
    //? Didn't work by setting initial value as defaultValueLength
    if (defaultValue) {
      setChar(defaultValue?.length)
    }
  }, [defaultValue])

  const textAreaRef = useRef(null)
  useAutoSizeTextArea(textAreaRef.current, char)
  return (
    <FormControl position="relative" {...formControlProps}>
      {!!isInvalid && (
        <Box
          top="calc(100% + 5px)"
          fontSize="sm"
          fontWeight="semibold"
          position="absolute"
          color="red.400"
        >
          {errorMessage || isInvalid?.message}
        </Box>
      )}
      {!!maxLength && (
        <Box
          position="absolute"
          right={0}
          fontWeight="semibold"
          fontSize="sm"
          color={isInvalid ? 'red.400' : isFocus ? 'blue.400' : 'gray.700'}
          top="calc(100% + 5px)"
        >
          {chars(char, minLength, maxLength)}
        </Box>
      )}
      {(title || description) && (
        <FormLabel>
          <Heading fontSize="xl" fontWeight="700" zIndex={2} {...titleProps}>
            {title}
          </Heading>
          <Text fontSize="sm" fontWeight={400} mt="6px" mb="16px">
            {description}
          </Text>
        </FormLabel>
      )}

      <Textarea
        isInvalid={!!isInvalid}
        borderColor="border.100"
        resize="none"
        _focus={{ borderColor: 'blue.400' }}
        _hover={{ borderColor: 'blue.400' }}
        sx={{ boxShadow: 'none !important' }}
        ref={(e) => {
          if (ref) {
            ref(e)
          }
          textAreaRef.current = e
        }}
        minLength={minLength}
        maxLength={maxLength}
        size="lg"
        overflow="hidden"
        fontSize="lg"
        onFocus={() => {
          setIsFocus(true)
        }}
        onBlur={() => {
          setIsFocus(false)
        }}
        onInput={(e) => {
          setChar(e.target.value.length)
        }}
        {...rest}
      />
    </FormControl>
  )
})

TextAreaComponent.displayName = 'InputField'
