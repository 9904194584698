import moment from 'moment'

export default function calculateProjectDurationLeft({
  t,
  campaignImplementorPeriod_en,
  fundraisingType,
}) {
  const currentDate = moment(new Date())
  let endingDate = ''

  if (fundraisingType === 'recurring') {
    endingDate = moment(moment().endOf('month'))
  } else {
    endingDate = moment(new Date(campaignImplementorPeriod_en))
  }

  const daysLeft = endingDate.diff(currentDate, 'days')
  const hoursLeft = endingDate.diff(currentDate, 'hours')

  if (daysLeft < 0 || !campaignImplementorPeriod_en) return ''
  if (daysLeft < 5 && daysLeft > 1) {
    return t('daysLeft2').replace('...', daysLeft)
  }
  if (daysLeft === 1) {
    return t('dayLeft').replace('...', daysLeft)
  }
  if (daysLeft === 0) {
    if (hoursLeft < 24 && hoursLeft >= 5) {
      return t('hoursLeft2').replace('...', hoursLeft)
    }
    if (hoursLeft === 1) {
      return t('hourLeft').replace('...', hoursLeft)
    }
    if (hoursLeft < 5 && hoursLeft > 1) {
      return t('hoursLeft').replace('...', hoursLeft)
    }
  }

  return t('daysLeft').replace('...', daysLeft)
}
