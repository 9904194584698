import React from 'react'
import MessengerCustomerChat from 'react-messenger-customer-chat'
import './styles.scss'

const MessengerWrapper = ({ children }) => {
  return (
    <>
      {children}

      {process.env.REACT_APP_ENV === 'production' && (
        <MessengerCustomerChat
          pageId={process.env.REACT_APP_AMPLIFY_FACEBOOK_CLIENT_PAGE_ID}
          appId={process.env.REACT_APP_AMPLIFY_FACEBOOK_CLIENT_ID}
        />
      )}
    </>
  )
}

export default MessengerWrapper
