import React from 'react'
import AuthWrapper from 'components/AuthWrapper'
import { useTranslation } from 'contexts/TranslationContext'
import {
  Box,
  Heading,
  useColorModeValue as mode,
  Button,
  Stack,
} from '@chakra-ui/react'
import { PasswordField } from 'components/PasswordField'
import { useForm } from 'react-hook-form'
import useSetPassword from 'hooks/auth/useSetPassword'
import {
  useCustomToast,
  useQueryParams,
  useSignIn,
  useSignUp,
  useUser,
} from 'hooks'
import { useNavigation } from 'pages'
import { useState } from 'react'

export default function SetPassword() {
  const { t, language } = useTranslation()
  const params = useQueryParams()
  const { signUp } = useSignUp()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      password: '',
      token: params.get('token'),
    },
  })
  const { mutate: setPasswordRequest } = useSetPassword()
  const toast = useCustomToast()
  const { signIn } = useSignIn()
  const { navigationPush } = useNavigation()
  const { getUser } = useUser()
  const [isLoading, setIsLoading] = useState()
  const setPassword = (data) => {
    console.log(data)
    setIsLoading(true)
    setPasswordRequest(
      { ...data },
      {
        onSuccess: (res) => {
          signUp(
            {
              email: res.user.email,
              firstName: res.user.firstName,
              lastName: res.user.lastName,
              password: data.password,
              language,
            },
            {
              onSuccess: async () => {
                toast({
                  status: 'success',
                  title: 'Password successfully set',
                  isClosable: true,
                  duration: 5000,
                })
                signIn(
                  {
                    password: data.password,
                    email: res.user.email,
                  },
                  {
                    onSuccess: async () => {
                      await getUser()
                      setIsLoading(false)
                      navigationPush('/profile/matching')
                    },
                    onError: (err) => {
                      setIsLoading(false)

                      toast({
                        title: t(err.message),
                        status: 'error',
                        duration: 5000,
                        position: 'top',
                      })
                    },
                  }
                )
              },
              onError: (e) => {
                console.log(e)
                setIsLoading(false)
                toast({
                  status: 'error',
                  title: t(`signUp@${e.code}`),
                  isClosable: true,
                  duration: 5000,
                })
              },
            }
          )
        },
        onError: (d) => {
          setIsLoading(false)
          console.log(d)
        },
      }
    )
  }
  return (
    <AuthWrapper>
      <Box w="full" maxW="lg" mx="6">
        <Heading
          as="h1"
          fontSize="4xl"
          fontWeight="extrabold"
          letterSpacing="tight"
          textAlign="center"
          mb="12"
        >
          {t('setPassword@heading')}
        </Heading>

        <Box w={{ md: '440px' }} bg={{ md: mode('white', 'gray.700') }} pb="8">
          <form onSubmit={handleSubmit(setPassword)}>
            <Stack spacing="8">
              <PasswordField
                size="lg"
                id="signup_input_password"
                isInvalid={!!errors.password}
                newPassword
                {...register('password', {
                  required: true,
                  pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
                  minLength: 8,
                })}
                label={t('signUp@password')}
              />
              <Button
                isLoading={isLoading}
                type="submit"
                variant="blue"
                size="lg"
                fontSize="md"
              >
                {t('setPassword@buttonText')}
              </Button>
            </Stack>
          </form>
        </Box>
      </Box>
    </AuthWrapper>
  )
}
