import { Stack } from '@chakra-ui/react'
import Info from './Info'
import Form from './Form'

export default function Variant1({
  logo,
  heading,
  isSecret,
  isLoading,
  description,
  inputs,
  onSubmit,
}) {
  return (
    <Stack
      p={6}
      borderRadius="16px"
      borderWidth={1}
      borderColor="gray.600"
      spacing={4}
    >
      <Info heading={heading} description={description} logo={logo} />
      <Form
        isSecret={isSecret}
        inputs={inputs}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
    </Stack>
  )
}
