const stripeRepo = (stripeService) => {
  const paymentIntent = (params) => stripeService.paymentIntent(params)

  const confirmPayment = (params) => stripeService.confirmPayment(params)

  const connectAccount = (params) => stripeService.connectAccount(params)

  const continueConnectAccount = (params) =>
    stripeService.continueConnectAccount(params)

  const retrieveAccount = (params) => stripeService.retrieveAccount(params)

  const createCustomer = (params) => stripeService.createCustomer(params)

  const attachPmToCustomer = (params) =>
    stripeService.attachPmToCustomer(params)

  const retrieveCustomer = (params) => stripeService.retrieveCustomer(params)

  const createSource = (params) => stripeService.createSource(params)

  const sendOneTimeEmail = (params) => stripeService.sendOneTimeEmail(params)

  return {
    paymentIntent,
    connectAccount,
    continueConnectAccount,
    retrieveAccount,
    confirmPayment,
    createCustomer,
    attachPmToCustomer,
    retrieveCustomer,
    createSource,
    sendOneTimeEmail,
  }
}

export default stripeRepo
