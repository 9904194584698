import { Box, Stack, Text } from '@chakra-ui/react'
import { CATEGORY } from '_constants'
import { InputField } from 'components/InputField'
import { Select } from 'components/Select'
import { useTranslation } from 'contexts/TranslationContext'
import Header from '../Header'

export default function NonProfitBasic({ register, errors, oldId }) {
  const { t, language } = useTranslation()

  return (
    <Box maxW="600px" width="100%">
      <Header />
      <Stack mb="5.5rem" spacing="12" mt={10}>
        <InputField
          isDisabled={!!oldId}
          isInvalid={!!errors.organizationName}
          titleProps={{ fontWeight: 'bold', fontSize: 'md' }}
          label={`1. ${t('Company name and legal form')}*`}
          errorMessage={t('createEditProject@thisFieldIsRequired')}
          maxLength={80}
          {...register('organizationName', {
            required: true,
            maxLength: 80,
          })}
        />
        <InputField
          isDisabled={!!oldId}
          isInvalid={!!errors.TIN}
          titleProps={{ fontWeight: 'bold', fontSize: 'md' }}
          label={`2. ${t('TIN (tax identification number)')}*`}
          errorMessage={t('createEditProject@thisFieldIsRequired')}
          maxLength={80}
          type="number"
          pattern="[0-9+]"
          {...register('TIN', {
            required: true,
            maxLength: 80,
          })}
        />
        <Stack spacing={2}>
          <Text fontSize="md" fontWeight="700" zIndex={2}>
            {`3. ${t("Organization's field of activity")}*`}
          </Text>
          <Select
            isDisabled={!!oldId}
            type="category"
            {...register('category', { required: true })}
            isInvalid={!!errors.category}
            options={CATEGORY[language]}
            errorMessage={t('error@category')}
            placeholder={t('createEditProject@category')}
          />
        </Stack>
        <InputField
          isDisabled={!!oldId}
          isInvalid={!!errors.summary}
          titleProps={{ fontWeight: 'bold', fontSize: 'md' }}
          label={`4. ${t("Description of the organization's activities")}*`}
          errorMessage={t('createEditProject@thisFieldIsRequired')}
          maxLength={80}
          {...register('summary', {
            required: true,
            maxLength: 80,
          })}
        />
      </Stack>
    </Box>
  )
}
