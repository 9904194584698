import { UnorderedList, ListItem, Box } from '@chakra-ui/react'
import moment from 'moment'

const DETAILS_FIELDS = {
  budgetBreakdown: 'budget breakdown',
  solution: 'solution',
  problem: 'problem',
  TIN: 'TIN',
  beneficiaries: 'beneficiaries',
  phases: 'phases',
  risks: 'risks',
  otherPlayers: 'other players',
  objective: 'objective',
  keyResults: 'key results',
  contactEmail: 'contact email',
  summary: 'summary',
  amount: 'amount',
  support: 'support',
  goalType: 'goal type',
  publishedAt: 'published at',
  createdAt: 'created at',
  updatedAt: 'updated at',
}

const DetailItem = ({ label, value }) => {
  if (!value || !DETAILS_FIELDS[label]) {
    return <></>
  }

  return (
    <ListItem mb={3}>
      <Box
        as="span"
        textTransform="capitalize"
        color="orange.400"
        fontWeight="bold"
      >
        {DETAILS_FIELDS[label]}
      </Box>
      <Box as="p">
        {label === 'createdAt' || label === 'updatedAt'
          ? moment(value).format('DD-MM-YYYY HH:mm:ss')
          : value}
      </Box>
    </ListItem>
  )
}

export default function Details({ project }) {
  return (
    <UnorderedList ml="0" listStyleType="none">
      {Object.entries(project).map(([key, value]) => (
        <DetailItem key={key} label={key} value={value} />
      ))}
    </UnorderedList>
  )
}
