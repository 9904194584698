import { useEffect, useState, memo } from 'react'
import { Spinner, GridItem, Heading, Flex, Box } from '@chakra-ui/react'
import _ from 'lodash'
import AllProductsWrapper from 'components/AllProductsWrapper'
import ProjectCard from 'components/ProjectCard'
import { useTranslation } from 'contexts/TranslationContext'
import {
  useCurrencySettings,
  thousandSeparator,
  convert,
} from 'contexts/CurrencySettingsContext'
import {
  useUser,
  useQueryParams,
  useProjects,
  calculateProjectDurationLeft,
} from 'hooks'
import { useQueryClient } from 'react-query'
import MobileFundraisers from './MobileFundraisers'
import MetaTags from 'components/MetaTags'
import { useNavigation } from 'pages'
import { projectsQuery } from 'elastic/queries'
import ProjectList from 'components/ProjectList'
import GiftCardBanner from 'components/GiftCardBanner'
import { useGiftCard } from 'contexts/GiftCardContext'
import { useModal } from 'providers/ModalProvider'
import amplitude from 'amplitude-js'
import { PROJECT_TYPE_NON_PROFIT } from 'constants/project'
import { useScreenSize } from 'contexts'
import CharityBasketSection from './CharityBasketSection'

const getFundraisersType = (queryParams) => {
  const search = queryParams.get('text')
  return queryParams.get('type') || (search ? '' : 'oneTime')
}

const buildFilterKey = (queryParams) => {
  const search = queryParams.get('text')
  let fundraisersType = getFundraisersType(queryParams)

  let key = 'projects_fundraisers'
  if (fundraisersType) {
    key += `.[type=${fundraisersType}]`
  }
  if (search) {
    key += `.[search=${search}]`
  }
  return key
}

const getProjectsFromCache = (queryParams) => {
  try {
    const cacheKey = buildFilterKey(queryParams)
    return JSON.parse(localStorage.getItem(cacheKey))
  } catch (e) {
    console.log(e)
  }
  return []
}

const Projects = ({ items, refetch, total, updateFilters, setCacheKey }) => {
  const queryClient = useQueryClient()
  const queryParams = useQueryParams()
  const { t, language } = useTranslation()
  const { navigationPush } = useNavigation()
  const { currency, settings, changeDefaultCurrency } = useCurrencySettings()
  const [isLoading, toggleLoading] = useState(false)
  const [projects, setProjects] = useState([])
  const [filters, setFilters] = useState({
    region: queryParams.get('region'),
    category: queryParams.get('category'),
    text: queryParams.get('text'),
    priceMax: queryParams.get('priceMax'),
    duration: queryParams.get('duration'),
    type: getFundraisersType(queryParams),
  })

  const { giftCardMode } = useGiftCard()
  const { openModal } = useModal()

  const onLoadMore = () => {
    toggleLoading(true)
    refetch()
  }

  /* new projects fetched */
  useEffect(() => {
    setProjects((projects) => {
      if (!projects) {
        return items
      }
      return _.uniqBy([...projects, ...items], 'id')
    })
    toggleLoading(false)
  }, [items])

  // /* update URL params */
  useEffect(() => {
    projects?.map((item) =>
      queryClient.setQueryData(['project_slug', item.slug], item)
    )

    queryParams.update(filters)
    if (queryParams.has('action')) {
      openModal(queryParams.get('action'))
    }
  }, [projects, filters])

  // /* filters updated */
  useEffect(() => {
    setCacheKey(buildFilterKey(queryParams))
    if (projects.length === 0 || filters.category !== null) {
      toggleLoading(true)
      setProjects([])

      updateFilters(filters.type, filters.category)
      return
    }
    if (projects.length === 0) {
      return
    }
    const cachedProjects = getProjectsFromCache(queryParams)
    if (!cachedProjects || cachedProjects.length === 0) {
      toggleLoading(true)
      setProjects([])

      updateFilters(filters.type, filters.category)
      return
    }
    setProjects(cachedProjects)
    updateFilters(filters.type, filters.category)
  }, [filters])

  useEffect(() => {
    /* set projects from cache */
    if (!isMobile) {
      if (queryParams.get('type') === 'oneTime') {
        amplitude.getInstance().logEvent('Web fundraisers oneTime page view')
        console.log('event sent to amplitude Web fundraisers oneTime page view')
      }
      if (queryParams.get('type') === 'recurring') {
        amplitude.getInstance().logEvent('Web fundraisers recurring page view')
        console.log(
          'event sent to amplitude Web fundraisers recurring page view'
        )
      }
    }
    const cachedProjects = getProjectsFromCache(queryParams)
    if (!cachedProjects || cachedProjects.length === 0) {
      toggleLoading(true)
      return
    }
    setProjects(cachedProjects)
  }, [queryParams.get('type')])

  const { isMobile } = useScreenSize()
  if (isMobile) {
    return (
      <>
        <MetaTags
          title={t('meta@funds@title')}
          description={t('meta@funds@description')}
        />
        <MobileFundraisers
          total={total}
          isLoading={isLoading}
          projects={projects}
          filters={filters}
          setFilters={setFilters}
          giftCardMode={giftCardMode}
          fetchMore={onLoadMore}
          hasMore={total > projects?.length}
          length={projects.length}
        />
      </>
    )
  }

  return (
    <>
      <MetaTags
        title={t('meta@funds@title')}
        description={t('meta@funds@description')}
      />
      <AllProductsWrapper
        type="fundraisers"
        filters={filters}
        setFilters={setFilters}
      >
        {giftCardMode && <GiftCardBanner />}
        {/*
        1. THIS LOADING IS BEING SHOW ONLY ONE TIME, BY THE FIRST
        2. IF REQUEST MADE BUT WE HAVE CACHED PROJECTS WE HIDE LOADING
      */}
        {isLoading && (!projects || projects?.length === 0) ? (
          <Flex minH="520px" w="full" align="center" justifyContent="center">
            <Spinner color="blue.400" />
          </Flex>
        ) : (
          <Box minH="720px" pb={10} position="relative">
            {filters.type === PROJECT_TYPE_NON_PROFIT && (
              <CharityBasketSection />
            )}
            {projects?.length > 0 ? (
              <ProjectList
                fetchMore={onLoadMore}
                hasMore={total > projects?.length}
                length={projects.length}
              >
                {_.uniqBy(projects, 'id').map((card) => (
                  <GridItem key={`project-card-${card.id}`} colSpan={1}>
                    <ProjectCard
                      card={card}
                      goalPrice={card.activeGoal?.amount || card.goal}
                      raisedMoney={thousandSeparator(
                        convert({
                          amount: card.amount,
                          currencyRate: settings?.currency[currency.current],
                        }),
                        currency.current
                      )}
                      thousandSeparatorText={thousandSeparator(
                        currency.current === 'AMD'
                          ? card.amount + Number(card.updateAmount)
                          : card.amount /
                              Number(settings?.currency[currency.current]) +
                              Number(card.updateAmount),
                        currency.current
                      )}
                      type="fundraisers"
                      calculatedProjectDurationLeft={calculateProjectDurationLeft(
                        {
                          t,
                          campaignImplementorPeriod_en:
                            card.campaignImplementorPeriod_en,
                          fundraisingType: card.fundraisingType,
                        }
                      )}
                      language={language}
                      currency={currency}
                      settings={settings}
                      changeDefaultCurrency={changeDefaultCurrency}
                      cardStatusText={t(`status@${card.status}`)}
                      cardCategoryText={t(`category@${card.category}`)}
                      editText={t('edit')}
                      unsubscribeText={t('unsubscribe')}
                      monthlyGoalText={t('monthlyGoal')}
                      raisedText={t('raised')}
                      navigationPush={navigationPush}
                      collaborationProposalsText={t(
                        card?.collaboratorsTotal === 1
                          ? 'collaboration proposal'
                          : 'collaboration proposals'
                      )}
                      isBasketApplicable={
                        filters.type === PROJECT_TYPE_NON_PROFIT
                      }
                      collaborationProposalsTextRuEdit={t(
                        'collaboration proposals'
                      )
                        .split('...')[1]
                        .replace('запросов', 'запроса')}
                    />
                  </GridItem>
                ))}
              </ProjectList>
            ) : (
              <Flex
                top="0"
                left="0"
                position="absolute"
                alignItems="center"
                justifyContent="center"
                h="full"
                w="full"
              >
                <Heading as="h2" fontSize="4xl" color="blue.400">
                  {t('noResults')}
                </Heading>
              </Flex>
            )}
          </Box>
        )}
      </AllProductsWrapper>
    </>
  )
}

const withProjects =
  (Component) =>
  ({ ...props }) => {
    const queryParams = useQueryParams()
    const { language } = useTranslation()
    const { user } = useUser()
    const size = 12

    const search = queryParams.get('text')
    const projectQueryObject = projectsQuery({
      fundraisingType: getFundraisersType(queryParams),
      isAdmin: user.isAdmin,
      search,
      language,
    })

    useEffect(() => {
      if (!search && !queryParams.has('type')) {
        queryParams.set('type', 'oneTime')
      }
    }, [search])

    const cacheKey = buildFilterKey(queryParams)
    const { projects, isLoading, setFilters, setCacheKey } = useProjects(
      projectQueryObject,
      true,
      search ? cacheKey : 'fundraisers',
      cacheKey
    )

    const refetch = () => {
      setFilters((filters) => {
        return {
          ...filters,
          from: (filters.from || 0) + size,
        }
      })
    }

    const updateFilters = (type, category) => {
      if (!type && !category) {
        return
      }

      setFilters((prevFilters) => {
        return {
          ...prevFilters,
          ...projectsQuery({
            fundraisingType: type,
            category,
            isAdmin: user.isAdmin,
            search,
            language,
          }),
          from: 0,
        }
      })
    }

    return (
      <Component
        {...props}
        updateFilters={updateFilters}
        items={projects?.items || []}
        cachedItems={projects?.cachedItems}
        total={projects?.total?.value || projects?.total}
        isLoading={isLoading}
        refetch={refetch}
        setCacheKey={setCacheKey}
      />
    )
  }

const areEqual = (prevProps, nextProps) => {
  if (!nextProps.isLoading) {
    // RE-RENDER
    return false
  }
  // PREVENT RENDER
  return true
}

export default withProjects(memo(Projects, areEqual))
