const stripeUseCase = (stripeRepo) => {
  const paymentIntent = (params) => stripeRepo.paymentIntent(params)

  const confirmPayment = (params) => stripeRepo.confirmPayment(params)
  const connectAccount = (params) => stripeRepo.connectAccount(params)

  const continueConnectAccount = (params) =>
    stripeRepo.continueConnectAccount(params)

  const retrieveAccount = (params) => stripeRepo.retrieveAccount(params)

  const createCustomer = (params) => stripeRepo.createCustomer(params)

  const attachPmToCustomer = (params) => stripeRepo.attachPmToCustomer(params)

  const retrieveCustomer = (params) => stripeRepo.retrieveCustomer(params)

  const createSource = (params) => stripeRepo.createSource(params)

  const sendOneTimeEmail = (params) => stripeRepo.sendOneTimeEmail(params)

  return {
    paymentIntent,
    connectAccount,
    continueConnectAccount,
    retrieveAccount,
    confirmPayment,
    createCustomer,
    attachPmToCustomer,
    retrieveCustomer,
    createSource,
    sendOneTimeEmail,
  }
}

export default stripeUseCase
