import React, { useState } from 'react'
import { Box, Heading, Text, Flex, Button } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { authUseCase } from 'core/factories'
import { useTranslation } from 'contexts/TranslationContext'
import { Link, useNavigation } from 'pages'
import AuthWrapper from 'components/AuthWrapper'
import { InputField } from 'components/InputField'
import { ReactComponent as Close } from 'assets/icons/closeBlack.svg'
import MobileForgotPassword from './MobileForgotPassword'
import useCustomToast from 'hooks/useCustomToast'
import { useScreenSize } from 'contexts'

const ForgotPassword = () => {
  const toast = useCustomToast()
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
  })
  const [isLoading, toggleLoading] = useState(false)
  const { navigationPush } = useNavigation()

  const onRestore = async (data) => {
    if (isLoading) return
    toggleLoading(true)
    try {
      await authUseCase.forgetPassword(data.email)
      toast({
        status: 'success',
        title: t('forgotPassword@successMessage'),
        isClosable: true,
        position: 'bottom-right',
        duration: 5000,
      })
      navigationPush(`/reset-password?email=${data.email}`)
    } catch (e) {
      toggleLoading(false)
      toast({
        status: 'error',
        title: t(`forgotPassword@${e.code || e.__type || e.name}`),
        isClosable: true,
        duration: 5000,
      })
    }
  }

  const { isMobile } = useScreenSize()
  if (isMobile) {
    return (
      <MobileForgotPassword
        register={register}
        onRestore={onRestore}
        errors={errors}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
      />
    )
  }

  return (
    <AuthWrapper
      ceoCompany="CEO of Kamala"
      ceoName="Samvel Papoyan"
      ceoDescription="“reArmenia is short description Though I’ll stop short of calling it a rule lorem ipsum`.”"
      url="/assets/images/auth.jpg"
    >
      <Box w="full" maxW="lg">
        <Link to="/">
          <Button
            display={{ base: 'inline-flex', md: 'none' }}
            position="absolute"
            top="20px"
            right="20px"
            variant="transparent"
          >
            <Close width="20px" />
          </Button>
        </Link>
        <Heading
          as="h1"
          fontSize="5xl"
          fontWeight="extrabold"
          letterSpacing="tight"
          textAlign="center"
        >
          {t('forgotPassword@heading')}
        </Heading>
        <Box
          minW={{ md: '350px' }}
          rounded="xl"
          bg="white"
          pt={{ base: '8', md: '12' }}
          pb="8"
        >
          <form onSubmit={handleSubmit(onRestore)}>
            <InputField
              size="lg"
              errorMessage={t('forgotPassword@emailErrorMessage')}
              label={t('forgotPassword@email')}
              isInvalid={!!errors.email}
              {...register('email', {
                required: true,
                pattern: /\S+@\S+\.\S+/,
              })}
            />
            <Button
              marginTop="2rem"
              w="full"
              variant="blue"
              type="submit"
              isLoading={isLoading}
            >
              {t('forgotPassword@submit')}
            </Button>
          </form>
        </Box>
        <Flex
          display={{ base: 'flex', sm: 'none' }}
          spacing="4"
          direction={{ base: 'column-reverse', md: 'row' }}
          mt="6"
          align="center"
          justify="space-between"
        >
          <Text color="gray.700" fontSize="sm" fontWeight="semibold">
            {t('forgotPassword@haveAnAccount')}{' '}
            <Button fontSize="sm" color="orange.400">
              <Link to="/sign-in">{t('forgotPassword@signIn')}</Link>
            </Button>
          </Text>
        </Flex>
      </Box>
      <Flex
        display={{ base: 'none', sm: 'flex' }}
        position="absolute"
        bottom="2rem"
        spacing="4"
        direction={{ base: 'column-reverse', md: 'row' }}
        mt="6"
        align="center"
        justify="space-between"
      >
        <Text color="gray.700" fontSize="sm" fontWeight="semibold">
          {t('forgotPassword@haveAnAccount')}{' '}
          <Button fontSize="sm" color="orange.400">
            <Link to="/sign-in">{t('forgotPassword@signIn')}</Link>
          </Button>
        </Text>
      </Flex>
    </AuthWrapper>
  )
}

export default ForgotPassword
