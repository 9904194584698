import React from 'react'
import { Link } from 'pages'
import { Text, Flex, Box, Heading, Container, Img } from '@chakra-ui/react'
import Navbar from 'components/Navbar'
import { Footer } from 'components/Footer'

const Terms = () => {
  return (
    <Flex
      direction="column"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Navbar title="Terms and Conditions" />
      <Box mb="3rem" bg="gray.800" as="section" position="relative" w="full">
        <Box h="full" py="32" position="relative" zIndex={1}>
          <Flex
            h="full"
            alignItems="center"
            maxW={{ base: 'xl', md: '4xl' }}
            mx="auto"
            px={{ base: '6', md: '8' }}
            color="white"
          >
            <Box maxW="3xl">
              <Heading
                as="h1"
                fontSize={{ base: '5xl', md: '6xl' }}
                fontWeight="extrabold"
              >
                Terms and Conditions
              </Heading>
            </Box>
          </Flex>
        </Box>
        <Flex
          id="image-wrapper"
          position="absolute"
          insetX="0"
          insetY="0"
          w="full"
          h="full"
          overflow="hidden"
          align="center"
        >
          <Box position="relative" w="full" h="full">
            <Img
              src="https://images.unsplash.com/photo-1585909695677-2b0492f96e3b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              alt="Main Cover"
              w="full"
              h="full"
              objectFit="cover"
              objectPosition="center"
              position="absolute"
            />
            <Box
              position="absolute"
              w="full"
              h="full"
              bg="linear-gradient(134.85deg, #002D72 0%, rgba(0,45,114,0) 100%)"
            />
          </Box>
        </Flex>
      </Box>
      <Container pb="5rem" px={{ base: '6', md: '8' }} maxW="4xl">
        {/* <Flex
                alignItems="baseline"
                my="4"
                w="full">
                <Box as="span">
                    1. THE PLATFORM / REARMENIA
                </Box>
                <Box
                    mx="2"
                    borderBottom="1px dotted black"
                    flex="1"/>
                    2
            </Flex>
            <Flex
                alignItems="baseline"
                my="4"
                w="full">
                <Box as="span">
                    1.1 PAYMENTS, FEES AND TIPS TO REARMENIA
                </Box>
                <Box
                    mx="2"
                    borderBottom="1px dotted black"
                    flex="1"/>
                    3
            </Flex>
            <Flex
                alignItems="baseline"
                my="4"
                w="full">
                <Box as="span">
                    1.2 REARMENIA IS AN ADMINISTRATIVE PLATFORM ONLY
                </Box>
                <Box
                    mx="2"
                    borderBottom="1px dotted black"
                    flex="1"/>
                    4
            </Flex>
            <Flex
                alignItems="baseline"
                my="4"
                w="full">
                <Box as="span">
                    1.3 REARMENIA'S EFFORTS
                </Box>
                <Box
                    mx="2"
                    borderBottom="1px dotted black"
                    flex="1"/>
                    4
            </Flex>
            <Flex
                alignItems="baseline"
                my="4"
                w="full">
                <Box as="span">
                    2. ANY USER
                </Box>
                <Box
                    mx="2"
                    borderBottom="1px dotted black"
                    flex="1"/>
                    5
            </Flex>
            <Flex
                alignItems="baseline"
                my="4"
                w="full">
                <Box as="span">
                    2.1 INTELECTUAL PROPERTY
                </Box>
                <Box
                    mx="2"
                    borderBottom="1px dotted black"
                    flex="1"/>
                    5
            </Flex>
            <Flex
                alignItems="baseline"
                my="4"
                w="full">
                <Box as="span">
                    3. PROJECT CREATOR
                </Box>
                <Box
                    mx="2"
                    borderBottom="1px dotted black"
                    flex="1"/>
                    6
            </Flex>
            <Flex
                alignItems="baseline"
                my="4"
                w="full">
                <Box as="span">
                    4. DONOR
                </Box>
                <Box
                    mx="2"
                    borderBottom="1px dotted black"
                    flex="1"/>
                    7
            </Flex>
            <Flex
                alignItems="baseline"
                my="4"
                w="full">
                <Box as="span">
                    5. INITIATOR
                </Box>
                <Box
                    mx="2"
                    borderBottom="1px dotted black"
                    flex="1"/>
                    8
            </Flex>
            <Flex
                alignItems="baseline"
                my="4"
                w="full">
                <Box as="span">
                    6. IMPLEMENTER
                </Box>
                <Box
                    mx="2"
                    borderBottom="1px dotted black"
                    flex="1"/>
                    9
            </Flex>
            <Flex
                alignItems="baseline"
                my="4"
                w="full">
                <Box as="span">
                    7. COLLABORATOR
                </Box>
                <Box
                    mx="2"
                    borderBottom="1px dotted black"
                    flex="1"/>
                    10
            </Flex>
            <Flex
                alignItems="baseline"
                my="4"
                w="full">
                <Box as="span">
                    8. SERVICE PROVIDER
                </Box>
                <Box
                    mx="2"
                    borderBottom="1px dotted black"
                    flex="1"/>
                    11
            </Flex> */}
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          1. The Platform / reArmenia
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          By using this website (rearmenia.com) operated by “ReArmenia”
          charitable Foundation (registered charity the Republic of Armenia) and
          reArmenia Inc. organization (a 501(c)(3) organization (EIN:93-2901346)
          registered in the United States) (together with, its representatives,
          consultants, employees, officers, and directors—collectively,
          “reArmenia,” “we,” or “us”), you’re agreeing to these legally binding
          rules (the “Terms”).
        </Text>
        <Text textAlign="justify" lineHeight="8">
          Rearmenia.com is an online donation and collaboration platform (the
          “Platform”) to:
        </Text>
        <Text textAlign="justify" lineHeight="8">
          (i) as to the donation part of the Platform - allow an individual,
          legal entity, for-profit (only in the case of ReArmenia Charitable
          Foundation) or non-profit organization (the "Project creator") to
          post their fundraising project \ campaign ("Project") (aimed at having
          a social impact) to accept charity donations ("Donations") from donors
          ("Donors"). Project creators will not provide goods or services in
          exchange for Donations, instead, Project creators may offer perks to
          Donors in thanks for the contribution of funds;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          (ii) as to collaboration part of the Platform - allow an individual,
          legal entity, for-profit (only in the case of ReArmenia Charitable
          Foundation) or non-profit organization (the “Initiator”) to post their
          initiative (an existing problem and its possible solution aimed at
          having a social impact) to find partners (implementers and
          collaborators) for cooperation and solving the problem.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          Should any User violate any of these Terms, we (reArmenia) reserve the
          right to terminate, suspend, or take any other action related to the
          corresponding User’s account at our sole discretion. The User may not
          access the Platform if the latter one has been prohibited from doing
          so by us.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          To protect the health and integrity of the Platform and to help ensure
          that Project creators, Donors, Initiators and all other Users enjoy a
          safe and secure environment, reArmenia reserves the following rights:
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; We can make changes to the Platform without notice or
          liability.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; We have the right to decide who is eligible to use the
          Platform. We may cancel accounts or decline to offer services on the
          Platform at any time (especially if you’re abusing them). We can
          change our eligibility criteria at any time.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; We have the right to reject, cancel, pause, interrupt, remove,
          terminate or suspend any Project or Initiative at any time and for any
          reason.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          ReArmenia is not liable for any damages as a result of any of these
          actions.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          It is our general policy not to comment on the reasons for any such
          action. However, by our sole discretion, if we impose restrictions on
          or limit a verified account holder’s access to any of the services on
          the Platform or if we intend to cancel a verified account, we may let
          the account holder know the reasons for this action and how they can
          solve any issues (where appropriate) unless we’re prohibited from
          doing so in the interest of safety.
        </Text>
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          1.1 Payments, fees, and tips to reArmenia
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          ReArmenia and its partner organization in the USA, reArmenia Inc. are
          not payment processors. Instead, reArmenia uses third-party payment
          processing partners to process Donations (“Payment Processor”). You
          acknowledge and agree that we exchange information with Payment
          Processors and any third party in order to facilitate the activity of
          the Platform.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          Project creators are not charged any fee as a portion of the Donations
          they raise on the Platform. User fee for posting Project and
          Initiative is 1000 AMD (Armenian Dram), but provided free of charge
          taking into consideration that reArmenia’s and the Platform’s
          operations aimed at having a social impact.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          Project creators are charged a transaction fee by the Payment
          Processor. Transfer fees, or bank delivery fees, are also applied each
          time the Payment Processor sends funds to a Project creator’s bank
          account.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          reArmenia’s and correspondingly the Platform’s self-sustainability is
          based on tips from Donors. Rather than charging a commission fee from
          Donation transactions, reArmenia enables Donors to add a tip on a
          voluntary basis. Tips contributed to reArmenia by Donors are
          non-refundable.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          There are two types of fundraising options available for the Project
          creator on the Platform - “Fixed Funding” (all or nothing) and
          “Flexible Funding” (keep it all). With a fixed funding project, the
          Project creator only keeps the funds raised if the funding goal is
          met. If a Project (fundraising campaign) does not meet its goal by its
          deadline, all its Donors will be refunded or, upon written consent of
          the Donors (by email), corresponding donations may be transferred to
          reArmenia’s account to implement reArmenia’s statutory goals and/or to
          another ongoing Project/s. With a flexible funding project, whether
          the Project creator meets the goal or not, the latter one can receive
          all the amount that has been achieved throughout the duration of the
          Project (fundraising campaign), provided that (in case the Project
          does not meet its goal by its deadline) the Project creator can
          provide reArmenia with confirmation and proof that the funds raised
          can be spent on the implementation of a certain part of the Project
          with the corresponding (sufficient) impact.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          Project creators are not guaranteed to immediate receive Donations
          made to a Project, even after the Project has ended and the
          fundraising goal has been reached. There may be a delay between the
          end of a Project (fundraising campaign) and remittance of Donations to
          a Project creator for a number of reasons including, but not limited
          to, refunds, chargeback disputes, fraud, Terms or other policy
          violations, or any other situation.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          Remittance of collected Donations to a Project creator will be carried
          out in accordance with the manner and terms established by the
          corresponding agreement between reArmenia and the Project creator.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          In the case of donations through ArCa and other payment systems, all
          donations are directed to the transit account of the Payment Processor
          (reArmenia’s partner bank in Armenia) and then remitted to the Project
          creator’s account. It is our general policy to carry out remittance of
          the collected Donations to Project creators in installments, as the
          Project’s milestones are successfully implemented and the Project
          creator’s related reporting materials are received by reArmenia.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          reArmenia Inc. partners with other companies (such as Stripe) for
          payment processing, only in cases where Project Creators have Charity
          status. When you donate or create a project, you’re also agreeing to
          Stripe’s terms of service.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          Payment Processor imposes the 2.9% + 30¢ per successful charge fee and
          0,25%+ $0.25 withdrawal fee from the accounts in cases when the
          payment is carried out via Stripe. Payment Processor’s credit card
          processing fees may apply to any donation.
        </Text>
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          1.2 reArmenia is an administrative platform only
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          <Link style={{ color: '#012f73', fontWeight: '600' }} to="/">
            reArmenia.com
          </Link>{' '}
          is an administrative platform only. The Platform facilitates the
          Projects and permits Donors to make donations to the Project creators.
          reArmenia is not a broker, agent, financial institution, or creditor.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          Regardless of anything reArmenia isn’t liable for any damages or
          losses related to your use of the Platform. We don’t become involved
          in disputes between users, or between users and any third party
          relating to the use of the Platform.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          The Platform is offered to help Project creators raise funds and
          Initiators find collaborators/implementors. reArmenia merely provides
          the technology to allow Project creators to connect with Donors and
          Initiators to connect with collaborators/implementors. We do not
          support (endorse) any Project, Project creator, Initiator, User, or
          cause. We make necessary efforts to check the information provided by
          Project creators, Initiators and other Users (as mentioned below), but
          we make no guarantee that any information provided through the
          Platform is accurate. No content is intended to provide financial,
          legal, tax, or other professional advice.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          The Platform or its services may be unavailable for scheduled
          maintenance and other reasons, including unplanned outages and other
          malfunctions. We are not responsible if the Platform or its services
          are unavailable, or if you lose any data, information, or User content
          for any reason.
        </Text>
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          1.3 reArmenia’s efforts
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          reArmenia makes the necessary efforts to investigate the Project
          and/or Project creator, Initiator or User Content, check the Projects
          for their relevance, the optimality of the solution proposed by the
          Project creator, and whether the Project creator has a team, able to
          successfully implement the proposed Project. Within the framework of
          checking the followings are carried out: verification of the statutory
          documents of the Project creator, verification of the authorization of
          persons representing the interests of the Project creator,
          verification of the submitted Project budget, evaluation by advisors
          and other checks. As we investigate the Project / Project creator or
          User Content, we may consider all available material including but not
          limited to social media, related news, and any other information that
          we, in our sole discretion, deem relevant in our review.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          As mentioned, we assess each Project by an independent advisory board,
          for which we involve advisors (professionals) from the corresponding
          industry. The scope of checks carried out for each Project may differ
          depending on industry/area.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          Project creators are also required to provide reArmenia and Donors
          with Project / campaign progress and financial reports by the date (in
          a timely manner) specified by reArmenia. The Platform and Donors
          depend heavily upon progress and financial reports to evaluate
          progress. Failure to submit progress or financial reports or any
          related information and document in a timely manner or incorrect
          information in these reports and documents can result termination or
          pause of the Project by reArmenia’s sole discretion.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          We take possible fraudulent activity and the misuse of funds raised
          very seriously. If you have reason to believe that a Project creator
          is not raising or using the funds for their stated purpose, please
          send us an email at{' '}
          <a
            style={{ fontWeight: '600', color: '#012f73' }}
            href="mailto:info@rearmenia.com"
          >
            info@rearmenia.com
          </a>{' '}
          to alert our team of this potential issue and we will investigate.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          We do our best to achieve that the Donations will be used in
          accordance with the relevant Project’s purpose prescribed by a Project
          creator, but we do not guarantee that.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          We also do not guarantee that a Project will obtain a certain amount
          of Donations or any Donations at all.
        </Text>
        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          2. Any User
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          To use many services of the Platform, you will need to register and
          create an account with a username and password. You must provide us
          with accurate and complete information, and you must keep your account
          information updated. You may not impersonate anyone else, choose an
          offensive username or violate any individual’s rights. You are solely
          responsible for maintaining the confidentiality of your account and
          for all activities associated with or occurring under your account.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          You acknowledge that all information and content accessed by you using
          the Platform is at your own risk. You are solely responsible for
          compliance with all applicable law in relation to your use of the
          Platform. You are further solely responsible for all User Content that
          you upload, post, publish, display, transmit or otherwise use. You
          agree to fully cooperate with any request we make for evidence we deem
          necessary to verify your compliance with these Terms.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          If you suspect or discover any unauthorized use of your account, you
          should notify reArmenia immediately by contacting us at{' '}
          <a
            style={{ fontWeight: '600', color: '#012f73' }}
            href="mailto:info@rearmenia.com"
          >
            info@rearmenia.com
          </a>
          . We are not responsible for any loss or damage arising from your
          failure to comply with the foregoing requirements or as a result of
          the use of your account with or without your knowledge.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          Don’t do any of the following things on the Platform:
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; Don’t break the law. Don’t take any action that infringes or
          violates other people’s rights, violates the law, or breaches any
          contract or legal duty you have toward anyone.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; Don’t lie to people. Don’t post information you know is false,
          misleading, or inaccurate. Don’t do anything deceptive or fraudulent.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; Don’t victimize anyone. Don’t do anything threatening,
          abusive, harassing, defamatory, libelous, tortious, obscene, profane,
          or invasive of another person’s privacy.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; Don’t spam. Don’t distribute unsolicited or unauthorized
          advertising or promotional material, or any junk mail, spam, or chain
          letters. Don’t run mail lists, listservs, or any kind of
          auto-responder or spam on or through the Platform.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; Don’t abuse other users’ personal information. When you use
          the Platform and especially if you create a Project or Initiative you
          may receive information about other Platform users, including things
          like their names, email addresses, and postal addresses. This
          information is provided for the purpose of participating in a relevant
          Project or Initiative: don’t use it for other purposes, and don’t
          abuse it.
        </Text>
        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          2.1 Intellectual property
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          reArmenia doesn’t own the content you submit to us (the “Content”).
          When you use any of the services of the Platform, incl. (but not
          limited to) submit a Project for review, or launch a Project or
          Initiative on the Platform, you agree to the following terms:
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; We can use the Content you’ve submitted. You grant to us, and
          others acting on our behalf, the worldwide, non-exclusive, perpetual,
          irrevocable, royalty-free, sublicensable, transferable right to use,
          exercise, commercialize, and exploit the copyright, publicity,
          trademark, and database rights with respect to your Content;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; When we use the Content, we can make changes, like editing or
          translating it. You grant us the right to edit, modify, reformat,
          excerpt, delete, or translate any of your Content;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; You won’t submit stuff you don’t hold the copyright for
          (unless you have permission). Your Content will not contain
          third-party copyrighted material, or material that is subject to other
          third-party proprietary rights unless you have permission from the
          rightful owner of the material, or you are otherwise legally entitled
          to post the material (and to grant reArmenia all the license rights
          outlined here);
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; Any royalties or licensing on your Content are your
          responsibility. You will pay all royalties and other amounts owed to
          any person or entity based on your Content;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; You guarantee that if we use your Content, we’re not violating
          anyone’s rights or copyrights;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; You’re responsible for the information you post on the
          Platform. All information submitted to the Platform \ reArmenia,
          whether publicly posted or privately transmitted, is the sole
          responsibility of Content creator;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; We’re not responsible for mistakes in your Content. ReArmenia
          will not be liable for any errors or omissions in any Content.
        </Text>
        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          3. Project creator
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          You, as a Project creator, represent, warrant, and covenant that
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; All information you provide in connection with your Project or
          beneficiary is accurate, complete, and not likely to deceive reArmenia
          or any User of the Platform;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; Register on the Platform using your true identities, including
          your name, address, and any image or video purporting to depict the
          Project creator or the Project. You agree to keep registration
          information current and up to date. If reArmenia at any time discovers
          that the information you provided about you or the purpose of your
          Project is incorrect or violates any of these Terms, your Project may
          be suspended and/or terminated with immediate effect;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; All Donations contributed to your Project will be used solely
          as described in the materials that you post or otherwise provide and
          for charitable purposes only if you are a non-profit organization;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; If you withdraw donations on behalf of someone other than you
          (i.e., the Beneficiary), all Donations will be given to and/or spent
          on behalf of the Beneficiary;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; It is your responsibility to determine what, if any, taxes
          apply to the Donations you receive through your use of the Platform.
          It is solely your responsibility to assess, collect, report, or remit
          the correct tax, if any, to the appropriate tax authority;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; You will not infringe the rights of others;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; You will comply with all relevant and applicable law and
          financial reporting obligations, including but not limited to laws and
          regulations relating to registration, tax reporting, political
          contributions, and asset disclosures for your project;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; To the extent you share with us any personal data of any third
          party for any purpose, including the names, email addresses, and phone
          numbers of your personal contacts, you have the authority (including
          any necessary consents), as required under applicable law, to provide
          us with such personal data and allow us to use such personal data for
          the purposes for which you shared it with us. You authorize reArmenia,
          and reArmenia reserves the right to provide information relating to
          your Project to any third parties within reArmenia’s activities;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; You agree with and confirm the provisions of the Paragraph 2.1
          of this Terms (Intellectual property).
        </Text>
        <Text textAlign="justify" lineHeight="8">
          You are entrusted with personal data shared by Donors, Experts and
          Collaborators and you are bound by certain responsibilities and
          liabilities to ensure that this data is handled in accordance with the
          law, terms and conditions, and in a sustainable, ethical manner. These
          responsibilities include, but are not limited to:
        </Text>
        <Text textAlign="justify" lineHeight="8">
          -Compliance with applicable law: You are required to handle personal
          data in compliance with all relevant data protection laws, including
          but not limited to GDPR, CCPA, or any other applicable regulations
          depending on the user's location. They must adhere to legal
          requirements concerning data collection, processing, and storage.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          -You are expected to use the shared personal information in a
          sustainable and ethical manner. This means they should avoid practices
          that may harm Donors, Experts and Collaborators or exploit their data
          for undue advantage. Ethical considerations include respecting Donors,
          Experts and Collaborators' privacy, not engaging in intrusive or
          harassing communication, and refraining from using personal
          information for any harmful or malicious purposes.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          -You are prohibited from sharing Donor’s, Expert’s, Collaborator’s
          personal information with any third parties without explicit consent
          from the corresponding Donor, Expert, Collaborator. This prohibition
          includes not selling, trading, or otherwise transferring personal data
          to external entities or individuals for any purpose. Any such activity
          would be considered a breach of trust and a violation of the Platform
          policies.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          Don’t offer prohibited Projects. Don’t offer any Project that is
          illegal, violate any of policies, rules, or guidelines, or violate any
          applicable law, statute, ordinance or regulation.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          You may receive information about Donors, including things like their
          names, email addresses, and postal addresses. This information is
          provided for the purpose of participating in (incl. donating) your
          project: don’t use it for other purposes, and don’t abuse it.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          The Project verification is carried out in accordance with the
          internal documents and policies of reArmenia. Please evaluate your
          time and effort possibilities in advance before applying to reArmenia
          to post and run your Project on the Platform and be prepared for a
          possible refusal due to non-compliance with Terms, reArmenia’s
          policies, internal rules, or strategy.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          After posting a Project on the Platform Project creators are also
          required to provide Donors and reArmenia with Project progress and
          finance reports (in a timely manner) and are due on the date specified
          by reArmenia. The Platform and Donors depend heavily upon progress and
          finance reports to evaluate progress. Failure to submit progress or
          finance reports or any other related information or documents in a
          timely manner or incorrect information in these reports or documents
          can result in a termination of the Project by reArmenia’s sole
          discretion.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          In the event of Project termination by reArmenia (for any reason), the
          Project creator is obliged to immediately refund all the Donations
          raised (if any) by the termination moment to the corresponding Donors
          and/or transfer related donations to reArmenia’s bank account
          (depending on reArmenia’s Email notification).
        </Text>
        <Text textAlign="justify" lineHeight="8">
          The Project creator undertakes to disclose to reArmenia any conflict
          of interests along with any personal interests, affiliation, or
          activity with reArmenia and/or Service provider.
        </Text>
        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          3.1 Content exclusion article
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          The following are examples of Project/Initiative/User Content that
          cannot be accepted by reArmenia:
        </Text>
        <Text textAlign="justify" lineHeight="8">
          ∙ any military-related Projects and Initiatives, as
        </Text>
        <Text textAlign="justify" lineHeight="8">
          (i) reArmenia cannot ensure transparency for such projects;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          (ii) promoting Projects and Initiatives of a military nature on social
          media may be deemed an illegal activity in some countries
        </Text>
        <Text textAlign="justify" lineHeight="8">
          ∙ any Projects or Initiatives in support of or in opposition to any
          political party or individual candidate;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          ∙ promotion of drugs, narcotics, steroids, controlled substances,
          pharmaceuticals or similar products or therapies that are either
          illegal, prohibited or enjoined by an applicable regulatory body;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          ∙ promotion of investment schemes, loans, equity contracts (i.e.
          investment opportunities or other related services);
        </Text>
        <Text textAlign="justify" lineHeight="8">
          ∙ promotion of gambling, betting, lottery and other related content or
          services;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          ∙ content that reflects or promotes behavior that reArmenia deems, in
          its sole discretion, to be an abuse of power or in support of hate,
          violence, harassment, bullying, discrimination, terrorism or
          intolerance;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          ∙ any other activity that reArmenia may deem, in its sole discretion,
          to be unacceptable.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          This list is not exhaustive and we reserve the right to refuse any
          application, freeze/remove any Project or Initiative and/or
          investigate any User who, in reArmenia’s sole discretion, violates any
          of the terms or spirit of these Terms and/or for which transparency
          cannot be ensured.
        </Text>
        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          4. Donor
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          All Donations are at your own risk. We (reArmenia) do our best to
          verify the information that Project creators or any other User supply,
          and to achieve that the Donations will be used in accordance with any
          Project purpose prescribed by a Project creator, but reArmenia is not
          responsible for any offers, promises or promotions made or offered by
          Project creators or any other Platform User and you understand and
          agree that reArmenia shall not be responsible for the use of your
          donations or the amount of funds raised for the Project creator.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          We expressly disclaim any liability or responsibility for the outcome
          or success of any Project. You, as a Donor, must make the final
          determination as to the value and appropriateness of contributing to
          any Project creator.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          If you have reason to believe that a Project creator is not raising or
          using the funds for the stated purpose, please use{' '}
          <a
            style={{ fontWeight: '600', color: '#012f73' }}
            href="mailto:info@rearmenia.com"
          >
            info@rearmenia.com
          </a>{' '}
          to alert our team of this potential issue and we will investigate.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          Donors shall provide reArmenia (if any corresponding reArmenia’s email
          notification) with information, necessary for the fulfillment by
          reArmenia of its duties, incl. providing necessary information to the
          authorities.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          Donor acknowledges and agrees that Donor’s personal information can be
          shared with the corresponding Project creator to which the Donor
          donates and other third parties, involved in the implementation of the
          corresponding Project and may be used by such Project creator or third
          party within the framework of the corresponding Project. reArmenia is
          not responsible, and shall not be liable, for any Project creator’s or
          third party’s use of any Donor’s information.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          In the event of the Project termination by the reason of violation by
          the Project creator of this Terms or other reArmenia’s policies,
          reArmenia will take necessary efforts (with no guarantee) to refund
          the donations contributed by the Donor to the corresponding Project
          creator.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          When a Donation is due to be refunded to Donor in accordance with this
          Terms (by any reason) reArmenia has the right, upon written consent of
          the Donor (by email) or if there is no reply to the correspodindg
          email request within 10 calendar days, to organize transferring of the
          corresponding donation to reArmenia’s account to implement reArmenia’s
          statutory goals and/or to another ongoing Project/s.
        </Text>
        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          5. Initiator
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          You, as an Initiator, post (initiate) on the Platform the existing
          problem and its possible solution aimed at having a social impact to
          find partners (collaborators) for cooperation and solving
          (Initiative).
        </Text>
        <Text textAlign="justify" lineHeight="8">
          You, as an Initiator represent, warrant, and covenant that:
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; All information you provide in connection with your Initiative
          is accurate, complete, and not likely to deceive reArmenia or any User
          of the Platform;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; Register on the Platform using your true identities, including
          your name, address, and any image or video purporting to depict the
          problem and its solution. You agree to keep registration information
          current and up to date. If reArmenia at any time discovers that the
          information you provided about you or the purpose of your initiative
          is incorrect, it doesn’t meet reArmenia’s policies or violates any of
          these Terms, your initiative may be rejected, suspended, and/or
          terminated with immediate effect;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; Before submitting an Initiative application on the Platform,
          you undertake to make sure that the issue/problem is valid and
          relevant;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; You will maintain (administer) your Initiative page on the
          Platform (including timely and efficiently filling with the necessary
          information), interact with Platform Users efficient and friendly;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; You will timely respond to notifications and proposals
          submitted by implementers and collaborators after the Initiative is
          posted on the Platform;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; You grant us the right to edit, modify, reformat, excerpt,
          delete, or translate any of your content on the Platform;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; You’re responsible for the stuff you post. All information
          submitted by you to the Platform or reArmenia directly, whether
          publicly posted or privately transmitted, is your sole responsibility;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; We’re not responsible for mistakes in your content. reArmenia
          will not be liable for any errors or omissions in any of your content;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; You agree with and confirm the provisions of the Paragraph 2.1
          of this Terms (Intellectual property).
        </Text>
        <Text textAlign="justify" lineHeight="8">
          Don’t place a prohibited initiative. Don’t offer any initiative that
          is illegal, violate any of policies, rules, or guidelines, or violate
          any applicable law, statute, ordinance, or regulation.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          To run an initiative, you fill an application on the Platform and
          provide reArmenia (if there is a corresponding request) with all
          necessary documents, information, and materials (including for the
          purpose of conducting compliance and trust and safety procedures).
        </Text>
        <Text textAlign="justify" lineHeight="8">
          The Initiative verification is carried out in accordance with the
          internal documents and policies of reArmenia. Please evaluate your
          time and effort possibilities in advance before applying your
          Initiative on the Platform and be prepared for a possible refusal of
          your Initiative due to non-compliance with Terms, reArmenia’s
          policies, internal rules, or strategy.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          You may receive information about potential Implementers and
          Collaborators, including things like their names, email addresses, and
          postal addresses. This information is provided for the purpose of
          cooperating and participating in your Initiative: don’t use it for
          other purposes, and don’t abuse it.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          You, as an Initiator, undertake to objectively and reasonably select
          and appoint an Implementor of the Initiative in order to implement the
          Initiative you have created (to solve the problem). You also may
          become an implementor or collaborator yourself if you objectively and
          reasonably have all the necessary means and resources.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          reArmenia has the right to inspect the Implementer, proposed by the
          Initiator, to test his ability to timely and efficiently perform the
          proposed work / provide the proposed services and, based on the
          results of such inspection, refuse the Initiator to appoint the
          corresponding Implementer with a proposal to submit another candidate.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          Initiator undertakes to disclose to reArmenia any conflict of
          interests along with any personal interests, affiliation or activity
          with reArmenia and/or Implementer.
        </Text>
        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          6. Implementer
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          You, as an Implementer obliges to implement/organize the
          implementation of the initiative, placed on the Platform, as you
          objectively and reasonably have all necessary means and resources for
          that.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          The Implementer is responsible for the timely and complete
          implementation of the Initiative (solving the existing problem)
          according to the solution proposed by the corresponding initiator,
          both independently and with the involvement of collaborators,
          remaining responsible to donors and reArmenia for timely and
          high-quality implementation of the Initiative.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          Implementer provides reArmenia (if there is a corresponding request)
          with all necessary documents, information and materials for the
          purpose of conducting compliance and trust and safety procedures and
          also to verify that the Implementer has all the necessary resources to
          successfully implement the initiative.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          You, as an Implementor, undertake to objectively and reasonably select
          and appoint a Collaborator/-s (if needed) for the Initiative in order
          to successfully implement the Initiative you are responsible for. You
          may also implement the Initiative without involving of Collaborators
          in case you objectively and reasonably have all necessary means and
          resources to do so.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          reArmenia has the right to inspect the Collaborator/-s, proposed by
          the Implementor, to test his/their ability to timely and efficiently
          perform the proposed work / provide the proposed services and, based
          on the results of such inspection, refuse the Implementor to appoint
          the corresponding Collaborator with a request to submit another
          candidate.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          The Implementor is obliged to timely respond to notifications and
          proposals submitted by Collaborators after the initiative is posted on
          the Platform;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          When an Initiative is completed (all the necessary parties for the
          implementation of the Initiative are appointed and organized) and, if
          the Initiative needs to be placed on the Platform to raise funds
          (donations) for implementation, The Implementor becomes a Project
          creator, unless otherwise agreed with reArmenia.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          You, as an Implementor represent, warrant, and covenant that:
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; All information you provide on/through the Platform is
          accurate, complete, and not likely to deceive reArmenia or any User of
          the Platform;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; Register on the Platform using your true identities, including
          your name, address. You agree to keep registration information current
          and up to date. If reArmenia at any time discovers that the
          information you provided about you is incorrect, doesn’t meet
          reArmenia’s policies, or violates any of these Terms, your proposal
          may be rejected, your profile (account) may be terminated with
          immediate effect;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; You will maintain (administer) your profile page on the
          Platform (including timely and efficiently filling with the necessary
          information), interact with all users through the Platform efficient
          and friendly;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; You grant us the right to edit, modify, reformat, excerpt,
          delete, or translate any of your content on the Platform;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; You’re responsible for the stuff you post. All information
          submitted to the Platform or reArmenia directly, whether publicly
          posted or privately transmitted, is the sole responsibility of the
          person from whom that content originated;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; We’re not responsible for mistakes in your content. reArmenia
          will not be liable for any errors or omissions in any of your content.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          Please evaluate your time and effort possibilities, means, and
          resources in advance before applying for implementation of initiative
          on the Platform and be prepared for a possible refusal due to
          non-compliance with Terms, reArmenia’s policies, internal rules, or
          strategy.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          The Implementer undertakes to disclose to reArmenia any conflict of
          interests along with any personal interests, affiliation, or activity
          with reArmenia and/or Collaborator.
        </Text>
        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          7. Collaborator
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          You, as a Collaborator obliges to collaborate with the corresponding
          Implementor and other collaborators (if any) to collectively implement
          the Initiative, placed on the Platform, as you objectively and
          reasonably have all necessary means and resources for that.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          Collaborator is responsible for the timely and complete implementation
          of a certain part of the corresponding Initiative according to the
          solution proposed by the Initiator, remaining responsible to
          Implementor.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          Collaborator provides Implementor and reArmenia (if there is a
          corresponding request) with all necessary documents, information, and
          materials for the purpose of conducting compliance and trust and
          safety procedures and also to verify that the Collaborator has all the
          necessary resources to successfully implement a certain part of the
          Initiative.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          Collaborator obliges to timely respond to users (incl. initiators and
          implementors) notifications and proposals submitted on / through the
          Platform.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          You, as a Collaborator represent, warrant, and covenant that:
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; all information you provide on/through the Platform is
          accurate, complete, and not likely to deceive reArmenia or any User of
          the Platform;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; register on the Platform using your true identities, including
          your name, address. You agree to keep registration information current
          and up to date. If reArmenia at any time discovers that the
          information you provided about you is incorrect, doesn’t meet
          reArmenia’s policies or violates any of these Terms, your
          collaboration may be canceled, your profile (account) may be
          terminated with immediate effect;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; you will maintain (administer) your profile page on the
          Platform (including timely and efficiently filling with the necessary
          information), interact with all users through the Platform efficient
          and friendly;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; you grant us the right to edit, modify, reformat, excerpt,
          delete, or translate any of your content on the Platform;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; you’re responsible for the stuff you post. All information
          submitted to the Platform or reArmenia directly, whether publicly
          posted or privately transmitted, is the sole responsibility of the
          person from whom that content originated;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; we’re not responsible for mistakes in your content. reArmenia
          will not be liable for any errors or omissions in any of your content.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          Please evaluate your time and effort possibilities, means and
          resources in advance before applying for collaboration on the Platform
          and be prepared for a possible refusal due to non-compliance with
          Terms, reArmenia’s policies, internal rules or strategy.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          Collaborator undertakes to disclose to reArmenia any conflict of
          interests along with any personal interests, affiliation or activity
          with reArmenia and/or Initiator and/or Implementor.
        </Text>
        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          8. Service Provider
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          reArmenia enables the opportunity for Project creators, Initiators or
          Implementors to leverage from a wide range of services aimed at
          facilitation of Project or Initiative implementation process through
          the list of Service providers here. The Service providers are legal
          entities or individuals, specialized in different areas, including but
          not limited to marketing, visual content creation, copywriting, legal,
          finance, GR, etc.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          You, as a Service provider, having objectively and reasonably all
          necessary means and resources, undertake to provide corresponding
          services to the Project creator, Initiator or Implementor who have
          contacted you for that. The Service provider is responsible for the
          timely and complete provision of services according to the
          corresponding agreement, signed between the Service provider and
          Project creators/Initiators/Implementors.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          The Service provider independently decides on what basis to provide
          the service: paid, partnership or charity. All rights and obligations
          will be on the sides (Service providers and Project
          creators/Initiators/Implementors).
        </Text>
        <Text textAlign="justify" lineHeight="8">
          The Platform serves as a venue for meet up between Service providers
          and Project creators/Initiators/Implementors. Regardless of anything
          reArmenia isn’t liable for any damages or losses related to
          cooperation between Service providers and Project
          creators/Initiators/Implementors. We don’t become involved in disputes
          between Platform users.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          The Service provider undertakes to disclose to reArmenia any conflict
          of interests along with any personal interests, affiliation, or
          activity with Initiators and/or Implementors and/or reArmenia.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          The Service provider also undertakes to provide reArmenia (if there is
          a corresponding request) with all necessary documents, information,
          and materials for the purpose of conducting compliance and trust and
          safety procedures and also to verify that the Service provider has all
          the necessary resources to provide corresponding services.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          You, as a Service provider represent, warrant, and covenant that:
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; all information you provide on/through the Platform (incl. but
          not limited to your professional skills and experience) is accurate,
          complete, and not likely to deceive reArmenia or any User of the
          Platform;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; register on the Platform using your true identities, including
          your name, address. You agree to keep registration information current
          and up to date. If reArmenia at any time discovers that the
          information you provided about you is incorrect, doesn’t meet
          reArmenia’s policies or violates any of these Terms, your profile
          (account) may be terminated with immediate effect;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; you will maintain (administer) your profile page on the
          Platform (including timely and efficiently filling with the necessary
          information), interact with all users through the Platform efficient
          and friendly;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; you grant us the right to edit, modify, reformat, excerpt,
          delete, or translate any of your content on the Platform;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; you’re responsible for the stuff you post. All information
          submitted to the Platform or reArmenia directly, whether publicly
          posted or privately transmitted, is the sole responsibility of the
          person from whom that content originated;
        </Text>
        <Text textAlign="justify" lineHeight="8">
          &#9679; we’re not responsible for mistakes in your content. reArmenia
          will not be liable for any errors or omissions in any of your content.
        </Text>
        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          9. Limitation of Liability
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          To the fullest extent permitted by law, in no event will reArmenia,
          its affiliates, directors, employees be liable for any indirect,
          incidental, punitive, consequential, special, or exemplary damages of
          any kind, including but not limited to damages (i) resulting from your
          access to, use of, or inability to access or use the Platform (it’s
          services); (ii) for any lost profits, data loss, or cost of
          procurement or substitute goods or services; or (iii) for any conduct
          of content of any third party on the Platform. In no event shall
          reArmenia’s liability for direct damages be in excess of (in the
          aggregate) fifty thousand Armenian Drams (AMD 50 000).
        </Text>
        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          10. Disputes resolution. Governing law
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          We at reArmenia encourage you to contact us if you’re having an issue
          before resorting to the courts. Our support team is on hand and ready
          to answer your questions. You can contact us via email{' '}
          <a
            style={{ fontWeight: '600', color: '#012f73' }}
            href="mailto:info@rearmenia.com"
          >
            info@rearmenia.com
          </a>{' '}
          so that we can personally reply to you and attempt to resolve your
          issue.
        </Text>
        <Text textAlign="justify" lineHeight="8">
          In the unfortunate situation where legal action does arise, these
          Terms (and all other rules, policies, or guidelines incorporated by
          reference) will be governed by and construed in accordance with the
          laws of the Republic of Armenia. You agree that any action at law
          arising out of or relating to these Terms, or your use or non-use of
          the Platform, shall be filed only in the courts located in the
          Republic of Armenia.
        </Text>
      </Container>
      <Footer />
    </Flex>
  )
}

export default Terms
