import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Flex } from '@chakra-ui/react'
import { useGetProject } from 'hooks'
import { useUpdateProject } from 'hooks'
import Donate from './Donate'
import Header from './Header'

const MobileDonation = () => {
  const { projectId } = useParams()
  const { data: getProjectResponse, isLoading } = useGetProject({ projectId })
  const { data: updateProjectResponse } = useUpdateProject()

  const project = useMemo(() => {
    if (updateProjectResponse) return updateProjectResponse.data.updateProject
    if (getProjectResponse) return getProjectResponse
    return null
  }, [getProjectResponse, updateProjectResponse])

  if (isLoading) {
    return <></>
  }

  return (
    <Flex align="center" justify="center" flexDirection="column" pb="3">
      <Header />
      <Donate project={project} />
    </Flex>
  )
}

export default MobileDonation
