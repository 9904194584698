import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'

export default function StatusTexts({ workTime, isVolunteer, ...rest }) {
  const { t } = useTranslation()
  return (
    <Flex flexDirection="column" {...rest}>
      <Text fontSize="md" color="gray.700">
        {isVolunteer && t('expertsCard@statusVolunteer')}-{' '}
        {workTime !== 'I don’t know yet' && t(workTime)}
      </Text>
    </Flex>
  )
}
