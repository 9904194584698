import {
  Stepper as ChakraStepper,
  Step,
  StepIndicator,
  StepStatus,
  Stack,
  StepTitle,
  Progress,
  Text,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as StepCheckIcon } from 'assets/icons/step-check.svg'

export default function Stepper({ steps, activeStep = 0 }) {
  const { t } = useTranslation()

  return (
    <ChakraStepper index={activeStep} size="lg">
      {steps.map((step, index) => {
        const isActive = index === activeStep
        const isCompleted = index < activeStep
        const isLastStep = index + 1 >= steps.length
        const progressPercent = isCompleted ? 100 : 0
        return (
          <Step key={index}>
            <Stack alignItems="center" spacing={3}>
              <StepIndicator
                sx={{
                  '[data-status=complete] &': {
                    background: 'blue.400',
                    borderColor: 'blue.400',
                    color: 'blue.400',
                  },
                  '[data-status=active] &': {
                    background: 'white',
                    borderColor: 'blue.400',
                    color: 'blue.400',
                  },
                  '[data-status=incomplete] &': {
                    background: 'white',
                    borderColor: 'gray.1100',
                  },
                }}
              >
                <StepStatus
                  complete={<StepCheckIcon />}
                  incomplete={'0' + (index + 1)}
                  active={'0' + (index + 1)}
                />
              </StepIndicator>

              <StepTitle>
                <Text
                  fontWeight={isActive || isCompleted ? 'bold' : 'medium'}
                  color={isActive || isCompleted ? 'blue.400' : 'gray.700'}
                >
                  {t(step)}
                </Text>
              </StepTitle>
            </Stack>
            {!isLastStep && (
              <Progress
                value={progressPercent}
                position="absolute"
                height="3px"
                width="full"
                left="47px"
                top="40px"
                transform="translateY(-21.5px)"
                bg="gray.1100"
                zIndex={-1}
              />
            )}
          </Step>
        )
      })}
    </ChakraStepper>
  )
}
