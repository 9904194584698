import * as React from 'react'
import { Box, Flex, Icon, useColorModeValue as mode } from '@chakra-ui/react'
import { Link } from 'pages'

const DesktopNavLink = ({ active, to, onClick = () => {}, ...rest }) => {
  return (
    <Link onClick={onClick} style={{ position: 'relative' }} to={to}>
      <Flex
        fontSize="md"
        display="flex"
        aria-current={active ? 'page' : undefined}
        fontWeight="semibold"
        color={mode('gray.800', 'gray.700')}
        {...rest}
        _activeLink={{
          color: 'blue.400',
          fontWeight: 'bold',
        }}
      />
      {active && (
        <Box
          left="0"
          top="25px"
          right="0"
          mx="auto"
          height="2px"
          width="24px"
          borderRadius="1px"
          backgroundColor="#002D78"
          position="absolute"
        />
      )}
    </Link>
  )
}

const MobileNavLink = ({ icon, children, to }) => {
  return (
    <Flex
      m="-3"
      p="3"
      align="center"
      rounded="md"
      cursor="pointer"
      _hover={{ bg: mode('gray.50', 'gray.600') }}
    >
      <Link to={to}>
        <Icon as={icon} color={mode('blue.600', 'blue.400')} fontSize="xl" />
        <Box marginStart="3" fontWeight="medium">
          {children}
        </Box>
      </Link>
    </Flex>
  )
}

export const NavLink = {
  Desktop: DesktopNavLink,
  Mobile: MobileNavLink,
}
