import React from 'react'
import { Center } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import EmptyState from 'components/EmptyState'

export default function Endowment() {
  const { t } = useTranslation()

  return (
    <Center h="100%">
      <EmptyState
        title={t('profile@dashboard@investor@endowment@emptyHeading')}
        description={t('profile@dashboard@investor@endowment@emptyDescription')}
        bulletedDescription={t(
          'profile@dashboard@investor@endowment@emptyDescriptionBullets'
        )}
        button={t('profile@dashboard@investor@endowment@emptyButton')}
        link="/contact-us"
        maxW="1000px"
        mobileMT={0}
      />
    </Center>
  )
}
