import { useNavigation } from 'pages'
import { useEffect } from 'react'

export default function DonationFallback() {
  const { navigationPush } = useNavigation()
  useEffect(() => {
    navigationPush('/donation?projectId=transaction-to-reArmenia')
  }, [])
  return null
}
