import { useEffect } from 'react'
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  VKShareButton,
} from 'react-share'
import { BiLink } from 'react-icons/bi'
import { Flex, IconButton, Button, useClipboard } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import useCustomToast from 'hooks/useCustomToast'
import { FaFacebookF, FaTwitter, FaLinkedin, FaVk } from 'react-icons/fa'

export default function ShareTransactionSocial({ link, ...props }) {
  const { t } = useTranslation()
  const { hasCopied, onCopy } = useClipboard(link)
  const toast = useCustomToast()

  useEffect(() => {
    if (!hasCopied) return
    toast({
      title: t('copied'),
      status: 'success',
      duration: 5000,
      isClosable: true,
    })
  }, [hasCopied])

  return (
    <Flex
      mt={{ base: 4, sm: 6 }}
      flexFlow="wrap"
      px={{ base: 5, md: 0 }}
      mb={{ base: 12, sm: '70px' }}
      justifyContent={{ base: 'center', md: 'flex-start' }}
      {...props}
    >
      <FacebookShareButton url={link} description={'reArmenia'}>
        <IconButton
          variant="socialIcon"
          mx={{ base: 2, sm: '3' }}
          icon={<FaFacebookF fontSize="18px" color="#1877F2" />}
          _hover={{ filter: 'brightness(1.05)' }}
        />
      </FacebookShareButton>
      <TwitterShareButton url={link} description={'reArmenia'}>
        <IconButton
          variant="socialIcon"
          mx={{ base: 2, sm: '3' }}
          icon={<FaTwitter fontSize="18px" color="#1FA1F1" />}
          _hover={{ filter: 'brightness(1.05)' }}
        />
      </TwitterShareButton>
      <VKShareButton url={link} description={'reArmenia'}>
        <IconButton
          variant="socialIcon"
          mx={{ base: 2, sm: '3' }}
          icon={<FaVk fontSize="18px" color="#5181B8" />}
          _hover={{ filter: 'brightness(1.05)' }}
        />
      </VKShareButton>
      <LinkedinShareButton url={link} description={'reArmenia'}>
        <IconButton
          variant="socialIcon"
          mx={{ base: 2, sm: '3' }}
          icon={<FaLinkedin fontSize="18px" color="#2867B2" />}
          _hover={{ filter: 'brightness(1.05)' }}
        />
      </LinkedinShareButton>
      <Button
        color="gray.800"
        fontSize="md"
        fontWeight="500"
        border="1px"
        borderColor="border.100"
        pl="27px"
        pr="27px"
        mx={3}
        my={4}
        height="59px"
        width={'auto'}
        borderRadius={{ base: 12, sm: 24 }}
        onClick={onCopy}
      >
        <BiLink
          color="black"
          style={{
            marginRight: '20px',
            width: 'auto',
            height: '18px',
          }}
        />
        {t('copyLink')}
      </Button>
    </Flex>
  )
}
