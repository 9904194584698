const styles = {
  global: {
    body: {
      overscrollBehaviorY: 'none',
    },
    '*:focus': {
      boxShadow: 'none !important',
    },
    '*[data-focus]': {
      boxShadow: 'none !important',
    },
    'html, body': {
      color: '#343434',
      fontWeight: 400,
      fontSize: '1rem',
    },
    a: {
      textDecoration: 'none !important',
    },
    input: {
      '&::placeholder': {
        color: 'placeholder.100',
      },
    },
    textarea: {
      '&::placeholder': {
        color: 'placeholder.100',
      },
    },
    button: {
      _focus: {
        boxShadow: 'none !important',
      },
    },
  },
}

export default styles
