import { BudgetFiles } from 'components/BudgetFiles'

export default function FileFormField({
  register,
  errors,
  setValue,
  getValues,
  data = [],
  fieldKey,
  clearErrors,
  label,
}) {
  return (
    <BudgetFiles
      label={label}
      register={register}
      errors={errors}
      setValue={setValue}
      getValues={getValues}
      data={data}
      fieldKey={fieldKey}
      clearErrors={clearErrors}
      isDownloadTemplateVisible={false}
    />
  )
}
