/* eslint-disable */
import React, { useEffect } from 'react'
import { Flex, Spinner } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useNavigation } from 'pages'
import {
  useCheckBasketOrder,
  useCustomToast,
  useQueryParams,
  useUser,
} from 'hooks'
import gtag from 'ga-gtag'
import amplitude from 'amplitude-js'
import { isMobileDevice } from 'utils'

const CheckTransactionBasketSubscription = () => {
  const params = useQueryParams()
  const toast = useCustomToast()
  const { navigationReplace } = useNavigation()
  const { t } = useTranslation()
  const {
    data: response,
    isLoading,
    error,
  } = useCheckBasketOrder({
    orderId: params.get('orderId'),
    provider: params.get('provider'),
  })
  const { getUser } = useUser()
  useEffect(() => {
    if (isLoading) {
      return
    }
    if (error) {
      amplitude
        .getInstance()
        .logEvent(
          `order-declined-basket-subscription-${
            isMobileDevice() ? 'mobile' : 'desktop'
          }`
        )
      navigationReplace('/')
      toast({
        title: t(`payment@orderStatus-0`),
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return
    }
    const success = response.order.success
    if (response.order.transactionInfo) {
      gtag('event', 'purchase', {
        transaction_id: response.order.orderNumber,
        affiliation: 'Rearmenia donation',
        value:
          Number(response.order.transactionInfo.amount) +
          Number(response.order.transactionInfo.tip),
        currency: 'AMD',
      })
    }

    if (success) {
      toast({
        title: t(`payment@orderStatus-2`),
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    } else {
      toast({
        title: t(`arca_message_action_code-${response.order.actionCode}`),
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }

    getUser()
    navigationReplace(success ? '/basket/thank-you' : '/basket/items')
  }, [response, error])

  return (
    <Flex
      width="100%"
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner size="lg" />
    </Flex>
  )
}

export default CheckTransactionBasketSubscription
