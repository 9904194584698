import { Image, Flex, Text, Radio } from '@chakra-ui/react'

const CardSavingSelectionWithChildren = ({
  text,
  isSelected,
  Icon,
  src,
  children,
  isInvalid,
  ...props
}) => (
  <Flex
    p="22px"
    justify="center"
    direction="column"
    borderRadius={8}
    borderWidth="1px"
    borderColor={
      isInvalid && isSelected
        ? 'red.400'
        : isSelected
        ? 'blue.300'
        : 'border.500'
    }
    h={isSelected && !!children ? 'auto' : '64px'}
    {...props}
  >
    <Flex justify="space-between">
      <Flex alignItems="center">
        {src && <Image src={src} h="20px" />}
        {Icon && <Icon />}

        <Text ml={4} color="black.100" fontSize="md">
          {text}
        </Text>
      </Flex>

      <Flex alignItems="center">
        <Radio value="1" isChecked={isSelected}></Radio>
      </Flex>
    </Flex>
    {isSelected && children}
  </Flex>
)

export default CardSavingSelectionWithChildren
