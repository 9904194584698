import { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
} from '@chakra-ui/react'
import { LANG_RU_SHORT, useTranslation } from 'contexts/TranslationContext'
import PerkCard from 'components/PerkCard'
import Progress from 'components/Progress'
import { calculateProjectDurationLeft, projectProgress } from 'hooks'
import {
  convert,
  CurrencyConverter,
  SUPPORTED_CURRENCIES,
  thousandSeparator,
  useCurrencySettings,
} from 'contexts/CurrencySettingsContext'
import { BlueSelect } from 'components/Select/BlueSelect'
import { useScreenSize } from 'contexts'
import Tip from './Tip'
import { FUNDRAISER_NONPROFIT } from 'constants/fundraiser'

export default function Web({
  PRICE,
  donation,
  setValue,
  getValues,
  other,
  perk,
  errors,
  register,
  handleAmount,
  giftCardMode,
  project,
  isContribution,
  isBuyingGiftCard,
  note,
}) {
  const { t, language } = useTranslation()
  const { isMobile } = useScreenSize()
  const [showInputZero, setShowInputZero] = useState(false)
  const { currency, settings, changeDefaultCurrency } = useCurrencySettings()
  const newProject = {
    ...project,
    amount: donation.amount,
  }
  const selectOption = (option) => {
    changeDefaultCurrency(option)
    setValue('amount', 0, '')
  }
  const progressData = projectProgress(newProject, currency?.current)
  useEffect(() => {
    if (!project) return
    project.hideStats = true
  }, [])

  const progressPercent = useMemo(() => {
    const projectAmount = convert({
      amount: project?.amount || 0,
      currencyRate: settings?.currency[currency.current],
    })
    const projectGoal = convert({
      amount: project?.activeGoal?.amount || project?.goal || 0,
      currencyRate: settings?.currency[currency.current],
    })

    return ((projectAmount + +progressData.amount) / projectGoal) * 100
  }, [
    project?.amount,
    project?.goal,
    project?.activeGoal?.amount,
    progressData.amount,
    currency.current,
    settings?.currency,
  ])

  return (
    <Stack w="full" mt={isBuyingGiftCard ? 8 : 0} spacing={8}>
      <Box
        w="full"
        background={isBuyingGiftCard ? 'gray.300' : 'white'}
        px={{ base: isBuyingGiftCard ? 5 : 0, sm: '22px' }}
        py={{ base: isBuyingGiftCard ? 5 : 0, sm: '22px' }}
        borderRadius="12px"
        borderStyle="solid"
        borderWidth={{ sm: '1px' }}
        borderColor="border.400"
      >
        {perk !== null ? (
          <>
            <Box mb="1" as="span" fontWeight="500" fontSize="xl">
              {t('donation@perk')}
            </Box>
            <PerkCard
              title={perk[`title_${language}`] ?? ''}
              description={perk[`description_${language}`] ?? ''}
              price={perk.price ?? 0}
              image={perk.imageKey ?? perk.image}
              isSelectable={false}
              convertPrice={false}
            />
          </>
        ) : (
          <>
            <FormControl zIndex={1}>
              <FormLabel
                fontSize="xl"
                fontWeight="semibold"
                color="black"
                zIndex={2}
              >
                {isBuyingGiftCard && t('giftCardAmount')}
                {note && (
                  <Text fontSize="sm" fontWeight={400} color="gray.700">
                    {note}{' '}
                  </Text>
                )}
              </FormLabel>

              <InputGroup alignItems="center">
                <InputLeftElement
                  top="unset"
                  width="100px"
                  borderRight="1px solid"
                  borderColor="border.100"
                  ml={2}
                  children={
                    <BlueSelect
                      variant="big"
                      display="flex"
                      type="currency"
                      labelFontSize="3xl"
                      amount={+progressData.amount}
                      label={currency.current}
                      options={SUPPORTED_CURRENCIES.map((option) => ({
                        title: t(`currency@${option}`),
                        amount: `${CurrencyConverter({
                          currency: option,
                          amount: +progressData.amount,
                        })} ${option}`,
                        value: option,
                      }))}
                      selectOption={selectOption}
                      currentSelectedOption={t(`currency@${currency.current}`)}
                    />
                  }
                />
                <Input
                  autoFocus
                  _focus={{
                    boxShadow: 'none',
                    color: 'black',
                    fontWeight: 'extrabold',
                  }}
                  borderColor={
                    errors.amount ? 'red.400 !important' : 'border.100'
                  }
                  _hover={{
                    borderColor: 'blue.300',
                  }}
                  maxLength="12"
                  value={
                    showInputZero ? '' : thousandSeparator(donation.amount)
                  }
                  pr="6"
                  color={Number(donation.amount) === 0 ? '#CDCDCD' : 'black'}
                  fontWeight={
                    Number(donation.amount) === 0 ? '400' : 'extrabold'
                  }
                  pattern="[0-9,]*"
                  {...register('amount', {
                    required: true,
                    min: 1,
                    onChange: (e) => {
                      setShowInputZero(!e.target.value)
                    },
                    onFocus: (e) => setShowInputZero(e.target.value === '0'),
                    onBlur: () => setShowInputZero(false),
                    setValueAs: (v) => {
                      if (typeof v === 'number') {
                        return v
                      }
                      return v?.replace(/[^0-9]|^0+(?!$)/g, '')
                    },
                  })}
                  pl={32}
                  textAlign={{ base: 'right', sm: 'left' }}
                  fontSize="2rem"
                  height="86px"
                  borderRadius="12px"
                  backgroundColor="white !important"
                />
              </InputGroup>
            </FormControl>
            {project?.isProject &&
              project.fundraisingType !== FUNDRAISER_NONPROFIT && (
                <Progress
                  project={{ ...project, updateAmount: progressData.amount }}
                  raisedMoney={thousandSeparator(
                    convert({
                      amount: project.amount,
                      currencyRate: settings?.currency[currency.current],
                    }),
                    currency.current
                  )}
                  progress={progressPercent}
                  optionsTexts={SUPPORTED_CURRENCIES.map((option) => ({
                    text: t(`currency@${option}`),
                    amount: CurrencyConverter({
                      amount: project?.amount,
                      currency: option,
                    }),
                  }))}
                  donationPage
                  thousandSeparatorText={thousandSeparator(
                    currency.current === 'AMD'
                      ? project.amount + Number(progressData.amount)
                      : project.amount /
                          Number(settings?.currency[currency.current]) +
                          Number(progressData.amount),
                    currency.current
                  )}
                  monthlyGoalText={t('monthlyGoal')}
                  goalPrice={project.activeGoal?.amount || project.goal}
                  goalText={t('goal')}
                  raisedText={
                    language === LANG_RU_SHORT && isMobile ? '' : t('raised')
                  }
                  calculatedProjectDurationLeft={calculateProjectDurationLeft({
                    t,
                    campaignImplementorPeriod_en: {
                      ...project,
                      updateAmount: progressData.amount,
                    }.campaignImplementorPeriod_en,
                    fundraisingType: {
                      ...project,
                      updateAmount: progressData.amount,
                    }.fundraisingType,
                  })}
                  currency={currency}
                  settings={settings}
                  changeDefaultCurrency={changeDefaultCurrency}
                />
              )}
          </>
        )}
      </Box>
      {project?.fundraisingType === 'recurring' &&
        !project?.isOneTimeDisabled &&
        !isContribution &&
        !giftCardMode && (
          <Checkbox
            size="lg"
            iconSize="240rem"
            onChange={({ target }) =>
              setValue('paymentType', target.checked ? 'recurring' : 'oneTime')
            }
            isChecked={getValues('paymentType') === 'recurring'}
          >
            <Text fontSize="md">
              {t('donation@makeThisDonationMonthly').split('(bold)')[0]}
              <b>{t('donation@makeThisDonationMonthly').split('(bold)')[1]}</b>
              {t('donation@makeThisDonationMonthly').split('(bold)')[2]}
            </Text>
          </Checkbox>
        )}
      {!isContribution && (
        <Tip
          price={perk !== null ? perk.price : donation.amount}
          other={other}
          handleAmount={handleAmount}
          setValue={setValue}
          donation={donation}
          PRICE={PRICE}
        />
      )}
    </Stack>
  )
}
