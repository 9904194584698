import { Flex, Box, Heading, Text, Image } from '@chakra-ui/react'
import { ReactComponent as Close } from 'assets/icons/closeBlack.svg'
import ImageWrapper from 'components/ImageWrapper'
import UserInfo from 'components/UserInfo'

const Header = ({
  giftCardAmount,
  goBack,
  title,
  reArmenia,
  project,
  language,
  giftCardCurrency,
  t,
  giftCardInfo,
  giftCardMode,
  GiftCardBanner,
  isContribution,
  isBuyingGiftCard,
  hasPerkIndex,
}) => {
  return (
    <Box>
      <Flex
        w="full"
        borderBottom="1px solid"
        borderColor="border.100"
        justifyContent="center"
        position="relative"
        alignItems="center"
        py="3"
      >
        <Flex
          position="absolute"
          left="12px"
          onClick={goBack}
          width="30px"
          height="30px"
          alignItems="center"
        >
          <Close width="14px" height="14px" />
        </Flex>
        <Heading as="h1" textAlign="center" fontSize="3xl" fontWeight={500}>
          {title}
        </Heading>
      </Flex>
      {!isBuyingGiftCard && !hasPerkIndex && (
        <>
          {giftCardMode && (
            <GiftCardBanner
              my={8}
              mx={5}
              minH="60px"
              w="auto"
              fontSize="md"
              giftCardCurrency={giftCardCurrency}
              giftCardAmount={giftCardAmount}
            />
          )}
          <Flex
            py={6}
            px={5}
            bg={isContribution ? 'white' : 'gray.400'}
            justify="space-between"
          >
            <Flex>
              <Box>
                {isContribution ? (
                  <Flex w="full" alignItems="center" fontSize="sm" mb={4}>
                    <Image src="/assets/images/rearmenia_avatar.png" />
                    <Text fontWeight={600} ml={4}>
                      {t('reArmeniaCollabPlatform')}
                    </Text>
                  </Flex>
                ) : (
                  <Box mr={1.5}>
                    <Heading mb={2} fontSize="lg" as="h2" fontWeight={700}>
                      {project?.title_en
                        ? project[`title_${language}`]
                        : project?.applicationTitle}
                    </Heading>
                    {!project.isCompany && project?.owner && (
                      <UserInfo
                        name={project[`creatorName_${language}`]}
                        image={project?.creatorImage}
                      />
                    )}
                  </Box>
                )}
              </Box>
            </Flex>
            {!isContribution && (
              <Box position="relative">
                <ImageWrapper
                  src={`/thumbnail/small/${project?.cover}`}
                  alt="donation"
                  objectFit="cover"
                  borderRadius="12px"
                  height="70px"
                  minW="121px"
                />
              </Box>
            )}
          </Flex>

          {!isContribution && giftCardMode && (
            <Flex
              borderColor="border.100"
              borderTopWidth="1px"
              borderBottomWidth="1px"
              direction="column"
              justifyContent="space-between"
              m={4}
              py={4}
            >
              <Flex justifyContent="space-between" pt={4} fontWeight="bold">
                <Text>{t('giftCardAmount')}</Text>
                <Text>{`${giftCardAmount} ${giftCardCurrency}`}</Text>
              </Flex>
              <Flex justifyContent="space-between" pt={4}>
                <Text>{t('donation@yourDonation')}</Text>
                <Text>
                  {(
                    giftCardInfo.amountWithCurrency -
                    reArmenia / giftCardInfo.currencyRate
                  ).toFixed(2)}{' '}
                  {giftCardInfo.currency}
                </Text>
              </Flex>
              <Flex justifyContent="space-between" pt={4}>
                <Text>{t('donation@reArmeniaTip')}</Text>
                <Text>
                  {(reArmenia / giftCardInfo.currencyRate).toFixed(2)}{' '}
                  {giftCardInfo.currency}
                </Text>
              </Flex>
            </Flex>
          )}
        </>
      )}
    </Box>
  )
}

export default Header
