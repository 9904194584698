const bindingCardUseCase = (bindingCardRepo) => {
  const deleteBindingCard = async (id) =>
    await bindingCardRepo.deleteBindingCard(id)

  return {
    deleteBindingCard,
  }
}

export default bindingCardUseCase
