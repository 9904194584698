import { projectsByIds } from 'elastic/queries'
import moment from 'moment'

const donationSubscriptionUseCase = (
  DonationSubscriptionService,
  projectUseCase
) => {
  const updateDonationSubscription = (donationSubscription) =>
    DonationSubscriptionService.updateDonationSubscription(donationSubscription)

  const donationSubscriptionsByUserEmail = async (
    condition,
    loadProjectsData
  ) => {
    const donationSubscriptionsRes =
      await DonationSubscriptionService.donationSubscriptionsByUserEmail(
        condition
      )
    let donationSubscriptions = donationSubscriptionsRes.items
    if (loadProjectsData) {
      const projectIds = donationSubscriptions.map((item) => item.projectId)
      const projectsRes = await projectUseCase.getProjects(
        projectsByIds(projectIds),
        'subscribed_projects'
      )
      const projects = projectsRes.items
      for (const subscription of donationSubscriptions) {
        subscription.project = projects.find(
          (project) => project.id === subscription.projectId
        )
        subscription.amount = Number(subscription.donationToProject)

        if (Number(subscription.donationToReArmenia)) {
          subscription.amount += Number(subscription.donationToReArmenia)
        }
      }
      donationSubscriptions = donationSubscriptions?.map((rest) => ({
        ...rest,
        formattedDate: moment(rest.createdAt).format('MMMM DD, YYYY'),
      }))
    }

    return donationSubscriptions
  }

  const donationSubscriptionsByProjectId = async (
    condition,
    withProjectDetails
  ) => {
    const donationSubscriptions =
      await DonationSubscriptionService.donationSubscriptionsByProjectId(
        condition
      )
    const items =
      donationSubscriptions.data.donationSubscriptionsByProjectId.items

    if (withProjectDetails) {
      for (const item of items) {
        item.project = await projectUseCase.retrieveProject(item.projectId)
        item.amount = item.donationToProject

        if (item.donationToReArmenia) {
          item.amount += item.donationToReArmenia
        }
      }
    }

    return items
  }

  const getDonationSubscription = (id) =>
    DonationSubscriptionService.getDonationSubscription(id)

  return {
    updateDonationSubscription,
    donationSubscriptionsByUserEmail,
    donationSubscriptionsByProjectId,
    getDonationSubscription,
  }
}

export default donationSubscriptionUseCase
