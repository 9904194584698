import React, { useEffect } from 'react'
import { useTranslation } from 'contexts/TranslationContext'
import { useForm } from 'react-hook-form'
import {
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
  Button,
  Heading,
  Text,
  VStack,
  chakra,
  Flex,
} from '@chakra-ui/react'
import { useSubscribeNews, useUser } from 'hooks'
import { InputField } from '../components/InputField'
import { useNavigation } from 'pages'

const SubscriptionModal = ({ closeModal }) => {
  const { register, handleSubmit } = useForm()
  const { navigationPush } = useNavigation()
  const { t, language } = useTranslation()
  const { mutate: subscribeNews, isLoading } = useSubscribeNews()
  const { user, setUser } = useUser()

  const submit = async (data) => {
    const [firstName, lastName] = data.fullName.split(' ')
    await subscribeNews(
      { email: data.email.toLowerCase(), language, firstName, lastName },
      {
        onSuccess: () => {
          closeModal()
          navigationPush('/subscribe/thank-you')
        },
        onError: (e) => {
          console.log('subscribe error', e)
        },
      }
    )
  }

  useEffect(() => {
    return () => {
      setUser({
        user: {
          ...user,
          seenSubscribeModal: true,
        },
        isLoading: false,
      })
    }
  }, [])

  return (
    <ModalContent px="2" borderRadius="16px">
      <ModalHeader pb="1">
        <Image
          height="100px"
          width="122px"
          src="/assets/images/screening.png"
        />
      </ModalHeader>
      <ModalCloseButton onClick={closeModal} id="subscribe_modal_close" />
      <ModalBody pb="0">
        <Heading as="h2" fontSize="4xl" fontWeight="bold" mb="2">
          {t('subscribeModal@Title')}
        </Heading>
        <Text color="#505D68" fontSize="sm" mb="7">
          {t('subscribeModal@subTitle')}
        </Text>
        <chakra.form onSubmit={handleSubmit(submit)}>
          <VStack spacing="3" mb="4.5rem">
            <InputField
              borderRadius="8px"
              label={t('subscribeModal@fullName')}
              {...register('fullName', { required: true })}
              id="subscribe_modal_fullName"
            />
            <InputField
              borderRadius="8px"
              label={t('subscribeModal@email')}
              {...register('email', { required: true })}
              id="subscribe_modal_email"
            />
          </VStack>
          <Flex>
            {' '}
            <Button
              isLoading={isLoading}
              w="full"
              variant="blue"
              type="submit"
              id="subscribe_modal_submit"
            >
              {t('subscribeModal@subscribeButton')}
            </Button>
          </Flex>
        </chakra.form>
      </ModalBody>

      <ModalFooter>
        <VStack
          pb="1.125rem"
          w="full"
          spacing="4"
          justifyContent="center"
          alignItems="center"
        >
          <Text color="#737373" fontSize="sm">
            {t('subscribeModal@unsubscribeNote')}
          </Text>
        </VStack>
      </ModalFooter>
    </ModalContent>
  )
}

export default SubscriptionModal
