const Modal = {
  variants: {
    responsive: {
      dialogContainer: {
        alignItems: {
          base: 'flex-start',
          lg: 'center',
        },
      },
    },
  },
}

export default Modal
