import React, { useEffect } from 'react'
import { Box } from '@chakra-ui/react'
import { Header } from './Header'
import PopularProjects from './PopularProjects'
import PopularInitiatives from './PopularInitiatives'
import Explaining from './Expaining'
import About from './About'
import { Footer } from 'components/Footer'
import Join from './Join'
import Blog from './Blog'
import Navbar from 'components/Navbar'
import amplitude from 'amplitude-js'

const Web = ({
  featuredProjects,
  isProjectsLoading,
  projects,
  initiatives,
  isPopularLoading,
}) => {
  useEffect(() => {
    amplitude.getInstance().logEvent('Web homepage view')
    console.log('sent event to amplitude')
  }, [])

  return (
    <Box>
      <Navbar />
      <Header projects={featuredProjects} isLoading={isProjectsLoading} />
      <Join />
      <PopularProjects isLoading={isPopularLoading} projects={projects} />
      <PopularInitiatives
        isLoading={isPopularLoading}
        initiatives={initiatives}
      />
      {/* <Urgent/> */}
      {/* TODO: UNCOMMENT WHEN GIFTS CARD WILL BE ENABLED */}
      {/* <GiftCard/> */}
      <Explaining />
      {/* <CreateProject /> */}
      <About />
      <Blog />
      <Footer />
    </Box>
  )
}

export default Web
