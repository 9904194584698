import React, { useState } from 'react'
import { Stack, Box, Heading, Text, Checkbox, Flex } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { Link } from 'pages'
import { InputField } from 'components/InputField'
import FilesUpload from 'components/FilesUpload'

const ContactInformation = ({
  canValidateEmail,
  register,
  errors,
  setLegal,
  legal,
  setValue,
  getValues,
  files,
}) => {
  const { t } = useTranslation()
  const [tinValue, setTinValue] = useState('')

  return (
    <Box maxW="600px" width="100%">
      <Heading mb="6" fontSize="6xl" as="h2">
        {t('Contact Information')}
      </Heading>

      <Stack mb="5.5rem" spacing="6">
        <InputField
          isInvalid={!!errors.contactFullName}
          label={t('createEditProject@contactPersonFullName') + '*'}
          description={t('createEditProject@contactPersonFullNameText')}
          errorMessage={t('createEditProject@thisFieldIsRequired')}
          maxLength={80}
          {...register('contactFullName', { required: true })}
        />
        <InputField
          isInvalid={!!errors.email && canValidateEmail}
          label={t('subscribeModal@email') + '*'}
          errorMessage={t(errors?.email?.message)}
          description={t('createEditProject@emailText')}
          maxLength={80}
          {...register('email', {
            required: {
              message: 'createEditProject@thisFieldIsRequired',
              value: true,
            },
            pattern: {
              message: 'createEditInitiative@emailError',
              value: /\S+@\S+\.\S+/,
            },
          })}
        />
        <InputField
          isInvalid={!!errors.phone}
          label={t('createEditProject@phoneNumber') + '*'}
          description={t('createEditProject@phoneNumberText')}
          errorMessage={t('createEditProject@thisFieldIsRequired')}
          type="number"
          pattern="[0-9+]"
          {...register('phone', { required: true })}
        />
        <Checkbox
          alignItems="flex-start"
          _focusVisible={{ boxShadow: 'outline' }}
          _focus={{ boxShadow: 'none' }}
          value={legal}
          onChange={() => setLegal(!legal)}
        >
          {t('createEditProject@submitFormRequiredNote')}
        </Checkbox>
        {legal && (
          <>
            <InputField
              isInvalid={!!errors.organizationLegalForm}
              errorMessage={t('createEditProject@thisFieldIsRequired')}
              description={t('createEditProject@organizationLegalFormText')}
              label={t('createEditProject@organizationLegalForm') + '*'}
              {...register('organizationLegalForm', { required: true })}
            />

            <InputField
              {...register('tin', { required: true, minLength: 8 })}
              isInvalid={!!errors.tin}
              value={tinValue}
              onInput={(e) => setTinValue(e.target.value.slice(0, 8))}
              pattern="[0-9]*"
              type="number"
              label={t('createEditProject@TIN') + '*'}
              description={t('createEditProject@TINText')}
              errorMessage={
                !tinValue
                  ? t('createEditProject@thisFieldIsRequired')
                  : t('createEditProject@TINErrorMessage2')
              }
            />
          </>
        )}
        <FilesUpload
          files={files}
          setValue={setValue}
          getValues={getValues}
          description={t('createEditProject@attachFileText')}
        />
        <InputField
          {...register('links')}
          label={t('createEditProject@LinkToFiles')}
          description={t('createEditProject@LinkToFilesText')}
        />
        <Flex direction="column">
          <Checkbox
            {...register('terms_and_conditions', { required: true })}
            isInvalid={!!errors.terms_and_conditions}
            mb="2"
          >
            {t('createEditProject@agreeWith')}&nbsp;
            <Text fontWeight={600} display="inline">
              <Link target="_blank" to="/terms-and-conditions">
                {t('createEditProject@termsConditions')}
              </Link>
            </Text>
          </Checkbox>
          <Checkbox
            {...register('privacy_policy', { required: true })}
            isInvalid={!!errors.privacy_policy}
            alignItems="flex-start"
          >
            {legal
              ? t('createEditProject@haveReadOrg')
              : t('createEditProject@haveRead')}
          </Checkbox>
        </Flex>
      </Stack>
    </Box>
  )
}

export default ContactInformation
