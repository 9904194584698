import { Box } from '@chakra-ui/react'
import { TransactionsProvider, useScreenSize } from 'contexts'
import DonationList from 'pages/Project/DonationList'
import Donates from 'pages/Project/MobileProject/Donations'
import React from 'react'
export default function DonorList({ project, ...rest }) {
  const { isMobile } = useScreenSize()
  return (
    <TransactionsProvider project={project}>
      <Box {...rest}>
        {!isMobile ? (
          <DonationList project={project} donationPage w="full" />
        ) : (
          <Donates project={project} donationPage />
        )}
      </Box>
    </TransactionsProvider>
  )
}
